import { notebookActionCreators } from 'src/common/actions/notebook/notebookActionCreators';
import {
  StudioStateGetter,
  StudioThunkDispatch,
} from 'src/common/actions/StudioAction';

export const addNote =
  () => async (dispatch: StudioThunkDispatch, _getState: StudioStateGetter) => {
    return dispatch(notebookActionCreators.addNote());
  };
