import { SimulationLinkDatum, SimulationNodeDatum } from 'd3-force';

export interface RawNode {
  '@id'?: string;
  '@type'?: string | string[];
  '@graph'?: string | string[];
  '@value'?: string;
  '@language'?: string;
  '@list'?: RawNode[];
  [key: string]: string | string[] | RawNode | RawNode[];
}

export interface RawEdge {
  '@id': RawNode;
  '@type'?: string | string[];
  '@graph'?: string | string[];
  [key: string]: string | string[] | RawNode | RawNode[];
}

export interface RawLink {
  '@type': string;
  '@source': string;
  '@target': string;
  '@graph': string | string[];
  '@subject'?: string;
  '@predicate'?: string;
  '@object'?: string;
  isEdgeProperty?: boolean;
}

export interface RawLinkTarget extends RawNode {
  '@id': string;
  '@type'?: string;
  '@value'?: string;
  '@language'?: string;
}

export const enum ForceGraphObjectType {
  NODE = 'NODE',
  LINK = 'LINK',
}

interface BaseForceGraphNode extends SimulationNodeDatum {
  id: string;
  labelId: string;
  type: ForceGraphObjectType.NODE;
  data: RawNode & {
    '@id': string;
    '@graph': string[];
  };
  linkData: {
    [key: string]: RawLinkTarget[];
  };
  edgeLink?: ForceGraphLink;
  hasBeenExpanded?: boolean;
  isHidden?: boolean;
  isLiteral?: boolean;
  isAttribute?: boolean;
  isEdge?: boolean;
  isEdgeProperty?: boolean;
  isForceGraphObject: true;
  isGroupType?: false;
}

interface ReferentForceGraphNode extends BaseForceGraphNode {
  data: BaseForceGraphNode['data'] & {
    '@type'?: string[];
  };
  isLiteral?: false;
  isEdge?: false;
}

interface LiteralForceGraphNode extends BaseForceGraphNode {
  data: BaseForceGraphNode['data'] & {
    '@type': string;
    '@value': string;
    '@language'?: string;
  };
  isLiteral: true;
  isEdge?: false;
}

interface EdgePropertyForceGraphNode extends BaseForceGraphNode {
  data: ReferentForceGraphNode['data'] & {
    '@subject': string;
    '@predicate': string;
    '@object': string;
  };
  isLiteral?: false;
  isEdge?: true;
}

export type ForceGraphNode =
  | ReferentForceGraphNode
  | LiteralForceGraphNode
  | EdgePropertyForceGraphNode;

export interface ForceGraphLink extends SimulationLinkDatum<ForceGraphNode> {
  id: string;
  type: ForceGraphObjectType.LINK;
  source: string | ForceGraphNode;
  target: string | ForceGraphNode;
  data: RawLink & {
    '@graph': string[];
  };
  edgeNode?: EdgePropertyForceGraphNode;
  __curvature: number;
  __bezier?: BezierJs.Bezier | null;
  __quad?: BezierJs.Bezier | null;
  __coords?: { x1: number; y1: number; x2: number; y2: number };
  isHidden?: boolean;
  isLiteral?: boolean;
  isAttribute?: boolean;
  isEdge?: false;
  isEdgeProperty?: boolean;
  isForceGraphObject: true;
  isGroupType?: false;
}

export interface ForceGraphGraphData {
  nodes: ForceGraphNode[];
  links: ForceGraphLink[];
}

export interface ForceGraphCameraData {
  position: {
    x: number;
    y: number;
  };
  zoomTransform: {
    k: number;
    x: number;
    y: number;
  };
}

export interface ForceGraphCachedData {
  graphData: ForceGraphGraphData;
  cameraData?: ForceGraphCameraData;
}

export interface ForceGraphGroupType {
  id: string;
  label?: string;
  labelObjects?: Array<RawNode | RawLinkTarget>;
  count: number;
  colorIdx: number;
  captionKey: string;
  captionOptions: VisualizationOption[];
  type: ForceGraphObjectType;
  isHidden?: boolean;
  isLiteral?: boolean;
  isEdge?: false;
  isEdgeProperty?: boolean;
  isForceGraphObject?: false;
  isGroupType: true;
}

export interface ForceGraphGroupTypeMap {
  [type: string]: ForceGraphGroupType;
}

export interface ForceGraphNodeColors {
  border: string;
  fill: string;
}

export interface VisualizationOption {
  label: string;
  value: string;
  disabled?: boolean;
}
