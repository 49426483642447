import uuid from 'uuid/v4';

import { browserApi } from 'src/browser/apis/browserApi';
import {
  databasesActionCreators,
  databasesStardogRequestDispatchers,
} from 'src/common/actions/databases/databasesActionCreators';
import {
  StudioStateGetter,
  StudioThunkDispatch,
} from 'src/common/actions/StudioAction';
import { IpcEventType } from 'src/common/constants/events';
import { RDFImportData } from 'src/common/constants/RDFImport';

type RemoveDataParams = {
  graphUri?: string[];
};

const eventIdToHandlerMap = new Map<
  string,
  { resolve: Function; reject: Function }
>();

export const registerRemoveDataListeners = async () => {
  browserApi.ipcRenderer.on(
    IpcEventType.REMOVE_STREAMING_DATA_RESPONSE,
    (_, { eventId, errorMessage }) => {
      if (!eventIdToHandlerMap.has(eventId)) {
        return;
      }
      if (errorMessage) {
        eventIdToHandlerMap.get(eventId).reject(errorMessage);
      } else {
        eventIdToHandlerMap.get(eventId).resolve();
      }
      eventIdToHandlerMap.delete(eventId);
    }
  );
};

export function removeData({
  databaseId,
  filePath,
  options,
  document,
  namedGraphs,
}: RDFImportData) {
  return async (dispatch: StudioThunkDispatch, getState: StudioStateGetter) => {
    const eventId = uuid();
    const connection = getState().connection.current;

    dispatch(databasesActionCreators.removeDataAttempt({ databaseId }));

    const resolve = () => {
      dispatch(databasesActionCreators.removeDataSuccess({ databaseId }));
      databasesStardogRequestDispatchers.getSize({ db: databaseId });
    };
    const reject = (errorMessage: string) => {
      dispatch(
        databasesActionCreators.removeDataFailure({
          databaseId,
          errorMessage,
        })
      );
    };

    const params: RemoveDataParams = {};
    if (namedGraphs && namedGraphs.length) {
      params.graphUri = namedGraphs;
    }

    eventIdToHandlerMap.set(eventId, { resolve, reject });
    browserApi.ipcRenderer.send(IpcEventType.REMOVE_STREAMING_DATA_REQUEST, {
      eventId,
      methodData: {
        databaseId,
        filePath,
        options,
        document,
        params,
        connection,
      },
    });
  };
}
