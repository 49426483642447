import { Headers as NodeFetchHeaders } from 'node-fetch';

const isNodeFetchHeaders = (candidate: any): candidate is NodeFetchHeaders =>
  Boolean(candidate.raw);

interface HeadersHolder {
  headers?: Headers | NodeFetchHeaders;
}

// `headers` is a Map, which can't be stringified for postMessage. Thus, we
// convert to a plain object.
export const formatHeadersForPostMessage = <T extends HeadersHolder>(
  headersHolder: T
) => {
  if (!headersHolder.headers) {
    return headersHolder;
  }

  let rawHeaders = {};

  if (isNodeFetchHeaders(headersHolder.headers)) {
    // @ts-ignore: `node-fetch` typings are missing this method
    rawHeaders = headersHolder.headers.raw();
  } else {
    // @ts-ignore: TypeScript definitions for `Headers` leave out the
    // the `Symbol.iterator` for backwards compat with ES5. :(
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of headersHolder.headers) {
      rawHeaders[key] = value;
    }
  }

  return {
    // type assertion required due to TypeScript bug when spreading generic types
    // https://github.com/Microsoft/TypeScript/pull/13288
    ...(headersHolder as HeadersHolder),
    headers: rawHeaders,
  };
};
