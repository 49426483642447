import * as semver from 'semver';

import { safelyGet } from 'src/common/utils/safelyGet';

// This enum should have 1 entry for each VG Source Type with its own special
// parameters. There are many datasources we just treat as SQL, so that grouping
// is visible here. The user may see 30 different integrations but we only care
// about these 3.
export enum VirtualGraphSource {
  SQL = 'SQL',
  MONGO = 'MONGO',
  APACHE_CASSANDRA = 'APACHE_CASSANDRA',
  ELASTICSEARCH = 'ELASTICSEARCH',
}

export interface VGSpec {
  type: VirtualGraphSource;
  label?: string;
  // labels by min version number
  labels?: Record<string, string>;
  // jdbcDriver and uriMatcher are required if the value of `type` is
  // VirtualGraphSource.SQL
  jdbcDriver?: string;
  minSupportedStardogVersion?: string;
  // used to determine the VirtualGraphMappingType from the VG data returned
  // from the server
  uriMatcher?: RegExp;
  // used in the VG dialog to provide a placeholder for the conn string input field
  uriPlaceholder?: string;
}
// This exists in a separate file to prevent circular dependencies.
export enum VirtualGraphMappingType {
  MONGO = 'MONGO',
  APACHE_HIVE = 'APACHE_HIVE',
  APACHE_CLOUDERA_IMPALA = 'APACHE_CLOUDERA_IMPALA',
  AWS_ATHENA = 'AWS_ATHENA',
  AWS_AURORA_MYSQL = 'AWS_AURORA_MYSQL',
  AWS_AURORA_POSTGRESQL = 'AWS_AURORA_POSTGRESQL',
  AWS_REDSHIFT = 'AWS_REDSHIFT',
  H2 = 'H2',
  DATABRICKS = 'DATABRICKS',
  DERBY = 'DERBY',
  IBM_DB2 = 'IBM_DB2',
  MICROSOFT_SQL_SERVER = 'MICROSOFT_SQL_SERVER',
  MYSQL = 'MYSQL',
  MARIADB = 'MARIADB',
  ORACLE = 'ORACLE',
  POSTGRESQL = 'POSTGRESQL',
  SALESFORCE = 'SALESFORCE',
  SAP_HANA = 'SAP_HANA',
  SPARKSQL = 'SPARKSQL',
  SNOWFLAKE = 'SNOWFLAKE',
  SYBASE_ASE = 'SYBASE_ASE',
  TERADATA = 'TERADATA',
  APACHE_CASSANDRA = 'APACHE_CASSANDRA',
  COSMOS_DB = 'COSMOS_DB',
  BIG_QUERY = 'BIG_QUERY',
  ELASTICSEARCH = 'ELASTICSEARCH',
  GENERIC_SQL = 'GENERIC_SQL',
}

// Map the user-visible VG datasources to our underlying grouping representing
// the different kinds of datasources.
// http://www.benchresources.net/jdbc-driver-list-and-url-for-all-databases/
export const VGSources: {
  [key in keyof typeof VirtualGraphMappingType]: VGSpec;
} = {
  [VirtualGraphMappingType.MONGO]: {
    type: VirtualGraphSource.MONGO,
    label: 'MongoDB',
  },
  [VirtualGraphMappingType.GENERIC_SQL]: {
    type: VirtualGraphSource.SQL,
    label: 'Generic SQL',
    jdbcDriver: '',
  },
  [VirtualGraphMappingType.APACHE_HIVE]: {
    type: VirtualGraphSource.SQL,
    label: 'Apache Hive',
    jdbcDriver: 'org.apache.hive.jdbc.HiveDriver',
    uriMatcher: /^jdbc:hive/,
    // 'jdbc:hive2://<host1>:<port1>,<host2>:<port2>/dbName;initFile=<file>;sess_var_list?hive_conf_list#hive_var_list'
    uriPlaceholder: 'jdbc:hive2://localhost:10000',
  },
  [VirtualGraphMappingType.APACHE_CLOUDERA_IMPALA]: {
    label: 'Apache / Cloudera Impala',
    type: VirtualGraphSource.SQL,
    // TODO there's also com.cloudera.impala.jdbc41.driver
    // is stardog using jdbc4 or 4.1?
    // per https://www.cloudera.com/documentation/other/connectors/impala-jdbc/latest/Cloudera-JDBC-Driver-for-Impala-Install-Guide.pdf
    jdbcDriver: 'com.cloudera.impala.jdbc4.Driver',
    uriMatcher: /^jdbc:impala/,
    // jdbc:impala://Host:Port[/Schema];Property1=Value;Property2=Value;...
    uriPlaceholder: 'jdbc:hive2://myhost.example.com:21050/',
  },
  // https://s3.amazonaws.com/athena-downloads/drivers/JDBC/SimbaAthenaJDBC_2.0.7/docs/Simba+Athena+JDBC+Driver+Install+and+Configuration+Guide.pdf
  [VirtualGraphMappingType.AWS_ATHENA]: {
    type: VirtualGraphSource.SQL,
    label: 'Amazon Athena',
    jdbcDriver: 'com.simba.athena.jdbc.Driver',
    // jdbc:awsathena://athena.[Region].amazonaws.com:443;User=[AccessKey];Password=[SecretKey];S3OutputLocation=[Output];[Property1]=[Value1];[Property2]=[Value2];...
    uriMatcher: /^jdbc:awsathena/,
    uriPlaceholder: 'jdbc:awsathena://AwsRegion=us-east-1;',
  },
  // https://docs.aws.amazon.com/AmazonRDS/latest/AuroraUserGuide/Aurora.Connecting.html
  [VirtualGraphMappingType.AWS_AURORA_MYSQL]: {
    type: VirtualGraphSource.SQL,
    label: 'Amazon Aurora MySQL',
    jdbcDriver: 'com.mysql.jdbc.Driver',
    // Cannot distinguish this from MySQL, and the properties are identical anyway, so while it will be an option in the dropdown after creation it'll just have the type `MySQL`
    // uriMatcher: /^jdbc:mysql/,
    uriPlaceholder:
      'jdbc:mariadb:aurora//mycluster.cluster-123456789012.us-east-1.rds.amazonaws.com:5432',
  },
  [VirtualGraphMappingType.AWS_AURORA_POSTGRESQL]: {
    type: VirtualGraphSource.SQL,
    label: 'Amazon Aurora PostgreSQL',
    jdbcDriver: 'org.postgresql.Driver',
    // Cannot distinguish this from MySQL, and the properties are identical anyway, so while it will be an option in the dropdown after creation it'll just have the type `MySQL`
    // uriMatcher: /^jdbc:postgresql/,
    uriPlaceholder:
      'jdbc:postgresql:aurora//mycluster.cluster-123456789012.us-east-1.rds.amazonaws.com:5432',
  },
  [VirtualGraphMappingType.AWS_REDSHIFT]: {
    type: VirtualGraphSource.SQL,
    label: 'Amazon Redshift',
    // TODO there's a generic class AND version-specific class names depends on jdbc version
    // jdbc4, jdbc41 and jdbc42
    // not sure why
    // https://stardog-union.slack.com/archives/C3TDMCFGF/p1561479078156400
    // they test with 4.2
    jdbcDriver: 'com.amazon.redshift.jdbc.Driver',
    uriMatcher: /^jdbc:redshift/,
    // jdbc:redshift://endpoint:port/database
    uriPlaceholder:
      'jdbc:redshift://examplecluster.abc123xyz789.us-west-2.redshift.amazonaws.com:5439/dev',
  },
  [VirtualGraphMappingType.H2]: {
    type: VirtualGraphSource.SQL,
    label: 'H2',
    jdbcDriver: 'org.h2.Driver',
    uriMatcher: /^jdbc:h2/,
    uriPlaceholder: 'jdbc:h2:tcp://localhost//data/test',
  },
  [VirtualGraphMappingType.DERBY]: {
    type: VirtualGraphSource.SQL,
    label: 'Apache Derby',
    // TODO is there a non-embedded driver?
    // Not 100% sure what this means https://db.apache.org/derby/docs/10.4/devguide/cdevdvlp40653.html
    jdbcDriver: 'org.apache.deerby.jdbc.EmbeddedDriver',
    uriMatcher: /^jdbc:derby/,
    // jdbc:derby:[subsubprotocol:][databaseName][;attribute=value]*
    uriPlaceholder: 'jdbc:derby:db1',
  },
  [VirtualGraphMappingType.IBM_DB2]: {
    type: VirtualGraphSource.SQL,
    label: 'IBM Db2',
    jdbcDriver: 'com.ibm.db2.jcc.DB2Driver',
    uriMatcher: /^jdbc:db2/,
    // jdbc:db2://<HOST>:<PORT>/<DATABASE_NAME>
    uriPlaceholder: 'jdbc:db2://myhost:5021/mydb',
  },
  [VirtualGraphMappingType.MICROSOFT_SQL_SERVER]: {
    type: VirtualGraphSource.SQL,
    label: 'Microsoft SQL Server',
    jdbcDriver: 'com.microsoft.sqlserver.jdbc.SQLServerDriver',
    uriMatcher: /^jdbc:sqlserver/,
    // jdbc:sqlserver://[serverName[\instanceName][:portNumber]][;property=value[;property=value]]
    uriPlaceholder: 'jdbc:sqlserver://localhost',
  },
  [VirtualGraphMappingType.MYSQL]: {
    type: VirtualGraphSource.SQL,
    label: 'MySQL',
    jdbcDriver: 'com.mysql.jdbc.Driver', // i saw a warning that there's a newer one
    uriMatcher: /^jdbc:mysql/,
    // protocol//[hosts][/database][?properties]
    uriPlaceholder: 'jdbc:mysql://localhost/database',
  },
  [VirtualGraphMappingType.MARIADB]: {
    type: VirtualGraphSource.SQL,
    label: 'MariaDB',
    jdbcDriver: 'com.mariadb.jdbc.Driver',
    uriMatcher: /^jdbc:mariadb/,
    // jdbc:(mysql|mariadb):[replication:|loadbalance:|sequential:|aurora:]//<hostDescription>[,<hostDescription>...]/[database][?<key1>=<value1>[&<key2>=<value2>]]
    uriPlaceholder: 'jdbc:mariadb://localhost:3306/DB',
  },
  [VirtualGraphMappingType.ORACLE]: {
    type: VirtualGraphSource.SQL,
    label: 'Oracle Database',
    jdbcDriver: 'oracle.jdbc.driver.OracleDriver',
    uriMatcher: /^jdbc:oracle/,
    // jdbc:oracle:<drivertype>:@<database>
    uriPlaceholder: 'jdbc:oracle:thin:@myhost:1521:orcl',
  },
  [VirtualGraphMappingType.POSTGRESQL]: {
    type: VirtualGraphSource.SQL,
    label: 'PostgreSQL',
    jdbcDriver: 'org.postgresql.Driver',
    uriMatcher: /^jdbc:postgresql/,
    // jdbc:postgresql://host:port/database
    uriPlaceholder: 'jdbc:postgresql://localhost/test',
  },
  [VirtualGraphMappingType.SALESFORCE]: {
    type: VirtualGraphSource.SQL,
    label: 'Salesforce',
    jdbcDriver: 'com.simba.salesforce.jdbc42.Driver',
    minSupportedStardogVersion: '7.2.0',
    uriMatcher: /^jdbc:salesforce/,
    // jdbc:sap://<server>:<port>[/?<options>]
    uriPlaceholder:
      'jdbc:salesforce://localhost/test;Endpoint=https://test.salesforce.com/services/Soap/u/46.0',
  },
  // https://help.sap.com/viewer/0eec0d68141541d1b07893a39944924e/2.0.00/en-US/ff15928cf5594d78b841fbbe649f04b4.html
  [VirtualGraphMappingType.SAP_HANA]: {
    type: VirtualGraphSource.SQL,
    label: 'SAP HANA',
    jdbcDriver: 'com.sap.db.jdbc.Driver',
    uriMatcher: /^jdbc:sap/,
    // jdbc:sap://<server>:<port>[/?<options>]
    uriPlaceholder: 'jdbc:sap://myServer:30015/',
  },
  [VirtualGraphMappingType.SNOWFLAKE]: {
    type: VirtualGraphSource.SQL,
    label: 'Snowflake',
    jdbcDriver: 'net.snowflake.client.jdbc.SnowflakeDriver',
    // jdbc:snowflake://[account_identifier].snowflakecomputing.com/?db=[database_name]
    minSupportedStardogVersion: '7.7.1',
    uriMatcher: /^jdbc:snowflake/,
    uriPlaceholder:
      'jdbc:snowflake://myId123.us-east-1.snowflakecomputing.com/?db=myDb',
  },
  [VirtualGraphMappingType.SPARKSQL]: {
    type: VirtualGraphSource.SQL,
    labels: {
      '0.0.0': 'Databricks and Spark SQL',
      '8.1.0': 'Simba Spark SQL',
    },
    jdbcDriver: 'com.simba.spark.jdbc.Driver',
    uriMatcher: /^jdbc:spark/,
    uriPlaceholder: 'jdbc:spark://<instance hostname>:10000/<database>',
  },
  [VirtualGraphMappingType.DATABRICKS]: {
    type: VirtualGraphSource.SQL,
    label: 'Databricks Spark SQL',
    jdbcDriver: 'com.databricks.client.jdbc.Driver',
    minSupportedStardogVersion: '8.1.0',
    uriMatcher: /^jdbc:databricks/,
    uriPlaceholder:
      'jdbc:databricks://<instance hostname>:443;HttpPath=<Http Path>',
  },
  [VirtualGraphMappingType.SYBASE_ASE]: {
    type: VirtualGraphSource.SQL,
    label: 'SAP (Sybase) ASE',
    jdbcDriver: 'com.sybase.jdbc4.jdbc.SybDriver',
    uriMatcher: /^jdbc:sybase/,
    //  jdbc:jtds:sybase://<host>[:<port>][/<database>]
    uriPlaceholder: 'jdbc:jtds:sybase://localhost:5000/myDB',
  },
  [VirtualGraphMappingType.TERADATA]: {
    type: VirtualGraphSource.SQL,
    label: 'Teradata',
    jdbcDriver: 'com.teradata.jdbc.TeraDriver',
    uriMatcher: /^jdbc:teradata/,
    // jdbc:teradata://DatabaseServerName/ParameterName=Value,ParameterName=Value
    uriPlaceholder: 'jdbc:teradata://mysystem/',
  },
  [VirtualGraphMappingType.APACHE_CASSANDRA]: {
    type: VirtualGraphSource.APACHE_CASSANDRA,
    label: 'Apache Cassandra',
  },
  [VirtualGraphMappingType.COSMOS_DB]: {
    type: VirtualGraphSource.SQL,
    label: 'Azure Cosmos DB',
    jdbcDriver: 'cdata.jdbc.cosmosdb.CosmosDBDriver',
    uriMatcher: /^jdbc:cosmosdb/,
    uriPlaceholder:
      'jdbc:cosmosdb:Server=anhohmongo.documents.azure.com;Port=10255;Database=test;',
  },
  // https://www.simba.com/products/BigQuery/doc/JDBC_InstallGuide/content/jdbc/bq/using/connectionurl.htm
  [VirtualGraphMappingType.BIG_QUERY]: {
    type: VirtualGraphSource.SQL,
    label: 'Google BigQuery',
    jdbcDriver: 'com.simba.googlebigquery.jdbc42.Driver',
    uriMatcher: /^jdbc:bigquery/,
    uriPlaceholder:
      // jdbc:bigquery://[Host]:[Port];ProjectId=[Project];OAuthType=[AuthValue];[Property1]=[Value1];...
      'jdbc:bigquery://https://bigquery.googleapis.com/bigquery/v2;ProjectId=myproject;DefaultDataset=mydataset;OAuthType=0;OAuthServiceAcctEmail=vgtestaccount@myacct.iam.gserviceaccount.com;OAuthPvtKeyPath=/path/creds/bq_pk.json;',
  },
  [VirtualGraphMappingType.ELASTICSEARCH]: {
    type: VirtualGraphSource.ELASTICSEARCH,
    label: 'Elasticsearch',
    minSupportedStardogVersion: '7.0.1',
  },
};

export const vgSourceTypesLabelText = (
  vgType: VirtualGraphMappingType,
  stardogVersion: string
) => {
  const label = safelyGet(VGSources, [vgType, 'label']);
  if (label) {
    return label;
  }

  const labels = safelyGet(VGSources, [vgType, 'labels']);
  if (labels) {
    const versions = Object.keys(labels).map((str) => semver.coerce(str));
    const versionMatch = semver.maxSatisfying(versions, `<=${stardogVersion}`);
    const labelMatch = labels[versionMatch.toString()];
    if (labelMatch) {
      return labelMatch;
    }
  }

  return vgType as string;
};
