import { Colors, H4, Icon, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';

import { LanguageId } from 'src/common/constants/LanguageId';
import { TEST_IDS } from 'src/common/constants/testIds';
import { getAbbreviation } from 'src/common/utils/caches/abbreviations';
import { ellipsize } from 'src/common/utils/ellipsize';

export const components = {
  cloudRedirectBanner: {
    content: (
      <>
        <H4>
          Studio access is moving to Stardog Cloud. Create your free Stardog
          Cloud account today!
          <Tooltip content="Create a Stardog Cloud account">
            <a
              data-testid={TEST_IDS.cloudRedirectBanner.cloudLink}
              href="https://cloud.stardog.com"
              rel="noreferrer"
              target="_blank"
            >
              <Icon color={Colors.WHITE} icon={IconNames.SHARE} />
            </a>
          </Tooltip>
        </H4>
        <div>
          Click
          <a
            data-testid={TEST_IDS.cloudRedirectBanner.infoLink}
            href="https://info.stardog.com/studio-explorer-eosl"
            rel="noreferrer"
            target="_blank"
          >
            here
          </a>
          for more information.
        </div>
      </>
    ),
  },
  defaults: { error: 'Something went wrong.' },
  databases: {
    properties: {
      sensitiveProperties: {
        addButton: 'Add',
        addPropertyItem: (name: string) => `Add ${name}`,
        groupColumn: 'Groups',
        invalidCharacters:
          'The sensitive property group name is invalid. "=" is not allowed.',
        propertyColumn: 'Properties',
        propertyPlaceholder: 'Add properties',
        usedProperty: (name: string) => `Property "${name}" already exists.`,
        usedGroup: (name: string) => `Group "${name}" already exists.`,
      },
    },
  },
  models: {
    editDisabledMessage: {
      multipleGraphs: (selectedSchemaName: string) =>
        `Editing is disabled for '${selectedSchemaName}' because it is in multiple named graphs.`,
      noNamespace: (selectedSchemaName: string) =>
        `Editing is disabled for '${selectedSchemaName}' because a namespace has not been confirmed.`,
      specialGraphs: (selectedSchemaName: string) =>
        `Editing is disabled for '${selectedSchemaName}' because it is in a special named graph.`,
    },
  },
  namedGraphSelector: {
    buttonText: (namedGraphs: string[], namespaces: string[]) =>
      namedGraphs.length === 1
        ? namedGraphs.map((ng) => getAbbreviation(ng, namespaces)).join(', ')
        : `${namedGraphs.length} selected`,
    noResults: 'No Results',
    placeholder: 'Search or create...',
  },
  notifications: {
    error: {
      copied: 'Copied!',
      copy: 'Copy',
    },
  },
  statusBar: {
    connectionStatus: {
      connected: ({
        endpoint,
        name,
        username,
      }: {
        endpoint: string;
        name: string;
        username: string;
      }) => {
        let result = username ? `${username}@${endpoint}` : endpoint;
        if (name) {
          result = `${name} ${result}`;
        }
        return result;
      },
      notConnected: 'Not connected',
    },
    keyboardShortcuts: 'Keyboard Shortcuts',
    serverVersion: (isConnected: boolean, stardogVersion: string) =>
      isConnected ? `Server Version: ${stardogVersion}` : '',
  },
  storedQueries: {
    refresh: 'Refresh List',
    search: 'Search Stored Queries ...',
    selectDatabase: 'Select Database',
  },
  voicebox: {
    title: 'Voicebox',
    initialUserPrompt:
      'What are the names of the top 5 most expensive products by msrp?',
  },
  workspace: {
    controlBar: {
      databaseSelector: {
        search: 'Search Databases...',
        select: 'Select Database',
      },
      executeButton: {
        run: 'Run',
        stop: 'Stop',
        tooltip: {
          run: 'Run',
          stop: 'Stop',
          noQuery: 'Write a query',
          selectDb: 'Select a database',
          pending: 'Waiting for an action to complete',
          pendingDrop:
            'Selected database is being dropped. Please select another.',
        },
      },
      namedGraphSelector: {
        tooltip: {
          default: 'Set Named Graph',
          pending: 'Waiting for an action to complete',
          selectDb: 'Select a database',
          unconnected: 'Connect to a Stardog server',
        },
      },
      search: {
        placeholder: (db: string) => `Search ${db}...`,
        tooltip: {
          default: 'Search',
          noDb: 'Select a database.',
          noLucene: (db: string) =>
            `Enable "Semantic Search" for ${ellipsize(
              db
            )} in the Databases section.`,
        },
      },
      showPlanButton: {
        profiler: {
          cancelQueryQuestion: 'Are you sure you want to cancel this query?',
          run: 'Run Profiler',
          stop: 'Stop Profiler',
        },
        text: 'Show Plan',
        tooltip: {
          default: 'Show Plan',
          offline: 'The database is offline',
          noQuery: (languageId: LanguageId) =>
            `Write some ${
              languageId === LanguageId.GRAPHQL ? 'GraphQL' : 'SPARQL'
            }`,
          pending: 'Waiting for an action to complete',
          pendingDrop:
            'Selected database is being dropped. Please select another.',
          selectDb: 'Select a database',
          stop: 'Stop',
          unconnected: 'Connect to a Stardog server',
        },
      },
    },
  },
};

export const errors = {
  validation: {
    iri: {
      semicolon: 'The IRI must include a semicolon ([:]).',
      specialCharacters: 'The IRI cannot have special characters or spaces.',
      startsWith: 'The IRI must start with an alphabet character ([A-Za-z]).',
    },
    model: {
      alreadyExists: 'A model with this name already exists.',
      specialCharacters:
        'The model name cannot have special characters or spaces',
      startsWith:
        'The model name must start with an alphabet character ([A-Za-z]).',
    },
  },
};
