export const colors = {
  blueprintjs: {
    // @blueprintjs/.../_colors.scss
    black: '#10161a',
    blue1: '#0e5a8a',
    blue2: '#106ba3',
    blue5: '#48aff0',
    gray3: '#8a9ba8',
    gray5: '#bfccd6',
    lightGray2: '#d8e1e8',
    lightGray3: '#e1e8eD',
    lightGray4: '#ebf1f5',
    lightGray5: '#f5f8fa',
    darkGray1: '#182026',
    darkGray2: '#202b33',
    darkGray3: '#293742',
    darkGray4: '#30404d',
    darkGray5: '#394b59',
    green5: '#3dcc91',
    red5: '#ff7373',
  },
  black: '#1e1e1e',
  white: '#fffffe',
  darkGray1: 'rgb(30,30,30)',
  darkGray2: '#1f2427',
  inherit: 'inherit',
};
