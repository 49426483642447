import { HTTP } from 'stardog';

import {
  ConnectionActionType,
  connectionActionCreators,
  connectionStardogRequestDispatchers,
} from 'src/common/actions/connection/connectionActionCreators';
import { upgradeConnection } from 'src/common/actions/connection/upgradeConnection';
import { requestAvailableOptions } from 'src/common/actions/databases/action-creators/requestAvailableOptions';
import { requestDetailsForDatabase } from 'src/common/actions/databases/action-creators/requestDetailsForDatabase';
import { requestDatabases } from 'src/common/actions/databases/action-creators/stardog-api/requestDatabases';
import { DatabasesActionType } from 'src/common/actions/databases/databasesActionCreators';
import { notebookActionCreators } from 'src/common/actions/notebook/notebookActionCreators';
import { isBody } from 'src/common/actions/request/types';
import {
  SecurityActionType,
  securityStardogRequestDispatchers,
} from 'src/common/actions/security/securityActionCreators';
import {
  StudioStateGetter,
  StudioThunkDispatch,
} from 'src/common/actions/StudioAction';
import { virtualGraphsActionCreators } from 'src/common/actions/virtualGraphs/virtualGraphsActionCreators';
import { isFeatureSupported } from 'src/common/reducers/features';
import { saveStore } from 'src/common/store';
import {
  Connection,
  SavedConnection,
  UserConfig,
} from 'src/common/store/connection/ConnectionState';

const shouldResetState = (
  prevConnection: Connection,
  nextConnection: Partial<Connection>
) =>
  !nextConnection ||
  !prevConnection ||
  prevConnection.endpoint !== nextConnection.endpoint ||
  prevConnection.username !== nextConnection.username;

// NOTE `config?` is optional because renderer.tsx assume the pre-existing
// persisted connection is used by default and pass `null` as the argument
export const setConnection =
  (
    config?: UserConfig,
    shouldSaveConnection?: boolean
  ): ((
    dispatch: StudioThunkDispatch,
    getState: StudioStateGetter
  ) => ReturnType<
    typeof connectionStardogRequestDispatchers.validateCredentials
  > | null) =>
  async (dispatch: StudioThunkDispatch, getState: StudioStateGetter) => {
    const { connection, notebook } = getState();
    const { current: currentConnection } = connection;
    const userConfig = config || currentConnection;
    // always reset this
    userConfig.stardogVersion = undefined;

    dispatch(connectionActionCreators.setConnectionAttempt(userConfig));

    const upgradedConnection = await upgradeConnection(userConfig);

    const isMissingPasswordAndToken = Boolean(
      !upgradedConnection.password && !upgradedConnection.token
    );

    if (upgradedConnection.username && isMissingPasswordAndToken) {
      // If we were unable to get a token or we are missing a password we know
      // that the validate will fail. Instead we need to prompt the user to
      // enter their password again.
      dispatch(
        connectionActionCreators.setConnectionSignedOut(upgradedConnection)
      );
      return null;
    }

    if (shouldResetState(currentConnection, upgradedConnection)) {
      dispatch(virtualGraphsActionCreators.resetVirtualGraphs());
      dispatch(notebookActionCreators.resetSelectedDatabases([]));
    }

    const validateResponse =
      await connectionStardogRequestDispatchers.validateCredentials(
        upgradedConnection
      );

    if (
      validateResponse.type ===
      ConnectionActionType.VALIDATE_CREDENTIALS_SUCCESS
    ) {
      // this must be called first to retrieve and set the server version
      // since requestAvailableOptions and requestDetailsForDatabase are dependent on such
      const statusResponse =
        await connectionStardogRequestDispatchers.requestServerStatus({
          userConfig: upgradedConnection,
          params: { databases: false },
        });

      let stardogVersion: string;
      let stardogLicenseType: string;
      let isSqlServerEnabled = false;
      if (
        statusResponse.type ===
        ConnectionActionType.REQUEST_SERVER_STATUS_SUCCESS
      ) {
        stardogVersion =
          statusResponse.payload.response?.body?.['dbms.version']?.value;
        stardogLicenseType =
          statusResponse.payload.response?.body?.['dbms.license.type']?.value;
        isSqlServerEnabled =
          statusResponse.payload.response?.body?.['dbms.sql.server.enabled']
            ?.value === 'true';

        if (
          !upgradedConnection.username &&
          isFeatureSupported('ephemeralLogin', stardogVersion)
        ) {
          const userResponse =
            await securityStardogRequestDispatchers.requestCurrentUser(
              upgradedConnection
            );
          if (
            userResponse.type ===
            SecurityActionType.REQUEST_CURRENT_USER_SUCCESS
          ) {
            upgradedConnection.username = userResponse?.payload?.response?.body;
          }
        }
      }
      dispatch(
        connectionActionCreators.setConnectionSuccess(
          {
            ...upgradedConnection,
            isSqlServerEnabled,
            stardogVersion,
            stardogLicenseType,
          },
          validateResponse.payload.response
        )
      );

      // try to get the current user's permissions
      const userDetails =
        await securityStardogRequestDispatchers.requestDetailsForUser({
          username: upgradedConnection.username,
          shouldNotDispatch: true,
        });

      // if we got the user permissions, add them to the connection state
      if (
        isBody(userDetails.payload.response) &&
        userDetails.payload.response.ok
      ) {
        const permissions =
          userDetails.payload.response?.body?.permissions || [];
        dispatch(connectionActionCreators.setCurrentPermissions(permissions));
      }

      if (shouldSaveConnection) {
        dispatch(
          connectionActionCreators.addSavedConnection(
            upgradedConnection as SavedConnection
          )
        );
      }
      dispatch(requestAvailableOptions());
      dispatch(requestDatabases()).then((res) => {
        const newDatabases =
          res.type === DatabasesActionType.REQUEST_DATABASES_SUCCESS
            ? (res.payload.response as HTTP.Body).body.databases
            : [];
        const activeNoteDatabase =
          notebook?.notes?.[notebook.activeNoteId]?.editorSettings
            ?.activeDatabase || null;
        // Re-request database details for active note's db, because these are cleared on SET_CONNECTION_SUCCESS
        if (newDatabases.includes(activeNoteDatabase)) {
          dispatch(requestDetailsForDatabase(activeNoteDatabase));
        }
        // TODO re-request stored queries because these are cleared on SET_CONNECTION_SUCCESS
        // I believe this is handled by the component right now
        // Ensure db selectors in notes are only set to known DBs
        dispatch(notebookActionCreators.resetSelectedDatabases(newDatabases));
      });
    } else {
      dispatch(
        connectionActionCreators.setConnectionFailure(
          upgradedConnection,
          validateResponse.payload.response
        )
      );
    }

    await saveStore();
    return validateResponse;
  };
