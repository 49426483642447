import * as React from 'react';
import { connect } from 'react-redux';
import { noop } from 'vet-bones/bones/utils';

import { StudioThunkDispatch } from 'src/common/actions/StudioAction';
import { VirtualGraphsAction } from 'src/common/actions/virtualGraphs/virtualGraphsActionCreators';
import { LanguageId } from 'src/common/constants/LanguageId';
import { StatusBarLanguageMenu } from 'src/common/containers/App/components/StatusBarLanguageMenu';
import { safelyGet } from 'src/common/utils/safelyGet';
import { ReduxState } from 'src/types';

const mapStateToProps = ({ virtualGraphs }: ReduxState) => {
  const { selectedVgId } = virtualGraphs;
  const languageIdForSelectedVg: LanguageId = safelyGet(
    virtualGraphs,
    ['vgsById', selectedVgId, 'languageId'],
    LanguageId.SMS2
  );

  return {
    languageIdForSelectedVg,
    selectedVgId,
  };
};

const mapDispatchToProps = (
  _: StudioThunkDispatch<VirtualGraphsAction>
) => ({});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export const UnconnectedVirtualGraphsStatusBar = (
  props: StateProps & DispatchProps
) => {
  if (props.selectedVgId) {
    return (
      <StatusBarLanguageMenu
        languageIds={['SMS2']}
        onChange={noop}
        value={LanguageId.SMS2}
      />
    );
  }
  return null;
};

export const VirtualGraphsStatusBar = connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(UnconnectedVirtualGraphsStatusBar);
