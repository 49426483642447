import clonedeep from 'lodash.clonedeep';
import moment from 'moment';
import { isNumber } from 'util';
import uuid from 'uuid';
import { alphaSort, isNotEqual } from 'vet-bones/bones/utils';

import {
  ConnectionAction,
  ConnectionActionType,
} from 'src/common/actions/connection/connectionActionCreators';
import {
  DatabasesAction,
  DatabasesActionType,
} from 'src/common/actions/databases/databasesActionCreators';
import {
  ModelsAction,
  ModelsActionType,
} from 'src/common/actions/models/modelsActionCreators';
import {
  PreferencesAction,
  PreferencesActionType,
} from 'src/common/actions/preferences/preferencesActionCreators';
import {
  SchemasAction,
  SchemasActionType,
} from 'src/common/actions/schemas/schemasActionCreators';
import {
  OWL_DATATYPE_PROPERTY_URI,
  OWL_OBJECT_PROPERTY_URI,
  OWL_THING_URI,
  OWL_URI,
  RDFS_URI,
  RDF_URI,
} from 'src/common/constants/uris';
import {
  DatabaseDetails,
  DbProperties,
  getReasoningSchemas,
} from 'src/common/store/databases/DatabasesState';
import {
  ConstraintsEditorSettings,
  ConstraintsReportSettings,
  MINIMUM_CONSTRAINTS_REPORT_HEIGHT,
} from 'src/common/store/models/ModelsConstraintsState';
import {
  CreateModelDialogType,
  DenormalizedModelsItem,
  DenormalizedModelsItems,
  ModelsItemDataFields,
  ModelsItemLocalData,
  ModelsItemLocalDataValues,
  ModelsNamedGraphsBySchema,
  ModelsState,
  ModelsTabIds,
  NormalizedModelsItem,
  NormalizedModelsItems,
  defaultMosaicState,
  getDefaultModelConstraintsData,
  getDefaultModelSchemaTabData,
  getDefaultModelsTextEditorData,
} from 'src/common/store/models/ModelsState';
import {
  RawJsonLdData,
  RawJsonLdResult,
} from 'src/common/store/visualization/VisualizationState';
import {
  TreeViewItemMap,
  getTreeViewData,
} from 'src/common/utils/getTreeViewData';
import { addLabelsToItems } from 'src/common/utils/labels/addLabelsToItems';
import { getLabelFromBindingResults } from 'src/common/utils/labels/getLabelFromBindingResults';
import { Label } from 'src/common/utils/labels/types';
import { RawModelsItemBinding } from 'src/common/utils/queryBuilders/models';

const alphaSorter = alphaSort();

function areItemsDirty(items: NormalizedModelsItems) {
  return Object.values(items).some((item) => item.isDirty);
}

function saveNormalizedItem(
  acc: NormalizedModelsItems,
  [iri, item]: [string, NormalizedModelsItem]
) {
  if (item.isDirty) {
    const {
      labelsByPredicate,
      labelsByPredicateId,
      parents,
      preferredLabel,
      ...localData
    } = item.localData || ({} as ModelsItemLocalData);

    const updatedItem: NormalizedModelsItem = {
      ...item,
      children: [],
      data: localData && Object.keys(localData).length ? localData : item.data,
      isDirty: false,
      isNew: false,
      labelsByPredicate:
        (item.localData ? labelsByPredicate : item.labelsByPredicate) || {},
      labelsByPredicateId: uuid(),
      parents: (item.localData ? parents : item.parents) || [],
      preferredLabel:
        (item.localData ? preferredLabel : item.preferredLabel) || null,
    };

    acc[iri] = updatedItem;
  } else {
    acc[iri] = item;
  }
  return acc;
}

function denormalizeResults(
  iris: string[],
  resultsByIri: TreeViewItemMap<NormalizedModelsItem>,
  visited: Set<string> = new Set([])
) {
  const newResults: DenormalizedModelsItems = {};
  iris.forEach((iri) => {
    const newVisitedSet = new Set(visited);
    newVisitedSet.add(iri);

    const childArr = resultsByIri[iri].children;
    const expandableChildren = [];
    const nonexpandableChildren = [];

    childArr.forEach((childIri) => {
      if (newVisitedSet.has(childIri)) {
        nonexpandableChildren.push(childIri);
      } else {
        expandableChildren.push(childIri);
      }
    });

    const children = denormalizeResults(
      expandableChildren,
      resultsByIri,
      newVisitedSet
    );

    nonexpandableChildren.forEach((childIri) => {
      children[childIri] = {
        children: {},
        id: childIri,
        isCircular: true,
        path: [...Array.from(newVisitedSet), childIri],
      };
    });

    newResults[iri] = {
      children,
      id: iri,
      isCircular: false,
      path: Array.from(newVisitedSet),
    };
  });

  return newResults;
}

function createResultHierarchyFromBindings(
  rawBindings: RawModelsItemBinding[],
  rootIri: string
) {
  const itemsByIri: NormalizedModelsItems = {};
  rawBindings.forEach((binding) => {
    if (!binding.subject || !binding.subject.value) {
      return;
    }
    const { label: subjectLabel, value: iri } = getLabelFromBindingResults(
      binding.subject
    );

    // do not include rdf, rdfs, and owl URIs in the classes list
    if (
      iri.startsWith(RDF_URI) ||
      iri.startsWith(RDFS_URI) ||
      iri.startsWith(OWL_URI)
    ) {
      return;
    }

    if (!itemsByIri[iri]) {
      itemsByIri[iri] = {
        children: [],
        data: {
          comment: '',
          domain: [],
          range: [],
        },
        id: iri,
        isDirty: false,
        isNew: false,
        isPendingData: false,
        label: subjectLabel,
        labelsByPredicate: {},
        labelsByPredicateId: uuid(),
        localData: undefined,
        parents: [],
        preferredLabel: null,
      };
    }

    addLabelsToItems(iri, binding.label, binding.labelPredicate, itemsByIri);

    if (
      binding.parent &&
      binding.parent.value &&
      !itemsByIri[iri].parents.includes(binding.parent.value)
    ) {
      itemsByIri[iri].parents.push(binding.parent.value);
    }

    if (binding.comment) {
      const comment = getLabelFromBindingResults(binding.comment);
      itemsByIri[iri].data.comment = comment.label || comment.value;
    }
    if (
      binding.domain &&
      !itemsByIri[iri].data.domain.includes(binding.domain.value)
    ) {
      itemsByIri[iri].data.domain.push(binding.domain.value);
    }
    if (
      binding.range &&
      !itemsByIri[iri].data.range.includes(binding.range.value)
    ) {
      itemsByIri[iri].data.range.push(binding.range.value);
    }
  });

  Object.values(itemsByIri).forEach((item) => {
    const {
      labelsByPredicate,
      labelsByPredicateId,
      parents,
      preferredLabel,
      data,
    } = item;
    item.localData = {
      labelsByPredicateId,
      labelsByPredicate: clonedeep(labelsByPredicate),
      parents: parents.sort(alphaSorter),
      preferredLabel,
      comment: data.comment,
      domain: data.domain.sort(alphaSorter),
      range: data.range.sort(alphaSorter),
    };
  });

  return updateResultsHierarchy(itemsByIri, rootIri);
}

function updateResultsHierarchy(
  oldResults: NormalizedModelsItems,
  rootIri: string,
  resultToAdd?: NormalizedModelsItem
) {
  const itemsByIri = { ...oldResults };

  if (resultToAdd && !itemsByIri[resultToAdd.id]) {
    itemsByIri[resultToAdd.id] = resultToAdd;
  }

  const { roots, treeView } = getTreeViewData(
    Object.values(itemsByIri),
    rootIri
  );
  const itemHierarchy = denormalizeResults(roots, treeView);
  Object.values(treeView).forEach((item) => {
    itemsByIri[item.id].children = item.children;
    itemsByIri[item.id].parents = item.parents;
  });

  return { itemHierarchy, itemsByIri };
}

function filterIrisFromLocalData(
  item: NormalizedModelsItem,
  field:
    | ModelsItemDataFields.domain
    | ModelsItemDataFields.range
    | ModelsItemDataFields.parents,
  deletedIris?: Set<string>
) {
  if (!item.localData || !deletedIris || !deletedIris.size) {
    return item;
  }

  item.localData[field] = item.localData[field].filter((iri) => {
    const isDeleted = deletedIris.has(iri);
    if (isDeleted) {
      item.isDirty = true;
    }
    return !isDeleted;
  });

  return item;
}

function getUpdatedItem({
  prevItem,
  localData,
  deletedParents,
  deletedDomain,
  deletedRange,
}: {
  prevItem: NormalizedModelsItem;
  localData?: Partial<ModelsItemLocalData>;
  deletedParents?: Set<string>;
  deletedDomain?: Set<string>;
  deletedRange?: Set<string>;
}): NormalizedModelsItem {
  const item: NormalizedModelsItem = { ...prevItem };

  if (localData) {
    item.localData = {
      labelsByPredicateId: item.labelsByPredicateId,
      labelsByPredicate: clonedeep(item.labelsByPredicate),
      parents: item.parents,
      preferredLabel: item.preferredLabel,
      ...item.localData,
      ...localData,
    };

    if (localData.labelsByPredicate || localData.preferredLabel !== undefined) {
      item.localData.labelsByPredicateId = uuid();
    }
    item.isDirty = true;
  }

  filterIrisFromLocalData(item, ModelsItemDataFields.parents, deletedParents);
  filterIrisFromLocalData(item, ModelsItemDataFields.domain, deletedDomain);
  filterIrisFromLocalData(item, ModelsItemDataFields.range, deletedRange);

  if (item.isDirty) {
    return item;
  }

  Object.keys(ModelsItemDataFields).every((field) => {
    const localValue: ModelsItemLocalDataValues = item.localData[field];
    const orderedLocal = Array.isArray(localValue)
      ? localValue.sort()
      : localValue;
    const data = item[field] !== undefined ? item[field] : item.data[field];
    const orderedData = Array.isArray(data) ? data.sort() : data;
    if (isNotEqual(orderedLocal, orderedData)) {
      item.isDirty = true;
      return false;
    }
    return true;
  });

  return item;
}

function createNewItem(
  iri: string,
  parentIri: string,
  label: Label,
  predicate: string
): NormalizedModelsItem {
  const labelsByPredicate = {
    [predicate]: {
      [label.value]: {
        ...label,
      },
    },
  };
  const parents = parentIri ? [parentIri] : [];
  const labelsByPredicateId = uuid();

  return {
    children: [],
    id: iri,
    isDirty: true,
    isNew: true,
    isPendingData: false,
    label: '',
    labelsByPredicate,
    labelsByPredicateId,
    localData: {
      comment: '',
      labelsByPredicate,
      labelsByPredicateId,
      parents,
      preferredLabel: label,
      domain: [],
      range: [],
    },
    parents,
    preferredLabel: label,
  };
}

function collectAllDescendants(
  iri: string,
  itemsByIri: NormalizedModelsItems,
  results: Set<string> = new Set()
) {
  if (results.has(iri)) {
    return results;
  }

  results.add(iri);
  const item = itemsByIri[iri];
  if (item && item.children.length) {
    item.children.forEach((childIri) =>
      collectAllDescendants(childIri, itemsByIri, results)
    );
  }

  return results;
}

function deleteItemsByIri(
  itemsByIri: NormalizedModelsItems,
  deletedParents: Set<string>,
  deletedDomain?: Set<string>,
  deletedRange?: Set<string>
) {
  return Object.entries(itemsByIri).reduce((acc, [iri, item]) => {
    if (!deletedParents.has(iri)) {
      acc[iri] = getUpdatedItem({
        prevItem: item,
        deletedParents,
        deletedDomain,
        deletedRange,
      });
    }
    return acc;
  }, {} as NormalizedModelsItems);
}

function getClearedModelsState(prevState: ModelsState) {
  return {
    ...prevState,
    attributes: getDefaultModelSchemaTabData(),
    classes: getDefaultModelSchemaTabData(),
    relationships: getDefaultModelSchemaTabData(),
    constraints: getDefaultModelConstraintsData(),
    textEditor: getDefaultModelsTextEditorData(),
    visualizationSettings: undefined,
  };
}

function getUpdatedNamedGraphsBySchema(
  properties: DbProperties,
  stardogSupportsVirtualTransparency: boolean
): ModelsNamedGraphsBySchema {
  return getReasoningSchemas(
    {
      properties,
    } as DatabaseDetails,
    stardogSupportsVirtualTransparency
  );
}

function addNodePathLineageToSet(oldSet: Set<string>, path: string[]) {
  const newSet = new Set(oldSet);
  if (!path.length) {
    return newSet;
  }
  for (let i = path.length; i >= 0; i -= 1) {
    const pathStr = path.slice(0, i).join('-');
    newSet.add(pathStr);
  }
  return newSet;
}

function getImmediatePath(hierarchy: DenormalizedModelsItems, iri: string) {
  const items = Object.values(hierarchy);
  let path: string[];
  for (let i = 0; i < items.length; i += 1) {
    path = getRelevantPathIfExists(items[i], iri);
    if (path) {
      break;
    }
  }
  return path || [];
}

function getRelevantPathIfExists(item: DenormalizedModelsItem, iri: string) {
  let path: string[];
  const children = Object.values(item.children);
  if (item.id === iri) {
    return [iri];
  }
  if (!children.length) {
    return null;
  }
  for (let i = 0; i < children.length; i += 1) {
    path = getRelevantPathIfExists(children[i], iri);
    if (path) {
      break;
    }
  }
  return path ? [item.id, ...path] : null;
}

function getStateWithUpdatedConstraintsProperties(
  prevState: ModelsState,
  partialUpdatedEditorSettings: Partial<ConstraintsEditorSettings>,
  partialUpdatedReportSettings: Partial<ConstraintsReportSettings>
): ModelsState {
  return {
    ...prevState,
    constraints: {
      ...prevState.constraints,
      editorSettings: {
        ...prevState.constraints.editorSettings,
        ...partialUpdatedEditorSettings,
      },
      reportSettings: {
        ...prevState.constraints.reportSettings,
        ...partialUpdatedReportSettings,
      },
    },
  };
}

const initialState = new ModelsState();

export const modelsReducer = (
  prevState: ModelsState = initialState,
  action:
    | ModelsAction
    | ConnectionAction
    | DatabasesAction
    | PreferencesAction
    | SchemasAction
): ModelsState => {
  switch (action.type) {
    case ConnectionActionType.SET_CONNECTION_SUCCESS: {
      return initialState;
    }
    case DatabasesActionType.REQUEST_DETAILS_FOR_DATABASE_SUCCESS: {
      const id = action.payload.args[0];
      if (id !== prevState.selectedDatabaseId) {
        return prevState;
      }

      const properties: DbProperties = action.payload?.response?.body || {};
      const namedGraphsBySchema = getUpdatedNamedGraphsBySchema(
        properties,
        action.payload.action.stardogSupportsVirtualTransparency
      );

      const nextState = {
        ...prevState,
        namedGraphsBySchema,
      };

      if (!namedGraphsBySchema[nextState.selectedSchemaName]) {
        return getClearedModelsState({ ...nextState, selectedSchemaName: '' });
      }
      return nextState;
    }
    case DatabasesActionType.UPDATE_DATABASE_DETAILS_SUCCESS: {
      const id = action.payload.args[0];
      if (id !== prevState.selectedDatabaseId) {
        return prevState;
      }

      const properties: DbProperties = action.payload.args[1] || {};
      const namedGraphsBySchema = getUpdatedNamedGraphsBySchema(
        {
          ...action.payload.action.prevDetails,
          ...properties,
        },
        action.payload.action.stardogSupportsVirtualTransparency
      );

      const nextState = {
        ...prevState,
        namedGraphsBySchema,
      };

      if (!namedGraphsBySchema[nextState.selectedSchemaName]) {
        return getClearedModelsState({ ...nextState, selectedSchemaName: '' });
      }
      return nextState;
    }
    case DatabasesActionType.DROP_DATABASE_SUCCESS: {
      const id = action.payload.args[0];
      if (id !== prevState.selectedDatabaseId) {
        return prevState;
      }
      return getClearedModelsState({
        ...prevState,
        namedGraphsBySchema: {},
        selectedDatabaseId: '',
        selectedSchemaName: '',
      });
    }
    case SchemasActionType.CREATE_SCHEMA_FOR_DATABASE_ATTEMPT: {
      return {
        ...prevState,
        isPendingCreateSchema: true,
      };
    }
    case SchemasActionType.CREATE_SCHEMA_FOR_DATABASE_FAILURE: {
      return {
        ...prevState,
        isPendingCreateSchema: false,
      };
    }
    case SchemasActionType.CREATE_SCHEMA_FOR_DATABASE_SUCCESS: {
      if (action.payload.dialogType !== CreateModelDialogType.CREATE) {
        return {
          ...prevState,
          isPendingCreateSchema: false,
        };
      }

      return getClearedModelsState({
        ...prevState,
        isPendingCreateSchema: false,
        isNewlyCreated: true,
        selectedSchemaName: action.payload.schemaName,
        tabId: ModelsTabIds.OVERVIEW,
      });
    }
    case SchemasActionType.REMOVE_SCHEMA_FROM_DATABASE_SUCCESS: {
      const { databaseId, schemaName } = action.payload;
      if (
        prevState.selectedSchemaName !== schemaName ||
        prevState.selectedDatabaseId !== databaseId
      ) {
        return prevState;
      }
      return {
        ...prevState,
        selectedSchemaName: '',
      };
    }
    case ModelsActionType.CLEAR_MODELS_DATA: {
      return getClearedModelsState(prevState);
    }

    // attributes section
    case ModelsActionType.CREATE_MODELS_ATTRIBUTE: {
      const {
        databaseId,
        schemaName,
        attributeIri,
        label,
        parentIri,
        predicate,
      } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      const deletedItems = new Set(prevState.attributes.deletedItems);
      if (deletedItems.has(attributeIri)) {
        deletedItems.delete(attributeIri);
      }

      const prevAttributesByIri = { ...prevState.attributes.itemsByIri };
      const newAttribute = createNewItem(
        attributeIri,
        parentIri,
        label,
        predicate
      );
      if (prevAttributesByIri[parentIri]) {
        prevAttributesByIri[parentIri].children.push(attributeIri);
      }

      const { itemHierarchy, itemsByIri } = updateResultsHierarchy(
        prevAttributesByIri,
        OWL_DATATYPE_PROPERTY_URI,
        newAttribute
      );
      const nextAttributesByIri = parentIri
        ? {
            ...itemsByIri,
            [parentIri]: {
              ...itemsByIri[parentIri],
              isOpen: true,
            },
          }
        : itemsByIri;
      return {
        ...prevState,
        attributes: {
          ...prevState.attributes,
          areItemsDirty: true,
          deletedItems,
          itemHierarchy,
          itemsByIri: nextAttributesByIri,
          selectedIri: newAttribute.id,
        },
      };
    }
    case ModelsActionType.DELETE_MODELS_ATTRIBUTE: {
      const { databaseId, schemaName, attributeIri } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      const attributeDescendants = collectAllDescendants(
        attributeIri,
        prevState.attributes.itemsByIri
      );
      const deletedItems = new Set([
        ...prevState.attributes.deletedItems,
        ...attributeDescendants,
      ]);

      const { itemHierarchy, itemsByIri } = updateResultsHierarchy(
        deleteItemsByIri(prevState.attributes.itemsByIri, deletedItems),
        OWL_DATATYPE_PROPERTY_URI
      );

      return {
        ...prevState,
        attributes: {
          ...prevState.attributes,
          areItemsDirty:
            Boolean(deletedItems.size) || areItemsDirty(itemsByIri),
          deletedItems,
          itemHierarchy,
          itemsByIri,
          selectedIri: deletedItems.has(prevState.attributes.selectedIri)
            ? ''
            : prevState.attributes.selectedIri,
        },
      };
    }
    case ModelsActionType.SET_MODEL_ATTRIBUTE_IRI: {
      const { databaseId, schemaName, attributeIri, path } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      const nextPath =
        path ||
        getImmediatePath(prevState.attributes.itemHierarchy, attributeIri);
      const prevOpenItems = prevState.attributes.openItems;
      const openItems = path
        ? prevOpenItems
        : addNodePathLineageToSet(
            prevOpenItems,
            nextPath.slice(0, nextPath.length - 1)
          );

      return {
        ...prevState,
        attributes: {
          ...prevState.attributes,
          openItems,
          selectedIri: attributeIri,
          selectedPath: nextPath,
        },
      };
    }
    case ModelsActionType.SET_MODEL_ATTRIBUTE_NODE_OPEN: {
      const { databaseId, path, schemaName } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      const { openItems } = prevState.attributes;
      // Opening the node always attempts to open its parents as well, since
      // 1) opening a node manually means that its parents are already open, and
      // 2) this type of opening behavior is needed for opening nodes automatically along with parents
      const nextOpenItems = addNodePathLineageToSet(openItems, path);

      return {
        ...prevState,
        attributes: {
          ...prevState.attributes,
          openItems: nextOpenItems,
        },
      };
    }
    case ModelsActionType.SET_MODEL_ATTRIBUTE_NODE_CLOSED: {
      const { databaseId, path, schemaName } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      const pathStr = path.join('-');

      const { openItems } = prevState.attributes;
      const nextOpenItems = new Set(openItems);
      nextOpenItems.delete(pathStr);

      return {
        ...prevState,
        attributes: {
          ...prevState.attributes,
          openItems: nextOpenItems,
        },
      };
    }
    case ModelsActionType.UPDATE_MODEL_ATTRIBUTE_LOCAL_DATA: {
      const { databaseId, schemaName, attributeIri, localData } =
        action.payload;

      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      const updatedItem = getUpdatedItem({
        prevItem: prevState.attributes.itemsByIri[attributeIri],
        localData,
      });
      const attributesByIri = {
        ...prevState.attributes.itemsByIri,
        [attributeIri]: updatedItem,
      };
      const isAttributesDirty = areItemsDirty(attributesByIri);

      return {
        ...prevState,
        attributes: {
          ...prevState.attributes,
          areItemsDirty: isAttributesDirty,
          itemsByIri: attributesByIri,
        },
      };
    }

    // classes section
    case ModelsActionType.CREATE_MODELS_CLASS: {
      const { databaseId, schemaName, classIri, label, parentIri, predicate } =
        action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      const deletedItems = new Set(prevState.classes.deletedItems);
      if (deletedItems.has(classIri)) {
        deletedItems.delete(classIri);
      }

      const prevClassesByIri = { ...prevState.classes.itemsByIri };
      const newClass = createNewItem(classIri, parentIri, label, predicate);
      if (prevClassesByIri[parentIri]) {
        prevClassesByIri[parentIri].children.push(classIri);
      }

      const { itemHierarchy, itemsByIri } = updateResultsHierarchy(
        prevClassesByIri,
        OWL_THING_URI,
        newClass
      );
      const nextClassesByIri = parentIri
        ? {
            ...itemsByIri,
            [parentIri]: {
              ...itemsByIri[parentIri],
              isOpen: true,
            },
          }
        : itemsByIri;

      return {
        ...prevState,
        classes: {
          ...prevState.classes,
          areItemsDirty: true,
          deletedItems,
          itemHierarchy,
          itemsByIri: nextClassesByIri,
          selectedIri: newClass.id,
        },
      };
    }
    case ModelsActionType.DELETE_MODELS_CLASS: {
      const { databaseId, schemaName, classIri } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      const classDescendants = collectAllDescendants(
        classIri,
        prevState.classes.itemsByIri
      );
      const deletedItems = new Set([
        ...prevState.classes.deletedItems,
        ...classDescendants,
      ]);

      const { itemHierarchy, itemsByIri } = updateResultsHierarchy(
        deleteItemsByIri(prevState.classes.itemsByIri, deletedItems),
        OWL_THING_URI
      );

      const attributesByIri = Object.entries(
        prevState.attributes.itemsByIri
      ).reduce((acc, [iri, item]) => {
        acc[iri] = getUpdatedItem({
          prevItem: item,
          deletedDomain: deletedItems,
        });
        return acc;
      }, {} as NormalizedModelsItems);
      const isAttributesDirty = areItemsDirty(attributesByIri);

      const relationshipsByIri = Object.entries(
        prevState.relationships.itemsByIri
      ).reduce((acc, [iri, item]) => {
        acc[iri] = getUpdatedItem({
          prevItem: item,
          deletedDomain: deletedItems,
          deletedRange: deletedItems,
        });
        return acc;
      }, {} as NormalizedModelsItems);
      const isRelationshipsDirty = areItemsDirty(relationshipsByIri);

      return {
        ...prevState,
        classes: {
          ...prevState.classes,
          areItemsDirty:
            Boolean(deletedItems.size) || areItemsDirty(itemsByIri),
          deletedItems,
          itemHierarchy,
          itemsByIri,
          selectedIri: deletedItems.has(prevState.classes.selectedIri)
            ? ''
            : prevState.classes.selectedIri,
        },
        attributes: {
          ...prevState.attributes,
          areItemsDirty: isAttributesDirty,
          itemsByIri: attributesByIri,
        },
        relationships: {
          ...prevState.relationships,
          areItemsDirty: isRelationshipsDirty,
          itemsByIri: relationshipsByIri,
        },
      };
    }
    case ModelsActionType.SET_MODEL_CLASS_IRI: {
      const { databaseId, schemaName, classIri, path } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }
      const nextPath =
        path || getImmediatePath(prevState.classes.itemHierarchy, classIri);
      const prevOpenItems = prevState.classes.openItems;
      const openItems = path
        ? prevOpenItems
        : addNodePathLineageToSet(
            prevOpenItems,
            nextPath.slice(0, nextPath.length - 1)
          );

      return {
        ...prevState,
        classes: {
          ...prevState.classes,
          openItems,
          selectedIri: classIri,
          selectedPath: nextPath,
        },
      };
    }
    case ModelsActionType.SET_MODEL_CLASS_NODE_OPEN: {
      const { databaseId, path, schemaName } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      const { openItems } = prevState.classes;
      // Opening the node always attempts to open its parents as well, since
      // 1) opening a node manually means that its parents are already open, and
      // 2) this type of opening behavior is needed for opening nodes automatically along with parents
      const nextOpenItems = addNodePathLineageToSet(openItems, path);

      return {
        ...prevState,
        classes: {
          ...prevState.classes,
          openItems: nextOpenItems,
        },
      };
    }
    case ModelsActionType.SET_MODEL_CLASS_NODE_CLOSED: {
      const { databaseId, path, schemaName } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      const pathStr = path.join('-');

      const { openItems } = prevState.classes;
      const nextOpenItems = new Set(openItems);
      nextOpenItems.delete(pathStr);

      return {
        ...prevState,
        classes: {
          ...prevState.classes,
          openItems: nextOpenItems,
        },
      };
    }
    case ModelsActionType.UPDATE_MODEL_CLASS_LOCAL_DATA: {
      const { databaseId, schemaName, classIri, localData } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      const updatedItem = getUpdatedItem({
        prevItem: prevState.classes.itemsByIri[classIri],
        localData,
      });
      const classesByIri = {
        ...prevState.classes.itemsByIri,
        [classIri]: updatedItem,
      };
      const isClassesDirty = areItemsDirty(classesByIri);

      return {
        ...prevState,
        classes: {
          ...prevState.classes,
          areItemsDirty: isClassesDirty,
          itemsByIri: classesByIri,
        },
      };
    }

    // relationships section
    case ModelsActionType.CREATE_MODELS_RELATIONSHIP: {
      const {
        databaseId,
        schemaName,
        relationshipIri,
        label,
        parentIri,
        predicate,
      } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      const deletedItems = new Set(prevState.relationships.deletedItems);
      if (deletedItems.has(relationshipIri)) {
        deletedItems.delete(relationshipIri);
      }

      const prevRelationshipsByIri = { ...prevState.relationships.itemsByIri };
      const newRelationship = createNewItem(
        relationshipIri,
        parentIri,
        label,
        predicate
      );

      if (prevRelationshipsByIri[parentIri]) {
        prevRelationshipsByIri[parentIri].children.push(relationshipIri);
      }
      const { itemHierarchy, itemsByIri } = updateResultsHierarchy(
        prevRelationshipsByIri,
        OWL_OBJECT_PROPERTY_URI,
        newRelationship
      );
      const nextRelationshipsByIri = parentIri
        ? {
            ...itemsByIri,
            [parentIri]: {
              ...itemsByIri[parentIri],
              isOpen: true,
            },
          }
        : itemsByIri;
      return {
        ...prevState,
        relationships: {
          ...prevState.relationships,
          areItemsDirty: true,
          deletedItems,
          itemHierarchy,
          itemsByIri: nextRelationshipsByIri,
          selectedIri: newRelationship.id,
        },
      };
    }
    case ModelsActionType.DELETE_MODELS_RELATIONSHIP: {
      const {
        databaseId,
        schemaName,
        relationshipIri,
        isEdgePropertiesEnabled,
      } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      const relationshipDescendants = collectAllDescendants(
        relationshipIri,
        prevState.relationships.itemsByIri
      );
      const deletedItems = new Set([
        ...prevState.relationships.deletedItems,
        ...relationshipDescendants,
      ]);

      const deletedDomainAndRange = isEdgePropertiesEnabled
        ? deletedItems
        : undefined;

      const { itemHierarchy, itemsByIri } = updateResultsHierarchy(
        deleteItemsByIri(
          prevState.relationships.itemsByIri,
          deletedItems,
          deletedDomainAndRange,
          deletedDomainAndRange
        ),
        OWL_OBJECT_PROPERTY_URI
      );

      const attributes = { ...prevState.attributes };
      if (isEdgePropertiesEnabled) {
        attributes.itemsByIri = Object.entries(attributes.itemsByIri).reduce(
          (acc, [iri, item]) => {
            acc[iri] = getUpdatedItem({
              prevItem: item,
              deletedDomain: deletedDomainAndRange,
            });
            return acc;
          },
          {} as NormalizedModelsItems
        );
        attributes.areItemsDirty = areItemsDirty(attributes.itemsByIri);
      }

      return {
        ...prevState,
        relationships: {
          ...prevState.relationships,
          areItemsDirty:
            Boolean(deletedItems.size) || areItemsDirty(itemsByIri),
          deletedItems,
          itemHierarchy,
          itemsByIri,
          selectedIri: deletedItems.has(prevState.relationships.selectedIri)
            ? ''
            : prevState.relationships.selectedIri,
        },
        attributes,
      };
    }
    case ModelsActionType.SET_MODEL_RELATIONSHIP_IRI: {
      const { databaseId, path, relationshipIri, schemaName } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      const nextPath =
        path ||
        getImmediatePath(
          prevState.relationships.itemHierarchy,
          relationshipIri
        );
      const prevOpenItems = prevState.relationships.openItems;
      const openItems = path
        ? prevOpenItems
        : addNodePathLineageToSet(
            prevOpenItems,
            nextPath.slice(0, nextPath.length - 1)
          );

      return {
        ...prevState,
        relationships: {
          ...prevState.relationships,
          openItems,
          selectedIri: relationshipIri,
          selectedPath: nextPath,
        },
      };
    }
    case ModelsActionType.SET_MODEL_RELATIONSHIP_NODE_OPEN: {
      const { databaseId, path, schemaName } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      const { openItems } = prevState.relationships;
      // Opening the node always attempts to open its parents as well, since
      // 1) opening a node manually means that its parents are already open, and
      // 2) this type of opening behavior is needed for opening nodes automatically along with parents
      const nextOpenItems = addNodePathLineageToSet(openItems, path);

      return {
        ...prevState,
        relationships: {
          ...prevState.relationships,
          openItems: nextOpenItems,
        },
      };
    }
    case ModelsActionType.SET_MODEL_RELATIONSHIP_NODE_CLOSED: {
      const { databaseId, path, schemaName } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      const pathStr = path.join('-');

      const { openItems } = prevState.relationships;
      const nextOpenItems = new Set(openItems);
      nextOpenItems.delete(pathStr);

      return {
        ...prevState,
        relationships: {
          ...prevState.relationships,
          openItems: nextOpenItems,
        },
      };
    }
    case ModelsActionType.UPDATE_MODEL_RELATIONSHIP_LOCAL_DATA: {
      const { databaseId, schemaName, relationshipIri, localData } =
        action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      const updatedItem = getUpdatedItem({
        prevItem: prevState.relationships.itemsByIri[relationshipIri],
        localData,
      });
      const relationshipsByIri = {
        ...prevState.relationships.itemsByIri,
        [relationshipIri]: updatedItem,
      };
      const isRelationshipsDirty = areItemsDirty(relationshipsByIri);

      return {
        ...prevState,
        relationships: {
          ...prevState.relationships,
          areItemsDirty: isRelationshipsDirty,
          itemsByIri: relationshipsByIri,
        },
      };
    }

    case ModelsActionType.REQUEST_MODEL_ATTRIBUTES_ATTEMPT: {
      const { databaseId, schemaName } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      return {
        ...prevState,
        attributes: {
          ...prevState.attributes,
          isPendingData: true,
          selectedIri: '',
        },
      };
    }
    case ModelsActionType.REQUEST_MODEL_ATTRIBUTES_FAILURE: {
      const { databaseId, schemaName } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      return {
        ...prevState,
        attributes: {
          ...prevState.attributes,
          isPendingData: false,
        },
      };
    }
    case ModelsActionType.REQUEST_MODEL_ATTRIBUTES_SUCCESS: {
      const { databaseId, schemaName, bindings } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      const { itemHierarchy, itemsByIri } = createResultHierarchyFromBindings(
        bindings,
        OWL_DATATYPE_PROPERTY_URI
      );

      return {
        ...prevState,
        attributes: {
          ...prevState.attributes,
          isPendingData: false,
          itemHierarchy,
          itemsByIri,
        },
      };
    }

    case ModelsActionType.REQUEST_MODEL_CLASSES_ATTEMPT: {
      const { databaseId, schemaName } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      return {
        ...prevState,
        classes: {
          ...prevState.classes,
          isPendingData: true,
          selectedIri: '',
        },
      };
    }
    case ModelsActionType.REQUEST_MODEL_CLASSES_FAILURE: {
      const { databaseId, schemaName } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      return {
        ...prevState,
        classes: {
          ...prevState.classes,
          isPendingData: false,
        },
      };
    }
    case ModelsActionType.REQUEST_MODEL_CLASSES_SUCCESS: {
      const { databaseId, schemaName, bindings } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      const { itemHierarchy, itemsByIri } = createResultHierarchyFromBindings(
        bindings,
        OWL_THING_URI
      );

      return {
        ...prevState,
        classes: {
          ...prevState.classes,
          isPendingData: false,
          itemHierarchy,
          itemsByIri,
        },
      };
    }

    case ModelsActionType.REQUEST_MODEL_RELATIONSHIPS_ATTEMPT: {
      const { databaseId, schemaName } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      return {
        ...prevState,
        relationships: {
          ...prevState.relationships,
          isPendingData: true,
          selectedIri: '',
        },
      };
    }
    case ModelsActionType.REQUEST_MODEL_RELATIONSHIPS_FAILURE: {
      const { databaseId, schemaName } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      return {
        ...prevState,
        relationships: {
          ...prevState.relationships,
          isPendingData: false,
        },
      };
    }
    case ModelsActionType.REQUEST_MODEL_RELATIONSHIPS_SUCCESS: {
      const { databaseId, schemaName, bindings } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      const { itemHierarchy, itemsByIri } = createResultHierarchyFromBindings(
        bindings,
        OWL_OBJECT_PROPERTY_URI
      );

      return {
        ...prevState,
        relationships: {
          ...prevState.relationships,
          isPendingData: false,
          itemHierarchy,
          itemsByIri,
        },
      };
    }

    case ModelsActionType.REQUEST_MODEL_VISUALIZATION_DATA_ATTEMPT: {
      const { databaseId, schemaName } = action.payload.action;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      return {
        ...prevState,
        pendingVisualization: true,
      };
    }
    case ModelsActionType.REQUEST_MODEL_VISUALIZATION_DATA_SUCCESS: {
      const { databaseId, schemaName } = action.payload.action;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      const result: RawJsonLdData[] | RawJsonLdResult =
        action.payload?.response?.body || {};
      const results = Array.isArray(result) ? result : result['@graph'];

      return {
        ...prevState,
        pendingVisualization: false,
        visualizationSettings: {
          visualizationId: uuid(),
          visualizationData: results,
          visualizationDate: moment().format('LT l'),
        },
      };
    }
    case ModelsActionType.REQUEST_MODEL_VISUALIZATION_DATA_FAILURE: {
      const { databaseId, schemaName } = action.payload.action;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      return {
        ...prevState,
        pendingVisualization: false,
      };
    }

    case ModelsActionType.SAVE_SELECTED_MODEL_ATTEMPT: {
      const { databaseId, schemaName } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      return {
        ...prevState,
        isPendingSave: true,
      };
    }
    case ModelsActionType.SAVE_SELECTED_MODEL_FAILURE: {
      const { databaseId, schemaName } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      return {
        ...prevState,
        isPendingSave: false,
      };
    }
    case ModelsActionType.SAVE_SELECTED_MODEL_SUCCESS: {
      const { databaseId, schemaName } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      const updatedConstraints = prevState.constraints.editorSettings.localDoc;
      const nextState = getStateWithUpdatedConstraintsProperties(
        prevState,
        {
          isDirty: false,
          lastRetrievedDoc: updatedConstraints,
          localDoc: updatedConstraints,
        },
        {}
      );

      const {
        itemHierarchy: attributesHierarchy,
        itemsByIri: attributesByIri,
      } = updateResultsHierarchy(
        Object.entries(prevState.attributes.itemsByIri).reduce(
          saveNormalizedItem,
          {} as NormalizedModelsItems
        ),
        OWL_DATATYPE_PROPERTY_URI
      );

      const { itemHierarchy: classesHierarchy, itemsByIri: classesByIri } =
        updateResultsHierarchy(
          Object.entries(prevState.classes.itemsByIri).reduce(
            saveNormalizedItem,
            {} as NormalizedModelsItems
          ),
          OWL_THING_URI
        );

      const {
        itemHierarchy: relationshipsHierarchy,
        itemsByIri: relationshipsByIri,
      } = updateResultsHierarchy(
        Object.entries(prevState.relationships.itemsByIri).reduce(
          saveNormalizedItem,
          {} as NormalizedModelsItems
        ),
        OWL_OBJECT_PROPERTY_URI
      );

      return {
        ...nextState,
        isPendingSave: false,
        attributes: {
          ...nextState.attributes,
          areItemsDirty: false,
          deletedItems: new Set(),
          itemHierarchy: attributesHierarchy,
          itemsByIri: attributesByIri,
        },
        classes: {
          ...nextState.classes,
          areItemsDirty: false,
          deletedItems: new Set(),
          itemHierarchy: classesHierarchy,
          itemsByIri: classesByIri,
        },
        relationships: {
          ...nextState.relationships,
          areItemsDirty: false,
          deletedItems: new Set(),
          itemHierarchy: relationshipsHierarchy,
          itemsByIri: relationshipsByIri,
        },
      };
    }

    case ModelsActionType.SET_MODELS_DATABASE_ID: {
      const selectedDatabaseId = action.payload.databaseId;
      if (prevState.selectedDatabaseId === selectedDatabaseId) {
        return prevState;
      }

      return getClearedModelsState({
        ...prevState,
        mosaicState: {
          ...prevState.mosaicState,
          CLASSES: defaultMosaicState.CLASSES,
        },
        namedGraphsBySchema: {},
        selectedDatabaseId,
        selectedSchemaName: '',
      });
    }
    case ModelsActionType.SET_MODELS_NEWLY_CREATED: {
      const { isNewlyCreated } = action.payload;
      return {
        ...prevState,
        isNewlyCreated,
      };
    }
    case ModelsActionType.SET_MODELS_SCHEMA_NAME: {
      return getClearedModelsState({
        ...prevState,
        selectedSchemaName: action.payload.schemaName,
        tabId: ModelsTabIds.OVERVIEW,
      });
    }
    case ModelsActionType.SET_MODELS_SECTION: {
      return {
        ...prevState,
        tabId: action.payload.tabId,
      };
    }
    case ModelsActionType.UPDATE_MODELS_MOSAIC_STATE: {
      return {
        ...prevState,
        mosaicState: {
          ...prevState.mosaicState,
          ...action.payload.mosaicStateChange,
        },
      };
    }

    // constraints

    case ModelsActionType.REQUEST_MODEL_CONSTRAINTS_ATTEMPT: {
      const { databaseId, schemaName } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      return getStateWithUpdatedConstraintsProperties(
        prevState,
        {
          didFailRequest: false,
          isPendingRequest: true,
        },
        {}
      );
    }
    case ModelsActionType.REQUEST_MODEL_CONSTRAINTS_FAILURE: {
      const { databaseId, schemaName } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      return getStateWithUpdatedConstraintsProperties(
        prevState,
        {
          didFailRequest: true,
          isPendingRequest: false,
        },
        {}
      );
    }
    case ModelsActionType.REQUEST_MODEL_CONSTRAINTS_SUCCESS: {
      const { databaseId, schemaName, body, requestedNamedGraphs } =
        action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      return getStateWithUpdatedConstraintsProperties(
        prevState,
        {
          didFailRequest: false,
          isDirty: false,
          isPendingRequest: false,
          lastRetrievedDoc: body || '',
          localDoc: body || '',
          requestedNamedGraphs,
        },
        {}
      );
    }

    case ModelsActionType.REQUEST_MODEL_CONSTRAINTS_REPORT_ATTEMPT: {
      const { databaseId, schemaName } = action.payload.action;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      return getStateWithUpdatedConstraintsProperties(
        prevState,
        {},
        {
          isPendingReport: true,
          isReportCollapsed: true,
        }
      );
    }
    case ModelsActionType.REQUEST_MODEL_CONSTRAINTS_REPORT_FAILURE: {
      const { databaseId, schemaName } = action.payload.action;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      return getStateWithUpdatedConstraintsProperties(
        prevState,
        {},
        { isPendingReport: false }
      );
    }
    case ModelsActionType.REQUEST_MODEL_CONSTRAINTS_REPORT_SUCCESS: {
      const { databaseId, schemaName } = action.payload.action;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      return getStateWithUpdatedConstraintsProperties(
        prevState,
        {},
        {
          isPendingReport: false,
          isReportCollapsed: false,
          lastRetrievedReport: action.payload?.response?.body,
          viewState: null,
        }
      );
    }
    case ModelsActionType.RESIZE_MODEL_CONSTRAINTS_REPORT: {
      const { autoCollapse, databaseId, height, schemaName } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }
      if (!isNumber(height)) {
        return prevState;
      }
      const { isReportCollapsed: prevCollapsed } =
        prevState.constraints.reportSettings;

      let isReportCollapsed: boolean;
      if (autoCollapse) {
        isReportCollapsed = height < MINIMUM_CONSTRAINTS_REPORT_HEIGHT;
      } else {
        isReportCollapsed = prevCollapsed;
      }

      return getStateWithUpdatedConstraintsProperties(
        prevState,
        {},
        {
          height,
          isReportCollapsed,
        }
      );
    }
    case ModelsActionType.SAVE_MODEL_CONSTRAINTS_EDITOR_VIEW_STATE: {
      const { databaseId, schemaName, viewState } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      return getStateWithUpdatedConstraintsProperties(
        prevState,
        { viewState },
        {}
      );
    }
    case ModelsActionType.SAVE_MODEL_CONSTRAINTS_REPORT_VIEW_STATE: {
      const { databaseId, schemaName, viewState } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      return getStateWithUpdatedConstraintsProperties(
        prevState,
        {},
        { viewState }
      );
    }
    case ModelsActionType.SET_MODEL_CONSTRAINTS_LOCAL_DOC: {
      const { databaseId, localDoc, schemaName } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      const { lastRetrievedDoc } = prevState.constraints.editorSettings;
      const isDirty = localDoc !== lastRetrievedDoc;
      return getStateWithUpdatedConstraintsProperties(
        prevState,
        {
          isDirty,
          localDoc,
        },
        {}
      );
    }
    case ModelsActionType.SET_MODEL_CONSTRAINTS_REPORT_NAMED_GRAPHS: {
      const { databaseId, namedGraphsForReport, schemaName } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      return getStateWithUpdatedConstraintsProperties(
        prevState,
        {},
        {
          namedGraphs: namedGraphsForReport,
        }
      );
    }
    case ModelsActionType.SHOW_MODEL_CONSTRAINTS_DISABLED_SYNTAX_NOTIFICATION: {
      const { databaseId, schemaName } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      return getStateWithUpdatedConstraintsProperties(
        prevState,
        {
          wasSyntaxNotificationShown: true,
        },
        {}
      );
    }
    case ModelsActionType.CLOSE_MODEL_CONSTRAINTS_EDIT_WARNING: {
      const { databaseId, schemaName } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }
      return getStateWithUpdatedConstraintsProperties(
        prevState,
        {
          shouldShowEditWarning: false,
        },
        {}
      );
    }
    case ModelsActionType.SHOW_MODEL_CONSTRAINTS_EDIT_WARNING: {
      const { databaseId, schemaName } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }
      return getStateWithUpdatedConstraintsProperties(
        prevState,
        {
          shouldShowEditWarning: true,
        },
        {}
      );
    }
    case ModelsActionType.TOGGLE_MODEL_CONSTRAINTS_SYNTAX_FEATURES: {
      const { areSyntaxFeaturesDisabled, databaseId, schemaName } =
        action.payload;
      // in case there is weird behavior around switching schemas quickly
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      return getStateWithUpdatedConstraintsProperties(
        prevState,
        {
          areSyntaxFeaturesDisabled,
        },
        {}
      );
    }
    case ModelsActionType.TOGGLE_MODEL_CONSTRAINTS_REPORT_COLLAPSE: {
      const { databaseId, schemaName } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }
      const { height: prevHeight, isReportCollapsed: prevCollapsed } =
        prevState.constraints.reportSettings;

      return getStateWithUpdatedConstraintsProperties(
        prevState,
        {},
        {
          height:
            prevHeight >= MINIMUM_CONSTRAINTS_REPORT_HEIGHT
              ? prevHeight
              : MINIMUM_CONSTRAINTS_REPORT_HEIGHT,
          isReportCollapsed: !prevCollapsed,
        }
      );
    }

    // text editor

    case ModelsActionType.REQUEST_MODEL_TEXT_ATTEMPT: {
      const { databaseId, schemaName } = action.payload.action;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }
      return {
        ...prevState,
        textEditor: {
          ...prevState.textEditor,
          isPendingRequest: true,
        },
      };
    }

    case ModelsActionType.REQUEST_MODEL_TEXT_FAILURE: {
      const { databaseId, schemaName } = action.payload.action;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }
      return {
        ...prevState,
        textEditor: {
          ...prevState.textEditor,
          isPendingRequest: true,
        },
      };
    }

    case ModelsActionType.REQUEST_MODEL_TEXT_SUCCESS: {
      const { databaseId, schemaName } = action.payload.action;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }
      const document = action.payload?.response?.body || {};
      return {
        ...prevState,
        textEditor: {
          ...prevState.textEditor,
          areSyntaxFeaturesDisabled: false,
          wasSyntaxNotificationShown: false,
          isDirty: false,
          isPendingRequest: false,
          lastRetrievedDoc: document,
          localDoc: document,
        },
      };
    }

    case ModelsActionType.SAVE_MODEL_TEXT_SUCCESS: {
      const { databaseId, schemaName } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }

      return {
        ...getClearedModelsState(prevState),
        textEditor: {
          ...prevState.textEditor,
          isDirty: false,
          lastRetrievedDoc: prevState.textEditor.localDoc,
        },
      };
    }

    case ModelsActionType.SET_MODEL_TEXT_EDITOR_LOCAL_DOC: {
      const { databaseId, localDoc, schemaName } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }
      const isDirty = prevState.textEditor.lastRetrievedDoc !== localDoc;
      return {
        ...prevState,
        textEditor: {
          ...prevState.textEditor,
          isDirty,
          localDoc,
        },
      };
    }

    case ModelsActionType.SHOW_MODEL_TEXT_EDITOR_DISABLED_SYNTAX_NOTIFICATION: {
      const { databaseId, schemaName } = action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }
      return {
        ...prevState,
        textEditor: {
          ...prevState.textEditor,
          wasSyntaxNotificationShown: true,
        },
      };
    }
    case ModelsActionType.TOGGLE_MODEL_TEXT_EDITOR_SYNTAX_FEATURES: {
      const { areSyntaxFeaturesDisabled, databaseId, schemaName } =
        action.payload;
      if (
        databaseId !== prevState.selectedDatabaseId ||
        schemaName !== prevState.selectedSchemaName
      ) {
        return prevState;
      }
      return {
        ...prevState,
        textEditor: {
          ...prevState.textEditor,
          areSyntaxFeaturesDisabled,
        },
      };
    }
    case PreferencesActionType.SET_PREFERENCES: {
      // there is a chance that the user left the models hub in the constraints section
      // before disabling models constraints in preferences
      const { includeConstraintsInModelsHub } = action.payload.preferences;
      if (
        !includeConstraintsInModelsHub &&
        prevState.tabId === ModelsTabIds.CONSTRAINTS
      ) {
        return {
          ...prevState,
          tabId: ModelsTabIds.OVERVIEW,
        };
      }
      return prevState;
    }
    default:
      return prevState;
  }
};
