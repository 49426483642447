import { Button, Classes, Icon, Position, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';
import { connect } from 'react-redux';

import { connectionActionCreators } from 'src/common/actions/connection/connectionActionCreators';
import { StudioThunkDispatch } from 'src/common/actions/StudioAction';
import { TEST_IDS } from 'src/common/constants/testIds';
import { isConnectionConnected } from 'src/common/utils/isConnectionConnected';
import { ReduxState } from 'src/types';

export const CONNECTED_ICON_COLOR = '#92da92';
export const DISCONNECTED_ICON_COLOR = '#b36767';

const mapStateToProps = ({ connection }: ReduxState) => {
  const { current: currentConnection } = connection;

  return {
    isConnected: isConnectionConnected(currentConnection),
  };
};

const mapDispatchToProps = (dispatch: StudioThunkDispatch) => ({
  toggleAppDrawer: () => dispatch(connectionActionCreators.toggleAppDrawer()),
});

type StandaloneConnectionButtonStateProps = ReturnType<typeof mapStateToProps>;
type StandaloneConnectionButtonDispatchProps = ReturnType<
  typeof mapDispatchToProps
>;

const UnconnectedStandaloneConnectionButton: React.VFC<
  StandaloneConnectionButtonStateProps & StandaloneConnectionButtonDispatchProps
> = ({ isConnected, toggleAppDrawer }) => {
  return (
    <Tooltip
      className="sd-main-nav-connect-button"
      content={isConnected ? 'Change Connection' : 'Connect to Stardog'}
      position={Position.RIGHT}
    >
      <Button
        className={Classes.TAB}
        icon={
          <Icon
            color={isConnected ? CONNECTED_ICON_COLOR : DISCONNECTED_ICON_COLOR}
            data-testid={TEST_IDS.standalone.connectButton}
            icon={IconNames.OFFLINE}
          />
        }
        onClick={() => toggleAppDrawer()}
      />
    </Tooltip>
  );
};

export const StandaloneConnectionButton = connect<
  StandaloneConnectionButtonStateProps,
  StandaloneConnectionButtonDispatchProps
>(
  mapStateToProps,
  mapDispatchToProps
)(UnconnectedStandaloneConnectionButton);
