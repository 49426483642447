import * as path from 'path-browserify';
import { isPortal } from 'vet-bones/bones/utils/portal';

import {
  zoomLevelIncrement,
  zoomLevelMax,
  zoomLevelMin,
} from 'src/common/constants/zoom';
import { NoteState } from 'src/common/store/notebook/NoteState';
import { Theme } from 'src/common/utils/theme';

const { parse } = path;

const zoomLevelEnumValues = [];
for (let i = zoomLevelMin; i <= zoomLevelMax; i += zoomLevelIncrement) {
  zoomLevelEnumValues.push(i);
}

export const RELEASE_NOTES_NOTE_ID = "__RELEASE_NOTES__/What's-New";
export const RELEASE_NOTES_NOTE_NAME = "What's New";
export const RELEASE_NOTES_NOTE = new NoteState(RELEASE_NOTES_NOTE_ID);

export const USER_PREFERENCES_NOTE_ID =
  '__USER_PREFERENCES__/User-Settings.json';
export const USER_PREFERENCES_NOTE_NAME = 'Preferences';
const USER_PREFERENCES_SCHEMA_ID = 'USER_PREFERENCES_SCHEMA';

const makeMarkdownDescription = (name: string, description: string) =>
  // NOTE: There are two empty spaces after **${name}**
  // below because this is required for markdown to insert a newline. :(
  `**${name}**  \n${description}`;

export const USER_PREFERENCES_NOTE_SCHEMA = {
  // according to the example seen on https://microsoft.github.io/monaco-editor/playground.html#extending-language-services-configure-json-defaults,
  // the uri here is a unique identifier for the schema, not the Monaco model
  uri: USER_PREFERENCES_SCHEMA_ID,
  fileMatch: [
    parse(USER_PREFERENCES_NOTE_ID).name + parse(USER_PREFERENCES_NOTE_ID).ext,
  ],
  // See `getPreferencesWithDefaults.ts` for injecting default values for new
  // preferences.
  schema: {
    type: 'object',
    properties: {
      editorFontSize: {
        type: 'integer',
        minimum: 8,
      },
      editorTooltips: {
        type: 'boolean',
        markdownDescription: makeMarkdownDescription(
          'editorTooltips',
          'If `true` (the default), Studio will provide tooltips for definitions and errors in text editors. If `false`, Studio will not show any tooltips in any editor.'
        ),
      },
      errorToastTimeout: {
        type: 'integer',
        markdownDescription: makeMarkdownDescription(
          'errorToastTimeout',
          'Duration error toasts will persist for the specified timeout set in seconds. Set to `0` to make it so all error toasts must be manually closed.'
        ),
        minimum: 0,
      },
      includeConstraintsInModelsHub: {
        type: 'boolean',
        markdownDescription: makeMarkdownDescription(
          'includeConstraintsInModelsHub',
          'If `true` (the default), Studio will dynamically query for and write constraints from your database to a named graph for Constraints when creating and editing models in the Models hub. If `false`, Studio will not include that information. In rare cases, setting this to false can improve Studio performance.'
        ),
      },
      theme: {
        type: 'string',
        enum: [Theme.LIGHT, Theme.DARK],
      },
      ...(isPortal()
        ? {}
        : {
            telemetryConsent: {
              type: 'boolean',
            },
          }),
      useSchemaForAutocompletion: {
        type: 'boolean',
        markdownDescription: makeMarkdownDescription(
          'useSchemaForAutocompletion',
          'If `true` (the default), Studio will dynamically include schema information from your databases, such as types and relationships, in autocomplete suggestions. If `false`, Studio will not include that information. In rare cases, setting this to `false` can improve Studio performance.'
        ),
      },
      useSchemaForNamedGraphs: {
        type: 'boolean',
        markdownDescription: makeMarkdownDescription(
          'useSchemaForNamedGraphs',
          'If `true` (the default), Studio will include named graph information from your databases in the named graph selector. If `false`, Studio will not include that information. In rare cases, setting this to `false` can improve Studio performance.'
        ),
      },
      labelPredicatesByDatabase: {
        type: 'object',
        markdownDescription: makeMarkdownDescription(
          'labelPredicatesByDatabase',
          'Label predicates order for search and visalization. These _must_ be the full URI.'
        ),
        additionalProperties: {
          type: 'array',
          items: { type: 'string', format: 'uri' },
        },
      },
      modelsBaseIriByDatabase: {
        type: 'object',
        markdownDescription: makeMarkdownDescription(
          'modelsBaseIriByDatabase',
          'Base IRI for creating new items in the Models Hub. This _must_ be the full IRI'
        ),
        additionalProperties: {
          type: 'object',
          additionalProperties: {
            type: ['string', 'null'],
            format: 'uri',
          },
        },
      },
      queryLimit: {
        type: 'number',
        markdownDescription: makeMarkdownDescription(
          'queryLimit',
          'The LIMIT to apply (specifying maximum number of results) to all non-CONSTRUCT/DESCRIBE SPARQL queries run inside of Studio. NOTE: If set to 0, this preference will be ignored.'
        ),
        minimum: 0,
        maximum: 50000,
      },
      voiceboxOverridesByDatabase: {
        type: 'object',
        markdownDescription: makeMarkdownDescription(
          'voiceboxOverridesByDatabase',
          'Override the automatically generated database schema prompt that is sent to Voicebox.'
        ),
        additionalProperties: {
          type: 'string',
        },
      },
    },
    required: [
      'editorFontSize',
      'theme',
      ...(isPortal() ? [] : ['telemetryConsent']),
      'useSchemaForAutocompletion',
      'useSchemaForNamedGraphs',
      'labelPredicatesByDatabase',
      'queryLimit',
    ],
    additionalProperties: false,
  },
};

// NOTE Several parts of schemas are pulled out here into `const`s instead of
// using `$ref` because monaco-json, as currently configured, cannot resolve
// cross-schema and external references. This requires `fetch` to be in its
// scope.
const prefixesPropertySchema = {
  type: 'object',
  markdownDescription: 'Namespace prefixes defined in the query.',
  additionalProperties: {
    type: 'string',
    format: 'uri',
  },
};

const datasetPropertySchema = {
  oneOf: [
    {
      type: 'object',
      markdownDescription: 'Named graphs used in this query.',
      additionalProperties: { type: 'string' },
    },
    {
      type: 'array',
      items: [
        {
          oneOf: [
            {
              type: 'object',
              markdownDescription: 'Named graphs used in this query.',
              additionalProperties: { type: 'string' },
            },
            {
              type: 'string',
              markdownDescription: 'Named graphs used in this query.',
            },
          ],
        },
      ],
    },
  ],
};

const explanationSchemaDefinitions = {
  planNode: {
    type: 'object',
    properties: {
      label: {
        oneOf: [
          // https://docs.stardog.com/operating-stardog/database-administration/managing-query-performance#query-plan-operators
          // NOTE Descriptions from docs contain URLs. These URLs are removed as we do not currently open these in the browser from Electron.
          {
            type: 'string',
            title: 'Scan Operator',
            markdownDescription:
              'Evaluates a triple/quad pattern against Stardog indexes. Indicates the index used, e.g. CSPO or POSC, where S,P,O,C stand for the kind of lexicographic ordering of quads that the index provides. SPOC means that the index is sorted first by *S*ubject, then *P*redicate, *O*bject, and *C*ontext (named graph IRI).',
            pattern: '^Scan*',
          },
          {
            type: 'string',
            title: 'HashJoin Operator [PIPELINE BREAKER]',
            markdownDescription:
              'Hash join algorithm, hashes the right operand.',
            pattern: '^HashJoin*',
          },
          {
            type: 'string',
            title: 'BindJoin Operator',
            markdownDescription:
              'A join algorithm binds the join variables on the right operator to the current values of the same variables in the current solution on the left. Can be seen as an optimization of the nested loop join for the case when the left operator produces far fewer results than the right. Not a pipeline breaker.',
            pattern: '^BindJoin*',
          },
          {
            type: 'string',
            title: 'DirectHashJoin Operator [PIPELINE BREAKER]',
            markdownDescription:
              'A hash join algorithm which directly uses indexes for lookups instead of building a hash table.',
            pattern: '^DirectHashJoin*',
          },
          {
            type: 'string',
            title: 'MergeJoin Operator',
            markdownDescription:
              'Merge join algorithm, the fastest option for joining two streams of solutions. Requires both operands be sorted on the join key.',
            pattern: '^MergeJoin*',
          },
          {
            type: 'string',
            title: 'NaryJoin Operator',
            markdownDescription:
              'Same as `MergeJoin` but for N operators sorted on the same join key.',
            pattern: '^NaryJoin*',
          },
          {
            type: 'string',
            title: 'NestedLoopJoin Operator',
            markdownDescription:
              'The nested loop join algorithm, the slowest join option. The only join option when there is no join key. Not a pipeline breaker.',
            pattern: '^NestedLoopJoin*',
          },
          {
            type: 'string',
            title: 'Path Query Operator',
            pattern: 'Paths*', // no leading `^` because Shortest|All(Cyclic) precedes `Paths`
          },
          {
            type: 'string',
            title: 'SortJoin Operator [PIPELINE BREAKER]',
            markdownDescription:
              'Sorts the argument solutions by the sort key, typically used as a part of a merge join.',
            pattern: '^SortJoin*',
          },
          {
            type: 'string',
            title: 'Sort Operator [PIPELINE BREAKER]',
            markdownDescription:
              'The sort operator builds an intermediate sorted collection of solutions produced by its child node. The main use case for sorting solutions is to prepare data for an operator which can benefit from sorted inputs, such as MergeJoin, Distinct, or GroupBy. All solutions have to be fetched from the child node before the smallest (w.r.t. the sort key) solution can be emitted.',
            pattern: '^Sort\\(*', // Open paren separates this from SortJoin pattern
          },
          {
            type: 'string',
            title: 'Filter Operator',
            markdownDescription:
              'Filters argument solutions according to the condition.',
            pattern: '^Filter*',
          },
          {
            type: 'string',
            title: 'Union Operator',
            markdownDescription:
              'Combines streams of argument solutions. If both streams are sorted by the same variable, the result is also sorted by that variable.',
            pattern: '^Union*',
          },
          {
            type: 'string',
            title: 'Minus Operator [PIPELINE BREAKER]',
            markdownDescription:
              'Removes solutions from the left operand that are compatible with solutions from the right operand.',
            pattern: '^Minus*',
          },
          {
            type: 'string',
            title: 'PropertyPath Operator',
            markdownDescription:
              'Evaluates a property path pattern against Stardog indexes.',
            pattern: '^PropertyPath*',
          },
          // https://stardog-union.slack.com/archives/C6D9J0EN7/p1560793289292800
          // https://www.w3.org/TR/sparql11-query/#rGroupGraphPattern
          // TODO comes up in path queries. find example.
          {
            type: 'string',
            title: 'GroupGraphPattern Operator',
            markdownDescription: '',
            pattern: '^GroupGraph*',
          },
          {
            type: 'string',
            title: 'GroupBy Operator [PIPELINE BREAKER]',
            markdownDescription:
              'Groups results of the child operator by values of the group-by expressions (i.e. keys) and aggregates solutions for each key. Pipeline breaker (unless the input is sorted by first key).',
            pattern: '^Group*', // Exclude the `By` bc the label is sometimes "Group(by=[?tp] aggregates=[(COUNT(DISTINCT ?s) AS ?count)])"
          },
          {
            type: 'string',
            title: 'Distinct Operator',
            markdownDescription:
              'Removes duplicate solutions from the input. Not a pipeline breaker but accumulates solutions as it runs so the memory pressure increases with the number of unique solutions.',
            pattern: '^Distinct*',
          },
          // https://docs.stardog.com/additional-resources/known-issues
          { type: 'string', pattern: '^Reduced*' },
          {
            type: 'string',
            title: 'VALUES Operator',
            markdownDescription:
              'Produces the inlined results specified in the query.',
            pattern: '^VALUES*',
          },
          {
            type: 'string',
            title: 'Search Operator',
            markdownDescription:
              'Evaluates a full-text search predicates against the Lucene index within a Stardog database.',
            pattern: '^Search*',
          },
          {
            type: 'string',
            title: 'Projection Operator',
            markdownDescription:
              'Projects variables as results of a query or a sub-query.',
            pattern: '^Projection*',
          },
          {
            type: 'string',
            title: 'Bind Operator',
            markdownDescription:
              'Evaluates expressions on each argument solution and binds their values to (new) variables.',
            pattern: '^Bind*',
          },
          {
            type: 'string',
            title: 'Unnest Operator',
            markdownDescription: 'Unnest array expressions.',
            pattern: '^Unnest*',
          },
          {
            type: 'string',
            title: 'Empty Operator',
            markdownDescription: 'The empty solution set.',
            pattern: '^Empty*',
          },
          {
            type: 'string',
            title: 'Singleton Operator',
            markdownDescription: 'A single empty solution.',
            pattern: '^Singleton*',
          },
          {
            type: 'string',
            title: 'Type Operator',
            markdownDescription:
              'Reasoning operator for evaluating patterns of the form `?x rdf:type ?type` or `:instance rdf:type ?type.`',
            pattern: '^Type*',
          },
          {
            type: 'string',
            title: 'Property Operator',
            markdownDescription:
              'Operator for evaluating triple patterns with unbound predicate with reasoning. Not a pipeline breaker but could be very expensive especially for large schemas. Better be avoided by either using an IRI in the predicate position or turning off reasoning for such patterns using a hint.',
            pattern: '^Property*',
          },
          {
            type: 'string',
            title: 'Service Operator',
            markdownDescription:
              'SPARQL federation operator which evaluate a pattern against a remote SPARQL endpoint or a virtual graph.',
            pattern: '^Service*',
          },
          {
            type: 'string',
            title: 'ServiceJoin Operator',
            markdownDescription:
              'A join algorithm used when one of the operators is a `Service`. Propagates bindings from the the operator to reduce the number of results coming over the network.',
            pattern: '^ServiceJoin*',
          },
          {
            type: 'string',
            title: 'VirtualGraph Operator',
            markdownDescription: '', // TODO this
            pattern: '^VirtualGraph*',
          },
          {
            type: 'string',
            title: 'Slice Operator',
            markdownDescription:
              'Combines `LIMIT` and `OFFSET` solution modifiers in SPARQL.',
            pattern: '^Slice*',
          },
          {
            type: 'string',
            title: 'OrderBy Operator',
            markdownDescription:
              'An operator which implements the `ORDER BY` solution modifier in SPARQL.',
            pattern: '^OrderBy*',
          },
          {
            type: 'string',
            title: 'SPARQL Describe Operator',
            pattern: '^Describe*',
          },
          {
            type: 'string',
            title: 'SPARQL Update Operator',
            pattern: '^ADD*',
          },
          {
            type: 'string',
            title: 'SPARQL Update Operator',
            pattern: '^CLEAR*',
          },
          {
            type: 'string',
            title: 'SPARQL Update Operator',
            pattern: '^COPY*',
          },
          {
            type: 'string',
            title: 'SPARQL Update Operator',
            pattern: '^LOAD*',
          },
          {
            type: 'string',
            title: 'SPARQL Update Operator',
            pattern: '^MOVE*',
          },
          {
            type: 'string',
            title: 'SPARQL Update Operator',
            pattern: '^DELETE*',
          },
          {
            type: 'string',
            title: 'SPARQL Update Operator',
            pattern: '^INSERT*',
          },
          {
            type: 'string',
            title: 'SPARQL Update Operator',
            pattern: '^WHERE*',
          },
          { type: 'string', title: 'Top Operator', pattern: '^Top*' },
          { type: 'string', title: 'Schema Operator', pattern: '^Schema*' },
        ],
      },
      cardinality: {
        oneOf: [
          {
            type: 'number',
            // NOTE In theory a `maximum` can be used to indicate a validation error at
            // hotspots in the query plan, but validation markers are not
            // shown when the editor is set to readOnly, which is true for
            // explanations.
            markdownDescription:
              'The # of solutions this operator is estimated to produce.',
          },
        ],
      },
      children: {
        type: 'array',
        items: { $ref: '#/definitions/planNode' },
      },
    },
    required: ['children', 'label', 'cardinality'],
  },
};

export const planNodeOperators =
  explanationSchemaDefinitions.planNode.properties.label.oneOf;

export const SPARQL_QUERY_EXPLANATION_JSON_URI =
  'http://stardog.com/studio/schemas/json/sparql/explanation';
export const SPARQL_QUERY_EXPLANATION_JSON_SCHEMA_ID =
  'SPARQL_QUERY_EXPLANATION_JSON_SCHEMA';
export const SPARQL_QUERY_EXPLANATION_NOTE_SCHEMA = {
  uri: SPARQL_QUERY_EXPLANATION_JSON_SCHEMA_ID,
  // Designed to match both the above URI and saved query plans, which by
  // default use the filename: `<note name>_sparql_explanation.json`
  fileMatch: ['*sparql*explanation*'],
  schema: {
    type: 'object',
    definitions: explanationSchemaDefinitions,
    properties: {
      prefixes: prefixesPropertySchema,
      dataset: datasetPropertySchema,
      plan: { $ref: '#definitions/planNode' },
    },
    required: ['prefixes', 'dataset', 'plan'],
  },
};

export const GRAPHQL_QUERY_EXPLANATION_JSON_URI =
  'http://stardog.com/studio/schemas/json/graphql/explanation';
const GRAPHQL_QUERY_EXPLANATION_JSON_SCHEMA_ID =
  'GRAPHQL_QUERY_EXPLANATION_JSON_SCHEMA';
export const GRAPHQL_QUERY_EXPLANATION_NOTE_SCHEMA = {
  uri: GRAPHQL_QUERY_EXPLANATION_JSON_SCHEMA_ID,
  // Designed to match both the above URI and saved query plans, which by
  // default use the filename: `<note name>_graphql_explanation.json`
  fileMatch: ['*graphql*explanation*'],
  schema: {
    type: 'object',
    // This is moved from within the `plan` property so the $ref works from
    // both places, as that path is from the root not relative to caller
    definitions: explanationSchemaDefinitions,
    properties: {
      sparql: {
        type: 'string',
        markdownDescription: 'Translation from GRAPHQL to SPARQL',
      },
      fields: {
        type: 'object',
        markdownDescription:
          'Mapping from variables in translated SPARQL query to fields in the GraphQL query.',
      },
      plan: {
        type: 'object',
        properties: {
          prefixes: prefixesPropertySchema,
          dataset: datasetPropertySchema,
          plan: { $ref: '#definitions/planNode' },
        },
        required: ['prefixes', 'dataset', 'plan'],
      },
    },
  },
};
