/* eslint-disable import/no-default-export */
import { Preferences } from 'src/common/store/preferences/PreferencesState';
import { safelyGet } from 'src/common/utils/safelyGet';
import { InitialData } from 'src/types';

/**
 * 3.5.0 didn't contain a default for fresh/new-user states,
 * so we're setting includeConstraintsInModelsHub back to true
 * (only if it has not already been set)
 *
 * Also default new toast timeout preference here to 60 seconds.
 */
export default (data: InitialData) => {
  const oldPreferences: Partial<Preferences> = safelyGet(
    data,
    ['preferences'],
    {}
  );
  const { includeConstraintsInModelsHub } = oldPreferences;
  return {
    ...data,
    version: '3.8.0',
    preferences: {
      ...oldPreferences,
      includeConstraintsInModelsHub:
        includeConstraintsInModelsHub === undefined
          ? true
          : includeConstraintsInModelsHub,
      errorToastTimeout: 60,
    },
  };
};
