import { alphaSort } from 'vet-bones/bones/utils';

import { Preferences } from 'src/common/store/preferences/PreferencesState';

const alphaSortFn = alphaSort();

function sortObject(obj: object) {
  return Object.keys(obj)
    .sort(alphaSortFn)
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
}

export const stringifyInOrder = (
  prefs: Partial<Preferences>,
  numIndentSpaces = 2
) => {
  if (!prefs) {
    return '';
  }

  const sortedPrefs = sortObject(prefs) as Partial<Preferences>;
  if (sortedPrefs.labelPredicatesByDatabase) {
    sortedPrefs.labelPredicatesByDatabase = sortObject(
      sortedPrefs.labelPredicatesByDatabase
    );
  }

  return JSON.stringify(sortedPrefs, undefined, numIndentSpaces);
};
