export const fileExtensionMatcher = /\w+\.(\w*$)/;

export const enum IMAGE_EXTENSION_TYPE {
  JPEG = 'jpeg',
  PNG = 'png',
}

export const imageExtensions = {
  fileExtensions: [IMAGE_EXTENSION_TYPE.JPEG, IMAGE_EXTENSION_TYPE.PNG],
  fileExtensionToMimeType: {
    [IMAGE_EXTENSION_TYPE.JPEG]: 'image/jpeg',
    [IMAGE_EXTENSION_TYPE.PNG]: 'image/png',
  },
};

export const sparqlExtensions = {
  fileExtensions: ['sparql', 'rq', 'sq'],
};

export const rdfFormats = [
  { contentType: 'text/turtle', label: 'Turtle' },
  { contentType: 'application/rdf+xml', label: 'RDF/XML' },
  { contentType: 'application/trig', label: 'TRiG' },
  { contentType: 'application/trix', label: 'TRiX' },
  { contentType: 'application/n-quads', label: 'NQuads' },
  { contentType: 'application/ld+json', label: 'JSON-LD' },
];

export const rdfCompressionTypes = [
  { encoding: 'gzip', label: 'GZIP' },
  { encoding: 'bzip2', label: 'BZ2' },
  { encoding: 'zip', label: 'ZIP' },
];

const rdfFileExtensions = [
  'rdf',
  'xml',
  'rdfxml',
  'owl',
  'ttl',
  'turtle',
  'trig',
  'trix',
  'nt',
  'nq',
  'json',
  'ld',
  'jsonld',
  'sms',
  'zip',
  'gz',
  'bz2',
];

const fileExtensionToMimeType = {
  rdf: 'application/rdf+xml',
  xml: 'application/rdf+xml',
  owl: 'application/rdf+xml',
  rdfxml: 'application/rdf+xml',
  turtle: 'text/turtle',
  ttl: 'text/turtle',
  trig: 'application/trig',
  trix: 'application/trix',
  nt: 'application/n-triples',
  nq: 'application/n-quads',
  ld: 'application/ld+json',
  jsonld: 'application/ld+json',
  json: 'application/ld+json',
};

const mimeTypeToFileExtension = Object.entries(fileExtensionToMimeType).reduce(
  (theMap, [key, value]) => {
    theMap[value] = key;
    return theMap;
  },
  {}
);

// FIXME shouldn't this match the `encoding` values specified in stardog.js,
// which is just this list:
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Accept-Encoding
const fileExtensionToCompressionType = {
  zip: 'zip',
  gz: 'gzip',
  bz2: 'bzip2',
};

const compressionTypeToFileExtension = Object.entries(
  fileExtensionToCompressionType
).reduce((theMap, [key, value]) => {
  theMap[value] = key;
  return theMap;
}, {});

export const rdfExtensions = {
  fileExtensions: rdfFileExtensions,
  fileExtensionToMimeType,
  mimeTypeToFileExtension,
  fileExtensionToCompressionType,
  compressionTypeToFileExtension,
  fileFilters: [
    {
      name: 'all formats',
      extensions: rdfFileExtensions,
    },
    {
      name: 'rdf',
      extensions: ['rdf', 'xml', 'rdfxml', 'owl'],
    },
    { name: 'turtle', extensions: ['ttl', 'turtle'] },
    { name: 'trig', extensions: ['trig'] },
    { name: 'trix', extensions: ['trix'] },
    { name: 'n-triples', extensions: ['nt'] },
    { name: 'n-quads', extensions: ['nq'] },
    {
      name: 'json-ld',
      extensions: ['json', 'ld', 'jsonld'],
    },
    { name: 'sms', extensions: ['sms'] },
    { name: 'zip', extensions: ['zip'] },
    { name: 'gzip', extensions: ['gz'] },
    { name: 'bzip2', extensions: ['bz2'] },
  ],
};
