import { noop } from 'vet-bones/bones/utils';

import { browserApi } from 'src/browser/apis/browserApi';
import { exportToFile } from 'src/browser/utils/exportToFile';
import { notebookActionCreators } from 'src/common/actions/notebook/notebookActionCreators';
import {
  StudioStateGetter,
  StudioThunkDispatch,
} from 'src/common/actions/StudioAction';
import { QueryTypeIdentifier } from 'src/common/constants/QueryType';
import { getQueryExportContent } from 'src/common/utils/getQueryExportContent';

// TODO: add browser support? This may not be necessary in browser, depending
// on whether or not we want to try to move exporting out of the main thread.
// @ts-ignore
export function registerExportListeners(dispatch: StudioThunkDispatch) {}

// NOTE: Currently runs on the main thread, unlike the similar method in the
// desktop app. We may want to eventually try to do this differently, but it
// works okay for now.
export const exportResultsForNote =
  ({ noteId, acceptType, ext, queryGetter = noop as any }) =>
  async (dispatch: StudioThunkDispatch, getState: StudioStateGetter) => {
    const {
      connection: { current: connection },
      notebook,
    } = getState();
    const note = notebook.notes[noteId];
    const {
      query,
      namedGraphs,
      reasoning,
      schema,
      timeout,
      languageId,
      activeDatabase: db,
      viewState,
    } = note.editorSettings;
    const { typeOfQuery, body } = note.resultSettings;
    const queryToExecute =
      queryGetter({
        query,
        noteId,
        viewState,
        languageId,
      }) || query;
    const queryName = browserApi.path.parse(noteId).name;
    let kind = 'results';
    if (typeOfQuery === QueryTypeIdentifier.EXPLAIN) {
      kind = 'explanation';
    } else if (typeOfQuery === QueryTypeIdentifier.PROFILE) {
      kind = 'profile';
    }
    const filename = `${queryName}_${languageId}_${kind}.${ext}`;

    dispatch(notebookActionCreators.exportResultsForNoteAttempt(noteId));

    try {
      let contents;
      if (typeOfQuery === QueryTypeIdentifier.SHACL_REPORT) {
        contents = body;
      } else if (
        typeOfQuery === QueryTypeIdentifier.EXPLAIN ||
        typeOfQuery === QueryTypeIdentifier.PROFILE
      ) {
        contents = body;
      } else {
        contents = await getQueryExportContent({
          query: queryToExecute,
          db,
          languageId,
          connection,
          namedGraphs,
          reasoning,
          schema,
          timeout,
          acceptType,
        });
      }

      exportToFile({ contents, filename, mimeType: acceptType });

      dispatch(
        notebookActionCreators.exportResultsForNoteSuccess(
          noteId,
          filename,
          typeOfQuery as QueryTypeIdentifier
        )
      );
    } catch (err) {
      dispatch(
        notebookActionCreators.exportResultsForNoteFailure(
          noteId,
          filename,
          typeOfQuery as QueryTypeIdentifier
        )
      );
    }
  };
