import { Preferences } from 'src/common/store/preferences/PreferencesState';
import { safelyGet } from 'src/common/utils/safelyGet';
import { InitialData } from 'src/types';

export default (data: InitialData) => {
  const oldPreferences: Partial<Preferences> = safelyGet(
    data,
    ['preferences'],
    {}
  );
  return {
    ...data,
    version: '1.14.0',
    preferences: {
      ...oldPreferences,
      labelPredicatesByDatabase: {},
    },
  };
};
