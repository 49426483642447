import { heapAgent } from 'vet-bones/bones/utils';

import {
  ConnectionAction,
  ConnectionActionType,
} from 'src/common/actions/connection/connectionActionCreators';
import { STARDOG_EXPRESS_CONNECTION_NAME } from 'src/common/constants/configuration';
import {
  ConnectionFailureReason,
  ConnectionState,
  ConnectionStatus,
  DrawerStatus,
} from 'src/common/store/connection/ConnectionState';
import { isStardogStudioWeb } from 'src/common/utils/isStardogStudioWeb';

export const initialState = new ConnectionState();

const toggleDrawerStatus = (currentDrawerStatus: DrawerStatus) => {
  return currentDrawerStatus === DrawerStatus.CLOSED
    ? DrawerStatus.OPEN
    : DrawerStatus.CLOSED;
};

export function connectionReducer(
  prevState = initialState,
  action: ConnectionAction
): ConnectionState {
  switch (action.type) {
    case ConnectionActionType.TOGGLE_DRAWER: {
      const { connectionName: selectedConnectionName = '' } = action.payload;
      return {
        ...prevState,
        drawer: {
          ...prevState.drawer,
          selectedConnectionName,
          status: toggleDrawerStatus(prevState.drawer.status),
        },
      };
    }
    case ConnectionActionType.DISCONNECT: {
      heapAgent.resetEventProperties();
      const nextState = {
        ...prevState,
        current: {
          ...prevState.current,
          status: ConnectionStatus.VIRGIN,
        },
        currentPermissions: null,
      };
      delete nextState.current.failureReason;
      return nextState;
    }
    case ConnectionActionType.SET_CONNECTION_ATTEMPT: {
      return {
        ...prevState,
        current: {
          ...prevState.current,
          endpoint: action.payload.config.endpoint,
          status: ConnectionStatus.PENDING,
        },
        currentPermissions: null,
      };
    }
    case ConnectionActionType.SET_CONNECTION_FAILURE: {
      heapAgent.resetEventProperties();
      const { status } = action.payload.response;
      let failureReason: ConnectionFailureReason;
      if (status === 401) {
        failureReason = ConnectionFailureReason.INVALID_CREDENTIALS;
      } else if (status === undefined) {
        failureReason = ConnectionFailureReason.NO_RESPONSE;
      }
      return {
        ...prevState,
        drawer: {
          ...prevState.drawer,
          status: DrawerStatus.OPEN,
        },
        current: {
          ...prevState.current,
          failureReason,
          status: ConnectionStatus.FAILURE,
        },
        currentPermissions: null,
      };
    }
    case ConnectionActionType.SET_CONNECTION_SIGNED_OUT: {
      heapAgent.resetEventProperties();
      const { config } = action.payload;
      return {
        ...prevState,
        current: {
          ...prevState.current,
          ...config,
          status: ConnectionStatus.SIGNED_OUT,
        },
        drawer: {
          ...prevState.drawer,
          status: DrawerStatus.OPEN,
        },
        currentPermissions: null,
      };
    }
    case ConnectionActionType.SET_CONNECTION_SUCCESS: {
      const { config } = action.payload;
      heapAgent.resetEventProperties({
        stardogVersion: config.stardogVersion || undefined,
        stardogLicenseType: config.stardogLicenseType || undefined,
      });
      const nextState: ConnectionState = {
        ...prevState,
        current: {
          ...prevState.current,
          ...config,
          status: ConnectionStatus.SUCCESS,
        },
        currentPermissions: null,
        drawer: {
          ...prevState.drawer,
          selectedConnectionName: '',
          status: DrawerStatus.CLOSED,
        },
      } as ConnectionState;

      // If we have successfully connected using a saved connection config and
      // the config has a password but the saved connection does not, store the
      // password in memory on the store for subsequent uses.
      if (
        config.name &&
        config.password &&
        nextState.saved[config.name] &&
        !nextState.saved[config.name].password
      ) {
        nextState.saved = {
          ...nextState.saved,
          [config.name]: {
            ...nextState.saved[config.name],
            password: config.password,
          },
        };
      }

      delete nextState.current.failureReason;
      return nextState;
    }
    case ConnectionActionType.SET_CURRENT_PERMISSIONS: {
      return {
        ...prevState,
        currentPermissions: action.payload.permissions,
      };
    }
    case ConnectionActionType.ADD_SAVED_CONNECTION: {
      const nextState = {
        ...prevState,
        saved: { ...prevState.saved },
      };
      const { connection } = action.payload;
      nextState.savedNames = [connection.name, ...nextState.savedNames];
      nextState.saved[connection.name] = connection;
      if (
        isStardogStudioWeb &&
        nextState.savedNames.includes(STARDOG_EXPRESS_CONNECTION_NAME)
      ) {
        // On stardog.studio, always keep the Sandbox connection in first place
        nextState.savedNames = [
          STARDOG_EXPRESS_CONNECTION_NAME,
          ...nextState.savedNames.filter(
            (name) => name !== STARDOG_EXPRESS_CONNECTION_NAME
          ),
        ];
      }
      return nextState;
    }
    case ConnectionActionType.SET_SELECTED_CONNECTION_NAME: {
      return {
        ...prevState,
        drawer: {
          ...prevState.drawer,
          selectedConnectionName: action.payload.selectedConnectionName,
        },
      };
    }
    case ConnectionActionType.EDIT_SAVED_CONNECTION: {
      const nextState = {
        ...prevState,
        saved: { ...prevState.saved },
      };
      const { name, connection } = action.payload;
      // if the current connection is loaded from the connection we're overwriting,
      // remove the saved properties from it since it is no longer the same
      if (nextState.current.name === name) {
        nextState.current = {
          ...nextState.current,
          name: '',
        };
      }
      const saveConnIdx = nextState.savedNames.indexOf(name);
      if (connection.name !== name) {
        nextState.savedNames = nextState.savedNames.slice(0);
        nextState.savedNames.splice(saveConnIdx, 1, connection.name);
        delete nextState.saved[name];
      }
      nextState.saved[connection.name] = connection;
      return nextState;
    }
    case ConnectionActionType.DELETE_SAVED_CONNECTION: {
      const nextState = {
        ...prevState,
        saved: { ...prevState.saved },
      };
      const { name } = action.payload;
      // if the current connection is loaded from the connection we're deleting,
      // remove the saved name from it since it is no longer saved
      if (nextState.current.name === name) {
        nextState.current = {
          ...nextState.current,
          name: '',
        };
      }
      // remove the saved connection from id list and map
      nextState.savedNames = nextState.savedNames.filter((id) => id !== name);
      delete nextState.saved[name];
      return nextState;
    }
    default:
      return prevState;
  }
}
