import {
  STARDOG_EXPRESS_CONNECTION_NAME,
  STARDOG_SANDBOX_CONNECTION_NAME,
} from 'src/common/constants/configuration';
import { SavedConnections } from 'src/common/store/connection/ConnectionState';
import { ReduxState } from 'src/types';

export const pruneSavedConnectionPasswords = (state: ReduxState) => {
  const oldSavedConnections: SavedConnections = state?.connection?.saved || {};
  const newSavedConnections: SavedConnections = {};
  Object.keys(oldSavedConnections).forEach((connectionKey) => {
    newSavedConnections[connectionKey] = {
      ...oldSavedConnections[connectionKey],
    };
    // We will keep the password only for Stardog Sandbox/Express, which is
    // already publicly available, per VET-589.
    if (
      connectionKey !== STARDOG_SANDBOX_CONNECTION_NAME &&
      connectionKey !== STARDOG_EXPRESS_CONNECTION_NAME
    ) {
      delete newSavedConnections[connectionKey].password;
    }
  });

  return {
    ...state,
    connection: {
      ...state.connection,
      saved: newSavedConnections,
    },
  };
};
