import { Spinner, SpinnerSize } from '@blueprintjs/core';
import * as React from 'react';

interface LoadingProps {
  message?: string;
}

export const LoadingScreen = (props: LoadingProps) => (
  <div
    className="sd-loading-screen"
    style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      backgroundColor: 'transparent',
    }}
  >
    <Spinner size={SpinnerSize.LARGE} />
    {props.message}
  </div>
);
