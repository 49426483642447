import omit from 'lodash.omit';
import { user } from 'stardog';
import { handleSessionTimeoutRedirectResponse } from 'vet-bones/bones/utils';
import { isPortal } from 'vet-bones/bones/utils/portal';

import { UserConfig } from 'src/common/store/connection/ConnectionState';
import { getStardogConnection } from 'src/common/utils/connection/getStardogConnection';

export const upgradeConnection = async (
  connection: UserConfig
): Promise<UserConfig> => {
  if (isPortal()) {
    return connection;
  }

  let upgradeResponse;

  try {
    upgradeResponse = await user.token(getStardogConnection(connection));
  } catch (_error) {
    // We assume if there was an error the endpoint does not support
    // tokens or the token is invalid in some unexpected way so remove it.
    return omit(connection, 'token');
  }

  const isSessionTimeoutRedirectResponse =
    handleSessionTimeoutRedirectResponse(upgradeResponse);

  if (!upgradeResponse.ok || isSessionTimeoutRedirectResponse) {
    // Clear any token that is stored on the connection so we can prompt the
    // user to re-enter their password.
    return omit(connection, 'token');
  }

  const token = upgradeResponse?.body?.token;
  const endpoint = upgradeResponse?.body?.endpoint || connection.endpoint;
  const username = upgradeResponse?.body?.username || connection.username;

  return {
    ...connection,
    token,
    username,
    endpoint,
  };
};
