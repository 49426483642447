import { server, user } from 'stardog';

import { request } from 'src/common/actions/request';
import { Scope } from 'src/common/actions/request/types';
import {
  ActionCreatorAction,
  createTypedAction,
} from 'src/common/actions/types';
import {
  SavedConnection,
  UserConfig,
} from 'src/common/store/connection/ConnectionState';
import { Permission } from 'src/common/store/security/SecurityState';

export const enum ConnectionActionType {
  TOGGLE_DRAWER = 'TOGGLE_DRAWER',
  DISCONNECT = 'DISCONNECT',

  SET_CONNECTION_ATTEMPT = 'SET_CONNECTION_ATTEMPT',
  SET_CONNECTION_SUCCESS = 'SET_CONNECTION_SUCCESS',
  SET_CONNECTION_FAILURE = 'SET_CONNECTION_FAILURE',
  SET_CONNECTION_SIGNED_OUT = 'SET_CONNECTION_SIGNED_OUT',

  SET_CURRENT_PERMISSIONS = 'SET_CURRENT_PERMISSIONS',

  VALIDATE_CREDENTIALS_ATTEMPT = 'VALIDATE_CREDENTIALS_ATTEMPT',
  VALIDATE_CREDENTIALS_FAILURE = 'VALIDATE_CREDENTIALS_FAILURE',
  VALIDATE_CREDENTIALS_SUCCESS = 'VALIDATE_CREDENTIALS_SUCCESS',

  REQUEST_SERVER_STATUS_ATTEMPT = 'REQUEST_SERVER_STATUS_ATTEMPT',
  REQUEST_SERVER_STATUS_FAILURE = 'REQUEST_SERVER_STATUS_FAILURE',
  REQUEST_SERVER_STATUS_SUCCESS = 'REQUEST_SERVER_STATUS_SUCCESS',

  ADD_SAVED_CONNECTION = 'ADD_SAVED_CONNECTION',
  EDIT_SAVED_CONNECTION = 'EDIT_SAVED_CONNECTION',
  DELETE_SAVED_CONNECTION = 'DELETE_SAVED_CONNECTION',

  SET_SELECTED_CONNECTION_NAME = 'SET_SELECTED_CONNECTION_NAME',
}

export const connectionActionCreators = {
  toggleAppDrawer: (withConnectionName?: string) =>
    createTypedAction(ConnectionActionType.TOGGLE_DRAWER, {
      connectionName: withConnectionName,
    }),
  disconnect: () => createTypedAction(ConnectionActionType.DISCONNECT),
  setConnectionAttempt: (config: UserConfig) =>
    createTypedAction(ConnectionActionType.SET_CONNECTION_ATTEMPT, {
      config,
    }),
  setConnectionFailure: (config: UserConfig, response) =>
    createTypedAction(ConnectionActionType.SET_CONNECTION_FAILURE, {
      config,
      response,
    }),
  setConnectionSuccess: (config: UserConfig, response) =>
    createTypedAction(ConnectionActionType.SET_CONNECTION_SUCCESS, {
      config,
      response,
    }),
  setConnectionSignedOut: (config: UserConfig) =>
    createTypedAction(ConnectionActionType.SET_CONNECTION_SIGNED_OUT, {
      config,
    }),
  setCurrentPermissions: (permissions: Permission[]) =>
    createTypedAction(ConnectionActionType.SET_CURRENT_PERMISSIONS, {
      permissions,
    }),
  addSavedConnection: (connection: SavedConnection) =>
    createTypedAction(ConnectionActionType.ADD_SAVED_CONNECTION, {
      connection,
    }),
  editSavedConnection: (name: string, connection: SavedConnection) =>
    createTypedAction(ConnectionActionType.EDIT_SAVED_CONNECTION, {
      name,
      connection,
    }),
  deleteSavedConnection: (name: string) =>
    createTypedAction(ConnectionActionType.DELETE_SAVED_CONNECTION, { name }),
  setSelectedConnectionName: (selectedConnectionName: string) =>
    createTypedAction(ConnectionActionType.SET_SELECTED_CONNECTION_NAME, {
      selectedConnectionName,
    }),
};

export const connectionStardogRequestDispatchers = {
  validateCredentials: (userConfig: UserConfig) =>
    request({
      api: user.valid,
      args: [],
      loading: ConnectionActionType.VALIDATE_CREDENTIALS_ATTEMPT,
      failure: ConnectionActionType.VALIDATE_CREDENTIALS_FAILURE,
      success: ConnectionActionType.VALIDATE_CREDENTIALS_SUCCESS,
      shouldNotDispatch: true,
      action: {
        config: userConfig,
      },
      scope: Scope.GLOBAL,
    }),
  requestServerStatus: ({
    userConfig,
    params,
  }: {
    userConfig: UserConfig;
    params: Parameters<typeof server.status>[1];
  }) =>
    request({
      api: server.status,
      args: params ? [params] : [],
      loading: ConnectionActionType.REQUEST_SERVER_STATUS_ATTEMPT,
      failure: ConnectionActionType.REQUEST_SERVER_STATUS_FAILURE,
      success: ConnectionActionType.REQUEST_SERVER_STATUS_SUCCESS,
      action: {
        config: userConfig,
      },
      shouldNotDispatch: true,
    }),
};

const connectionActionTypeMap = {
  ...connectionActionCreators,
  ...connectionStardogRequestDispatchers,
};

export type ConnectionAction = ActionCreatorAction<
  typeof connectionActionTypeMap
>;
