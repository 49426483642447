import {
  ActionCreatorAction,
  createTypedAction,
} from 'src/common/actions/types';
import { Preferences } from 'src/common/store/preferences/PreferencesState';

export enum PreferencesActionType {
  SET_PREFERENCE = 'SET_PREFERENCE',
  SET_PREFERENCES = 'SET_PREFERENCES',
}

export type PreferencesAction = ActionCreatorAction<
  typeof preferencesActionCreators
>;

export const preferencesActionCreators = {
  setPreference: <T extends keyof Preferences>(
    key: T,
    value: Preferences[T],
    preferences: Preferences
  ) =>
    createTypedAction(PreferencesActionType.SET_PREFERENCE, {
      key,
      value,
      preferences,
    }),
  setPreferences: (preferences: Preferences) =>
    createTypedAction(PreferencesActionType.SET_PREFERENCES, { preferences }),
};
