import { query as stardogJsQuery } from 'stardog';

import {
  QueriesAction,
  QueriesActionType,
} from 'src/common/actions/queries/queriesActionCreators';
import { QueriesState } from 'src/common/store/queries/QueriesState';

const initialState = new QueriesState();

const queriesReducer = (prevState = initialState, action: QueriesAction) => {
  switch (action.type) {
    case QueriesActionType.KILL_QUERY_SUCCESS: {
      const { associatedNoteId } = action.payload.action;
      return {
        ...prevState,
        queries: prevState.queries.filter((q) => q.id !== associatedNoteId),
      };
    }
    case QueriesActionType.KILL_QUERIES_SUCCESS: {
      const killedIds = action.payload.successQueryIds;
      return {
        ...prevState,
        queries: prevState.queries.filter((q) => !killedIds.includes(q.id)),
      };
    }
    case QueriesActionType.KILL_QUERIES_FAILURE: {
      const killedIds = action.payload.successQueryIds;
      return killedIds.length
        ? {
            ...prevState,
            queries: prevState.queries.filter((q) => !killedIds.includes(q.id)),
          }
        : prevState;
    }
    case QueriesActionType.POPULATE_QUERIES_SUCCESS: {
      const queries = (action.payload?.response?.body?.queries || []).map(
        (query) => {
          const type =
            query.queryType ||
            stardogJsQuery.utils.queryType(query.query || '') ||
            '';
          return {
            ...query,
            type,
          };
        }
      );

      if (queries.length !== prevState.queries.length) {
        return {
          ...prevState,
          queries,
        };
      }

      // Since this action fires over and over again at an interval, we want
      // to be safe not to trigger unnecessary re-renders, so we check for
      // equality and do not create "new" state (by spreading prevState) if
      // all queries are equal.
      const areQueriesEqual = queries.every((query, idx) => {
        const prevQuery = prevState.queries[idx];

        if (!prevQuery) {
          return false;
        }

        return Object.keys(query).every((key) => query[key] === prevQuery[key]);
      });

      if (!areQueriesEqual) {
        return {
          ...prevState,
          queries,
        };
      }

      return prevState;
    }
    case QueriesActionType.SET_REFRESH_RATE:
      return {
        ...prevState,
        refreshRate: action.payload.refreshRate,
      };
    // TODO: Failure handling
    default:
      return prevState;
  }
};

export { queriesReducer };
