import clonedeep from 'lodash.clonedeep';

import { getRootReducer } from 'src/common/utils/getRootReducer';

export const appReducer = getRootReducer();

const initialState = appReducer(undefined, {
  type: '',
});

// return default initial state
export const createInitialState = () => clonedeep(initialState);
