export const opacity = (color: string, num: number) =>
  color + Math.ceil(255 * num).toString(16);

/**
 * replicate sass lighten and darken
 *
 * @see             https://www.sitepoint.com/javascript-generate-lighter-darker-color/
 * @param  {string} hexColor the original hex string for the color
 * @param  {number} lumPercent the luminosity in percent as a decimal. Negative for darken and positive for lighten
 * @return {string}     the transformed hex string
 */

export const luminance = (hexColor: string, lumPercent: number): string => {
  // validate hex string
  let hex = String(hexColor).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 3) {
    return hexColor;
  }
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  const lum = lumPercent || 0;

  // convert to decimal and change luminosity
  let hexTransformed = '#';

  for (let i = 0; i < 3; i += 1) {
    const intPart = parseInt(hex.substr(i * 2, 2), 16);
    const hexPart = Math.round(
      Math.min(Math.max(0, intPart + intPart * lum), 255)
    ).toString(16);
    hexTransformed += `00${hexPart}`.substr(hexPart.length);
  }
  return hexTransformed;
};
