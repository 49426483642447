import {
  AnchorButton,
  Classes,
  Divider,
  H6,
  Icon,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  Tooltip,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { getCurrentConnectionInfo } from 'portal-sdk';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  redirectToPortalHome,
  redirectToPortalProduct,
  usePortalConnections,
  usePortalUser,
} from 'vet-bones/bones/utils/portal';

import { TEST_IDS } from 'src/common/constants/testIds';
import { isConnectionConnected } from 'src/common/utils/isConnectionConnected';
import { ReduxState } from 'src/types';

export const CONNECTED_ICON_COLOR = '#92da92';
export const DISCONNECTED_ICON_COLOR = '#b36767';

const mapStateToProps = ({ connection }: ReduxState) => {
  const { current: currentConnection } = connection;

  return {
    isConnected: isConnectionConnected(currentConnection),
  };
};

type PortalConnectionButtonStateProps = ReturnType<typeof mapStateToProps>;

const UnconnectedPortalConnectionButton: React.VFC<PortalConnectionButtonStateProps> =
  ({ isConnected }) => {
    const portalUser = usePortalUser();
    const portalConnections = usePortalConnections();
    const portalAccountName =
      portalUser.data?.email || portalUser.data?.username;

    const connectionTooltipContent = (
      <div data-testid={TEST_IDS.tooltip.content}>
        <H6 className="sd-main-nav-tooltip-content__header">
          Stardog Cloud Account
        </H6>
        <span>{portalAccountName}</span>
      </div>
    );

    const urlInfo = getCurrentConnectionInfo(window);

    const menu = (
      <Menu data-testid={TEST_IDS.portal.connectMenu}>
        <MenuDivider title={portalAccountName} />

        {portalConnections.data?.map(({ id, name, index, dashboard }) => (
          <MenuItem
            key={id}
            active={index === urlInfo?.connectionIndex}
            disabled={!dashboard || !urlInfo?.product}
            icon={IconNames.OFFLINE}
            onClick={() => {
              if (
                dashboard &&
                index !== urlInfo?.connectionIndex &&
                urlInfo?.product
              ) {
                redirectToPortalProduct(window, dashboard, urlInfo.product);
              }
            }}
            text={name}
          />
        ))}

        <Divider />

        <MenuItem
          icon={IconNames.HOME}
          onClick={() => {
            redirectToPortalHome(window);
          }}
          text="Stardog Cloud"
        />
      </Menu>
    );

    return (
      <Popover content={menu}>
        <Tooltip content={connectionTooltipContent}>
          <AnchorButton
            className={Classes.TAB}
            icon={
              <Icon
                color={
                  isConnected ? CONNECTED_ICON_COLOR : DISCONNECTED_ICON_COLOR
                }
                data-testid={TEST_IDS.portal.connectButton}
                icon={IconNames.OFFLINE}
              />
            }
          />
        </Tooltip>
      </Popover>
    );
  };

export const PortalConnectionButton = connect<PortalConnectionButtonStateProps>(
  mapStateToProps
)(UnconnectedPortalConnectionButton);
