import {
  STARDOG_EXPRESS_CONNECTION_NAME,
  STARDOG_SANDBOX_CONNECTION_NAME,
} from 'src/common/constants/configuration';
import {
  Connection,
  ConnectionStatus,
} from 'src/common/store/connection/ConnectionState';
import { ReduxState } from 'src/types';

export const resetCurrentConnectionState = (state: ReduxState) => {
  const { current: currentConnection } = state.connection;
  const nextCurrentConnectionState: Connection = {
    ...currentConnection,
    status: ConnectionStatus.VIRGIN, // We never want to save the current connection's status to disk, in the off-chance that it's PENDING and the user ends up stuck with that.
  };

  if (
    currentConnection.name !== STARDOG_SANDBOX_CONNECTION_NAME &&
    currentConnection.name !== STARDOG_EXPRESS_CONNECTION_NAME
  ) {
    delete nextCurrentConnectionState.password;
  }

  return {
    ...state,
    connection: {
      ...state.connection,
      current: nextCurrentConnectionState,
      currentPermissions: null,
    },
  };
};
