import { AnchorButton } from '@blueprintjs/core';
import * as React from 'react';

type NotificationAction = {
  onClick: () => void;
  text: string;
};

export type ActionableNotificationMessageProps = {
  actions: NotificationAction[];
  closeNotification?: (id: string) => void;
  message: string;
  notificationId?: string;
  testId?: string;
};

export const ActionableNotificationMessage = ({
  actions,
  closeNotification,
  message,
  notificationId,
  testId,
}: ActionableNotificationMessageProps) => {
  const actionButtons = actions.map((action, idx) => (
    <AnchorButton
      key={`${notificationId}-${idx}`}
      minimal
      onClick={() => {
        action.onClick();
        if (closeNotification && notificationId) {
          closeNotification(notificationId);
        }
      }}
      text={action.text}
    />
  ));
  return (
    <>
      <div className="sd-toast-actionable-message" data-testid={testId}>
        <span>{message}</span>
      </div>
      <div className="sd-toast-actionable-actions">{actionButtons}</div>
    </>
  );
};
