import { user } from 'stardog';

import { request } from 'src/common/actions/request';
import {
  ActionCreatorAction,
  createTypedAction,
} from 'src/common/actions/types';
import { UserConfig } from 'src/common/store/connection/ConnectionState';
import {
  Permission,
  SecurityMosaicStateChange,
  SortPermissionsParams,
} from 'src/common/store/security/SecurityState';

export const enum SecurityActionType {
  REQUEST_USERS_ATTEMPT = 'REQUEST_USERS_ATTEMPT',
  REQUEST_USERS_SUCCESS = 'REQUEST_USERS_SUCCESS',
  REQUEST_USERS_FAILURE = 'REQUEST_USERS_FAILURE',
  REQUEST_CURRENT_USER_ATTEMPT = 'REQUEST_CURRENT_USER_ATTEMPT',
  REQUEST_CURRENT_USER_SUCCESS = 'REQUEST_CURRENT_USER_SUCCESS',
  REQUEST_CURRENT_USER_FAILURE = 'REQUEST_CURRENT_USER_FAILURE',
  REQUEST_DETAILS_FOR_USER_ATTEMPT = 'REQUEST_DETAILS_FOR_USER_ATTEMPT',
  REQUEST_DETAILS_FOR_USER_SUCCESS = 'REQUEST_DETAILS_FOR_USER_SUCCESS',
  REQUEST_DETAILS_FOR_USER_FAILURE = 'REQUEST_DETAILS_FOR_USER_FAILURE',
  REQUEST_USERS_LIST_INFO_ATTEMPT = 'REQUEST_USERS_LIST_INFO_ATTEMPT',
  REQUEST_USERS_LIST_INFO_SUCCESS = 'REQUEST_USERS_LIST_INFO_SUCCESS',
  REQUEST_USERS_LIST_INFO_FAILURE = 'REQUEST_USERS_LIST_INFO_FAILURE',
  REQUEST_ROLES_ATTEMPT = 'REQUEST_ROLES_ATTEMPT',
  REQUEST_ROLES_SUCCESS = 'REQUEST_ROLES_SUCCESS',
  REQUEST_ROLES_FAILURE = 'REQUEST_ROLES_FAILURE',
  REQUEST_DETAILS_FOR_ROLE_ATTEMPT = 'REQUEST_DETAILS_FOR_ROLE_ATTEMPT',
  REQUEST_DETAILS_FOR_ROLE_SUCCESS = 'REQUEST_DETAILS_FOR_ROLE_SUCCESS',
  REQUEST_DETAILS_FOR_ROLE_FAILURE = 'REQUEST_DETAILS_FOR_ROLE_FAILURE',
  REQUEST_ROLES_LIST_INFO_ATTEMPT = 'REQUEST_ROLES_LIST_INFO_ATTEMPT',
  REQUEST_ROLES_LIST_INFO_SUCCESS = 'REQUEST_ROLES_LIST_INFO_SUCCESS',
  REQUEST_ROLES_LIST_INFO_FAILURE = 'REQUEST_ROLES_LIST_INFO_FAILURE',
  TOGGLE_USER_ENABLED_ATTEMPT = 'TOGGLE_USER_ENABLED_ATTEMPT',
  TOGGLE_USER_ENABLED_SUCCESS = 'TOGGLE_USER_ENABLED_SUCCESS',
  TOGGLE_USER_ENABLED_FAILURE = 'TOGGLE_USER_ENABLED_FAILURE',
  DELETE_USER_ATTEMPT = 'DELETE_USER_ATTEMPT',
  DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE = 'DELETE_USER_FAILURE',
  ADD_USER_ATTEMPT = 'ADD_USER_ATTEMPT',
  ADD_USER_SUCCESS = 'ADD_USER_SUCCESS',
  ADD_USER_FAILURE = 'ADD_USER_FAILURE',
  DELETE_ROLE_ATTEMPT = 'DELETE_ROLE_ATTEMPT',
  DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS',
  DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE',
  ADD_ROLE_ATTEMPT = 'ADD_ROLE_ATTEMPT',
  ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS',
  ADD_ROLE_FAILURE = 'ADD_ROLE_FAILURE',
  SET_ROLES_FOR_USER_ATTEMPT = 'SET_ROLES_FOR_USER_ATTEMPT',
  SET_ROLES_FOR_USER_SUCCESS = 'SET_ROLES_FOR_USER_SUCCESS',
  SET_ROLES_FOR_USER_FAILURE = 'SET_ROLES_FOR_USER_FAILURE',
  CHANGE_PASSWORD_FOR_USER_ATTEMPT = 'CHANGE_PASSWORD_FOR_USER_ATTEMPT',
  CHANGE_PASSWORD_FOR_USER_SUCCESS = 'CHANGE_PASSWORD_FOR_USER_SUCCESS',
  CHANGE_PASSWORD_FOR_USER_FAILURE = 'CHANGE_PASSWORD_FOR_USER_FAILURE',
  ADD_PERMISSION_FOR_ROLE_ATTEMPT = 'ADD_PERMISSION_FOR_ROLE_ATTEMPT',
  ADD_PERMISSION_FOR_ROLE_SUCCESS = 'ADD_PERMISSION_FOR_ROLE_SUCCESS',
  ADD_PERMISSION_FOR_ROLE_FAILURE = 'ADD_PERMISSION_FOR_ROLE_FAILURE',
  ADD_PERMISSION_FOR_USER_ATTEMPT = 'ADD_PERMISSION_FOR_USER_ATTEMPT',
  ADD_PERMISSION_FOR_USER_SUCCESS = 'ADD_PERMISSION_FOR_USER_SUCCESS',
  ADD_PERMISSION_FOR_USER_FAILURE = 'ADD_PERMISSION_FOR_USER_FAILURE',
  DELETE_PERMISSION_FOR_ROLE_ATTEMPT = 'DELETE_PERMISSION_FOR_ROLE_ATTEMPT',
  DELETE_PERMISSION_FOR_ROLE_SUCCESS = 'DELETE_PERMISSION_FOR_ROLE_SUCCESS',
  DELETE_PERMISSION_FOR_ROLE_FAILURE = 'DELETE_PERMISSION_FOR_ROLE_FAILURE',
  DELETE_PERMISSION_FOR_USER_ATTEMPT = 'DELETE_PERMISSION_FOR_USER_ATTEMPT',
  DELETE_PERMISSION_FOR_USER_SUCCESS = 'DELETE_PERMISSION_FOR_USER_SUCCESS',
  DELETE_PERMISSION_FOR_USER_FAILURE = 'DELETE_PERMISSION_FOR_USER_FAILURE',
  SELECT_ROLE = 'SELECT_ROLE',
  SELECT_USER = 'SELECT_USER',
  SET_SORT = 'SET_SORT',
  UPDATE_SECURITY_MOSAIC_STATE = 'UPDATE_SECURITY_MOSAIC_STATE',
}

export type SecurityAction = ActionCreatorAction<
  typeof securityActionCreatorMap
>;

export const securityActionCreators = {
  selectRole: (role: string) =>
    createTypedAction(SecurityActionType.SELECT_ROLE, {
      role,
    }),
  selectUser: (user: string) =>
    createTypedAction(SecurityActionType.SELECT_USER, {
      user,
    }),
  setSort: ({ which, column, direction, username }: SortPermissionsParams) =>
    createTypedAction(SecurityActionType.SET_SORT, {
      which,
      column,
      direction,
      username,
    }),
  updateSecurityMosaicState: (mosaicStateChange: SecurityMosaicStateChange) =>
    createTypedAction(SecurityActionType.UPDATE_SECURITY_MOSAIC_STATE, {
      mosaicStateChange,
    }),
};

export const securityStardogRequestDispatchers = {
  addPermissionForRole: ({ permission, roleId }) =>
    request({
      api: user.role.assignPermission,
      args: [
        roleId,
        {
          action: permission.action,
          resourceType: permission.resource_type, // stardog.js discrepancy
          resources: permission.resource, // stardog.js discrepancy
        },
      ],
      action: {},
      loading: SecurityActionType.ADD_PERMISSION_FOR_ROLE_ATTEMPT,
      success: SecurityActionType.ADD_PERMISSION_FOR_ROLE_SUCCESS,
      failure: SecurityActionType.ADD_PERMISSION_FOR_ROLE_FAILURE,
    }),
  addPermissionForUser: (userId: string, permission: Permission) =>
    request({
      api: user.assignPermission,
      args: [
        userId,
        {
          action: permission.action,
          resourceType: permission.resource_type, // stardog.js discrepancy
          resources: permission.resource, // stardog.js discrepancy
        },
      ],
      action: {
        explicit: permission.explicit,
      },
      loading: SecurityActionType.ADD_PERMISSION_FOR_USER_ATTEMPT,
      success: SecurityActionType.ADD_PERMISSION_FOR_USER_SUCCESS,
      failure: SecurityActionType.ADD_PERMISSION_FOR_USER_FAILURE,
    }),
  addRole: ({ rolename }: { rolename: string }) =>
    request({
      api: user.role.create,
      args: [{ name: rolename }],
      action: {},
      loading: SecurityActionType.ADD_ROLE_ATTEMPT,
      success: SecurityActionType.ADD_ROLE_SUCCESS,
      failure: SecurityActionType.ADD_ROLE_FAILURE,
    }),
  addUser: ({
    username,
    password,
    isSuperuser,
  }: {
    username: string;
    password: string;
    isSuperuser: boolean;
  }) =>
    request({
      api: user.create,
      args: [
        {
          username,
          password,
          superuser: isSuperuser,
        },
      ],
      action: {},
      loading: SecurityActionType.ADD_USER_ATTEMPT,
      success: SecurityActionType.ADD_USER_SUCCESS,
      failure: SecurityActionType.ADD_USER_FAILURE,
    }),
  changePasswordForUser: ({
    userId,
    newPassword,
  }: {
    userId: string;
    newPassword: string;
  }) =>
    request({
      api: user.changePassword,
      args: [userId, newPassword],
      action: {},
      loading: SecurityActionType.CHANGE_PASSWORD_FOR_USER_ATTEMPT,
      success: SecurityActionType.CHANGE_PASSWORD_FOR_USER_SUCCESS,
      failure: SecurityActionType.CHANGE_PASSWORD_FOR_USER_FAILURE,
    }),
  deletePermissionForRole: ({ permission, roleId }) =>
    request({
      api: user.role.deletePermission,
      args: [
        roleId,
        {
          action: permission.action,
          resourceType: permission.resource_type,
          resources: permission.resource,
        },
      ],
      action: {},
      loading: SecurityActionType.DELETE_PERMISSION_FOR_ROLE_ATTEMPT,
      success: SecurityActionType.DELETE_PERMISSION_FOR_ROLE_SUCCESS,
      failure: SecurityActionType.DELETE_PERMISSION_FOR_ROLE_FAILURE,
    }),
  deletePermissionForUser: (userId: string, permission: Permission) =>
    request({
      api: user.deletePermission,
      args: [
        userId,
        {
          action: permission.action,
          resourceType: permission.resource_type,
          resources: permission.resource,
        },
      ],
      action: {},
      loading: SecurityActionType.DELETE_PERMISSION_FOR_USER_ATTEMPT,
      success: SecurityActionType.DELETE_PERMISSION_FOR_USER_SUCCESS,
      failure: SecurityActionType.DELETE_PERMISSION_FOR_USER_FAILURE,
    }),
  deleteRole: ({ roleId }: { roleId: string }) =>
    request({
      api: user.role.remove,
      args: [roleId],
      action: {},
      loading: SecurityActionType.DELETE_ROLE_ATTEMPT,
      success: SecurityActionType.DELETE_ROLE_SUCCESS,
      failure: SecurityActionType.DELETE_ROLE_FAILURE,
    }),
  deleteUser: ({ userId }: { userId: string }) =>
    request({
      api: user.remove,
      args: [userId],
      action: {},
      loading: SecurityActionType.DELETE_USER_ATTEMPT,
      success: SecurityActionType.DELETE_USER_SUCCESS,
      failure: SecurityActionType.DELETE_USER_FAILURE,
    }),
  requestCurrentUser: (config: UserConfig) =>
    request({
      api: user.whoAmI,
      args: [],
      loading: SecurityActionType.REQUEST_CURRENT_USER_ATTEMPT,
      success: SecurityActionType.REQUEST_CURRENT_USER_SUCCESS,
      failure: SecurityActionType.REQUEST_CURRENT_USER_FAILURE,
      action: { config },
    }),
  requestDetailsForRole: ({
    roleId,
    shouldNotDispatch,
  }: {
    roleId: string;
    shouldNotDispatch?: boolean;
  }) =>
    request({
      api: user.role.permissions,
      args: [roleId],
      action: {},
      loading: SecurityActionType.REQUEST_DETAILS_FOR_ROLE_ATTEMPT,
      success: SecurityActionType.REQUEST_DETAILS_FOR_ROLE_SUCCESS,
      failure: SecurityActionType.REQUEST_DETAILS_FOR_ROLE_FAILURE,
      shouldNotDispatch,
    }),
  requestRolesListInfo: () =>
    request({
      api: user.role.listInfo,
      args: [],
      action: {},
      loading: SecurityActionType.REQUEST_ROLES_LIST_INFO_ATTEMPT,
      success: SecurityActionType.REQUEST_ROLES_LIST_INFO_SUCCESS,
      failure: SecurityActionType.REQUEST_ROLES_LIST_INFO_FAILURE,
    }),
  requestDetailsForUser: ({
    username,
    shouldNotDispatch,
  }: {
    username: string;
    shouldNotDispatch?: boolean;
  }) =>
    request({
      api: user.get,
      args: [username],
      action: {
        isLogin: false,
      },
      loading: SecurityActionType.REQUEST_DETAILS_FOR_USER_ATTEMPT,
      success: SecurityActionType.REQUEST_DETAILS_FOR_USER_SUCCESS,
      failure: SecurityActionType.REQUEST_DETAILS_FOR_USER_FAILURE,
      shouldNotDispatch,
    }),
  requestUsersListInfo: () =>
    request({
      api: user.listInfo,
      args: [],
      action: {},
      loading: SecurityActionType.REQUEST_USERS_LIST_INFO_ATTEMPT,
      success: SecurityActionType.REQUEST_USERS_LIST_INFO_SUCCESS,
      failure: SecurityActionType.REQUEST_USERS_LIST_INFO_FAILURE,
    }),
  requestRoles: () =>
    request({
      api: user.role.list,
      args: [],
      action: {},
      loading: SecurityActionType.REQUEST_ROLES_ATTEMPT,
      success: SecurityActionType.REQUEST_ROLES_SUCCESS,
      failure: SecurityActionType.REQUEST_ROLES_FAILURE,
    }),
  requestUsers: () =>
    request({
      api: user.list,
      args: [],
      action: {},
      loading: SecurityActionType.REQUEST_USERS_ATTEMPT,
      success: SecurityActionType.REQUEST_USERS_SUCCESS,
      failure: SecurityActionType.REQUEST_USERS_FAILURE,
    }),
  setRolesForUser: ({ roleIds, userId }) =>
    request({
      api: user.setRoles,
      args: [userId, roleIds],
      action: {},
      loading: SecurityActionType.SET_ROLES_FOR_USER_ATTEMPT,
      success: SecurityActionType.SET_ROLES_FOR_USER_SUCCESS,
      failure: SecurityActionType.SET_ROLES_FOR_USER_FAILURE,
    }),
  toggleUserEnabled: ({
    userId,
    enable,
  }: {
    userId: string;
    enable: boolean;
  }) =>
    request({
      api: user.enable,
      args: [userId, enable],
      action: {},
      loading: SecurityActionType.TOGGLE_USER_ENABLED_ATTEMPT,
      success: SecurityActionType.TOGGLE_USER_ENABLED_SUCCESS,
      failure: SecurityActionType.TOGGLE_USER_ENABLED_FAILURE,
    }),
};

const securityActionCreatorMap = {
  ...securityActionCreators,
  ...securityStardogRequestDispatchers,
};
