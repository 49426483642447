import * as Monaco from 'monaco-editor/esm/vs/editor/editor.api';

import {
  NotebookAction,
  notebookActionCreators,
} from 'src/common/actions/notebook/notebookActionCreators';
import {
  StudioStateGetter,
  StudioThunkDispatch,
} from 'src/common/actions/StudioAction';
import {
  LanguageId,
  getLanguageIdAlias,
} from 'src/common/constants/LanguageId';

export const setLanguageIdForActiveNote =
  (languageId: LanguageId) =>
  (
    dispatch: StudioThunkDispatch<NotebookAction>,
    getState: StudioStateGetter
  ) => {
    const noteId = getState().notebook.activeNoteId;

    // We would prefer not to set the language on the monaco global here
    // because we resent having to use a global at all and because we believe
    // action creators should not produce side effects. However, if we do not
    // update the model before rerenders occur then the document will not be
    // registered by its appropriate LanguageClient and any monaco events that
    // fire as a result of the Editor re-mounting could trigger attempts to
    // communicate to the LanguageServer of the now out-of-date languageId and
    // that LanguageServer could be in the process of shutting down.
    const model = Monaco.editor.getModel(Monaco.Uri.file(noteId));
    Monaco.editor.setModelLanguage(model, getLanguageIdAlias(languageId));

    return dispatch(
      notebookActionCreators.setLanguageIdForNote({
        noteId,
        languageId,
      })
    );
  };
