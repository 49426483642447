import { Classes, Dialog } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import omit from 'lodash.omit';
import { getCurrentConnectionInfo } from 'portal-sdk';
import * as React from 'react';
import { connect } from 'react-redux';
import { usePortalConnections } from 'vet-bones/bones/utils/portal';

import { setConnection } from 'src/common/actions/connection/action-creators/stardog-api/setConnection';
import { connectionActionCreators } from 'src/common/actions/connection/connectionActionCreators';
import { StudioThunkDispatch } from 'src/common/actions/StudioAction';
import { TEST_IDS } from 'src/common/constants/testIds';
import { SignInForm } from 'src/common/containers/Login/SignInForm';
import {
  Connection,
  ConnectionStatus,
  DrawerStatus,
} from 'src/common/store/connection/ConnectionState';
import { ReduxState } from 'src/types';

function mapStateToProps({ connection }: ReduxState) {
  const { current, drawer, saved, savedNames } = connection;
  const isOpen =
    (current.status === ConnectionStatus.FAILURE ||
      current.status === ConnectionStatus.SIGNED_OUT) &&
    drawer.status === DrawerStatus.OPEN;

  return {
    connection: current,
    isOpen,
    savedConnections: saved,
    savedConnectionNames: savedNames,
    selectedConnectionName: drawer.selectedConnectionName,
  };
}

function mapDispatchToProps(dispatch: StudioThunkDispatch) {
  return {
    toggleAppDrawer: () => dispatch(connectionActionCreators.toggleAppDrawer()),
    setConnection: (connection: Connection, shouldSaveConnection?: boolean) =>
      dispatch(setConnection(connection, shouldSaveConnection)),
  };
}

type PortalLoginDialogStateProps = ReturnType<typeof mapStateToProps>;
type PortalLoginDialogDispatchProps = ReturnType<typeof mapDispatchToProps>;
type PortalLoginDialogCombinedProps = PortalLoginDialogStateProps &
  PortalLoginDialogDispatchProps;

const UnconnectedPortalLoginDialog: React.VFC<PortalLoginDialogCombinedProps> =
  ({ connection, isOpen, setConnection, toggleAppDrawer }) => {
    const portalConnections = usePortalConnections();
    const connectionIndex = getCurrentConnectionInfo(window)?.connectionIndex;

    const currentPortalConnection = portalConnections?.data?.find(
      ({ index }) => index === connectionIndex
    );

    const handleClose = () => {
      toggleAppDrawer();
    };

    return (
      <Dialog
        className="sd-dialog-connect"
        icon={IconNames.OFFLINE}
        isOpen={isOpen && Boolean(currentPortalConnection)}
        onClose={handleClose}
        title="Sign In"
      >
        <div
          className="sd-dialog-login"
          data-testid={TEST_IDS.portal.loginDialog}
        >
          <div className="sd-sign-in__title">
            <div className={Classes.TEXT_LARGE}>
              <b>{currentPortalConnection?.name}</b>
            </div>
            <div>{currentPortalConnection?.endpoint}</div>
          </div>
          <SignInForm
            connection={connection}
            currentConnection={connection}
            hideTitle
            onSubmit={(submittedConnection) => {
              const { password } = submittedConnection;
              if (!password) {
                return;
              }

              setConnection({
                ...omit(connection, 'token'),
                password,
              });
            }}
          />
        </div>
      </Dialog>
    );
  };

export const PortalLoginDialog = connect<
  PortalLoginDialogStateProps,
  PortalLoginDialogDispatchProps
>(
  mapStateToProps,
  mapDispatchToProps
)(UnconnectedPortalLoginDialog);
