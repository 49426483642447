import { Store } from 'redux';
import * as Stardog from 'stardog';

import { Connection } from 'src/common/store/connection/ConnectionState';
import { ReduxState } from 'src/types';

interface CoreStardogAPIResponse {
  statusCode?: number;
  status: string;
  statusText: string;
  result: object | string | boolean | null;
  ok: boolean;
  body: any;
}

export interface StardogAPIResponse extends CoreStardogAPIResponse {
  headers: Headers;
}

// This recursively accumulates function signatures from
// an arbitrarily nested object, the Stardog API
export type NestedCalls<T> = {
  [K in keyof T]: T[K] extends Function
    ? T[K]
    : T[K] extends object
    ? NestedCalls<T[K]>
    : never;
}[keyof T];

export type API = Exclude<
  NestedCalls<typeof Stardog>,
  typeof Stardog.Connection
>;
export type APIResponse = ReturnType<API>;

export type Unwrapped<S> = S extends Promise<infer U> ? U : S;
export type UnwrappedAPIResponse = Unwrapped<APIResponse>;

export enum Scope {
  LOCAL = 'LOCAL',
  GLOBAL = 'GLOBAL',
}

export interface SharedOptions<
  Action extends object,
  APIMethod extends API | string,
  OnResponseStartType,
  OnReceivedType
> {
  api: APIMethod;
  args: any[];
  onResponseStart?: OnResponseStartType;
  onReceived?: OnReceivedType;
  action: Action;
  scope?: Scope;
  shouldNotDispatch?: boolean;
}

export interface CallOptions<Action extends object, B extends API | string>
  extends SharedOptions<Action, B, any, any> {
  connection: Connection;
}

export interface Options<
  Action extends object,
  APIMethod extends API | string,
  PendingType extends string,
  SuccessType extends string,
  FailureType extends string,
  OnResponseStartType extends string,
  OnReceivedType extends string
> extends SharedOptions<
    Action,
    APIMethod,
    OnResponseStartType,
    OnReceivedType
  > {
  loading: PendingType;
  failure: FailureType;
  success: SuccessType;
  silent?: RequestSilent;
}

type func = (...args: any[]) => any;
export type APIReturn<B> = B extends func
  ? Unwrapped<ReturnType<B>>
  : UnwrappedAPIResponse;

export type Response<
  Action extends object,
  ResponseType extends string,
  ResponseShape
> = {
  meta: {
    scope: string;
  };
  payload: {
    args: any[];
    response: ResponseShape;
    action: Action;
    timeElapsed: number;
    silent?: boolean;
  };
  type: ResponseType;
};

export type SuccessResponse<
  Action extends object,
  APIMethod extends API | string,
  ResponseType extends string
> = Response<Action, ResponseType, APIReturn<APIMethod>>;

export type FailureResponse<
  Action extends object,
  ResponseType extends string
> = Response<Action, ResponseType, { id: any; errorMessage: any }>;

export function isBody(x: any): x is Stardog.HTTP.Body {
  return Object.keys(x).some((key) => key === 'ok');
}

export interface RequestAction {
  [k: string]: any;
}

export type RequestSilent = {
  loading?: boolean;
  failure?: boolean;
  success?: boolean;
};
