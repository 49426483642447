export interface ModelsTextEditorSettings {
  areSyntaxFeaturesDisabled: boolean;
  wasSyntaxNotificationShown: boolean;
  isDirty: boolean;
  isPendingRequest: boolean;
  lastRetrievedDoc: string;
  localDoc: string;
}

export const defaultModelsTextEditorSettings: ModelsTextEditorSettings = {
  areSyntaxFeaturesDisabled: false,
  wasSyntaxNotificationShown: false,
  isDirty: false,
  isPendingRequest: false,
  lastRetrievedDoc: '',
  localDoc: '',
};
