import { Button, Classes, Intent } from '@blueprintjs/core';
import * as React from 'react';

import { WithConfirmationOptions } from 'src/common/components/ContextualStudioApi';
import { TEST_IDS } from 'src/common/constants/testIds';

const DialogContent = ({
  title,
  message,
  onSubmit,
  children,
}: {
  title: React.ReactNode;
  message: React.ReactNode;
  onSubmit?: WithConfirmationOptions['onConfirmationFailure'];
  children: React.ReactNode;
}) => (
  <form
    className={Classes.POPOVER_CONTENT_SIZING}
    onSubmit={(evt) => {
      evt.preventDefault();
      if (onSubmit) {
        onSubmit();
      }
    }}
  >
    {title}
    <p>{message}</p>
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {children}
    </div>
  </form>
);

export const ConfirmationDialogContent = ({
  message,
  noPrompt,
  onConfirmationFailure,
  onConfirmationSuccess,
  title,
  yesPrompt,
}: {
  message: JSX.Element | string;
  noPrompt: string;
  onConfirmationFailure: WithConfirmationOptions['onConfirmationFailure'];
  onConfirmationSuccess: WithConfirmationOptions['onConfirmationSuccess'];
  title: JSX.Element;
  yesPrompt: string;
}) => (
  <DialogContent
    message={message}
    onSubmit={onConfirmationFailure}
    title={title}
  >
    <Button
      className={Classes.POPOVER_OPEN}
      data-testid={TEST_IDS.confirmationDialog.yesButton}
      intent={Intent.DANGER}
      onClick={onConfirmationSuccess}
      style={{
        marginRight: 20,
      }}
      text={yesPrompt || 'Yes'}
    />
    <Button
      autoFocus
      className={Classes.POPOVER_DISMISS}
      data-testid={TEST_IDS.confirmationDialog.noButton}
      text={noPrompt || 'No'}
      type="submit"
    />
  </DialogContent>
);
