import { Connection, ConnectionOptions } from 'stardog';

import * as ConnectionState from 'src/common/store/connection/ConnectionState';

export const getStardogConnection = (
  connection: ConnectionState.Connection | ConnectionState.UserConfig
): Connection => {
  const { endpoint, password, username, token, useBrowserAuth } = connection;
  const connectionOptions: ConnectionOptions = {
    username,
    password,
    token,
    endpoint,
  };

  if (useBrowserAuth || (!username && !password)) {
    // If there is no username and password set we are assuming that
    // they are using the browser credentials to connect. Either they are
    // using kerberos or they have some other proxy set up to handle auth.
    // Override the default to tell the browser to include credentials
    // for CORS requests support.
    const createRequest = ({ uri, Request }) => {
      return new Request(uri, { credentials: 'include' });
    };

    // We need to remove the existing Authorization headers as this conflicts
    // if the browser credentials are used to negotiate authentication such
    // as the case for kerberos auth.
    const createHeaders = ({ headers }) => {
      headers.delete('Authorization');
      return headers;
    };
    return new Connection(connectionOptions, { createRequest, createHeaders });
  }

  return new Connection(connectionOptions);
};
