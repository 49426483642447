import { getAnalyticsConsentCookie } from 'portal-sdk';
import {
  isOnPremise,
  isPortal,
  redirectToPortalHome,
} from 'vet-bones/bones/utils/portal';

import { Preferences } from 'src/common/store/preferences/PreferencesState';
import { disableRollbar, enableRollbar } from 'src/common/utils/rollbar';

let analyticsLoaded = false;

/**
 * when in Portal, return true if Portal consent cookie is enabled;
 * when not in Portal, return if preferences.telemetryConsent is true
 */
export const isAnalyticsEnabled = ({
  telemetryConsent,
}: Pick<Preferences, 'telemetryConsent'>) => {
  if (isOnPremise()) {
    return false;
  }
  if (isPortal()) {
    const consentCookie = getAnalyticsConsentCookie();
    return consentCookie?.consented;
  }
  return telemetryConsent === true;
};

const loadAnalytics = () => {
  enableRollbar();
};

const unloadAnalytics = () => {
  // we have to reload because heap doesn't have a way to stop tracking
  console.warn(
    'reloading page because heap may have been previously loaded, and analytics is no longer enabled'
  );
  window.location.reload();

  disableRollbar();
};

/**
 * load Heap and Rollbar if analytics are enabled,
 * otherwise unload them if they were previously loaded
 */
export const checkAnalytics = (
  preferences: Pick<Preferences, 'telemetryConsent'>
) => {
  if (isPortal()) {
    const consentCookie = getAnalyticsConsentCookie();
    if (!consentCookie) {
      console.warn('redirecting to Portal because no consent cookie found');
      redirectToPortalHome(window);
    }
  }

  const enabled = isAnalyticsEnabled(preferences);

  if (enabled && !analyticsLoaded) {
    loadAnalytics();
    analyticsLoaded = true;
  } else if (!enabled && analyticsLoaded) {
    unloadAnalytics();
    analyticsLoaded = false;
  }
};

export const hasAnsweredTelemetryConsent = ({
  telemetryConsent,
}: Pick<Preferences, 'telemetryConsent'>) => {
  if (isPortal()) {
    return true;
  }
  return telemetryConsent !== undefined;
};
