/* NOTE: This file can only be imported in the renderer process. */
const {
  editorDarkBgColor,
  editorLightBgColor,
  tabBarHeight,
  controlBarHeight,
  primaryControlBarHeight,
  statusBarHeight,
  sideBarSectionHeaderHeight,
} = require('src/common/styles/shared-constants.scss');

const intTabBarHeight = parseInt(tabBarHeight, 10);
const intControlBarHeight = parseInt(controlBarHeight, 10);
const intPrimaryControlBarHeight = parseInt(primaryControlBarHeight, 10);
const intStatusBarHeight = parseInt(statusBarHeight, 10);
const intsideBarSectionHeaderHeight = parseInt(sideBarSectionHeaderHeight, 10);
const minSidebarSize = 130; // px
const minSidebarExpandSize = 30; // px
const minMainPaneSize = 270; // px
const popoverRowClassName = 'sd-popover-form-row';

export {
  editorDarkBgColor,
  editorLightBgColor,
  intTabBarHeight as tabBarHeight,
  intControlBarHeight as controlBarHeight,
  intPrimaryControlBarHeight as primaryControlBarHeight,
  intStatusBarHeight as statusBarHeight,
  intsideBarSectionHeaderHeight as sideBarSectionHeaderHeight,
  minSidebarSize,
  minSidebarExpandSize,
  minMainPaneSize,
  popoverRowClassName,
};
