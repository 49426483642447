import { query } from 'stardog';

import { request } from 'src/common/actions/request';
import {
  ActionCreatorAction,
  createTypedAction,
} from 'src/common/actions/types';
import { NoteState } from 'src/common/store/notebook/NoteState';
import { Query } from 'src/common/store/queries/QueriesState';

export const enum QueriesActionType {
  POPULATE_QUERIES_ATTEMPT = 'POPULATE_QUERIES_ATTEMPT',
  POPULATE_QUERIES_SUCCESS = 'POPULATE_QUERIES_SUCCESS',
  POPULATE_QUERIES_FAILURE = 'POPULATE_QUERIES_FAILURE',
  KILL_QUERY_ATTEMPT = 'KILL_QUERY_ATTEMPT',
  KILL_QUERY_SUCCESS = 'KILL_QUERY_SUCCESS',
  KILL_QUERY_FAILURE = 'KILL_QUERY_FAILURE',
  KILL_QUERIES_ATTEMPT = 'KILL_QUERIES_ATTEMPT',
  KILL_QUERIES_SUCCESS = 'KILL_QUERIES_SUCCESS',
  KILL_QUERIES_FAILURE = 'KILL_QUERIES_FAILURE',
  SET_REFRESH_RATE = 'SET_REFRESH_RATE',
  SET_REFRESH_INTERVAL_ID = 'SET_REFRESH_INTERVAL_ID',
  CLEAR_REFRESH_INTERVAL = 'CLEAR_REFRESH_INTERVAL',
  DISMISS_MESSAGE = 'DISMISS_MESSAGE',
}

export const queriesActionCreators = {
  setRefreshRate: (newRateInMs: number) =>
    createTypedAction(QueriesActionType.SET_REFRESH_RATE, {
      refreshRate: newRateInMs,
    }),
  killQueriesAttempt: ({
    queryIds,
    associatedNoteIds,
  }: {
    queryIds: Array<Query['id']>;
    associatedNoteIds: { [noteId: string]: Query['id'] };
  }) =>
    createTypedAction(QueriesActionType.KILL_QUERIES_ATTEMPT, {
      queryIds,
      associatedNoteIds,
    }),
  killQueriesSuccess: ({
    successQueryIds,
    successAssociatedNoteIds,
  }: {
    successQueryIds: Array<Query['id']>;
    successAssociatedNoteIds: { [noteId: string]: Query['id'] };
  }) =>
    createTypedAction(QueriesActionType.KILL_QUERIES_SUCCESS, {
      successQueryIds,
      successAssociatedNoteIds,
    }),
  killQueriesFailure: ({
    successQueryIds,
    successAssociatedNoteIds,
    failureQueryIds,
    failureAssociatedNoteIds,
  }: {
    successQueryIds: Array<Query['id']>;
    successAssociatedNoteIds: { [noteId: string]: Query['id'] };
    failureQueryIds: Array<Query['id']>;
    failureAssociatedNoteIds: { [noteId: string]: Query['id'] };
  }) =>
    createTypedAction(QueriesActionType.KILL_QUERIES_FAILURE, {
      successQueryIds,
      successAssociatedNoteIds,
      failureQueryIds,
      failureAssociatedNoteIds,
    }),
};

export const queriesStardogRequestDispatchers = {
  killQuery: ({
    queryId,
    associatedNoteId,
    shouldNotDispatch,
  }: {
    queryId: Query['id'];
    associatedNoteId?: NoteState['id'];
    shouldNotDispatch?: boolean;
  }) =>
    request({
      api: query.kill,
      args: [queryId],
      action: {
        associatedNoteId,
      },
      loading: QueriesActionType.KILL_QUERY_ATTEMPT,
      success: QueriesActionType.KILL_QUERY_SUCCESS,
      failure: QueriesActionType.KILL_QUERY_FAILURE,
      shouldNotDispatch,
    }),
  populateQueries: () =>
    request({
      api: query.list,
      args: [],
      action: {},
      loading: QueriesActionType.POPULATE_QUERIES_ATTEMPT,
      success: QueriesActionType.POPULATE_QUERIES_SUCCESS,
      failure: QueriesActionType.POPULATE_QUERIES_FAILURE,
    }),
};

const queriesActionTypeMap = {
  ...queriesActionCreators,
  ...queriesStardogRequestDispatchers,
};

export type QueriesAction = ActionCreatorAction<typeof queriesActionTypeMap>;
