import { withLeaveDatabasesConfirmation } from 'src/common/actions/databases/withLeaveDatabasesConfirmation';
import { withLeaveModelsConfirmation } from 'src/common/actions/models/withLeaveModelsConfirmation';
import { Response } from 'src/common/actions/request/types';

export const createTypedAction = <T extends string, P = undefined>(
  type: T,
  payload?: P
): { type: T; payload?: P } => ({
  type,
  payload,
});

export type NormalAction = ReturnType<typeof createTypedAction>;

type ActionCreatorReturningMap = {
  [key: string]:
    | ((...arg: any[]) => NormalAction)
    | ((...arg: any[]) => Promise<Response<any, any, any>>);
};

export type ActionCreatorMap<Map extends ActionCreatorReturningMap> = {
  [K in keyof Map]: ReturnType<Map[K]> extends NormalAction
    ? ReturnType<Map[K]>
    : ReturnType<Map[K]> extends Promise<infer Response>
    ? Response
    : never;
};

export type ActionCreatorAction<Map extends ActionCreatorReturningMap> =
  ActionCreatorMap<Map>[keyof Map];

export type LeaveHubConfirmationHandler =
  | typeof withLeaveDatabasesConfirmation
  | typeof withLeaveModelsConfirmation;
