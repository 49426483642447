import {
  ForceGraphCachedData,
  ForceGraphCameraData,
  ForceGraphGraphData,
} from 'src/common/constants/visualization/visualizationTypes';

interface ForceGraphDataCache {
  [key: string]: ForceGraphCachedData;
}

let cache: ForceGraphDataCache = {};

export function getForceGraphDataFromCache(cacheKey: string) {
  if (!cacheKey) {
    return;
  }
  return cache[cacheKey] || null;
}

export function addForceGraphGraphDataToCache(
  cacheKey: string,
  graphData: ForceGraphGraphData
) {
  if (!cacheKey) {
    return;
  }
  let data = cache[cacheKey];
  if (data) {
    data.graphData = graphData;
  } else {
    data = { graphData };
    cache[cacheKey] = data;
  }
  return data;
}

export function addForceGraphCameraDataToCache(
  cacheKey: string,
  cameraData: ForceGraphCameraData
) {
  if (!cacheKey) {
    return;
  }
  const data = cache[cacheKey];
  if (data) {
    data.cameraData = cameraData;
    return data;
  }
}

export function removeForceGraphDataFromCache(cacheKey: string) {
  cache[cacheKey] = undefined;
}

export function resetForceGraphDataCache() {
  cache = {};
}
