import * as Monaco from 'monaco-editor/esm/vs/editor/editor.api';

export const MINIMUM_CONSTRAINTS_EDITOR_HEIGHT = 100;
export const DEFAULT_CONSTRAINTS_REPORT_HEIGHT = 300;
export const MINIMUM_CONSTRAINTS_REPORT_HEIGHT = 100;

export interface ConstraintsEditorSettings {
  areSyntaxFeaturesDisabled: boolean;
  wasSyntaxNotificationShown: boolean;
  shouldShowEditWarning: boolean;
  didFailRequest: boolean;
  isDirty: boolean;
  isPendingRequest: boolean;
  lastRetrievedDoc: string;
  localDoc: string;
  requestedNamedGraphs: string[];
  viewState?: Monaco.editor.ICodeEditorViewState;
}

export const defaultConstraintsEditorSettings: ConstraintsEditorSettings = {
  areSyntaxFeaturesDisabled: false,
  wasSyntaxNotificationShown: false,
  shouldShowEditWarning: false,
  didFailRequest: false,
  isDirty: false,
  isPendingRequest: false,
  lastRetrievedDoc: '',
  localDoc: '',
  requestedNamedGraphs: [],
  viewState: null,
};

export interface ConstraintsReportSettings {
  height: number;
  isPendingReport: boolean;
  isReportCollapsed: boolean;
  lastRetrievedReport: string;
  namedGraphs: string[];
  viewState: Monaco.editor.ICodeEditorViewState;
}

export const defaultConstraintsReportSettings: ConstraintsReportSettings = {
  height: DEFAULT_CONSTRAINTS_REPORT_HEIGHT,
  isPendingReport: false,
  isReportCollapsed: true,
  lastRetrievedReport: '',
  namedGraphs: [],
  viewState: null,
};
