import { preferencesActionCreators } from 'src/common/actions/preferences/preferencesActionCreators';
import {
  StudioStateGetter,
  StudioThunkDispatch,
} from 'src/common/actions/StudioAction';
import { Preferences } from 'src/common/store/preferences/PreferencesState';

// Mostly a convenience action-creator so that we:
//  1. don't accidentally hold on to stale preferences from old state, but always get the latest, and
//  2. don't have to always get and pass in the current preferences state when setting a single preference
export const setPreference =
  <T extends keyof Preferences>(key: T, value: Preferences[T]) =>
  async (dispatch: StudioThunkDispatch, getState: StudioStateGetter) => {
    const { preferences } = getState();
    return dispatch(
      preferencesActionCreators.setPreference(key, value, preferences)
    );
  };
