import { Intent } from '@blueprintjs/core';
import omit from 'lodash.omit';
import { query as stardogJsQuery } from 'stardog';
import uuid from 'uuid/v4';
import { noop } from 'vet-bones/bones/utils';

import { requestDetailsForDatabase } from 'src/common/actions/databases/action-creators/requestDetailsForDatabase';
import { executeSelectQueryForNote } from 'src/common/actions/notebook/action-creators/stardog-api/executeQueryForNote/select';
import {
  notebookActionCreators,
  notebookStardogRequestDispatchers,
} from 'src/common/actions/notebook/notebookActionCreators';
import { notificationsActionCreators } from 'src/common/actions/notifications/notificationsActionCreators';
import { queryHistoryActionCreators } from 'src/common/actions/queryHistory/queryHistoryActionCreators';
import {
  StudioStateGetter,
  StudioThunkDispatch,
} from 'src/common/actions/StudioAction';
import { visualizationsActionCreators } from 'src/common/actions/visualizations/visualizationsActionCreators';
import { LanguageId, QueryLanguageId } from 'src/common/constants/LanguageId';
import { QueryType, QueryTypeIdentifier } from 'src/common/constants/QueryType';
import { QueryHistoryEntryStatus } from 'src/common/store/queryHistory/QueryHistoryState';
import { rdfExtensions } from 'src/common/utils/fileExtensions';
import { getNumResultsByQueryType } from 'src/common/utils/getNumResultsByQueryType';
import { getSparqlQueryLimit } from 'src/common/utils/getSparqlQueryLimit';
import { doesQueryAcceptLimitPreference } from 'src/common/utils/preferences/doesQueryAcceptLimitPreference';
import { StardogQueryMethodData } from 'src/common/utils/rpc/types';
import { safelyGet } from 'src/common/utils/safelyGet';
import { QueryGetter } from 'src/types/QueryGetter';

export const executeQueryForNote =
  (noteId: string, queryGetter: QueryGetter = noop as any) =>
  async (dispatch: StudioThunkDispatch, getState: StudioStateGetter) => {
    const { connection, features, notebook, preferences } = getState();
    const { isClientSideQueryIdSupported } = features.stardog;
    const note = notebook.notes[noteId];

    const {
      activeDatabase: db,
      languageId = LanguageId.SPARQL,
      namedGraphs,
      query,
      reasoning,
      schema: schemaForReasoning,
      timeout,
      viewState = null,
    } = note.editorSettings;
    const queryToExecute =
      queryGetter({
        query,
        noteId,
        viewState,
        languageId,
      }) || query;
    // Apply the queryLimit preference if and only if it is not set to 0 (which
    // turns it off) and the query is one to which the preference applies.
    const limit =
      preferences.queryLimit === 0 ||
      !doesQueryAcceptLimitPreference(queryToExecute, languageId)
        ? null
        : getSparqlQueryLimit(queryToExecute, preferences.queryLimit || 1000);
    const schema = reasoning ? schemaForReasoning : undefined;

    if (!db || !queryToExecute.length) {
      const message = `${
        !db ? 'Select a database' : 'Please provide an input'
      } to execute a query`;
      dispatch(
        notificationsActionCreators.queueNotification({
          message,
          intent: Intent.WARNING,
        })
      );
      return;
    }

    if (note.resultSettings.pending) {
      return; // Dont allow users to execute twice in the same tab concurrently
    }

    // clear the old visualiation cached data for the note
    const cacheKey = safelyGet(note, [
      'resultSettings',
      'jsonLdBody',
      'queryHistoryId',
    ]);
    dispatch(visualizationsActionCreators.clearVisualizationData([cacheKey]));

    const queryType: Exclude<QueryType, QueryTypeIdentifier.EXPLAIN> =
      // We can treat all GraphQL queries as one type of query because Stardog
      // does the same (it converts them all to SELECTs; mutation is not
      // supported)
      languageId === LanguageId.GRAPHQL
        ? QueryTypeIdentifier.GRAPHQL
        : stardogJsQuery.utils.queryType(queryToExecute);
    const queryId = uuid();

    dispatch(requestDetailsForDatabase(db));

    dispatch(
      queryHistoryActionCreators.updateQueryHistory({
        id: queryId,
        associatedNoteId: noteId,
        queryType,
        queryText: queryToExecute,
        withReasoning: reasoning,
        endpoint: connection.current.endpoint,
        targetDb: db,
        numResults: 0,
        timestamp: Date.now(),
        vars: [],
        status: QueryHistoryEntryStatus.PENDING,
      })
    );

    let response;
    const coreStardogQueryMethodData: Omit<
      StardogQueryMethodData,
      'acceptType' | 'connection' | 'end' | 'start'
    > = {
      db,
      limit,
      namedGraphs,
      noteId,
      query: queryToExecute,
      reasoning,
      schema,
      timeout,
    };
    if (isClientSideQueryIdSupported) {
      coreStardogQueryMethodData.queryId = queryId;
    } else {
      coreStardogQueryMethodData.onResponseStart = (res) => {
        dispatch(
          notebookActionCreators.setQueryIdForNote(
            res.headers.get('sd-query-id'),
            noteId
          )
        );
        return true;
      };
    }

    try {
      switch (queryType) {
        case QueryTypeIdentifier.SELECT: {
          response = await executeSelectQueryForNote(
            {
              ...coreStardogQueryMethodData,
              connection: connection.current,
            },
            dispatch
          );
          break;
        }
        case QueryTypeIdentifier.VALIDATE:
        case QueryTypeIdentifier.CONSTRUCT:
        case QueryTypeIdentifier.DESCRIBE: {
          // NOTE: `limit` will always currently be null for these queries. See
          // VET-999 and VET-293.
          await Promise.all([
            notebookStardogRequestDispatchers.executeQueryDispatcher({
              ...coreStardogQueryMethodData,
              acceptType: rdfExtensions.fileExtensionToMimeType.trig,
            }),
            notebookStardogRequestDispatchers.executeJsonLdQueryDispatcher({
              ...coreStardogQueryMethodData,
              // With JSON-LD queries, the queryId is used as a cache key, so
              // the dispatcher always needs to associate the id with the
              // dispatched payload, even if Stardog itself doesn't support
              // client-side queryIds for query execution
              queryId: isClientSideQueryIdSupported ? uuid() : undefined,
            }),
          ]).then(([queryResp]) => {
            response = queryResp;
          });
          break;
        }
        case QueryTypeIdentifier.PATHS: {
          response =
            await notebookStardogRequestDispatchers.executePathsQueryForNoteDispatcher(
              coreStardogQueryMethodData
            );
          break;
        }
        case QueryTypeIdentifier.GRAPHQL: {
          response =
            await notebookStardogRequestDispatchers.executeQueryDispatcher({
              // intentionally exclude schema as the reasoning schema is not supported by graphql queries
              // https://github.com/stardog-union/stardog/pull/7531#discussion_r304016124
              // schema,
              ...omit(coreStardogQueryMethodData, ['limit', 'schema']),
              languageId: languageId as QueryLanguageId,
            });
          break;
        }
        default: {
          response =
            await notebookStardogRequestDispatchers.executeQueryDispatcher({
              ...omit(coreStardogQueryMethodData, ['limit']), // unsure why limit is omitted
              languageId: languageId as QueryLanguageId,
            });
          break;
        }
      }
    } catch (ex) {
      // Log but proceed; if one of the above dispatchers threw, then `response`
      // will not be set, so the failure case will be dispatched below.
      console.error(ex);
    }

    if (
      response &&
      response.payload &&
      response.payload.response &&
      response.payload.response.ok
    ) {
      const { body } = response.payload.response;
      const numResults = getNumResultsByQueryType(queryType, body);

      dispatch(
        queryHistoryActionCreators.updateQueryHistory({
          id: queryId,
          status: QueryHistoryEntryStatus.SUCCEEDED,
          queryExecutionTime: response.payload.timeElapsed,
          vars: safelyGet(body, ['head', 'vars']) || [],
          numResults,
        })
      );
    } else {
      dispatch(
        queryHistoryActionCreators.updateQueryHistory({
          id: queryId,
          status: QueryHistoryEntryStatus.FAILED,
        })
      );
    }

    return response;
  };
