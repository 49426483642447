import { VoiceboxProperty } from 'src/common/utils/voicebox/parseModelResults';

export type VoiceboxWrapperRequest = {
  query: string;
  conversationId: string;
  retries?: number;
};

export type VoiceboxWrapperResponse = {
  content: string;
  conversationId: string;
  errors?: string[];
};

export type VoiceboxModelSchema = {
  classIris: string[];
  labelsByIri: Record<string, string>;
  propertiesByIri: Record<string, VoiceboxProperty>;
};

// Internal types below-- may become obsolete when we move away from openai

export type VoiceboxBaseMessage = {
  id: string;
  role: 'assistant' | 'system' | 'user';
  content: string;
};

export type VoiceboxAssistantMessage = VoiceboxBaseMessage & {
  role: 'assistant';
  /** id of the matching VoiceboxUserMessage */
  requestMessageId: string;
};

export type VoiceboxSystemMessage = VoiceboxBaseMessage & {
  role: 'system';
};

export type VoiceboxUserMessage = VoiceboxBaseMessage & {
  role: 'user';
  userPrompt: string;
};

export type VoiceboxMessage =
  | VoiceboxAssistantMessage
  | VoiceboxSystemMessage
  | VoiceboxUserMessage;

export const isAssistantMessage = (
  message?: VoiceboxMessage
): message is VoiceboxAssistantMessage =>
  Boolean(message?.role === 'assistant');

export const isSystemMessage = (
  message?: VoiceboxMessage
): message is VoiceboxSystemMessage => Boolean(message?.role === 'system');

export const isUserMessage = (
  message?: VoiceboxMessage
): message is VoiceboxUserMessage => Boolean(message?.role === 'user');
