import * as React from 'react';

import {
  ConnectionForm,
  ConnectionType,
} from 'src/common/containers/Login/ConnectionForm';
import { Connection } from 'src/common/store/connection/ConnectionState';

type NewConnectionFormProps = {
  connection: Connection;
  handleSetNewConnection: (
    Connection: Connection,
    shouldSaveConnection: boolean
  ) => any;
};

export const NewConnectionForm = ({
  connection,
  handleSetNewConnection,
}: NewConnectionFormProps) => (
  <ConnectionForm
    connection={connection}
    connectionType={ConnectionType.NEW}
    onSubmit={(connectionFormState) =>
      handleSetNewConnection(
        connectionFormState.connection,
        connectionFormState.shouldSaveConnection
      )
    }
  />
);
