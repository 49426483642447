import { EDITOR_QUERY_LIMIT } from 'src/common/constants/editorLimits';
import {
  RDFS_LABEL_URI,
  STARDOG_STUDIO_IS_ATTRIBUTE_TAG,
  STARDOG_STUDIO_LABEL_TAG,
} from 'src/common/constants/uris';
import { getAbbreviationOrFullIri } from 'src/common/utils/caches/abbreviations';

export function buildRdfsSchemaQuery(
  labelPredicates?: string[],
  namespaces?: string[]
) {
  const classTriples: string[] = [];
  const propertyTriples: string[] = [];

  const labels = [`<${STARDOG_STUDIO_LABEL_TAG}>`];
  if (labelPredicates && labelPredicates.length) {
    labelPredicates.forEach((labelIri) =>
      labels.push(getAbbreviationOrFullIri(labelIri, namespaces || []))
    );
  } else {
    labels.push(`<${RDFS_LABEL_URI}>`);
  }

  labels.forEach((label, idx) => {
    classTriples.push(`?sub ${label} ?sub_label_${idx} .`);
    classTriples.push(`?sup ${label} ?sup_label_${idx} .`);
    propertyTriples.push(`?domain ${label} ?domain_label_${idx} .`);
    propertyTriples.push(`?range ${label} ?range_label_${idx} .`);
  });
  const classBlocks: string[] = classTriples.map(
    (triple) => `OPTIONAL { ${triple} }`
  );
  const propertyBlocks: string[] = propertyTriples.map(
    (triple) => `OPTIONAL { ${triple} }`
  );

  return `# RDFS Schema
CONSTRUCT {
  ?sub rdfs:subClassOf ?sup .
  ${classTriples.join('\n  ')}
  ?domain ?prop ?range .
  ${propertyTriples.join('\n  ')}
  ?range <${STARDOG_STUDIO_IS_ATTRIBUTE_TAG}> ?isAttribute .
}
WHERE {
  { 
    ?sub rdfs:subClassOf ?sup
    FILTER (?sub != ?sup && isIRI(?sup)) .
    ${classBlocks.join('\n    ')}
  }
  UNION {
    ?prop rdfs:domain ?domain .
    ?prop rdfs:range ?range .
    ${propertyBlocks.join('\n    ')}
    OPTIONAL { ?prop rdf:type ?type } 
    OPTIONAL { 
        FILTER (?type = owl:DatatypeProperty)
        BIND(true AS ?isAttribute)
    } 
  }
}
LIMIT ${EDITOR_QUERY_LIMIT}`;
}
