import { Intent } from '@blueprintjs/core';

import { connectionActionCreators } from 'src/common/actions/connection/connectionActionCreators';
import { notificationsActionCreators } from 'src/common/actions/notifications/notificationsActionCreators';
import {
  StudioStateGetter,
  StudioThunkDispatch,
} from 'src/common/actions/StudioAction';
import { SavedConnection } from 'src/common/store/connection/ConnectionState';

export const editSavedConnection =
  (name: string, connection: SavedConnection) =>
  (dispatch: StudioThunkDispatch, getState: StudioStateGetter) => {
    const { saved } = getState().connection;

    // A save is valid if the the name of the connection is constant
    // or there isn't an already saved connection with that name
    if (name === connection.name || !saved[connection.name]) {
      dispatch(connectionActionCreators.editSavedConnection(name, connection));
      dispatch(
        notificationsActionCreators.queueNotification({
          message: `Successfully saved changes to connection "${connection.name}"`,
          intent: Intent.SUCCESS,
        })
      );
      return true;
    }
    dispatch(
      notificationsActionCreators.queueNotification({
        message: `Could not save changes to connection "${connection.name}": a connection with that name already exists.`,
        intent: Intent.DANGER,
      })
    );
    return false;
  };
