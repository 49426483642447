import { notebookActionCreators } from 'src/common/actions/notebook/notebookActionCreators';
import { StardogQueryMethodData } from 'src/common/utils/rpc/types';

export const executeSelectQueryForNote = async (
  options: StardogQueryMethodData,
  dispatch,
  getResponseIpc
) => {
  const { query, noteId, queryId } = options;
  dispatch(notebookActionCreators.executeSelectAttempt(query, noteId, queryId));

  try {
    const start = new Date().getTime();

    const response = await getResponseIpc(options);
    const timeElapsed = new Date().getTime() - start;

    const responseAction = notebookActionCreators.executeSelectSuccess({
      query,
      noteId,
      response,
      timeElapsed,
    });

    dispatch(responseAction);
    return responseAction;
  } catch (error) {
    const errorAction = notebookActionCreators.executeSelectFailure({
      noteId,
      error,
    });
    dispatch(errorAction);
    return errorAction;
  }
};
