import {
  ModelsConstraintsData,
  ModelsSchemaTabData,
  ModelsState,
  ModelsVisualizationSettings,
} from 'src/common/store/models/ModelsState';
import { ModelsTextEditorSettings } from 'src/common/store/models/ModelsTextEditorState';

function getSanitizedModelSchemaData(
  data: ModelsSchemaTabData
): ModelsSchemaTabData | { itemHierarchy: string; itemsByIri: string } {
  return {
    ...data,
    itemHierarchy: 'redacted for user experience',
    itemsByIri: 'redacted for user experience',
  };
}

export const getSanitizedModelsState = (models: ModelsState) => {
  const modelsConstraints: ModelsConstraintsData = {
    ...models.constraints,
    editorSettings: {
      ...models.constraints.editorSettings,
      lastRetrievedDoc:
        models.constraints.editorSettings.lastRetrievedDoc === ''
          ? ''
          : 'redacted for user experience',
      localDoc:
        models.constraints.editorSettings.localDoc === ''
          ? ''
          : 'redacted for user experience',
    },
  };
  const modelsTextEditor: ModelsTextEditorSettings = {
    ...models.textEditor,
    lastRetrievedDoc:
      models.textEditor.lastRetrievedDoc === ''
        ? ''
        : 'redacted for user experience',
    localDoc:
      models.textEditor.localDoc === '' ? '' : 'redacted for user experience',
  };

  const modelsVisualization:
    | ModelsVisualizationSettings
    | { visualizationData: string } = models.visualizationSettings
    ? {
        ...models.visualizationSettings,
        visualizationData: 'redacted for user experience',
      }
    : undefined;

  return {
    ...models,
    attributes: getSanitizedModelSchemaData(models.attributes),
    classes: getSanitizedModelSchemaData(models.classes),
    constraints: modelsConstraints,
    relationships: getSanitizedModelSchemaData(models.relationships),
    textEditor: modelsTextEditor,
    visualizationSettings: modelsVisualization,
  };
};
