import { ToastProps } from '@blueprintjs/core';

import {
  ActionCreatorAction,
  createTypedAction,
} from 'src/common/actions/types';

export const enum NotificationsActionType {
  UNQUEUE_NOTIFICATION = 'UNQUEUE_NOTIFICATION',
  QUEUE_NOTIFICATION = 'QUEUE_NOTIFICATION',
}

export type NotificationsAction = ActionCreatorAction<
  typeof notificationsActionCreators
>;

export const notificationsActionCreators = {
  unqueueNotification: (id: string) =>
    createTypedAction(NotificationsActionType.UNQUEUE_NOTIFICATION, { id }),
  queueNotification: (notification: ToastProps) =>
    createTypedAction(NotificationsActionType.QUEUE_NOTIFICATION, notification),
};
