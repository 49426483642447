import { LanguageId } from 'src/common/constants/LanguageId';
import {
  NoteStateMap,
  NotebookState,
} from 'src/common/store/notebook/NotebookState';
import { NoteState } from 'src/common/store/notebook/NoteState';
import { safelyGet } from 'src/common/utils/safelyGet';
import { InitialData } from 'src/types';

export default (data: InitialData) => {
  const oldNotebook: Partial<NotebookState> = safelyGet(data, ['notebook'], {});
  const oldNotes: NoteStateMap = safelyGet(oldNotebook, ['notes'], {});

  const notes = Object.entries(oldNotes).reduce((acc, [noteId, oldNote]) => {
    const note: NoteState = {
      ...oldNote,
      editorSettings: {
        ...oldNote.editorSettings,
      },
    };

    // if the note languageId is the old sms, change it to sms2
    // @ts-ignore: we don't want to extend LanguageId to include deprecated values
    // so in this case, the typings for NoteState aren't quite right since the
    // point of the migration is to replace possible deprecated LanguageId values
    if (note.editorSettings.languageId === 'sms') {
      note.editorSettings.languageId = LanguageId.SMS2;
    }

    acc[noteId] = note;
    return acc;
  }, {});

  return {
    ...data,
    version: '1.11.0',
    notebook: {
      ...oldNotebook,
      notes,
    },
  };
};
