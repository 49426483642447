import * as path from 'path-browserify';

import { browserClipboardApi } from 'src/browser/apis/browserClipboardApi';
import { browserConfirmationApi } from 'src/browser/apis/browserConfirmationApi';
import { browserContextMenuApi } from 'src/browser/apis/browserContextMenuApi';
import { browserDialogApi } from 'src/browser/apis/browserDialogApi';
import { loadBrowserFs } from 'src/browser/apis/browserFsApi';
import { ipcFacade } from 'src/browser/apis/browserIpcApi';
import { browserShellApi } from 'src/browser/apis/browserShellApi';
import { browserWindowApi } from 'src/browser/apis/browserWindowApi';
import { StudioApi } from 'src/common/components/ContextualStudioApi';

const browserApi: StudioApi = {
  clipboard: browserClipboardApi,
  confirmation: browserConfirmationApi,
  contextMenu: browserContextMenuApi,
  dialog: browserDialogApi,
  ipcRenderer: ipcFacade,
  ipcMain: ipcFacade,
  path,
  fs: loadBrowserFs(
    {
      fs: 'IndexedDB',
      options: {
        storeName: 'StudioFs',
      },
    },
    (fsApi) => {
      browserApi.fs = fsApi;
    }
  ) as any,
  window: browserWindowApi,
  shell: browserShellApi,
};

export { browserApi };
