import { IconName, IconNames } from '@blueprintjs/icons';

export const enum StarchartView {
  LANDING = 'LANDING',
  CHART = 'CHART',
  RELATIONSHIPS = 'RELATIONSHIPS',
}

// In case this bothers someone, this is ordered this way on purpose.
export const enum StarchartSortBy {
  NAME = 'SOURCES',
  NAME_DESC = 'SOURCES_DESC',
}

export interface StarchartSortByOption {
  value: StarchartSortBy;
  icon: IconName;
}

export const starchartSortOptions: StarchartSortByOption[] = [
  {
    value: StarchartSortBy.NAME,
    icon: IconNames.SORT_ALPHABETICAL,
  },
  {
    value: StarchartSortBy.NAME_DESC,
    icon: IconNames.SORT_ALPHABETICAL_DESC,
  },
];

export interface StarchartSorting {
  page: number;
  sortBy: StarchartSortBy;
}

export function defaultStartChartSorting() {
  return {
    page: 0,
    sortBy: StarchartSortBy.NAME,
  };
}

export interface StarchartSortingByDatabase {
  [databaseId: string]: StarchartSorting;
}

export interface StarchartClassesBySource {
  [vgIri: string]: string[];
}

export interface StarchartSourcesByClass {
  [classIri: string]: string[];
}

export interface StarchartClass {
  iri: string;
  label: string;
  count: number;
  radius?: number;
  color?: string;
  x?: number;
  y?: number;
  style?: React.CSSProperties;
  circleStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
}

export interface StarchartClasses {
  [classIri: string]: StarchartClass;
}

export interface StarchartStardog {
  iri: '__STARDOG_SOURCE__';
  name: 'STARDOG';
  dataService?: undefined;
}

export const STARDOG_SOURCE: StarchartStardog = {
  iri: '__STARDOG_SOURCE__',
  name: 'STARDOG',
};

export interface StarchartVirtualGraph {
  iri: string;
  name: string;
  dataService: string;
}

export interface StarchartVirtualGraphs {
  [vgIri: string]: StarchartVirtualGraph;
}

export interface StarchartDataService {
  iri: string;
  type: string;
}

export interface StarchartDataServices {
  [dataServiceIri: string]: StarchartDataService;
}

export interface StarchartSourcesData {
  // source maps
  classesBySource: StarchartClassesBySource;
  sourcesByClass: StarchartSourcesByClass;
  // data maps
  classes: StarchartClasses;
  virtualGraphs: StarchartVirtualGraphs;
  dataServices: StarchartDataServices;
}

export function defaultStarchartSourcesData() {
  return {
    classesBySource: {},
    sourcesByClass: {},
    classes: {},
    virtualGraphs: {},
    dataServices: {},
  } as StarchartSourcesData;
}

export interface StarchartStats {
  avgQueryLatency: number; // value of average query latency
  queryLatencyUnits: string; // units for average query latency
  avgTransactionLatency: number; // value of average transaction latency
  transactionLatencyUnits: string; // units for average transaction latency
  size: number; // approximate triples
}

export class StarchartState {
  constructor(
    public currentView: StarchartView = StarchartView.LANDING,
    public selectedDatabaseId: string = '',
    public sourcesData: StarchartSourcesData = defaultStarchartSourcesData(),
    public isPendingSourcesData: boolean = false,
    public sortingByDatabase: StarchartSortingByDatabase = {},
    public stats: StarchartStats = null,
    public isPendingStats: boolean = false,
    public selectedSourceIris: Set<string> = new Set(),
    public selectedClassIris: Set<string> = new Set()
  ) {}
}
