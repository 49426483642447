import { AnchorButton, Tooltip } from '@blueprintjs/core';
import pick from 'lodash.pick';
import * as React from 'react';
import { connect } from 'react-redux';

import { browserApi } from 'src/browser/apis/browserApi';
import { KeyboardIcon } from 'src/common/components/icons/Keyboard';
import { IpcEventType } from 'src/common/constants/events';
import { TEST_IDS } from 'src/common/constants/testIds';
import * as copy from 'src/common/templates/copy';
import { isConnectionConnected } from 'src/common/utils/isConnectionConnected';
import { ReduxState } from 'src/types';

const mapStateToProps = ({ connection }: ReduxState) => ({
  ...pick(connection.current, 'endpoint', 'username', 'name', 'stardogVersion'),
  isConnected: isConnectionConnected(connection.current),
});

type StateProps = ReturnType<typeof mapStateToProps>;

export const UnconnectedStatusBar: React.VFC<
  StateProps & { children?: React.ReactNode }
> = ({ children, endpoint, isConnected, name, stardogVersion, username }) => {
  return (
    <div className="sd-status-bar" data-testid={TEST_IDS.statusBar.container}>
      <div className="sd-status-bar-static">
        <span
          className="sd-status-bar-connection"
          data-testid={TEST_IDS.statusBar.connection}
        >
          {isConnected
            ? copy.components.statusBar.connectionStatus.connected({
                endpoint,
                name,
                username,
              })
            : copy.components.statusBar.connectionStatus.notConnected}
        </span>
        <span className="sd-status-bar-version">
          {copy.components.statusBar.serverVersion(isConnected, stardogVersion)}
        </span>
      </div>

      <div style={{ display: 'flex' }}>
        <Tooltip
          content={copy.components.statusBar.keyboardShortcuts}
          hoverOpenDelay={800}
        >
          <AnchorButton
            data-testid={TEST_IDS.statusBar.keyboardShortcutButton}
            icon={<KeyboardIcon />}
            id="sd-status-bar-keyboard-button"
            onClick={() =>
              browserApi.ipcRenderer.emit(IpcEventType.SHOW_KEYBOARD_SHORTCUTS)
            }
          />
        </Tooltip>
        {children}
      </div>
    </div>
  );
};

export const StatusBar =
  connect<StateProps>(mapStateToProps)(UnconnectedStatusBar);
