import { CstNode, IToken, isCstNode, traverse } from 'millan';

import { sparqlParser } from 'src/common/utils/editor/language-services/sparql/sparqlParser';

export const getSparqlQueryLimit = (query, defaultLimit = 1000) => {
  let actualLimit = defaultLimit;
  let cst: CstNode;
  try {
    cst = sparqlParser.parse(query).cst;
  } catch (e) {
    console.error(e);
    return actualLimit;
  }

  traverse(cst, (ctx, next) => {
    const { node, parentCtx } = ctx;

    if (!isCstNode(node)) {
      // No further to go.
      return;
    }

    if (node.name !== 'LimitClause') {
      next(); // keep looking
      return;
    }

    let isTopLevelLimit = true;
    let cstParentPointer = parentCtx;
    while (cstParentPointer && isTopLevelLimit) {
      if (
        cstParentPointer.node &&
        (cstParentPointer.node as CstNode).name === 'SubSelect'
      ) {
        isTopLevelLimit = false;
      }
      cstParentPointer = cstParentPointer.parentCtx;
    }

    if (!isTopLevelLimit) {
      // No need to look further, as limits below limits are not top-level.
      return;
    }

    const { INTEGER } = node.children;
    if (INTEGER && INTEGER[0]) {
      const limitNumberToken = INTEGER[0] as IToken;
      const limitNumber = parseInt(limitNumberToken.image, 10);
      if (!Number.isNaN(limitNumber)) {
        actualLimit = limitNumber;
      }
    }
  });

  return actualLimit;
};
