// `NestedProperties<T>` is the union of all top-level + nested keys of T.
// The `extends never` check prevents deeply-nested empty objects from screwing
// up the type (otherwise, the type will resolve to `any`).
type NestedProperties<T> = keyof T extends never
  ? never
  : {
      [K in keyof T]: T[K] extends object ? K | NestedProperties<T[K]> : K;
    }[keyof T];

const isNullOrUndefined = (candidate): candidate is void =>
  typeof candidate === 'undefined' || candidate === null;

export const safelyGet = <
  TargetType = object,
  ExpectedReturnType = any,
  DefaultValueType = void,
  AllowedPathKeysType = NestedProperties<TargetType>
>(
  target: TargetType,
  path: AllowedPathKeysType[],
  defaultValue: DefaultValueType = undefined
): ExpectedReturnType | DefaultValueType => {
  if (isNullOrUndefined(target)) {
    // Trying to access properties on these values would throw; return default.
    return defaultValue;
  }

  if (!path || path.length < 1) {
    // No properties to access; just return initial object.
    return target as any;
  }

  // eslint-disable-next-line prefer-const -- only remainingPath is constant
  let [currentPart, ...remainingPath] = path;
  // @ts-ignore: TypeScript doesn't have tuple types, and doesn't realize that
  // this next line will only be using the first (top-level) key of `target`.
  let innerVal = target[currentPart];

  while (remainingPath.length > 0) {
    currentPart = remainingPath.shift();
    // @ts-ignore: Same tuple-related problem as above.
    innerVal = isNullOrUndefined(innerVal) ? undefined : innerVal[currentPart];
  }

  return typeof innerVal === 'undefined' ? defaultValue : (innerVal as any);
};
