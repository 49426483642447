import { Icon, IconProps, Tooltip, TooltipProps } from '@blueprintjs/core';
import * as React from 'react';

import { emptyObject } from 'src/common/utils/emptyObject';

export interface IconWithTooltipProps {
  icon: IconProps['icon'];
  tooltipContent: TooltipProps['content'];
  iconProps?: Pick<IconProps, Exclude<keyof IconProps, 'icon'>>;
  tooltipProps?: Pick<TooltipProps, Exclude<keyof TooltipProps, 'content'>>;
  className?: string;
}

const styles = {
  contentContainer: {
    textAlign: 'center',
    lineHeight: 'initial',
    padding: '15px 0',
  } as React.CSSProperties,
};

export class IconWithTooltip extends React.PureComponent<IconWithTooltipProps> {
  render() {
    const {
      icon,
      tooltipContent,
      iconProps = emptyObject,
      tooltipProps = emptyObject,
      className,
    } = this.props;

    return (
      <Tooltip content={tooltipContent} {...tooltipProps}>
        <div style={styles.contentContainer}>
          <Icon icon={icon} {...iconProps} className={className} />
        </div>
      </Tooltip>
    );
  }
}
