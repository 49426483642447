export const voiceboxQueryResponseFilter = (part: string) => {
  // eslint-disable-next-line no-param-reassign
  part = part.trim();
  const posOfMarkdownStart = part.indexOf('```');
  if (posOfMarkdownStart !== -1) {
    // comment all extra lines with '#'
    const posOfMarkdownEnd = part.indexOf('```', posOfMarkdownStart + 3);
    const before = part
      .slice(0, posOfMarkdownStart)
      .trim()
      .replace(/^(\S.*)$/gm, '\n# $1')
      .trim();

    if (posOfMarkdownEnd !== -1) {
      const code = part.slice(posOfMarkdownStart + 3, posOfMarkdownEnd).trim();
      const after = part
        .slice(posOfMarkdownEnd + 3)
        .trim()
        .replace(/^(\S.*)$/gm, '\n# $1')
        .trim();
      return `${before}\n${code}\n${after}`.trim();
    }
    const code = part.slice(posOfMarkdownStart + 3).trim();
    return `${before}\n${code}`.trim();
  }

  return part.replace(/^```$/gm, '').trim();
};
