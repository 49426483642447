import {
  ActionCreatorAction,
  createTypedAction,
} from 'src/common/actions/types';
import {
  VisualizationSettings,
  VisualizationSourceData,
} from 'src/common/store/visualization/VisualizationState';

export const enum VisualizationsActionType {
  VISUALIZE_RESULTS_ATTEMPT = 'VISUALIZE_RESULTS_ATTEMPT',
  VISUALIZE_RESULTS_SUCCESS = 'VISUALIZE_RESULTS_SUCCESS',
  VISUALIZE_RESULTS_FAILURE = 'VISUALIZE_RESULTS_FAILURE',

  EXPORT_VISUALIZATION_TO_IMAGE_ATTEMPT = 'EXPORT_VISUALIZATION_TO_IMAGE_ATTEMPT',
  EXPORT_VISUALIZATION_TO_IMAGE_SUCCESS = 'EXPORT_VISUALIZATION_TO_IMAGE_SUCCESS',
  EXPORT_VISUALIZATION_TO_IMAGE_FAILURE = 'EXPORT_VISUALIZATION_TO_IMAGE_FAILURE',

  SAVE_VISUALIZATION_LAYOUT_ATTEMPT = 'SAVE_VISUALIZATION_LAYOUT_ATTEMPT',
  SAVE_VISUALIZATION_LAYOUT_SUCCESS = 'SAVE_VISUALIZATION_LAYOUT_SUCCESS',
  SAVE_VISUALIZATION_LAYOUT_FAILURE = 'SAVE_VISUALIZATION_LAYOUT_FAILURE',

  LOAD_VISUALIZATION_LAYOUT_ATTEMPT = 'LOAD_VISUALIZATION_LAYOUT_ATTEMPT',
  LOAD_VISUALIZATION_LAYOUT_SUCCESS = 'LOAD_VISUALIZATION_LAYOUT_SUCCESS',
  LOAD_VISUALIZATION_LAYOUT_FAILURE = 'LOAD_VISUALIZATION_LAYOUT_FAILURE',

  UPDATE_VISUALIZATION_SETTINGS = 'UPDATE_VISUALIZATION_SETTINGS',
  CLEAR_VISUALIZATION_DATA = 'CLEAR_VISUALIZATION_DATA',
}

export const visualizationsActionCreators = {
  visualizeResultsAttempt: (
    cacheKey: string,
    settingsId: string,
    source: VisualizationSourceData
  ) =>
    createTypedAction(VisualizationsActionType.VISUALIZE_RESULTS_ATTEMPT, {
      cacheKey,
      settingsId,
      source,
    }),
  visualizeResultsSuccess: (
    cacheKey: string,
    settingsId: string,
    source: VisualizationSourceData,
    settings: Partial<VisualizationSettings>
  ) =>
    createTypedAction(VisualizationsActionType.VISUALIZE_RESULTS_SUCCESS, {
      cacheKey,
      settingsId,
      source,
      settings,
    }),
  visualizeResultsFailure: (
    cacheKey: string,
    settingsId: string,
    source: VisualizationSourceData
  ) =>
    createTypedAction(VisualizationsActionType.VISUALIZE_RESULTS_FAILURE, {
      cacheKey,
      settingsId,
      source,
    }),

  exportVisualizationToImageAttempt: (cacheKey: string, settingsId: string) =>
    createTypedAction(
      VisualizationsActionType.EXPORT_VISUALIZATION_TO_IMAGE_ATTEMPT,
      {
        cacheKey,
        settingsId,
      }
    ),
  exportVisualizationToImageSuccess: (
    cacheKey: string,
    settingsId: string,
    filePath: string
  ) =>
    createTypedAction(
      VisualizationsActionType.EXPORT_VISUALIZATION_TO_IMAGE_SUCCESS,
      {
        cacheKey,
        settingsId,
        filePath,
      }
    ),
  exportVisualizationToImageFailure: (
    cacheKey: string,
    settingsId: string,
    exportFor: string,
    wasCancelled?: boolean
  ) =>
    createTypedAction(
      VisualizationsActionType.EXPORT_VISUALIZATION_TO_IMAGE_FAILURE,
      {
        cacheKey,
        settingsId,
        exportFor,
        wasCancelled,
      }
    ),

  saveVisualizationLayoutAttempt: (cacheKey: string, settingsId: string) =>
    createTypedAction(
      VisualizationsActionType.SAVE_VISUALIZATION_LAYOUT_ATTEMPT,
      {
        cacheKey,
        settingsId,
      }
    ),
  saveVisualizationLayoutSuccess: (
    cacheKey: string,
    settingsId: string,
    filePath: string
  ) =>
    createTypedAction(
      VisualizationsActionType.SAVE_VISUALIZATION_LAYOUT_SUCCESS,
      {
        cacheKey,
        settingsId,
        filePath,
      }
    ),
  saveVisualizationLayoutFailure: (
    cacheKey: string,
    settingsId: string,
    layoutFor: string
  ) =>
    createTypedAction(
      VisualizationsActionType.SAVE_VISUALIZATION_LAYOUT_FAILURE,
      {
        cacheKey,
        settingsId,
        layoutFor,
      }
    ),

  loadVisualizationLayoutAttempt: (cacheKey: string, settingsId: string) =>
    createTypedAction(
      VisualizationsActionType.LOAD_VISUALIZATION_LAYOUT_ATTEMPT,
      {
        cacheKey,
        settingsId,
      }
    ),
  loadVisualizationLayoutSuccess: (
    cacheKey: string,
    settingsId: string,
    filePath: string
  ) =>
    createTypedAction(
      VisualizationsActionType.LOAD_VISUALIZATION_LAYOUT_SUCCESS,
      {
        cacheKey,
        settingsId,
        filePath,
      }
    ),
  loadVisualizationLayoutFailure: (
    cacheKey: string,
    settingsId: string,
    layoutFor: string
  ) =>
    createTypedAction(
      VisualizationsActionType.LOAD_VISUALIZATION_LAYOUT_FAILURE,
      {
        cacheKey,
        settingsId,
        layoutFor,
      }
    ),

  updateVisualizationSettings: (
    settingsId: string,
    settings: Partial<VisualizationSettings>
  ) =>
    createTypedAction(VisualizationsActionType.UPDATE_VISUALIZATION_SETTINGS, {
      settingsId,
      settings,
    }),
  clearVisualizationData: (cacheKeys: string[], settingsId?: string) =>
    createTypedAction(VisualizationsActionType.CLEAR_VISUALIZATION_DATA, {
      cacheKeys,
      settingsId,
    }),
};

export type VisualizationsAction = ActionCreatorAction<
  typeof visualizationsActionCreators
>;
