import { ReduxState } from 'src/types';

// during development, studio can be accidentally brought to a state
// where the activenoteId does not refer to an existing tab.
export const validateActiveNoteId = (state: ReduxState): ReduxState => {
  const { activeNoteId, noteIds } = state.notebook;

  return {
    ...state,
    notebook: {
      ...state.notebook,
      activeNoteId: noteIds.includes(activeNoteId) ? activeNoteId : noteIds[0],
    },
  };
};
