import {
  RDFS_LABEL_URI,
  RDF_TYPE_URI,
  STARDOG_API_GRAPH_TAG,
  STARDOG_STUDIO_IS_ATTRIBUTE_TAG,
  STARDOG_STUDIO_LABEL_TAG,
} from 'src/common/constants/uris';

function listToBoolMap(arr: any[]) {
  return arr.reduce(
    (acc, value) => ({
      ...acc,
      [value]: true,
    }),
    {}
  );
}

export const VISUALIZATION_TRIPLES_LIMIT = 100000;
export const VISUALIZATION_PERF_MODE_LIMIT = 1000;

// group types
export const NO_GROUP_TYPE = '__NO_GROUP_TYPE__';
export const MULTI_GROUP_TYPE = '__MULTI_GROUP_TYPE__';

export const LITERAL_GROUP_PREFIX = '__LITERAL__';
export function getLiteralGroupType(uri: string) {
  return `${LITERAL_GROUP_PREFIX}${uri}`;
}

export const USE_LABEL_PREDICATES_CAPTION_KEY = '__USE_LABEL_PREDICATES__';
export const DEFAULT_NODE_CAPTION_KEY = USE_LABEL_PREDICATES_CAPTION_KEY;
export const DEFAULT_LINK_CAPTION_KEY = '@type';
export const DEFAULT_LITERAL_CAPTION_KEY = '@type';

export const BASE_GROUP_TYPE_LIST = [NO_GROUP_TYPE, MULTI_GROUP_TYPE];
export const BASE_GROUP_TYPE_MAP = listToBoolMap(BASE_GROUP_TYPE_LIST);

// properties that we generally handle as edge cases in rendering
export const SPECIAL_PROPERTIES_LIST = [
  '@id',
  '@type',
  '@graph',
  '@subject',
  '@predicate',
  '@object',
  STARDOG_STUDIO_LABEL_TAG,
  STARDOG_STUDIO_IS_ATTRIBUTE_TAG,
];
export const SPECIAL_PROPERTIES_MAP = listToBoolMap(SPECIAL_PROPERTIES_LIST);

// options
export const ORDERED_BASE_OPTIONS = [
  USE_LABEL_PREDICATES_CAPTION_KEY,
  '@id',
  '@type',
  RDF_TYPE_URI,
  '@graph',
  RDFS_LABEL_URI,
  '@source',
  '@target',
  '',
];
export const BASE_NODE_GROUPS = {
  [RDF_TYPE_URI]: RDF_TYPE_URI,
  '@graph': STARDOG_API_GRAPH_TAG,
};
export const BASE_LINK_GROUPS = {
  '@type': 'iri',
  '@graph': STARDOG_API_GRAPH_TAG,
  '@source': 'source',
  '@target': 'target',
};

export const BASE_NODE_CAPTIONS = {
  [USE_LABEL_PREDICATES_CAPTION_KEY]: 'Use Label Predicates',
  '@id': 'iri',
  '@graph': STARDOG_API_GRAPH_TAG,
  [RDF_TYPE_URI]: RDF_TYPE_URI,
  '': 'none',
};
export const BASE_LINK_CAPTIONS = {
  '@type': 'iri',
  '@graph': STARDOG_API_GRAPH_TAG,
  '@source': 'source',
  '@target': 'target',
  '': 'none',
};
