import omit from 'lodash.omit';
import { isNotEqual } from 'vet-bones/bones/utils';

import { hiddenProperties } from 'src/common/store/virtualGraphs/options';
import { MutableVirtualGraphSettings } from 'src/common/store/virtualGraphs/VirtualGraphsState';
import { filterVirtualGraphOptions } from 'src/common/utils/virtual_graphs/filterVirtualGraphOptions';

export const isPropertiesDirty = (
  prevProps: MutableVirtualGraphSettings['properties'],
  nextProps: MutableVirtualGraphSettings['properties']
) => {
  const prevFiltered = omit(
    filterVirtualGraphOptions(prevProps),
    hiddenProperties
  );
  const nextFiltered = omit(
    filterVirtualGraphOptions(nextProps),
    hiddenProperties
  );
  return isNotEqual(prevFiltered, nextFiltered);
};
