import { Reducer, combineReducers } from 'redux';

import { connectionReducer } from 'src/common/reducers/connection';
import { dataReducer } from 'src/common/reducers/data';
import { databasesReducer } from 'src/common/reducers/databases';
import { featuresReducer } from 'src/common/reducers/features';
import { modelsReducer } from 'src/common/reducers/models';
import { notebookReducer } from 'src/common/reducers/notebook';
import { notificationsReducer } from 'src/common/reducers/notifications';
import { preferencesReducer } from 'src/common/reducers/preferences';
import { queriesReducer } from 'src/common/reducers/queries';
import { queryHistoryReducer } from 'src/common/reducers/queryHistory';
import { securityReducer } from 'src/common/reducers/security';
import { starchartReducer } from 'src/common/reducers/starchart';
import { storedQueriesReducer } from 'src/common/reducers/storedQueries';
import { virtualGraphsReducer } from 'src/common/reducers/virtualGraphs';
import { visualizationsReducer } from 'src/common/reducers/visualizations';
import { ReduxState } from 'src/types';

export const reducers = {
  connection: connectionReducer,
  data: dataReducer,
  databases: databasesReducer,
  features: featuresReducer,
  models: modelsReducer,
  notebook: notebookReducer,
  notifications: notificationsReducer,
  security: securityReducer,
  preferences: preferencesReducer,
  queries: queriesReducer,
  storedQueries: storedQueriesReducer,
  queryHistory: queryHistoryReducer,
  starchart: starchartReducer,
  virtualGraphs: virtualGraphsReducer,
  visualizations: visualizationsReducer,
  // Next "reducer" never really does anything, but having it here allows us
  // to keep the version string on state without having Redux complain about
  // unexpected keys.
  version: () => process.env.APP_VERSION || '0.0.1', // fallback here is only really needed for unit tests (which skip compilation step)
};

export function getRootReducer(): Reducer<ReduxState> {
  return combineReducers({ ...reducers });
}
