import omit from 'lodash.omit';
import { alphaSort, isNotEqual } from 'vet-bones/bones/utils';

import {
  ConnectionAction,
  ConnectionActionType,
} from 'src/common/actions/connection/connectionActionCreators';
import {
  DataAction,
  DataActionType,
} from 'src/common/actions/data/dataActionCreators';
import { isBody } from 'src/common/actions/request/types';
import {
  VirtualGraphsAction,
  VirtualGraphsActionType,
} from 'src/common/actions/virtualGraphs/virtualGraphsActionCreators';
import {
  DataSource,
  DataSourceListInfoObject,
  DataState,
  VirtualGraphsByDataSource,
} from 'src/common/store/data/DataState';
import { VirtualGraphOptionValueMap } from 'src/common/store/virtualGraphs/options';
import {
  VGSources,
  VirtualGraphMappingType,
  VirtualGraphSource,
} from 'src/common/store/virtualGraphs/VirtualGraphMappingType';
import { getDataSourceNameFromUri } from 'src/common/utils/dataSources/getDataSourceNameFromUri';
import { dedupeArray } from 'src/common/utils/dedupeArray';
import { emptyObject } from 'src/common/utils/emptyObject';
import { safelyGet } from 'src/common/utils/safelyGet';

const initialState = new DataState();
const alphaSorter = alphaSort();

function setLocalDataSource(
  dataSource: Partial<DataSource>,
  overrideLocalData?: boolean
) {
  let isDirty = false;

  if (!dataSource.localSource || !dataSource.isDirty || overrideLocalData) {
    dataSource.localSource = dataSource.source;
  } else if (dataSource.localSource !== dataSource.source) {
    isDirty = true;
  }

  if (!dataSource.localType || !dataSource.isDirty || overrideLocalData) {
    dataSource.localType = dataSource.type;
  } else if (dataSource.localType !== dataSource.type) {
    isDirty = true;
  }

  if (!dataSource.localProperties || !dataSource.isDirty || overrideLocalData) {
    dataSource.localProperties = dataSource.lastRetrievedProperties;
  } else if (
    isNotEqual(dataSource.localProperties, dataSource.lastRetrievedProperties)
  ) {
    isDirty = true;
  }

  if (!isDirty) {
    dataSource.missingFieldNames = [];
    dataSource.validationErrors = {};
  }

  dataSource.isDirty = isDirty;

  return dataSource;
}

export const dataReducer = (
  prevState: DataState = initialState,
  action: DataAction | ConnectionAction | VirtualGraphsAction
): DataState => {
  switch (action.type) {
    case ConnectionActionType.SET_CONNECTION_SUCCESS: {
      return initialState;
    }

    case VirtualGraphsActionType.REQUEST_VIRTUAL_GRAPHS_LIST_INFO_SUCCESS: {
      const vgs: any[] = action.payload?.response?.body?.virtual_graphs || [];
      const vgsByDataSourceId = vgs.reduce<VirtualGraphsByDataSource>(
        (acc, vg) => {
          const dataSource = vg.data_source
            ? getDataSourceNameFromUri(vg.data_source)
            : null;
          if (!dataSource) {
            return acc;
          }

          if (!acc[dataSource]) {
            acc[dataSource] = [];
          }
          acc[dataSource].push(vg.name);
          return acc;
        },
        {}
      );

      const dataSourcesById = Object.entries(prevState.dataSourcesById).reduce(
        (acc, [id, dataSource]) => {
          const hasVirtualGraphs = Boolean(
            safelyGet(vgsByDataSourceId, [id, 'length'], 0)
          );
          acc[id] = setLocalDataSource(
            {
              ...dataSource,
            },
            hasVirtualGraphs
          );
          return acc;
        },
        {}
      );

      return {
        ...prevState,
        vgsByDataSourceId,
        dataSourcesById,
      };
    }

    case VirtualGraphsActionType.CREATE_VIRTUAL_GRAPH_SUCCESS: {
      const { name, dataSourceId } = action.payload.action;
      // we don't need to do extra work if data sources are not supported (Stardog < v7.4.4)
      if (!dataSourceId) {
        return prevState;
      }

      const vgsByDataSourceId = { ...prevState.vgsByDataSourceId };
      if (!vgsByDataSourceId[dataSourceId]) {
        vgsByDataSourceId[dataSourceId] = [name];
      } else {
        vgsByDataSourceId[dataSourceId] = dedupeArray(
          vgsByDataSourceId[dataSourceId].concat(name)
        );
      }

      return {
        ...prevState,
        vgsByDataSourceId,
      };
    }

    case VirtualGraphsActionType.UPDATE_VIRTUAL_GRAPH_SUCCESS: {
      const { name, dataSourceId } = action.payload.action;

      const vgsByDataSourceId = Object.entries(
        prevState.vgsByDataSourceId
      ).reduce<VirtualGraphsByDataSource>((acc, [dataSource, vgs]) => {
        const updatedVgs = vgs.slice(0);
        const vgIdx = vgs.indexOf(name);
        // if necessary, move the virtual graph from the old data source list to the new one
        if (vgIdx > -1 && dataSource !== dataSourceId) {
          // the virtual graph is no longer paired with data source and needs to be removed
          updatedVgs.splice(vgIdx, 1);
        } else if (vgIdx === -1 && dataSource === dataSourceId) {
          // the virtual graph is not paired with data source and needs to be added
          updatedVgs.push(name);
        }
        acc[dataSource] = updatedVgs;
        return acc;
      }, {});

      if (dataSourceId && !vgsByDataSourceId[dataSourceId]) {
        vgsByDataSourceId[dataSourceId] = [name];
      }

      return {
        ...prevState,
        vgsByDataSourceId,
      };
    }

    case VirtualGraphsActionType.DELETE_VIRTUAL_GRAPH_SUCCESS: {
      const { vgId } = action.payload;
      let privateDataSourceName = '';

      const vgsByDataSourceId = Object.entries(
        prevState.vgsByDataSourceId
      ).reduce<VirtualGraphsByDataSource>((acc, [dataSourceName, vgs]) => {
        const updatedVgs = vgs.slice(0);
        const vgIdx = vgs.indexOf(vgId);
        if (vgIdx > -1) {
          updatedVgs.splice(vgIdx, 1);
        }

        const dataSource = prevState.dataSourcesById[dataSourceName];
        const isVgTiedPrivateDataSource =
          dataSource && !dataSource.sharable && vgIdx >= 0;

        if (isVgTiedPrivateDataSource) {
          privateDataSourceName = dataSourceName;
        } else {
          acc[dataSourceName] = updatedVgs;
        }
        return acc;
      }, {});

      return {
        ...prevState,
        dataSourceIds: prevState.dataSourceIds.filter(
          (dataSourceId) => dataSourceId !== privateDataSourceName
        ),
        dataSourcesById: omit(prevState.dataSourcesById, privateDataSourceName),
        selectedDataSourceId:
          privateDataSourceName === prevState.selectedDataSourceId
            ? ''
            : prevState.selectedDataSourceId,
        vgsByDataSourceId,
      };
    }

    case DataActionType.REQUEST_DATA_SOURCES_LIST_INFO_ATTEMPT: {
      return {
        ...prevState,
        pending: true,
      };
    }
    case DataActionType.REQUEST_DATA_SOURCES_LIST_INFO_SUCCESS: {
      const data_sources: DataSourceListInfoObject[] =
        action.payload?.response?.body?.data_sources || [];
      const dataSourceNames = data_sources
        .map((dataSource) => getDataSourceNameFromUri(dataSource.entityName))
        .sort(alphaSorter);
      const nextDataSourcesById = dataSourceNames.reduce((acc, name) => {
        const dataSource = data_sources.find(
          (dataSource) =>
            getDataSourceNameFromUri(dataSource.entityName) === name
        );
        const nextDataSource = prevState.dataSourcesById[name] || { id: name };
        nextDataSource.sharable = dataSource.sharable;
        nextDataSource.available = dataSource.available;
        return {
          ...acc,
          [name]: nextDataSource,
        };
      }, {});

      return {
        ...prevState,
        pending: false,
        dataSourceIds: dataSourceNames,
        dataSourcesById: nextDataSourcesById,
      };
    }
    case DataActionType.REQUEST_DATA_SOURCES_LIST_INFO_FAILURE: {
      return {
        ...prevState,
        pending: false,
      };
    }

    case DataActionType.REQUEST_OPTIONS_FOR_DATA_SOURCES_SUCCESS: {
      return action.payload.responses.reduce((state, action) => {
        return dataReducer(state, action as DataAction);
      }, prevState);
    }
    case DataActionType.REQUEST_OPTIONS_FOR_DATA_SOURCE_SUCCESS: {
      if (!isBody(action.payload.response)) {
        return prevState;
      }

      // get the default options for the virtual graph
      const id = action.payload.args[0] as string;

      const responseOptions = safelyGet<
        typeof action.payload,
        VirtualGraphOptionValueMap,
        typeof emptyObject
      >(action.payload, ['response', 'body', 'options'], emptyObject);

      let source: VirtualGraphSource;
      let type: VirtualGraphMappingType;
      if (responseOptions['mongodb.uri']) {
        source = VirtualGraphSource.MONGO;
        type = VirtualGraphMappingType.MONGO;
      } else if (responseOptions['cassandra.contact.point']) {
        source = VirtualGraphSource.APACHE_CASSANDRA;
        type = VirtualGraphMappingType.APACHE_CASSANDRA;
      } else if (responseOptions['elasticsearch.rest.urls']) {
        source = VirtualGraphSource.ELASTICSEARCH;
        type = VirtualGraphMappingType.ELASTICSEARCH;
      } else if (responseOptions['jdbc.url']) {
        source = VirtualGraphSource.SQL;
        const match = Object.entries(VGSources).find(([, vgSpec]) => {
          return (
            vgSpec.uriMatcher && // it's optional
            vgSpec.uriMatcher.test(responseOptions['jdbc.url'])
          );
        });
        type = match
          ? (match[0] as VirtualGraphMappingType)
          : VirtualGraphMappingType.GENERIC_SQL;
      }
      if (!type) {
        return prevState;
      }

      const overrideLocalData =
        action.payload.action.overrideLocalData ||
        Boolean(safelyGet(prevState.vgsByDataSourceId, [id, 'length'], 0));

      return {
        ...prevState,
        dataSourcesById: {
          ...prevState.dataSourcesById,
          [id]: setLocalDataSource(
            {
              ...prevState.dataSourcesById[id],
              source,
              type,
              lastRetrievedProperties: responseOptions,
            },
            overrideLocalData
          ),
        },
      };
    }

    case DataActionType.BRING_DATA_SOURCE_ONLINE_ATTEMPT: {
      const id = action.payload.action.dataSourceId;
      return {
        ...prevState,
        dataSourcesById: {
          ...prevState.dataSourcesById,
          [id]: {
            ...prevState.dataSourcesById[id],
            pending: true,
          },
        },
      };
    }
    case DataActionType.BRING_DATA_SOURCE_ONLINE_SUCCESS: {
      const id = action.payload.action.dataSourceId;
      return {
        ...prevState,
        dataSourcesById: {
          ...prevState.dataSourcesById,
          [id]: {
            ...prevState.dataSourcesById[id],
            available: true,
            pending: false,
          },
        },
      };
    }
    case DataActionType.BRING_DATA_SOURCE_ONLINE_FAILURE: {
      const id = action.payload.action.dataSourceId;
      const nextState = {
        ...prevState,
        dataSourcesById: {
          ...prevState.dataSourcesById,
          [id]: {
            ...prevState.dataSourcesById[id],
            pending: false,
          },
        },
      };

      // ignore failure if the vg is already online
      if (
        safelyGet<any, string, string>(
          action.payload.response,
          ['body', 'message'],
          ''
        ).endsWith('is already online')
      ) {
        nextState.dataSourcesById[id].available = true;
      }

      return nextState;
    }

    case DataActionType.UPDATE_DATA_SOURCE_ATTEMPT: {
      const {
        dataSourceName: id,
        source: localSource,
        sourceType: localType,
        options: localProperties,
      } = action.payload.action;
      return {
        ...prevState,
        dataSourcesById: {
          ...prevState.dataSourcesById,
          [id]: {
            ...prevState.dataSourcesById[id],
            localSource,
            localType,
            localProperties,
            pendingUpdate: true,
          },
        },
      };
    }
    case DataActionType.UPDATE_DATA_SOURCE_SUCCESS: {
      const {
        dataSourceName: id,
        source,
        sourceType: type,
        options: lastRetrievedProperties,
      } = action.payload.action;
      const prevDataSource = prevState.dataSourcesById[id];
      return {
        ...prevState,
        dataSourcesById: {
          ...prevState.dataSourcesById,
          [id]: {
            ...prevDataSource,
            source,
            type,
            lastRetrievedProperties,
            isDirty: false,
            pendingUpdate: false,
          },
        },
      };
    }

    case DataActionType.SHARE_DATA_SOURCE_FAILURE:
    case DataActionType.UPDATE_DATA_SOURCE_FAILURE: {
      const { dataSourceName: id } = action.payload.action;
      return {
        ...prevState,
        dataSourcesById: {
          ...prevState.dataSourcesById,
          [id]: {
            ...prevState.dataSourcesById[id],
            pendingUpdate: false,
          },
        },
      };
    }

    case DataActionType.SHARE_DATA_SOURCE_ATTEMPT: {
      const { dataSourceName: id } = action.payload.action;
      return {
        ...prevState,
        dataSourcesById: {
          ...prevState.dataSourcesById,
          [id]: {
            ...prevState.dataSourcesById[id],
            pendingUpdate: true,
          },
        },
      };
    }

    case DataActionType.SHARE_DATA_SOURCE_SUCCESS: {
      const { dataSourceName: id } = action.payload.action;
      const prevDataSource = prevState.dataSourcesById[id];
      return {
        ...prevState,
        dataSourcesById: {
          ...prevState.dataSourcesById,
          [id]: {
            ...prevDataSource,
            sharable: true,
            pendingUpdate: false,
          },
        },
      };
    }

    case DataActionType.CREATE_DATA_SOURCE_ATTEMPT: {
      return {
        ...prevState,
        pendingCreate: true,
      };
    }
    case DataActionType.CREATE_DATA_SOURCE_SUCCESS: {
      const {
        dataSourceName: id,
        source,
        sourceType: type,
        options,
      } = action.payload.action;
      return {
        ...prevState,
        pendingCreate: false,
        selectedDataSourceId: id,
        dataSourceIds: [...prevState.dataSourceIds, id].sort(alphaSorter),
        dataSourcesById: {
          ...prevState.dataSourcesById,
          [id]: new DataSource({
            id,
            available: true,
            isDirty: false,
            sharable: true,
            source,
            type,
            lastRetrievedProperties: options,
            localProperties: options,
            localSource: source,
            localType: type,
          }),
        },
      };
    }
    case DataActionType.CREATE_DATA_SOURCE_FAILURE: {
      return {
        ...prevState,
        pendingCreate: false,
      };
    }

    case DataActionType.DELETE_DATA_SOURCE_SUCCESS: {
      const { dataSourceName } = action.payload.action;
      const dataSourcesById = Object.keys(prevState.dataSourcesById)
        .filter((dataSourceId) => dataSourceId !== dataSourceName)
        .reduce((acc, key) => {
          acc[key] = prevState.dataSourcesById[key];
          return acc;
        }, {});

      return {
        ...prevState,
        dataSourceIds: Object.keys(dataSourcesById),
        dataSourcesById,
        selectedDataSourceId:
          dataSourceName === prevState.selectedDataSourceId
            ? ''
            : prevState.selectedDataSourceId,
      };
    }

    case DataActionType.SET_SELECTED_DATA_SOURCE_ID: {
      return {
        ...prevState,
        selectedDataSourceId: action.payload.dataSourceId,
      };
    }

    case DataActionType.UPDATE_LOCAL_DATA_SOURCE: {
      const {
        dataSourceId: id,
        source: localSource,
        type: localType,
        properties,
        validationErrors: errors,
        missingFieldNames,
      } = action.payload;

      const prevDataSource = prevState.dataSourcesById[id];

      const localProperties = Object.entries(properties).reduce(
        (acc, [key, value]) => {
          if (value) {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );
      const validationErrors = Object.entries({
        ...prevDataSource.validationErrors,
        ...errors,
      }).reduce((acc, [key, value]) => {
        if (value) {
          acc[key] = value;
        }
        return acc;
      }, {});

      return {
        ...prevState,
        dataSourcesById: {
          ...prevState.dataSourcesById,
          [id]: setLocalDataSource({
            ...prevDataSource,
            isDirty: true,
            localSource,
            localType,
            localProperties,
            validationErrors,
            missingFieldNames,
          }),
        },
      };
    }

    case DataActionType.UPDATE_DATA_MOSAIC_STATE: {
      return {
        ...prevState,
        mosaicState: {
          ...prevState.mosaicState,
          ...action.payload.mosaicStateChange,
        },
      };
    }

    default:
      return prevState;
  }
};
