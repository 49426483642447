import { Classes, Icon, InputGroup, InputGroupProps } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import classNames from 'classnames';
import debounce from 'lodash.debounce';
import * as React from 'react';

import { DebouncedInput } from 'src/common/components/DebouncedInput';

interface FilterableItemsProps<T>
  extends Pick<InputGroupProps, 'leftIcon' | 'rightElement' | 'placeholder'> {
  items: readonly T[] | T[];
  predicate(item: T, filterText: string): boolean;
  children(filteredItems: T[]): JSX.Element;
  filter?: (
    changeHandler: (event: React.FormEvent<HTMLElement>) => void
  ) => JSX.Element;
  onFilterChange?: (filterText: string, prevFilterText: string) => void;
  actions?: JSX.Element;
  className?: string;
  debounced?: boolean;
  testId?: string;
  inputTestId?: string;
}

export class FilterableItems<T> extends React.Component<
  FilterableItemsProps<T>,
  { filterText: string }
> {
  static defaultProps = {
    leftIcon: IconNames.SEARCH,
    rightElement: null,
    placeholder: 'Filter...',
  };

  static ofType<T>() {
    return FilterableItems as new (
      props: FilterableItemsProps<T>
    ) => FilterableItems<T>;
  }

  private memoizationData;

  private controlBarElement: HTMLDivElement;

  private listElement: HTMLDivElement;

  private debouncedCheckScrollForControlBarShadow = debounce(
    this.checkScrollForControlBarShadow,
    10
  );

  state = {
    filterText: '',
  };

  handleFilterChange = (filterText: string) => {
    if (filterText !== this.state.filterText) {
      if (this.props.onFilterChange) {
        this.props.onFilterChange(filterText, this.state.filterText);
      }
      this.setState({ filterText });
    }
  };

  memoizedGetFilteredItems = () => {
    const { predicate } = this.props;
    const items = this.props.items as T[];
    const { filterText } = this.state;

    if (!this.memoizationData) {
      this.memoizationData = {
        items,
        filterText,
        lastResults: items.filter((item) => predicate(item, filterText)),
      };
      return this.memoizationData.lastResults;
    }

    const {
      items: lastItems,
      filterText: lastFilterText,
      lastResults,
    } = this.memoizationData;

    const dataDidChange =
      lastItems.length !== items.length ||
      lastFilterText !== filterText ||
      lastItems.some((item, idx) => item !== items[idx]);

    if (dataDidChange) {
      this.memoizationData = {
        items,
        filterText,
        lastResults: items.filter((item) => predicate(item, filterText)),
      };
      return this.memoizationData.lastResults;
    }

    return lastResults;
  };

  setControlBarContainer = (node: HTMLDivElement | null) => {
    this.controlBarElement = node;
  };

  setListContainer = (node: HTMLDivElement | null) => {
    this.listElement = node;
  };

  checkScrollForControlBarShadow() {
    const list = this.listElement;
    const controlBar = this.controlBarElement;

    if (!list || !controlBar) {
      return;
    }

    if (list.scrollTop > 0) {
      controlBar.classList.add('scroll-active');
    } else {
      controlBar.classList.remove('scroll-active');
    }
  }

  renderFilter() {
    const { debounced, inputTestId, leftIcon, rightElement, placeholder } =
      this.props;
    const { filterText } = this.state;
    return debounced ? (
      <div
        className={classNames(
          Classes.INPUT_GROUP,
          'sd-filterable-items-filter '
        )}
      >
        {leftIcon ? <Icon icon={leftIcon} /> : null}
        <DebouncedInput
          className={Classes.INPUT}
          onChange={this.handleFilterChange}
          placeholder={placeholder}
          testId={inputTestId}
          type="search"
          value={filterText}
        />
        {rightElement}
      </div>
    ) : (
      <InputGroup
        className="sd-filterable-items-filter"
        data-testid={inputTestId}
        leftIcon={leftIcon}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
          this.handleFilterChange(evt.currentTarget.value)
        }
        placeholder={placeholder}
        rightElement={rightElement}
        type="search"
      />
    );
  }

  render() {
    const filteredItems = this.memoizedGetFilteredItems();
    const { className } = this.props;
    return (
      <div className="sd-filterable-items">
        <div
          ref={this.setControlBarContainer}
          className="sd-filterable-items-control-bar"
          data-testid={this.props.testId}
        >
          {this.renderFilter()}
          {this.props.actions}
        </div>
        <div
          ref={this.setListContainer}
          className={classNames('sd-sidebar-list', className)}
          onScroll={this.debouncedCheckScrollForControlBarShadow}
        >
          {this.props.children(filteredItems)}
        </div>
      </div>
    );
  }
}
