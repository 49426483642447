import omit from 'lodash.omit';
import * as React from 'react';

import {
  ConnectionList,
  ConnectionListProps,
} from 'src/common/containers/Login/ConnectionList';
import { SignInForm } from 'src/common/containers/Login/SignInForm';

type SavedConnectionsManagerProps = ConnectionListProps & {
  selectedConnectionName: string;
};

export const SavedConnectionsManager: React.VFC<SavedConnectionsManagerProps> =
  (props) => {
    const {
      connections,
      currentConnection,
      handleSetConnection,
      handleSetSelectedConnectionName,
      selectedConnectionName,
    } = props;

    if (!selectedConnectionName || !connections[selectedConnectionName]) {
      return <ConnectionList {...props} />;
    }

    return (
      <SignInForm
        connection={connections[selectedConnectionName]}
        // FIXME: we need to pass down currentConnection only because we need its
        // PENDING status in order to show a progress spinner on the SignInForm.
        // This is because the status of the SAVED connection that you are
        // actually trying to connect with is not itself updated. It probably
        // should be. Whether we then change how the current connection is
        // handled, too, is an open question.
        currentConnection={currentConnection}
        onBack={() => handleSetSelectedConnectionName('')}
        onSubmit={(connection) => {
          handleSetConnection(omit(connection, 'token'));
        }}
      />
    );
  };
