import {
  Button,
  Checkbox,
  Intent,
  Spinner,
  SpinnerSize,
} from '@blueprintjs/core';
import { autoBindMethodsForReact } from 'class-autobind-decorator';
import classNames from 'classnames';
import * as React from 'react';

import { TEST_IDS } from 'src/common/constants/testIds';
import { popoverRowClassName } from 'src/common/constants/ui';
import {
  FormOptions,
  SavedFormOptions,
} from 'src/common/containers/Login/ConnectionFormOptions';
import {
  Connection,
  ConnectionStatus,
} from 'src/common/store/connection/ConnectionState';

export const enum ConnectionType {
  EXISTING,
  NEW,
}

type ConnectionFormState = {
  connection: Connection;
  shouldSaveConnection?: boolean;
};

type ConnectionFormProps = {
  connection: Connection;
  connectionType: ConnectionType;
  onCancel?: (todo: any) => any;
  onSubmit: (connectionFormState: ConnectionFormState) => void;
};

@autoBindMethodsForReact
export class ConnectionForm extends React.Component<
  ConnectionFormProps,
  ConnectionFormState
> {
  constructor(props: ConnectionFormProps) {
    super(props);
    const { connection } = props;

    this.state = { connection };
  }

  handleTextFieldChange(evt: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = evt.target;
    const { connection } = this.state;
    this.setState({
      connection: {
        ...connection,
        [name]: value,
      },
    });
  }

  handleToggleProperty(property: keyof ConnectionFormState | keyof Connection) {
    if (property === 'shouldSaveConnection') {
      this.setState((prevState) => ({
        ...prevState,
        [property]: !prevState[property],
      }));
    } else if (property === 'shouldStayLoggedIn') {
      this.setState((prevState) => ({
        ...prevState,
        connection: {
          ...prevState.connection,
          [property]: !prevState.connection[property],
        },
      }));
    }
  }

  handleFormSubmit(evt: React.FormEvent) {
    const { onSubmit } = this.props;
    evt.preventDefault();

    const { connection } = this.state;

    onSubmit({
      ...this.state,
      connection: {
        ...connection,
        name: connection.name.trim(),
      },
    });
  }

  render() {
    const { connection, connectionType, onCancel } = this.props;
    const { connection: localConnection, shouldSaveConnection } = this.state;

    const actionControls =
      connectionType === ConnectionType.NEW ? (
        <Button
          data-testid={TEST_IDS.connectionForm.submitButton}
          disabled={
            connection.status === ConnectionStatus.PENDING ||
            (shouldSaveConnection && !localConnection.name)
          }
          icon={
            connection.status === ConnectionStatus.PENDING ? (
              <Spinner intent={Intent.SUCCESS} size={SpinnerSize.SMALL} />
            ) : null
          }
          intent={Intent.PRIMARY}
          text="Connect"
          type="submit"
        />
      ) : (
        <>
          <Button
            data-testid={TEST_IDS.connectionForm.submitButton}
            disabled={!localConnection.name}
            intent={Intent.PRIMARY}
            text="Save and Connect"
            type="submit"
          />
          {onCancel ? (
            <Button
              data-testid={TEST_IDS.connectionForm.cancelButton}
              intent={Intent.NONE}
              onClick={onCancel}
              text="Cancel"
              type="button"
            />
          ) : null}
        </>
      );

    return (
      <form onSubmit={this.handleFormSubmit}>
        <FormOptions
          connection={localConnection}
          handleTextFieldChange={this.handleTextFieldChange}
          handleToggleProperty={this.handleToggleProperty}
        />
        {connectionType === ConnectionType.NEW ? (
          <Checkbox
            checked={shouldSaveConnection}
            label="Add to My Connections"
            onChange={() => this.handleToggleProperty('shouldSaveConnection')}
          />
        ) : null}
        {connectionType === ConnectionType.EXISTING || shouldSaveConnection ? (
          <SavedFormOptions
            connection={localConnection}
            handleTextFieldChange={this.handleTextFieldChange}
          />
        ) : null}
        <div className={classNames(popoverRowClassName, 'actions')}>
          {actionControls}
        </div>
      </form>
    );
  }
}
