export type Lit =
  | string
  | number
  | boolean
  | undefined
  | null
  | void
  | symbol
  | {};
export const getAsTypedTuple = <T extends Lit[]>(...args: T): T => args;
