import { queriesStardogRequestDispatchers } from 'src/common/actions/queries/queriesActionCreators';
import { Response } from 'src/common/actions/request/types';
import {
  StudioStateGetter,
  StudioThunkDispatch,
} from 'src/common/actions/StudioAction';
import { NoteState } from 'src/common/store/notebook/NoteState';

export const killQuery =
  (queryId: string) =>
  async (_: StudioThunkDispatch, getState: StudioStateGetter) => {
    const { notes } = getState().notebook;
    const associatedNote: NoteState | undefined = Object.values(notes).find(
      (note) => {
        return note?.resultSettings?.queryId === queryId;
      }
    );

    const promises: Array<Promise<Response<any, any, any>>> = [];

    if (!associatedNote) {
      return Promise.all(promises);
    }

    promises.push(
      queriesStardogRequestDispatchers.killQuery({
        queryId,
        associatedNoteId: associatedNote.id,
      })
    );

    const jsonLdQueryId =
      associatedNote.resultSettings?.jsonLdBody?.queryHistoryId;

    if (jsonLdQueryId) {
      promises.push(
        queriesStardogRequestDispatchers.killQuery({
          queryId: jsonLdQueryId,
        })
      );
    }

    return Promise.all(promises);
  };
