import { H4 } from '@blueprintjs/core';
import { autoBindMethodsForReact } from 'class-autobind-decorator';
import * as React from 'react';
import { stringToRegExp } from 'vet-bones/bones/utils';

import { ConnectionListItem } from 'src/common/containers/Login/ConnectionListItem';
import { ExportSavedConnectionsButton } from 'src/common/containers/Login/ExportSavedConnectionsButton';
import { FilterableItems } from 'src/common/containers/QueryHistory/FilterableItems';
import {
  Connection,
  SavedConnectionNames,
  SavedConnections,
} from 'src/common/store/connection/ConnectionState';
import { isConnectionInfoSufficient } from 'src/common/utils/connection/isConnectionInfoSufficient';

const FilterableConnections = FilterableItems.ofType<string>();

export type ConnectionListProps = {
  connections: SavedConnections;
  connectionNames: SavedConnectionNames;
  currentConnection: Connection;
  handleDeleteConnection: (connectionName: string) => any;
  handleSetEditingConnection: (connectionName: string) => any;
  handleSetConnection: (connection: Connection) => any;
  handleSetSelectedConnectionName: (connectionName: string) => any;
};

@autoBindMethodsForReact
export class ConnectionList extends React.Component<ConnectionListProps> {
  handleFilterPredicate(item: string, filterText: string) {
    const { connections } = this.props;
    const connection = connections[item];
    if (!connection) {
      return false;
    }
    const { username, endpoint, name } = connection;
    const base = username ? `${username}@${endpoint}` : endpoint;
    const testString = name ? `${name} ${base}` : base;
    return stringToRegExp(filterText, 'i').test(testString);
  }

  render() {
    const {
      currentConnection,
      connections,
      connectionNames,
      handleSetConnection,
      handleSetSelectedConnectionName,
      handleDeleteConnection,
      handleSetEditingConnection,
    } = this.props;

    if (!connectionNames.length) {
      return (
        <div className="sd-connection-list sd-connection-list-empty">
          <H4>No Connections Yet</H4>
          <p>
            To save connections to here, check "Add to My Connections" when
            creating a new connection.
          </p>
        </div>
      );
    }

    return (
      <div className="sd-connection-list">
        <FilterableConnections
          className="sd-connection-list-content"
          items={connectionNames}
          placeholder="Search Connections..."
          predicate={this.handleFilterPredicate}
        >
          {(filteredConnections) => (
            <>
              {filteredConnections.map((name) => {
                const connection = connections[name];
                if (!connection) {
                  return null;
                }
                return (
                  <ConnectionListItem
                    key={name}
                    connection={connection}
                    currentConnection={currentConnection}
                    deleteConnection={() => handleDeleteConnection(name)}
                    editConnection={() => {
                      handleSetEditingConnection(name);
                    }}
                    setConnection={() => {
                      // If we click on a connection that already has
                      // sufficient info, we just connect to it. Otherwise,
                      // we switch to the Sign In form.
                      if (isConnectionInfoSufficient(connection)) {
                        handleSetConnection(connection);
                      } else {
                        handleSetSelectedConnectionName(name);
                      }
                    }}
                  />
                );
              })}
            </>
          )}
        </FilterableConnections>

        <ExportSavedConnectionsButton />
      </div>
    );
  }
}
