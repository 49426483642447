import { getSanitizedModelsState } from 'src/common/store/sanitize/models';
import { getSanitizedNotebookState } from 'src/common/store/sanitize/notebook';
import { getSanitizedVisualizationsState } from 'src/common/store/sanitize/visualizations';
import { ReduxState } from 'src/types';

export const stateSanitizer = (state: ReduxState) => {
  return {
    ...state,
    models: getSanitizedModelsState(state.models),
    notebook: getSanitizedNotebookState(state.notebook),
    visualizations: getSanitizedVisualizationsState(state.visualizations),
  };
};
