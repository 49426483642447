import * as semver from 'semver';

import {
  DatabasesAction,
  databasesActionCreators,
  databasesStardogRequestDispatchers,
} from 'src/common/actions/databases/databasesActionCreators';
import {
  StudioStateGetter,
  StudioThunkDispatch,
} from 'src/common/actions/StudioAction';

export const requestAvailableOptions =
  () =>
  (
    dispatch: StudioThunkDispatch<DatabasesAction>,
    getState: StudioStateGetter
  ) => {
    const { connection, features } = getState();
    const { isAvailableDatabaseOptionsMethodSupported } = features.stardog;
    const currentVersion = connection.current.stardogVersion;
    const currentSemver = semver.coerce(currentVersion || '0.0.0');
    if (isAvailableDatabaseOptionsMethodSupported) {
      const overrideGraphqlDefaultLimit = semver.eq(currentSemver, '7.7.2');
      // For Stardog 7.7.2, the server returns an invalid defaultValue for
      // graphql.default.limit (see PLAT-2929). Studio must override this value,
      // or no other DB properties can be updated by the user unless they first
      // change the value of graphql.default.limit themselves.
      return databasesStardogRequestDispatchers.requestAvailableOptions(
        overrideGraphqlDefaultLimit
      );
    }
    return dispatch(databasesActionCreators.setStaticAvailableOptions());
  };
