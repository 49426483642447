import {
  NoteStateMap,
  NotebookState,
} from 'src/common/store/notebook/NotebookState';
import {
  NoteState,
  defaultVoiceboxSettings,
} from 'src/common/store/notebook/NoteState';
import { Preferences } from 'src/common/store/preferences/PreferencesState';
import { safelyGet } from 'src/common/utils/safelyGet';
import { InitialData } from 'src/types';

// eslint-disable-next-line import/no-default-export
export default (data: InitialData) => {
  const oldNotebook: Partial<NotebookState> = safelyGet(data, ['notebook'], {});
  const oldNotes: NoteStateMap = safelyGet(oldNotebook, ['notes'], {});
  const newNotes: NoteStateMap = Object.entries(oldNotes).reduce(
    (acc, [noteId, note]) => {
      const newNote: NoteState = {
        ...note,
        voiceboxSettings: { ...defaultVoiceboxSettings },
      };
      acc[noteId] = newNote;
      return acc;
    },
    {}
  );
  const preferences: Partial<Preferences & { defaultQueryPlanFormat: string }> =
    { ...(data?.preferences || {}) };
  delete preferences.defaultQueryPlanFormat;

  return {
    ...data,
    version: '3.10.6',
    notebook: {
      ...oldNotebook,
      notes: newNotes,
    },
    preferences,
  };
};
