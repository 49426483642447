import * as semver from 'semver';

import { databasesStardogRequestDispatchers } from 'src/common/actions/databases/databasesActionCreators';
import {
  StudioStateGetter,
  StudioThunkDispatch,
} from 'src/common/actions/StudioAction';

export const requestDetailsForDatabase =
  (dbId: string, shouldNotDispatch?: boolean) =>
  (_: StudioThunkDispatch, getState: StudioStateGetter) => {
    const { connection, features } = getState();
    const { isUpdatedGetAllSupported, isVirtualTransparencySupported } =
      features.stardog;
    const { stardogVersion = '0.0.0' } = connection.current;
    const currentSemver = semver.coerce(stardogVersion);
    const overrideGraphQlDefaultLimit = semver.gte(currentSemver, '7.7.2');

    return databasesStardogRequestDispatchers.requestDetailsForDatabase({
      dbId,
      overrideGraphQlDefaultLimit,
      stardogSupportsVirtualTransparency: isVirtualTransparencySupported,
      shouldNotDispatch,
      useGetAllMethod: isUpdatedGetAllSupported,
    });
  };
