import { isDevelopment } from 'src/common/utils/isDevelopment';
import { isSnapshot } from 'src/common/utils/isSnapshot';

/* TODO: use template strings after typescript is updated
  export type StardogFeatures = {
    [key in `is${Capitalize<keyof typeof featureSupportVersions>}Supported`]: boolean;
  };
 */
export type StardogFeatures = {
  isAtomicGraphQlSchemaUpdateSupported: boolean;
  isAvailableDatabaseOptionsMethodSupported: boolean;
  isBiServerSupported: boolean;
  isClientSideQueryIdSupported: boolean;
  isDataSourceShareSupported: boolean;
  isDataSourcesSupported: boolean;
  isDbCreateWithPropertiesFileSupported: boolean;
  isIcvConstraintsEnabled: boolean;
  isEditingDataSourcesWithVgsSupported: boolean;
  isEphemeralLoginSupported: boolean;
  isFrequencyPredicatesSupported: boolean;
  isGraphQLStoredQueriesSupported: boolean;
  isImportNamespacesOverHttpSupported: boolean;
  isMultiSchemaSupported: boolean;
  isNamedGraphAliasesSupported: boolean;
  isPreserveExactMappingsSupported: boolean;
  isQueryProfilerSupported: boolean;
  isRequestServerPropertiesSupported: boolean;
  isSensitivePropertiesSupported: boolean;
  isSms2Supported: boolean;
  isStarchartSupported: boolean;
  isStoredQueryReasoningAndDescriptionSupported: boolean;
  isUpdatedGetAllSupported: boolean;
  isUserRoleListEndpointsSupported: boolean;
  isVgAssociateWithDbSupported: boolean;
  isVirtualTransparencySupported: boolean;
};

const defaultStardogFeatures: StardogFeatures = {
  isAtomicGraphQlSchemaUpdateSupported: false,
  isAvailableDatabaseOptionsMethodSupported: false,
  isBiServerSupported: false,
  isClientSideQueryIdSupported: false,
  isDataSourceShareSupported: false,
  isDataSourcesSupported: false,
  isDbCreateWithPropertiesFileSupported: false,
  isIcvConstraintsEnabled: true,
  isEditingDataSourcesWithVgsSupported: false,
  isEphemeralLoginSupported: true,
  isFrequencyPredicatesSupported: false,
  isGraphQLStoredQueriesSupported: false,
  isImportNamespacesOverHttpSupported: false,
  isMultiSchemaSupported: false,
  isNamedGraphAliasesSupported: false,
  isPreserveExactMappingsSupported: false,
  isQueryProfilerSupported: false,
  isRequestServerPropertiesSupported: false,
  isSensitivePropertiesSupported: false,
  isSms2Supported: false,
  isStarchartSupported: false,
  isStoredQueryReasoningAndDescriptionSupported: false,
  isUpdatedGetAllSupported: false,
  isUserRoleListEndpointsSupported: false,
  isVgAssociateWithDbSupported: false,
  isVirtualTransparencySupported: false,
};

type StudioFeatures = {
  isRepositoriesEnabled: boolean;
};

export const isStudioFeatureEnabled = isDevelopment() || isSnapshot();

const defaultStudioFeatures: StudioFeatures = {
  isRepositoriesEnabled: isStudioFeatureEnabled,
};

export class FeaturesState {
  constructor(
    public stardog: StardogFeatures = defaultStardogFeatures,
    public studio: StudioFeatures = defaultStudioFeatures
  ) {}
}
