export const enum IpcEventType {
  OPEN_FILE = 'open-file',
  UPDATE_TELEMETRY_CONSENT = 'update-telemetry-consent',
  MAIN_PROCESS_REQUEST = 'main-process-request',
  MAIN_PROCESS_REQUEST_RESULT = 'main-process-request-result',
  MAIN_PROCESS_REQUEST_RECEIVED = 'main-process-request-received',
  NEW_TAB_FROM_MENU = 'new-tab-from-menu',
  CLOSE_TAB_FROM_MENU = 'close-tab-from-menu',
  FORCE_CLOSE_TAB_FROM_MENU = 'force-close-tab-from-menu',
  ADD_STREAMING_DATA_REQUEST = 'ADD_STREAMING_DATA_REQUEST',
  ADD_STREAMING_DATA_RESPONSE = 'ADD_STREAMING_DATA_RESPONSE',
  REMOVE_STREAMING_DATA_REQUEST = 'REMOVE_STREAMING_DATA_REQUEST',
  REMOVE_STREAMING_DATA_RESPONSE = 'REMOVE_STREAMING_DATA_RESPONSE',
  QUERY_RESPONSE_START = 'query-response-start',
  QUERY_RESULTS = 'query-results',
  QUERY_FAILURE = 'query-failure',
  EXECUTE_SELECT_QUERY = 'execute-select-query',
  INVALIDATE_BINDINGS_CACHE = 'invalidate-bindings-cache',
  FETCH_BINDINGS_CACHE = 'fetch-bindings-cache',
  RECEIVED_BINDINGS_CACHE = 'received-bindings-cache',
  KEYBOARD_EVENT = 'keyboard_event',
  OPEN_RELEASE_NOTES = 'open_release_notes',
  OPEN_USER_PREFERENCES = 'open_user_preferences',
  SHOW_KEYBOARD_SHORTCUTS = 'show-keyboard-shortcuts',
}

export const WorkerRpcEvent = 'WORKER_RPC_EVENT';
export const ElectronIpcEvent = 'ELECTRON_IPC_EVENT';
