import {
  AnchorButton,
  Button,
  Classes,
  FormGroup,
  InputGroup,
  Intent,
  Spinner,
  SpinnerSize,
  Tooltip,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';

import { TEST_IDS } from 'src/common/constants/testIds';
import {
  Connection,
  ConnectionStatus,
} from 'src/common/store/connection/ConnectionState';

type SignInFormProps = {
  connection: Connection;
  currentConnection: Connection;
  hideTitle?: boolean;
  onBack?: () => any;
  onSubmit: (connection: Connection) => any;
};

export const SignInForm: React.VFC<SignInFormProps> = ({
  connection,
  currentConnection,
  hideTitle = false,
  onBack,
  onSubmit,
}) => {
  const [localConnection, setLocalConnection] = React.useState<any>({
    ...connection,
  });
  const isConnectionPending =
    currentConnection.status === ConnectionStatus.PENDING;

  const handleTextFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    setLocalConnection({
      ...localConnection,
      [name]: value,
    });
  };

  const handleSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();

    if (localConnection.password && !isConnectionPending) {
      onSubmit(localConnection);
    }
  };

  return (
    <div className="sd-sign-in">
      {onBack ? (
        <div className="sd-back-button">
          <Button
            className={Classes.TEXT_SMALL}
            icon={IconNames.CHEVRON_LEFT}
            minimal
            onClick={onBack}
            text="Back"
          />
        </div>
      ) : null}
      <form className="sd-connection-form" onSubmit={handleSubmit}>
        <div className="sd-connection-form__body">
          {!hideTitle ? (
            <div className="sd-sign-in__title">
              <div className={Classes.TEXT_LARGE}>
                Sign in to <b>{connection.name}</b>
              </div>
              <div>{connection.endpoint}</div>
            </div>
          ) : null}

          <FormGroup
            disabled
            label="Username"
            labelFor="sd-connection-username"
          >
            <InputGroup
              autoComplete="username"
              disabled
              id="sd-connection-username"
              name="username"
              value={localConnection.username}
            />
          </FormGroup>
          <FormGroup label="Password" labelFor="sd-connection-password">
            <InputGroup
              autoComplete="password"
              autoFocus
              id="sd-connection-password"
              name="password"
              onChange={handleTextFieldChange}
              type="password"
              value={localConnection.password}
            />
          </FormGroup>
          <input hidden type="submit" />
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Tooltip
              content="Password is required"
              disabled={Boolean(localConnection.password)}
            >
              <AnchorButton
                data-testid={TEST_IDS.connectionForm.submitButton}
                disabled={!localConnection.password || isConnectionPending}
                icon={
                  isConnectionPending ? (
                    <Spinner size={SpinnerSize.SMALL} />
                  ) : null
                }
                intent={Intent.PRIMARY}
                onClick={handleSubmit}
                text="Connect"
              />
            </Tooltip>
          </div>
        </div>
      </form>
    </div>
  );
};
