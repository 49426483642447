import { HTTP } from 'stardog';

import { WorkerRpcEvent } from 'src/common/constants/events';
import { Connection } from 'src/common/store/connection/ConnectionState';

export enum SparqlLSPMethod {
  DID_UPDATE_COMPLETION_DATA = '$/didUpdateCompletionData',
}

export const isErrorResponseLike = (
  candidate: any
): candidate is Partial<WorkerErrorResponse> => Boolean(candidate.error);

export const isPartialResponse = (
  candidate: any
): candidate is Partial<WorkerPartialResponse> =>
  Boolean(candidate.partialResponse);

interface CoreWorkerRPCData<T> {
  methodData: T;
  eventId: string; // useful for coordinating messages and their handlers
  sendPartialResponse?: boolean;
}
export interface WorkerRPCData<T> extends CoreWorkerRPCData<T> {
  type: typeof WorkerRpcEvent;
  method: 'callStardogApi';
}
export interface WorkerRPCMethodArg<T> extends CoreWorkerRPCData<T> {
  ctx: any;
}

export interface WorkerEvent<T> extends MessageEvent {
  data: WorkerRPCData<T>;
}
export interface WorkerResponseEvent<T> extends MessageEvent {
  data: WorkerResponse<T> | WorkerPartialResponse | WorkerErrorResponse;
}

export enum ResponseType {
  PART,
  COMPLETE,
  PARTIAL,
  ERROR,
  DONE,
}

interface CoreWorkerResponse {
  type: typeof WorkerRpcEvent;
  eventId: string;
  isResponseComplete: boolean;
  responseType: ResponseType;
}
export interface WorkerPartialResponse extends CoreWorkerResponse {
  responseType:
    | ResponseType.PARTIAL
    | ResponseType.COMPLETE
    | ResponseType.PART;
  partialResponse: any;
}
export interface WorkerResponse<T> extends CoreWorkerResponse {
  responseType: ResponseType.DONE;
  response: T;
}
export interface WorkerErrorResponse extends CoreWorkerResponse {
  responseType: ResponseType.ERROR;
  error: Error['message'];
}

export interface StardogApiMethodData {
  api: string;
  connection: Connection;
  args: any[];
}

export interface StardogQueryMethodData {
  acceptType?: string;
  connection?: Connection;
  db: string;
  end?: string;
  limit?: number;
  noteId?: string;
  namedGraphs?: string[];
  onResponseStart?: any;
  query: string;
  queryId?: string;
  reasoning: boolean;
  schema?: string;
  start?: string;
  timeout?: string;
}
export interface Binding {
  value: string;
  type: string;
  datatype?: string;
}
export type StatementBinding = {
  type: 'statement';
  s: Binding;
  p: Binding;
  o: Binding;
};

export type Bindings = Array<{
  [column: string]: Binding | StatementBinding;
}>;

export const CACHED_ROWS_TO_FETCH = 50;

type HTTPBodyWithVariableHeaders = HTTP.Body & {
  headers: any;
};

export interface ReturnData extends Partial<HTTPBodyWithVariableHeaders> {
  noteId: string;
  body: {
    head: any;
    results: {
      bindings: Bindings;
      totalLength: number;
    };
  };
}

export type StardogApiEvent = WorkerEvent<StardogApiMethodData>;

export interface AdditionalActionTriggers {
  onResponseStart?(...args: any[]): void;
}
