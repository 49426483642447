import * as React from 'react';
import { isPortal } from 'vet-bones/bones/utils/portal';

import { WebProtocol } from 'src/common/constants/web';

export const createWebProtocolWarningMessage = ({
  appProtocol,
  endpointProtocol,
  isEndpointLocalhost,
}: {
  appProtocol: WebProtocol;
  endpointProtocol: WebProtocol;
  isEndpointLocalhost: boolean;
}) => {
  let messageElement: React.ReactNode;
  const appLink =
    appProtocol === WebProtocol.HTTP
      ? window.location.origin.replace(WebProtocol.HTTP, WebProtocol.HTTPS)
      : window.location.origin.replace(WebProtocol.HTTPS, WebProtocol.HTTP);
  const link = !isPortal() ? appLink : undefined;

  if (appProtocol === WebProtocol.HTTP && isEndpointLocalhost) {
    messageElement = (
      <>
        The Stardog server is not responding. This could be because:
        <ul>
          <li>Your server is not running</li>
          <li>
            You are connecting to a local server from a website without SSL
          </li>
        </ul>
        Confirm your server is running and you have the correct endpoint.{' '}
        {link ? (
          <>
            Additionally, you can try connecting from{' '}
            <a href={link} rel="noreferrer" target="_blank">
              {link}
            </a>
            .
          </>
        ) : (
          ''
        )}
      </>
    );
  } else if (
    appProtocol === WebProtocol.HTTPS &&
    endpointProtocol === WebProtocol.HTTP
  ) {
    messageElement = (
      <>
        The Stardog server is not responding. This could be because:
        <ul>
          <li>Your server is not running</li>
          <li>
            You are connecting to a HTTP server from an SSL enabled website
          </li>
        </ul>
        Confirm your server is running and you have the correct endpoint.{' '}
        {link ? (
          <>
            Additionally, you can try connecting from{' '}
            <a href={link} rel="noreferrer" target="_blank">
              {link}
            </a>{' '}
            or{' '}
            <a
              href="https://docs.stardog.com/operating-stardog/security/encryption-in-transit#steps-to-setup-ssl"
              rel="noreferrer"
              target="_blank"
            >
              click here
            </a>{' '}
            to learn how to enable SSL for Stardog.
          </>
        ) : (
          <>
            <a
              href="https://docs.stardog.com/operating-stardog/security/encryption-in-transit#steps-to-setup-ssl"
              rel="noreferrer"
              target="_blank"
            >
              Click here
            </a>{' '}
            to learn how to enable SSL for Stardog.
          </>
        )}
      </>
    );
  } else if (
    appProtocol === WebProtocol.HTTP &&
    endpointProtocol === WebProtocol.HTTPS
  ) {
    messageElement = (
      <>
        The Stardog server is not responding. This could be because:
        <ul>
          <li>Your server is not running</li>
          <li>
            You are connecting to a HTTPS server from a website without SSL
          </li>
        </ul>
        Confirm your server is running and you have the correct endpoint.{' '}
        {link ? (
          <>
            Additionally, you can try connecting from{' '}
            <a href={link} rel="noreferrer" target="_blank">
              {link}
            </a>
            .
          </>
        ) : (
          ''
        )}
      </>
    );
  }
  return <>{messageElement}</>;
};
