import { QueryType } from 'src/common/constants/QueryType';

export enum QueryHistoryEntryStatus {
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  SUCCEEDED = 'SUCCEEDED',
}

export interface QueryHistoryEntry {
  id: string;
  associatedNoteId: string;
  timestamp: number;
  queryType: QueryType;
  queryText: string;
  queryExecutionTime: number;
  withReasoning: boolean;
  endpoint: string;
  targetDb: string;
  numResults: number;
  vars: string[];
  status: QueryHistoryEntryStatus;
}

interface QueryHistoryMap {
  [id: string]: QueryHistoryEntry;
}

export class QueryHistoryState {
  constructor(
    public entryIds: readonly string[] = [],
    public entriesById: QueryHistoryMap
  ) {}
}
