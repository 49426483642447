import {
  ConnectionAction,
  ConnectionActionType,
} from 'src/common/actions/connection/connectionActionCreators';
import {
  StoredQueriesAction,
  StoredQueriesActionType,
} from 'src/common/actions/storedQueries/storedQueriesActionCreators';
import { LanguageId } from 'src/common/constants/LanguageId';
import {
  StoredQuery,
  StoredQueryState,
} from 'src/common/store/storedQueries/StoredQueriesState';

const graphqlStartTokens = [
  '{',
  'query',
  'mutation',
  'subscription',
  'fragment',
  'schema',
  '"',
  'scalar',
  'type',
  'interface',
  'union',
  'enum',
  'input',
  'directive',
  'extend',
];

const graphqlRegex = new RegExp(`^(${graphqlStartTokens.join('|')})`);

const initialState = new StoredQueryState();

export const storedQueriesReducer = (
  prevState = initialState,
  action: StoredQueriesAction | ConnectionAction
): StoredQueryState => {
  switch (action.type) {
    // Reset stored queries state if a new connection is set
    case ConnectionActionType.SET_CONNECTION_SUCCESS:
      return initialState;

    case StoredQueriesActionType.GET_STORED_QUERIES_SUCCESS: {
      const { queries = [] }: { queries: StoredQuery[] } =
        action.payload?.response?.body || {};
      const { isGraphQLStoredQueriesSupported } = action.payload?.action || {};
      if (!isGraphQLStoredQueriesSupported) {
        return {
          ...prevState,
          queries,
          isCached: true,
        };
      }

      const queriesWithLanguageId = queries.map((query) => {
        return {
          ...query,
          languageId: query.query.match(graphqlRegex)
            ? LanguageId.GRAPHQL
            : LanguageId.SPARQL,
        };
      });

      return {
        ...prevState,
        queries: queriesWithLanguageId,
        isCached: true,
      };
    }
    case StoredQueriesActionType.DELETE_STORED_QUERY_SUCCESS: {
      const deletedName = action.payload.action.queryName;
      const queries = prevState.queries.filter(
        (query) => query.name !== deletedName
      );
      const newState = {
        ...prevState,
        queries,
      };
      return newState;
    }
    case StoredQueriesActionType.STORE_QUERY_SUCCESS: {
      const newQuery = action.payload.args[0];
      return {
        ...prevState,
        queries: [...prevState.queries, newQuery],
      };
    }
    case StoredQueriesActionType.UPDATE_QUERY_SUCCESS: {
      // changes to notebook state are handled in that reducer, this should only
      // update the stored queries state
      const actionQuery: Pick<
        StoredQuery,
        'name' | 'database' | 'query' | 'shared' | 'reasoning'
      > = action.payload.args[0];
      const prevQuery = prevState.queries.find(
        (query) => query.name === actionQuery.name
      );
      const updatedQuery: StoredQuery = { ...prevQuery, ...actionQuery };
      const updatedQueries = [
        updatedQuery,
        ...prevState.queries.filter(
          (query) => query.name !== updatedQuery.name
        ),
      ];
      return { ...prevState, queries: updatedQueries };
    }
    default:
      return prevState;
  }
};
