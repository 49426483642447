import { Button, Menu, MenuItem, Popover } from '@blueprintjs/core';
import * as React from 'react';

import {
  LanguageId,
  LanguageIdKey,
  availableLanguageIds,
} from 'src/common/constants/LanguageId';
import {
  TEST_IDS,
  getStatusBarLanguageMenuItemTestId,
} from 'src/common/constants/testIds';
import { isRenderingHeadlessly } from 'src/common/utils/isRenderingHeadlessly';

export interface StatusBarLanguageMenuProps {
  onChange(languageId: LanguageId): any;
  value: LanguageId;
  languageIds?: readonly LanguageIdKey[];
}

export const StatusBarLanguageMenuInterior = ({
  languageIds,
  selectedLanguageId,
  setLanguageId,
}: {
  languageIds: typeof availableLanguageIds;
  selectedLanguageId: StatusBarLanguageMenuProps['value'];
  setLanguageId: StatusBarLanguageMenuProps['onChange'];
}) => (
  <Menu data-testid={TEST_IDS.statusBar.languageMenu.interior}>
    {languageIds.map((id) => (
      <MenuItem
        key={id}
        active={selectedLanguageId === id.toLowerCase()}
        data-testid={getStatusBarLanguageMenuItemTestId(
          id.toLowerCase() as LanguageId
        )}
        onClick={() => setLanguageId(id.toLowerCase() as LanguageId)}
        text={id}
      />
    ))}
  </Menu>
);

export const StatusBarLanguageMenu = ({
  value,
  onChange,
  languageIds,
}: StatusBarLanguageMenuProps) => (
  <div className="sd-status-bar-dynamic">
    <Popover
      content={
        <StatusBarLanguageMenuInterior
          languageIds={languageIds || availableLanguageIds}
          selectedLanguageId={value}
          setLanguageId={onChange}
        />
      }
      minimal
    >
      <Button
        className="sd-language-menu-button"
        data-testid={TEST_IDS.statusBar.languageMenu.button}
        minimal
      >
        {isRenderingHeadlessly ? 'Loading' : value.toUpperCase()}
      </Button>
    </Popover>
  </div>
);
