import { query as stardogQuery } from 'stardog';

import { LanguageId } from 'src/common/constants/LanguageId';
import { QueryTypeIdentifier } from 'src/common/constants/QueryType';

// Only SPARQL queries accept LIMIT clauses, and we currently intentionally do
// not apply the queryLimit preference to CONSTRUCT/DESCRIBE queries (see
// VET-999 and VET-293).
export const doesQueryAcceptLimitPreference = (
  query: string,
  queryLanguageId: LanguageId
) => {
  if (queryLanguageId !== LanguageId.SPARQL) {
    return false;
  }
  const queryType = stardogQuery.utils.queryType(query);
  return (
    queryType !== QueryTypeIdentifier.CONSTRUCT &&
    queryType !== QueryTypeIdentifier.DESCRIBE &&
    queryType !== QueryTypeIdentifier.VALIDATE
  );
};
