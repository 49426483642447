import uuid from 'uuid';

import { STARDOG_STUDIO_LABEL_TAG } from 'src/common/constants/uris';
import { getLabelFromBindingResults } from 'src/common/utils/labels/getLabelFromBindingResults';
import { ItemWithLabels } from 'src/common/utils/labels/types';
import { BindingResult } from 'src/common/utils/queryBuilders/shared/types';

export function addLabelsToItems<T>(
  id: string,
  label: BindingResult,
  labelPredicate: BindingResult,
  items: { [id: string]: ItemWithLabels<T> }
) {
  if (!label || !labelPredicate) {
    return items;
  }

  const predicateLabel = getLabelFromBindingResults(label);
  if (labelPredicate.value === STARDOG_STUDIO_LABEL_TAG) {
    items[id].preferredLabel = predicateLabel;
  } else {
    if (!items[id].labelsByPredicate[labelPredicate.value]) {
      items[id].labelsByPredicate[labelPredicate.value] = {};
    }
    items[id].labelsByPredicate[labelPredicate.value][predicateLabel.value] =
      predicateLabel;
  }

  items[id].labelsByPredicateId = uuid();
  return items;
}
