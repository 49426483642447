import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { BrowserWithConfirmation } from 'src/browser/components/BrowserWithConfirmation';
import { Confirmation } from 'src/browser/components/Confirmation';
import { WithConfirmationOptions } from 'src/common/components/ContextualStudioApi';

const unmountAndRemove = (node: HTMLElement) => {
  ReactDOM.unmountComponentAtNode(node);
  node.remove();
};
// Render an unattached dialog for receiving confirmation imperatively.
const showBrowserConfirmation = (props: WithConfirmationOptions) => {
  const node = document.createElement('div');
  ReactDOM.render(
    <Confirmation
      {...props}
      onConfirmationFailure={() => {
        unmountAndRemove(node);
        if (props.onConfirmationFailure) {
          props.onConfirmationFailure();
        }
      }}
      onConfirmationSuccess={() => {
        unmountAndRemove(node);
        props.onConfirmationSuccess();
      }}
    />,
    node
  );
};

export const browserConfirmationApi = {
  WithConfirmation: BrowserWithConfirmation,
  showConfirmation: showBrowserConfirmation,
};
