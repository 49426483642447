import { ModelsActionType } from 'src/common/actions/models/modelsActionCreators';
import { NotebookActionType } from 'src/common/actions/notebook/notebookActionCreators';
import { VisualizationsActionType } from 'src/common/actions/visualizations/visualizationsActionCreators';

export const actionSanitizer = (action) => {
  if (
    action.type === NotebookActionType.EXECUTE_QUERY_FOR_NOTE_SUCCESS ||
    action.type === NotebookActionType.EXECUTE_JSON_LD_QUERY_FOR_NOTE_SUCCESS ||
    action.type === ModelsActionType.REQUEST_MODEL_TEXT_SUCCESS ||
    action.type === ModelsActionType.REQUEST_MODEL_VISUALIZATION_DATA_SUCCESS
  ) {
    return {
      ...action,
      payload: {
        ...action.payload,
        response: {
          ...action.payload.response,
          body: 'redacted for user experience',
        },
      },
    };
  }
  if (action.type === ModelsActionType.REQUEST_MODEL_CONSTRAINTS_SUCCESS) {
    return {
      ...action,
      payload: {
        ...action.payload,
        body: action.payload.body === '' ? '' : 'redacted for user experience',
      },
    };
  }
  if (
    action.type === ModelsActionType.REQUEST_MODEL_ATTRIBUTES_SUCCESS ||
    action.type === ModelsActionType.REQUEST_MODEL_CLASSES_SUCCESS ||
    action.type === ModelsActionType.REQUEST_MODEL_RELATIONSHIPS_SUCCESS
  ) {
    return {
      ...action,
      payload: {
        ...action.payload,
        bindings: action.payload.bindings.length
          ? 'redacted for user experience'
          : action.payload.bindings,
      },
    };
  }
  if (action.type === VisualizationsActionType.VISUALIZE_RESULTS_SUCCESS) {
    return {
      ...action,
      payload: {
        ...action.payload,
        settings: {
          ...action.payload.settings,
          groupTypes: 'redacted for user experience',
        },
      },
    };
  }
  return action;
};
