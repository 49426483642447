import {
  NoteStateMap,
  NotebookState,
} from 'src/common/store/notebook/NotebookState';
import { NoteState } from 'src/common/store/notebook/NoteState';
import { safelyGet } from 'src/common/utils/safelyGet';
import { InitialData } from 'src/types';

export default (data: InitialData) => {
  const oldNotebook: Partial<NotebookState> = safelyGet(data, ['notebook'], {});
  const oldNotes: NoteStateMap = safelyGet(oldNotebook, ['notes'], {});
  const newNotes: NoteStateMap = Object.entries(oldNotes).reduce(
    (acc, [noteId, note]) => {
      // @ts-ignore: migrating namedGraph string to namedGraphs string[]
      const namedGraphs = note.editorSettings.namedGraph
        ? [
            // @ts-ignore: migrating namedGraph string to namedGraphs string[]
            note.editorSettings.namedGraph,
          ]
        : [];
      const newNote: NoteState = {
        ...note,
        editorSettings: {
          ...note.editorSettings,
          namedGraphs,
        },
      };
      acc[noteId] = newNote;
      return acc;
    },
    {}
  );
  return {
    ...data,
    version: '1.15.0',
    notebook: {
      ...oldNotebook,
      notes: newNotes,
    },
  };
};
