import { openInTab } from 'src/browser/actions/fileSystem/action-creators/openInTab';
import { browserApi } from 'src/browser/apis/browserApi';
import { withLeaveDataConfirmation } from 'src/common/actions/data/withLeaveDataConfirmation';
import { withLeaveDatabasesConfirmation } from 'src/common/actions/databases/withLeaveDatabasesConfirmation';
import { withLeaveModelsConfirmation } from 'src/common/actions/models/withLeaveModelsConfirmation';
import {
  deleteNote,
  forceDeleteNote,
} from 'src/common/actions/notebook/action-creators/deleteNote';
import { openUserPreferences } from 'src/common/actions/notebook/action-creators/openUserPreferences';
import { notebookActionCreators } from 'src/common/actions/notebook/notebookActionCreators';
import { killQuery } from 'src/common/actions/queries/action-creators/stardog-api/killQuery';
import {
  StudioStore,
  StudioThunkDispatch,
} from 'src/common/actions/StudioAction';
import { LeaveHubConfirmationHandler } from 'src/common/actions/types';
import { IpcEventType } from 'src/common/constants/events';
import { ROUTES } from 'src/common/constants/Routes';
import { NoteState } from 'src/common/store/notebook/NoteState';
import { history } from 'src/common/utils/history';
import { safelyGet } from 'src/common/utils/safelyGet';

const leaveHubConfirmationHandlers = {
  [ROUTES.DATA]: withLeaveDataConfirmation,
  [ROUTES.DATABASES]: withLeaveDatabasesConfirmation,
  [ROUTES.MODELS]: withLeaveModelsConfirmation,
};

function withLeaveHubConfirmation(
  dispatch: StudioThunkDispatch,
  onConfirmationSuccess: () => any
) {
  const confirmationHandler: LeaveHubConfirmationHandler =
    leaveHubConfirmationHandlers[history.location.pathname];

  if (confirmationHandler) {
    return dispatch(confirmationHandler({ onConfirmationSuccess }));
  }
  return onConfirmationSuccess();
}

// Replicates closeNote() and closeNotes() found in NotebookTab component
function closeNote(note: NoteState, noteId: string) {
  return (dispatch: StudioThunkDispatch) => {
    const pending = safelyGet<NoteState, boolean, boolean>(
      note,
      ['resultSettings', 'pending'],
      false
    );
    const queryId = safelyGet<NoteState>(note, ['resultSettings', 'queryId']);
    return new Promise<void>((resolve) => {
      if (pending && queryId) {
        browserApi.confirmation.showConfirmation({
          message:
            'Closing this tab will kill the running query. Do you want to continue?',
          onConfirmationSuccess: () => {
            dispatch(killQuery(queryId)).then(() => {
              dispatch(deleteNote({ noteId }));
              resolve();
            });
          },
        });
      } else {
        dispatch(deleteNote({ noteId }));
        resolve();
      }
    });
  };
}

function forceCloseNote(note: NoteState, noteId: string) {
  return (dispatch: StudioThunkDispatch) => {
    const pending = safelyGet<NoteState, boolean, boolean>(
      note,
      ['resultSettings', 'pending'],
      false
    );
    const queryId = safelyGet<NoteState>(note, ['resultSettings', 'queryId']);
    return new Promise<void>((resolve) => {
      if (pending && queryId) {
        dispatch(killQuery(queryId)).then(() => {
          dispatch(forceDeleteNote({ noteId }));
          resolve();
        });
      } else {
        dispatch(forceDeleteNote({ noteId }));
        resolve();
      }
    });
  };
}

export function registerNotebookListeners(
  store: StudioStore & {
    dispatch: StudioThunkDispatch;
  }
) {
  browserApi.ipcRenderer.on(IpcEventType.OPEN_FILE, (_, file) =>
    withLeaveHubConfirmation(store.dispatch, () => {
      // If we're not in the editor view currently, switch to it
      if (history.location.pathname !== ROUTES.NOTEBOOK) {
        history.push(ROUTES.NOTEBOOK);
      }
      store.dispatch(openInTab(file));
    })
  );

  browserApi.ipcRenderer.on(IpcEventType.OPEN_RELEASE_NOTES, () =>
    withLeaveHubConfirmation(store.dispatch, () => {
      // If we're not in the editor view currently, switch to it
      if (history.location.pathname !== ROUTES.NOTEBOOK) {
        history.push(ROUTES.NOTEBOOK);
      }
      store.dispatch(notebookActionCreators.openReleaseNotes());
    })
  );

  browserApi.ipcRenderer.on(IpcEventType.OPEN_USER_PREFERENCES, () =>
    withLeaveHubConfirmation(store.dispatch, () => {
      // If we're not in the editor view currently, switch to it
      if (history.location.pathname !== ROUTES.NOTEBOOK) {
        history.push(ROUTES.NOTEBOOK);
      }
      store.dispatch(openUserPreferences());
    })
  );

  browserApi.ipcRenderer.on(IpcEventType.CLOSE_TAB_FROM_MENU, () => {
    const { activeNoteId, notes } = store.getState().notebook;
    return store.dispatch(closeNote(notes[activeNoteId], activeNoteId));
  });
  browserApi.ipcRenderer.on(IpcEventType.FORCE_CLOSE_TAB_FROM_MENU, () => {
    const { activeNoteId, notes } = store.getState().notebook;
    return store.dispatch(forceCloseNote(notes[activeNoteId], activeNoteId));
  });
}
