import * as semver from 'semver';

import {
  ConnectionAction,
  ConnectionActionType,
} from 'src/common/actions/connection/connectionActionCreators';
import { featureSupportVersions } from 'src/common/constants/featureSupportVersions';
import {
  FeaturesState,
  StardogFeatures,
} from 'src/common/store/features/FeaturesState';

export const isFeatureSupported = (
  featureName: keyof typeof featureSupportVersions,
  stardogVersion: string
) => {
  if (!stardogVersion) {
    return false;
  }

  const supportVersion = featureSupportVersions[featureName];
  const stardogSemVer = semver.coerce(stardogVersion);

  if (supportVersion.min) {
    if (!semver.gte(stardogSemVer, supportVersion.min)) {
      return false;
    }
  }

  if (supportVersion.max) {
    if (!semver.lte(stardogSemVer, supportVersion.max)) {
      return false;
    }
  }

  return true;
};

const initialState = new FeaturesState();

export const featuresReducer = (
  prevState: FeaturesState = initialState,
  action: ConnectionAction
): FeaturesState => {
  switch (action.type) {
    case ConnectionActionType.SET_CONNECTION_SUCCESS: {
      const { stardogVersion } = action.payload.config;
      const isCurrentlySupported = (featureName: string) =>
        isFeatureSupported(featureName, stardogVersion);

      const currentFeatures: StardogFeatures = {
        isAtomicGraphQlSchemaUpdateSupported: isCurrentlySupported(
          'atomicGraphQlSchemaUpdate'
        ),
        isAvailableDatabaseOptionsMethodSupported: isCurrentlySupported(
          'availableDatabaseOptionsMethod'
        ),
        isBiServerSupported: isCurrentlySupported('biServer'),
        isClientSideQueryIdSupported: isCurrentlySupported('clientSideQueryId'),
        isDataSourceShareSupported: isCurrentlySupported('dataSourceShare'),
        isDataSourcesSupported: isCurrentlySupported('dataSources'),
        isDbCreateWithPropertiesFileSupported: isCurrentlySupported(
          'dbCreateWithPropertiesFile'
        ),
        isIcvConstraintsEnabled: !isCurrentlySupported('disableIcvConstraints'),
        isEditingDataSourcesWithVgsSupported: isCurrentlySupported(
          'editingDataSourcesWithVgs'
        ),
        isEphemeralLoginSupported: isCurrentlySupported('ephemeralLogin'),
        isFrequencyPredicatesSupported: isCurrentlySupported(
          'frequencyPredicates'
        ),
        isGraphQLStoredQueriesSupported: isCurrentlySupported(
          'graphQLStoredQueries'
        ),
        isImportNamespacesOverHttpSupported: isCurrentlySupported(
          'importNamespacesOverHttp'
        ),
        isMultiSchemaSupported: isCurrentlySupported('multiSchema'),
        isNamedGraphAliasesSupported: isCurrentlySupported('namedGraphAliases'),
        isPreserveExactMappingsSupported: isCurrentlySupported(
          'preserveExactMappings'
        ),
        isQueryProfilerSupported: isCurrentlySupported('queryProfiler'),
        isRequestServerPropertiesSupported: isCurrentlySupported(
          'requestServerProperties'
        ),
        isSensitivePropertiesSupported: isCurrentlySupported(
          'sensitiveProperties'
        ),
        isSms2Supported: isCurrentlySupported('sms2'),
        isStarchartSupported: isCurrentlySupported('starchart'),
        isStoredQueryReasoningAndDescriptionSupported: isCurrentlySupported(
          'storedQueryReasoningAndDescription'
        ),
        isUpdatedGetAllSupported: isCurrentlySupported('updatedGetAll'),
        isUserRoleListEndpointsSupported: isCurrentlySupported(
          'usersRolesListEndpoints'
        ),
        isVgAssociateWithDbSupported: isCurrentlySupported('vgAssociateWithDb'),
        isVirtualTransparencySupported: isCurrentlySupported(
          'virtualTransparency'
        ),
      };

      return {
        ...prevState,
        stardog: {
          ...prevState.stardog,
          ...currentFeatures,
        },
      };
    }
    case ConnectionActionType.DISCONNECT: {
      return initialState;
    }
    default:
      return prevState;
  }
};
