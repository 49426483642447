import * as React from 'react';
import { connect } from 'react-redux';

import { setLanguageIdForActiveNote } from 'src/common/actions/notebook/action-creators/setLanguageIdForActiveNote';
import { NotebookAction } from 'src/common/actions/notebook/notebookActionCreators';
import { StudioThunkDispatch } from 'src/common/actions/StudioAction';
import {
  LanguageId,
  availableLanguageIds,
} from 'src/common/constants/LanguageId';
import {
  RELEASE_NOTES_NOTE_ID,
  USER_PREFERENCES_NOTE_ID,
} from 'src/common/constants/notebook/noteConstants';
import { StatusBarLanguageMenu } from 'src/common/containers/App/components/StatusBarLanguageMenu';
import {
  EditorSettings,
  NoteStorageType,
} from 'src/common/store/notebook/NoteState';
import { safelyGet } from 'src/common/utils/safelyGet';
import { ReduxState } from 'src/types';

const notesWithoutStatusBar = [RELEASE_NOTES_NOTE_ID, USER_PREFERENCES_NOTE_ID];

const mapStateToProps = ({ notebook }: ReduxState) => {
  const { activeNoteId } = notebook;
  const hideLanguageMenu = notesWithoutStatusBar.indexOf(activeNoteId) > -1;
  const {
    languageId: languageIdForActiveNote,
    storageType,
  }: Partial<EditorSettings> = safelyGet(
    notebook,
    ['notes', activeNoteId, 'editorSettings'],
    { languageId: LanguageId.TXT }
  );

  const activeLanguageIds: typeof availableLanguageIds =
    storageType === NoteStorageType.STARDOG
      ? ['GRAPHQL', 'SPARQL']
      : availableLanguageIds;

  return {
    activeLanguageIds,
    hideLanguageMenu,
    languageIdForActiveNote,
  };
};

const mapDispatchToProps = (dispatch: StudioThunkDispatch<NotebookAction>) => ({
  setLanguageIdForActiveNote: (languageId: LanguageId) =>
    dispatch(setLanguageIdForActiveNote(languageId)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export const UnconnectedNotebookStatusBar = (
  props: StateProps & DispatchProps
) => {
  if (props.hideLanguageMenu) {
    return null;
  }
  return (
    <StatusBarLanguageMenu
      languageIds={props.activeLanguageIds}
      onChange={props.setLanguageIdForActiveNote}
      value={props.languageIdForActiveNote}
    />
  );
};

export const NotebookStatusBar = connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(UnconnectedNotebookStatusBar);
