export const promisify =
  <T extends any[], U extends Function>(fn: U) =>
  (...argsSansNodeCallback: T) =>
    new Promise((resolve, reject) => {
      fn(...argsSansNodeCallback, (err: any, result: any) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(result);
      });
    });

// A `Promisified` module is one where the functions on that module have been
// converted to promise-returning functions.
export type Promisified<
  Module extends {},
  Keys extends string | number | symbol = keyof Module
> = {
  [K in keyof Module]: K extends Keys
    ? Module[K] extends (...args: any[]) => any
      ? (...args: any[]) => Promise<any>
      : Module[K]
    : Module[K];
};
