import { executeQueryForNote } from 'src/common/actions/notebook/action-creators/stardog-api/executeQueryForNote';
import { notebookActionCreators } from 'src/common/actions/notebook/notebookActionCreators';
import { queryLanguageIds } from 'src/common/constants/LanguageId';
import { getStore } from 'src/common/store';
import { isConnectionConnected } from 'src/common/utils/isConnectionConnected';

export const eventMap = {
  nextTab: () => {
    const { getState, dispatch } = getStore();
    const { noteIds, activeNoteId } = getState().notebook;

    let nextIndex = noteIds.indexOf(activeNoteId) + 1;

    if (nextIndex === noteIds.length) {
      nextIndex = 0;
    }

    const noteId = noteIds[nextIndex];

    dispatch(
      notebookActionCreators.rearrangeNotes({
        sourceNoteId: noteId,
        targetNoteId: noteId,
      })
    );
  },
  previousTab: () => {
    const { getState, dispatch } = getStore();
    const { noteIds, activeNoteId } = getState().notebook;

    let nextIndex = noteIds.indexOf(activeNoteId) - 1;

    if (nextIndex === -1) {
      nextIndex = noteIds.length - 1;
    }

    const noteId = noteIds[nextIndex];

    dispatch(
      notebookActionCreators.rearrangeNotes({
        sourceNoteId: noteId,
        targetNoteId: noteId,
      })
    );
  },
  executeQuery: () => {
    const { getState, dispatch } = getStore();
    const { connection, notebook } = getState();

    if (!isConnectionConnected(connection.current)) {
      return;
    }

    const { notes, activeNoteId } = notebook;
    const { languageId } = notes[activeNoteId].editorSettings;

    if (!queryLanguageIds.includes(languageId as any)) {
      return;
    }

    dispatch(executeQueryForNote(activeNoteId));
  },
};

export type HotkeyHandler = keyof typeof eventMap;
