import * as stardog from 'stardog';

import { API } from 'src/common/actions/request/types';
import { LanguageId } from 'src/common/constants/LanguageId';
import { Connection } from 'src/common/store/connection/ConnectionState';
import { getStardogConnection } from 'src/common/utils/connection/getStardogConnection';

export async function getQueryExportContent({
  acceptType,
  connection,
  db,
  languageId,
  query,
  namedGraphs,
  reasoning,
  schema: schemaForReasoning,
  timeout,
  additionalHandlers,
}: {
  acceptType: string;
  connection: Connection;
  db: string;
  languageId: LanguageId;
  query: string;
  namedGraphs?: string[];
  reasoning: boolean;
  schema?: string;
  timeout?: string;
  additionalHandlers?: {
    [key: string]: (...args: any[]) => any;
  };
}) {
  const conn = getStardogConnection(connection);

  const schema = reasoning ? schemaForReasoning : undefined;

  let api: API;
  let args: any[];

  if (languageId === LanguageId.GRAPHQL) {
    api = stardog.query.graphql.execute;
    // intentionally exclude schema as the reasoning schema is not supported by graphql queries
    // https://github.com/stardog-union/stardog/pull/7531#discussion_r304016124
    const options: any = { '@reasoning': reasoning };
    if (namedGraphs && namedGraphs.length) {
      options['graph-uri'] = namedGraphs;
    }
    if (timeout) {
      options.timeout = timeout;
    }
    const params = {};
    args = [db, query, options, params];
  } else {
    api = stardog.query.execute;
    const options: any = { reasoning };
    if (namedGraphs && namedGraphs.length) {
      options['graph-uri'] = namedGraphs;
    }
    if (reasoning && schema) {
      options.schema = schema;
    }
    if (timeout) {
      options.timeout = timeout;
    }
    args = [db, query, acceptType, options];
  }

  if (additionalHandlers) {
    args.push(additionalHandlers);
  }

  const response = await (api as any)(conn, ...args);

  const body =
    typeof response.body === 'string'
      ? response.body
      : JSON.stringify(response.body, null, 2);
  return body;
}
