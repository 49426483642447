const namespacesString = `prefix : <http://api.stardog.com/>
prefix owl: <http://www.w3.org/2002/07/owl#>
prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix stardog: <tag:stardog:api:>
prefix xsd: <http://www.w3.org/2001/XMLSchema#>
prefix geow: <http://www.w3.org/2003/01/geo/wgs84_pos#>
prefix xml: <http://www.w3.org/XML/1998/namespace>
prefix schema: <http://schema.org/>`;

export const defaultNamespaces = namespacesString
  .split('\n')
  .reduce((accumulator, line) => {
    const [prefixSection, remainder] = line.split(':');
    const prefix = prefixSection.slice(7);
    const iri = remainder.trim();

    accumulator[prefix] = iri;
    return accumulator;
  }, {});

export const FAILURE_CONTENT_TYPE = 'failure';

export const defaultSms2VgEditorContent =
  '# See https://docs.stardog.com/virtual-graphs/mapping-data-sources#sms2-stardog-mapping-syntax-2 for syntax details.\n# Hit Cmd/Ctrl+Space (or begin typing "mapping") to get started.\n';
