import { Connection } from 'src/common/store/connection/ConnectionState';

// Is the connection info sufficient for connecting to Stardog? This means we
// have an endpoint, and either both a username and password or neither
// (neither can indicate Kerberos in the browser)
export const isConnectionInfoSufficient = ({
  endpoint,
  password,
  username,
}: Connection) =>
  Boolean(endpoint && ((username && password) || (!username && !password)));
