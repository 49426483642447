import { LanguageId } from 'src/common/constants/LanguageId';

export interface CollapseStateMap {
  [databaseName: string]: boolean;
}

export interface StoredQuery {
  name: string;
  creator: string;
  database: string;
  query: string;
  shared: boolean;
  // Was added in version 6.2.2
  reasoning?: boolean;
  // GraphQL stored queries were added in 6.2.3
  languageId?: LanguageId;
}

export interface StoredQueryMap {
  [databaseName: string]: StoredQuery[];
}

export class StoredQueryState {
  public isCached = false;

  public queries: StoredQuery[] = [];

  // public collapseState: CollapseStateMap = {};
}
