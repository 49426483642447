import * as Monaco from 'monaco-editor/esm/vs/editor/editor.api';
import { TextDocumentItem } from 'monaco-languageclient';
import { DidOpenTextDocumentNotification } from 'vscode-languageserver';

import { LanguageId } from 'src/common/constants/LanguageId';
import { getActivatedLanguageClient } from 'src/common/utils/editor/language-services/createBaseLanguageClient';

export const registerOnDidChangeLanguageListener = () => {
  // `monaco-languageclient` automates most necessary communication between
  // monaco editors and language servers, but (for some reason) *not* this one.
  // Because we allow changing the language of an open editor without changing
  // its URI, we need to manually notify the server that an editor for the new
  // language has "opened.""
  Monaco.editor.onDidChangeModelLanguage(({ model }) => {
    const modelUri = model.uri.toString();
    const modelVersion = model.getVersionId();
    const document = model.getValue();
    const languageId = model.getLanguageId();
    const client = getActivatedLanguageClient(languageId as LanguageId);

    if (!client) {
      // language has no client, so just clear diagnostics
      Monaco.editor.setModelMarkers(model, 'default', []);
      return;
    }

    if (client.needsStart()) {
      // safety check; this should never happen, but apparently something has
      // shut down the client, so we'll try to restart it.
      client.start();
    }

    // `onReady()` ensures that the client has started by the time we try to send
    // a notification.
    client.onReady().then(() => {
      client.sendNotification(DidOpenTextDocumentNotification.type, {
        textDocument: TextDocumentItem.create(
          modelUri,
          languageId,
          modelVersion,
          document
        ),
      });
    });
  });
};
