import { HTTP, server, db as stardogDb, query as stardogQuery } from 'stardog';

import { requestReadFile } from 'src/browser/actions/fileSystem/requestReadFile';
import { MosaicStateChange } from 'src/common/actions/databases/MosaicStateChange';
import { request } from 'src/common/actions/request';
import {
  FailureResponse,
  RequestAction,
  Response,
} from 'src/common/actions/request/types';
import {
  ActionCreatorAction,
  createTypedAction,
} from 'src/common/actions/types';
import { EDITOR_QUERY_LIMIT } from 'src/common/constants/editorLimits';
import {
  ALIASES_GRAPH,
  ALIAS_PREDICATE,
  getAllLocalNamedGraph,
} from 'src/common/constants/namedGraphs';
import { DbProperties } from 'src/common/store/databases/DatabasesState';
import { namedGraphsQuery } from 'src/common/utils/queryBuilders/namedGraphs';

export const enum DatabasesActionType {
  REQUEST_DATABASES_ATTEMPT = 'REQUEST_DATABASES_ATTEMPT',
  REQUEST_DATABASES_SUCCESS = 'REQUEST_DATABASES_SUCCESS',
  REQUEST_DATABASES_FAILURE = 'REQUEST_DATABASES_FAILURE',

  REQUEST_AVAILABLE_DATABASE_OPTIONS_ATTEMPT = 'REQUEST_AVAILABLE_DATABASE_OPTIONS_ATTEMPT',
  REQUEST_AVAILABLE_DATABASE_OPTIONS_SUCCESS = 'REQUEST_AVAILABLE_DATABASE_OPTIONS_SUCCESS',
  REQUEST_AVAILABLE_DATABASE_OPTIONS_FAILURE = 'REQUEST_AVAILABLE_DATABASE_OPTIONS_FAILURE',
  // For Stardog versions that don't support the API method used by
  // `REQUEST_AVAILABLE_DATABASE_OPTIONS*`
  SET_STATIC_AVAILABLE_OPTIONS = 'SET_STATIC_AVAILABLE_OPTIONS',

  REQUEST_NAMESPACES_ATTEMPT = 'REQUEST_NAMESPACES_ATTEMPT',
  REQUEST_NAMESPACES_SUCCESS = 'REQUEST_NAMESPACES_SUCCESS',
  REQUEST_NAMESPACES_FAILURE = 'REQUEST_NAMESPACES_FAILURE',

  REQUEST_NAMED_GRAPHS_ATTEMPT = 'REQUEST_NAMED_GRAPHS_ATTEMPT',
  REQUEST_NAMED_GRAPHS_SUCCESS = 'REQUEST_NAMED_GRAPHS_SUCCESS',
  REQUEST_NAMED_GRAPHS_FAILURE = 'REQUEST_NAMED_GRAPHS_FAILURE',

  REQUEST_NAMED_GRAPH_ALIASES_ATTEMPT = 'REQUEST_NAMED_GRAPH_ALIASES_ATTEMPT',
  REQUEST_NAMED_GRAPH_ALIASES_SUCCESS = 'REQUEST_NAMED_GRAPH_ALIASES_SUCCESS',
  REQUEST_NAMED_GRAPH_ALIASES_FAILURE = 'REQUEST_NAMED_GRAPH_ALIASES_FAILURE',

  CLEAR_NAMED_GRAPHS = 'CLEAR_NAMED_GRAPHS',

  REQUEST_DETAILS_FOR_DATABASES_ATTEMPT = 'REQUEST_DETAILS_FOR_DATABASES_ATTEMPT',
  REQUEST_DETAILS_FOR_DATABASES_SUCCESS = 'REQUEST_DETAILS_FOR_DATABASES_SUCCESS',
  REQUEST_DETAILS_FOR_DATABASES_FAILURE = 'REQUEST_DETAILS_FOR_DATABASES_FAILURE',

  REQUEST_DETAILS_FOR_DATABASE_ATTEMPT = 'REQUEST_DETAILS_FOR_DATABASE_ATTEMPT',
  REQUEST_DETAILS_FOR_DATABASE_SUCCESS = 'REQUEST_DETAILS_FOR_DATABASE_SUCCESS',
  REQUEST_DETAILS_FOR_DATABASE_FAILURE = 'REQUEST_DETAILS_FOR_DATABASE_FAILURE',

  REQUEST_GRAPHQL_SCHEMAS_FOR_DATABASE_ATTEMPT = 'REQUEST_GRAPHQL_SCHEMAS_FOR_DATABASE_ATTEMPT',
  REQUEST_GRAPHQL_SCHEMAS_FOR_DATABASE_SUCCESS = 'REQUEST_GRAPHQL_SCHEMAS_FOR_DATABASE_SUCCESS',
  REQUEST_GRAPHQL_SCHEMAS_FOR_DATABASE_FAILURE = 'REQUEST_GRAPHQL_SCHEMAS_FOR_DATABASE_FAILURE',

  REQUEST_GRAPHQL_SCHEMA_FOR_DATABASE_ATTEMPT = 'REQUEST_GRAPHQL_SCHEMA_FOR_DATABASE_ATTEMPT',
  REQUEST_GRAPHQL_SCHEMA_FOR_DATABASE_SUCCESS = 'REQUEST_GRAPHQL_SCHEMA_FOR_DATABASE_SUCCESS',
  REQUEST_GRAPHQL_SCHEMA_FOR_DATABASE_FAILURE = 'REQUEST_GRAPHQL_SCHEMA_FOR_DATABASE_FAILURE',

  ADD_GRAPHQL_SCHEMA_FOR_DATABASE_ATTEMPT = 'ADD_GRAPHQL_SCHEMA_FOR_DATABASE_ATTEMPT',
  ADD_GRAPHQL_SCHEMA_FOR_DATABASE_SUCCESS = 'ADD_GRAPHQL_SCHEMA_FOR_DATABASE_SUCCESS',
  ADD_GRAPHQL_SCHEMA_FOR_DATABASE_FAILURE = 'ADD_GRAPHQL_SCHEMA_FOR_DATABASE_FAILURE',

  UPDATE_GRAPHQL_SCHEMA_FOR_DATABASE_ATTEMPT = 'UPDATE_GRAPHQL_SCHEMA_FOR_DATABASE_ATTEMPT',
  UPDATE_GRAPHQL_SCHEMA_FOR_DATABASE_SUCCESS = 'UPDATE_GRAPHQL_SCHEMA_FOR_DATABASE_SUCCESS',
  UPDATE_GRAPHQL_SCHEMA_FOR_DATABASE_FAILURE = 'UPDATE_GRAPHQL_SCHEMA_FOR_DATABASE_FAILURE',

  REMOVE_GRAPHQL_SCHEMA_FOR_DATABASE_ATTEMPT = 'REMOVE_GRAPHQL_SCHEMA_FOR_DATABASE_ATTEMPT',
  REMOVE_GRAPHQL_SCHEMA_FOR_DATABASE_SUCCESS = 'REMOVE_GRAPHQL_SCHEMA_FOR_DATABASE_SUCCESS',
  REMOVE_GRAPHQL_SCHEMA_FOR_DATABASE_FAILURE = 'REMOVE_GRAPHQL_SCHEMA_FOR_DATABASE_FAILURE',

  SHOW_DISABLED_SYNTAX_NOTIFICATION_FOR_GRAPHQL_SCHEMA = 'SHOW_DISABLED_SYNTAX_NOTIFICATION_FOR_GRAPHQL_SCHEMA',

  SET_UNSAVED_DETAIL_FOR_DATABASE = 'SET_UNSAVED_DETAIL_FOR_DATABASE',

  TOGGLE_DATABASE_ONLINE_ATTEMPT = 'TOGGLE_DATABASE_ONLINE_ATTEMPT',
  TOGGLE_DATABASE_ONLINE_SUCCESS = 'TOGGLE_DATABASE_ONLINE_SUCCESS',
  TOGGLE_DATABASE_ONLINE_FAILURE = 'TOGGLE_DATABASE_ONLINE_FAILURE',

  OPTIMIZE_DATABASE_ATTEMPT = 'OPTIMIZE_DATABASE_ATTEMPT',
  OPTIMIZE_DATABASE_SUCCESS = 'OPTIMIZE_DATABASE_SUCCESS',
  OPTIMIZE_DATABASE_FAILURE = 'OPTIMIZE_DATABASE_FAILURE',

  DROP_DATABASE_ATTEMPT = 'DROP_DATABASE_ATTEMPT',
  DROP_DATABASE_SUCCESS = 'DROP_DATABASE_SUCCESS',
  DROP_DATABASE_FAILURE = 'DROP_DATABASE_FAILURE',

  UPDATE_DATABASE_DETAILS_ATTEMPT = 'UPDATE_DATABASE_DETAILS_ATTEMPT',
  UPDATE_DATABASE_DETAILS_SUCCESS = 'UPDATE_DATABASE_DETAILS_SUCCESS',
  UPDATE_DATABASE_DETAILS_FAILURE = 'UPDATE_DATABASE_DETAILS_FAILURE',

  CREATE_DATABASE_ATTEMPT = 'CREATE_DATABASE_ATTEMPT',
  CREATE_DATABASE_SUCCESS = 'CREATE_DATABASE_SUCCESS',
  CREATE_DATABASE_FAILURE = 'CREATE_DATABASE_FAILURE',

  ADD_DATA_ATTEMPT = 'ADD_DATA_ATTEMPT',
  ADD_DATA_SUCCESS = 'ADD_DATA_SUCCESS',
  ADD_DATA_FAILURE = 'ADD_DATA_FAILURE',

  REMOVE_DATA_ATTEMPT = 'REMOVE_DATA_ATTEMPT',
  REMOVE_DATA_SUCCESS = 'REMOVE_DATA_SUCCESS',
  REMOVE_DATA_FAILURE = 'REMOVE_DATA_FAILURE',

  EXPORT_DATABASE_ATTEMPT = 'EXPORT_DATABASE_ATTEMPT',
  EXPORT_DATABASE_SUCCESS = 'EXPORT_DATABASE_SUCCESS',
  EXPORT_DATABASE_FAILURE = 'EXPORT_DATABASE_FAILURE',

  SET_SELECTED_DATABASE_ID = 'SET_SELECTED_DATABASE_ID',
  SET_DATABASE_MANAGER_IS_DIRTY = 'SET_DATABASE_MANAGER_IS_DIRTY',

  BEGIN_TX_ATTEMPT = 'BEGIN_TX_ATTEMPT',
  BEGIN_TX_SUCCESS = 'BEGIN_TX_SUCCESS',
  BEGIN_TX_FAILURE = 'BEGIN_TX_FAILURE',

  COMMIT_TX_ATTEMPT = 'COMMIT_TX_ATTEMPT',
  COMMIT_TX_SUCCESS = 'COMMIT_TX_SUCCESS',
  COMMIT_TX_FAILURE = 'COMMIT_TX_FAILURE',

  ROLLBACK_TX_ATTEMPT = 'ROLLBACK_TX_ATTEMPT',
  ROLLBACK_TX_SUCCESS = 'ROLLBACK_TX_SUCCESS',
  ROLLBACK_TX_FAILURE = 'ROLLBACK_TX_FAILURE',

  GET_STATUS_ATTEMPT = 'GET_STATUS_ATTEMPT',
  GET_STATUS_SUCCESS = 'GET_STATUS_SUCCESS',
  GET_STATUS_FAILURE = 'GET_STATUS_FAILURE',

  GET_SIZE_ATTEMPT = 'GET_SIZE_ATTEMPT',
  GET_SIZE_SUCCESS = 'GET_SIZE_SUCCESS',
  GET_SIZE_FAILURE = 'GET_SIZE_FAILURE',

  REQUEST_RELATIONSHIPS_ATTEMPT = 'GET_RELATIONSHIPS_ATTEMPT',
  REQUEST_RELATIONSHIPS_SUCCESS = 'GET_RELATIONSHIPS_SUCCESS',
  REQUEST_RELATIONSHIPS_FAILURE = 'GET_RELATIONSHIPS_FAILURE',

  REQUEST_TYPES_ATTEMPT = 'GET_TYPES_ATTEMPT',
  REQUEST_TYPES_SUCCESS = 'GET_TYPES_SUCCESS',
  REQUEST_TYPES_FAILURE = 'GET_TYPES_FAILURE',

  CLEAR_RELATIONSHIPS_AND_TYPES = 'CLEAR_RELATIONSHIPS_AND_TYPES',

  ADD_ICV_CONSTRAINTS_ATTEMPT = 'ADD_ICV_CONSTRAINTS_ATTEMPT',
  ADD_ICV_CONSTRAINTS_SUCCESS = 'ADD_ICV_CONSTRAINTS_SUCCESS',
  ADD_ICV_CONSTRAINTS_FAILURE = 'ADD_ICV_CONSTRAINTS_FAILURE',

  REMOVE_ICV_CONSTRAINTS_ATTEMPT = 'REMOVE_ICV_CONSTRAINTS_ATTEMPT',
  REMOVE_ICV_CONSTRAINTS_SUCCESS = 'REMOVE_ICV_CONSTRAINTS_SUCCESS',
  REMOVE_ICV_CONSTRAINTS_FAILURE = 'REMOVE_ICV_CONSTRAINTS_FAILURE',

  VALIDATE_CONSTRAINTS_ATTEMPT = 'VALIDATE_CONSTRAINTS_ATTEMPT',
  VALIDATE_CONSTRAINTS_SUCCESS = 'VALIDATE_CONSTRAINTS_SUCCESS',
  VALIDATE_CONSTRAINTS_FAILURE = 'VALIDATE_CONSTRAINTS_FAILURE',

  EXPORT_NAMESPACES_ATTEMPT = 'EXPORT_NAMESPACES_ATTEMPT',
  EXPORT_NAMESPACES_SUCCESS = 'EXPORT_NAMESPACES_SUCCESS',
  EXPORT_NAMESPACES_FAILURE = 'EXPORT_NAMESPACES_FAILURE',

  IMPORT_NAMESPACES_ATTEMPT = 'IMPORT_NAMESPACES_ATTEMPT',
  IMPORT_NAMESPACES_SUCCESS = 'IMPORT_NAMESPACES_SUCCESS',
  IMPORT_NAMESPACES_FAILURE = 'IMPORT_NAMESPACES_FAILURE',

  REQUEST_BI_SCHEMA_MAPPING_ATTEMPT = 'REQUEST_BI_SCHEMA_MAPPING_ATTEMPT',
  REQUEST_BI_SCHEMA_MAPPING_SUCCESS = 'REQUEST_BI_SCHEMA_MAPPING_SUCCESS',
  REQUEST_BI_SCHEMA_MAPPING_FAILURE = 'REQUEST_BI_SCHEMA_MAPPING_FAILURE',

  REQUEST_GENERATED_BI_SCHEMA_MAPPING_ATTEMPT = 'REQUEST_GENERATED_BI_SCHEMA_MAPPING_ATTEMPT',
  REQUEST_GENERATED_BI_SCHEMA_MAPPING_SUCCESS = 'REQUEST_GENERATED_BI_SCHEMA_MAPPING_SUCCESS',
  REQUEST_GENERATED_BI_SCHEMA_MAPPING_FAILURE = 'REQUEST_GENERATED_BI_SCHEMA_MAPPING_FAILURE',

  SHOW_DISABLED_SYNTAX_NOTIFICATION_FOR_BI_SCHEMA = 'SHOW_DISABLED_SYNTAX_NOTIFICATION_FOR_BI_SCHEMA',

  UPDATE_BI_SCHEMA_MAPPING_ATTEMPT = 'UPDATE_BI_SCHEMA_MAPPING_ATTEMPT',
  UPDATE_BI_SCHEMA_MAPPING_SUCCESS = 'UPDATE_BI_SCHEMA_MAPPING_SUCCESS',
  UPDATE_BI_SCHEMA_MAPPING_FAILURE = 'UPDATE_BI_SCHEMA_MAPPING_FAILURE',

  UPDATE_DATABASES_MOSAIC_STATE = 'UPDATE_DATABASES_MOSAIC_STATE',
}

export const databasesActionCreators = {
  addDataAttempt: ({ databaseId }: { databaseId: string }) =>
    createTypedAction(DatabasesActionType.ADD_DATA_ATTEMPT, { databaseId }),
  addDataSuccess: ({ databaseId }: { databaseId: string }) =>
    createTypedAction(DatabasesActionType.ADD_DATA_SUCCESS, {
      databaseId,
    }),
  addDataFailure: ({
    databaseId,
    errorMessage,
  }: {
    databaseId: string;
    errorMessage: string;
  }) =>
    createTypedAction(DatabasesActionType.ADD_DATA_FAILURE, {
      databaseId,
      response: {
        errorMessage,
      },
    }),
  removeDataAttempt: ({ databaseId }: { databaseId: string }) =>
    createTypedAction(DatabasesActionType.REMOVE_DATA_ATTEMPT, { databaseId }),
  removeDataSuccess: ({ databaseId }: { databaseId: string }) =>
    createTypedAction(DatabasesActionType.REMOVE_DATA_SUCCESS, {
      databaseId,
    }),
  removeDataFailure: ({
    databaseId,
    errorMessage,
  }: {
    databaseId: string;
    errorMessage: string;
  }) =>
    createTypedAction(DatabasesActionType.REMOVE_DATA_FAILURE, {
      databaseId,
      response: {
        errorMessage,
      },
    }),
  exportDatabaseAttempt: ({
    databaseId,
    filePath,
    format,
    compression,
    namedGraph,
  }: {
    databaseId: string;
    filePath?: string;
    format: string;
    compression: string;
    namedGraph: string;
  }) =>
    createTypedAction(DatabasesActionType.EXPORT_DATABASE_ATTEMPT, {
      databaseId,
      filePath,
      format,
      compression,
      namedGraph,
    }),
  exportDatabaseSuccess: ({
    databaseId,
    filePath,
    responseMessage,
  }: {
    databaseId: string;
    filePath?: string;
    responseMessage?: string;
  }) =>
    createTypedAction(DatabasesActionType.EXPORT_DATABASE_SUCCESS, {
      databaseId,
      filePath,
      responseMessage,
    }),
  exportDatabaseFailure: ({
    databaseId,
    errorMessage,
  }: {
    databaseId: string;
    errorMessage?: string;
  }) =>
    createTypedAction(DatabasesActionType.EXPORT_DATABASE_FAILURE, {
      databaseId,
      response: {
        errorMessage,
      },
    }),
  clearNamedGraphs: ({ databaseId }: { databaseId: string }) =>
    createTypedAction(DatabasesActionType.CLEAR_NAMED_GRAPHS, {
      databaseId,
    }),
  clearRelationshipsAndTypes: ({ databaseId }: { databaseId: string }) =>
    createTypedAction(DatabasesActionType.CLEAR_RELATIONSHIPS_AND_TYPES, {
      databaseId,
    }),
  setSelectedDatabaseId: ({ databaseId }: { databaseId: string }) =>
    createTypedAction(DatabasesActionType.SET_SELECTED_DATABASE_ID, {
      databaseId,
    }),
  setDatabaseManagerIsDirty: ({
    areGraphQlSchemasDirty,
    areNamespacesDirty,
    arePropertiesDirty,
    isBiSchemaMappingDirty,
  }: {
    areGraphQlSchemasDirty?: boolean;
    areNamespacesDirty?: boolean;
    arePropertiesDirty?: boolean;
    isBiSchemaMappingDirty?: boolean;
  }) =>
    createTypedAction(DatabasesActionType.SET_DATABASE_MANAGER_IS_DIRTY, {
      areGraphQlSchemasDirty,
      areNamespacesDirty,
      arePropertiesDirty,
      isBiSchemaMappingDirty,
    }),
  showDisabledSyntaxNotificationForBiSchema: (
    databaseId: string,
    buttonAction: () => void,
    closeNotification: (id: string) => void
  ) =>
    createTypedAction(
      DatabasesActionType.SHOW_DISABLED_SYNTAX_NOTIFICATION_FOR_BI_SCHEMA,
      {
        databaseId,
        buttonAction,
        closeNotification,
      }
    ),
  showDisabledSyntaxNotificationForGraphQlSchema: (
    schemaName: string,
    buttonAction: () => void,
    closeNotification: (id: string) => void
  ) =>
    createTypedAction(
      DatabasesActionType.SHOW_DISABLED_SYNTAX_NOTIFICATION_FOR_GRAPHQL_SCHEMA,
      {
        schemaName,
        buttonAction,
        closeNotification,
      }
    ),
  updateDatabasesMosaicState: (mosaicStateChange: MosaicStateChange) =>
    createTypedAction(DatabasesActionType.UPDATE_DATABASES_MOSAIC_STATE, {
      mosaicStateChange,
    }),
  requestDetailsForDatabasesSuccess: (
    responses: Array<
      Response<
        { stardogSupportsVirtualTransparency: boolean },
        DatabasesActionType.REQUEST_DETAILS_FOR_DATABASE_SUCCESS,
        any
      >
    >
  ) =>
    createTypedAction(
      DatabasesActionType.REQUEST_DETAILS_FOR_DATABASES_SUCCESS,
      {
        responses,
      }
    ),
  requestDetailsForDatabasesFailure: () =>
    createTypedAction(
      DatabasesActionType.REQUEST_DETAILS_FOR_DATABASES_FAILURE
    ),
  setStaticAvailableOptions: () =>
    createTypedAction(DatabasesActionType.SET_STATIC_AVAILABLE_OPTIONS),
  exportNamespacesAttempt: (databaseId: string) =>
    createTypedAction(DatabasesActionType.EXPORT_NAMESPACES_ATTEMPT, {
      databaseId,
    }),
  exportNamespacesSuccess: (databaseId: string, filePath: string) =>
    createTypedAction(DatabasesActionType.EXPORT_NAMESPACES_SUCCESS, {
      databaseId,
      filePath,
    }),
  exportNamespacesFailure: (databaseId: string) =>
    createTypedAction(DatabasesActionType.EXPORT_NAMESPACES_FAILURE, {
      databaseId,
    }),
  updateGraphQlSchemaForDatabaseAttempt: (
    databaseId: string,
    graphQlSchemaId: string
  ) =>
    createTypedAction(
      DatabasesActionType.UPDATE_GRAPHQL_SCHEMA_FOR_DATABASE_ATTEMPT,
      {
        databaseId,
        graphQlSchemaId,
      }
    ),
  updateGraphQlSchemaForDatabaseFailure: (
    databaseId: string,
    graphQlSchemaId: string,
    failureResponse: FailureResponse<
      any,
      DatabasesActionType.ADD_GRAPHQL_SCHEMA_FOR_DATABASE_FAILURE
    >
  ) =>
    createTypedAction(
      DatabasesActionType.UPDATE_GRAPHQL_SCHEMA_FOR_DATABASE_FAILURE,
      {
        databaseId,
        graphQlSchemaId,
        failureResponse,
      }
    ),
  updateGraphQlSchemaForDatabaseSuccess: (
    databaseId: string,
    graphQlSchemaId: string,
    updatedSchema: string
  ) =>
    createTypedAction(
      DatabasesActionType.UPDATE_GRAPHQL_SCHEMA_FOR_DATABASE_SUCCESS,
      {
        databaseId,
        graphQlSchemaId,
        graphQlSchema: updatedSchema,
      }
    ),
  requestBiSchemaMappingForDatabaseAttempt: (databaseId: string) =>
    createTypedAction(DatabasesActionType.REQUEST_BI_SCHEMA_MAPPING_ATTEMPT, {
      databaseId,
    }),
  requestBiSchemaMappingForDatabaseFailure: (
    databaseId: string,
    failureResponse: FailureResponse<
      any,
      DatabasesActionType.REQUEST_BI_SCHEMA_MAPPING_FAILURE
    >
  ) =>
    createTypedAction(DatabasesActionType.REQUEST_BI_SCHEMA_MAPPING_FAILURE, {
      databaseId,
      failureResponse,
    }),
  requestBiSchemaMappingForDatabaseSuccess: (
    databaseId: string,
    biSchemaMapping: string
  ) =>
    createTypedAction(DatabasesActionType.REQUEST_BI_SCHEMA_MAPPING_SUCCESS, {
      biSchemaMapping,
      databaseId,
    }),
  updateBiSchemaMappingForDatabaseAttempt: (databaseId: string) =>
    createTypedAction(DatabasesActionType.UPDATE_BI_SCHEMA_MAPPING_ATTEMPT, {
      databaseId,
    }),
  updateBiSchemaMappingForDatabaseFailure: (
    databaseId: string,
    exception: Error
  ) =>
    createTypedAction(DatabasesActionType.UPDATE_BI_SCHEMA_MAPPING_FAILURE, {
      databaseId,
      exception,
    }),
  updateBiSchemaMappingForDatabaseSuccess: (
    databaseId: string,
    updatedSchemaMapping: string
  ) =>
    createTypedAction(DatabasesActionType.UPDATE_BI_SCHEMA_MAPPING_SUCCESS, {
      databaseId,
      updatedSchemaMapping,
    }),
};

export const databasesStardogRequestDispatchers = {
  createDatabase: async ({
    databaseId,
    initialDbDetails,
    options,
  }: {
    databaseId: string;
    initialDbDetails: DbProperties;
    options?: { propertiesFile?: File };
  }) =>
    request({
      api: stardogDb.create,
      args: [databaseId, initialDbDetails, options],
      success: DatabasesActionType.CREATE_DATABASE_SUCCESS,
      loading: DatabasesActionType.CREATE_DATABASE_ATTEMPT,
      failure: DatabasesActionType.CREATE_DATABASE_FAILURE,
      action: {},
    }),
  dropDatabase: async ({
    databaseId,
    action,
  }: {
    databaseId: string;
    action?: RequestAction;
  }) =>
    request({
      api: stardogDb.drop,
      args: [databaseId],
      loading: DatabasesActionType.DROP_DATABASE_ATTEMPT,
      success: DatabasesActionType.DROP_DATABASE_SUCCESS,
      failure: DatabasesActionType.DROP_DATABASE_FAILURE,
      action: action || {},
    }),
  getSize: async ({ db }: { db: string }) =>
    request({
      api: stardogDb.size,
      args: [db],
      loading: DatabasesActionType.GET_SIZE_ATTEMPT,
      success: DatabasesActionType.GET_SIZE_SUCCESS,
      failure: DatabasesActionType.GET_SIZE_FAILURE,
      action: { db },
    }),
  getStatus: async () =>
    request({
      loading: DatabasesActionType.GET_STATUS_ATTEMPT,
      success: DatabasesActionType.GET_STATUS_SUCCESS,
      failure: DatabasesActionType.GET_STATUS_FAILURE,
      api: server.status,
      args: [],
      action: {},
    }),
  importNamespaces: async (databaseId: string, file: File) => {
    const data = await requestReadFile({ file });
    return request({
      api: stardogDb.namespaces.add,
      args: [databaseId, data],
      loading: DatabasesActionType.IMPORT_NAMESPACES_ATTEMPT,
      success: DatabasesActionType.IMPORT_NAMESPACES_SUCCESS,
      failure: DatabasesActionType.IMPORT_NAMESPACES_FAILURE,
      action: {},
    });
  },
  optimizeDatabase: async ({ databaseId }: { databaseId: string }) =>
    request({
      api: stardogDb.optimize,
      args: [databaseId],
      loading: DatabasesActionType.OPTIMIZE_DATABASE_ATTEMPT,
      success: DatabasesActionType.OPTIMIZE_DATABASE_SUCCESS,
      failure: DatabasesActionType.OPTIMIZE_DATABASE_FAILURE,
      action: {},
    }),
  requestAvailableOptions: async (overrideGraphQlDefaultLimit = false) =>
    request({
      api: stardogDb.options.getAvailable,
      args: [],
      loading: DatabasesActionType.REQUEST_AVAILABLE_DATABASE_OPTIONS_ATTEMPT,
      success: DatabasesActionType.REQUEST_AVAILABLE_DATABASE_OPTIONS_SUCCESS,
      failure: DatabasesActionType.REQUEST_AVAILABLE_DATABASE_OPTIONS_FAILURE,
      action: overrideGraphQlDefaultLimit
        ? {
            flatOptionOverrides: {
              'graphql.default.limit.defaultValue': -1,
            },
          }
        : {},
    }),
  requestDatabases: async (action?: RequestAction) =>
    request({
      api: stardogDb.list,
      args: [],
      loading: DatabasesActionType.REQUEST_DATABASES_ATTEMPT,
      success: DatabasesActionType.REQUEST_DATABASES_SUCCESS,
      failure: DatabasesActionType.REQUEST_DATABASES_FAILURE,
      action: action || {},
    }),
  requestDetailsForDatabase: async ({
    dbId,
    overrideGraphQlDefaultLimit = false,
    stardogSupportsVirtualTransparency,
    shouldNotDispatch,
    useGetAllMethod = true,
  }: {
    dbId: string;
    overrideGraphQlDefaultLimit: boolean;
    stardogSupportsVirtualTransparency: boolean;
    shouldNotDispatch?: boolean;
    useGetAllMethod: boolean;
  }) => {
    const action: any = { stardogSupportsVirtualTransparency };
    if (overrideGraphQlDefaultLimit) {
      action.flatOptionOverrides = {
        'graphql.default.limit': -1,
      };
    }
    return request({
      api: useGetAllMethod ? stardogDb.options.getAll : stardogDb.options.get,
      args: [dbId],
      loading: DatabasesActionType.REQUEST_DETAILS_FOR_DATABASE_ATTEMPT,
      success: DatabasesActionType.REQUEST_DETAILS_FOR_DATABASE_SUCCESS,
      failure: DatabasesActionType.REQUEST_DETAILS_FOR_DATABASE_FAILURE,
      action,
      shouldNotDispatch,
    });
  },
  requestGraphQlSchemaIdsForDatabase: async ({
    databaseId,
  }: {
    databaseId: string;
  }) =>
    request({
      api: stardogQuery.graphql.listSchemas,
      args: [databaseId],
      loading: DatabasesActionType.REQUEST_GRAPHQL_SCHEMAS_FOR_DATABASE_ATTEMPT,
      success: DatabasesActionType.REQUEST_GRAPHQL_SCHEMAS_FOR_DATABASE_SUCCESS,
      failure: DatabasesActionType.REQUEST_GRAPHQL_SCHEMAS_FOR_DATABASE_FAILURE,
      action: {
        databaseId,
      },
    }),
  requestNamespaces: async ({ db }: { db: string }) =>
    request({
      api: stardogDb.namespaces.get,
      args: [db],
      loading: DatabasesActionType.REQUEST_NAMESPACES_ATTEMPT,
      success: DatabasesActionType.REQUEST_NAMESPACES_SUCCESS,
      failure: DatabasesActionType.REQUEST_NAMESPACES_FAILURE,
      action: {},
    }),
  requestNamedGraphs: async ({ db }: { db: string }) => {
    return request({
      api: stardogQuery.execute,
      args: [db, namedGraphsQuery],
      loading: DatabasesActionType.REQUEST_NAMED_GRAPHS_ATTEMPT,
      success: DatabasesActionType.REQUEST_NAMED_GRAPHS_SUCCESS,
      failure: DatabasesActionType.REQUEST_NAMED_GRAPHS_FAILURE,
      action: { db },
    });
  },
  requestNamedGraphAliases: async ({ db }: { db: string }) => {
    const query = `select distinct ?graph from <${ALIASES_GRAPH}> { ?graph <${ALIAS_PREDICATE}> ?original } limit 1000`;
    return request({
      api: stardogQuery.execute,
      args: [db, query],
      loading: DatabasesActionType.REQUEST_NAMED_GRAPH_ALIASES_ATTEMPT,
      success: DatabasesActionType.REQUEST_NAMED_GRAPH_ALIASES_SUCCESS,
      failure: DatabasesActionType.REQUEST_NAMED_GRAPH_ALIASES_FAILURE,
      action: { db },
    });
  },
  requestRelationships: async ({
    db,
    stardogSupportsVirtualTransparency,
    stardogSupportsFrequencyPredicates,
  }: {
    db: string;
    stardogSupportsVirtualTransparency: boolean;
    stardogSupportsFrequencyPredicates: boolean;
  }) => {
    const graph = getAllLocalNamedGraph(stardogSupportsVirtualTransparency);
    const query = stardogSupportsFrequencyPredicates
      ? `select ?relationship ?count from <${graph}> { ?relationship <tag:stardog:frequency:predicate> ?count } limit 10000`
      : `select ?relationship (count(*) as ?count) from <${graph}> { ?node ?relationship ?node2 } group by ?relationship limit 10000`;

    return request({
      api: stardogQuery.execute,
      args: [db, query],
      loading: DatabasesActionType.REQUEST_RELATIONSHIPS_ATTEMPT,
      success: DatabasesActionType.REQUEST_RELATIONSHIPS_SUCCESS,
      failure: DatabasesActionType.REQUEST_RELATIONSHIPS_FAILURE,
      action: { db },
    });
  },
  requestTypes: async ({
    db,
    stardogSupportsVirtualTransparency,
    stardogSupportsFrequencyPredicates,
  }: {
    db: string;
    stardogSupportsVirtualTransparency: boolean;
    stardogSupportsFrequencyPredicates: boolean;
  }) => {
    const graph = getAllLocalNamedGraph(stardogSupportsVirtualTransparency);
    const query = stardogSupportsFrequencyPredicates
      ? `select ?type ?count from <${graph}> { ?type <tag:stardog:frequency:class> ?count } limit 10000`
      : `select ?type (count(*) as ?count) from <${graph}> { ?node a ?type } group by ?type limit 10000`;

    return request({
      api: stardogQuery.execute,
      args: [db, query],
      loading: DatabasesActionType.REQUEST_TYPES_ATTEMPT,
      success: DatabasesActionType.REQUEST_TYPES_SUCCESS,
      failure: DatabasesActionType.REQUEST_TYPES_FAILURE,
      action: { db },
    });
  },
  addGraphQlSchemaForDatabase: async ({
    databaseId,
    graphQlSchemaId,
    graphQlSchema,
    shouldNotDispatch = false,
  }: {
    databaseId: string;
    graphQlSchemaId: string;
    graphQlSchema: string;
    shouldNotDispatch?: boolean;
  }) =>
    request({
      api: stardogQuery.graphql.updateSchema,
      args: [databaseId, graphQlSchemaId, graphQlSchema],
      loading: DatabasesActionType.ADD_GRAPHQL_SCHEMA_FOR_DATABASE_ATTEMPT,
      success: DatabasesActionType.ADD_GRAPHQL_SCHEMA_FOR_DATABASE_SUCCESS,
      failure: DatabasesActionType.ADD_GRAPHQL_SCHEMA_FOR_DATABASE_FAILURE,
      action: {
        databaseId,
        graphQlSchemaId,
        graphQlSchema,
      },
      shouldNotDispatch,
    }),
  requestGraphQlSchemaForDatabase: async ({
    databaseId,
    graphQlSchemaId,
  }: {
    databaseId: string;
    graphQlSchemaId: string;
  }) =>
    request({
      api: stardogQuery.graphql.getSchema,
      args: [databaseId, graphQlSchemaId],
      loading: DatabasesActionType.REQUEST_GRAPHQL_SCHEMA_FOR_DATABASE_ATTEMPT,
      success: DatabasesActionType.REQUEST_GRAPHQL_SCHEMA_FOR_DATABASE_SUCCESS,
      failure: DatabasesActionType.REQUEST_GRAPHQL_SCHEMA_FOR_DATABASE_FAILURE,
      action: {
        databaseId,
        graphQlSchemaId,
      },
    }),
  removeGraphQlSchemaForDatabase: async ({
    databaseId,
    graphQlSchemaId,
    shouldNotDispatch = false,
  }: {
    databaseId: string;
    graphQlSchemaId: string;
    shouldNotDispatch?: boolean;
  }) =>
    request({
      api: stardogQuery.graphql.removeSchema,
      args: [databaseId, graphQlSchemaId],
      loading: DatabasesActionType.REMOVE_GRAPHQL_SCHEMA_FOR_DATABASE_ATTEMPT,
      success: DatabasesActionType.REMOVE_GRAPHQL_SCHEMA_FOR_DATABASE_SUCCESS,
      failure: DatabasesActionType.REMOVE_GRAPHQL_SCHEMA_FOR_DATABASE_FAILURE,
      action: {
        databaseId,
        graphQlSchemaId,
      },
      shouldNotDispatch,
    }),
  toggleDatabaseOnline: async ({
    id,
    isComingOnline,
  }: {
    id: string;
    isComingOnline: boolean;
  }) =>
    request({
      api: stardogDb[isComingOnline ? 'online' : 'offline'],
      args: [id],
      loading: DatabasesActionType.TOGGLE_DATABASE_ONLINE_ATTEMPT,
      success: DatabasesActionType.TOGGLE_DATABASE_ONLINE_SUCCESS,
      failure: DatabasesActionType.TOGGLE_DATABASE_ONLINE_FAILURE,
      action: {
        isComingOnline,
      },
    }),
  updateDatabaseDetails: async ({
    db,
    details,
    prevDetails,
    stardogSupportsVirtualTransparency,
    silent = false,
  }: {
    db: string;
    details: DbProperties;
    prevDetails: DbProperties;
    stardogSupportsVirtualTransparency: boolean;
    silent?: boolean;
  }) =>
    request({
      api: stardogDb.options.set,
      args: [db, details],
      loading: DatabasesActionType.UPDATE_DATABASE_DETAILS_ATTEMPT,
      success: DatabasesActionType.UPDATE_DATABASE_DETAILS_SUCCESS,
      failure: DatabasesActionType.UPDATE_DATABASE_DETAILS_FAILURE,
      action: {
        silent,
        prevDetails,
        stardogSupportsVirtualTransparency,
      },
    }),
  addIcvConstraints: async ({
    databaseId,
    constraints,
    options,
    params,
  }: {
    databaseId: string;
    constraints: string;
    options?: { contentType: HTTP.RdfMimeType };
    params?: object;
  }) =>
    request({
      api: stardogDb.icv.add,
      args: [databaseId, constraints, options, params],
      success: DatabasesActionType.ADD_ICV_CONSTRAINTS_SUCCESS,
      loading: DatabasesActionType.ADD_ICV_CONSTRAINTS_ATTEMPT,
      failure: DatabasesActionType.ADD_ICV_CONSTRAINTS_FAILURE,
      action: { databaseId },
    }),
  removeIcvConstraints: async ({
    databaseId,
    constraints,
    options,
    params,
  }: {
    databaseId: string;
    constraints: string;
    options?: { contentType: HTTP.RdfMimeType };
    params?: object;
  }) =>
    request({
      api: stardogDb.icv.remove,
      args: [databaseId, constraints, options, params],
      success: DatabasesActionType.REMOVE_ICV_CONSTRAINTS_SUCCESS,
      loading: DatabasesActionType.REMOVE_ICV_CONSTRAINTS_ATTEMPT,
      failure: DatabasesActionType.REMOVE_ICV_CONSTRAINTS_FAILURE,
      action: { databaseId },
    }),
  validateConstraints: async ({
    noteId,
    databaseId,
    constraints,
    options,
    params,
  }: {
    noteId: string;
    databaseId: string;
    constraints: string;
    options?: { accept: HTTP.RdfMimeType };
    params?: { namedGraphs?: string[] };
  }) =>
    request({
      api: stardogDb.icv.report,
      args: [
        databaseId,
        constraints,
        options,
        { graphUri: params.namedGraphs },
      ],
      success: DatabasesActionType.VALIDATE_CONSTRAINTS_SUCCESS,
      loading: DatabasesActionType.VALIDATE_CONSTRAINTS_ATTEMPT,
      failure: DatabasesActionType.VALIDATE_CONSTRAINTS_FAILURE,
      action: {
        databaseId,
        noteId,
      },
    }),
  requestBiSchemaMappingForDatabase: ({
    databaseId,
    shouldNotDispatch = false,
    sqlSchemaNamedGraph,
  }: {
    databaseId: string;
    shouldNotDispatch?: boolean;
    sqlSchemaNamedGraph: string;
  }) => {
    const query = `construct { ?s ?p ?o } { graph <${sqlSchemaNamedGraph}> { ?s ?p ?o } } limit ${EDITOR_QUERY_LIMIT}`;

    return request({
      api: stardogQuery.execute,
      args: [
        databaseId,
        query,
        'text/turtle',
        { pretty: true, prettify: true, useNamespaces: true },
      ],
      loading: DatabasesActionType.REQUEST_BI_SCHEMA_MAPPING_ATTEMPT,
      success: DatabasesActionType.REQUEST_BI_SCHEMA_MAPPING_SUCCESS,
      failure: DatabasesActionType.REQUEST_BI_SCHEMA_MAPPING_FAILURE,
      action: { databaseId },
      shouldNotDispatch,
    });
  },
  requestGeneratedBiSchemaMappingForDatabase: ({
    databaseId,
    reasoning,
    shouldNotDispatch = false,
  }: {
    databaseId: string;
    reasoning?: boolean;
    shouldNotDispatch?: boolean;
  }) =>
    request({
      api: stardogDb.model,
      args: [
        databaseId,
        { accept: 'text/turtle' },
        {
          output: 'SQL',
          prettify: true,
          schema: 'default',
          ...(reasoning !== undefined ? { reasoning } : {}),
        },
      ],
      success: DatabasesActionType.REQUEST_GENERATED_BI_SCHEMA_MAPPING_SUCCESS,
      loading: DatabasesActionType.REQUEST_GENERATED_BI_SCHEMA_MAPPING_ATTEMPT,
      failure: DatabasesActionType.REQUEST_GENERATED_BI_SCHEMA_MAPPING_FAILURE,
      action: {
        databaseId,
      },
      shouldNotDispatch,
    }),
};

const databasesActionTypeMap = {
  ...databasesActionCreators,
  ...databasesStardogRequestDispatchers,
};

export type DatabasesAction = ActionCreatorAction<
  typeof databasesActionTypeMap
>;
