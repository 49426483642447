import { Region, TableProps } from '@blueprintjs/table';
import * as Monaco from 'monaco-editor/esm/vs/editor/editor.api';
import uuid from 'uuid/v4';

import { FileSystemFileHandle } from 'src/browser/constants/FileSystemAccess';
import { LanguageId } from 'src/common/constants/LanguageId';
import { QueryType } from 'src/common/constants/QueryType';
import { DEFAULT_REASONING_SCHEMA } from 'src/common/store/databases/DatabasesState';
import {
  BindingsResult,
  JsonLdBody,
  ScrollPosition,
} from 'src/common/store/notebook/NotebookState';
import { VoiceboxMessage } from 'src/common/utils/voicebox/types';

export const enum NoteStorageType {
  FILESYSTEM = 'FILESYSTEM',
  STARDOG = 'STARDOG',
}

export interface EditorSettings {
  activeDatabase: string;
  changedWhileInactive: boolean;
  languageId: LanguageId;
  namedGraphs: string[];
  reasoning: boolean;
  timeout: string;
  schema: string;
  query: string;
  latestSavedVersionId: number;
  handle?: FileSystemFileHandle;
  // indicates whether a note's current query content has been saved, or, for
  // `isUntitled=true` notes, whether the content has been modified
  isSaved: boolean;
  // indicates whether a note has been saved to disk or to stardog, but not
  // whether its current query content has been saved
  isUntitled: boolean;
  viewState?: Monaco.editor.ICodeEditorViewState;
  height?: number;
  heightPercent?: number;
  heightPercentMaxHeight?: number;
  isStoreQueryPopoverOpen: boolean;
  isStoreQueryDialogOpen: boolean;
  isQueryConfigPopoverOpen: boolean;
  isSchemaDialogOpen: boolean;
  // Field will be missing for all tabs created before the following PR was deployed:
  // https://github.com/stardog-union/stardog-studio/pull/883
  storageType?: NoteStorageType;
  // visualization settings id for accessing visualization state
  visualizationSettingsId: string;
}

export const enum ResultViewType {
  TEXTUAL = 'textual',
  VISUAL = 'visual',
  SANDDANCE = 'sanddance',
}

export interface ResultSettings {
  contentType?: string;
  body?: string | Record<string, any> | BindingsResult | boolean;
  jsonLdBody?: JsonLdBody;
  query?: string;
  queryId?: string;
  languageId?: LanguageId;
  reasoning?: boolean;
  database?: string;
  pending: boolean;
  pendingSandDance: boolean;
  resultsProcessed?: boolean;
  columnWidths?: TableProps['columnWidths'];
  scrollPosition?: ScrollPosition;
  selection?: Region[];
  viewType: ResultViewType;
  isCollapsed: boolean;
  typeOfQuery?: QueryType;
  height?: number;
  heightPercent?: number;
  heightPercentMaxHeight?: number;
  timeElapsed?: number;
  pathStart?: string; // Name of binding for the start of a path result
  pathEnd?: string; // Name of binding for the end of a path result
}

export interface VoiceboxSettings {
  conversationId?: string;
  /** current messages sent to voicebox to get sparql query */
  messages?: VoiceboxMessage[];
  /** voicebox request is running */
  pending?: boolean;
}

// Used when instantiating a new saved tab
export const defaultEditorSettings: EditorSettings = {
  activeDatabase: '',
  namedGraphs: [],
  changedWhileInactive: false,
  languageId: null, // this should be set based on the new note's content and context
  reasoning: false,
  timeout: '',
  schema: DEFAULT_REASONING_SCHEMA,
  query: '',
  latestSavedVersionId: 1, // monaco's version ids are 1-indexed
  isSaved: true,
  isUntitled: false,
  isStoreQueryPopoverOpen: false,
  isStoreQueryDialogOpen: false,
  isQueryConfigPopoverOpen: false,
  isSchemaDialogOpen: false,
  visualizationSettingsId: '',
};

export const defaultResultSettings: ResultSettings = {
  viewType: ResultViewType.TEXTUAL,
  isCollapsed: true,
  pending: false,
  pendingSandDance: false,
};

export const defaultVoiceboxSettings: VoiceboxSettings = {
  conversationId: undefined,
  messages: undefined,
  pending: undefined,
};

export class NoteState {
  public isPreview = false;

  public editorSettings = {
    ...defaultEditorSettings,
    visualizationSettingsId: uuid(),
  };

  public resultSettings = {
    ...defaultResultSettings,
  };

  public totalQueries = 0;

  public pane = 0;

  public voiceboxSettings = {
    ...defaultVoiceboxSettings,
  };

  constructor(public id: string) {}
}
