import { QueryType } from 'src/common/constants/QueryType';

export enum QueryStatus {
  NOT_STARTED = 'NOT STARTED',
  RUNNING = 'RUNNING',
  TERMINATED = 'TERMINATED',
  DONE = 'DONE',
}

export interface Query {
  db: string;
  id: string;
  reasoning: boolean;
  startTime: number;
  status: QueryStatus;
  timeout: number;
  query?: string;
  type?: QueryType;
}

export class QueriesState {
  constructor(
    public queries: Query[] = [],
    public refreshRate: number = 3000
  ) {}
}
