import {
  RDFS_LABEL_URI,
  STARDOG_STUDIO_LABEL_TAG,
} from 'src/common/constants/uris';
import { getAbbreviationOrFullIri } from 'src/common/utils/caches/abbreviations';

export function getQueryLabels({
  labelPredicates = [],
  namespaces = [],
}: {
  labelPredicates: string[];
  namespaces: string[];
}) {
  const labels = [`<${STARDOG_STUDIO_LABEL_TAG}>`];
  if (labelPredicates.length) {
    labelPredicates.forEach((labelIri) =>
      labels.push(getAbbreviationOrFullIri(labelIri, namespaces || []))
    );
  } else {
    labels.push(`<${RDFS_LABEL_URI}>`);
  }
  return labels;
}
