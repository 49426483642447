import {
  ORDERED_BASE_OPTIONS,
  SPECIAL_PROPERTIES_MAP,
  USE_LABEL_PREDICATES_CAPTION_KEY,
} from 'src/common/constants/visualization/visualizationConstants';
import { VisualizationOption } from 'src/common/constants/visualization/visualizationTypes';

const baseOptionsAccumulator =
  (baseOptionsMap: { [key: string]: string }) =>
  (acc: VisualizationOption[], key: string) => {
    acc.push({ label: baseOptionsMap[key], value: key });
    if (key === USE_LABEL_PREDICATES_CAPTION_KEY) {
      acc.push({
        label: '──────────',
        value: '__LABEL_PREDICATES_DIVIDER__',
        disabled: true,
      });
    }
    return acc;
  };

export function getVisualizationOptions(
  baseOptionsMap: { [key: string]: string },
  optionsMap?: { [key: string]: string }
): VisualizationOption[] {
  if (!optionsMap) {
    return ORDERED_BASE_OPTIONS.filter((key) => baseOptionsMap[key]).reduce(
      baseOptionsAccumulator(baseOptionsMap),
      []
    );
  }

  const baseOptions = ORDERED_BASE_OPTIONS.filter(
    (key) => baseOptionsMap[key] && optionsMap[key]
  ).reduce(baseOptionsAccumulator(baseOptionsMap), []);

  const otherOptions = Object.keys(optionsMap)
    .filter((key) => !baseOptionsMap[key] && !SPECIAL_PROPERTIES_MAP[key])
    .sort()
    .map((key) => ({ label: optionsMap[key], value: key }));

  const divider =
    baseOptions.length && otherOptions.length
      ? [
          {
            label: '──────────',
            value: '__OTHER_OPTIONS_DIVIDER__',
            disabled: true,
          },
        ]
      : [];

  return [...baseOptions, ...divider, ...otherOptions];
}
