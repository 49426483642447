import { notebookActionCreators } from 'src/common/actions/notebook/notebookActionCreators';
import {
  StudioStateGetter,
  StudioThunkDispatch,
} from 'src/common/actions/StudioAction';

export const openUserPreferences =
  () => (dispatch: StudioThunkDispatch, getState: StudioStateGetter) => {
    const { preferences } = getState();
    dispatch(notebookActionCreators.openUserPreferences(preferences));
  };
