import * as Monaco from 'monaco-editor/esm/vs/editor/editor.api';
import { basename } from 'path';

import { browserApi } from 'src/browser/apis/browserApi';
import { notebookActionCreators } from 'src/common/actions/notebook/notebookActionCreators';
import {
  StudioStateGetter,
  StudioThunkDispatch,
} from 'src/common/actions/StudioAction';
import { visualizationsActionCreators } from 'src/common/actions/visualizations/visualizationsActionCreators';
import { StudioMessageBoxOptions } from 'src/common/components/ContextualStudioApi';
import { IpcEventType } from 'src/common/constants/events';
import {
  USER_PREFERENCES_NOTE_ID,
  USER_PREFERENCES_NOTE_NAME,
} from 'src/common/constants/notebook/noteConstants';
import { NoteState } from 'src/common/store/notebook/NoteState';
import { safelyGet } from 'src/common/utils/safelyGet';

function performDelete(
  noteId: string,
  note: NoteState,
  dispatch: StudioThunkDispatch
) {
  const cacheKey = safelyGet(note, [
    'resultSettings',
    'jsonLdBody',
    'queryHistoryId',
  ]);
  const settingsId = safelyGet(note, [
    'editorSettings',
    'visualizationSettingsId',
  ]);

  dispatch(notebookActionCreators.deleteNote(noteId));
  dispatch(
    visualizationsActionCreators.clearVisualizationData([cacheKey], settingsId)
  );
  browserApi.ipcRenderer.send(IpcEventType.INVALIDATE_BINDINGS_CACHE, noteId);
}

function constructDeleteOptions(
  note: NoteState,
  createDeleteOptions: (noteName: string) => StudioMessageBoxOptions
) {
  let options: StudioMessageBoxOptions;

  if (note.id === USER_PREFERENCES_NOTE_ID) {
    const markers = Monaco.editor.getModelMarkers({
      resource: Monaco.Uri.file(USER_PREFERENCES_NOTE_ID),
    });
    // User preferences are invalid if there's no query value or the model has invalid markers
    if (!note.editorSettings.query || markers.length) {
      options = {
        type: 'question',
        message: `Your changes to ${USER_PREFERENCES_NOTE_NAME} are invalid and cannot be saved. Are you sure you want to close the editor?`,
        detail: "Your changes will be lost if you don't save them.",
        buttons: ['Cancel', 'Close and Discard'],
      };
    } else {
      options = createDeleteOptions(USER_PREFERENCES_NOTE_NAME);
    }
  } else {
    options = createDeleteOptions(basename(note.id));
  }

  return options;
}

export const deleteNote =
  ({ noteId }) =>
  async (dispatch: StudioThunkDispatch, getState: StudioStateGetter) => {
    const { notes } = getState().notebook;
    const note = notes[noteId];

    if (note.editorSettings.isSaved) {
      return performDelete(noteId, note, dispatch);
    }

    const options = constructDeleteOptions(
      note,
      (noteName: string): StudioMessageBoxOptions => ({
        type: 'question',
        message: `You have unsaved changes in ${noteName}. Are you sure you want to close the editor?`,
        detail: "Your changes will be lost if you don't save them.",
        buttons: ['Cancel', 'Close and Discard'],
      })
    );

    const { response: index } = await browserApi.dialog.showMessageBox(options);
    if (options.buttons[index] !== 'Cancel') {
      return performDelete(noteId, note, dispatch);
    }
  };

export const forceDeleteNote =
  ({ noteId }) =>
  async (dispatch: StudioThunkDispatch, getState: StudioStateGetter) => {
    const { notes } = getState().notebook;
    const note = notes[noteId];
    return performDelete(noteId, note, dispatch);
  };
