import * as React from 'react';

export const KeyboardIcon = () => (
  <svg height="30px" viewBox="0 0 30 30" width="30px">
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(2.520000, 7.200000)">
        <rect
          height="15.6"
          opacity="0.949999988"
          rx="1.2"
          stroke="#FFFFFF"
          strokeWidth="1.5"
          width="24.96"
          x="-1.0658141e-14"
          y="6.79456491e-14"
        />
        <g fill="#FFFFFF" transform="translate(2.460000, 3.180000)">
          <g>
            <rect height="2.1" width="2.1" x="0" y="0" />
            <rect height="2.1" width="2.1" x="3.6" y="0" />
            <rect height="2.1" width="2.1" x="7.2" y="0" />
            <rect height="2.1" width="2.1" x="10.8" y="0" />
            <rect height="2.1" width="2.1" x="14.4" y="0" />
            <rect height="2.1" width="2.1" x="18" y="0" />
          </g>
          <g transform="translate(0.000000, 7.200000)">
            <rect height="2.1" width="2.1" x="0" y="0" />
            <rect height="2.1" width="12.9" x="3.6" y="0" />
            <rect height="2.1" width="2.1" x="18" y="0" />
          </g>
          <g transform="translate(1.800000, 3.600000)">
            <rect height="2.1" width="2.1" x="0" y="0" />
            <rect height="2.1" width="2.1" x="3.6" y="0" />
            <rect height="2.1" width="2.1" x="7.2" y="0" />
            <rect height="2.1" width="2.1" x="10.8" y="0" />
            <rect height="2.1" width="2.1" x="14.4" y="0" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
