import { EDITOR_QUERY_LIMIT } from 'src/common/constants/editorLimits';
import {
  DEFAULT_GRAPH,
  getAllLocalNamedGraph,
} from 'src/common/constants/namedGraphs';
import {
  OWL_CLASS_URI,
  OWL_DATATYPE_PROPERTY_URI,
  OWL_OBJECT_PROPERTY_URI,
  RDFS_COMMENT_URI,
  RDFS_DOMAIN_URI,
  RDFS_RANGE_URI,
  RDFS_SUB_CLASS_OF_URI,
  RDFS_SUB_PROPERTY_OF_URI,
  RDF_TYPE_URI,
  SHACL_URI,
  STARDOG_STUDIO_LABEL_TAG,
} from 'src/common/constants/uris';
import { NormalizedModelsItems } from 'src/common/store/models/ModelsState';
import { getQueryLabelsValues } from 'src/common/utils/queryBuilders/shared/getQueryLabelsValues';
import { BindingResult } from 'src/common/utils/queryBuilders/shared/types';

export interface RawModelsItemBinding {
  subject: BindingResult;
  label?: BindingResult;
  labelPredicate?: BindingResult;
  parent?: BindingResult;
  comment?: BindingResult;
  domain?: BindingResult;
  range?: BindingResult;
}

const MODELS_QUERY_LIMIT = 100000;

export function buildModelsClassesQuery(
  page = 0,
  labelPredicates?: string[],
  namespaces?: string[]
) {
  const labels = getQueryLabelsValues({
    labelPredicates,
    namespaces,
  });
  return `# Models Classes
SELECT DISTINCT ?subject ?label ?labelPredicate ?parent ?comment
WHERE {
  {
    {
      SELECT ?subject {
        ?subject a <${OWL_CLASS_URI}>
      }
      LIMIT ${MODELS_QUERY_LIMIT}
      OFFSET ${MODELS_QUERY_LIMIT * page}
    }
  } OPTIONAL {
    {
      ${labels}
    } UNION {
      ?subject <${RDFS_SUB_CLASS_OF_URI}> ?parent
      FILTER (?subject != ?parent && isIRI(?parent))
    } UNION {
      ?subject <${RDFS_COMMENT_URI}> ?comment
    }
  }
}
`;
}

export function buildModelsRelationshipsQuery(
  page = 0,
  labelPredicates?: string[],
  namespaces?: string[]
) {
  const labels = getQueryLabelsValues({ labelPredicates, namespaces });
  return `# Models Relationships
SELECT DISTINCT ?subject ?label ?labelPredicate ?parent ?comment ?domain ?range
WHERE {
  {
    {
      SELECT ?subject {
        ?subject a <${OWL_OBJECT_PROPERTY_URI}>
      }
      LIMIT ${MODELS_QUERY_LIMIT}
      OFFSET ${MODELS_QUERY_LIMIT * page}
    }
  } OPTIONAL {
    {
      ${labels}
    } UNION {
      ?subject <${RDFS_SUB_PROPERTY_OF_URI}> ?parent
      FILTER (?subject != ?parent && isIRI(?parent))
    } UNION {
      ?subject <${RDFS_COMMENT_URI}> ?comment
    } UNION {
      ?subject <${RDFS_DOMAIN_URI}> ?domain
    } UNION {
      ?subject <${RDFS_RANGE_URI}> ?range
    }
  }
}
`;
}

export function buildModelsAttributesQuery(
  page = 0,
  labelPredicates?: string[],
  namespaces?: string[]
) {
  const labels = getQueryLabelsValues({ labelPredicates, namespaces });
  return `# Models Attributes
SELECT DISTINCT ?subject ?label ?labelPredicate ?parent ?comment ?domain ?range
WHERE {
  {
    {
      SELECT ?subject {
        ?subject a <${OWL_DATATYPE_PROPERTY_URI}>
      }
      LIMIT ${MODELS_QUERY_LIMIT}
      OFFSET ${MODELS_QUERY_LIMIT * page}
    }
  } OPTIONAL {
    {
      ${labels}
    } UNION {
      ?subject <${RDFS_SUB_PROPERTY_OF_URI}> ?parent
      FILTER (?subject != ?parent && isIRI(?parent))
    } UNION {
      ?subject <${RDFS_COMMENT_URI}> ?comment
    } UNION {
      ?subject <${RDFS_DOMAIN_URI}> ?domain
    } UNION {
      ?subject <${RDFS_RANGE_URI}> ?range
    }
  }
}
`;
}

export function buildModelsDeleteClassesQuery(
  iris: string[],
  labels: string[],
  namedGraphUri: string,
  type: string
) {
  return `WITH <${namedGraphUri}>
DELETE { ?s ?p ?o }
WHERE {
  VALUES (?s) {
    ${iris.map((iri) => `(<${iri}>)`).join('\n    ')}
  }
  VALUES (?p) {
    ${labels.map((label) => `(<${label}>)`).join('\n    ')}
    (<${RDFS_COMMENT_URI}>)
    (<${RDFS_SUB_CLASS_OF_URI}>)
  }
  ?s ?p ?o
};

WITH <${namedGraphUri}>
DELETE { ?s <${RDF_TYPE_URI}> <${type}> }
WHERE {
  VALUES (?s) {
    ${iris.map((iri) => `(<${iri}>)`).join('\n    ')}
  }
  ?s <${RDF_TYPE_URI}> <${type}>
}`;
}

export function buildModelsDeletePropertiesQuery(
  iris: string[],
  labels: string[],
  namedGraphUri: string,
  type: string
) {
  return `WITH <${namedGraphUri}>
DELETE { ?s ?p ?o }
WHERE {
  VALUES (?s) {
    ${iris.map((iri) => `(<${iri}>)`).join('\n    ')}
  }
  VALUES (?p) {
    ${labels.map((label) => `(<${label}>)`).join('\n    ')}
    (<${RDFS_COMMENT_URI}>)
    (<${RDFS_SUB_PROPERTY_OF_URI}>)
    (<${RDFS_DOMAIN_URI}>)
    (<${RDFS_RANGE_URI}>)
  }
  ?s ?p ?o
};

WITH <${namedGraphUri}>
DELETE { ?s <${RDF_TYPE_URI}> <${type}> }
WHERE {
  VALUES (?s) {
    ${iris.map((iri) => `(<${iri}>)`).join('\n    ')}
  }
  ?s <${RDF_TYPE_URI}> <${type}>
}`;
}

export function buildModelsDeleteConstraintsQuery(namedGraphUri: string) {
  const namedGraphLine =
    namedGraphUri === DEFAULT_GRAPH ? '' : `WITH <${namedGraphUri}>`;
  return `${namedGraphLine}
DELETE { ?s ?p ?o }
WHERE {
  GRAPH <${namedGraphUri}> {
    ?s ?p ?o
    FILTER (
      STRSTARTS(STR(?p), '${SHACL_URI}') ||
      STRSTARTS(STR(?o), '${SHACL_URI}')
    )
  }
};

${namedGraphLine}
DELETE { ?o ?p2 ?o2 }
WHERE {
  GRAPH <${namedGraphUri}> {
    ?s ?p ?o
    OPTIONAL { ?o ?p2 ?o2 }
    FILTER (
      STRSTARTS(STR(?p), '${SHACL_URI}')
    )
  }
}`;
}

export function buildModelTriples(
  iris: string[],
  itemsByIri: NormalizedModelsItems,
  type: string,
  subType: string
) {
  const triples: string[] = [];
  iris.forEach((iri) => {
    if (!itemsByIri[iri] || !itemsByIri[iri].localData) {
      return;
    }
    triples.push(`<${iri}> <${RDF_TYPE_URI}> <${type}>`);
    const {
      labelsByPredicate,
      preferredLabel,
      comment,
      parents,
      domain,
      range,
    } = itemsByIri[iri].localData;
    const labelPredicates = Object.keys(labelsByPredicate);
    if (labelPredicates.length) {
      labelPredicates.forEach((predicate) => {
        const labels = labelsByPredicate[predicate];
        Object.values(labels).forEach((label) => {
          const object = `"${label.label || label.value}"`;
          const taggedObject = label.tag ? `${object}@${label.tag}` : object;
          triples.push(`<${iri}> <${predicate}> ${taggedObject}`);
        });
      });
    }
    if (preferredLabel) {
      const label = `"${preferredLabel.label || preferredLabel.value}"`;
      const taggedLabel = preferredLabel.tag
        ? `${label}@${preferredLabel.tag}`
        : label;
      triples.push(`<${iri}> <${STARDOG_STUDIO_LABEL_TAG}> ${taggedLabel}`);
    }
    if (comment) {
      triples.push(`<${iri}> <${RDFS_COMMENT_URI}> "${comment}"`);
    }
    if (parents.length) {
      triples.push(...parents.map((p) => `<${iri}> <${subType}> <${p}>`));
    }
    if (domain.length) {
      triples.push(
        ...domain.map((d) => `<${iri}> <${RDFS_DOMAIN_URI}> <${d}>`)
      );
    }
    if (range.length) {
      triples.push(...range.map((r) => `<${iri}> <${RDFS_RANGE_URI}> <${r}>`));
    }
  });
  return triples;
}

export const buildModelConstraintsGraphQuery = (
  stardogSupportsVirtualTransparency: boolean
) => {
  const graph = getAllLocalNamedGraph(stardogSupportsVirtualTransparency);
  return `
# Models Constraints Graphs
SELECT DISTINCT ?graph
FROM NAMED <${graph}>
{
  GRAPH ?graph {
    ?s ?p ?o
    FILTER (
      STRSTARTS(STR(?p), '${SHACL_URI}') ||
      STRSTARTS(STR(?o), '${SHACL_URI}')
    )
  }
}`;
};

export const modelConstraintsRequestQuery = `
PREFIX sh: <${SHACL_URI}>
CONSTRUCT { 
  ?s ?p ?o . 
  ?o ?p2 ?o2 .
}
WHERE {
  {
    ?s ?p ?o
    FILTER (
      STRSTARTS(STR(?o), '${SHACL_URI}')
    )
  }
  UNION {
    ?s ?p ?o
    OPTIONAL { ?o ?p2 ?o2 }
    FILTER (
        STRSTARTS(STR(?p), '${SHACL_URI}')
    )
  }
}
LIMIT ${EDITOR_QUERY_LIMIT}
`;
