import { NotebookState } from 'src/common/store/notebook/NotebookState';

export const getSanitizedNotebookState = (
  notebook: NotebookState
): NotebookState => {
  const notes = Object.keys(notebook.notes).reduce((acc, key) => {
    return {
      ...acc,
      [key]: {
        ...notebook.notes[key],
        resultSettings: {
          ...notebook.notes[key].resultSettings,
          body:
            notebook.notes[key].resultSettings.body !== undefined
              ? 'redacted for user experience'
              : null,
        },
      },
    };
  }, {});

  return {
    ...notebook,
    notes,
  };
};
