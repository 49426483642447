import { Theme } from 'src/common/utils/theme';

export class Preferences {
  public autoUpdate: boolean | undefined = undefined;

  public editorFontSize: number | undefined = 14;

  public editorTooltips: boolean | undefined = undefined;

  /** Duration error toasts will persist for the specified timeout set in seconds */
  public errorToastTimeout = 60;

  public includeConstraintsInModelsHub: boolean | undefined = true;

  public telemetryConsent: boolean | undefined = undefined;

  public useSchemaForAutocompletion: boolean | undefined = true;

  public useSchemaForNamedGraphs: boolean | undefined = true;

  public zoomLevel = 0;

  public theme: Theme = Theme.DARK;

  public labelPredicatesByDatabase: {
    [databaseId: string]: string[];
  } = {};

  public modelsBaseIriByDatabase: {
    [databaseId: string]: {
      [schemaName: string]: string | null;
    };
  } = {};

  public queryLimit = 1000;

  public voiceboxOverridesByDatabase: Record<string, string> | undefined =
    undefined;
}
