import { STARDOG_SANDBOX_CONNECTION_NAME } from 'src/common/constants/configuration';
import { initialState } from 'src/common/reducers/connection';
import { ConnectionState } from 'src/common/store/connection/ConnectionState';
import { isStardogStudioWeb } from 'src/common/utils/isStardogStudioWeb';
import { safelyGet } from 'src/common/utils/safelyGet';
import { InitialData } from 'src/types';

// On the stardog.studio website, ensure that the state has a saved connection
// for Stardog Sandbox.
export default (data: InitialData) => {
  if (!isStardogStudioWeb) {
    return data;
  }

  const oldConnections: Partial<ConnectionState> = safelyGet(
    data,
    ['connection'],
    {}
  );
  const { saved, savedNames } = oldConnections;

  return {
    ...data,
    version: '1.22.0',
    connection: {
      ...oldConnections,
      saved: {
        ...saved,
        [STARDOG_SANDBOX_CONNECTION_NAME]: {
          ...initialState.current,
          name: STARDOG_SANDBOX_CONNECTION_NAME,
          username: 'anonymous',
          password: 'anonymous',
          endpoint: 'https://sandbox.stardog.cloud:5820',
        },
      },
      savedNames: [STARDOG_SANDBOX_CONNECTION_NAME, ...savedNames],
    },
  };
};
