import { FileSystemFileHandle } from 'src/browser/constants/FileSystemAccess';
import {
  ActionCreatorAction,
  createTypedAction,
} from 'src/common/actions/types';

export const enum FileSystemActionType {
  DID_SAVE = 'DID_SAVE',
  DID_SAVE_AS = 'DID_SAVE_AS',
  EXPORTED_SAVED_CONNECTIONS = 'EXPORTED_SAVED_CONNECTIONS',
}

export type FileSystemAction = ActionCreatorAction<
  typeof fileSystemActionCreators
>;

export const fileSystemActionCreators = {
  didSave: (
    filename: string,
    latestSavedVersionId: number,
    handle?: FileSystemFileHandle
  ) =>
    createTypedAction(FileSystemActionType.DID_SAVE, {
      handle,
      latestSavedVersionId,
      noteId: filename,
    }),
  didSaveAs: (oldUri: string, newUri: string, handle?: FileSystemFileHandle) =>
    createTypedAction(FileSystemActionType.DID_SAVE_AS, {
      handle,
      oldUri,
      newUri,
    }),

  exportedSavedConnections: (filename: string, errorMessage?: string) =>
    createTypedAction(FileSystemActionType.EXPORTED_SAVED_CONNECTIONS, {
      filename,
      errorMessage,
    }),
};
