import { QueryType, QueryTypeIdentifier } from 'src/common/constants/QueryType';
import { ResultSettings } from 'src/common/store/notebook/NoteState';
import { safelyGet } from 'src/common/utils/safelyGet';

export const getNumResultsByQueryType = (
  typeOfQuery: QueryType,
  body: ResultSettings['body']
): number => {
  const bindingsPath =
    typeOfQuery === QueryTypeIdentifier.GRAPHQL
      ? ['data']
      : ['results', 'bindings'];
  const bindings = safelyGet(body, bindingsPath, []);

  switch (typeOfQuery) {
    case QueryTypeIdentifier.SELECT:
      // SELECT queries will be streamed from the main process and SHOULD have
      // a `totalLength` property when that happens; fall back to
      // `bindings.length` if it's missing.
      return safelyGet(body, ['results', 'totalLength'], bindings.length);
    case QueryTypeIdentifier.PATHS: {
      // PATH queries return paths of bindings, which are delimited in the JSON
      // by `bindings` entries with no length. The number of results is the
      // number of paths, which is the number of delimiters + 1.
      const numDelimiters = bindings.reduce(
        (sum, binding) => (Object.keys(binding).length === 0 ? sum + 1 : sum),
        0
      );
      return numDelimiters > 0 ? numDelimiters + 1 : 0;
    }
    default:
      return bindings.length;
  }
};
