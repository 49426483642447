import {
  PreferencesAction,
  PreferencesActionType,
} from 'src/common/actions/preferences/preferencesActionCreators';
import { Preferences } from 'src/common/store/preferences/PreferencesState';

const initalState = new Preferences();

export const preferencesReducer = (
  prevState: Preferences = initalState,
  action: PreferencesAction
) => {
  switch (action.type) {
    case PreferencesActionType.SET_PREFERENCE:
      return {
        ...prevState,
        [action.payload.key]: action.payload.value,
      };
    case PreferencesActionType.SET_PREFERENCES: {
      return {
        ...action.payload.preferences,
      };
    }
    default:
      return prevState;
  }
};
