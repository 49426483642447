import pick from 'lodash.pick';
import { extname } from 'path';
import { query as stardogQuery } from 'stardog';
import uuid from 'uuid/v4';

import {
  DatabasesAction,
  DatabasesActionType,
} from 'src/common/actions/databases/databasesActionCreators';
import {
  FileSystemAction,
  FileSystemActionType,
} from 'src/common/actions/fileSystem/fileSystemActionCreators';
import {
  NotebookAction,
  NotebookActionType,
  notebookActionCreators,
  notebookStardogRequestDispatchers,
} from 'src/common/actions/notebook/notebookActionCreators';
import {
  PreferencesAction,
  PreferencesActionType,
} from 'src/common/actions/preferences/preferencesActionCreators';
import {
  QueriesAction,
  QueriesActionType,
} from 'src/common/actions/queries/queriesActionCreators';
import { Response, Unwrapped } from 'src/common/actions/request/types';
import {
  StoredQueriesAction,
  StoredQueriesActionType,
} from 'src/common/actions/storedQueries/storedQueriesActionCreators';
import {
  VisualizationsAction,
  VisualizationsActionType,
} from 'src/common/actions/visualizations/visualizationsActionCreators';
import { LanguageId } from 'src/common/constants/LanguageId';
import { FAILURE_CONTENT_TYPE } from 'src/common/constants/notebook/editorConstants';
import {
  RELEASE_NOTES_NOTE,
  RELEASE_NOTES_NOTE_ID,
  USER_PREFERENCES_NOTE_ID,
} from 'src/common/constants/notebook/noteConstants';
import { QueryType, QueryTypeIdentifier } from 'src/common/constants/QueryType';
import { GLOBAL_SYMBOL } from 'src/common/constants/storedQueries/storedQueriesConstants';
import { DEFAULT_REASONING_SCHEMA } from 'src/common/store/databases/DatabasesState';
import {
  JsonLdBody,
  NotebookState,
  PaneStateMap,
  defaultResultsHeight,
  defaultSanddanceResultsHeight,
  defaultVisualizationResultsHeight,
  minResultsHeight,
} from 'src/common/store/notebook/NotebookState';
import {
  EditorSettings,
  NoteState,
  NoteStorageType,
  ResultSettings,
  ResultViewType,
  defaultEditorSettings,
  defaultVoiceboxSettings,
} from 'src/common/store/notebook/NoteState';
import {
  RawJsonLdData,
  RawJsonLdResult,
  VisualizationSourceType,
} from 'src/common/store/visualization/VisualizationState';
import { getPreferencesQuery } from 'src/common/utils/preferences/getPreferencesQuery';
import { safelyGet } from 'src/common/utils/safelyGet';
import {
  isAssistantMessage,
  isUserMessage,
} from 'src/common/utils/voicebox/types';
import { voiceboxQueryResponseFilter } from 'src/common/utils/voicebox/voiceboxQueryResponseFilter';

const getEmptyBodyForQueryType = (queryType: QueryType) =>
  queryType === QueryTypeIdentifier.SELECT ||
  queryType === QueryTypeIdentifier.PATHS ||
  queryType === QueryTypeIdentifier.GRAPHQL
    ? {}
    : '';

const getQueryType = (languageId, query) =>
  languageId && languageId === QueryTypeIdentifier.GRAPHQL
    ? QueryTypeIdentifier.GRAPHQL
    : stardogQuery.utils.queryType(query);

const updateNoteState = (
  prevState: NotebookState,
  noteId: NoteState['id'],
  parentKey: keyof Pick<NoteState, 'editorSettings' | 'resultSettings'>,
  update: Partial<EditorSettings | ResultSettings>,
  incrementQueries = false
): NotebookState => {
  const currentNote = prevState.notes[noteId];

  if (!currentNote) {
    return prevState;
  }

  return {
    ...prevState,
    notes: {
      ...prevState.notes,
      [noteId]: {
        ...currentNote,
        totalQueries: currentNote.totalQueries + (incrementQueries ? 1 : 0),
        [parentKey]: {
          ...currentNote[parentKey],
          ...update,
        },
      },
    },
  };
};

const findEmptyUntitledTabId = (prevState: NotebookState) =>
  [
    // make sure to check the active tab first, for UX's sake
    prevState.activeNoteId,
    ...Object.keys(prevState.notes),
  ].find((tabId) => {
    const tab = prevState.notes[tabId];
    if (
      tab.editorSettings.isUntitled &&
      tab.editorSettings.isSaved &&
      tab.editorSettings.query.length < 1
    ) {
      return true;
    }
    return false;
  });

// TODO: There is a `fileExtensions.ts` file that is used elsewhere and
// should probably be the single source of truth for these things.
const noteLanguageId = (noteUri: string) => {
  switch (extname(noteUri)) {
    case '.gql':
    case '.graphql':
      return LanguageId.GRAPHQL;
    // TODO add case for SHACL extensions
    case '.sms':
      return LanguageId.SMS2;
    case '': // TODO check file content. If it's valid sparql or graphql we can figure this out! https://github.com/stardog-union/stardog-studio/issues/1054
    case '.rq':
    case '.sq':
    case '.sparql':
      return LanguageId.SPARQL;
    // TODO add case for SRS extensions
    case '.trig':
      return LanguageId.TRIG;
    case '.ttl':
    case '.turtle':
      return LanguageId.TURTLE;
    case '.srs':
      return LanguageId.SRS;
    case '.json':
      return LanguageId.JSON;
    default:
      return LanguageId.TXT;
  }
};

const addTab = (prevState: NotebookState, newTab: NoteState) => {
  const uri = newTab.id;

  // Check to see if the file is already open in a tab.
  if (prevState.notes[uri]) {
    return {
      ...prevState,
      activeNoteId: uri,
    };
  }

  // automatically select the active database from the previously active tab,
  // as long as the new tab isn't a special non-DB-having tab
  if (
    !newTab.editorSettings.activeDatabase &&
    uri !== USER_PREFERENCES_NOTE_ID &&
    uri !== RELEASE_NOTES_NOTE_ID
  ) {
    const prevSelectedDatabase = safelyGet(prevState, [
      'notes',
      prevState.activeNoteId,
      'editorSettings',
      'activeDatabase',
    ]);
    if (prevSelectedDatabase) {
      newTab.editorSettings.activeDatabase = prevSelectedDatabase;
    }
  }

  if (!newTab.editorSettings.languageId) {
    newTab.editorSettings.languageId = noteLanguageId(uri);
  }
  if (newTab.editorSettings.languageId === LanguageId.GRAPHQL) {
    newTab.editorSettings.namedGraphs = [];
  }

  const prevActiveTabIdx = prevState.noteIds.indexOf(prevState.activeNoteId);
  const nextState = {
    ...prevState,
    activeNoteId: uri,
    noteIds: [
      ...prevState.noteIds.slice(0, prevActiveTabIdx + 1),
      uri,
      ...prevState.noteIds.slice(prevActiveTabIdx + 1),
    ],
    notes: { ...prevState.notes, [uri]: newTab },
    panes: {
      ...prevState.panes,
      '0': {
        ...prevState.panes['0'],
        noteIds: [...prevState.panes['0'].noteIds, uri],
      },
    },
  };

  if (prevState.noteIds.length === 1) {
    // if there was previously only one tab
    const prevTab = prevState.notes[Object.keys(prevState.notes)[0]];
    if (
      // and it's the default untitled tab and untouched by the user
      prevTab.editorSettings.isUntitled &&
      prevTab.editorSettings.isSaved &&
      !prevTab.editorSettings.query.length &&
      // and if the user is not opening another untitled tab
      !newTab.editorSettings.isUntitled &&
      // and the new tab is not the release notes or user prefrences tab
      uri !== RELEASE_NOTES_NOTE_ID &&
      uri !== USER_PREFERENCES_NOTE_ID
    ) {
      // remove the default untitled tab
      return removeTab(nextState, prevTab.id);
    }
  }

  return nextState;
};

const getNewTabId = (prevState: NotebookState, title?: string): string => {
  if (!title) {
    const untitledNumbers = Object.entries(prevState.notes).reduce(
      (acc, [uri, note]) => {
        if (note.editorSettings.isUntitled) {
          const untitledNumber = Number(uri.slice(uri.lastIndexOf('-') + 1));
          acc.push(untitledNumber);
        }
        return acc;
      },
      [0]
    );
    const maxUntitledNum = Math.max(...untitledNumbers);
    return `${uuid()}/Untitled-${maxUntitledNum + 1}`;
  }
  // sort note ids, then iterate through
  const indexWithoutConflict = Object.keys(prevState.notes)
    .sort()
    .reduce((acc, uri) => {
      if (uri === title) {
        return 1;
      }
      if (uri.startsWith(title) && acc && uri === `${title} (${acc})`) {
        return acc + 1;
      }
      return acc;
    }, null);
  return indexWithoutConflict ? `${title} (${indexWithoutConflict})` : title;
};

const addNewTab = (
  prevState: NotebookState,
  contents?: string,
  title?: string
) => {
  const newId: string = getNewTabId(prevState, title);
  const newNote = new NoteState(newId);
  const { activeNoteId, notes } = prevState;
  // Carry over `activeDatabase` value
  const previouslySelectedDb = safelyGet(
    notes,
    [activeNoteId, 'editorSettings', 'activeDatabase'],
    defaultEditorSettings.activeDatabase
  );
  newNote.editorSettings.activeDatabase = previouslySelectedDb;
  // Carry over `namedGraphs` value
  const previouslySelectedNamedGraphs = safelyGet(
    notes,
    [activeNoteId, 'editorSettings', 'namedGraphs'],
    defaultEditorSettings.namedGraphs
  );
  newNote.editorSettings.namedGraphs = [...previouslySelectedNamedGraphs];
  // Carry over `reasoning` value
  const previouslySelectedReasoning = safelyGet(
    notes,
    [activeNoteId, 'editorSettings', 'reasoning'],
    defaultEditorSettings.reasoning
  );
  newNote.editorSettings.reasoning = previouslySelectedReasoning;
  newNote.editorSettings.isUntitled = !title;
  newNote.editorSettings.query = contents || newNote.editorSettings.query;
  // New tabs are marked as saved so we don't confirm before the user
  // closes them. `isSaved` is set to false as soon as the tab content is
  // modified. The following commented out line would only make this true when
  // the tab is empty.
  // newNote.editorSettings.isSaved = contents ? false : true;
  newNote.editorSettings.isSaved = true;

  return addTab(prevState, newNote);
};

const removeTab = (
  prevState: NotebookState,
  tabId: NoteState['id']
): NotebookState => {
  const filteredIds = prevState.noteIds.filter((id) => id !== tabId);
  const idxOfDeletion = prevState.noteIds.indexOf(tabId);

  const notes = filteredIds.reduce((accumulator, id) => {
    accumulator[id] = prevState.notes[id];
    return accumulator;
  }, {});

  const activeNoteId =
    tabId !== prevState.activeNoteId
      ? // if the deleted tab is not the active tab, just keep the current tab active
        prevState.activeNoteId
      : filteredIds.length < 1
      ? // if there are no tabs after this deletion, there is no active tab
        null
      : idxOfDeletion < filteredIds.length
      ? // as long as the deleted tab's index is less than the remaining number of
        // tabs, set the tab to the right as active
        filteredIds[idxOfDeletion]
      : idxOfDeletion === filteredIds.length
      ? // if the rightmost tab was deleted, set the new rightmost tab to active
        filteredIds[idxOfDeletion - 1]
      : // in any other case, which i'm not sure exists, set the first tab to active
        filteredIds[0];

  const nextState = {
    ...prevState,
    notes,
    noteIds: filteredIds,
    panes: {
      ...prevState.panes,
      '0': {
        ...prevState.panes['0'],
        noteIds: filteredIds,
      },
    },
    activeNoteId,
  };

  if (nextState.noteIds.length < 1) {
    return addNewTab(nextState);
  }

  return nextState;
};

const parseGraphqlTextExplanation = ({
  plan = '',
  fields = {},
  sparql = '',
}) => {
  return `SPARQL:
${sparql}
FIELDS:
${JSON.stringify(fields, null, 4)}
PLAN:
${plan}
`.replace(/\n\s*\n\s*\n/g, '\n\n'); // remove duplicate empty lines
};

export const initialState = addNewTab(new NotebookState());

export const notebookReducer = (
  prevState = initialState,
  action:
    | NotebookAction
    | QueriesAction
    | FileSystemAction
    | StoredQueriesAction
    | PreferencesAction
    | DatabasesAction
    | VisualizationsAction
): NotebookState => {
  const noteId: string = action.payload
    ? // TODO: Could have better types here, I imagine.
      (action.payload as any).action
      ? (action.payload as any).action.noteId
      : (action as any).payload.noteId
    : prevState.activeNoteId;

  switch (action.type) {
    case NotebookActionType.ASK_VOICEBOX_ATTEMPT: {
      const currentNote = prevState.notes[noteId];

      if (!currentNote) {
        return prevState;
      }

      return {
        ...prevState,
        notes: {
          ...prevState.notes,
          [noteId]: {
            ...currentNote,
            resultSettings: {
              ...currentNote.resultSettings,
              pending: true,
            },
            voiceboxSettings: {
              ...currentNote.voiceboxSettings,
              pending: true,
            },
          },
        },
      };
    }
    case NotebookActionType.ASK_VOICEBOX_SUCCESS:
    case NotebookActionType.ASK_VOICEBOX_FAILURE: {
      const currentNote = prevState.notes[noteId];

      if (!currentNote) {
        return prevState;
      }

      return {
        ...prevState,
        notes: {
          ...prevState.notes,
          [noteId]: {
            ...currentNote,
            resultSettings: {
              ...currentNote.resultSettings,
              pending: false,
            },
            voiceboxSettings: {
              ...currentNote.voiceboxSettings,
              pending: false,
            },
          },
        },
      };
    }
    case NotebookActionType.UPDATED_VOICEBOX_CONVERSATION: {
      const { conversationId, messages } = action.payload;
      const currentNote = prevState.notes[noteId];

      if (!currentNote) {
        return prevState;
      }

      let query = '';
      const lastMessage =
        messages.length >= 2 ? messages[messages.length - 1] : undefined;
      if (isAssistantMessage(lastMessage)) {
        const userMessage = messages.find(
          ({ id }) => id === lastMessage.requestMessageId
        );
        if (isUserMessage(userMessage)) {
          const { userPrompt } = userMessage;
          const codePart = voiceboxQueryResponseFilter(lastMessage.content);
          query = `# ${userPrompt}\n${codePart}\n`;
        }
      }

      return {
        ...prevState,
        notes: {
          ...prevState.notes,
          [noteId]: {
            ...currentNote,
            editorSettings: {
              ...currentNote.editorSettings,
              ...(query ? { query } : {}),
            },
            voiceboxSettings: {
              ...currentNote.voiceboxSettings,
              conversationId,
              messages,
            },
          },
        },
      };
    }
    case NotebookActionType.SET_DB_FOR_NOTE: {
      const previouslyActiveDatabase =
        prevState.notes[noteId].editorSettings.activeDatabase;
      return {
        ...prevState,
        notes: {
          ...prevState.notes,
          [noteId]: {
            ...prevState.notes[noteId],
            editorSettings: {
              ...prevState.notes[noteId].editorSettings,
              activeDatabase: action.payload.db,
              namedGraphs: [],
              schema:
                previouslyActiveDatabase !== action.payload.db
                  ? DEFAULT_REASONING_SCHEMA
                  : prevState.notes[noteId].editorSettings.schema,
            },
            resultSettings: {
              ...prevState.notes[noteId].resultSettings,
              // Clear any results hanging around if the DB is changed (#98)
              body: undefined,
              contentType: null,
            },
            voiceboxSettings: { ...defaultVoiceboxSettings },
          },
        },
      };
    }
    case NotebookActionType.SET_NAMED_GRAPHS_FOR_NOTE:
      return updateNoteState(prevState, noteId, 'editorSettings', {
        namedGraphs: action.payload.namedGraphs,
      });
    case NotebookActionType.TOGGLE_REASONING_FOR_NOTE:
      return updateNoteState(prevState, noteId, 'editorSettings', {
        reasoning: !prevState.notes[noteId].editorSettings.reasoning,
      });
    case NotebookActionType.SET_QUERY_TIMEOUT_FOR_NOTE:
      return updateNoteState(prevState, noteId, 'editorSettings', {
        timeout: action.payload.timeout,
      });
    case NotebookActionType.SET_REASONING_SCHEMA_FOR_NOTE:
      return updateNoteState(prevState, noteId, 'editorSettings', {
        schema: action.payload.schema,
      });
    case NotebookActionType.SET_QUERY_FOR_NOTE:
      return updateNoteState(prevState, noteId, 'editorSettings', {
        query: action.payload.query,
      });
    case NotebookActionType.SET_QUERY_ID_FOR_NOTE:
      return updateNoteState(prevState, noteId, 'resultSettings', {
        queryId:
          (
            action as ReturnType<
              typeof notebookActionCreators['setQueryIdForNote']
            >
          ).payload.queryId ||
          safelyGet(
            (action as unknown as Response<any, any, any>).payload.response,
            ['headers', 'sd-query-id', 0]
          ),
      });
    case QueriesActionType.KILL_QUERY_SUCCESS: {
      const { associatedNoteId } = action.payload.action;

      if (typeof associatedNoteId === 'undefined') {
        return prevState;
      }

      return updateNoteState(prevState, associatedNoteId, 'resultSettings', {
        pending: false,
      });
    }
    case QueriesActionType.KILL_QUERIES_FAILURE:
    case QueriesActionType.KILL_QUERIES_SUCCESS: {
      const { successAssociatedNoteIds } = action.payload;

      return Object.keys(successAssociatedNoteIds).reduce(
        (accState, associatedNoteId) => {
          if (typeof associatedNoteId === 'undefined') {
            return accState;
          }

          return updateNoteState(accState, associatedNoteId, 'resultSettings', {
            pending: false,
          });
        },
        prevState
      );
    }
    case DatabasesActionType.VALIDATE_CONSTRAINTS_ATTEMPT: {
      return updateNoteState(prevState, noteId, 'resultSettings', {
        typeOfQuery: QueryTypeIdentifier.SHACL_REPORT,
        contentType: null,
        body: getEmptyBodyForQueryType(QueryTypeIdentifier.SHACL_REPORT),
        jsonLdBody: undefined,
        languageId: prevState.notes[noteId].editorSettings.languageId,
        reasoning: prevState.notes[noteId].editorSettings.reasoning,
        database: prevState.notes[noteId].editorSettings.activeDatabase,
        pending: true,
        resultsProcessed: false,
        viewType: ResultViewType.TEXTUAL,
      });
    }
    case NotebookActionType.EXECUTE_QUERY_FOR_NOTE_ATTEMPT: {
      let query: string;
      let queryId: string;

      if ((action as any).payload.args) {
        const { payload } = action as Response<any, any, any>;
        query = payload.args[1];
        queryId = (payload.args[3] || {}).id;
      } else {
        const { payload } = action as ReturnType<
          typeof notebookActionCreators['executeSelectAttempt']
        >;
        ({ query, queryId } = payload);
      }

      const queryType = getQueryType(
        safelyGet(action.payload as any, ['action', 'languageId'], null),
        query
      );

      const noteStateResultsUpdate = {
        query,
        // Set this only in the attempt as we have all the necessary knowledge
        // (the query) and that doesn't change between attempt and success or
        // failure.
        typeOfQuery: queryType,
        contentType: null,
        body: getEmptyBodyForQueryType(queryType),
        jsonLdBody: undefined,
        languageId: prevState.notes[noteId].editorSettings.languageId,
        reasoning: prevState.notes[noteId].editorSettings.reasoning,
        database: prevState.notes[noteId].editorSettings.activeDatabase,
        pending: true,
        resultsProcessed: false,
        viewType: ResultViewType.TEXTUAL,
      };

      if (queryId) {
        (noteStateResultsUpdate as any).queryId = queryId;
      }

      return updateNoteState(
        prevState,
        noteId,
        'resultSettings',
        noteStateResultsUpdate
      );
    }
    case NotebookActionType.EXECUTE_JSON_LD_QUERY_FOR_NOTE_ATTEMPT: {
      return updateNoteState(prevState, noteId, 'resultSettings', {
        jsonLdBody: {
          queryHistoryId: action.payload.action.queryHistoryId,
        },
      });
    }
    case NotebookActionType.EXPLAIN_QUERY_FOR_NOTE_ATTEMPT: {
      const { noteId, profile, queryId } = action.payload.action;
      const query = (action as Response<any, any, any>).payload.args[1];
      const oldNote = prevState.notes[noteId];
      const oldResultSettingsToKeep =
        oldNote.resultSettings.typeOfQuery === QueryTypeIdentifier.EXPLAIN ||
        oldNote.resultSettings.typeOfQuery === QueryTypeIdentifier.PROFILE
          ? pick(oldNote.resultSettings, 'languageId')
          : null;

      return updateNoteState(prevState, noteId, 'resultSettings', {
        // Reset some stuff
        contentType: '',
        body: {},
        jsonLdBody: undefined,
        resultsProcessed: false,
        // Update some stuff, as these values affect the explanation
        query,
        queryId,
        ...oldResultSettingsToKeep,
        // Set this only in the attempt as we have all the necessary knowledge
        // (the query) and that doesn't change between attempt and success or
        // failure.
        typeOfQuery: profile
          ? QueryTypeIdentifier.PROFILE
          : QueryTypeIdentifier.EXPLAIN,
        reasoning: prevState.notes[noteId].editorSettings.reasoning,
        database: prevState.notes[noteId].editorSettings.activeDatabase,
        // State
        pending: true,
        viewType: ResultViewType.TEXTUAL,
        languageId: LanguageId.TXT,
      });
    }
    case NotebookActionType.EXPLAIN_QUERY_FOR_NOTE_SUCCESS: {
      const { response, action: actionPayload } = action.payload;
      const { queryLanguageId, profile } = actionPayload;
      const height =
        prevState.notes[noteId].resultSettings.height || defaultResultsHeight;
      let { body: responseBody } = response;
      if (queryLanguageId === LanguageId.GRAPHQL) {
        responseBody = parseGraphqlTextExplanation(responseBody.data);
      }
      return updateNoteState(
        prevState,
        noteId,
        'resultSettings',
        {
          // TODO this comment
          // `typeOfQuery` is only set here for tests in NoteResult.test.tsx
          // which only dispatch the `success` message and expect the state to
          // be fully set for the NoteResult component to render correctly.
          // The tests should be simulating the full series of messages (ATTEMPT -> RECEIVED -> SUCCESS | FAILURE)
          typeOfQuery: profile
            ? QueryTypeIdentifier.PROFILE
            : QueryTypeIdentifier.EXPLAIN,
          contentType: response.headers['content-type'],
          resultsProcessed: true,
          body: responseBody,
          isCollapsed: false,
          pending: false,
          height,
          heightPercent: null,
          queryId: undefined,
        },
        true
      );
    }
    case NotebookActionType.FETCH_FROM_CACHE: {
      const note = prevState.notes[noteId];
      const body = note.resultSettings.body as any;
      if (!body.results) {
        return prevState;
      }

      const { bindings } = body.results;
      const newBindings = [...bindings];
      newBindings.splice(
        action.payload.row,
        action.payload.bindings.length,
        ...action.payload.bindings
      );
      return updateNoteState(prevState, noteId, 'resultSettings', {
        body: {
          ...body,
          results: {
            ...body.results,
            bindings: newBindings,
          },
        },
      });
    }
    case NotebookActionType.EXECUTE_QUERY_FOR_NOTE_RECEIVED: {
      const query = action.payload.args[1];
      const queryType = getQueryType(
        safelyGet(action.payload as any, ['action', 'languageId'], null),
        query
      );

      const resultsHeight =
        prevState.notes[noteId].resultSettings.height || defaultResultsHeight;

      return updateNoteState(prevState, noteId, 'resultSettings', {
        isCollapsed: false,
        height: resultsHeight,
        heightPercent: null,
        // TODO: this is a hack, I think -- @mscandal said he might do something else here:
        body: getEmptyBodyForQueryType(queryType),
        viewType: ResultViewType.TEXTUAL,
        resultsProcessed: false,
        pending: false,
      });
    }
    case DatabasesActionType.VALIDATE_CONSTRAINTS_SUCCESS: {
      const { response } = action.payload;
      const resultsHeight =
        prevState.notes[noteId].resultSettings.height || defaultResultsHeight;

      return updateNoteState(prevState, noteId, 'resultSettings', {
        contentType: response.headers['content-type'],
        body: response?.body,
        isCollapsed: false,
        height: resultsHeight,
        heightPercent: null,
        pending: false,
        resultsProcessed: true,
        typeOfQuery: QueryTypeIdentifier.SHACL_REPORT,
      });
    }
    case NotebookActionType.EXECUTE_QUERY_FOR_NOTE_SUCCESS: {
      const { response, action: actionPayload } = action.payload;
      const query = (action as Response<any, any, any>).payload.args
        ? (action as Response<any, any, any>).payload.args[1]
        : (
            action as ReturnType<
              typeof notebookActionCreators['executeSelectSuccess']
            >
          ).payload.query;

      const queryType = getQueryType((actionPayload as any).languageId, query);

      type BandaidAction = Unwrapped<
        ReturnType<
          typeof notebookStardogRequestDispatchers['executeQueryDispatcher']
        >
      >;

      let body;
      switch (queryType) {
        case QueryTypeIdentifier.SELECT: {
          const bindings = [];
          for (
            let i = 0;
            i < response?.body?.results?.totalLength || 0;
            i += 1
          ) {
            // TODO: Someone please comment what's going on with the `false` push here.
            bindings.push(response?.body?.results?.bindings[i] || false);
          }
          body = {
            head: response?.body?.head,
            results: {
              bindings,
            },
          };
          break;
        }
        default:
          body = response?.body;
      }

      const resultsHeight =
        prevState.notes[noteId].resultSettings.height || defaultResultsHeight;

      return updateNoteState(
        prevState,
        noteId,
        'resultSettings',
        {
          // `typeOfQuery` is only set here for tests in NoteResult.test.tsx
          // which only dispatch the `success` message and expect the state to
          // be fully set for the NoteResult component to render correctly.
          // The tests should be simulating the full series of messages (ATTEMPT -> RECEIVED -> SUCCESS | FAILURE)
          typeOfQuery: queryType,
          contentType:
            queryType === QueryTypeIdentifier.UPDATE
              ? null
              : response.headers['content-type'][0],
          body,
          isCollapsed: false,
          height: resultsHeight,
          heightPercent: null,
          pending: false,
          resultsProcessed: true,
          scrollPosition: [0, 0, 0, 0],
          selection: undefined,
          timeElapsed: action.payload.timeElapsed,
          pathStart: (action as BandaidAction).payload.action.start,
          pathEnd: (action as BandaidAction).payload.action.end,
          viewType: ResultViewType.TEXTUAL,
          queryId: undefined,
        },
        true
      );
    }
    case NotebookActionType.EXECUTE_JSON_LD_QUERY_FOR_NOTE_SUCCESS: {
      type BandaidAction = Unwrapped<
        ReturnType<
          typeof notebookStardogRequestDispatchers['executeJsonLdQueryDispatcher']
        >
      >;
      const { response, action: actionPayload } = (action as BandaidAction)
        .payload;

      const result: RawJsonLdData[] | RawJsonLdResult = response?.body || [];
      const results = Array.isArray(result) ? result : result['@graph'];

      const prevJsonLdBody: Partial<JsonLdBody> =
        prevState.notes[noteId]?.resultSettings?.jsonLdBody || {};
      return updateNoteState(prevState, noteId, 'resultSettings', {
        jsonLdBody: {
          ...prevJsonLdBody,
          queryHistoryId:
            prevJsonLdBody.queryHistoryId || actionPayload.queryHistoryId,
          results,
        },
        queryId: undefined,
      });
    }
    case DatabasesActionType.VALIDATE_CONSTRAINTS_FAILURE: {
      return updateNoteState(prevState, noteId, 'resultSettings', {
        pending: false,
      });
    }
    case NotebookActionType.EXECUTE_QUERY_FOR_NOTE_FAILURE:
    case NotebookActionType.EXECUTE_JSON_LD_QUERY_FOR_NOTE_FAILURE: {
      const { response } = (action as Response<any, any, any>).payload;
      const resultsHeight =
        prevState.notes[noteId].resultSettings.height || defaultResultsHeight;
      return updateNoteState(prevState, noteId, 'resultSettings', {
        body: response?.body,
        jsonLdBody: undefined,
        contentType: FAILURE_CONTENT_TYPE,
        pending: false,
        height: resultsHeight,
        heightPercent: null,
        resultsProcessed: true,
        viewType: ResultViewType.TEXTUAL,
        isCollapsed: false,
        queryId: undefined,
      });
    }
    case NotebookActionType.EXPLAIN_QUERY_FOR_NOTE_FAILURE: {
      const { response, action: actionPayload } = action.payload;
      const { profile } = actionPayload;
      const resultsHeight =
        prevState.notes[noteId].resultSettings.height || defaultResultsHeight;
      return updateNoteState(prevState, noteId, 'resultSettings', {
        typeOfQuery: profile
          ? QueryTypeIdentifier.PROFILE
          : QueryTypeIdentifier.EXPLAIN,
        // TODO why any
        body: (response as any).body,
        jsonLdBody: undefined,
        contentType: FAILURE_CONTENT_TYPE,
        pending: false,
        height: resultsHeight,
        heightPercent: null,
        resultsProcessed: true,
        isCollapsed: false,
        queryId: undefined,
      });
    }
    case NotebookActionType.EXPORT_RESULTS_FOR_NOTE_ATTEMPT:
      return updateNoteState(prevState, noteId, 'resultSettings', {
        pending: true,
      });
    case NotebookActionType.EXPORT_RESULTS_FOR_NOTE_FAILURE:
    case NotebookActionType.EXPORT_RESULTS_FOR_NOTE_SUCCESS:
      return updateNoteState(prevState, noteId, 'resultSettings', {
        pending: false,
      });
    case VisualizationsActionType.VISUALIZE_RESULTS_ATTEMPT: {
      const { type, noteId } = action.payload.source;
      if (type === VisualizationSourceType.NOTE) {
        const height = Math.max(
          prevState.notes[noteId].resultSettings.height,
          defaultVisualizationResultsHeight
        );

        return updateNoteState(prevState, noteId, 'resultSettings', {
          viewType: ResultViewType.VISUAL,
          height,
          heightPercent: null,
        });
      }
      return prevState;
    }
    case VisualizationsActionType.VISUALIZE_RESULTS_SUCCESS: {
      const { type, noteId } = action.payload.source;
      if (type === VisualizationSourceType.NOTE) {
        const height = Math.max(
          prevState.notes[noteId].resultSettings.height,
          defaultVisualizationResultsHeight
        );

        return updateNoteState(prevState, noteId, 'resultSettings', {
          viewType: ResultViewType.VISUAL,
          height,
          heightPercent: null,
        });
      }
      return prevState;
    }
    case NotebookActionType.ADD_NOTE: {
      return addNewTab(
        prevState,
        action.payload.contents,
        action.payload.title
      );
    }
    case NotebookActionType.OPEN_CONTENTS_IN_NEW_NOTE: {
      const emptyUntitledTabId = findEmptyUntitledTabId(prevState);

      // if there's an existing, untouched, untitled tab, populate it instead of creating a new one
      if (emptyUntitledTabId) {
        return removeTab(
          addNewTab(prevState, action.payload.contents),
          emptyUntitledTabId
        );
      }
      return addNewTab(prevState, action.payload.contents);
    }
    case NotebookActionType.DELETE_FILE_HANDLE_FOR_NOTE: {
      const { noteId } = action.payload;
      const prevNote = prevState.notes[noteId];
      const nextEditorSettings = {
        ...prevNote.editorSettings,
      };
      delete nextEditorSettings.handle;
      return {
        ...prevState,
        notes: {
          ...prevState.notes,
          [noteId]: {
            ...prevNote,
            editorSettings: nextEditorSettings,
          },
        },
      };
    }
    case NotebookActionType.DELETE_NOTE: {
      return removeTab(prevState, noteId);
    }
    case NotebookActionType.TOGGLE_RESULT_COLLAPSE_FOR_NOTE: {
      const { isCollapsed: prevCollapsed, height: resultsHeight } =
        prevState.notes[noteId].resultSettings;

      const nextCollapsed = !prevCollapsed;

      let height;
      if (!nextCollapsed && resultsHeight < defaultResultsHeight) {
        // if user resized results to the point of collapsing them, when they're toggled back open, jump to default height
        height = defaultResultsHeight;
      } else {
        height = resultsHeight;
      }

      return updateNoteState(prevState, noteId, 'resultSettings', {
        isCollapsed: nextCollapsed,
        height,
        heightPercent: null,
      });
    }
    case NotebookActionType.REARRANGE_NOTES: {
      const { sourceNoteId, targetNoteId } = action.payload;
      const indexOfTarget = prevState.noteIds.indexOf(targetNoteId);

      const newNoteIds = prevState.noteIds.filter((id) => id !== sourceNoteId);
      newNoteIds.splice(indexOfTarget, 0, sourceNoteId);

      return {
        ...prevState,
        noteIds: newNoteIds,
        activeNoteId: sourceNoteId,
      };
    }
    case NotebookActionType.RENAME_NOTE: {
      const { oldNoteId, newNoteId } = action.payload;
      if (
        !oldNoteId ||
        !newNoteId ||
        oldNoteId === newNoteId ||
        !prevState.notes[oldNoteId] ||
        prevState.notes[newNoteId]
      ) {
        return prevState;
      }

      const noteIdx = prevState.noteIds.indexOf(oldNoteId);
      const nextNoteIds = [...prevState.noteIds];
      nextNoteIds.splice(noteIdx, 1, newNoteId);

      const nextPanes = Object.keys(prevState.panes).reduce<PaneStateMap>(
        (acc, key) => {
          const noteIds = [...prevState.panes[key].noteIds];
          const noteIdx = noteIds.indexOf(oldNoteId);
          noteIds.splice(noteIdx, 1, newNoteId);
          acc[key] = { noteIds };
          return acc;
        },
        {}
      );

      const nextNotes = { ...prevState.notes };
      const nextNote = nextNotes[oldNoteId];
      const note: NoteState = {
        ...nextNote,
        id: newNoteId,
        editorSettings: {
          ...nextNote.editorSettings,
          isUntitled: false,
        },
      };
      nextNotes[newNoteId] = note;
      delete nextNotes[oldNoteId];

      return {
        ...prevState,
        noteIds: nextNoteIds,
        notes: nextNotes,
        panes: nextPanes,
        activeNoteId:
          prevState.activeNoteId === oldNoteId
            ? newNoteId
            : prevState.activeNoteId,
      };
    }
    case NotebookActionType.RESIZE_RESULTS_FOR_NOTE: {
      const { resultsHeight } = action.payload;
      const note = prevState.notes[noteId];

      const isCollapsed = !(resultsHeight > minResultsHeight);

      return {
        ...prevState,
        notes: {
          ...prevState.notes,
          [noteId]: {
            ...note,
            resultSettings: {
              ...note.resultSettings,
              height: resultsHeight,
              heightPercent: null,
              isCollapsed,
            },
          },
        },
      };
    }
    case NotebookActionType.SAVE_VIEW_STATE_FOR_NOTE: {
      return updateNoteState(
        prevState,
        action.payload.noteId,
        'editorSettings',
        {
          viewState: action.payload.viewState,
        }
      );
    }
    case FileSystemActionType.DID_SAVE: {
      return updateNoteState(
        prevState,
        action.payload.noteId,
        'editorSettings',
        {
          handle: action.payload.handle,
          latestSavedVersionId: action.payload.latestSavedVersionId,
          isSaved: true,
        }
      );
    }
    // Stored Query Save and Save As
    // Similar to `FileSystemAction.DID_SAVE_AS` with the extra case from
    // `FileSystemAction.DID_SAVE` where the user is performing a regular
    // `Save`.
    case StoredQueriesActionType.UPDATE_QUERY_SUCCESS: {
      const {
        name: newUri,
        query,
        database,
        reasoning,
      } = action.payload.args[0];
      // Handle the `Save` case
      if (newUri === prevState.activeNoteId) {
        return updateNoteState(prevState, newUri, 'editorSettings', {
          latestSavedVersionId: action.payload.action.latestSavedVersionId,
          isSaved: true,
        });
      }
      // Handle the `Save As` case
      const oldTab = prevState.notes[prevState.activeNoteId];
      const stateWithoutOldTab = removeTab(prevState, prevState.activeNoteId);
      const newEditorSettings = {
        query,
        visualizationSettingsId: oldTab.editorSettings.visualizationSettingsId,
        reasoning,
        activeDatabase:
          database && database !== GLOBAL_SYMBOL
            ? database
            : oldTab.editorSettings.activeDatabase,
        storageType: NoteStorageType.STARDOG,
      };
      const newResultSettings = { ...oldTab.resultSettings };
      // The query name in the action may differ from that of the currently open
      // tab, so we must update that note's state separately to close the `Save As` dialog.
      if (prevState.notes[newUri]) {
        const stateWithUpdatedQuery = updateNoteState(
          stateWithoutOldTab,
          newUri,
          'editorSettings',
          newEditorSettings
        );
        const stateWithFullyUpdatedTab = updateNoteState(
          stateWithUpdatedQuery,
          newUri,
          'resultSettings',
          newResultSettings
        );
        return { ...stateWithFullyUpdatedTab, activeNoteId: newUri };
      }
      const newTab = new NoteState(newUri);
      newTab.editorSettings = {
        ...newTab.editorSettings,
        ...newEditorSettings,
      };
      newTab.resultSettings = newResultSettings;
      return addTab(stateWithoutOldTab, newTab);
    }
    // Replace current tab with a new one for `newUri`. If a tab already exists
    // with that ID then update and switch to it. Retain the current (old) tab's
    // query and result settings.
    case FileSystemActionType.DID_SAVE_AS: {
      const { handle, oldUri, newUri } = action.payload;
      const oldTab = prevState.notes[oldUri];
      const stateWithoutOldTab = removeTab(prevState, oldUri);
      if (prevState.notes[newUri] && oldUri !== newUri) {
        const stateWithUpdatedQuery = updateNoteState(
          stateWithoutOldTab,
          newUri,
          'editorSettings',
          {
            handle,
            query: oldTab.editorSettings.query,
            storageType: NoteStorageType.FILESYSTEM,
            visualizationSettingsId:
              oldTab.editorSettings.visualizationSettingsId,
          }
        );
        const stateWithFullyUpdatedTab = updateNoteState(
          stateWithUpdatedQuery,
          newUri,
          'resultSettings',
          { ...oldTab.resultSettings }
        );
        return { ...stateWithFullyUpdatedTab, activeNoteId: newUri };
      }
      const newTab = new NoteState(newUri);
      newTab.editorSettings = {
        ...newTab.editorSettings,
        // Carry the following editor settings over:
        ...pick(
          oldTab.editorSettings,
          'activeDatabase',
          'namedGraphs',
          'languageId',
          'reasoning',
          'timeout',
          'schema',
          'query',
          'visualizationSettingsId'
        ),
        handle,
        storageType: NoteStorageType.FILESYSTEM,
      };
      newTab.resultSettings = {
        ...oldTab.resultSettings,
      };
      return addTab(stateWithoutOldTab, newTab);
    }
    case NotebookActionType.SET_SAVED_FLAG_FOR_NOTE: {
      return updateNoteState(
        prevState,
        prevState.activeNoteId,
        'editorSettings',
        {
          isSaved: action.payload.isSaved,
          changedWhileInactive: false,
        }
      );
    }
    case NotebookActionType.OPEN_IN_TAB: {
      const {
        target,
        contents,
        storageType,
        database,
        reasoning,
        languageId,
        handle,
      } = action.payload;
      // Does not pass `target` to `getNewTabId` because this action does not
      // assume the tab to open is a new tab -- it may already be present, which
      // will be handled by `addTab`
      const newTab = new NoteState(target || getNewTabId(prevState));
      newTab.editorSettings.query = contents;
      newTab.editorSettings.storageType = storageType;
      newTab.editorSettings.reasoning = reasoning;
      newTab.editorSettings.isUntitled = !target;
      if (handle) {
        newTab.editorSettings.handle = handle;
      }
      if (storageType === NoteStorageType.STARDOG) {
        newTab.editorSettings.languageId = languageId;
      }
      if (database && database !== GLOBAL_SYMBOL) {
        newTab.editorSettings.activeDatabase = database;
      }
      return addTab(prevState, newTab);
    }
    // not sure that we want to automatically open a tab when files are created
    // case FileSystemActionType.FILE_CREATED: {
    //   const { uri } = action.payload;
    //   return addTab(prevState, new NoteState(uri));
    // }
    case NotebookActionType.UPDATE_NOTEBOOK_MOSAIC_STATE: {
      const { mosaicStateChange } = action.payload;
      return {
        ...prevState,
        mosaicState: {
          ...prevState.mosaicState,
          ...mosaicStateChange,
        },
      };
    }
    case NotebookActionType.SET_STORED_AND_HISTORY_TAB: {
      const { storedAndHistoryTab } = action.payload;
      return {
        ...prevState,
        storedAndHistoryTab,
      };
    }
    case NotebookActionType.SET_RESULTS_COLUMN_WIDTHS: {
      const { columnWidths } = action.payload;
      return updateNoteState(
        prevState,
        prevState.activeNoteId,
        'resultSettings',
        {
          columnWidths,
        }
      );
    }
    case NotebookActionType.SET_RESULTS_SCROLL_POSITION: {
      const { scrollPosition } = action.payload;
      return updateNoteState(prevState, noteId, 'resultSettings', {
        scrollPosition,
      });
    }
    case NotebookActionType.SET_RESULTS_SELECTION: {
      const { selection } = action.payload;
      return updateNoteState(
        prevState,
        prevState.activeNoteId,
        'resultSettings',
        {
          selection,
        }
      );
    }
    case NotebookActionType.SET_RESULTS_VIEW_TYPE: {
      const { viewType } = action.payload;
      return updateNoteState(
        prevState,
        prevState.activeNoteId,
        'resultSettings',
        {
          viewType,
        }
      );
    }
    case NotebookActionType.SHOW_STORE_QUERY_DIALOG: {
      return updateNoteState(prevState, noteId, 'editorSettings', {
        isStoreQueryDialogOpen: action.payload.open,
      });
    }
    case NotebookActionType.TOGGLE_STORE_QUERY_POPOVER: {
      return updateNoteState(prevState, noteId, 'editorSettings', {
        isStoreQueryPopoverOpen:
          !prevState.notes[noteId].editorSettings.isStoreQueryPopoverOpen,
      });
    }
    case NotebookActionType.SET_QUERY_CONFIG_POPOVER_OPEN: {
      return updateNoteState(prevState, noteId, 'editorSettings', {
        isQueryConfigPopoverOpen: action.payload.isOpen,
      });
    }
    case NotebookActionType.SET_SCHEMA_DIALOG_OPEN:
      return updateNoteState(prevState, noteId, 'editorSettings', {
        isSchemaDialogOpen: action.payload.isOpen,
      });
    // Store query button for filesystem queries
    case StoredQueriesActionType.STORE_QUERY_SUCCESS: {
      const { activeNoteId } = prevState;
      const prevNote = prevState.notes[activeNoteId];
      const newTab = new NoteState(action.payload.args[0].name);
      newTab.editorSettings.query = action.payload.args[0].query;
      newTab.editorSettings.storageType = NoteStorageType.STARDOG;
      newTab.editorSettings.languageId =
        prevNote.editorSettings.languageId || LanguageId.SPARQL;
      newTab.editorSettings.visualizationSettingsId =
        prevNote.editorSettings.visualizationSettingsId;
      newTab.resultSettings = { ...prevNote.resultSettings };
      if (prevNote.editorSettings.isUntitled) {
        const stateWithStoredTab = addTab(prevState, newTab);
        return removeTab(stateWithStoredTab, activeNoteId);
      }
      const updatedActiveNoteState = updateNoteState(
        prevState,
        activeNoteId,
        'editorSettings',
        { isStoreQueryPopoverOpen: false }
      );
      return addTab(updatedActiveNoteState, newTab);
    }
    case StoredQueriesActionType.DELETE_STORED_QUERY_SUCCESS: {
      return removeTab(prevState, action.payload.action.queryName);
    }
    case NotebookActionType.RESET_SELECTED_DATABASES: {
      const { databases } = action.payload;
      return {
        ...prevState,
        notes: Object.keys(prevState.notes).reduce((acc, id) => {
          const prevNamedGraphsForNote =
            prevState.notes[id].editorSettings.namedGraphs;
          let nextActiveDatabaseIdForNote: string;
          let nextNamedGraphsForNote: string[];
          const prevActiveDatabaseIdForNote =
            prevState.notes[id].editorSettings.activeDatabase;
          if (databases.includes(prevActiveDatabaseIdForNote)) {
            nextActiveDatabaseIdForNote = prevActiveDatabaseIdForNote;
            nextNamedGraphsForNote = [...prevNamedGraphsForNote];
          } else {
            nextActiveDatabaseIdForNote = '';
            nextNamedGraphsForNote = [];
          }

          return {
            ...acc,
            [id]: {
              ...prevState.notes[id],
              editorSettings: {
                ...prevState.notes[id].editorSettings,
                activeDatabase: nextActiveDatabaseIdForNote,
                namedGraphs: nextNamedGraphsForNote,
              },
            },
          };
        }, {}),
      };
    }
    case NotebookActionType.SET_LANGUAGE_ID_FOR_NOTE: {
      const { noteId, languageId } = action.payload;
      const editorSettingsUpdate: Partial<EditorSettings> = { languageId };
      // if we're switching to GraphQL, remove the named graph
      if (languageId === LanguageId.GRAPHQL) {
        editorSettingsUpdate.namedGraphs = [];
      }
      return updateNoteState(
        prevState,
        noteId,
        'editorSettings',
        editorSettingsUpdate
      );
    }
    case NotebookActionType.OPEN_RELEASE_NOTES: {
      return addTab(prevState, RELEASE_NOTES_NOTE);
    }
    case NotebookActionType.OPEN_USER_PREFERENCES: {
      const { preferences } = action.payload;
      const newNote = new NoteState(USER_PREFERENCES_NOTE_ID);
      newNote.editorSettings.query = getPreferencesQuery(preferences);
      return addTab(prevState, newNote);
    }
    case NotebookActionType.LOAD_SANDDANCE_ATTEMPT: {
      const { noteId } = action.payload;
      const { height } = prevState.notes[noteId].resultSettings;
      let heightPercent: number = null;
      let heightPercentMaxHeight: number = null;
      if (height < defaultSanddanceResultsHeight) {
        heightPercent = 80;
        heightPercentMaxHeight = defaultSanddanceResultsHeight;
      }
      return updateNoteState(prevState, noteId, 'resultSettings', {
        pendingSandDance: true,
        height,
        heightPercent,
        heightPercentMaxHeight,
      });
    }
    case NotebookActionType.LOAD_SANDDANCE_SUCCESS:
    case NotebookActionType.LOAD_SANDDANCE_FAILURE: {
      const { noteId } = action.payload;
      return updateNoteState(prevState, noteId, 'resultSettings', {
        pendingSandDance: false,
      });
    }
    // The UserPreferences note does not share the same ManagedEditor as the
    // other the Note components, so its ManagedEditor will not be aware about
    // needing to check its state while the note is inactive. Therefore, we need
    // to update the note state when the set preferences actions are dispatched
    case PreferencesActionType.SET_PREFERENCE: {
      const note = prevState.notes[USER_PREFERENCES_NOTE_ID];
      if (!note) {
        return prevState;
      }
      const preferences = {
        ...action.payload.preferences,
        [action.payload.key]: action.payload.value,
      };
      const query = JSON.stringify(preferences, null, 2);
      // We only want to override the contents of the user preferences note
      // if the note is not currently being edited (that is, in a saved state)
      if (note.editorSettings.isSaved) {
        return updateNoteState(
          prevState,
          USER_PREFERENCES_NOTE_ID,
          'editorSettings',
          {
            query,
            isSaved: true,
          }
        );
      }
      // If the change results in the same value of the current note,
      // we update the isSaved flag of the note
      if (note.editorSettings.query === query) {
        return updateNoteState(
          prevState,
          USER_PREFERENCES_NOTE_ID,
          'editorSettings',
          {
            isSaved: true,
          }
        );
      }
      // Otherwise, no state change is necessary
      return prevState;
    }
    case PreferencesActionType.SET_PREFERENCES: {
      const { preferences } = action.payload;
      const query = getPreferencesQuery(preferences);
      // Currently, set preferences is only dispatched at the request
      // of "saving" the user prefrences file, so we don't need to
      // worry about the note contents being out of sync
      return updateNoteState(
        prevState,
        USER_PREFERENCES_NOTE_ID,
        'editorSettings',
        {
          query,
          isSaved: true,
        }
      );
    }
    default:
      return prevState;
  }
};
