import { ConnectionOptions } from 'stardog';

import {
  STARDOG_EXPRESS_CONNECTION_NAME,
  STARDOG_EXPRESS_ENDPOINT,
  STARDOG_EXPRESS_PASSWORD,
  STARDOG_EXPRESS_USERNAME,
} from 'src/common/constants/configuration';
import { Permission } from 'src/common/store/security/SecurityState';
import { isStardogStudioWeb } from 'src/common/utils/isStardogStudioWeb';

export type UserConfig = Partial<Connection> & {
  username: string;
  [key: string]: any;
};

export const enum ConnectionStatus {
  VIRGIN = 'VIRGIN',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  SIGNED_OUT = 'SIGNED_OUT',
}

export enum ConnectionFailureReason {
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  NO_RESPONSE = 'NO_RESPONSE',
}

export const enum DrawerStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export interface DrawerState {
  selectedConnectionName: string;
  status: DrawerStatus;
}

export interface Connection extends ConnectionOptions {
  name?: string;
  proxyUrl?: string;
  useKerberos?: boolean;
  isSqlServerEnabled?: boolean;
  status: ConnectionStatus;
  failureReason?: ConnectionFailureReason;
  shouldStayLoggedIn: boolean;
  shouldAllowSelfSignedCerts?: boolean;
  stardogVersion?: string;
  /** whether this connection came from the portal api */
  isPortalConnection?: boolean;
  useBrowserAuth?: boolean;
}

// Same as `Connection` but `name` is not optional
export interface SavedConnection extends Connection {
  name: string;
}

export type SavedConnectionNames = string[];

export type SavedConnections = {
  [key: string]: SavedConnection;
};

export interface IConnectionState {
  drawer: DrawerState;
  current: Connection;
  savedNames: SavedConnectionNames;
  saved: SavedConnections;
  currentPermissions: Permission[] | null;
}

export const defaultCurrentConnection: Connection = {
  name: '',
  username: 'admin',
  password: 'admin',
  endpoint: process.env.STARDOG_ENDPOINT,
  proxyUrl: '',
  shouldStayLoggedIn: false,
  shouldAllowSelfSignedCerts: false,
  useKerberos: false,
  status: ConnectionStatus.VIRGIN,
};

export const stardogExpressConnection: SavedConnection = {
  ...defaultCurrentConnection,
  name: STARDOG_EXPRESS_CONNECTION_NAME,
  username: STARDOG_EXPRESS_USERNAME,
  password: STARDOG_EXPRESS_PASSWORD,
  endpoint: STARDOG_EXPRESS_ENDPOINT,
};

export class ConnectionState implements IConnectionState {
  // The drawer stuff should arguably live in a UI reducer, but, TODO.
  public drawer: DrawerState = {
    selectedConnectionName: '',
    status: DrawerStatus.CLOSED,
  };

  public current: Connection = defaultCurrentConnection;

  public savedNames: SavedConnectionNames = isStardogStudioWeb
    ? [STARDOG_EXPRESS_CONNECTION_NAME]
    : [];

  public saved: SavedConnections = isStardogStudioWeb
    ? { [STARDOG_EXPRESS_CONNECTION_NAME]: stardogExpressConnection }
    : {};

  public currentPermissions: Permission[] | null = null;
}

export const SAVED_CONNECTIONS_SCHEMA = {
  type: 'object',
  additionalProperties: {
    type: 'object',
    properties: {
      // from stardog.js ConnectionOptions
      endpoint: { type: 'string' },
      username: { type: 'string' },
      password: { type: 'string' },
      token: { type: 'string' },
      meta: { type: 'object' },
      // from Connection
      name: { type: 'string' },
      proxyUrl: { type: 'string' },
      useKerberos: { type: 'boolean' },
      isSqlServerEnabled: { type: 'boolean' },
      status: {
        type: 'string',
        enum: ['VIRGIN', 'PENDING', 'SUCCESS', 'FAILURE'],
      },
      failureReason: {
        type: 'string',
        enum: ['INVALID_CREDENTIALS', 'NO_RESPONSE'],
      },
      shouldStayLoggedIn: { type: 'boolean' },
      shouldAllowSelfSignedCerts: { type: 'boolean' },
      stardogVersion: { type: 'string' },
      isPortalConnection: { type: 'boolean' },
    },
    required: [
      'endpoint',
      'username',
      'password',
      'name',
      'status',
      'shouldStayLoggedIn',
    ],
    additionalProperties: false,
  },
};
