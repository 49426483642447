import { ConnectionState } from 'src/common/store/connection/ConnectionState';
import { Preferences } from 'src/common/store/preferences/PreferencesState';
import { safelyGet } from 'src/common/utils/safelyGet';
import { InitialData } from 'src/types';

export default (data: InitialData) => {
  const oldConnection: Partial<ConnectionState> = safelyGet(
    data,
    ['connection'],
    {}
  );
  const oldPreferences: Partial<Preferences> = safelyGet(
    data,
    ['preferences'],
    {}
  );
  return {
    ...data,
    version: '1.24.0',
    preferences: {
      ...oldPreferences,
      modelsBaseIriByDatabase: {},
    },
    connection: {
      ...oldConnection,
      currentPermissions: null,
    },
  };
};
