import { MutableVirtualGraphSettings } from 'src/common/store/virtualGraphs/VirtualGraphsState';

export const filterVirtualGraphOptions = (
  options: MutableVirtualGraphSettings['properties']
) => {
  const optionsWithValues = Object.keys(options).reduce((acc, key) => {
    if (
      typeof options[key] === 'boolean' ||
      (options[key] && options[key].length > 0)
    ) {
      acc[key] = options[key];
    }
    return acc;
  }, {} as MutableVirtualGraphSettings['properties']);
  const hasTablesToInclude =
    typeof optionsWithValues['default.mapping.include.tables'] === 'string' &&
    optionsWithValues['default.mapping.include.tables'].trim().length > 0;

  if (hasTablesToInclude) {
    // If there are tables being included specifically, this takes precedence
    // over anything in `exclude`. Also, the server won't accept both (even if
    // one is empty), so we remove `exclude`.
    const { 'default.mapping.exclude.tables': _, ...filteredOptions } =
      optionsWithValues;
    return filteredOptions;
  }

  // Here, we know `include` is empty, so we remove it unconditionally.
  const { 'default.mapping.include.tables': _, ...filteredOptions } =
    optionsWithValues;
  return filteredOptions;
};
