import { isProduction } from 'src/common/utils/isProduction';

// The workers have different paths in dev vs. prod, since, in dev, they're
// served from webpack-dev-server (but not in prod).
export const getWorkerResourcePath = (filename: string) => {
  if (!isProduction(process.env.NODE_ENV)) {
    return `${filename}`;
  }

  let hashedFilename =
    window.__STARDOG_WORKER_MANIFEST__[filename] || `${filename}`;
  hashedFilename = hashedFilename.slice(hashedFilename.lastIndexOf('/') + 1);
  return hashedFilename;
};
