import { alphaSort } from 'vet-bones/bones/utils';

import {
  ConnectionAction,
  ConnectionActionType,
} from 'src/common/actions/connection/connectionActionCreators';
import {
  DataAction,
  DataActionType,
} from 'src/common/actions/data/dataActionCreators';
import { isBody } from 'src/common/actions/request/types';
import {
  VirtualGraphsAction,
  VirtualGraphsActionType,
  virtualGraphsActionCreators,
} from 'src/common/actions/virtualGraphs/virtualGraphsActionCreators';
import { LanguageId } from 'src/common/constants/LanguageId';
import { VirtualGraphOptionValueMap } from 'src/common/store/virtualGraphs/options';
import {
  VGSources,
  VirtualGraphMappingType,
  VirtualGraphSource,
} from 'src/common/store/virtualGraphs/VirtualGraphMappingType';
import {
  VirtualGraph,
  VirtualGraphsState,
} from 'src/common/store/virtualGraphs/VirtualGraphsState';
import { getDataSourceNameFromUri } from 'src/common/utils/dataSources/getDataSourceNameFromUri';
import { dedupeArray } from 'src/common/utils/dedupeArray';
import { emptyObject } from 'src/common/utils/emptyObject';
import { safelyGet } from 'src/common/utils/safelyGet';
import { getGraphNameFromUri } from 'src/common/utils/virtual_graphs/getGraphNameFromUri';
import { isPropertiesDirty } from 'src/common/utils/virtual_graphs/isPropertiesDirty';

const initialState = new VirtualGraphsState();
const alphaSorter = alphaSort();

export const virtualGraphsReducer = (
  prevState: VirtualGraphsState = initialState,
  action: VirtualGraphsAction | ConnectionAction | DataAction
): VirtualGraphsState => {
  switch (action.type) {
    // Reset virtual graphs state if a new connection is set
    case ConnectionActionType.SET_CONNECTION_SUCCESS: {
      return initialState;
    }
    case DataActionType.DELETE_DATA_SOURCE_SUCCESS: {
      const { dataSourceName } = action.payload.action;
      const removedVgIds = [];
      const vgsById = Object.keys(prevState.vgsById).reduce((acc, vgId) => {
        const vg = prevState.vgsById[vgId];
        if (vg.dataSource !== dataSourceName) {
          acc[vgId] = vg;
        } else {
          removedVgIds.push(vgId);
        }
        return acc;
      }, {});
      const vgIds = Object.keys(vgsById);

      return {
        ...prevState,
        lastKnownServerSideVgIds: vgIds,
        selectedVgId: removedVgIds.includes(prevState.selectedVgId)
          ? ''
          : prevState.selectedVgId,
        vgIds,
        vgsById,
      };
    }
    case VirtualGraphsActionType.UPDATE_VIRTUAL_GRAPHS_MOSAIC_STATE:
      return {
        ...prevState,
        mosaicState: action.payload.mosaicStateChange,
      };
    case VirtualGraphsActionType.REQUEST_MAPPINGS_FOR_VIRTUAL_GRAPH_ATTEMPT: {
      const id = action.payload.args[0] as string;
      return {
        ...prevState,
        vgsById: {
          ...prevState.vgsById,
          [id]: {
            ...prevState.vgsById[id],
            pending: true,
          },
        },
      };
    }
    case VirtualGraphsActionType.REQUEST_MAPPINGS_FOR_VIRTUAL_GRAPH_SUCCESS: {
      const id = action.payload.args[0] as string;
      const response = safelyGet(action.payload, ['response', 'body']);
      const prevVg = prevState.vgsById[id];
      return {
        ...prevState,
        vgsById: {
          ...prevState.vgsById,
          [id]: {
            ...prevVg,
            mappingDoc: response,
            isMappingDirty: false,
            pending: false,
            lastRetrievedMappingDoc: response,
            areSyntaxFeaturesDisabled:
              prevVg.areSyntaxFeaturesDisabled || false,
            wasSyntaxNotificationShown:
              prevVg.wasSyntaxNotificationShown || false,
          },
        },
      };
    }
    case VirtualGraphsActionType.REQUEST_MAPPINGS_FOR_VIRTUAL_GRAPH_FAILURE: {
      const id = action.payload.args[0] as string;
      return {
        ...prevState,
        vgsById: {
          ...prevState.vgsById,
          [id]: {
            ...prevState.vgsById[id],
            pending: false,
          },
        },
      };
    }
    case VirtualGraphsActionType.SET_MAPPINGS_DOCUMENT: {
      const { virtualGraphName, document } = action.payload;
      const virtualGraph = prevState.vgsById[virtualGraphName];

      return {
        ...prevState,
        vgsById: {
          ...prevState.vgsById,
          [virtualGraphName]: {
            ...virtualGraph,
            mappingDoc: document,
            isMappingDirty: document !== virtualGraph.lastRetrievedMappingDoc,
          },
        },
      };
    }
    case VirtualGraphsActionType.REQUEST_VIRTUAL_GRAPHS_LIST_FAILURE:
    case VirtualGraphsActionType.REQUEST_VIRTUAL_GRAPHS_LIST_INFO_FAILURE:
      return {
        ...prevState,
        pending: false,
      };
    case VirtualGraphsActionType.REQUEST_VIRTUAL_GRAPHS_LIST_SUCCESS: {
      const { virtual_graphs = [] } = action.payload?.response?.body || {};
      // Ensure that client-side-only vgs don't get erased when they fail to
      // exist in the retreived vgs from the server.
      const previousClientOnlyVgIds = prevState.vgIds.filter(
        (vgId) => prevState.lastKnownServerSideVgIds.indexOf(vgId) === -1
      );
      const receivedGraphNames = virtual_graphs.map((vgUri: string) =>
        getGraphNameFromUri(vgUri)
      );
      const vgNames = dedupeArray([
        ...previousClientOnlyVgIds,
        ...receivedGraphNames,
      ]).sort(alphaSorter);

      const nextVgsById = vgNames.reduce((acc, name) => {
        const prevVg = prevState.vgsById[name];
        return {
          ...acc,
          [name]: prevVg || {
            databaseId: '*',
          },
        };
      }, {});

      return {
        ...prevState,
        vgIds: vgNames,
        vgsById: nextVgsById,
        pending: false,
        lastKnownServerSideVgIds: receivedGraphNames,
      };
    }
    case VirtualGraphsActionType.REQUEST_VIRTUAL_GRAPHS_LIST_INFO_SUCCESS: {
      const { virtual_graphs = [] } = action.payload?.response?.body || {};
      // Ensure that client-side-only vgs don't get erased when they fail to
      // exist in the retreived vgs from the server.
      const previousClientOnlyVgIds = prevState.vgIds.filter(
        (vgId) => prevState.lastKnownServerSideVgIds.indexOf(vgId) === -1
      );
      const receivedGraphNames = virtual_graphs.map((vg) => vg.name);
      const vgNames = dedupeArray([
        ...previousClientOnlyVgIds,
        ...receivedGraphNames,
      ]).sort(alphaSorter);

      const nextVgsById = vgNames.reduce((acc, name) => {
        const prevVg = prevState.vgsById[name];
        let nextVg = prevVg;
        const vg = virtual_graphs.find((vg) => vg.name === name);
        if (!prevVg && vg) {
          nextVg = {
            databaseId: vg.database,
          };
        }
        if (nextVg && vg) {
          nextVg.dataSource = vg.data_source
            ? getDataSourceNameFromUri(vg.data_source)
            : null;
          nextVg.lastRetrievedDataSource = nextVg.dataSource;
          nextVg.isDataSourceDirty = false;
        }
        return {
          ...acc,
          [name]: nextVg,
        };
      }, {});

      return {
        ...prevState,
        vgIds: vgNames,
        vgsById: nextVgsById,
        pending: false,
        lastKnownServerSideVgIds: receivedGraphNames,
      };
    }
    // This action is plural of the case following it
    case VirtualGraphsActionType.REQUEST_OPTIONS_FOR_VIRTUAL_GRAPHS_SUCCESS: {
      return action.payload.responses.reduce((state, action) => {
        return virtualGraphsReducer(state, action as VirtualGraphsAction);
      }, prevState);
    }
    case VirtualGraphsActionType.REQUEST_OPTIONS_FOR_VIRTUAL_GRAPH_SUCCESS: {
      if (!isBody(action.payload.response)) {
        return prevState;
      }

      // get the default options for the virtual graph
      const id = action.payload.args[0] as string;

      const responseOptions = safelyGet<
        typeof action.payload,
        VirtualGraphOptionValueMap,
        typeof emptyObject
      >(action.payload, ['response', 'body', 'options'], emptyObject);

      let source: VirtualGraphSource;
      let type: VirtualGraphMappingType;
      if (responseOptions['mongodb.uri']) {
        source = VirtualGraphSource.MONGO;
        type = VirtualGraphMappingType.MONGO;
      } else if (responseOptions['cassandra.contact.point']) {
        source = VirtualGraphSource.APACHE_CASSANDRA;
        type = VirtualGraphMappingType.APACHE_CASSANDRA;
      } else if (responseOptions['elasticsearch.rest.urls']) {
        source = VirtualGraphSource.ELASTICSEARCH;
        type = VirtualGraphMappingType.ELASTICSEARCH;
      } else if (responseOptions['jdbc.url']) {
        source = VirtualGraphSource.SQL;
        const match = Object.entries(VGSources).find(([, vgSpec]) => {
          return (
            vgSpec.uriMatcher && // it's optional
            vgSpec.uriMatcher.test(responseOptions['jdbc.url'])
          );
        });
        type = match
          ? (match[0] as VirtualGraphMappingType)
          : VirtualGraphMappingType.GENERIC_SQL;
      }

      return {
        ...prevState,
        vgsById: {
          ...prevState.vgsById,
          [id]: {
            ...prevState.vgsById[id],
            id,
            source,
            type,
            // TODO: Will eventually need to support R2RML from the responseOptions
            // TODO get this from `mappings.syntax` in the vg's properties
            languageId: safelyGet(
              prevState,
              ['vgsById', id, 'languageId'],
              LanguageId.SMS2
            ),
            properties: responseOptions,
            mappingDoc: safelyGet(prevState, ['vgsById', id, 'mappingDoc'], ''),
            isPropertiesDirty: false,
            lastRetrievedProperties: responseOptions,
            areSyntaxFeaturesDisabled: false,
            wasSyntaxNotificationShown: false,
          },
        },
      };
    }
    case VirtualGraphsActionType.SET_SELECTED_VIRTUAL_GRAPH_ID: {
      return {
        ...prevState,
        selectedVgId: action.payload.virtualGraphId,
      };
    }
    case VirtualGraphsActionType.CREATE_UNSAVED_VIRTUAL_GRAPH: {
      const {
        graphName: vgId,
        source,
        sourceType: type,
        databaseId,
        dataSourceId: dataSource,
        mappingDoc = '',
        ...properties
      } = action.payload;

      return {
        ...prevState,
        vgIds: [...prevState.vgIds, vgId].sort(alphaSorter),
        vgsById: {
          ...prevState.vgsById,
          [vgId]: new VirtualGraph({
            id: vgId,
            source,
            type,
            databaseId,
            dataSource,
            properties,
            languageId: LanguageId.SMS2,
            mappingDoc,
            isLocal: true,
            isMappingDirty: true,
            isPropertiesDirty: true,
          }),
        },
      };
    }
    case VirtualGraphsActionType.UPDATE_UNSAVED_VIRTUAL_GRAPH_SETTINGS: {
      const {
        graphName: vgId,
        mappingDoc,
        source,
        sourceType: type,
        databaseId,
        dataSourceId: dataSource,
        isLocal,
        ...properties
      } = action.payload;
      const previousVgState = prevState.vgsById[vgId];

      if (!previousVgState /* creating new VG */) {
        return virtualGraphsReducer(
          prevState,
          virtualGraphsActionCreators.createUnsavedVirtualGraph(action.payload)
        );
      }

      return {
        ...prevState,
        vgsById: {
          ...prevState.vgsById,
          [vgId]: {
            ...previousVgState,
            mappingDoc:
              mappingDoc === undefined
                ? previousVgState.mappingDoc
                : mappingDoc,
            source,
            type,
            databaseId,
            dataSource,
            isLocal,
            isDataSourceDirty:
              isLocal || dataSource !== previousVgState.lastRetrievedDataSource,
            isPropertiesDirty:
              isLocal ||
              isPropertiesDirty(
                properties,
                previousVgState.lastRetrievedProperties
              ),
            properties,
          },
        },
      };
    }
    case VirtualGraphsActionType.CREATE_VIRTUAL_GRAPH_ATTEMPT: {
      // Add the unsaved VG immediately, if it doesn't exist yet; otherwise,
      // just return prevState.
      if (prevState.vgIds.includes(action.payload.action.name)) {
        return prevState;
      }

      const {
        name,
        mappings,
        options,
        source,
        sourceType,
        databaseId,
        dataSourceId,
      } = action.payload.action;
      const candidateNextState = virtualGraphsReducer(
        prevState,
        virtualGraphsActionCreators.createUnsavedVirtualGraph({
          graphName: name,
          source,
          sourceType,
          databaseId,
          dataSourceId,
          mappingDoc: mappings,
          ...options,
        })
      );

      if (!mappings) {
        // We're creating with empty mappings, which means they'll be
        // generated, so we need to set the pending flag to `true` here.
        candidateNextState.vgsById[name].pending = true;
      }

      return candidateNextState;
    }
    case VirtualGraphsActionType.CREATE_VIRTUAL_GRAPH_FAILURE: {
      const { name } = action.payload.action;
      const nextState: VirtualGraphsState = {
        ...prevState,
        vgsById: {
          ...prevState.vgsById,
        },
      };

      if (prevState.selectedVgId === name) {
        // Creation failed, but the user is currently working on this VG, so
        // don't delete the local stuff; just mark as not pending.
        nextState.vgsById[name] = {
          ...prevState.vgsById[name],
          pending: false,
        };
      } else {
        // User is _not_ working on the VG locally, but was trying to create it
        // at the get-go (e.g., maybe auto-generating mappings). Revert
        // optimistic updates.
        delete nextState.vgsById[name];
        nextState.vgIds = prevState.vgIds.filter((id) => id !== name);
      }

      return nextState;
    }
    case VirtualGraphsActionType.CREATE_VIRTUAL_GRAPH_SUCCESS: {
      const { action: actionPayloadAction, response } = action.payload;
      const {
        name,
        mappings,
        options,
        databaseId,
        dataSourceId: dataSource,
      } = actionPayloadAction;
      const nextMappings = safelyGet(
        response,
        ['body', 'mappingsString'],
        mappings
      );

      return {
        ...prevState,
        selectedVgId: name,
        vgsById: {
          ...prevState.vgsById,
          [name]: {
            ...prevState.vgsById[name],
            databaseId,
            dataSource,
            isLocal: false,
            isDataSourceDirty: false,
            isPropertiesDirty: false,
            isMappingDirty: false,
            lastRetrievedDataSource: dataSource,
            lastRetrievedMappingDoc: nextMappings,
            lastRetrievedProperties: options,
            mappingDoc: nextMappings,
            pending: false,
          },
        },
        lastKnownServerSideVgIds: [...prevState.lastKnownServerSideVgIds, name],
      };
    }
    case VirtualGraphsActionType.DELETE_VIRTUAL_GRAPH_ATTEMPT: {
      const { vgId } = action.payload;
      const nextVgIds = prevState.vgIds.filter((id) => id !== vgId);
      const { [vgId]: _, ...nextVgsById } = prevState.vgsById;

      return {
        ...prevState,
        vgIds: nextVgIds,
        selectedVgId:
          prevState.selectedVgId === vgId
            ? nextVgIds[0]
            : prevState.selectedVgId,
        vgsById: nextVgsById,
        lastKnownServerSideVgIds: prevState.lastKnownServerSideVgIds.filter(
          (id) => id !== vgId
        ),
      };
    }
    case VirtualGraphsActionType.DELETE_VIRTUAL_GRAPH_FAILURE: {
      const { deletedGraph } = action.payload;
      return {
        ...prevState,
        vgIds: [...prevState.vgIds, deletedGraph.id].sort(alphaSorter),
        vgsById: {
          ...prevState.vgsById,
          [deletedGraph.id]: deletedGraph,
        },
        lastKnownServerSideVgIds: [
          ...prevState.lastKnownServerSideVgIds,
          deletedGraph.id,
        ],
      };
    }
    case VirtualGraphsActionType.UPDATE_VIRTUAL_GRAPH_ATTEMPT: {
      const { name } = action.payload.action;

      return {
        ...prevState,
        vgsById: {
          ...prevState.vgsById,
          [name]: {
            ...prevState.vgsById[name],
            pendingUpdate: true,
          },
        },
      };
    }
    case VirtualGraphsActionType.UPDATE_VIRTUAL_GRAPH_SUCCESS: {
      const { action: actionPayloadAction, response } = action.payload;
      const { name, mappings, options, resetLocalMapping, dataSourceId } =
        actionPayloadAction;
      const nextMappings = safelyGet(
        response,
        ['body', 'mappingsString'],
        mappings
      );

      return {
        ...prevState,
        vgsById: {
          ...prevState.vgsById,
          [name]: {
            ...prevState.vgsById[name],
            isLocal: false,
            isMappingDirty: prevState.vgsById[name].mappingDoc !== nextMappings,
            isDataSourceDirty: false,
            isPropertiesDirty: false,
            lastRetrievedDataSource: dataSourceId,
            lastRetrievedMappingDoc: nextMappings,
            lastRetrievedProperties: options,
            mappingDoc: resetLocalMapping
              ? nextMappings
              : prevState.vgsById[name].mappingDoc,
            dataSource: dataSourceId,
            pendingUpdate: false,
          },
        },
        lastKnownServerSideVgIds:
          prevState.lastKnownServerSideVgIds.indexOf(name) !== -1
            ? prevState.lastKnownServerSideVgIds
            : [...prevState.lastKnownServerSideVgIds, name],
      };
    }
    case VirtualGraphsActionType.UPDATE_VIRTUAL_GRAPH_FAILURE: {
      const { name } = action.payload.action;

      return {
        ...prevState,
        vgsById: {
          ...prevState.vgsById,
          [name]: {
            ...prevState.vgsById[name],
            pendingUpdate: false,
          },
        },
      };
    }
    case VirtualGraphsActionType.SET_LANGUAGE_ID_FOR_VG: {
      const { vgId, languageId } = action.payload;

      return {
        ...prevState,
        vgsById: {
          ...prevState.vgsById,
          [vgId]: {
            ...prevState.vgsById[vgId],
            languageId,
          },
        },
      };
    }
    case VirtualGraphsActionType.RESET_VIRTUAL_GRAPHS: {
      // keep only the mosaic (UI) state -- reset everything else.
      return new VirtualGraphsState(prevState.mosaicState);
    }
    case VirtualGraphsActionType.SHOW_VIRTUAL_GRAPH_DISABLED_SYNTAX_NOTIFICATION: {
      const { vgId } = action.payload;
      const { selectedVgId } = prevState;
      if (!selectedVgId || selectedVgId !== vgId) {
        return prevState;
      }
      return {
        ...prevState,
        vgsById: {
          ...prevState.vgsById,
          [vgId]: {
            ...prevState.vgsById[vgId],
            wasSyntaxNotificationShown: true,
          },
        },
      };
    }
    case VirtualGraphsActionType.TOGGLE_VIRTUAL_GRAPH_SYNTAX_FEATURES: {
      const { areSyntaxFeaturesDisabled, vgId } = action.payload;
      const { selectedVgId } = prevState;
      if (!selectedVgId || selectedVgId !== vgId) {
        return prevState;
      }
      return {
        ...prevState,
        vgsById: {
          ...prevState.vgsById,
          [vgId]: {
            ...prevState.vgsById[vgId],
            areSyntaxFeaturesDisabled,
          },
        },
      };
    }
    default:
      return prevState;
    case VirtualGraphsActionType.SAVE_VIEW_STATE_FOR_VG: {
      const { viewState, virtualGraphUri } = action.payload;
      const virtualGraphName = getGraphNameFromUri(virtualGraphUri);
      return {
        ...prevState,
        vgsById: {
          ...prevState.vgsById,
          [virtualGraphName]: {
            ...prevState.vgsById[virtualGraphName],
            viewState,
          },
        },
      };
    }
  }
};
