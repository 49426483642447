import { defaultVisualizationSettings } from 'src/common/store/visualization/VisualizationState';
import { safelyGet } from 'src/common/utils/safelyGet';
import { InitialData } from 'src/types';

export default (data: InitialData) => {
  const oldNotebook = safelyGet(data, ['notebook'], {});
  const oldNotes = safelyGet(oldNotebook, ['notes'], {});

  // add the default visualizations to each note if there are none
  const notes = Object.keys(oldNotes).reduce((acc, noteId) => {
    const note = { ...oldNotes[noteId] };
    if (!note.visualizationSettings) {
      note.visualizationSettings = defaultVisualizationSettings();
    }
    acc[noteId] = note;
    return acc;
  }, {});

  return {
    ...data,
    version: '1.7.1',
    notebook: {
      ...oldNotebook,
      notes,
    },
  };
};
