import { Dialog } from '@blueprintjs/core';
import { autoBindMethodsForReact } from 'class-autobind-decorator';
import * as React from 'react';

import { ConfirmationDialogContent } from 'src/browser/components/ConfirmationDialogContent';
import { WithConfirmationProps } from 'src/common/components/ContextualStudioApi';

@autoBindMethodsForReact
export class Confirmation extends React.Component<
  WithConfirmationProps,
  { isOpen: boolean }
> {
  static defaultProps = {
    noPrompt: 'No',
    yesPrompt: 'Yes',
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
  }

  handleClose() {
    this.setState({ isOpen: false });
  }

  render() {
    const { isOpen } = this.state;
    const {
      title,
      message,
      onConfirmationFailure,
      onConfirmationSuccess,
      noPrompt,
      yesPrompt,
    } = this.props;
    return (
      <Dialog
        isOpen={isOpen}
        onClose={this.handleClose}
        style={{ width: 350 }}
        title={title || 'Are you sure?'}
      >
        <ConfirmationDialogContent
          message={message}
          noPrompt={noPrompt}
          onConfirmationFailure={onConfirmationFailure}
          onConfirmationSuccess={onConfirmationSuccess}
          title={null}
          yesPrompt={yesPrompt}
        />
      </Dialog>
    );
  }
}
