import { NoteStateMap } from 'src/common/store/notebook/NotebookState';
import { defaultResultSettings } from 'src/common/store/notebook/NoteState';
import { ReduxState } from 'src/types';

export const resetNoteResults = (state: ReduxState) => ({
  ...state,
  notebook: {
    ...state.notebook,
    notes: Object.keys(state.notebook.notes)
      .map((key) => ({
        key,

        value: {
          ...state.notebook.notes[key],
          resultSettings: defaultResultSettings,
        },
      }))
      .reduce(
        (acc, { key, value }) => ({ ...acc, [key]: value }),
        {}
      ) as NoteStateMap,
  },
});
