import { DatabasesActionType } from 'src/common/actions/databases/databasesActionCreators';
import { FailureResponse } from 'src/common/actions/request/types';
import {
  ActionCreatorAction,
  createTypedAction,
} from 'src/common/actions/types';
import { CreateModelDialogType } from 'src/common/store/models/ModelsState';

export const enum SchemasActionType {
  CREATE_SCHEMA_FOR_DATABASE_ATTEMPT = 'CREATE_SCHEMA_FOR_DATABASE_ATTEMPT',
  CREATE_SCHEMA_FOR_DATABASE_SUCCESS = 'CREATE_SCHEMA_FOR_DATABASE_SUCCESS',
  CREATE_SCHEMA_FOR_DATABASE_FAILURE = 'CREATE_SCHEMA_FOR_DATABASE_FAILURE',

  REMOVE_SCHEMA_FROM_DATABASE_ATTEMPT = 'REMOVE_SCHEMA_FROM_DATABASE_ATTEMPT',
  REMOVE_SCHEMA_FROM_DATABASE_SUCCESS = 'REMOVE_SCHEMA_FROM_DATABASE_SUCCESS',
  REMOVE_SCHEMA_FROM_DATABASE_FAILURE = 'REMOVE_SCHEMA_FROM_DATABASE_FAILURE',
}

export const schemasActionCreators = {
  createSchemaForDatabaseAttempt: (databaseId: string, schemaName: string) =>
    createTypedAction(SchemasActionType.CREATE_SCHEMA_FOR_DATABASE_ATTEMPT, {
      databaseId,
      schemaName,
    }),
  createSchemaForDatabaseSuccess: (
    databaseId: string,
    schemaName: string,
    dialogType: CreateModelDialogType
  ) =>
    createTypedAction(SchemasActionType.CREATE_SCHEMA_FOR_DATABASE_SUCCESS, {
      databaseId,
      schemaName,
      dialogType,
    }),
  createSchemaForDatabaseFailure: (
    databaseId: string,
    schemaName: string,
    dialogType: CreateModelDialogType,
    failureResponse?: FailureResponse<
      any,
      DatabasesActionType.UPDATE_DATABASE_DETAILS_FAILURE
    >
  ) =>
    createTypedAction(SchemasActionType.CREATE_SCHEMA_FOR_DATABASE_FAILURE, {
      databaseId,
      schemaName,
      dialogType,
      failureResponse,
    }),

  removeSchemaFromDatabaseAttempt: (databaseId: string, schemaName: string) =>
    createTypedAction(SchemasActionType.REMOVE_SCHEMA_FROM_DATABASE_ATTEMPT, {
      databaseId,
      schemaName,
    }),
  removeSchemaFromDatabaseSuccess: (databaseId: string, schemaName: string) =>
    createTypedAction(SchemasActionType.REMOVE_SCHEMA_FROM_DATABASE_SUCCESS, {
      databaseId,
      schemaName,
    }),
  removeSchemaFromDatabaseFailure: (
    databaseId: string,
    schemaName: string,
    failureResponse?: FailureResponse<
      any,
      DatabasesActionType.UPDATE_DATABASE_DETAILS_FAILURE
    >
  ) =>
    createTypedAction(SchemasActionType.REMOVE_SCHEMA_FROM_DATABASE_FAILURE, {
      databaseId,
      schemaName,
      failureResponse,
    }),
};

export type SchemasAction = ActionCreatorAction<typeof schemasActionCreators>;
