import omit from 'lodash.omit';
import uuid from 'uuid/v4';

import { safelyGet } from 'src/common/utils/safelyGet';
import { InitialData } from 'src/types';

export default (data: InitialData) => {
  const oldNotebook = safelyGet(data, ['notebook'], {});
  const oldNotes = safelyGet(oldNotebook, ['notes'], {});
  const oldVisualizations = safelyGet(data, ['visualizations'], {});
  const oldSettings = safelyGet(oldNotebook, ['settings'], {});

  const settings = { ...oldSettings };

  const notes = Object.keys(oldNotes).reduce((acc, noteId) => {
    const note = {
      ...oldNotes[noteId],
      editorSettings: {
        ...oldNotes[noteId].editorSettings,
      },
    };

    // if the note doesn't have a visualization settings id, create one
    if (!note.editorSettings.visualizationSettingsId) {
      note.editorSettings.visualizationSettingsId = uuid();
    }
    const settingsId = note.editorSettings.visualizationSettingsId;

    // if the note already has visualization settings, copy them to the settings them
    if (note.visualizationSettings) {
      settings[settingsId] = note.visualizationSettings;
    }

    acc[noteId] = omit(note, 'visualizationSettings');
    return acc;
  }, {});

  return {
    ...data,
    version: '1.10.0',
    notebook: {
      ...oldNotebook,
      notes,
    },
    visualizations: {
      ...oldVisualizations,
      settings,
    },
  };
};
