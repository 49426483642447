import { Alignment, Classes, FormGroup, Switch } from '@blueprintjs/core';
import * as React from 'react';

import { Connection } from 'src/common/store/connection/ConnectionState';

type FormOptionProps = {
  connection: Connection;
  handleTextFieldChange: (evt: React.ChangeEvent<HTMLInputElement>) => any;
  handleToggleProperty: (key: keyof Connection) => any;
};

export const FormOptions = ({
  connection,
  handleTextFieldChange,
  handleToggleProperty,
}: FormOptionProps) => {
  return (
    <>
      <FormGroup label="Username">
        <input
          className={Classes.INPUT}
          name="username"
          onChange={handleTextFieldChange}
          value={connection.username}
        />
      </FormGroup>
      <FormGroup label="Password">
        <input
          className={Classes.INPUT}
          name="password"
          onChange={handleTextFieldChange}
          type="password"
          value={connection.password}
        />
      </FormGroup>

      <FormGroup
        helperText="(The endpoint URL of your Stardog Server)"
        label="Endpoint"
      >
        <input
          className={Classes.INPUT}
          name="endpoint"
          onChange={handleTextFieldChange}
          placeholder={process.env.STARDOG_ENDPOINT}
          value={connection.endpoint}
        />
      </FormGroup>
      <div>
        <Switch
          alignIndicator={Alignment.RIGHT}
          checked={connection.shouldStayLoggedIn}
          inline
          label="Reconnect on startup: "
          onChange={() => handleToggleProperty('shouldStayLoggedIn')}
        />
      </div>
    </>
  );
};

export const SavedFormOptions = ({ connection, handleTextFieldChange }) => {
  return (
    <FormGroup helperText="(A unique name for this connection)" label="Name">
      <input
        className={Classes.INPUT}
        name="name"
        onChange={handleTextFieldChange}
        value={connection.name}
      />
    </FormGroup>
  );
};
