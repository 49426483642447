import { databasesStardogRequestDispatchers } from 'src/common/actions/databases/databasesActionCreators';
import { setPreference } from 'src/common/actions/preferences/action-creators/setPreference';
import { isBody } from 'src/common/actions/request/types';
import {
  StudioStateGetter,
  StudioThunkDispatch,
} from 'src/common/actions/StudioAction';
import {
  DCMI_ELEMENTS_TITLE_URI,
  RDFS_LABEL_URI,
} from 'src/common/constants/uris';

export const requestDatabases =
  () => async (dispatch: StudioThunkDispatch, getState: StudioStateGetter) => {
    const response =
      await databasesStardogRequestDispatchers.requestDatabases();

    if (isBody(response.payload.response)) {
      const labelPredicatesByDatabase = {
        ...getState().preferences.labelPredicatesByDatabase,
      };
      const { databases = [] } = response.payload?.response?.body || {};
      databases.forEach((dbId: string) => {
        if (!labelPredicatesByDatabase[dbId]) {
          labelPredicatesByDatabase[dbId] = [
            RDFS_LABEL_URI,
            DCMI_ELEMENTS_TITLE_URI,
          ];
        }
      });
      dispatch(
        setPreference('labelPredicatesByDatabase', labelPredicatesByDatabase)
      );
    }

    return response;
  };
