import { Button, Classes, Dialog } from '@blueprintjs/core';
import classNames from 'classnames';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import {
  DialogApi,
  StudioMessageBoxOptions,
} from 'src/common/components/ContextualStudioApi';

const unmountAndRemove = (node: HTMLElement) => {
  ReactDOM.unmountComponentAtNode(node);
  node.remove();
};

const showMessageBox = (options: StudioMessageBoxOptions) => {
  let node = document.createElement('div');
  return new Promise<{ response: number }>((resolve) => {
    const handleClose = (idx: number) => {
      unmountAndRemove(node);
      node = null;
      resolve({ response: idx });
    };
    ReactDOM.render(
      <Dialog
        canOutsideClickClose={false}
        isOpen
        onClose={() => handleClose(options.cancelId || 0)}
        style={{ width: 350 }}
        title={options.title || null}
      >
        <div className={classNames(Classes.DIALOG_BODY, options.className)}>
          <div>
            <strong>{options.message}</strong>
          </div>
          <div>{options.detail}</div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            {options.buttons.map((buttonText: string, idx: number) => (
              <Button
                key={buttonText}
                className={classNames({
                  [`${options.className}-action--${idx}`]: options.className,
                })}
                onClick={() => handleClose(idx)}
                text={buttonText}
              />
            ))}
          </div>
        </div>
      </Dialog>,
      node
    );
  });
};

export const browserDialogApi: DialogApi = {
  showMessageBox,
  showErrorBox: (title: string, content: string) =>
    showMessageBox({ message: title, detail: content, buttons: ['OK'] }),
};
