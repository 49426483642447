import { getCurrentConnectionFromPortal } from 'portal-sdk';

import {
  Connection,
  ConnectionStatus,
} from 'src/common/store/connection/ConnectionState';

export const getConnectionFromApi = async (): Promise<Connection | null> => {
  const connectionInfo = await getCurrentConnectionFromPortal();
  if (!connectionInfo) {
    return null;
  }

  return {
    ...connectionInfo,
    shouldStayLoggedIn: false,
    status: ConnectionStatus.VIRGIN,
  };
};
