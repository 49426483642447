import { Label } from 'src/common/utils/labels/types';
import { BindingResult } from 'src/common/utils/queryBuilders/shared/types';

export function getLabelFromBindingResults(
  result: BindingResult,
  labelBinding?: BindingResult
): Label {
  let label: string;
  let tag: string;
  let value: string;
  if (result.type === 'bnode') {
    // add prefix to bnodes
    value = `_:${result.value}`;
  } else if (result.datatype) {
    // key non-string literal by datatype
    label = result.value;
    value = `${result.value}-${result.datatype}`;
  } else if (result['xml:lang']) {
    // key and display language strings with the language;
    label = result.value;
    tag = result['xml:lang'];
    value = `${result.value}-${result['xml:lang']}`;
  } else {
    value = result.value;
    // get the label from the label binding, if provided
    if (labelBinding) {
      const labelResult = getLabelFromBindingResults(labelBinding);
      label = labelResult.label || labelResult.value;
      tag = labelResult.tag;
    }
  }
  return { label, tag, value };
}
