import { getAsTypedTuple } from 'src/common/utils/types/getAsTypedTuple';
import { UnwrappedArrayValueType } from 'src/common/utils/types/UnwrappedArrayValueType';

export enum LanguageId {
  // languages that interface with stardog
  // note that turtle and srs are effectively interchangeable as turtle uses the
  // srs language client, and the srs language id points to turtle components
  GRAPHQL = 'graphql',
  SHACL = 'shacl',
  SMS2 = 'sms2',
  SPARQL = 'sparql',
  SRS = 'srs',
  TRIG = 'trig',
  TURTLE = 'turtle',

  // languages that don't
  JSON = 'json',
  TXT = 'txt',
}

const languageIdAliases = {
  ...Object.keys(LanguageId).reduce(
    (acc, key) => ({
      ...acc,
      [LanguageId[key]]: LanguageId[key],
    }),
    {} as { [K in LanguageId]: K }
  ),
  srs: LanguageId.TURTLE,
};

export type LanguageIdKey = keyof typeof LanguageId;

export const availableLanguageIds: readonly LanguageIdKey[] = Object.keys(
  LanguageId
) as readonly LanguageIdKey[];

// Utility method for use with external services -- e.g., monaco-languageclient
// and the language servers -- that don't know that Studio has aliased some
// languages to others.
export const getLanguageIdAlias = (alias: LanguageId) =>
  languageIdAliases[alias];

export const languageIdsSupportingResults = getAsTypedTuple(
  LanguageId.GRAPHQL,
  LanguageId.SHACL,
  LanguageId.SPARQL,
  LanguageId.SRS,
  LanguageId.TRIG,
  LanguageId.TURTLE
);
export type ResultSupportingLanguageId = UnwrappedArrayValueType<
  typeof languageIdsSupportingResults
>;

export const queryLanguageIds = getAsTypedTuple(
  LanguageId.GRAPHQL,
  LanguageId.SPARQL
);
export type QueryLanguageId = UnwrappedArrayValueType<typeof queryLanguageIds>;

export const triplesSameSubjectLanguageIds = getAsTypedTuple(
  LanguageId.GRAPHQL,
  LanguageId.SHACL,
  LanguageId.SMS2,
  LanguageId.SPARQL,
  LanguageId.SRS,
  LanguageId.TRIG,
  LanguageId.TURTLE
);
export type TriplesSameSubjectLanguageIds = UnwrappedArrayValueType<
  typeof triplesSameSubjectLanguageIds
>;
