export namespace browserWindowApi {
  export const getWindowSize = () =>
    [window.innerWidth, window.innerHeight] as [number, number];

  export const addResizeListener = (fn: (...args: any[]) => any) => {
    window.addEventListener('resize', fn);
  };

  export const removeResizeListener = (fn: (...args: any[]) => any) => {
    window.removeEventListener('resize', fn);
  };
}
