import { FileWithHandle } from 'browser-fs-access';

import { requestReadFile } from 'src/browser/actions/fileSystem/requestReadFile';
import { browserApi } from 'src/browser/apis/browserApi';
import { FileSystemFileHandle } from 'src/browser/constants/FileSystemAccess';
import { notebookActionCreators } from 'src/common/actions/notebook/notebookActionCreators';
import { StudioThunkDispatch } from 'src/common/actions/StudioAction';
import { NoteStorageType } from 'src/common/store/notebook/NoteState';

const showErrorDialog = (dialog, message: string) =>
  dialog.showErrorBox('Failed to Read File', message);

export const openInTab =
  (file: FileWithHandle) => async (dispatch: StudioThunkDispatch) => {
    const genericErrorMessage = `Studio was unable to read the contents of ${file.name}. Please try again.`;
    let contents;
    try {
      contents = await requestReadFile({ file });
    } catch (ex) {
      showErrorDialog(
        browserApi.dialog,
        `${genericErrorMessage} (Error: ${ex.message || ex})`
      );
      return;
    }

    if (typeof contents !== 'string') {
      showErrorDialog(browserApi.dialog, genericErrorMessage);
      return;
    }

    return dispatch(
      notebookActionCreators.openInTab({
        target: file.name,
        contents,
        storageType: NoteStorageType.FILESYSTEM,
        handle: file.handle as FileSystemFileHandle,
      })
    );
  };
