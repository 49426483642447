import { HotkeyConfig } from '@blueprintjs/core';

import { ipcFacade as ipcRenderer } from 'src/browser/apis/browserIpcApi';
import { legacySupportingFileOpen } from 'src/browser/utils/legacyFileOpen';
import { IpcEventType } from 'src/common/constants/events';

export type KeyboardShortcut = {
  label: string;
  group: HotkeyConfig['group'];
  combo: string;
  onKeyDown?: HotkeyConfig['onKeyDown'];
};

const enum HotkeyGroup {
  NAVIGATION = 'Navigation',
  WORKSPACE = 'Workspace',
  HELP = 'Help',
}

export const KeyboardShortcuts: Record<
  'navigation' | 'help' | 'workspace' | 'monaco',
  HotkeyConfig[]
> = {
  navigation: [
    {
      label: 'New Tab',
      global: true,
      group: HotkeyGroup.NAVIGATION,
      combo: 'alt + mod + t',
      preventDefault: true,
      allowInInput: true,
      onKeyDown: () => {
        ipcRenderer.emit(IpcEventType.NEW_TAB_FROM_MENU);
      },
    },
    {
      // This is the only navigation shortcut that also works outside of the
      // workspace section (see `overriddenShortcuts`, below). Since the
      // override attaches globally, the handler is not provided here. Instead,
      // this item is used only for the purposes of displaying in the shortcuts
      // dialog.
      label: 'File Upload',
      global: true,
      combo: 'mod + o',
    },
    {
      label: 'Close Tab',
      global: true,
      group: HotkeyGroup.NAVIGATION,
      combo: 'alt + mod + w',
      preventDefault: true,
      allowInInput: true,
      onKeyDown: () => {
        ipcRenderer.emit(IpcEventType.CLOSE_TAB_FROM_MENU);
      },
    },
    {
      label: 'Force Close Tab',
      global: true,
      group: HotkeyGroup.NAVIGATION,
      combo: 'alt + mod + shift + w',
      preventDefault: true,
      allowInInput: true,
      onKeyDown: () => {
        ipcRenderer.emit(IpcEventType.FORCE_CLOSE_TAB_FROM_MENU);
      },
    },
    {
      label: 'Next Tab',
      global: true,
      group: HotkeyGroup.NAVIGATION,
      combo: 'alt + tab',
      preventDefault: true,
      allowInInput: true,
      onKeyDown: () => {
        ipcRenderer.emit(IpcEventType.KEYBOARD_EVENT, 'nextTab');
      },
    },
    {
      label: 'Previous Tab',
      global: true,
      group: HotkeyGroup.NAVIGATION,
      combo: 'alt + shift + tab',
      preventDefault: true,
      allowInInput: true,
      onKeyDown: () => {
        ipcRenderer.emit(IpcEventType.KEYBOARD_EVENT, 'previousTab');
      },
    },
  ],
  help: [
    {
      label: 'Show Keyboard Shortcuts',
      global: true,
      group: HotkeyGroup.HELP,
      combo: 'ctrl + shift + y',
      preventDefault: true,
      allowInInput: true,
      onKeyDown: () => ipcRenderer.emit(IpcEventType.SHOW_KEYBOARD_SHORTCUTS),
    },
    {
      label: 'Open Preferences',
      global: true,
      group: HotkeyGroup.HELP,
      combo: 'mod + ,',
      preventDefault: true,
      allowInInput: true,
      onKeyDown: () => ipcRenderer.emit(IpcEventType.OPEN_USER_PREFERENCES),
    },
    {
      label: "Show What's New",
      global: true,
      group: HotkeyGroup.HELP,
      combo: 'mod + .',
      preventDefault: true,
      allowInInput: true,
      onKeyDown: () => ipcRenderer.emit(IpcEventType.OPEN_RELEASE_NOTES),
    },
  ],
  // we want Workspace shortcuts to work both when the editor is focused,
  // and when it is not. That means that sometimes these shortcuts will
  // have to be registered both here and in the editor. For example, cmd + e
  // will execute the "current query" when the editor is focused, but will run
  // everything in the buffer (can't have a current query without cursor!) when
  // the editor is not focused.
  workspace: [
    {
      label: 'Execute Current Query',
      global: true,
      group: HotkeyGroup.WORKSPACE,
      combo: 'mod + e',
      preventDefault: true,
      stopPropagation: true,
      onKeyDown: () => {
        ipcRenderer.emit(IpcEventType.KEYBOARD_EVENT, 'executeQuery');
      },
    },
    {
      label: 'Execute Current Query',
      global: true,
      group: HotkeyGroup.WORKSPACE,
      combo: 'mod + enter',
      preventDefault: true,
      stopPropagation: true,
      onKeyDown: () => {
        ipcRenderer.emit(IpcEventType.KEYBOARD_EVENT, 'executeQuery');
      },
    },
    {
      label: 'Execute All (or Selected) Queries',
      group: HotkeyGroup.WORKSPACE,
      combo: 'mod + shift + e',
      preventDefault: true,
      stopPropagation: true,
      onKeyDown: () => {
        ipcRenderer.emit(IpcEventType.KEYBOARD_EVENT, 'executeQuery');
      },
    },
    {
      label: 'Execute All (or Selected) Queries',
      group: HotkeyGroup.WORKSPACE,
      combo: 'mod + shift + enter',
      preventDefault: true,
      stopPropagation: true,
      onKeyDown: () => {
        ipcRenderer.emit(IpcEventType.KEYBOARD_EVENT, 'executeQuery');
      },
    },
    {
      label: 'Save',
      global: true,
      group: 'Workspace',
      combo: 'mod + s',
      preventDefault: true,
      allowInInput: true,
    },
    {
      label: 'Store New Query',
      global: true,
      group: 'Workspace',
      combo: 'mod + shift + s',
      preventDefault: true,
      allowInInput: true,
    },
  ],
  // monaco keybindings are built into monaco editors. While separated here,
  // they are grouped with workspace hotkeys in the hotkeys dialog. Unlike
  // Workspace hotkeys, these won't work when the editor is not focused.
  monaco: [
    {
      label: 'Trigger Suggest',
      group: HotkeyGroup.WORKSPACE,
      combo: 'ctrl + space',
    },
    {
      label: 'Toggle Comment',
      group: HotkeyGroup.WORKSPACE,
      combo: 'mod + slash',
    },
    {
      label: 'Insert Cursor Below',
      group: HotkeyGroup.WORKSPACE,
      combo: 'alt + mod + down',
    },
    {
      label: 'Insert Cursor Above',
      group: HotkeyGroup.WORKSPACE,
      combo: 'alt + mod + up',
    },
    {
      label: 'Select all occurrences of current selection',
      group: HotkeyGroup.WORKSPACE,
      combo: 'shift + mod + l',
    },
    {
      label: 'Select all occurrences of current word',
      group: HotkeyGroup.WORKSPACE,
      combo: 'mod + f2',
    },
    {
      label: 'Delete Line',
      group: HotkeyGroup.WORKSPACE,
      combo: 'shift + mod + k',
    },
    { label: 'Indent Line', group: HotkeyGroup.WORKSPACE, combo: 'mod + ]' },
    { label: 'Outdent Line', group: HotkeyGroup.WORKSPACE, combo: 'mod + [' },
    {
      label: 'Fold Region',
      group: HotkeyGroup.WORKSPACE,
      combo: 'option + mod + [',
    },
    {
      label: 'Unfold Region',
      group: HotkeyGroup.WORKSPACE,
      combo: 'option + mod + ]',
    },
    {
      label: 'Fold All Subregions',
      group: HotkeyGroup.WORKSPACE,
      combo: 'mod + K + mod + [',
    },
    {
      label: 'Unfold all Subregions',
      group: HotkeyGroup.WORKSPACE,
      combo: 'mod + K + mod + ]',
    },
    {
      label: 'Fold all Regions',
      group: HotkeyGroup.WORKSPACE,
      combo: 'mod + K + mod + 0',
    },
    {
      label: 'Unfold all Regions',
      group: HotkeyGroup.WORKSPACE,
      combo: 'mod + K + mod + j',
    },
  ],
};

// these hotkeys need to work throughout Studio (or at least prevent
// the default behavior)
export const overriddenShortcuts: HotkeyConfig[] = [
  {
    label: 'Previous Tab',
    global: true,
    combo: 'alt + mod + w',
    preventDefault: true,
    allowInInput: true,
    onKeyDown: () => {},
  },
  {
    label: 'Save',
    global: true,
    group: 'Workspace',
    combo: 'mod + s',
    preventDefault: true,
    allowInInput: true,
    onKeyDown: () => {},
  },
  {
    label: 'File Upload',
    global: true,
    group: 'Workspace',
    combo: 'mod + o',
    preventDefault: true,
    allowInInput: true,
    onKeyDown: async () => {
      try {
        const file = await legacySupportingFileOpen();
        ipcRenderer.emit(IpcEventType.OPEN_FILE, 'open-file', file);
      } catch (err) {
        if (err && err.name !== 'AbortError') {
          console.error(err);
        }
      }
    },
  },
];
