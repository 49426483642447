import {
  QueriesAction,
  QueriesActionType,
} from 'src/common/actions/queries/queriesActionCreators';
import {
  QueryHistoryAction,
  QueryHistoryActionType,
} from 'src/common/actions/queryHistory/queryHistoryActionCreators';
import {
  QueryHistoryEntryStatus,
  QueryHistoryState,
} from 'src/common/store/queryHistory/QueryHistoryState';

const MAX_HISTORY_LENGTH = 100;

const initialState = new QueryHistoryState([], {});

export const queryHistoryReducer = (
  prevState = initialState,
  action: QueryHistoryAction | QueriesAction
): QueryHistoryState => {
  switch (action.type) {
    case QueryHistoryActionType.UPDATE_QUERY_HISTORY: {
      const { id } = action.payload;
      const nextState = {
        ...prevState,
        entryIds: prevState.entryIds.some((entryId) => entryId === id)
          ? prevState.entryIds
          : [id, ...prevState.entryIds],
        entriesById: {
          ...prevState.entriesById,
          [id]: {
            ...prevState.entriesById[id],
            ...action.payload,
          },
        },
      };

      if (nextState.entryIds.length <= MAX_HISTORY_LENGTH) {
        return nextState;
      }

      const trimmedEntries = nextState.entryIds.slice(0, MAX_HISTORY_LENGTH);
      const trimmedState = {
        ...nextState,
        entryIds: trimmedEntries,
        entriesById: trimmedEntries.reduce((acc, id) => {
          return {
            ...acc,
            [id]: nextState.entriesById[id],
          };
        }, {}),
      };

      return trimmedState;
    }
    case QueryHistoryActionType.DELETE_QUERY_HISTORY_ITEM: {
      const id = action.payload;
      const newEntriesById = {
        ...prevState.entriesById,
      };
      delete newEntriesById[id];

      return {
        ...prevState,
        entryIds: prevState.entryIds.filter((entryId) => entryId !== id),
        entriesById: newEntriesById,
      };
    }
    case QueryHistoryActionType.DELETE_QUERY_HISTORY: {
      return new QueryHistoryState([], {});
    }
    // When a query is killed, use the associated noteId to find all
    // QueryHistoryEntry items associated with that note (if any) and set all
    // PENDING items to FAILED. Fixes #276.
    case QueriesActionType.KILL_QUERY_SUCCESS: {
      const { associatedNoteId } = action.payload.action;

      if (typeof associatedNoteId === 'undefined') {
        return prevState;
      }

      const matchingQueryHistoryEntryIds = prevState.entryIds.filter(
        (id) => prevState.entriesById[id].associatedNoteId === associatedNoteId
      );
      const nextFailedHistoryEntries = matchingQueryHistoryEntryIds.reduce(
        // Switch all PENDING matching entries to FAILED.
        (acc, id) => {
          const queryHistoryEntry = prevState.entriesById[id];
          if (queryHistoryEntry.status === QueryHistoryEntryStatus.PENDING) {
            acc[id] = {
              ...queryHistoryEntry,
              status: QueryHistoryEntryStatus.FAILED,
            };
          }
          return acc;
        },
        {}
      );

      return {
        ...prevState,
        entriesById: {
          ...prevState.entriesById,
          ...nextFailedHistoryEntries,
        },
      };
    }
    case QueriesActionType.KILL_QUERIES_FAILURE:
    case QueriesActionType.KILL_QUERIES_SUCCESS: {
      const { successAssociatedNoteIds } = action.payload;

      return Object.keys(successAssociatedNoteIds).reduce(
        (accState, associatedNoteId) => {
          if (typeof associatedNoteId === 'undefined') {
            return accState;
          }

          const matchingQueryHistoryEntryIds = accState.entryIds.filter(
            (id) =>
              accState.entriesById[id].associatedNoteId === associatedNoteId
          );

          const nextFailedHistoryEntries = matchingQueryHistoryEntryIds.reduce(
            // Switch all PENDING matching entries to FAILED.
            (acc, id) => {
              const queryHistoryEntry = accState.entriesById[id];
              if (
                queryHistoryEntry.status === QueryHistoryEntryStatus.PENDING
              ) {
                acc[id] = {
                  ...queryHistoryEntry,
                  status: QueryHistoryEntryStatus.FAILED,
                };
              }
              return acc;
            },
            {}
          );

          return {
            ...accState,
            entriesById: {
              ...accState.entriesById,
              ...nextFailedHistoryEntries,
            },
          };
        },
        prevState
      );
    }
    default:
      return prevState;
  }
};
