import { safelyGet } from 'src/common/utils/safelyGet';
import { InitialData } from 'src/types';

const defaultPreferences = {
  editorFontSize: 14,
  telemetryConsent: false,
  zoomLevel: 0,
};

export default (data: InitialData) => {
  const oldPreferences = safelyGet(data, ['preferences'], {});
  const preferences = Object.keys(defaultPreferences).reduce((acc, key) => {
    return {
      ...acc,
      [key]:
        oldPreferences[key] === undefined
          ? defaultPreferences[key]
          : oldPreferences[key],
    };
  }, {});

  return {
    ...data,
    version: '1.4.0',
    preferences,
  };
};
