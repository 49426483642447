import { Button, Icon, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { autoBindMethodsForReact } from 'class-autobind-decorator';
import * as React from 'react';

import { browserApi } from 'src/browser/apis/browserApi';
import { STARDOG_EXPRESS_CONNECTION_NAME } from 'src/common/constants/configuration';
import {
  Connection,
  SavedConnection,
} from 'src/common/store/connection/ConnectionState';
import { isConnectionConnected } from 'src/common/utils/isConnectionConnected';
import { isStardogStudioWeb } from 'src/common/utils/isStardogStudioWeb';

interface ListItemProps {
  connection: SavedConnection;
  currentConnection: Connection;
  setConnection: () => any;
  editConnection: () => any;
  deleteConnection: () => any;
}

@autoBindMethodsForReact
export class ConnectionListItem extends React.Component<ListItemProps> {
  handleClickItem() {
    const { setConnection } = this.props;
    setConnection();
  }

  handleClickEdit(evt: React.MouseEvent) {
    const { editConnection } = this.props;
    evt.stopPropagation();
    editConnection();
  }

  handleClickDelete(evt: React.MouseEvent) {
    const { deleteConnection, connection } = this.props;
    evt.stopPropagation();
    browserApi.confirmation.showConfirmation({
      message: `Are you sure you want to remove ${connection.name} from My Connections?`,
      onConfirmationSuccess: deleteConnection,
    });
  }

  render() {
    const { connection, currentConnection } = this.props;
    const { name, username, endpoint } = connection;

    const isConnected =
      currentConnection.name === name &&
      isConnectionConnected(currentConnection);

    const isWebSandboxConnection =
      isStardogStudioWeb && name === STARDOG_EXPRESS_CONNECTION_NAME;

    const connectedIcon = isConnected ? (
      <Tooltip className="sd-connection-list-item-icon" content="Connected">
        <Icon
          color="#92da92"
          icon={IconNames.OFFLINE}
          style={{ marginRight: 8 }}
        />
      </Tooltip>
    ) : null;

    // Editing is allowed for all connections but the Sandbox connection on the
    // web.
    const editControl = !isWebSandboxConnection ? (
      <Tooltip className="sd-connection-list-item-icon" content="Edit">
        <Button icon={IconNames.EDIT} minimal onClick={this.handleClickEdit} />
      </Tooltip>
    ) : null;

    const connectionLabel = username ? `${username}@${endpoint}` : endpoint;

    // Deleting is allowed for all connections but the Sandbox connection on the
    // web.
    const deleteControl = !isWebSandboxConnection ? (
      <Tooltip className="sd-connection-list-item-icon" content="Delete">
        <Button
          icon={IconNames.TRASH}
          minimal
          onClick={this.handleClickDelete}
        />
      </Tooltip>
    ) : null;

    return (
      <div
        className="sd-connection-list-item sd-sidebar-list-item"
        onClick={this.handleClickItem}
      >
        {connectedIcon}
        <Tooltip
          className="sd-connection-list-item-details"
          // Note: tooltip is only used for web sandbox connection
          content="A publicly accessible and preconfigured read-only Stardog cluster"
          disabled={!isWebSandboxConnection}
        >
          <>
            <div className="sd-connection-list-item-name">{name}</div>
            <div className="sd-connection-list-item-meta">
              {connectionLabel}
            </div>
          </>
        </Tooltip>
        {editControl}
        {deleteControl}
      </div>
    );
  }
}
