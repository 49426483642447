import { ToastProps } from '@blueprintjs/core';

import { StudioActionType } from 'src/common/actions/StudioAction';
import { ActionableNotificationMessageProps } from 'src/common/components/ActionableNotificationMessage';
import { Query } from 'src/common/store/queries/QueriesState';

export interface Notification extends ToastProps {
  id: string;
  type: StudioActionType;
  data: {
    schemaName?: string;
    actionableProps?: ActionableNotificationMessageProps;
    [key: string]: any;
  };
}

export interface NotificationStateMetadata {
  queriesBeingKilledByNoteId: {
    [key: string /* NoteState['id'] */]: Query['id'];
  };
  bulkQueriesBeingKilledByNoteId: {
    [key: string /* NoteState['id'] */]: Query['id'];
  };
}

export class NotificationsState {
  constructor(
    public notifications: Notification[] = [],
    public metadata: NotificationStateMetadata = {
      queriesBeingKilledByNoteId: {},
      bulkQueriesBeingKilledByNoteId: {},
    }
  ) {}
}
