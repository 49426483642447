import { Preferences } from 'src/common/store/preferences/PreferencesState';
import { Theme } from 'src/common/utils/theme';

export const defaultPrefs: Preferences = {
  autoUpdate: true,
  editorFontSize: 14,
  editorTooltips: true,
  errorToastTimeout: 60,
  includeConstraintsInModelsHub: true,
  telemetryConsent: true,
  useSchemaForAutocompletion: true,
  useSchemaForNamedGraphs: true,
  zoomLevel: 0,
  theme: Theme.DARK,
  labelPredicatesByDatabase: {},
  modelsBaseIriByDatabase: {},
  queryLimit: 1000,
  voiceboxOverridesByDatabase: undefined,
};

export const getPreferencesWithDefaults = (
  currentPrefs: Partial<Preferences> = {}
) => {
  const newPrefs = { ...defaultPrefs };
  Object.entries(currentPrefs).forEach(([key, val]) => {
    if (val !== undefined) {
      newPrefs[key] = val;
    }
  });
  return newPrefs;
};
