import omit from 'lodash.omit';

import {
  VisualizationsAction,
  VisualizationsActionType,
} from 'src/common/actions/visualizations/visualizationsActionCreators';
import {
  VisualizationSettings,
  VisualizationsState,
} from 'src/common/store/visualization/VisualizationState';
import { removeForceGraphDataFromCache } from 'src/common/utils/caches/forceGraphData';

const initialState = new VisualizationsState();

const updateSettings = (
  prevState: VisualizationsState,
  settingsId: string,
  settings: Partial<VisualizationSettings>
): VisualizationsState => {
  return {
    ...prevState,
    settings: {
      ...prevState.settings,
      [settingsId]: {
        ...prevState.settings[settingsId],
        ...settings,
      },
    },
  };
};

const visualizationsReducer = (
  prevState = initialState,
  action: VisualizationsAction
): VisualizationsState => {
  switch (action.type) {
    case VisualizationsActionType.VISUALIZE_RESULTS_ATTEMPT: {
      const { settingsId } = action.payload;
      return updateSettings(prevState, settingsId, {
        isTransforming: true,
      });
    }
    case VisualizationsActionType.VISUALIZE_RESULTS_SUCCESS: {
      const { settingsId, settings } = action.payload;
      return updateSettings(prevState, settingsId, {
        ...settings,
        isTransforming: false,
      });
    }
    case VisualizationsActionType.VISUALIZE_RESULTS_FAILURE: {
      const { settingsId } = action.payload;
      if (!settingsId) {
        return prevState;
      }

      return updateSettings(prevState, settingsId, {
        isTransforming: false,
      });
    }

    case VisualizationsActionType.EXPORT_VISUALIZATION_TO_IMAGE_ATTEMPT: {
      const { settingsId } = action.payload;
      return updateSettings(prevState, settingsId, {
        isExporting: true,
      });
    }
    case VisualizationsActionType.EXPORT_VISUALIZATION_TO_IMAGE_SUCCESS:
    case VisualizationsActionType.EXPORT_VISUALIZATION_TO_IMAGE_FAILURE: {
      const { settingsId } = action.payload;
      return updateSettings(prevState, settingsId, {
        isExporting: false,
      });
    }

    case VisualizationsActionType.UPDATE_VISUALIZATION_SETTINGS: {
      const { settingsId, settings } = action.payload;
      return updateSettings(prevState, settingsId, settings);
    }
    case VisualizationsActionType.CLEAR_VISUALIZATION_DATA: {
      const { cacheKeys, settingsId } = action.payload;
      cacheKeys.forEach((cacheKey) => removeForceGraphDataFromCache(cacheKey));
      if (settingsId) {
        return {
          ...prevState,
          settings: omit(prevState.settings, settingsId),
        };
      }
      return prevState;
    }

    default:
      return prevState;
  }
};

export { visualizationsReducer };
