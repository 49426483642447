import { ICstVisitor } from 'chevrotain';

import { sparqlParser } from 'src/common/utils/editor/language-services/sparql/sparqlParser';

const BaseVisitor = sparqlParser.getBaseCstVisitorConstructorWithDefaults();

class PathVisitor extends BaseVisitor {
  result: string[] = [];

  constructor() {
    super();
    (this as ICstVisitor<any, any>).validateVisitor();
  }

  // tslint:disable-next-line:function-name
  PathTerminal(ctx) {
    // grabs the variables bound to the start and ends of each path segment and strips off the VAR prefix
    this.result.push(ctx.Var[0].children.VAR1[0].image.substring(1));
  }
}

export function getPathsStartAndEnd(query: string): {
  start?: string;
  end?: string;
} {
  try {
    const { cst } = sparqlParser.parse(query);
    const visitor = new PathVisitor();
    (visitor as ICstVisitor<any, any>).visit(cst);
    const [start, end] = visitor.result;
    return { start, end };
  } catch (e) {
    console.error(e);
    return { start: undefined, end: undefined };
  }
}
