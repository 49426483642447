import Bowser from 'bowser';

import { browserApi } from 'src/browser/apis/browserApi';
import { IpcEventType } from 'src/common/constants/events';
import { checkAnalytics } from 'src/common/utils/analytics';

const telemetryConsentDispatcher = (
  eventType: IpcEventType,
  data?: { telemetryConsent: boolean }
) => browserApi.ipcRenderer.emit(eventType, null, data);

const getTelemetryController = async () => {
  return {
    updateTelemetryConsent: (telemetryConsent: boolean) => {
      checkAnalytics({ telemetryConsent });

      telemetryConsentDispatcher(IpcEventType.UPDATE_TELEMETRY_CONSENT, {
        telemetryConsent,
      });
    },
    openUserPreferences: () =>
      telemetryConsentDispatcher(IpcEventType.OPEN_USER_PREFERENCES),
  };
};

const preferencesShortcut = (() => {
  let preferencesShortcut: string;
  const browserInfo = Bowser.getParser(navigator.userAgent);
  preferencesShortcut = browserInfo.getOS().name.includes('macOS')
    ? 'Ctrl+,'
    : 'Meta+,';
  return preferencesShortcut;
})();

const importApis = async () => {
  const telemetryController = await getTelemetryController();
  return {
    dialog: browserApi.dialog,
    ...telemetryController,
  };
};

// NOTE: In order avoid the cost of unnecessary promise resolutions, this
// method is memoized after the first call.
export let requestTelemetryConsent = () => {
  importApis().then(
    ({ dialog, updateTelemetryConsent, openUserPreferences }) => {
      // Memoize `requestTelemetryConsent`.
      requestTelemetryConsent = async () => {
        const options = {
          className: 'sd-telemetry-consent-alert',
          type: 'question',
          title: 'Telemetry',
          // icon: TODO,
          defaultId: 0,
          cancelId: 0,
          message: 'To improve Stardog Studio, we collect anonymous usage data',
          detail:
            'We only collect information like session duration, feature usage, and the size of queries and results. We never collect the actual content of queries or results. To opt-out, set telemetryConsent to "false" in your Preferences',
          buttons: ['OK', `Go to Preferences (${preferencesShortcut})`],
        };

        try {
          const { response } = await dialog.showMessageBox(options);
          // We always set telemetryConsent to `true` here, because we are
          // informing the user that it's on (so, we want to be sure that it
          // is).
          updateTelemetryConsent(true);
          // But, if the user's response is "go to preferences", then we do
          // that here, and they have the option of setting telemetryConsent
          // to `false` at that point.
          if (response === 1) {
            openUserPreferences();
          }
        } catch (ex) {
          // Log and eat the error. This should never actually happen, but
          // it's at least logically possible that `dialog.showMessageBox`
          // throws, and we don't want unhandled Promise rejections.
          console.error(ex);
        }
      };
      requestTelemetryConsent();
    }
  );
};
