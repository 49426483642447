import { browserApi } from 'src/browser/apis/browserApi';
import { createWebProtocolWarningMessage } from 'src/common/components/WebProtocolWarningMessage';
import { WebProtocol } from 'src/common/constants/web';
import { getProtocols } from 'src/common/utils/web/getProtocols';
import { isUrlLocalhost } from 'src/common/utils/web/isUrlLocalhost';
import { isWebProtocol } from 'src/common/utils/web/isWebProtocol';

export const warnWebProtocolInconsistencies = async (endpoint: string) => {
  const { appProtocol, endpointProtocol } = getProtocols(endpoint);
  const isEndpointLocalhost = isUrlLocalhost(endpoint);

  if (
    !isWebProtocol(appProtocol) ||
    !isWebProtocol(endpointProtocol) ||
    (appProtocol === endpointProtocol &&
      !(appProtocol === WebProtocol.HTTP && isEndpointLocalhost))
  ) {
    return;
  }

  const options = {
    type: 'question',
    defaultId: 0,
    message: createWebProtocolWarningMessage({
      appProtocol,
      endpointProtocol,
      isEndpointLocalhost,
    }),
    buttons: ['OK'],
  };

  try {
    await browserApi.dialog.showMessageBox(options);
  } catch (ex) {
    // Log and eat the error. This should never actually happen, but
    // it's at least logically possible that `dialog.showMessageBox`
    // throws, and we don't want unhandled Promise rejections.
    console.error(ex);
  }
};
