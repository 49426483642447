import { noop } from 'vet-bones/bones/utils';

import { browserApi } from 'src/browser/apis/browserApi';
import {
  StudioStateGetter,
  StudioThunkDispatch,
} from 'src/common/actions/StudioAction';
import { commaSeparatedString } from 'src/common/utils/commaSeparatedString';

export const withLeaveDataConfirmation =
  ({
    message = 'Are you sure you want to proceed?',
    noPrompt,
    onConfirmationSuccess = noop,
    onConfirmationFailure = noop,
    yesPrompt,
  }: {
    message?: string;
    noPrompt?: string;
    onConfirmationSuccess?: () => any;
    onConfirmationFailure?: () => any;
    yesPrompt?: string;
  }) =>
  async (_: StudioThunkDispatch, getState: StudioStateGetter) => {
    const { dataSourcesById } = getState().data;
    let shouldShowConfirmation = false;
    const dirtyDataSources: string[] = [];

    Object.values(dataSourcesById).forEach((dataSource) => {
      if (dataSource.isDirty) {
        dirtyDataSources.push(`"${dataSource.id}"`);
        shouldShowConfirmation = true;
      }
    });

    if (shouldShowConfirmation) {
      browserApi.confirmation.showConfirmation({
        message: `You have unsaved changes in ${commaSeparatedString(
          dirtyDataSources
        )}. ${message}`,
        noPrompt,
        onConfirmationSuccess,
        onConfirmationFailure,
        yesPrompt,
      });
    } else {
      onConfirmationSuccess();
    }
  };
