import { LanguageId } from 'src/common/constants/LanguageId';

export interface AcceptType {
  acceptType: string;
  ext: string;
}

export interface AcceptTypes {
  [fileType: string]: AcceptType;
}

export const explainAcceptTypes: AcceptTypes = {
  [LanguageId.TXT]: {
    acceptType: 'text/plain',
    ext: 'txt',
  },
};

export const booleanAcceptTypes: AcceptTypes = {
  txt: {
    acceptType: 'text/boolean',
    ext: 'txt',
  },
};

export const graphQLAcceptTypes: AcceptTypes = {
  json: {
    acceptType: 'application/json',
    ext: 'json',
  },
};

export const rdfAcceptTypes: AcceptTypes = {
  // NOTE: not found in the CLI
  'json-ld': {
    acceptType: 'application/ld+json',
    ext: 'json',
  },
  // TODO: Broken Stardog support (always returns "No value present" and fails in CLI)
  // n3: {
  //   acceptType: 'text/n3',
  //   ext: 'n3',
  // },
  'n-quads': {
    acceptType: 'application/n-quads',
    ext: 'nq',
  },
  'n-triples': {
    acceptType: 'application/n-triples',
    ext: 'nt',
  },
  // TODO: Missing stardog suppport (HTTPProtocol and ProtocolUtils.RDF_TYPES)
  // 'pretty-turtle': {
  //   acceptType: ??? (How should we differentiate this from regular turtle?)
  //   ext: 'ttl',
  // },
  'rdf/xml': {
    acceptType: 'application/rdf+xml',
    ext: 'xml',
  },
  trig: {
    acceptType: 'application/trig',
    ext: 'trig',
  },
  // TODO: Missing stardog suppport (ProtocolUtils.RDF_TYPES)
  // trix: {
  //   acceptType: 'application/trix',
  //   ext: 'trix',
  // },
  turtle: {
    acceptType: 'text/turtle',
    ext: 'ttl',
  },
};

export const sparqlAcceptTypes: AcceptTypes = {
  csv: {
    acceptType: 'text/csv',
    ext: 'csv',
  },
  // TODO: Missing stardog suppport (HTTPProtocol and ProtocolUtils.SPARQL_TYPES)
  // html: {
  //   acceptType: 'text/html',
  //   ext: 'html',
  // },
  json: {
    acceptType: 'application/sparql-results+json',
    ext: 'json',
  },
  // TODO: Missing stardog suppport (HTTPProtocol and ProtocolUtils.SPARQL_TYPES)
  // txt: {
  //   acceptType: 'text/plain',
  //   ext: 'txt',
  // },
  tsv: {
    acceptType: 'text/tab-separated-values',
    ext: 'tsv',
  },
  xml: {
    acceptType: 'application/sparql-results+xml',
    ext: 'xml',
  },
};
