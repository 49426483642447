import { STARDOG_SANDBOX_CONNECTION_NAME } from 'src/common/constants/configuration';
import {
  ConnectionStatus,
  DrawerStatus,
  SavedConnections,
} from 'src/common/store/connection/ConnectionState';
import { safelyGet } from 'src/common/utils/safelyGet';
import { InitialData, ReduxState } from 'src/types';

const pruneSavedConnectionPasswords = (state: ReduxState) => {
  const oldSavedConnections: SavedConnections = safelyGet(
    state,
    ['connection', 'saved'],
    {}
  );
  const newSavedConnections: SavedConnections = {};
  Object.keys(oldSavedConnections).forEach((connectionKey) => {
    newSavedConnections[connectionKey] = {
      ...oldSavedConnections[connectionKey],
    };
    // We will keep the password only for Stardog Sandbox, which is already
    // publicly available, per VET-589.
    if (connectionKey !== STARDOG_SANDBOX_CONNECTION_NAME) {
      delete newSavedConnections[connectionKey].password;
    }
  });

  return {
    ...state,
    connection: {
      ...state.connection,
      saved: newSavedConnections,
    },
  };
};

const resetCurrentConnectionState = (state: ReduxState) => {
  const { current: currentConnection } = state.connection;
  const nextCurrentConnectionState = {
    ...currentConnection,
    status: ConnectionStatus.VIRGIN, // We never want to save the current connection's status to disk, in the off-chance that it's PENDING and the user ends up stuck with that.
  };

  if (currentConnection.name !== STARDOG_SANDBOX_CONNECTION_NAME) {
    delete nextCurrentConnectionState.password;
  }

  return {
    ...state,
    connection: {
      ...state.connection,
      current: nextCurrentConnectionState,
      currentPermissions: null,
    },
  };
};

// Migrates drawer status and, in the browser, also removes stored passwords
// on any saved connections other than the one for Stardog Express.
export default (data: InitialData) => {
  const oldDrawerStatus = data.connection.drawerStatus;
  const dataCopy = resetCurrentConnectionState(
    pruneSavedConnectionPasswords(data as any)
  );
  dataCopy.connection = {
    ...dataCopy.connection,
    drawer: {
      selectedConnectionName: '',
      status: oldDrawerStatus || DrawerStatus.CLOSED,
    },
  };
  // @ts-ignore
  delete dataCopy.connection.drawerStatus;

  return {
    ...dataCopy,
    version: '1.34.0',
  };
};
