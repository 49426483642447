import * as React from 'react';
import { isPortal } from 'vet-bones/bones/utils/portal';

import { StandAloneLoginDialog } from 'src/common/containers/Login//StandaloneLoginDialog';
import { PortalLoginDialog } from 'src/common/containers/Login/PortalLoginDialog';

export const LoginDialog: React.VFC = () => {
  if (isPortal()) {
    return <PortalLoginDialog />;
  }

  return <StandAloneLoginDialog />;
};
