import {
  ALL_GRAPHS,
  BLOCKED_NAMED_GRAPHS,
} from 'src/common/constants/namedGraphs';
import { NAMED_GRAPHS_REQUEST_LIMIT } from 'src/common/constants/queries';

/**
 * Determines the available named graphs for a given database.
 * Named graphs in the aliases graph and sql schema graphs are filtered out
 *
 * **Call Frequency**
 *
 * This query is called
 * 1) when the selected database for a SPARQL query changes in the workspace to populate ng selector
 * 2) when a database is selected in the Models tab
 * 3) when a database is selected in the Databases tab 
 *
 *
 * **Example**
 * ```sparql
# Named Graphs
SELECT DISTINCT ?graph 
FROM NAMED <tag:stardog:api:context:all>
WHERE {
  graph ?graph { } 
  filter (?graph NOT IN (<tag:stardog:api:graph:aliases>,<tag:stardog:api:sql:schema>))
}
LIMIT 1000
 * ```
 */

export const namedGraphsQuery = `# Named Graphs
SELECT DISTINCT ?graph 
FROM NAMED <${ALL_GRAPHS}>
WHERE {
  graph ?graph { } 
  filter (?graph NOT IN (${BLOCKED_NAMED_GRAPHS.map((ng) => `<${ng}>`).join(
    ','
  )}))
}
LIMIT ${NAMED_GRAPHS_REQUEST_LIMIT}`;
