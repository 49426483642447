import capitalize from 'lodash.capitalize';
import {
  CloseAction,
  ErrorAction,
  MonacoLanguageClient,
  createConnection,
} from 'monaco-languageclient';
import { createMessageConnection } from 'vscode-jsonrpc';
import {
  BrowserMessageReader,
  BrowserMessageWriter,
} from 'vscode-jsonrpc/browser';

import { LanguageId } from 'src/common/constants/LanguageId';
import { sparqlExtensions } from 'src/common/utils/fileExtensions';
import { getWorkerResourcePath } from 'src/common/utils/rpc/getWorkerResourcePath';
import { UnwrappedArrayValueType } from 'src/common/utils/types/UnwrappedArrayValueType';

// NOTE: This map is the single source of truth for all types and values in
// this file. If you add a language here, it'll be automatically included in
// the relevant values and types throughout the rest of this file, and will
// become available for use as an activated language client.
const documentSelectorsByLanguageId = {
  [LanguageId.GRAPHQL]: ['graphql'],
  [LanguageId.SHACL]: ['shacl'],
  [LanguageId.SMS2]: ['sms2'],
  [LanguageId.SPARQL]: sparqlExtensions.fileExtensions,
  [LanguageId.TRIG]: ['trig'],
  [LanguageId.TURTLE]: ['turtle', 'ttl'],
};

const languagesWithClients = Object.keys(
  documentSelectorsByLanguageId
) as Array<
  {
    [K in keyof typeof documentSelectorsByLanguageId]: K;
  }[keyof typeof documentSelectorsByLanguageId]
>;

type LanguageIdWithClient = UnwrappedArrayValueType<
  typeof languagesWithClients
>;

const clientsByLanguageId = languagesWithClients.reduce<
  Record<string, MonacoLanguageClient>
>(
  (clientManager, languageId) => ({
    ...clientManager,
    [languageId]: null,
  }),
  {} as { [K in LanguageIdWithClient]: MonacoLanguageClient }
);

const createBaseLanguageClient = ({
  documentSelector,
  name,
  worker,
}: {
  documentSelector: MonacoLanguageClient.Options['clientOptions']['documentSelector'];
  name: MonacoLanguageClient.Options['name'];
  worker: Worker;
}) => {
  const reader = new BrowserMessageReader(worker);
  const writer = new BrowserMessageWriter(worker);

  const messageConnection = createMessageConnection(reader, writer);

  return new MonacoLanguageClient({
    name,
    clientOptions: {
      documentSelector,
      // disable the default error handler
      errorHandler: {
        error: () => ErrorAction.Continue,
        closed: () => CloseAction.DoNotRestart,
      },
      // All language servers should run in 'stardog' mode (where applicable)
      initializationOptions: {
        mode: 'stardog',
      },
    },
    connectionProvider: {
      get: (errorHandler, closeHandler) =>
        Promise.resolve(
          createConnection(messageConnection, errorHandler, closeHandler)
        ),
    },
  });
};

export const getActivatedLanguageClient = (languageId: LanguageId) => {
  if (!languagesWithClients.some((id) => id === languageId)) {
    // This language does not have a language client.
    return;
  }

  if (!clientsByLanguageId[languageId]) {
    const client = createBaseLanguageClient({
      name: `${capitalize(languageId)} Language Client`,
      documentSelector: documentSelectorsByLanguageId[languageId],
      worker: new Worker(
        getWorkerResourcePath(`${languageId}LanguageServer.js`)
      ),
    });
    client.start();
    clientsByLanguageId[languageId] = client;
  }

  return clientsByLanguageId[languageId];
};
