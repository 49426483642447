import { processTestIds } from 'vet-bones/bones/utils';

import { StudioActionType } from 'src/common/actions/StudioAction';
import { LanguageId } from 'src/common/constants/LanguageId';
import { ModelsTabIds } from 'src/common/store/models/ModelsState';
import { isProduction } from 'src/common/utils/isProduction';

const getTestId = (id: string, idx?: number) =>
  isProduction(process.env.NODE_ENV) ? `${idx}` : id;

export const getDatabaseListItemTestId = (id: string, idx?: number) =>
  `sd-databases-list-item-${getTestId(id, idx)}`;

export const getNamedGraphListItemTestId = (id: string, idx?: number) =>
  `sd-named-graph-list-item-${getTestId(id, idx)}`;

export const getModelsSectionTabTestId = (tabId: ModelsTabIds) =>
  `sd-models-section-tabs-${tabId}`;

export const getNotebookControlBarDatabaseSelectorItemTestId = (
  id: string,
  idx?: number
) => `sd-notebook-control-bar-database-selector-item-${getTestId(id, idx)}`;

export const getNotebookControlBarNamedGraphSelectorItemTestId = (
  id: string,
  idx?: number
) => `sd-notebook-control-bar-named-graph-selector-item-${getTestId(id, idx)}`;

export const getNotificationTestId = (action: StudioActionType | string) =>
  `sd-notification-${action}`;

export const getQueryHistoryListItemTestId = (
  timeStamp: number,
  idx?: number
) => `sd-query-history-list-item-${getTestId(`${timeStamp}`, idx)}`;

export const getSecuritySidebarUserItemTestId = (
  username: string,
  idx?: number
) => `sd-security-sidebar-user-item-${getTestId(username, idx)}`;

export const getSecuritySidebarRoleItemTestId = (
  rolename: string,
  idx?: number
) => `sd-security-sidebar-role-item-${getTestId(rolename, idx)}`;

export const getSensitivePropertyGroupActionsTestId = (
  group: string,
  idx?: number
) => `sd-sensitive-property-group-actions-${getTestId(group, idx)}`;

export const getSensitivePropertyGroupNameTestId = (
  group: string,
  idx?: number
) => `sd-sensitive-property-group-name-${getTestId(group, idx)}`;

export const getSensitivePropertyGroupPropertyMenuItemTestId = (
  property: string,
  idx?: number
) =>
  `sd-sensitive-property-group-property-menu-item-${getTestId(property, idx)}`;

export const getSensitivePropertyGroupPropertyTagTestId = (
  property: string,
  idx?: number
) => `sd-sensitive-property-group-property-tag-${getTestId(property, idx)}`;

export const getStatusBarLanguageMenuItemTestId = (languageId: LanguageId) =>
  `sd-status-bar-language-menu-item-${languageId}`;

export const getStoredQueryDatabaseSelectorListItemTestId = (
  name: string,
  idx?: number
) => `sd-stored-query-database-selector-list-item-${getTestId(name, idx)}`;

export const getStoredQueryListItemTestId = (name: string, idx?: number) =>
  `sd-stored-query-list-item-${getTestId(name, idx)}`;

export const getVirtualGraphsListItemTestId = (id: string, idx?: number) =>
  `sd-virtual-graphs-list-item-${getTestId(id, idx)}`;

export const TEST_IDS = {
  // global
  confirmationDialog: {
    noButton: '',
    yesButton: '',
  },
  connect: {
    overlay: '',
  },
  connectionForm: {
    cancelButton: '',
    submitButton: '',
  },
  fileDialog: {
    trigger: '',
    triggerInput: '',
  },
  notification: {
    errorCopyButton: '',
  },
  portal: {
    connectButton: '',
    connectMenu: '',
    loginDialog: '',
  },
  standalone: {
    connectButton: '',
    loginDialog: '',
  },
  tooltip: {
    content: '',
  },

  // containers
  cloudRedirectBanner: {
    cloudLink: '',
    collapseButton: '',
    content: '',
    infoLink: '',
  },
  constraints: {
    validateButton: '',
  },
  createDatabaseDialog: {
    config: '',
  },
  data: {
    button: {
      addButton: '',
      moreOptionsButton: '',
      removeButton: '',
    },
    saveButton: '',
  },
  databasesManager: {
    panel: '',
    panelAdminDropDatabaseButton: '',
    properties: {
      sensitiveProperties: {
        group: {
          addActionsSection: '',
          addButton: '',
          addNameSection: '',
          createNewItem: '',
          nameInput: '',
          removeButton: '',
        },
      },
    },
    saveButton: '',
    rdfFileDialog: {
      contentTypeSelector: '',
      namedGraphSelector: '',
      namedGraphSelectorClear: '',
    },
    sidebar: '',
    sidebarCreateButton: '',
  },
  export: {
    databaseButton: '',
    databaseDialog: '',
    fileFormatSelector: '',
    namedGraphSelector: '',
    submitButton: '',
  },
  model: {
    saveButton: '',
  },
  notebook: {
    controlBar: {
      container: '',
      databaseSelector: '',
      executeButton: '',
      explain: {
        button: '',
        caretButton: '',
      },
      namedGraph: {
        selector: '',
        selectorClearButton: '',
      },
      profiler: {
        menuButton: '',
        stopButton: '',
      },
      reasoningToggle: '',
      save: {
        existingFileButton: '',
        existingStoredQueryButton: '',
        newFileButton: '',
      },
      searchButton: '',
      searchForm: '',
    },
    newTabButton: '',
    pane: '',
    tab: '',
    tabButton: {
      fileUploadItem: '',
      newTabItem: '',
    },
    turtleControlBar: {
      constraintsButton: '',
      container: '',
    },
  },
  noteResult: {
    controlBarCollapseButton: '',
    explain: {
      exportButton: '',
      textButton: '',
      visualButton: '',
    },
    runToFileButton: '',
  },
  queryConfig: {
    menu: '',
    menuTimeout: '',
  },
  resultViewManager: {
    explainResult: '',
    pathEmptyResult: '',
    pathResult: '',
    rdfResult: '',
    rdfResultToggle: {
      textButton: '',
      visualButton: '',
    },
    selectResult: '',
    visualResult: '',
  },
  security: {
    roleHeader: '',
    userHeader: '',
    userRoleTag: '',
  },
  starchart: {
    dashboard: '',
    landing: '',
    line: '',
    source: '',
    sources: '',
    system: {
      center: '',
      class: '',
      classCircle: '',
      container: '',
    },
  },
  statusBar: {
    connection: '',
    container: '',
    keyboardShortcutButton: '',
    languageMenu: {
      button: '',
      interior: '',
    },
  },
  storedQuery: {
    databaseSelector: '',
    deleteMenuItem: '',
    filterableQueries: {
      container: '',
      input: '',
    },
    popover: {
      button: '',
      formSubmitButton: '',
      nameInput: '',
    },
    refreshButton: '',
  },
  virtualGraphs: {
    saveButton: '',
    sidebar: {
      addButton: '',
      container: '',
      filterableVgs: '',
      refreshButton: '',
    },
  },
  visualization: {
    bottomOverlay: {
      container: '',
      menuButton: '',
    },
    colorPill: '',
    focusedGroup: {
      captionSelector: '',
      container: '',
      visualizationSwitch: '',
    },
    focusedLink: '',
    focusedNode: '',
    info: '',
    linkPill: '',
    nodePill: '',
    performanceAlertDialog: '',
    propertyPill: '',
    redrawMenu: {
      container: '',
      item: '',
    },
    saveMenu: {
      container: '',
      item: '',
    },
    settingsMenu: {
      container: '',
      performanceInfoIcon: '',
    },
    topBar: {
      container: '',
      selectPill: '',
    },
  },
};

processTestIds(TEST_IDS, '-', 'sd');
