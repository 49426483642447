import omit from 'lodash.omit';

import { executeSelectQueryForNote as executeQuery } from 'src/browser/actions/notebook/action-creators/stardog-api/executeQueryForNote/select';
import { browserApi } from 'src/browser/apis/browserApi';
import { registerCacheFetchListener } from 'src/common/actions/notebook/action-creators/fetchRowFromCache';
import { notebookActionCreators } from 'src/common/actions/notebook/notebookActionCreators';
import { IpcEventType } from 'src/common/constants/events';
import { ReturnData, StardogQueryMethodData } from 'src/common/utils/rpc/types';

const ipcResponseMap = new Map<
  string,
  {
    resolve: Function;
    reject: Function;
  }
>();

export const registerSelectListeners = (dispatch) => {
  registerCacheFetchListener(dispatch);

  // NOTE: This event is only dispatched for Stardog < 7.7.2. On later
  // versions of Stardog, the queryId is generated by Studio.
  browserApi.ipcRenderer.on(
    IpcEventType.QUERY_RESPONSE_START,
    (_, { noteId, queryId }) => {
      dispatch(notebookActionCreators.setQueryIdForNote(queryId, noteId));
    }
  );

  browserApi.ipcRenderer.on(IpcEventType.QUERY_RESULTS, (_, data) => {
    if (ipcResponseMap.has(data.noteId)) {
      ipcResponseMap.get(data.noteId).resolve(data);
    }
  });
  browserApi.ipcRenderer.on(IpcEventType.QUERY_FAILURE, (_, data) => {
    if (ipcResponseMap.has(data.noteId)) {
      ipcResponseMap.get(data.noteId).reject(data.error);
    }
  });
};

const makeGetResponseIpc = () => (options: StardogQueryMethodData) =>
  new Promise<ReturnData>((resolve, reject) => {
    ipcResponseMap.set(options.noteId, { resolve, reject });
    browserApi.ipcRenderer.send(
      IpcEventType.EXECUTE_SELECT_QUERY,
      omit(options, 'onResponseStart') // never send functions
    );
  });

export const executeSelectQueryForNote = async (
  options: StardogQueryMethodData,
  dispatch
) => executeQuery(options, dispatch, makeGetResponseIpc());
