/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
import * as semver from 'semver';

import { InitialData, PersistedData } from 'src/types';

export const transforms = {
  '1.0.0': require('src/common/store/migrate/1.0.0').default,
  '1.4.0': require('src/common/store/migrate/1.4.0').default,
  '1.5.0': require('src/common/store/migrate/1.5.0').default,
  '1.7.1': require('src/common/store/migrate/1.7.1').default,
  '1.10.0': require('src/common/store/migrate/1.10.0').default,
  '1.11.0': require('src/common/store/migrate/1.11.0').default,
  '1.12.0': require('src/common/store/migrate/1.12.0').default,
  '1.14.0': require('src/common/store/migrate/1.14.0').default,
  '1.15.0': require('src/common/store/migrate/1.15.0').default,
  '1.16.0': require('src/common/store/migrate/1.16.0').default,
  '1.22.0': require('src/common/store/migrate/1.22.0').default,
  '1.24.0': require('src/common/store/migrate/1.24.0').default,
  '1.25.0': require('src/common/store/migrate/1.25.0').default,
  '1.29.0': require('src/common/store/migrate/1.29.0').default,
  '1.34.0': require('src/common/store/migrate/1.34.0').default,
  '1.34.1': require('src/common/store/migrate/1.34.1').default,
  '1.34.2': require('src/common/store/migrate/1.34.2').default,
  '2.4.1': require('src/common/store/migrate/2.4.1').default,
  '2.4.4': require('src/common/store/migrate/2.4.4').default,
  '3.4.0': require('src/common/store/migrate/3.4.0').default,
  '3.5.0': require('src/common/store/migrate/3.5.0').default,
  '3.8.0': require('src/common/store/migrate/3.8.0').default,
  '3.9.6': require('src/common/store/migrate/3.9.6').default,
  '3.10.6': require('src/common/store/migrate/3.10.6').default,
  // '2.0.0': (data: ReturnType<typeof transforms['1.x.x']>) => data,
};

// sort all the transform functions by ascending semantic version
export const transformIds = Object.keys(transforms).sort(semver.compare);

// given a versioned state object, find the first transform function of a higher version range than the state object's version
export const findFirstTransformIdx = (dataVersion) => {
  return transformIds.findIndex((transformVersion) =>
    semver.lt(dataVersion, transformVersion)
  );
};

export function migrate(initial: InitialData): PersistedData {
  // find first relevant transform
  const firstTransformIdx = findFirstTransformIdx(initial.version);

  if (firstTransformIdx < 0) {
    // state is up-to-date
    return initial;
  }

  // create array of transforms to perform (everything after and including the first transform)
  const itinerary = transformIds.slice(firstTransformIdx);

  // push initial data through all transforms
  const final = itinerary.reduce(
    (state: PersistedData, transformId: string) => {
      const transform = transforms[transformId];
      const transformedState = transform(state);
      return {
        ...transformedState,
        version: transformId,
      };
    },
    initial
  );

  return final;
}
