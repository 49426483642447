import { safelyGet } from 'src/common/utils/safelyGet';
import { InitialData } from 'src/types';

const defaultOldConnectionState = {
  username: 'admin',
  password: 'admin',
  proxyUrl: '',
  endpoint: 'http://localhost:5820',
  isConnected: false,
  shouldStayLoggedIn: false,
  shouldAllowSelfSignedCerts: false,
  useKerberos: false,
  status: 'VIRGIN',
  drawerStatus: 'CLOSED',
};

export default (data: InitialData) => {
  const oldConnection = safelyGet(
    data,
    ['connection'],
    defaultOldConnectionState
  );
  const { drawerStatus, ...currentConnection } = oldConnection;
  const connection = {
    drawerStatus,
    current: {
      ...currentConnection,
      name: '',
    },
    savedNames: [],
    saved: {},
  };

  return {
    ...data,
    version: '1.5.0',
    connection,
  };
};
