import RollbarType from 'rollbar';

// Rollbar says that data.body must have exactly one of the properties: 'trace',
// 'trace_chain', 'message', or 'crash_report'.
const mutuallyExclusiveBodyProperties = [
  'trace',
  'trace_chain',
  'message',
  'crash_report',
];
const nonTraceExclusiveBodyProperties =
  mutuallyExclusiveBodyProperties.slice(1);

const payloadTransform = (payload: any = {}) => {
  /* eslint-disable no-param-reassign */
  payload.body = payload.body || {};

  if (
    Object.keys(payload.body).some((key) =>
      nonTraceExclusiveBodyProperties.includes(key)
    )
  ) {
    // Do not add a 'trace' property to body if one of the other excluding
    // properties already exists.
    return;
  }

  payload.body.trace = payload.body.trace || {};
  payload.body.trace.frames = payload.body.trace.frames || [];

  // wipe all filenames from the payload, since sensitive info may be in the paths
  payload.body.trace.frames.forEach((frame) => {
    frame.filename = '';
  });
  /* eslint-enable no-param-reassign */
};

const config: RollbarType.Configuration = {
  accessToken: process.env.ROLLBAR_ACCESS_TOKEN,
  autoInstrument: {
    network: false, // prevent sending URLs with potentially sensitive data
    log: false, // prevent sending logs with potentially sensitive state data
    dom: false,
    navigation: true,
    connectivity: true,
  },
  captureIp: false,
  captureUncaught: true,
  captureUnhandledRejections: true,
  code_version: process.env.APP_VERSION,
  payload: {
    environment: process.env.NODE_ENV,
  },
  transform: payloadTransform,
};

let rollbar: RollbarType | undefined;
let loadingPromise: Promise<void> | undefined;

export const enableRollbar = () => {
  if (rollbar) {
    rollbar.configure({ enabled: true });
  } else if (loadingPromise) {
    loadingPromise = loadingPromise.then(() => {
      rollbar.configure({ enabled: true });
    });
  } else {
    loadingPromise = import('rollbar').then(({ default: Rollbar }) => {
      loadingPromise = undefined;
      rollbar = new Rollbar(config);
    });
  }
};

export const disableRollbar = () => {
  if (loadingPromise) {
    loadingPromise = loadingPromise.then(() => {
      rollbar.configure({ enabled: false });
    });
  } else if (rollbar) {
    rollbar.configure({ enabled: false });
  }
};
