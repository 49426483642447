import { Validation } from 'src/common/components/GenericPropertiesEditor';
import { FlatMosaicParent } from 'src/common/components/MosaicViewManager';
import {
  CassandraOptionValueMap,
  MongoOptionValueMap,
  SqlOptionValueMap,
} from 'src/common/store/virtualGraphs/options';
import {
  VirtualGraphMappingType,
  VirtualGraphSource,
} from 'src/common/store/virtualGraphs/VirtualGraphMappingType';

export const enum DataTileIds {
  SIDEBAR = 'SIDEBAR',
  VIEW = 'VIEW',
}

export interface DataMosaicState {
  TOP: FlatMosaicParent<DataTileIds.SIDEBAR | DataTileIds.VIEW>;
  SIDEBAR: FlatMosaicParent<DataTileIds.SIDEBAR>;
}

export const defaultMosaicState: DataMosaicState = {
  TOP: {
    direction: 'row',
    first: DataTileIds.SIDEBAR,
    second: DataTileIds.VIEW,
    splitPercentage: 20,
  },
  SIDEBAR: {
    direction: 'row',
    first: DataTileIds.SIDEBAR,
    second: DataTileIds.SIDEBAR,
    splitPercentage: 20,
  },
};

export type DataMosaicStateChange =
  | {
      TOP: DataMosaicState['TOP'];
    }
  | {
      SIDEBAR: DataMosaicState['SIDEBAR'];
    };

export class DataSource {
  public id: string;

  public sharable: boolean;

  public available: boolean;

  public pending: boolean;

  public pendingUpdate: boolean;

  public source: VirtualGraphSource;

  public type: VirtualGraphMappingType;

  public lastRetrievedProperties:
    | Partial<SqlOptionValueMap>
    | Partial<MongoOptionValueMap>
    | Partial<CassandraOptionValueMap>;

  public localSource: VirtualGraphSource;

  public localType: VirtualGraphMappingType;

  public localProperties:
    | Partial<SqlOptionValueMap>
    | Partial<CassandraOptionValueMap>
    | Partial<MongoOptionValueMap>;

  public isDirty = true;

  public validationErrors: { [index: string]: Validation } = {};

  public missingFieldNames: string[] = [];

  constructor(initialConfig: Partial<DataSource>) {
    Object.keys(initialConfig).forEach((key) => {
      this[key] = initialConfig[key];
    });
  }
}

export type MutableDataSourceSettings = Pick<
  DataSource,
  'localSource' | 'localType' | 'localProperties'
>;

export type DataSourceListInfoObject = {
  entityName: string;
  sharable: boolean;
  available: boolean;
};

export type VirtualGraphsByDataSource = { [id: string]: string[] };

export class DataState {
  constructor(
    public mosaicState: DataMosaicState = defaultMosaicState,
    public pending: boolean = false,
    public pendingCreate: boolean = false,
    public selectedDataSourceId = '',
    public dataSourceIds: readonly string[] = [],
    public dataSourcesById: Record<string, Partial<DataSource>> = {},
    public vgsByDataSourceId: VirtualGraphsByDataSource = {}
  ) {}
}
