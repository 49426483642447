/* eslint-disable import/no-default-export */
import { Preferences } from 'src/common/store/preferences/PreferencesState';
import { safelyGet } from 'src/common/utils/safelyGet';
import { InitialData } from 'src/types';

export default (data: InitialData) => {
  const oldPreferences: Partial<
    Preferences & { defaultQueryPlanFormat: string }
  > = safelyGet(data, ['preferences'], {});
  const newQueryPlanFormat =
    oldPreferences.defaultQueryPlanFormat === 'json'
      ? 'visual'
      : oldPreferences.defaultQueryPlanFormat;
  return {
    ...data,
    version: '1.11.0',
    preferences: {
      ...oldPreferences,
      defaultQueryPlanFormat: newQueryPlanFormat,
    },
  };
};
