import { isStardogStudioWeb } from 'src/common/utils/isStardogStudioWeb';
import { safelyGet } from 'src/common/utils/safelyGet';
import { InitialData } from 'src/types';

const EXPRESS_CONNECTION_NAME = 'Stardog Express';

// this object is different from the express connection used in future versions
const oldStardogExpressConnection = {
  name: EXPRESS_CONNECTION_NAME,
  username: 'anonymous',
  password: 'anonymous',
  endpoint: 'https://express.stardog.cloud:5820',
  proxyUrl: '',
  shouldStayLoggedIn: false,
  shouldAllowSelfSignedCerts: false,
  useKerberos: false,
  status: 'VIRGIN',
  isConnected: false,
};

// After the switch to IndexedDB, in v2.1.0, the way that initial state and
// migrations are handled changed, so that new app users get the full initial
// state (with an up-to-date version number) rather than an incomplete initial
// state with a '0.0.1' version number that is then migrated. This caused the
// Stardog Express connection not to be included in the state for new users of
// stardog.studio. This migration restores the Express connection for those
// users. (As of v2.4.1, new users of stardog.studio _will_ have the Stardog
// Express connection in their app state by default, pre-migration.)
export default (data: InitialData) => {
  if (!isStardogStudioWeb) {
    return data;
  }

  const prevSavedConnections = safelyGet(data, ['connection', 'saved'], {});
  const prevSavedConnectionNames = safelyGet(
    data,
    ['connection', 'savedNames'],
    []
  );

  const nextSavedConnections = {
    ...prevSavedConnections,
    [EXPRESS_CONNECTION_NAME]: oldStardogExpressConnection,
  };
  const nextSavedConnectionNames = [
    EXPRESS_CONNECTION_NAME,
    ...prevSavedConnectionNames.filter(
      (connectionName) => connectionName !== EXPRESS_CONNECTION_NAME
    ),
  ];

  return {
    ...data,
    connection: {
      ...data.connection,
      saved: nextSavedConnections,
      savedNames: nextSavedConnectionNames,
    },
    version: '2.4.1',
  };
};
