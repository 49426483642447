import {
  STARDOG_EXPRESS_CONNECTION_NAME,
  STARDOG_EXPRESS_PASSWORD,
} from 'src/common/constants/configuration';
import { initialState } from 'src/common/reducers/connection';
import { stardogExpressConnection } from 'src/common/store/connection/ConnectionState';
import { isStardogStudioWeb } from 'src/common/utils/isStardogStudioWeb';
import { safelyGet } from 'src/common/utils/safelyGet';
import { InitialData } from 'src/types';

// Restores erroneously deleted Stardog Express password on stardog.studio.
// Yes, this migration is functionally the same as the 1.34.1 migration. The
// 1.34.1 migration restored the Stardog Express password, but `getPruned` was
// in a state that caused it to be removed again on save. So, 1.34.2
// unfortunately needs to restore it again.
export default (data: InitialData) => {
  if (!isStardogStudioWeb) {
    return data;
  }

  const prevSavedExpressConnection = safelyGet(
    data,
    ['connection', 'saved', STARDOG_EXPRESS_CONNECTION_NAME],
    stardogExpressConnection
  );
  const nextSavedExpressConnection = {
    ...prevSavedExpressConnection,
    password: STARDOG_EXPRESS_PASSWORD,
  };

  // Since this adds a saved Express connection in the (should-be-impossible)
  // case where it doesn't already exist, we also ensure consistency with
  // savedNames:
  const prevSavedConnectionNames = safelyGet(
    data,
    ['connection', 'savedNames'],
    []
  );
  const nextSavedConnectionNames = prevSavedConnectionNames.includes(
    STARDOG_EXPRESS_CONNECTION_NAME
  )
    ? prevSavedConnectionNames
    : [STARDOG_EXPRESS_CONNECTION_NAME, ...prevSavedConnectionNames];

  // And if the current connection is to Stardog Express, we update it there
  // as well.
  const prevCurrentConnection = safelyGet(
    data,
    ['connection', 'current'],
    initialState.current
  );
  const nextCurrentConnection =
    prevCurrentConnection.name === STARDOG_EXPRESS_CONNECTION_NAME
      ? {
          ...prevCurrentConnection,
          password: STARDOG_EXPRESS_PASSWORD,
        }
      : prevCurrentConnection;

  return {
    ...data,
    connection: {
      ...data.connection,
      current: nextCurrentConnection,
      saved: {
        ...data.connection.saved,
        [STARDOG_EXPRESS_CONNECTION_NAME]: nextSavedExpressConnection,
      },
      savedNames: nextSavedConnectionNames,
    },
    version: '1.34.2',
  };
};
