import { SavedConnections } from 'src/common/store/connection/ConnectionState';

const doubleQuoteRegex = /"/g;

export const savedConnectionsToCsv = (connections: SavedConnections) =>
  [
    ['Name', 'Username', 'Password', 'Endpoint'],
    ...Object.values(connections).map(
      ({ name, username, password, endpoint }) => [
        name,
        username,
        password,
        endpoint,
      ]
    ),
  ]
    .map((row) =>
      row.reduce<string>((acc, item) => {
        const escapedItem = (item || '').replace(doubleQuoteRegex, '""');
        return `${acc ? `${acc},` : ''}"${escapedItem}"`;
      }, '')
    )
    .join('\r\n');
