import { getPageProtocol } from 'src/common/utils/web/getPageProtocol';

export const getProtocols = (
  endpoint: string
): { appProtocol: string; endpointProtocol: string } => {
  const appProtocol = getPageProtocol(window);
  let endpointProtocol: string | null;
  try {
    const endpointUrl = new URL(endpoint);
    endpointProtocol = endpointUrl.protocol;
  } catch (ex) {
    // the url could be invalid
    console.error(ex);
    endpointProtocol = null;
  }

  return { appProtocol, endpointProtocol };
};
