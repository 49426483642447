import {
  fileOpen,
  supported as isFileSystemAccessSupported,
} from 'browser-fs-access';

import { history } from 'src/common/utils/history';

let fileUploadRejectionHandlers = [];

export const rejectPendingLegacyFileUploads = () => {
  fileUploadRejectionHandlers.forEach((handler) => handler());
  fileUploadRejectionHandlers = [];
};

// Any history change means the user has canceled the legacy "Open"
// dialog, so we want the rejection handler for the legacy API to be
// called at that point.
if (!isFileSystemAccessSupported) {
  history.listen(rejectPendingLegacyFileUploads);
}

export const legacySupportingFileOpen = async () => {
  return fileOpen({
    setupLegacyCleanupAndRejection: (rejectionHandler) => {
      fileUploadRejectionHandlers.push(rejectionHandler);

      return (reject) => {
        if (reject) {
          // Real rejection; do the `reject` and no need for further cleanup,
          // since `rejectPendingLegacyFileUploads` is the only rejection route
          // right now, and it clears all handlers.
          reject();
        } else {
          // This is just a cleanup, not a rejection, so we need to remove the
          // handler ourself.
          fileUploadRejectionHandlers = fileUploadRejectionHandlers.filter(
            (handler) => handler !== rejectionHandler
          );
        }
      };
    },
  });
};
