import { VirtualGraphSource } from 'src/common/store/virtualGraphs/VirtualGraphMappingType';

// The "base" for the options. Other properties (`name`, `required`) are added
// programmatically.
// this type provokes all sorts of problems I don't understand, but I find this type quite useful to document what's going on in such a large object.
const basePartialOptionMap =
  // : {
  //   [key: string]: {
  //     label: string;
  //     description: string;
  //     type: string;
  //     required?: boolean;
  //     defaultValue?: any;
  //     canBeEmpty?: boolean;
  //     possibleValues?: any[];
  //     minVersionInclusive?: string; // >= (when the feature was added)
  //     maxVersionExclusive?: string; // < (when the feature was removed or deprecated)
  //     [key: string]: any;
  //   };
  // }
  {
    'jdbc.url': {
      label: 'JDBC Connection URL',
      description: 'The URL of the JDBC connection.',
      type: 'string',
      defaultValue: '',
      required: true,
    },
    'jdbc.username': {
      label: 'JDBC Username',
      description: 'The username used to make the JDBC connection.',
      type: 'string',
      defaultValue: '',
      required: true,
      canBeEmpty: true,
    },
    'jdbc.password': {
      label: 'JDBC Password',
      description: 'The password used to make the JDBC connection.',
      type: 'string',
      defaultValue: '',
      required: true,
      canBeEmpty: true,
    },
    'jdbc.driver': {
      label: 'JDBC Driver Class',
      description: 'The driver class name used to make the JDBC connection.',
      type: 'string',
      defaultValue: '',
      required: true,
      canBeEmptyAsOfVersion: '7.0.3',
    },
    'csv.separator': {
      label: 'CSV Separator',
      description:
        'A single-character separator used when importing tabular data files.',
      type: 'string',
      defaultValue: ',',
      required: true,
    },
    'csv.quote': {
      label: 'CSV Quote',
      description:
        'A single character used to encapsulate values containing special characters.',
      type: 'string',
      defaultValue: '"',
    },
    'csv.escape': {
      label: 'CSV Escape',
      description:
        'A single character used to escape values containing special characters.',
      type: 'string',
      defaultValue: '',
    },
    'csv.header': {
      label: 'Read CSV Header',
      description:
        'Should the import process read the header row? When headers are enabled the first row of the input file is used to retrieve the column names and mappings can refer to those column names.',
      type: 'boolean',
      defaultValue: 'true',
    },
    'csv.skip.empty': {
      label: 'Skip Empty CSV Values',
      description:
        'Should empty values be skipped in the CSV file? If true no triples will be generated for templates that refer to a column with empty value.',
      type: 'boolean',
      defaultValue: 'true',
    },
    'percent.encode': {
      label: 'Percent Encode IRI Template Strings',
      description:
        'Should IRI template strings be percent-encoded to be valid IRIs?',
      type: 'boolean',
      defaultValue: 'true',
      // maxVersionExclusive: '1.0.0'
    },
    'import.optimize': {
      label: 'Use Optimized Import',
      description:
        'Should `virtual import` and `?s ?p ?o` queries use the optimized import?',
      type: 'boolean',
      defaultValue: 'true',
    },
    'parser.sql.quoting': {
      label: 'SQL Quoting Convention',
      description:
        'If set to `NATIVE`, R2RML views (using `rr:sqlQuery`) will be parsed using the DB-native identifier quoting convention. For example, MySQL queries will be parsed treating backtick as the identifier quote character. If set to `ANSI`, the ANSI SQL convention of treating a double quote as the identifier quote character will be used instead.',
      type: 'string',
      defaultValue: 'NATIVE',
      possibleValues: ['NATIVE', 'ANSI'],
    },
    'query.translation': {
      label: 'Query Translation Algorithm',
      description:
        'If set to `DEFAULT`, use the core query translation algorithm. If set to `LEGACY`, the previous algorithm will be used. This option is intended for debugging and not production use.',
      type: 'string',
      defaultValue: 'DEFAULT',
      possibleValues: ['DEFAULT', 'LEGACY'],
      maxVersionExclusive: '6.0.0',
    },
    'sql.functions': {
      label: 'SQL Function Names',
      description:
        'A comma-separated list of SQL function names to register with the parser. If an R2RML view (using `rr:sqlQuery`) fails to parse, this option can be set to allow use of non-standard functions.',
      type: 'string',
      defaultValue: '',
    },
    'sql.schemas': {
      label: 'SQL Schemas',
      description:
        'A comma-separated list of schemas to append to the schema search path. This option allows R2RML tables and queries to reference tables that are outside of the default schema for the connected user.',
      type: 'string',
      defaultValue: '',
    },
    'default.mapping.include.tables': {
      label: 'Tables to Include',
      description:
        'A comma-separated list of tables to include when generating default mappings. If blank, mappings will be generated for all tables in the default schema for the connected user, plus any schemas listed in sql.schemas. Cannot be combined with `default.mapping.exclude.tables`.',
      type: 'string',
      defaultValue: '',
    },
    'default.mapping.exclude.tables': {
      label: 'Tables to Exclude',
      description:
        'A comma-separated list of tables to exclude when generating default mappings. Mappings will be generated for all tables in the default schema for the connected user, plus any schemas listed in sql.schemas, except those tables listed in this option. Cannot be combined with `default.mapping.include.tables`.',
      type: 'string',
      defaultValue: '',
    },
    'mongodb.uri': {
      label: 'MongoDB URI',
      description: 'The URL of the MongoDB connection.',
      type: 'string',
      defaultValue: '',
      placeholder: 'mongodb://localhost:27017/database',
      required: true,
    },
    'cassandra.contact.point': {
      label: 'Cassandra Node Address',
      description:
        'The address of the Cassandra node(s) that the driver uses to discover the cluster topology.',
      type: 'string',
      defaultValue: '',
      placeholder: 'localhost',
      required: true,
    },
    'cassandra.keyspace': {
      label: 'Cassandra Keyspace',
      description: 'The Cassandra keyspace to use for this session.',
      type: 'string',
      defaultValue: '',
      required: true,
    },
    'cassandra.username': {
      label: 'Cassandra Username',
      description: 'The username for the Cassandra cluster.',
      type: 'string',
      defaultValue: '',
      required: true,
      canBeEmpty: true,
    },
    'cassandra.password': {
      label: 'Cassandra Password',
      description: 'The password for the Cassandra cluster.',
      type: 'string',
      defaultValue: '',
      required: true,
      canBeEmpty: true,
    },
    'cassandra.allow.filtering': {
      label: 'Cassandra Allow Filtering',
      description:
        'Whether to include the ALLOW FILTERING clause at the end of Cassandra CQL queries. Not recommended for production use.',
      type: 'boolean',
      defaultValue: 'false',
      required: false,
    },
    'elasticsearch.rest.urls': {
      label: 'Elasticsearch URLs',
      description:
        'Whitespace-delimited list of connection scheme://host:port values for Elasticsearch. Scheme defaults to http.',
      type: 'string',
      defaultValue: '',
      placeholder: 'https://server1:9200 http://server2:9200 server3:9200',
      required: true,
    },
    'elasticsearch.username': {
      label: 'Elasticsearch Username',
      description: 'Username for Elasticsearch connections',
      type: 'string',
      defaultValue: '',
      required: true,
      canBeEmpty: true,
      minVersionInclusive: '7.4.2',
    },
    'elasticsearch.password': {
      label: 'Elasticsearch Password',
      description: 'Password for Elasticsearch connections',
      type: 'string',
      defaultValue: '',
      required: true,
      canBeEmpty: true,
      minVersionInclusive: '7.4.2',
    },
  };

// The type for the actual complete optionMap.
export type VirtualGraphOptionMap = {
  [K in keyof typeof basePartialOptionMap]: typeof basePartialOptionMap[K] & {
    name: K;
    required: boolean;
    canBeEmpty: boolean;
    placeholder?: string;
    // The option can be empty as of some version.
    // It may still be `required` which affects the prominence of where the
    // property is rendered.
    canBeEmptyAsOfVersion?: string;
    possibleValues?: string[];
    minVersionInclusive?: string;
    maxVersionExclusive?: string;
  };
};

// Ordered option names.
// BEWARE of `optionNames.filter` calls here -> they do not guarantee ordering,
// so if ordering matters, explicitly list the option names in the desired
// order. Sort indices in the option maps might be a better way.
const optionNames: string[] = Object.keys(basePartialOptionMap).sort();
const sharedOptionNames = optionNames.filter(
  (name) =>
    !name.startsWith('jdbc.') &&
    !name.startsWith('mongodb.') &&
    !name.startsWith('csv.') &&
    !name.startsWith('cassandra.') &&
    !name.startsWith('elasticsearch') &&
    !name.includes('sql.') &&
    !name.includes('.tables')
);
const sqlOptionNames = [
  'jdbc.url',
  'jdbc.username',
  'jdbc.password',
  'jdbc.driver',
  ...optionNames.filter(
    (name) =>
      name.includes('sql.') ||
      name.includes('.tables') ||
      sharedOptionNames.includes(name)
  ),
];
const mongoOptionNames = ['mongodb.uri', ...sharedOptionNames];
// @ts-ignore: unused for now, probably used later (TODO)
const csvOptionNames = [
  ...optionNames.filter((name) => name.startsWith('csv.')),
  ...sharedOptionNames,
];
const cassandraOptionNames = [
  'cassandra.contact.point',
  'cassandra.keyspace',
  'cassandra.username',
  'cassandra.password',
  'cassandra.allow.filtering',
  ...sharedOptionNames,
];
const elasticsearchOptionNames = [
  'elasticsearch.rest.urls',
  'elasticsearch.username',
  'elasticsearch.password',
  ...sharedOptionNames,
];

// Option maps.
const reduceNamesToOptionMap = (names: string[]) =>
  names.reduce(
    (acc, name) => ({
      ...acc,
      [name]: {
        ...basePartialOptionMap[name],
        name,
        required: basePartialOptionMap[name].required,
        canBeEmpty: basePartialOptionMap[name].canBeEmpty,
      },
    }),
    {} as VirtualGraphOptionMap
  );

// Complete option map, consisting of `basePartialOptionMap` with names and
// `required` added:
export const virtualGraphOptionMap: VirtualGraphOptionMap =
  reduceNamesToOptionMap(optionNames);
// All details for all types of options: the order of the options in the UI,
// as well as the details for each option
export const virtualGraphOptionNamesByType = {
  [VirtualGraphSource.SQL]: sqlOptionNames,
  [VirtualGraphSource.MONGO]: mongoOptionNames,
  [VirtualGraphSource.APACHE_CASSANDRA]: cassandraOptionNames,
  [VirtualGraphSource.ELASTICSEARCH]: elasticsearchOptionNames,
};
// A map of the default values for all options (just for slightly easier access).
export const virtualGraphDefaultValueMap = optionNames.reduce(
  (acc, name) => ({
    ...acc,
    [name]: basePartialOptionMap[name].defaultValue,
  }),
  {} as Record<
    keyof VirtualGraphOptionMap,
    VirtualGraphOptionMap[keyof VirtualGraphOptionMap]['defaultValue']
  >
);

// Typings for options and values specific to source type.
type CsvSpecificKeys =
  | 'csv.separator'
  | 'csv.quote'
  | 'csv.escape'
  | 'csv.header'
  | 'csv.skip.empty';
type SqlSpecificKeys =
  | 'jdbc.url'
  | 'jdbc.username'
  | 'jdbc.password'
  | 'jdbc.driver'
  | 'parser.sql.quoting'
  | 'sql.functions'
  | 'sql.schemas'
  | 'default.mappings.include.tables'
  | 'default.mappings.exclude.tables';
type MongoSpecificKeys = 'mongodb.uri';
type CassandraSpecificKeys =
  | 'cassandra.contact.point'
  | 'cassandra.keyspace'
  | 'cassandra.username'
  | 'cassandra.password'
  | 'cassandra.allow.filtering';
type ElasticsearchSpecificKeys =
  | 'elasticsearch.rest.urls'
  | 'elasticsearch.username'
  | 'elasticsearch.password';
type SqlOptionMap = Pick<
  VirtualGraphOptionMap,
  Exclude<
    keyof VirtualGraphOptionMap,
    | MongoSpecificKeys
    | CsvSpecificKeys
    | CassandraSpecificKeys
    | ElasticsearchSpecificKeys
  >
>;
type MongoOptionMap = Pick<
  VirtualGraphOptionMap,
  Exclude<
    keyof VirtualGraphOptionMap,
    | SqlSpecificKeys
    | CsvSpecificKeys
    | CassandraSpecificKeys
    | ElasticsearchSpecificKeys
  >
>;
type CassandraOptionMap = Pick<
  VirtualGraphOptionMap,
  Exclude<
    keyof VirtualGraphOptionMap,
    | SqlSpecificKeys
    | MongoSpecificKeys
    | CsvSpecificKeys
    | ElasticsearchSpecificKeys
  >
>;
type ElasticsearchOptionMap = Pick<
  VirtualGraphOptionMap,
  Exclude<
    keyof VirtualGraphOptionMap,
    | SqlSpecificKeys
    | MongoSpecificKeys
    | CsvSpecificKeys
    | CassandraSpecificKeys
  >
>;
export type SqlOptionValueMap = Record<keyof SqlOptionMap, string>;
export type MongoOptionValueMap = Record<keyof MongoOptionMap, string>;
export type CassandraOptionValueMap = Record<keyof CassandraOptionMap, string>;
export type ElasticsearchOptionValueMap = Record<
  keyof ElasticsearchOptionMap,
  string
>;
export type VirtualGraphOptionValueMap =
  | SqlOptionValueMap
  | MongoOptionValueMap
  | CassandraOptionValueMap;
export type SqlOption = {
  [K in keyof SqlOptionMap]: SqlOptionMap[K];
}[keyof SqlOptionMap];
export type MongoOption = {
  [K in keyof MongoOptionMap]: MongoOptionMap[K];
}[keyof MongoOptionMap];
export type CassandraOption = {
  [K in keyof CassandraOptionMap]: CassandraOptionMap[K];
}[keyof CassandraOptionMap];
export type ElasticsearchOption = {
  [K in keyof ElasticsearchOptionMap]: ElasticsearchOptionMap[K];
}[keyof ElasticsearchOptionMap];
export type SqlOptionValue = {
  [K in keyof SqlOptionValueMap]: SqlOptionValueMap[K];
}[keyof SqlOptionValueMap];
export type MongoOptionValue = {
  [K in keyof MongoOptionValueMap]: MongoOptionValueMap[K];
}[keyof MongoOptionValueMap];
export type CassandraOptionValue = {
  [K in keyof CassandraOptionValueMap]: CassandraOptionValueMap[K];
}[keyof CassandraOptionValueMap];
export type ElasticsearchOptionValue = {
  [K in keyof ElasticsearchOptionValueMap]: ElasticsearchOptionValueMap[K];
}[keyof ElasticsearchOptionValueMap];

export const connectionPoolOptions: Array<{
  name: string;
  description?: string;
  type: HTMLInputElement['type'];
  defaultValue: string;
}> = [
  {
    name: 'defaultAutoCommit',
    description:
      'The default auto-commit state of connections created by this pool. If not set, default is JDBC driver default (If not set then the setAutoCommit method will not be called.)',
    type: 'boolean',
    defaultValue: 'false',
  },
  {
    name: 'defaultReadOnly',
    description:
      "The default read-only state of connections created by this pool. If not set then the setReadOnly method will not be called. (Some drivers don't support read only mode, ex: Informix)",
    type: 'boolean',
    defaultValue: 'false',
  },
  {
    name: 'defaultTransactionIsolation',
    description:
      'The default TransactionIsolation state of connections created by this pool. One of the following: (see javadoc )\n\n\nNONE\nREAD_COMMITTED\nREAD_UNCOMMITTED\nREPEATABLE_READ\nSERIALIZABLE\n\nIf not set, the method will not be called and it defaults to the JDBC driver.',
    type: 'text',
    defaultValue: '',
  },
  {
    name: 'defaultCatalog',
    description: 'The default catalog of connections created by this pool.',
    type: 'text',
    defaultValue: '',
  },
  // disclude this b/c it's collected as a required VG param
  // { name: 'driverClassName', description: '', type: '' },
  // { name: 'username', description: '', type: '' },
  // { name: 'password', description: '', type: '' },
  {
    name: 'maxActive',
    description:
      'The maximum number of active connections that can be allocated from this pool at the same time. The default value is 100',
    type: 'number',
    defaultValue: '100',
  },
  {
    name: 'maxIdle',
    description:
      'The maximum number of connections that should be kept in the pool at all times. Default value is maxActive:100 Idle connections are checked periodically (if enabled) and connections that been idle for longer than minEvictableIdleTimeMillis will be released. (also see testWhileIdle)',
    type: 'number',
    defaultValue: '100',
  },
  {
    name: 'minIdle',
    description:
      'The minimum number of established connections that should be kept in the pool at all times. The connection pool can shrink below this number if validation queries fail. Default value is derived from initialSize:10 (also see testWhileIdle)',
    type: 'number',
    defaultValue: '10',
  },
  {
    name: 'initialSize',
    description:
      'The initial number of connections that are created when the pool is started. Default value is 10',
    type: 'number',
    defaultValue: '10',
  },
  {
    name: 'maxWait',
    description:
      'The maximum number of milliseconds that the pool will wait (when there are no available connections) for a connection to be returned before throwing an exception. Default value is 30000 (30 seconds)',
    type: 'number',
    defaultValue: '30000',
  },
  {
    name: 'testOnBorrow',
    description:
      'The indication of whether objects will be validated before being borrowed from the pool. If the object fails to validate, it will be dropped from the pool, and we will attempt to borrow another. In order to have a more efficient validation, see validationInterval. Default value is false',
    type: 'boolean',
    defaultValue: 'false',
  },
  {
    name: 'testOnConnect',
    description:
      'The indication of whether objects will be validated when a connection is first created. If an object fails to validate, it will be throw SQLException. Default value is false',
    type: 'boolean',
    defaultValue: 'false',
  },
  {
    name: 'testOnReturn',
    description:
      'The indication of whether objects will be validated before being returned to the pool. The default value is false. ',
    type: 'boolean',
    defaultValue: 'false',
  },
  {
    name: 'testWhileIdle',
    description:
      'The indication of whether objects will be validated by the idle object evictor (if any). If an object fails to validate, it will be dropped from the pool. The default value is false and this property has to be set in order for the pool cleaner/test thread is to run (also see timeBetweenEvictionRunsMillis)',
    type: 'boolean',
    defaultValue: 'false',
  },
  {
    name: 'validationQuery',
    description:
      "The SQL query that will be used to validate connections from this pool before returning them to the caller. If specified, this query does not have to return any data, it just can't throw a SQLException. The default value is null. If not specified, connections will be validation by the isValid() method. Example values are SELECT 1(mysql), select 1 from dual(oracle), SELECT 1(MS Sql Server)",
    type: 'text',
    defaultValue: '',
  },
  {
    name: 'validationQueryTimeout',
    description:
      "The timeout in seconds before a connection validation queries fail. This works by calling java.sql.Statement.setQueryTimeout(seconds) on the statement that executes the validationQuery. The pool itself doesn't timeout the query, it is still up to the JDBC driver to enforce query timeouts. A value less than or equal to zero will disable this feature. The default value is -1.",
    type: 'number',
    defaultValue: '-1',
  },
  {
    name: 'validatorClassName',
    description:
      'The name of a class which implements the org.apache.tomcat.jdbc.pool.Validator interface and provides a no-arg constructor (may be implicit). If specified, the class will be used to create a Validator instance which is then used instead of any validation query to validate connections. The default value is null. An example value is com.mycompany.project.SimpleValidator.',
    type: 'text',
    defaultValue: '',
  },
  {
    name: 'timeBetweenEvictionRunsMillis',
    description:
      'The number of milliseconds to sleep between runs of the idle connection validation/cleaner thread. This value should not be set under 1 second. It dictates how often we check for idle, abandoned connections, and how often we validate idle connections. This value will be overridden by maxAge if the latter is non-zero and lower. The default value is 5000 (5 seconds).',
    type: 'number',
    defaultValue: '5000',
  },
  // {
  //   name: 'numTestsPerEvictionRun',
  //   description: 'Property not used in tomcat-jdbc-pool.',
  //   type: 'number',
  // },
  {
    name: 'minEvictableIdleTimeMillis',
    description:
      'The minimum amount of time an object may sit idle in the pool before it is eligible for eviction. The default value is 60000 (60 seconds).',
    type: 'number',
    defaultValue: '60000',
  },
  // { name: 'accessToUnderlyingConnectionAllowed', description: 'Property not used.', type: '' },
  {
    name: 'removeAbandoned',
    description:
      'Flag to remove abandoned connections if they exceed the removeAbandonedTimeout. If set to true a connection is considered abandoned and eligible for removal if it has been in use longer than the removeAbandonedTimeout Setting this to true can recover db connections from applications that fail to close a connection. See also logAbandoned The default value is false.',
    type: 'boolean',
    defaultValue: 'false',
  },
  {
    name: 'removeAbandonedTimeout',
    description:
      'Flag to remove abandoned connections if they exceed the removeAbandonedTimeout. If set to true a connection is considered abandoned and eligible for removal if it has been in use longer than the removeAbandonedTimeout Setting this to true can recover db connections from applications that fail to close a connection. See also logAbandoned The default value is false.',
    type: 'number',
    defaultValue: 'false',
  },
  {
    name: 'logAbandoned',
    description:
      'Flag to log stack traces for application code which abandoned a Connection. Logging of abandoned Connections adds overhead for every Connection borrow because a stack trace has to be generated. The default value is false.',
    type: 'boolean',
    defaultValue: 'false',
  },
  {
    name: 'connectionProperties',
    description:
      'The connection properties that will be sent to our JDBC driver when establishing new connections. Format of the string must be [propertyName=property;]* NOTE - The "user" and "password" properties will be passed explicitly, so they do not need to be included here. The default value is null.',
    type: 'text',
    defaultValue: '',
  },
  // {
  //   name: 'poolPreparedStatements',
  //   description: 'Property not used.',
  //   type: 'boolean',
  // },
  // {
  //   name: 'maxOpenPreparedStatements',
  //   description: 'Property not used.',
  //   type: 'number',
  // },
  // `Tomcat JDBC Enhanced Attrributes` from here
  // https://tomcat.apache.org/tomcat-9.0-doc/jdbc-pool.html#Common_Attributes
  {
    name: 'initSQL',
    description:
      'A custom query to be run when a connection is first created. The default value is null.',
    type: 'text',
    defaultValue: '',
  },
  {
    name: 'jdbcInterceptors',
    description:
      'A semicolon separated list of classnames extending org.apache.tomcat.jdbc.pool.JdbcInterceptor class.',
    type: 'text',
    defaultValue: '',
  },
  {
    name: 'validationInterval',
    description:
      'Avoid excess validation, only run validation at most at this frequency - time in milliseconds. If a connection is due for validation, but has been validated previously within this interval, it will not be validated again. The default value is 3000 (3 seconds).',
    type: 'number',
    defaultValue: '3000',
  },
  {
    name: 'jmxEnabled',
    description:
      'Register the pool with JMX or not. The default value is true.',
    type: 'boolean',
    defaultValue: 'true',
  },
  {
    name: 'fairQueue',
    description:
      'Set to true if you wish that calls to getConnection should be treated fairly in a true FIFO fashion. This uses the org.apache.tomcat.jdbc.pool.FairBlockingQueue implementation for the list of the idle connections. The default value is true.',
    type: 'boolean',
    defaultValue: 'true',
  },
  {
    name: 'abandonWhenPercentageFull',
    description:
      'Connections that have been abandoned (timed out) wont get closed and reported up unless the number of connections in use are above the percentage defined by abandonWhenPercentageFull. The value should be between 0-100. The default value is 0, which implies that connections are eligible for closure as soon as removeAbandonedTimeout has been reached.',
    type: 'number',
    defaultValue: '0',
  },
  {
    name: 'maxAge',
    description:
      'Time in milliseconds to keep a connection before recreating it. When a connection is borrowed from the pool, the pool will check to see if the now - time-when-connected > maxAge has been reached , and if so, it reconnects before borrow it. When a connection is returned to the pool, the pool will check to see if the now - time-when-connected > maxAge has been reached, and if so, it tries to reconnect. When a connection is idle and timeBetweenEvictionRunsMillis is greater than zero, the pool will periodically check to see if the now - time-when-connected > maxAge has been reached, and if so, it tries to reconnect. Setting maxAge to a value lower than timeBetweenEvictionRunsMillis will override it (so idle connection validation/cleaning will run more frequently). The default value is 0, which implies that connections will be left open and no age check will be done upon borrowing from the pool, returning the connection to the pool or when checking idle connections.',
    type: 'number',
    defaultValue: '0',
  },
  {
    name: 'useEquals',
    description:
      'Set to true if you wish the ProxyConnection class to use String.equals and set to false when you wish to use == when comparing method names. This property does not apply to added interceptors as those are configured individually. The default value is true. ',
    type: 'boolean',
    defaultValue: 'true',
  },
  {
    name: 'suspectTimeout',
    description: 'Timeout value in seconds. Default value is 0.',
    type: 'number',
    defaultValue: '0',
  },
  {
    name: 'rollbackOnReturn',
    description:
      'If autoCommit==false then the pool can terminate the transaction by calling rollback on the connection as it is returned to the pool Default value is false.',
    type: 'boolean',
    defaultValue: 'false',
  },
  {
    name: 'commitOnReturn',
    description:
      'If autoCommit==false then the pool can complete the transaction by calling commit on the connection as it is returned to the pool If rollbackOnReturn==true then this attribute is ignored. Default value is false.',
    type: 'boolean',
    defaultValue: 'false',
  },
  {
    name: 'alternateUsernameAllowed',
    description:
      'By default, the jdbc-pool will ignore the DataSource.getConnection(username,password) call, and simply return a previously pooled connection under the globally configured properties username and password, for performance reasons.',
    type: 'boolean',
    defaultValue: 'false',
  },
  // TODO the type here is `javax.sql.DataSource` -- does Stardog support this property?
  // { name: 'dataSource', description: 'Inject a data source to the connection pool, and the pool will use the data source to retrieve connections instead of establishing them using the java.sql.Driver interface. This is useful when you wish to pool XA connections or connections established using a data source instead of a connection string. Default value is null', type: 'javax.sql.DataSource', defaultValue: null },
  // This is tied to the property above
  // { name: 'dataSourceJNDI', description: 'The JNDI name for a data source to be looked up in JNDI and then used to establish connections to the database. See the dataSource attribute. Default value is null', type: 'text', defaultValue: null },
  {
    name: 'useDisposableConnectionFacade',
    description:
      'Set this to true if you wish to put a facade on your connection so that it cannot be reused after it has been closed. This prevents a thread holding on to a reference of a connection it has already called closed on, to execute queries on it. Default value is true. ',
    type: 'boolean',
    defaultValue: 'true',
  },
  {
    name: 'logValidationErrors',
    description:
      'Set this to true to log errors during the validation phase to the log file. If set to true, errors will be logged as SEVERE. Default value is false for backwards compatibility. ',
    type: 'boolean',
    defaultValue: 'false',
  },
  {
    name: 'propagateInterruptState',
    description:
      'Set this to true to propagate the interrupt state for a thread that has been interrupted (not clearing the interrupt state). Default value is false for backwards compatibility.',
    type: 'boolean',
    defaultValue: 'false',
  },
  {
    name: 'ignoreExceptionOnPreLoad',
    description:
      'Flag whether ignore error of connection creation while initializing the pool. Set to true if you want to ignore error of connection creation while initializing the pool. Set to false if you want to fail the initialization of the pool by throwing exception. The default value is false.',
    type: 'boolean',
    defaultValue: 'false',
  },
  {
    name: 'useStatementFacade',
    description:
      'Set this to true if you wish to wrap statements in order to enable equals() and hashCode() methods to be called on the closed statements if any statement proxy is set. Default value is true.',
    type: 'boolean',
    defaultValue: 'true',
  },
];

export const connectionPoolOptionsMap = connectionPoolOptions.reduce(
  (acc, option) => {
    acc[option.name] = option;
    return acc;
  },
  {}
);

// `mappings.syntax` is hard-coded in action creators to `sms2`
//
// `namespaces` is hidden from CLI and HTTP API output
// https://stardog-union.slack.com/archives/C3TDMCFGF/p1555950205021500
// https://github.com/stardog-union/stardog/issues/7164
//
// We allow both properties in the redux store but we exclude them from
// configuration rendering.
// NOTE - the user however can set these. we have to ensure the default value we're always sending over is `sms2`
export const hiddenProperties = [
  'isLocal',
  'mappings.syntax',
  'mappings.format',
  'namespaces',
  'mappingDoc',
  'regenerateMapping',
];
export const hiddenPropertiesSet = new Set(hiddenProperties);

export const vgOnlyProperties = [
  'base',
  'namespaces',
  'default.mapping.include.tables',
  'default.mapping.exclude.tables',
  'percent.encode',
  'schema.in.generated.mappings',
  'union.strategy',
  'charset.handling',
  'import.optimize',
  'parser.sql.quoting',
  'sql.functions',
  'explain.format',
  'cassandra.allow.filtering',
];
export const vgOnlyPropertiesSet = new Set(vgOnlyProperties);

// used for hiding Data Source properties from private VG config
export const dsOnlyProperties = [
  ...Object.keys(connectionPoolOptionsMap),
  'unique.key.sets',
  'jdbc.url',
  'jdbc.username',
  'jdbc.password',
  'jdbc.driver',
  'sql.schemas',
  'sql.dialect',
  'sql.default.schema',
  'mongodb.uri',
  'elasticsearch.rest.urls',
  'elasticsearch.indexes',
  'elasticsearch.username',
  'elasticsearch.password',
  'cassandra.contact.point',
  'cassandra.port',
  'cassandra.keyspace',
  'cassandra.username',
  'cassandra.password',
];
export const dsOnlyPropertiesSet = new Set(dsOnlyProperties);
