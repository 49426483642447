import { abbreviatePrefixArray } from 'stardog-language-utils';

interface AbbreviationsCache {
  [key: string]: string;
}

let namespacesCache: AbbreviationsCache = {};
let localCache: AbbreviationsCache = {};

export function getAbbreviation(text: string, namespaces: string[]) {
  // cache the prefix abbreviations by the text and namespaces
  const cacheKey = `${text}_${namespaces.sort().join('-')}`;

  // if we've abbreviated this before, return the found result
  const foundAbbr = namespacesCache[cacheKey];
  if (foundAbbr) {
    return foundAbbr;
  }

  // otherwise, get the new abbreviation and add it to the cache
  const abbr = abbreviatePrefixArray(text, namespaces);
  namespacesCache[cacheKey] = abbr;
  return abbr;
}

export function getLocalName(text: string) {
  // cache the local names by the text since they are not namespace specific
  // if we've abbreviated this before, return the found result
  const foundAbbr = localCache[text];
  if (foundAbbr) {
    return foundAbbr;
  }

  // Group 1: split after the first occurrence of the '#' character
  // Group 2: split after the last occurrence of the '/' character
  // Group 3: split after the last occurrence of the ':' character
  const match = text.match(/[^\#\n]*\#(.*)|.*\/(.*)|.*\:(.*)/);
  const localName = match ? match[1] || match[2] || match[3] : text;

  // otherwise, add the local name to the cache
  localCache[text] = localName;
  return localName;
}

export function getAbbreviationOrLocalName(text: string, namespaces: string[]) {
  const namespacesAbbr = getAbbreviation(text, namespaces);
  return text !== namespacesAbbr ? namespacesAbbr : getLocalName(text);
}

export function getAbbreviationOrFullIri(text: string, namespaces: string[]) {
  const abbreviatedText = getAbbreviation(text, namespaces);
  return text === abbreviatedText ? `<${text}>` : abbreviatedText;
}

export function resetAbbreviationCache() {
  namespacesCache = {};
  localCache = {};
}
