import * as React from 'react';
import {
  usePortalHeap,
  usePortalHotjar,
  usePortalUserflow,
} from 'vet-bones/bones/utils/portal';

/**
 * Define the app-level hooks as a child of the App to prevent
 * the App (and all of its children) from unnecessarily re-rendering
 * every time a dependency of the app-level hooks changes.
 */
export const AppHooks: React.VFC = () => {
  usePortalHeap();
  usePortalHotjar();
  usePortalUserflow();

  return null;
};
