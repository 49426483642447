import { get } from 'idb-keyval';
import {
  Reducer,
  StoreEnhancer,
  applyMiddleware,
  compose,
  createStore as createReduxStore,
} from 'redux';
import thunk from 'redux-thunk';

import { StudioThunkDispatch } from 'src/common/actions/StudioAction';
import { browserStoreKey } from 'src/common/constants/store';
import { migrate } from 'src/common/store/migrate/migrate';
import { actionSanitizer } from 'src/common/store/sanitize/actionSanitizer';
import { stateSanitizer } from 'src/common/store/sanitize/stateSanitizer';
import { isDevelopment } from 'src/common/utils/isDevelopment';
import { isTesting } from 'src/common/utils/isTesting';
import { ReduxState } from 'src/types';

function getEnhancers(): StoreEnhancer<{ dispatch: StudioThunkDispatch }> {
  const enhancers = [applyMiddleware(thunk)];
  const root: any = global;

  if (isDevelopment() && root.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(
      root.__REDUX_DEVTOOLS_EXTENSION__({
        actionsBlacklist: [
          'POPULATE_QUERIES_SUCCESS',
          'POPULATE_QUERIES_ATTEMPT',
          'GET_SIZE_ATTEMPT',
          'GET_SIZE_FAILURE',
          'FETCH_FROM_CACHE',
        ],
        actionSanitizer,
        stateSanitizer,
      })
    );
  }

  return compose(...enhancers);
}

export const createStore = (
  initialState: ReduxState,
  reducer: Reducer<ReduxState>
) => createReduxStore(reducer, initialState, getEnhancers());

export const getRehydratedState = async (defaultInitialState) => {
  let storedState;

  try {
    storedState = await get(browserStoreKey);

    if (!storedState) {
      // No store found in IndexedDB. Check for old store in localStorage, or
      // fall back to initial state.
      storedState =
        JSON.parse(localStorage.getItem(browserStoreKey)) ||
        defaultInitialState;
    }
  } catch (ex) {
    // Accessing IndexedDB/localStorage or parsing localStorage threw; fall
    // back to initial state.
    console.error(ex);
    storedState = defaultInitialState;
  }

  return storedState;
};

export const createHydratedStore = async (defaultInitialState, reducer) => {
  const rehydratedState = await getRehydratedState(defaultInitialState);

  if (!rehydratedState.version) {
    // This should never happen, but in the off-chance that it does (e.g.,
    // someone mucked with their storage and deleted the version), we will run
    // whatever we found in storage through all of the migrations to get to a
    // reasonable state.
    rehydratedState.version = '0.0.1';
  }

  return createStore(
    {
      ...defaultInitialState,
      ...migrate(isTesting() ? defaultInitialState : rehydratedState),
    },
    reducer
  );
};
