import {
  Menu as BlueprintMenu,
  ContextMenu,
  MenuItem,
} from '@blueprintjs/core';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import uuid from 'uuid';

import { ContextMenuProps } from 'src/common/components/ContextualStudioApi';

class BrowserContextMenu extends React.Component<ContextMenuProps> {
  componentDidMount() {
    setTimeout(() => {
      const { template, clickEvent } = this.props;
      const menu = (
        <BlueprintMenu>
          {template.map((item) => (
            <MenuItem
              key={item.label}
              data-testid={item.testId}
              disabled={item.enabled === undefined ? false : !item.enabled}
              onClick={item.click}
              text={item.label}
            />
          ))}
        </BlueprintMenu>
      );
      ContextMenu.show(
        menu,
        {
          left: clickEvent.clientX,
          top: clickEvent.clientY,
        },
        this.onClose.bind(this)
      );
    });
  }

  componentWillUnmount() {
    this.onClose();
  }

  onClose() {
    const { onClose } = this.props;
    onClose?.();
  }

  render() {
    return <div />;
  }
}

export const browserContextMenuApi = {
  getContextMenu: ({ event, template, onClose }) => (
    <BrowserContextMenu
      key={uuid()}
      clickEvent={event}
      id={uuid()}
      onClose={onClose}
      template={template}
    />
  ),
  showContextMenu: ({ event, template, onClose }) => {
    event.preventDefault();
    const node = document.createElement('div');
    ReactDOM.render(
      browserContextMenuApi.getContextMenu({
        event,
        template,
        onClose: () => {
          ReactDOM.unmountComponentAtNode(node);
          node.remove();
          onClose?.();
        },
      }),
      node
    );
  },
};
