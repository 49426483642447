import { noop } from 'vet-bones/bones/utils';

import { browserApi } from 'src/browser/apis/browserApi';
import {
  StudioStateGetter,
  StudioThunkDispatch,
} from 'src/common/actions/StudioAction';
import { ModelsTabIds } from 'src/common/store/models/ModelsState';
import { commaSeparatedString } from 'src/common/utils/commaSeparatedString';

export const withLeaveModelsConfirmation =
  ({
    message = 'Are you sure you want to proceed?',
    noPrompt,
    onConfirmationSuccess = noop,
    onConfirmationFailure = noop,
    yesPrompt,
  }: {
    message?: string;
    noPrompt?: string;
    onConfirmationSuccess?: () => any;
    onConfirmationFailure?: () => any;
    yesPrompt?: string;
  }) =>
  async (_: StudioThunkDispatch, getState: StudioStateGetter) => {
    const {
      attributes,
      classes,
      constraints,
      relationships,
      tabId,
      textEditor,
    } = getState().models;

    let shouldShowConfirmation = false;
    let messageToShow: string;
    if (tabId !== ModelsTabIds.TEXT_EDITOR) {
      const unsavedChangesLocations = [];
      if (attributes.areItemsDirty) {
        unsavedChangesLocations.push(ModelsTabIds.ATTRIBUTES);
      }
      if (classes.areItemsDirty) {
        unsavedChangesLocations.push(ModelsTabIds.CLASSES);
      }
      if (relationships.areItemsDirty) {
        unsavedChangesLocations.push(ModelsTabIds.RELATIONSHIPS);
      }
      if (constraints.editorSettings.isDirty) {
        unsavedChangesLocations.push(ModelsTabIds.CONSTRAINTS);
      }
      if (unsavedChangesLocations.length) {
        shouldShowConfirmation = true;
        messageToShow = `You have unsaved changes in ${commaSeparatedString(
          unsavedChangesLocations
        )}. ${message}`;
      }
    } else if (textEditor.isDirty) {
      shouldShowConfirmation = true;
      messageToShow = `You have unsaved changes in the '${ModelsTabIds.TEXT_EDITOR}'. ${message}`;
    }

    if (shouldShowConfirmation) {
      browserApi.confirmation.showConfirmation({
        message: messageToShow,
        noPrompt,
        onConfirmationSuccess,
        onConfirmationFailure,
        yesPrompt,
      });
    } else {
      onConfirmationSuccess();
    }
  };
