import * as Monaco from 'monaco-editor/esm/vs/editor/editor.api';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import { registerExportListeners } from 'src/browser/actions/notebook/action-creators/stardog-api/exportResultsForNote';
import { ipcFacade as ipcRenderer } from 'src/browser/apis/browserIpcApi';
import { App } from 'src/browser/containers/App';
import { getConnectionFromApi } from 'src/browser/utils/getConnectionFromApi';
import { bindCommands } from 'src/browser/utils/keyboard';
import { setConnection } from 'src/common/actions/connection/action-creators/stardog-api/setConnection';
import { connectionActionCreators } from 'src/common/actions/connection/connectionActionCreators';
import { registerAddDataListeners } from 'src/common/actions/databases/action-creators/stardog-api/addData';
import { registerRemoveDataListeners } from 'src/common/actions/databases/action-creators/stardog-api/removeData';
import { addNote } from 'src/common/actions/notebook/action-creators/addNote';
import { registerNotebookListeners } from 'src/common/actions/notebook/action-creators/notebookListeners';
import { registerSelectListeners } from 'src/common/actions/notebook/action-creators/stardog-api/executeQueryForNote/select';
import { setPreference } from 'src/common/actions/preferences/action-creators/setPreference';
import { IpcEventType } from 'src/common/constants/events';
import { ROUTES } from 'src/common/constants/Routes';
import { getStore, resetStore, saveStore, setupStore } from 'src/common/store';
import {
  ConnectionStatus,
  DrawerStatus,
} from 'src/common/store/connection/ConnectionState';
import { checkAnalytics } from 'src/common/utils/analytics';
import { registerOnDidChangeLanguageListener } from 'src/common/utils/editor/language-services/registerOnDidChangeLanguageListener';
import { history } from 'src/common/utils/history';
import { isDevelopment } from 'src/common/utils/isDevelopment';
import { isTesting } from 'src/common/utils/isTesting';
import { setTheme } from 'src/common/utils/theme';
import { ReduxState } from 'src/types';

declare global {
  interface Window {
    monaco: typeof Monaco;
    restart(): void;
    getState?(): ReduxState; // exposed only in testing
    __STARDOG_WORKER_MANIFEST__: { [key: string]: string };
    APP_VERSION: string;
  }
}

async function start() {
  // Ensure that app always starts at NOTEBOOK route
  if (history.location.pathname !== ROUTES.NOTEBOOK) {
    history.replace(ROUTES.NOTEBOOK);
  }
  await setupStore();
  const { store } = getStore();

  window.APP_VERSION = process.env.APP_VERSION || 'unknown';

  // save state every 5 seconds
  let saveStoreTimeoutId: ReturnType<typeof setTimeout>;
  const setSaveStoreIntermittently = async () => {
    try {
      await saveStore();
    } finally {
      saveStoreTimeoutId = setTimeout(setSaveStoreIntermittently, 5000);
    }
  };
  saveStoreTimeoutId = setTimeout(setSaveStoreIntermittently, 5000);

  window.restart = async () => {
    await resetStore();
    window.location.reload();
  };

  window.addEventListener('beforeunload', async () => {
    clearTimeout(saveStoreTimeoutId);

    // do not persist permissions on reload or quit
    store.dispatch(connectionActionCreators.disconnect());

    await saveStore();
  });

  if (isTesting(process.env.NODE_ENV) || isDevelopment(process.env.NODE_ENV)) {
    window.getState = store.getState;
  }

  // Doesn't seem to currently be an issue, but this call expects
  // that the store has been rehydrated with persisted data and it
  // may be possible that the store won't hydrate in time. If that
  // is indeed the case, we should set up communication between
  // the main process and this renderer process once the renderer
  // loads, which listens to a subscribe event on the persistor in
  // the main process and sends a message here if and when the persistor
  // rehydrates the store.
  const { connection, preferences } = store.getState();
  const { current: currentConnection } = connection;

  const defaultConnection = await getConnectionFromApi();
  if (defaultConnection) {
    store.dispatch(setConnection(defaultConnection));
  } else if (currentConnection.shouldStayLoggedIn) {
    if (currentConnection.password || !currentConnection.username) {
      store.dispatch(setConnection(null)); // reconnect using stored connection info, if any
    } else if (
      currentConnection.status === ConnectionStatus.VIRGIN &&
      connection.drawer.status === DrawerStatus.CLOSED
    ) {
      // Login Dialog should be opened on start in cases where (i) the connection
      // should be logged in, but (ii) the connection has a username, but no password,
      // and (iii) the connection is currently in the signed out ("VIRGIN")
      // state. See VET-589.
      store.dispatch(
        connectionActionCreators.toggleAppDrawer(currentConnection.name)
      );
    }
  }

  setTheme(preferences.theme);

  checkAnalytics(preferences);

  const queryClient = new QueryClient();

  ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <QueryClientProvider client={queryClient}>
          <App dispatch={store.dispatch} />
        </QueryClientProvider>
      </Router>
    </Provider>,
    document.getElementById('stardog-studio-host')
  );

  ipcRenderer.on(
    IpcEventType.UPDATE_TELEMETRY_CONSENT,
    (_, { telemetryConsent }) => {
      store.dispatch(setPreference('telemetryConsent', telemetryConsent));
    }
  );

  ipcRenderer.on(IpcEventType.NEW_TAB_FROM_MENU, () =>
    store.dispatch(addNote())
  );

  registerAddDataListeners();
  registerOnDidChangeLanguageListener();
  registerRemoveDataListeners();
  registerSelectListeners(store.dispatch);
  registerExportListeners(store.dispatch);
  registerNotebookListeners(store);
  bindCommands();
}

start();
