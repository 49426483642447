import { MessageConnection, createMessageConnection } from 'vscode-jsonrpc';
import {
  BrowserMessageReader,
  BrowserMessageWriter,
} from 'vscode-jsonrpc/browser';

import { dedupeArray } from 'src/common/utils/dedupeArray';
import { emptyArray } from 'src/common/utils/emptyArray';
import { isDevelopment } from 'src/common/utils/isDevelopment';
import { getWorkerResourcePath } from 'src/common/utils/rpc/getWorkerResourcePath';

interface ListenerMap {
  [key: string]: Function[];
}

interface InvokableListenerMap {
  [key: string]: Function;
}

export class IpcFacade {
  private listenersByType: ListenerMap = {};

  private invokableListenersByType: InvokableListenerMap = {};

  private connection: MessageConnection;

  constructor(workerCtx) {
    const reader = new BrowserMessageReader(workerCtx);
    const writer = new BrowserMessageWriter(workerCtx);
    this.connection = createMessageConnection(reader, writer);
    this.connection.onRequest(this.emitWithSenderForInvoke);
    this.connection.onNotification(this.emitWithSender);
    this.connection.listen();
  }

  private emitWithSender = (eventType: string, ...args: any[]) =>
    this.emit(eventType, { sender: this }, ...args);

  private emitWithSenderForInvoke = (eventType: string, ...args: any[]) =>
    this.emitForInvoke(eventType, { sender: this }, ...args);

  public handle = (eventType: string, fn: Function) => {
    if (!this.invokableListenersByType[eventType]) {
      this.invokableListenersByType[eventType] = fn;
    }
  };

  public invoke = (eventType: string, ...args: any[]) =>
    this.connection.sendRequest(eventType, ...args);

  public on = (eventType: string, fn: Function) => {
    this.listenersByType[eventType] = dedupeArray(
      (this.listenersByType[eventType] || emptyArray).concat(fn)
    );
  };

  public once = (eventType: string, fn: Function) => {
    const wrapped = (...args: any[]) => {
      fn(...args);
      this.removeListener(eventType, wrapped);
    };
    this.on(eventType, wrapped);
  };

  public removeListener = (eventType: string, fn: Function) => {
    if (!this.listenersByType[eventType]) {
      return;
    }

    this.listenersByType[eventType] = this.listenersByType[eventType].filter(
      (func) => func !== fn
    );

    if (this.listenersByType[eventType].length < 1) {
      this.listenersByType[eventType] = null; // just to not hold a bunch of empty arrays in memory
    }
  };

  public emit = (eventType: string, ...args: any[]) => {
    if (!this.listenersByType[eventType]) {
      return;
    }
    this.listenersByType[eventType].forEach((fn) => fn(...args));
  };

  public emitForInvoke = (eventType: string, ...args: any[]) => {
    if (!this.invokableListenersByType[eventType]) {
      if (isDevelopment) {
        console.error(
          `Failed to invoke for ipcFacade: listener not registered for event ${eventType}`
        );
      }
      return;
    }

    return this.invokableListenersByType[eventType](...args);
  };

  public removeAllListeners = (eventType: string) => {
    this.listenersByType[eventType] = null;
    this.invokableListenersByType[eventType] = null;
  };

  public send = (eventType: string, ...args: any[]) =>
    this.connection.sendNotification(eventType, ...args);

  // @ts-ignore: unused args
  public sendSync = (...args: any[]) => {
    throw new Error('This method is not implemented in the browser');
  };

  public addListener = this.on;
}

export let ipcFacade: IpcFacade;
if (typeof window === 'undefined') {
  ipcFacade = new IpcFacade(self);
} else {
  const mainWorker = new Worker(getWorkerResourcePath('mainWorker.js'));
  ipcFacade = new IpcFacade(mainWorker);
}
