/* eslint-disable no-param-reassign */
import * as BrowserFS from 'browserfs';
import { FSModule } from 'browserfs/dist/node/core/FS';

import { Promisified, promisify } from 'src/common/utils/promisify';
import { getAsTypedTuple } from 'src/common/utils/types/getAsTypedTuple';
import { UnwrappedArrayValueType } from 'src/common/utils/types/UnwrappedArrayValueType';

// TODO: remove this now that we don't use electron
// `methodsToOverwrite` and `methodsToAdd` are names of methods on browserFs
// that we change to make its API match that of `fs-extra` for Electron
const methodsToOverwrite = getAsTypedTuple(
  'access',
  'createFile',
  'writeFile',
  'readFile',
  'readdir',
  'rename',
  'mkdir',
  'lstat',
  'stat'
);
const methodsToAdd = getAsTypedTuple('remove', 'createFile', 'move');
const allMethods = [...methodsToOverwrite, ...methodsToAdd];

type MethodName = UnwrappedArrayValueType<typeof allMethods>;
export type BrowserFsApi = Promisified<FSModule, MethodName> &
  {
    [K in UnwrappedArrayValueType<typeof methodsToAdd>]: (
      ...args: any[]
    ) => Promise<any>;
  };

// Because browserFs loads asynchronously, there is a chance that fs-related
// methods are called before it has finished loading. In that case, we store
// the arguments and the resolver and rejecter methods for the calls, and then
// execute them once the library has loaded.
const onLoadCallbackData = [] as Array<{
  methodName: MethodName;
  args: any[];
  resolve: (...args: any[]) => any;
  reject: (...args: any[]) => any;
}>;
// *Before* browserFs has loaded, every method is a stub that simply stores the
// arguments, the resolver, and the rejecter (as described above) and then
// returns a promise that will be resolved or rejected once browserFs loads.
const browserFsApiNotLoadedStub = allMethods.reduce(
  (stub, methodName) => ({
    ...stub,
    [methodName]: (...args: any[]) =>
      new Promise((resolve, reject) =>
        onLoadCallbackData.push({
          methodName,
          args,
          resolve,
          reject,
        })
      ),
  }),
  {} as Partial<BrowserFsApi>
);

// Force BrowserFS to resemble fs-extra (at least enough for our needs):
const augmentBrowserFS = (fs: any) => {
  methodsToOverwrite.forEach((key) => {
    fs[key] = promisify(fs[key]);
  });

  fs.remove = (path: string) =>
    new Promise(async (resolve, reject) => {
      const stats = await fs.lstat(path);
      if (stats.isDirectory()) {
        fs.rmdir(path, (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        });
      } else {
        fs.unlink(path, (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        });
      }
    });

  // not equivalent to fs-extra, but fits our needs
  fs.createFile = (path: string) =>
    fs.writeFile(path, '', { encoding: 'utf-8' });

  fs.move = fs.rename;

  // Hack-ish polyfill that works for our purposes. Revert once BrowserFS includes support: https://github.com/jvilk/BrowserFS/issues/217
  fs.createReadStream = (
    path: string,
    options: {
      encoding?: string;
    } = {}
  ) =>
    new ReadableStream({
      start(controller) {
        fs.readFile(path, { encoding: options.encoding || 'utf8' })
          .then((contents: Buffer) => {
            controller.enqueue(contents);
            controller.close();
          })
          .catch((err: Error) =>
            controller.error(`Error reading ${path}: ${err.message}.`)
          );
      },
    });
};

export const loadBrowserFs = (
  config: BrowserFS.FileSystemConfiguration,
  callback?: (browserFsApi?: Partial<BrowserFsApi>) => any
) => {
  const browserFs: { require?: typeof BrowserFS.BFSRequire } = {};
  BrowserFS.install(browserFs);
  BrowserFS.configure(config, (err) => {
    if (err) {
      throw err;
    }

    // Once browserFs has loaded, assign it to the exported `browserFsApi`
    // binding...
    const fs = browserFs.require('fs');
    augmentBrowserFS(fs);

    // ...then, go through any calls that were initiated before browserFs had
    // finished loading, and resolve/reject using browserFs.
    onLoadCallbackData.forEach(({ methodName, args, resolve, reject }) =>
      fs[methodName](...args)
        .then(resolve)
        .catch(reject)
    );

    if (callback) {
      callback(fs as unknown as Partial<BrowserFsApi>);
    }
  });

  return browserFsApiNotLoadedStub;
};
