import {
  STARDOG_EXPRESS_CONNECTION_NAME,
  STARDOG_EXPRESS_ENDPOINT,
  STARDOG_SANDBOX_CONNECTION_NAME,
} from 'src/common/constants/configuration';
import { initialState } from 'src/common/reducers/connection';
import { ConnectionState } from 'src/common/store/connection/ConnectionState';
import { dedupeArray } from 'src/common/utils/dedupeArray';
import { isStardogStudioWeb } from 'src/common/utils/isStardogStudioWeb';
import { safelyGet } from 'src/common/utils/safelyGet';
import { InitialData } from 'src/types';

export default (data: InitialData) => {
  if (!isStardogStudioWeb) {
    return {
      ...data,
      version: '1.29.0',
    };
  }
  const prevConnection: ConnectionState = safelyGet(
    data,
    ['connection'],
    initialState
  );
  const reSandboxConnectionName = new RegExp(
    STARDOG_SANDBOX_CONNECTION_NAME,
    'i'
  );
  const saved = Object.keys(prevConnection.saved).reduce((acc, key) => {
    if (!reSandboxConnectionName.test(key)) {
      acc[key] = prevConnection.saved[key];
    }
    return acc;
  }, {});

  const expressConnection = {
    ...initialState.current,
    name: STARDOG_EXPRESS_CONNECTION_NAME,
    username: 'anonymous',
    password: 'anonymous',
    endpoint: STARDOG_EXPRESS_ENDPOINT,
  };

  let current = safelyGet(prevConnection, ['current'], initialState.current);

  if (reSandboxConnectionName.test(current.name)) {
    current = {
      ...expressConnection,
    };
  }
  const filteredSavedNames = dedupeArray(
    prevConnection.savedNames.filter(
      (name) =>
        ![
          STARDOG_EXPRESS_CONNECTION_NAME,
          STARDOG_SANDBOX_CONNECTION_NAME,
        ].includes(name)
    )
  );

  return {
    ...data,
    version: '1.29.0',
    connection: {
      ...prevConnection,
      current,
      saved: {
        [STARDOG_EXPRESS_CONNECTION_NAME]: {
          ...expressConnection,
        },
        ...saved,
      },
      savedNames: [STARDOG_EXPRESS_CONNECTION_NAME, ...filteredSavedNames],
    },
  };
};
