import { Button, Collapse, Colors, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';

import { TEST_IDS } from 'src/common/constants/testIds';
import * as copy from 'src/common/templates/copy';
import { isDevelopment } from 'src/common/utils/isDevelopment';

const REDIRECT_ORIGINS = ['http', 'https'].map(
  (protocol) => `${protocol}://stardog.studio`
);

export const CloudRedirectBanner: React.VFC = () => {
  const addElement =
    isDevelopment() || REDIRECT_ORIGINS.indexOf(window.location.origin) !== -1;
  const [isOpen, setIsOpen] = React.useState<boolean>(addElement);

  return addElement ? (
    <Collapse className="sd-cloud-redirect-banner" isOpen={isOpen}>
      <div
        className="sd-cloud-redirect-banner__content"
        data-testid={TEST_IDS.cloudRedirectBanner.content}
      >
        {copy.components.cloudRedirectBanner.content}
      </div>
      <Button
        className="sd-cloud-redirect-banner__collapse-button"
        data-testid={TEST_IDS.cloudRedirectBanner.collapseButton}
        icon={<Icon color={Colors.WHITE} icon={IconNames.CROSS} />}
        minimal
        onClick={() => {
          setIsOpen(false);
        }}
      />
    </Collapse>
  ) : null;
};
