import { AnchorButton } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';
import { connect } from 'react-redux';

import { exportSavedConnections } from 'src/browser/actions/fileSystem/action-creators/exportSavedConnections';
import { StudioThunkDispatch } from 'src/common/actions/StudioAction';

const mapDispatchToProps = (dispatch: StudioThunkDispatch) => ({
  exportConnections: () => dispatch(exportSavedConnections()),
});

const UnconnectedExportSavedConnectionsButton: React.VFC<
  ReturnType<typeof mapDispatchToProps>
> = ({ exportConnections }) => {
  return (
    <div className="sd-connection-list-export-button">
      <AnchorButton
        icon={IconNames.DOWNLOAD}
        onClick={exportConnections}
        text="Export Saved Connections"
      />
    </div>
  );
};

export const ExportSavedConnectionsButton = connect(
  null,
  mapDispatchToProps
)(UnconnectedExportSavedConnectionsButton);
