import isPlainObject from 'lodash.isplainobject';
import pick from 'lodash.pick';
import { SparqlCompletionData, splitNamespace } from 'stardog-language-utils';
import { alphaSort } from 'vet-bones/bones/utils';

import { FlatMosaicParent } from 'src/common/components/MosaicViewManager';
import {
  ALL_GRAPHS,
  ALL_LOCAL_NAMED_GRAPHS,
  DEFAULT_GRAPH,
  NamedGraphBinding,
  getAllLocalNamedGraph,
} from 'src/common/constants/namedGraphs';
import { SortOrderStatus } from 'src/common/constants/SortOrderStatus';
import { StardogOption } from 'src/common/constants/StardogOption';
import { safelyGet } from 'src/common/utils/safelyGet';

export interface DbManagerSettings {
  areGraphQlSchemasDirty: boolean;
  arePropertiesDirty: boolean;
  areNamespacesDirty: boolean;
  isBiSchemaMappingDirty: boolean;
  selectedDatabaseId: string;
  sortColumn: string;
  sortOrder: SortOrderStatus;
  page: number;
  rowsPerPage: number;
  isCollapsed: boolean;
}

const defaultDbManagerSettings: DbManagerSettings = {
  areGraphQlSchemasDirty: false,
  areNamespacesDirty: false,
  arePropertiesDirty: false,
  isBiSchemaMappingDirty: false,
  selectedDatabaseId: '',
  sortColumn: '',
  sortOrder: SortOrderStatus.DESC,
  page: 0,
  rowsPerPage: 5,
  isCollapsed: false,
};

export type DbProperties = {
  [key: string]: OptionValue;
};

export type DatabaseDetails = {
  biSchemaMapping: string;
  graphQlSchemaIds: readonly string[];
  graphQlSchemasById: { [key: string]: string };
  isPendingAddData: boolean;
  isPendingDropDatabase: boolean;
  isPendingExportDatabase: boolean;
  isPendingOptimizeDatabase: boolean;
  isPendingRemoveData: boolean;
  isPendingRequestBiSchemaMapping: boolean;
  isPendingRequestGraphQlSchemaIds: boolean;
  isPendingUpdateBiSchemaMapping: boolean;
  isPendingUpdateConstraints: boolean;
  isPendingValidateConstraints: boolean;
  pendingGraphQlSchemaIds: readonly string[];
  properties: DbProperties;
  triples?: number;
  namedGraphBindings?: NamedGraphBinding[];
  namedGraphAliasBindings?: NamedGraphBinding[];
  relationshipBindings?: SparqlCompletionData['relationshipBindings'];
  typeBindings?: SparqlCompletionData['typeBindings'];
};

export interface DatabasesMap {
  [id: string]: DatabaseDetails;
}

export const enum DatabaseTileIds {
  SIDEBAR = 'SIDEBAR',
  VIEW = 'VIEW',
}

export interface DatabasesMosaicState {
  TOP: FlatMosaicParent<DatabaseTileIds.SIDEBAR | DatabaseTileIds.VIEW>;
}

export const defaultMosaicState: DatabasesMosaicState = {
  TOP: {
    direction: 'row',
    first: DatabaseTileIds.SIDEBAR,
    second: DatabaseTileIds.VIEW,
    splitPercentage: 20,
  },
};

export type OptionValue = string | boolean | number | string[];

export const optionKeysArray = [
  'name',
  'type', // `reasoning.type`
  'mutable',
  'category',
  'label',
  'description',
  'defaultValue',
  'possibleValues',
];
// How do we identify whether a node in the availableOptions tree is an option
// or just an internal node? We check whether it has this property, assuming
// it'll never be used as a segment in an option's name.
export const optionIdentifyingKey = 'label';
export const optionKeys = new Set(optionKeysArray);

export type AvailableOption = StardogOption & {
  type:
    | 'String'
    | 'Boolean'
    | 'Namespace'
    | 'Enum'
    | 'SchemaGraph'
    | 'SensitiveProperty'
    | 'Duration'
    | 'Integer'
    | 'IRI'
    | 'Double'
    | 'Long';
  mutable: boolean;
  // https://github.com/stardog-union/stardog-studio/issues/101
  mutableWhenOnline?: boolean;
  category: string;
  defaultValue: OptionValue; // See `search.index.datatypes` for string[]
  possibleValues: string[];
  server?: boolean; // after Stardog 7.4.1, server options are denoted by server=true
};
export type AvailableOptions =
  | AvailableOption
  | { [key: string]: AvailableOptions };

export const optionTypeToInputType = (
  t: AvailableOption['type']
): HTMLInputElement['type'] => {
  if (new Set(['Integer', 'Double', 'Long']).has(t)) {
    return 'number';
  }
  return 'text';
};

export class DatabasesState {
  constructor(
    public databaseIds: readonly string[] = [],
    public databasesById: DatabasesMap = {},
    public requesting = false,
    public dbManagerSettings = defaultDbManagerSettings,
    public availableOptions: AvailableOptions = {},
    public availableServerOptions: AvailableOptions = {},
    public mosaicState = defaultMosaicState,
    public pending = false,
    public pendingCreateDatabase = false
  ) {}
}

export const isOnline = (db: DatabaseDetails): boolean =>
  safelyGet(db, ['properties', 'database.online'], false);

export const isEdgePropertiesEnabled = (db: DatabaseDetails): boolean =>
  safelyGet(db, ['properties', 'edge.properties'], false);

export const getNamespaces = (db: DatabaseDetails): string[] =>
  safelyGet(db, ['properties', 'database.namespaces'], []);

export const DEFAULT_REASONING_SCHEMA = 'default';

const schemaSorter = alphaSort(
  (schema: string) => splitNamespace(schema)[0] || ''
);

const getSortedSchemaMap = (
  schemas: string[]
): { [schemaName: string]: string[] } => {
  return schemas.sort(schemaSorter).reduce((acc, schema) => {
    const [schemaName, graphIri] = splitNamespace(schema);
    if (schemaName === DEFAULT_REASONING_SCHEMA) {
      return acc;
    }
    if (!acc[schemaName]) {
      acc[schemaName] = [graphIri];
    } else {
      acc[schemaName].push(graphIri);
    }
    return acc;
  }, {});
};

export const getReasoningSchemas = (
  db: DatabaseDetails,
  stardogSupportsVirtualTransparency: boolean
): { [schemaName: string]: string[] } => {
  const schemas = (safelyGet(db, ['properties', 'reasoning.schemas']) ||
    []) as string[];
  const schemaMap = getSortedSchemaMap(schemas);
  return {
    [DEFAULT_REASONING_SCHEMA]: getDefaultReasoningSchemaGraphs(
      db,
      stardogSupportsVirtualTransparency
    ),
    ...schemaMap,
  };
};

const getDefaultReasoningSchemaGraphs = (
  db: DatabaseDetails,
  stardogSupportsVirtualTransparency: boolean
) =>
  (
    (safelyGet(db, ['properties', 'reasoning.schema.graphs']) || []) as string[]
  ).map((schemaGraph) => {
    switch (schemaGraph) {
      case '*':
        // https://github.com/stardog-union/stardog/blob/98e6e50ed77952c931303302bdafa20f84040605/core/shared/main/src/com/complexible/stardog/metadata/TextIO.java#L189
        // We're not actually sure if this is 'tag:stardog:api:context:local`
        // or `tag:stardog:api:context:all` since stardog shorthands both to `*`,
        // but we can assume it's whatever the collection of local graphs is
        return getAllLocalNamedGraph(stardogSupportsVirtualTransparency);
      case 'default':
        return DEFAULT_GRAPH;
      default:
        return schemaGraph;
    }
  });

const specialURNs = {
  [ALL_GRAPHS]: '*',
  [ALL_LOCAL_NAMED_GRAPHS]: '*',
  [DEFAULT_GRAPH]: 'default',
};

/*
 * Stardog's Update DB API differs from the availableOptions API in two ways:
 * 1 - Default values with the special tag urns are not returned by stardog's DB
 *     properties APIs, instead their shortforms are returned -- the values in
 *     `specialURNs` above.
 * 2 - Default values that are arrays indicate the update API accepts
 *     comma-separated lists.
 * So this function 'normalizes' the default values to match those we'll be
 working with in state and sending back to Stardog in updates.
*/
export const normalizeDefaultValue = (defaultValue: OptionValue) => {
  if (typeof defaultValue !== 'object') {
    return defaultValue;
  }
  return defaultValue
    .sort()
    .map((v: string) => specialURNs[v] || v)
    .join(', ');
};
export const normalizeCurrentValue = (currentValue: OptionValue) => {
  if (typeof currentValue !== 'object') {
    return currentValue;
  }
  return currentValue.sort().join(', ');
};

/* Traverse the `availableOptions` tree returned by Stardog, flattening it, and
 * filtering based on `includeProperty`.
 */
export const flattenProperties = (
  properties: AvailableOptions,
  includeProperty = (_) => true
): AvailableOption[] => {
  return Object.entries(properties).reduce((acc, x) => {
    const [, property] = x;
    const foundProperty = property[optionIdentifyingKey]
      ? pick(property, optionKeysArray)
      : null;
    // Cannot blanket omit the elements of `optionKeysArray` because some
    // option name segments collide with elements of `optionKeysArray`
    /* const childProperties = omit(property, optionKeysArray); */
    const childProperties = Object.entries(property).reduce(
      (acc, [key, value]) => {
        if (isPlainObject(value)) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );
    if (foundProperty && includeProperty(foundProperty)) {
      return [
        ...acc,
        foundProperty,
        ...flattenProperties(childProperties, includeProperty),
      ];
    }
    return [...acc, ...flattenProperties(childProperties, includeProperty)];
  }, []);
};

// Properties hidden from the standard property list forms.
// - `database.online` is managed with a separate toggle
// - `database.namespaces` is managed with a special-purpose form
export const hiddenProperties = new Set([
  'database.online',
  'database.namespaces',
  'database.name',
]);

// Used when current connection's Stardog version does not support the API for
// retrieving this data.
// Data generated by cherry-picking the Stardog commit implementing this API
// onto the final Stardog release excluding the API method, which is 6.2.2.
// $ git checkout 6.2.2
// $ git cherry-pick 0c742d6a655cea6b1b672cc676572bb084e0f6eb
export const staticDatabaseOptions = {
  database: {
    archetypes: {
      name: 'database.archetypes',
      type: 'String',
      mutable: true,
      category: 'Database',
      label: 'Database Archetypes',
      description: 'The name of one or more database archetypes',
      defaultValue: [],
    },
    connection: {
      timeout: {
        name: 'database.connection.timeout',
        type: 'Duration',
        mutable: true,
        category: 'Database',
        label: 'Connection Timeout',
        description:
          'The amount of time a connection to the database can be open, but inactive, before being automatically closed to reclaim the resources',
        defaultValue: '10m',
      },
    },
    name: {
      name: 'database.name',
      type: 'String',
      mutable: false,
      category: 'Database',
      label: 'Name',
      description:
        'A database name, the legal value of which is given by the regular expression [A-Za-z]{1}[A-Za-z0-9_-]',
    },
    namespaces: {
      name: 'database.namespaces',
      type: 'Namespace',
      mutable: true,
      category: 'Database',
      label: 'Namespaces',
      description: 'The default namespaces for the database',
      defaultValue: [
        ' :: http://api.stardog.com/',
        'owl :: http://www.w3.org/2002/07/owl#',
        'rdf :: http://www.w3.org/1999/02/22-rdf-syntax-ns#',
        'rdfs :: http://www.w3.org/2000/01/rdf-schema#',
        'stardog :: tag:stardog:api:',
        'xsd :: http://www.w3.org/2001/XMLSchema#',
      ],
    },
    online: {
      name: 'database.online',
      type: 'Boolean',
      mutable: false,
      category: 'Database',
      label: 'Online',
      description: 'Whether or not the database is online',
      defaultValue: true,
    },
  },
  docs: {
    default: {
      rdf: {
        extractors: {
          name: 'docs.default.rdf.extractors',
          type: 'String',
          mutable: true,
          category: 'BITES',
          label: 'RDF Extractors',
          description:
            'Comma-separated list of names of RDF extractors to use when processing documents when no RDF extractor names are given',
          defaultValue: 'tika',
        },
      },
      text: {
        extractors: {
          name: 'docs.default.text.extractors',
          type: 'String',
          mutable: true,
          category: 'BITES',
          label: 'Text Extractors',
          description:
            'Comma-separated list of names of text extractors to use when processing documents when no text extractor names are given',
          defaultValue: 'tika',
        },
      },
    },
    filesystem: {
      uri: {
        name: 'docs.filesystem.uri',
        type: 'String',
        mutable: true,
        category: 'BITES',
        label: 'Filesystem URI',
        description:
          'A URI indicating which FileSystem provider to use for document storage. In addition to local storage (file:///), documents can be stored on Amazon S3 (s3:///) or document storage can be disabled altogether (none)',
        defaultValue: 'file:///',
      },
    },
    opennlp: {
      models: {
        path: {
          name: 'docs.opennlp.models.path',
          type: 'String',
          mutable: true,
          category: 'BITES',
          label: 'OpenNLP Models Path',
          description: 'The directory where OpenNLP models are located',
          defaultValue: '',
        },
      },
    },
    path: {
      name: 'docs.path',
      type: 'String',
      mutable: true,
      category: 'BITES',
      label: 'Path',
      description:
        'The path under which documents will be stored. A relative path is relative to the database directory. S3 storage should specify an absolute path with the bucket name as the first part of the path',
      defaultValue: 'docs',
    },
    s3: {
      protocol: {
        name: 'docs.s3.protocol',
        type: 'String',
        mutable: true,
        category: 'BITES',
        label: 'S3 Protocol',
        description:
          'Protocol used when storing on S3 (and compatible) stores. Can be set to http to disable TLS/SSL',
        defaultValue: 'HTTPS',
      },
    },
  },
  graphql: {
    default: {
      limit: {
        name: 'graphql.default.limit',
        type: 'Long',
        mutable: true,
        category: 'GraphQL',
        label: 'Default Limit',
        description: 'The maximum number of results a GraphQL query can return',
        defaultValue: 10000,
      },
    },
  },
  icv: {
    active: {
      graphs: {
        name: 'icv.active.graphs',
        type: 'IRI',
        mutable: false,
        category: 'ICV',
        label: 'Active Graphs',
        description:
          'Specifies which part of the database, in terms of named graphs, is checked with IC validation',
        defaultValue: [ALL_GRAPHS],
      },
    },
    consistency: {
      automatic: {
        name: 'icv.consistency.automatic',
        type: 'Boolean',
        mutable: true,
        category: 'ICV',
        label: 'Automatic Consistency',
        description:
          'Enables automatic ICV consistency check as part of transactions',
        defaultValue: false,
      },
    },
    enabled: {
      name: 'icv.enabled',
      type: 'Boolean',
      mutable: true,
      category: 'ICV',
      label: '"Guard Mode"',
      description:
        'Determines if all database mutations are subject to IC validation',
      defaultValue: false,
    },
    explanation: {
      limit: {
        name: 'icv.explanation.limit',
        type: 'Integer',
        mutable: true,
        category: 'ICV',
        label: 'Explanation Limit',
        description:
          'The number of violations that will be computed and returned in the error message when guard mode is enabled. If the option is set to 0 no explanations will be computed and transaction failure will only indicate there was a violation without specifying which constraint failed.',
        defaultValue: 1,
      },
    },
    reasoning: {
      enabled: {
        name: 'icv.reasoning.enabled',
        type: 'Boolean',
        mutable: true,
        category: 'ICV',
        label: 'Reasoning Enabled',
        description: 'Determines if reasoning is used during IC validation',
        defaultValue: false,
      },
    },
  },
  index: {
    differential: {
      enable: {
        limit: {
          name: 'index.differential.enable.limit',
          type: 'Integer',
          mutable: true,
          category: 'Index',
          label: 'Differential Index Minimum',
          description:
            'The minimum size of the Stardog database before differential indexes are used',
          defaultValue: 500000,
        },
      },
      merge: {
        limit: {
          name: 'index.differential.merge.limit',
          type: 'Integer',
          mutable: true,
          category: 'Index',
          label: 'Differential Merge Maximum',
          description:
            'The size in number of RDF triples before the differential indexes are merged to the main indexes',
          defaultValue: 20000,
        },
      },
    },
    literals: {
      canonical: {
        name: 'index.literals.canonical',
        type: 'Boolean',
        mutable: false,
        category: 'Index',
        label: 'Literal Canonicalization',
        description: 'Enables RDF literal canonicalization',
        defaultValue: true,
      },
    },
    lucene: {
      mmap: {
        name: 'index.lucene.mmap',
        type: 'Boolean',
        mutable: true,
        category: 'Database',
        label: 'Lucene Memory-Mapping',
        description:
          'Enables memory-mapping in lucene indices (e.g., search, spatial)',
        defaultValue: true,
      },
    },
    named: {
      graphs: {
        name: 'index.named.graphs',
        type: 'Boolean',
        mutable: false,
        category: 'Index',
        label: 'Named Graph Indexing',
        description:
          'Enables optimized index support for named graphs; speeds SPARQL query evaluation with named graphs at the cost of some overhead for database loading and index maintenance',
        defaultValue: true,
      },
    },
    statistics: {
      cache: {
        capacity: {
          name: 'index.statistics.cache.capacity',
          type: 'Integer',
          mutable: true,
          category: 'Index',
          label: 'Cache Capacity',
          description:
            'The max capacity for the query pattern cardinality cache that is shared across queries to the same database',
          defaultValue: 1024,
        },
      },
      characteristic: {
        limit: {
          name: 'index.statistics.characteristic.limit',
          type: 'Integer',
          mutable: true,
          category: 'Index',
          label: 'Statisctics Characteristic Sets Limit',
          description:
            'The max number of characteristic sets computed as a part of the statistical summary of the database. More diverse datasets may require a higher number for more accurate query planning. The downside is higher memory footprint and slower planning',
          defaultValue: 10000,
        },
      },
      update: {
        automatic: {
          name: 'index.statistics.update.automatic',
          type: 'Boolean',
          mutable: true,
          category: 'Index',
          label: 'Automatic Statistics Update',
          description:
            'Determines whether statistics are maintained automatically',
          defaultValue: true,
        },
        blocking: {
          ratio: {
            name: 'index.statistics.update.blocking.ratio',
            type: 'Double',
            mutable: true,
            category: 'Index',
            label: 'Statistics Update Blocking Ratio',
            description:
              'Once the updates go over this limit statistics computation will be performed synchronously within the transaction instead of a background thread',
            defaultValue: 0,
          },
        },
        min: {
          size: {
            name: 'index.statistics.update.min.size',
            type: 'Long',
            mutable: true,
            category: 'Index',
            label: 'Minimum Statistics Update Size',
            description:
              'Minimum number of triples that should be in the database for statistics to be updated automatically',
            defaultValue: 10000,
          },
        },
        ratio: {
          name: 'index.statistics.update.ratio',
          type: 'Double',
          mutable: true,
          category: 'Index',
          label: 'Statistics Update Ratio',
          description:
            'Ratio of updated triples to the number of triples in the database that triggers the automatic statistics computation in a background thread',
          defaultValue: 0.1,
        },
      },
    },
    writer: {
      merge: {
        limit: {
          name: 'index.writer.merge.limit',
          type: 'Integer',
          mutable: true,
          category: 'Index',
          label: 'Writer Merge Limit',
          description:
            'Maximum number of triples to keep in memory for merging interleaving additions and removals while querying uncommitted state',
          defaultValue: 1000,
        },
      },
    },
  },
  literal: {
    comparison: {
      extended: {
        name: 'literal.comparison.extended',
        type: 'Boolean',
        mutable: true,
        category: 'Database',
        label: 'Extended Comparison',
        description:
          'Controls whether query evaluation will use extended literal comparison',
        defaultValue: true,
      },
    },
    language: {
      normalization: {
        name: 'literal.language.normalization',
        type: 'Enum',
        mutable: false,
        category: 'Database',
        label: 'Language Normalization',
        description:
          'Configuration option for determining the normalization algorithm for the langauge tags of literals',
        possibleValues: ['DEFAULT', 'RFC3066', 'BCP47'],
        defaultValue: 'DEFAULT',
      },
    },
  },
  preserve: {
    bnode: {
      ids: {
        name: 'preserve.bnode.ids',
        type: 'Boolean',
        mutable: false,
        category: 'Database',
        label: 'Preserve BNode IDs',
        description:
          'Determines how the Stardog parser handles bnode identifiers that may be present in RDF input. If this property is enabled, parsing and data loading performance are improved; but the other effect is that if distinct input files use (randomly or intentionally) the same bnode identifier, that bnode will point to one and the same node in the database. If you have input files that use explicit bnode identifiers, and more than one of those files may use the same bnode identifiers, and you don’t want those bnodes to be smushed into a single node in the database, then this configuration option should be disabled',
        defaultValue: true,
      },
    },
  },
  progress: {
    monitor: {
      enabled: {
        name: 'progress.monitor.enabled',
        type: 'Boolean',
        mutable: true,
        category: 'Database',
        label: 'Progress Monitoring Enabled',
        description: 'Progress Monitoring Enabled',
        defaultValue: true,
      },
    },
  },
  query: {
    all: {
      graphs: {
        name: 'query.all.graphs',
        type: 'Boolean',
        mutable: true,
        category: 'Database',
        label: 'Query All Graphs',
        description:
          'Whether to query over the default graph AND the union of all named graphs',
        defaultValue: false,
      },
    },
    describe: {
      strategy: {
        name: 'query.describe.strategy',
        type: 'String',
        mutable: true,
        category: 'Database',
        label: 'Describe Strategy',
        description: 'The default DESCRIBE query strategy for the database',
        defaultValue: 'default',
      },
    },
    plan: {
      reuse: {
        name: 'query.plan.reuse',
        type: 'Enum',
        mutable: true,
        category: 'Database',
        label: 'Query Plan Reuse',
        description: 'The conditions under which a cached plan will be reused',
        possibleValues: ['ALWAYS', 'NEVER', 'CARDINALITY'],
        defaultValue: 'ALWAYS',
      },
    },
    pp: {
      contexts: {
        name: 'query.pp.contexts',
        type: 'Boolean',
        mutable: true,
        category: 'Database',
        label: 'Property Path Contexts',
        description:
          'Determines how property paths interact with named graphs in the data. When set to true and the property path pattern is in the default scope (i.e. not inside a graph keyword), Stardog will check that paths do not span multiple named graphs (per 18.1.7). For this to affect query results either there should be multiple FROM clauses or query.all.graphs must be also set to true. This database option overrides any global server settings',
        defaultValue: false,
      },
    },
    timeout: {
      name: 'query.timeout',
      type: 'Duration',
      mutable: true,
      category: 'Database',
      label: 'Query Timeout',
      description: 'Determines max execution time for query evaluation',
      defaultValue: '5m',
    },
  },
  reasoning: {
    approximate: {
      name: 'reasoning.approximate',
      type: 'Boolean',
      mutable: true,
      category: 'Reasoning',
      label: 'Approximate',
      description:
        'Enables approximate reasoning. With this flag enabled Stardog will approximate an axiom that is outside the profile Stardog supports and normally ignored. For example, an equivalent class axiom might be split into two subclass axioms and only one subclass axiom is used',
      defaultValue: false,
    },
    classify: {
      eager: {
        name: 'reasoning.classify.eager',
        type: 'Boolean',
        mutable: true,
        category: 'Reasoning',
        label: 'Eagerly Classify',
        description:
          'Perform schema classification eagerly when the schema is loaded. Classifying eagerly ensures subclass and equivalence queries between named classes can be answered with a simple lookup. However, if the schema is changing frequently then this option can be turned off so classification is performed only if necessary',
        defaultValue: true,
      },
    },
    consistency: {
      automatic: {
        name: 'reasoning.consistency.automatic',
        type: 'Boolean',
        mutable: true,
        category: 'Reasoning',
        label: 'Automatic Consistency',
        description:
          'Enables automatic consistency checking with respect to a transaction',
        defaultValue: false,
      },
    },
    punning: {
      enabled: {
        name: 'reasoning.punning.enabled',
        type: 'Boolean',
        mutable: true,
        category: 'Reasoning',
        label: 'Punning',
        description: 'Enables punning',
        defaultValue: false,
      },
    },
    sameas: {
      name: 'reasoning.sameas',
      type: 'Enum',
      mutable: true,
      category: 'Reasoning',
      label: 'SameAs',
      description:
        'Option to enable owl:sameAs reasoning. When this option is set to ON reflexive, symmetric, and transitive closure of the owl:sameAs triples in the database is computed. When it is set to FULL, owl:sameAs inferences are computed based on the schema axioms such as functional properties',
      possibleValues: ['OFF', 'ON', 'FULL'],
      defaultValue: 'OFF',
    },
    schema: {
      graphs: {
        name: 'reasoning.schema.graphs',
        type: 'IRI',
        mutable: true,
        category: 'Reasoning',
        label: 'Schema Graphs',
        description:
          'Determines which, if any, named graph or graphs contains the "TBox", i.e., the schema part of the data. The legal value is a comma-separated list of named graph identifiers, including (optionally) the special names, tag:stardog:api:context:default and tag:stardog:api:context:all, which represent the default graph and the union of all named graphs and the default graph, respectively. In the context of database configurations only, Stardog will recognize default and * as short forms of those URIs, respectively',
        defaultValue: [ALL_GRAPHS],
      },
      timeout: {
        name: 'reasoning.schema.timeout',
        type: 'Duration',
        mutable: true,
        category: 'Reasoning',
        label: 'Schema Reasoning Timeout',
        description:
          'Timeout for schema reasoning. If schema reasoning cannot be completed in the specified time then only RDFS reasoning will be performed for the schema which might yield incomplete answers for the schema queries',
        defaultValue: '1m',
      },
    },
    type: {
      name: 'reasoning.type',
      type: 'Enum',
      mutable: true,
      category: 'Reasoning',
      label: 'Type',
      description: 'Specifies the reasoning type associated with the database',
      possibleValues: ['NONE', 'RDFS', 'QL', 'EL', 'RL', 'DL', 'SL'],
      defaultValue: 'SL',
    },
    virtual: {
      graph: {
        enabled: {
          name: 'reasoning.virtual.graph.enabled',
          type: 'Boolean',
          mutable: true,
          category: 'Reasoning',
          label: 'Virtual Graph',
          description:
            'Flag to enable reasoning over virtual graphs and SERVICE clauses',
          defaultValue: true,
        },
      },
    },
  },
  search: {
    default: {
      limit: {
        name: 'search.default.limit',
        type: 'Integer',
        mutable: true,
        category: 'Search',
        label: 'Default Limit',
        description:
          'Specify the default limit on the number of results returned from a full-text search (-1 returns all results)',
        defaultValue: -1,
      },
    },
    enabled: {
      name: 'search.enabled',
      type: 'Boolean',
      mutable: true,
      category: 'Search',
      label: 'Semantic Search',
      description: 'Enables semantic search for the database',
      defaultValue: false,
    },
    index: {
      datatypes: {
        name: 'search.index.datatypes',
        type: 'IRI',
        mutable: false,
        category: 'Search',
        label: 'Index Datatypes',
        description:
          'Option to specify the datatypes for which to index literals',
        defaultValue: [
          'http://www.w3.org/2001/XMLSchema#string',
          'http://www.w3.org/1999/02/22-rdf-syntax-ns#langString',
        ],
      },
    },
    reindex: {
      tx: {
        name: 'search.reindex.tx',
        type: 'Boolean',
        mutable: true,
        category: 'Search',
        label: 'Reindex Tx',
        description:
          'Whether literals added during a transaction are automatically indexed',
        defaultValue: true,
      },
    },
    wildcard: {
      search: {
        enabled: {
          name: 'search.wildcard.search.enabled',
          type: 'Boolean',
          mutable: true,
          category: 'Search',
          label: 'Lucene Wildcard Search',
          description:
            'Enable support in Lucene for searches with leading wildcards',
          defaultValue: false,
        },
      },
    },
  },
  security: {
    named: {
      graphs: {
        name: 'security.named.graphs',
        type: 'Boolean',
        mutable: true,
        category: 'Security',
        label: 'Named Graph Security',
        description: 'Whether named graph security is enabled',
        defaultValue: false,
      },
    },
  },
  spatial: {
    enabled: {
      name: 'spatial.enabled',
      type: 'Boolean',
      mutable: true,
      category: 'Spatial',
      label: 'Geospatial Search',
      description: 'Enables geospatial search for the database',
      defaultValue: false,
    },
    precision: {
      name: 'spatial.precision',
      type: 'Integer',
      mutable: false,
      category: 'Spatial',
      label: 'Precision',
      description:
        'Specifies the precision used for the indexing of geospatial data. The smaller the value, the less precision',
      defaultValue: 11,
    },
    result: {
      limit: {
        name: 'spatial.result.limit',
        type: 'Integer',
        mutable: true,
        category: 'Spatial',
        label: 'Result Limit',
        description:
          'Specify the default limit on the number of results returned from a geospatial query (-1 returns all results)',
        defaultValue: 10000,
      },
    },
  },
  strict: {
    parsing: {
      name: 'strict.parsing',
      type: 'Boolean',
      mutable: false,
      category: 'Database',
      label: 'Strict Parsing',
      description: 'Controls whether Stardog parses RDF strictly',
      defaultValue: true,
    },
  },
  transaction: {
    isolation: {
      name: 'transaction.isolation',
      type: 'Enum',
      mutable: true,
      category: 'Database',
      label: 'Transaction Isolation',
      description: 'Configures isolation level for transactions',
      possibleValues: ['SNAPSHOT', 'SERIALIZABLE'],
      defaultValue: 'SNAPSHOT',
    },
    logging: {
      name: 'transaction.logging',
      type: 'Boolean',
      mutable: true,
      category: 'Database',
      label: 'Transaction Logging',
      description: 'Enables logged transactions',
      defaultValue: false,
      rotation: {
        remove: {
          name: 'transaction.logging.rotation.remove',
          type: 'Boolean',
          mutable: true,
          category: 'Database',
          label: 'Transaction Logging Rotation Remove',
          description:
            'Determines whether old log files will be deleted after rotation',
          defaultValue: true,
        },
        size: {
          name: 'transaction.logging.rotation.size',
          type: 'Long',
          mutable: true,
          category: 'Database',
          label: 'Transaction Logging Rotation Size',
          description:
            'Determines the size (in bytes) at which the transaction log will be rotated',
          defaultValue: 524288000,
        },
      },
    },
  },
};
