import { VisualizationsState } from 'src/common/store/visualization/VisualizationState';

export const getSanitizedVisualizationsState = (
  visualizations: VisualizationsState
): VisualizationsState | { settings: string } => {
  return {
    ...visualizations,
    settings: 'redacted for user experience',
  };
};
