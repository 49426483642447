import { browserApi } from 'src/browser/apis/browserApi';
import { CachePayload } from 'src/browser/apis/browserQueryCache';
import { notebookActionCreators } from 'src/common/actions/notebook/notebookActionCreators';
import { IpcEventType } from 'src/common/constants/events';
import { CACHED_ROWS_TO_FETCH } from 'src/common/utils/rpc/types';

const pending = new Map<string, boolean>();

export const registerCacheFetchListener = async (dispatch) => {
  browserApi.ipcRenderer.on(
    IpcEventType.RECEIVED_BINDINGS_CACHE,
    (_, payload: CachePayload) => {
      dispatch(notebookActionCreators.fetchRowFromCache(payload));

      const { bindings, cacheKeySuffix, noteId, row } = payload;
      for (let i = row; i < row + bindings.length; i += 1) {
        pending.delete(`noteId=${noteId}&row=${i}${cacheKeySuffix || ''}`);
      }
    }
  );
};

export const fetchRowFromCache = async (state: {
  cacheKeySuffix?: string;
  noteId: string;
  row: number;
  numRows?: number;
}) => {
  const { cacheKeySuffix, noteId, row, numRows } = state;
  if (numRows === 0) {
    return;
  }
  const numRowsToFetch = numRows || CACHED_ROWS_TO_FETCH;
  const key = `noteId=${noteId}&row=${row}${cacheKeySuffix || ''}`;
  if (!pending.has(key)) {
    pending.set(key, true);

    return browserApi.ipcRenderer
      .invoke(IpcEventType.FETCH_BINDINGS_CACHE, {
        cacheKeySuffix,
        id: noteId,
        numRows: numRowsToFetch,
        row,
      })
      .catch((err) => {
        pending.delete(key);
        throw err;
      });
  }
};

export const fetchRowsFromCacheInChunks = (state: {
  cacheKeySuffix?: string;
  noteId: string;
  numRows?: number;
  rowCount: number;
}) => {
  const { cacheKeySuffix, noteId, numRows, rowCount } = state;
  let row = 0;

  const promises: Array<Promise<void>> = [];
  while (row < rowCount) {
    const remainder = rowCount - row;
    const chunkSize = Math.min(numRows || CACHED_ROWS_TO_FETCH, remainder);

    promises.push(
      fetchRowFromCache({
        cacheKeySuffix,
        noteId,
        numRows: chunkSize,
        row,
      })
    );

    row += numRows || CACHED_ROWS_TO_FETCH;
  }
  return Promise.all(promises);
};
