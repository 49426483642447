import {
  ActionCreatorAction,
  createTypedAction,
} from 'src/common/actions/types';
import { QueryHistoryEntry } from 'src/common/store/queryHistory/QueryHistoryState';

export const enum QueryHistoryActionType {
  UPDATE_QUERY_HISTORY = 'UPDATE_QUERY_HISTORY',
  DELETE_QUERY_HISTORY_ITEM = 'DELETE_QUERY_HISTORY_ITEM',
  DELETE_QUERY_HISTORY = 'DELETE_QUERY_HISTORY',
}
export type QueryHistoryAction = ActionCreatorAction<
  typeof queryHistoryActionCreators
>;

export const queryHistoryActionCreators = {
  deleteQueryHistory: () =>
    createTypedAction(QueryHistoryActionType.DELETE_QUERY_HISTORY),
  deleteQueryHistoryItem: (id: QueryHistoryEntry['id']) =>
    createTypedAction(QueryHistoryActionType.DELETE_QUERY_HISTORY_ITEM, id),
  updateQueryHistory: (data: Partial<QueryHistoryEntry>) =>
    createTypedAction(QueryHistoryActionType.UPDATE_QUERY_HISTORY, data),
};
