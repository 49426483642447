import { noop } from 'vet-bones/bones/utils';

import { browserApi } from 'src/browser/apis/browserApi';
import {
  StudioStateGetter,
  StudioThunkDispatch,
} from 'src/common/actions/StudioAction';

export const withLeaveDatabasesConfirmation =
  ({
    message = 'Are you sure you want to leave?',
    onConfirmationSuccess = noop,
    onConfirmationFailure = noop,
  }: {
    message?: string;
    onConfirmationSuccess?: () => any;
    onConfirmationFailure?: () => any;
  }) =>
  async (_: StudioThunkDispatch, getState: StudioStateGetter) => {
    const {
      areGraphQlSchemasDirty,
      areNamespacesDirty,
      arePropertiesDirty,
      isBiSchemaMappingDirty,
    } = getState().databases.dbManagerSettings;

    const unsavedChangesLocations = [];
    if (areGraphQlSchemasDirty) {
      unsavedChangesLocations.push('GraphQL Schemas');
    }
    if (areNamespacesDirty) {
      unsavedChangesLocations.push('Namespaces');
    }
    if (arePropertiesDirty) {
      unsavedChangesLocations.push('Properties');
    }
    if (isBiSchemaMappingDirty) {
      unsavedChangesLocations.push('BI Schema Mapping');
    }

    if (unsavedChangesLocations.length) {
      browserApi.confirmation.showConfirmation({
        message: `You have unsaved changes in ${unsavedChangesLocations.join(
          ' and '
        )}. ${message}`,
        onConfirmationSuccess,
        onConfirmationFailure,
      });
    } else {
      onConfirmationSuccess();
    }
  };
