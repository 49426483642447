import { browserApi } from 'src/browser/apis/browserApi';

export const warnUnsupportedBrowser = async () => {
  const options = {
    className: 'sd-unsupported-browser-alert',
    type: 'question',
    title: 'Unsupported Browser',
    // icon: TODO,
    defaultId: 0,
    message:
      'You’re using a web browser we don’t support. Try the latest version of Firefox, Google Chrome, or Microsoft Edge to have a better experience on Stardog Studio.',
    buttons: ['Acknowledge'],
  };

  try {
    await browserApi.dialog.showMessageBox(options);
  } catch (ex) {
    // Log and eat the error. This should never actually happen, but
    // it's at least logically possible that `dialog.showMessageBox`
    // throws, and we don't want unhandled Promise rejections.
    console.error(ex);
  }
};
