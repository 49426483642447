import * as React from 'react';
import { connect } from 'react-redux';
import { noop } from 'vet-bones/bones/utils';

import { StudioThunkDispatch } from 'src/common/actions/StudioAction';
import { LanguageId } from 'src/common/constants/LanguageId';
import { StatusBarLanguageMenu } from 'src/common/containers/App/components/StatusBarLanguageMenu';
import { ModelsTabIds } from 'src/common/store/models/ModelsState';
import { ReduxState } from 'src/types';

const mapStateToProps = ({ models }: ReduxState) => {
  const { tabId } = models;
  return {
    tabId,
  };
};

const mapDispatchToProps = (_: StudioThunkDispatch) => ({});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export const UnconnectedModelsStatusBar = ({
  tabId,
}: StateProps & DispatchProps) => {
  if (tabId === ModelsTabIds.TEXT_EDITOR) {
    return (
      <StatusBarLanguageMenu
        languageIds={['SHACL']}
        onChange={noop}
        value={LanguageId.SHACL}
      />
    );
  }
  return null;
};

export const ModelsStatusBar = connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(UnconnectedModelsStatusBar);
