import { FlatMosaicParent } from 'src/common/components/MosaicViewManager';
import { SortOrderStatus } from 'src/common/constants/SortOrderStatus';

export interface User {
  username: string;
  superuser: boolean;
  enabled: boolean;
  roles: Array<Role['rolename']>;
  permissions: Permission[];
}

export interface Role {
  rolename: string;
  permissions: Permission[];
}

export interface Permission {
  action: Action;
  explicit?: boolean;
  resource_type: ResourceType;
  resource: string[];
}

export enum Action {
  ALL = 'ALL',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  READ = 'READ',
  WRITE = 'WRITE',
  GRANT = 'GRANT',
  REVOKE = 'REVOKE',
  EXECUTE = 'EXECUTE',
}

export enum ResourceType {
  '*' = '*',
  DB = 'db',
  USER = 'user',
  ROLE = 'role',
  ADMIN = 'admin',
  METADATA = 'metadata',
  NAMED_GRAPH = 'named-graph',
  VIRTUAL_GRAPH = 'virtual-graph',
  ICV_CONSTRAINTS = 'icv-constraints',
  DATA_SOURCE = 'data-source',
  DBMS_ADMIN = 'dbms-admin',
  SENSITIVE_PROPERTIES = 'sensitive-properties',
  STORED_QUERY = 'stored-query',
}

export interface Password {
  min: number;
  max: number;
  regex: RegExp;
}

export const enum SecurityTileIds {
  USERS = 'USERS',
  ROLES = 'ROLES',
  SIDEBAR = 'SIDEBAR',
  VIEW = 'VIEW',
}

export interface SecurityMosaicState {
  TOP: FlatMosaicParent<SecurityTileIds.SIDEBAR | SecurityTileIds.VIEW>;
  SIDEBAR: FlatMosaicParent<SecurityTileIds.USERS | SecurityTileIds.ROLES>;
}

export type SecurityMosaicStateChange =
  | {
      TOP: SecurityMosaicState['TOP'];
    }
  | {
      SIDEBAR: SecurityMosaicState['SIDEBAR'];
    };

export const defaultMosaicState: SecurityMosaicState = {
  TOP: {
    direction: 'row',
    first: SecurityTileIds.SIDEBAR,
    second: SecurityTileIds.VIEW,
    splitPercentage: 20,
  },
  SIDEBAR: {
    direction: 'column',
    first: SecurityTileIds.USERS,
    second: SecurityTileIds.ROLES,
    splitPercentage: 50,
  },
};

export enum PermissionAssignee {
  USER = 'user',
  ROLE = 'role',
  ADMIN = 'admin',
  NEITHER = 'neither',
}

export interface SortPermissionsParams {
  which: PermissionAssignee;
  column?: keyof Permission;
  direction?: SortOrderStatus;
  username: string;
}

export class SecurityState {
  constructor(
    public which: PermissionAssignee = PermissionAssignee.NEITHER,
    public userIds: string[] = [],
    public users: { [userId: string]: User } = {},
    public user: string = null,
    public roleIds: string[] = [],
    public roles: { [roleId: string]: Role } = {},
    public role: string = null,
    public pending = false,
    public password: Password = {
      min: 4,
      max: 1024,
      regex: /.*/,
    },
    public userPermissionSort: SortOrderStatus = SortOrderStatus.ASC,
    public userPermissionColumn: keyof Permission = 'action',
    public rolePermissionSort: SortOrderStatus = SortOrderStatus.ASC,
    public rolePermissionColumn: keyof Permission = 'action',
    public shouldLimitPermissions: boolean = false,
    public mosaicState: SecurityMosaicState = defaultMosaicState
  ) {}
}
