import { unflatten } from 'flat';
import { namespaceObjToArray } from 'stardog-language-utils';
import { alphaSort } from 'vet-bones/bones/utils';

import {
  ConnectionAction,
  ConnectionActionType,
} from 'src/common/actions/connection/connectionActionCreators';
import {
  DatabasesAction,
  DatabasesActionType,
} from 'src/common/actions/databases/databasesActionCreators';
import { isBody } from 'src/common/actions/request/types';
import {
  AvailableOptions,
  DatabaseDetails,
  DatabasesState,
  OptionValue,
  staticDatabaseOptions,
} from 'src/common/store/databases/DatabasesState';
import { emptyArray } from 'src/common/utils/emptyArray';
import { emptyObject } from 'src/common/utils/emptyObject';
import { safelyGet } from 'src/common/utils/safelyGet';

const GRAPHQL_DEFAULT_LIMIT = staticDatabaseOptions.graphql.default.limit.name;

function separateAvailableOptions(
  allOptions: Record<string, OptionValue>,
  flatOptionOverrides = {} as {
    [flattenedOptionPath: string]: string | number | boolean | null;
  }
) {
  const serverKeys: Set<string> = new Set(
    Object.entries(allOptions)
      .filter(([key, value]) => key.endsWith('.server') && value === true)
      .map(([key]) => key.slice(0, key.lastIndexOf('.')))
  );

  const options: Record<string, OptionValue> = {};
  const serverOptions: Record<string, OptionValue> = {};
  Object.entries(allOptions).forEach(([key, value]) => {
    const keyMatch = key.slice(0, key.lastIndexOf('.'));
    if (serverKeys.has(keyMatch)) {
      serverOptions[key] = flatOptionOverrides[key] || value;
    } else {
      options[key] = flatOptionOverrides[key] || value;
    }
  });

  return {
    availableOptions: unflatten(options) as AvailableOptions,
    availableServerOptions: unflatten(serverOptions) as AvailableOptions,
  };
}

const initialState = new DatabasesState();

const databasesReducer = (
  prevState = initialState,
  action: DatabasesAction | ConnectionAction
): DatabasesState => {
  switch (action.type) {
    case ConnectionActionType.SET_CONNECTION_SUCCESS:
      return initialState;
    // TODO: FAILURE actions
    // There are a few comments like this. How do we handle failure of
    // behind-the-scenes requests in a way that communicates what happened to
    // the user and offers a recovery path?
    case DatabasesActionType.REQUEST_AVAILABLE_DATABASE_OPTIONS_SUCCESS:
      return {
        ...prevState,
        ...separateAvailableOptions(
          action.payload?.response?.body,
          action.payload?.action?.flatOptionOverrides
        ),
      };
    case DatabasesActionType.SET_STATIC_AVAILABLE_OPTIONS:
      return {
        ...prevState,
        // @ts-ignore the type AvailableOptions is not quite correct
        availableOptions: { ...staticDatabaseOptions },
        serverOptions: {},
      };
    case DatabasesActionType.REQUEST_DATABASES_ATTEMPT:
      return {
        ...prevState,
        requesting: true,
      };
    case DatabasesActionType.IMPORT_NAMESPACES_ATTEMPT:
    case DatabasesActionType.REQUEST_NAMESPACES_ATTEMPT: // intentional fallthrough
      return {
        ...prevState,
        requesting: true,
        pending: true,
      };
    case DatabasesActionType.REQUEST_DATABASES_FAILURE:
      return {
        ...prevState,
        pending: false,
        requesting: false,
      };
    case DatabasesActionType.REQUEST_DATABASES_SUCCESS: {
      const { databases = [] } = action.payload?.response?.body || {};

      databases.sort(alphaSort());

      const dbManagerSettings = {
        ...prevState.dbManagerSettings,
      };
      if (databases.length === 1) {
        dbManagerSettings.selectedDatabaseId = databases[0];
      }

      return {
        ...prevState,
        databaseIds: databases,
        pending: false,
        requesting: false,
        dbManagerSettings,
      };
    }
    case DatabasesActionType.IMPORT_NAMESPACES_SUCCESS: // intentional fallthrough
    case DatabasesActionType.REQUEST_NAMESPACES_SUCCESS: {
      if (!isBody(action.payload.response)) {
        return prevState;
      }
      const namespaces =
        action.type === DatabasesActionType.IMPORT_NAMESPACES_SUCCESS
          ? action.payload?.response?.body?.namespaces
          : namespaceObjToArray(action.payload?.response?.body || {});
      const db = action.payload.args[0] as string;

      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [db]: {
            ...prevState.databasesById[db],
            properties: {
              ...safelyGet(prevState.databasesById, [db, 'properties'], {}),
              'database.namespaces': namespaces,
            },
          },
        },
        pending: false,
        requesting: false,
      };
    }
    case DatabasesActionType.REQUEST_DETAILS_FOR_DATABASES_SUCCESS: {
      return action.payload.responses.reduce(
        (state, action) => databasesReducer(state, action),
        prevState
      );
    }
    case DatabasesActionType.REQUEST_DETAILS_FOR_DATABASE_SUCCESS: {
      if (!isBody(action.payload.response)) {
        return prevState;
      }
      const dbDetails = action.payload?.response?.body || {};
      const id = action.payload.args[0];
      const flatOptionOverrides = safelyGet(action.payload.action, [
        'flatOptionOverrides',
      ]);
      const nextProperties = {
        ...(dbDetails as any),
      };

      // Apply any overrides to the flat DB options, so that invalid
      // values are not in the store.
      if (
        dbDetails[GRAPHQL_DEFAULT_LIMIT] > Number.MAX_SAFE_INTEGER &&
        flatOptionOverrides &&
        flatOptionOverrides[GRAPHQL_DEFAULT_LIMIT]
      ) {
        // For Stardog 7.7.2, the server can have invalid values for
        // graphql.default.limit (see PLAT-2929). Studio must override this
        // value, or no other DB properties can be updated by the user unless
        // they first change the value of graphql.default.limit themselves.
        nextProperties[GRAPHQL_DEFAULT_LIMIT] =
          flatOptionOverrides[GRAPHQL_DEFAULT_LIMIT];
      }

      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [id]: {
            ...prevState.databasesById[id],
            properties: nextProperties,
          },
        },
        pending: false,
        requesting: false,
      };
    }
    // TODO: Failure and requesting
    // https://github.com/stardog-union/stardog-studio/issues/1017
    case DatabasesActionType.TOGGLE_DATABASE_ONLINE_SUCCESS: {
      const { isComingOnline } = action.payload.action;
      const id = action.payload.args[0];

      return {
        ...prevState,
        pending: false,
        databasesById: {
          ...prevState.databasesById,
          [id]: {
            ...(prevState.databasesById[id] || {}),
            properties: {
              ...safelyGet(prevState.databasesById, [id, 'properties'], {}),
              'database.online': isComingOnline,
            },
          },
        },
      };
    }
    case DatabasesActionType.DROP_DATABASE_ATTEMPT: {
      const databaseId = action.payload.args[0];
      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [databaseId]: {
            ...prevState.databasesById[databaseId],
            isPendingDropDatabase: true,
          },
        },
      };
    }
    case DatabasesActionType.DROP_DATABASE_FAILURE: {
      const databaseId = action.payload.args[0];
      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [databaseId]: {
            ...prevState.databasesById[databaseId],
            isPendingDropDatabase: false,
          },
        },
      };
    }
    case DatabasesActionType.DROP_DATABASE_SUCCESS: {
      const id = action.payload.args[0];
      const notThisId = (dbId) => dbId !== id;

      const getReducerForProp = (prop) => (accumulator, key) => {
        accumulator[key] = prevState[prop][key];
        return accumulator;
      };

      const getStateAtPropWithoutId = <State, SubState>(
        state: State,
        prop: keyof State
      ): Partial<SubState> =>
        Object.keys(state[prop])
          .filter(notThisId)
          .reduce(getReducerForProp(prop), {});

      const databasesById = getStateAtPropWithoutId<
        ReturnType<typeof databasesReducer>,
        ReturnType<typeof databasesReducer>['databasesById']
      >(prevState, 'databasesById');

      const databaseIds = prevState.databaseIds.filter(notThisId);
      const selectedDatabaseId =
        prevState.dbManagerSettings.selectedDatabaseId === id
          ? ''
          : prevState.dbManagerSettings.selectedDatabaseId;
      return {
        ...prevState,
        databaseIds,
        databasesById,
        pending: false,
        dbManagerSettings: {
          ...prevState.dbManagerSettings,
          selectedDatabaseId,
        },
      };
    }
    case DatabasesActionType.UPDATE_DATABASE_DETAILS_ATTEMPT: {
      return {
        ...prevState,
        pending: true,
      };
    }
    case DatabasesActionType.UPDATE_DATABASE_DETAILS_SUCCESS: {
      const id = action.payload.args[0];
      return {
        ...prevState,
        pending: false,
        databasesById: {
          ...prevState.databasesById,
          [id]: {
            ...prevState.databasesById[id],
            properties: {
              ...prevState.databasesById[id].properties,
              ...action.payload.args[1],
            },
          },
        },
      };
    }
    case DatabasesActionType.CREATE_DATABASE_ATTEMPT: {
      return {
        ...prevState,
        pendingCreateDatabase: true,
      };
    }
    case DatabasesActionType.CREATE_DATABASE_FAILURE: {
      return {
        ...prevState,
        pendingCreateDatabase: false,
      };
    }
    case DatabasesActionType.CREATE_DATABASE_SUCCESS: {
      const [id, initialDbDetails] = action.payload.args;

      return {
        ...prevState,
        pendingCreateDatabase: false,
        databaseIds: [...prevState.databaseIds, id],
        databasesById: {
          ...prevState.databasesById,
          [id]: {
            ...prevState.databasesById[id],
            ...(unflatten<DatabaseDetails, any>(initialDbDetails).database ||
              {}),
          },
        },
      };
    }
    case DatabasesActionType.SET_SELECTED_DATABASE_ID: {
      return {
        ...prevState,
        dbManagerSettings: {
          ...prevState.dbManagerSettings,
          selectedDatabaseId: action.payload.databaseId,
        },
      };
    }
    case DatabasesActionType.SET_DATABASE_MANAGER_IS_DIRTY: {
      const {
        areGraphQlSchemasDirty,
        areNamespacesDirty,
        arePropertiesDirty,
        isBiSchemaMappingDirty,
      } = action.payload;
      const partialNextState: any = {};
      if (typeof areGraphQlSchemasDirty === 'boolean') {
        partialNextState.areGraphQlSchemasDirty = areGraphQlSchemasDirty;
      }
      if (typeof areNamespacesDirty === 'boolean') {
        partialNextState.areNamespacesDirty = areNamespacesDirty;
      }
      if (typeof arePropertiesDirty === 'boolean') {
        partialNextState.arePropertiesDirty = arePropertiesDirty;
      }
      if (typeof isBiSchemaMappingDirty === 'boolean') {
        partialNextState.isBiSchemaMappingDirty = isBiSchemaMappingDirty;
      }
      return {
        ...prevState,
        dbManagerSettings: {
          ...prevState.dbManagerSettings,
          ...partialNextState,
        },
      };
    }
    case DatabasesActionType.ADD_DATA_ATTEMPT: {
      const dbName = action.payload.databaseId;
      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [dbName]: {
            ...prevState.databasesById[dbName],
            isPendingAddData: true,
          },
        },
      };
    }
    case DatabasesActionType.REMOVE_DATA_ATTEMPT: {
      const dbName = action.payload.databaseId;
      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [dbName]: {
            ...prevState.databasesById[dbName],
            isPendingRemoveData: true,
          },
        },
      };
    }
    case DatabasesActionType.REMOVE_DATA_FAILURE:
    case DatabasesActionType.REMOVE_DATA_SUCCESS: {
      const dbName = action.payload.databaseId;
      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [dbName]: {
            ...prevState.databasesById[dbName],
            isPendingRemoveData: false,
          },
        },
      };
    }
    case DatabasesActionType.ADD_DATA_FAILURE:
    case DatabasesActionType.ADD_DATA_SUCCESS: {
      const dbName = action.payload.databaseId;
      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [dbName]: {
            ...prevState.databasesById[dbName],
            isPendingAddData: false,
          },
        },
      };
    }
    case DatabasesActionType.ADD_ICV_CONSTRAINTS_ATTEMPT:
    case DatabasesActionType.REMOVE_ICV_CONSTRAINTS_ATTEMPT: {
      const dbName = action.payload.action.databaseId;
      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [dbName]: {
            ...prevState.databasesById[dbName],
            isPendingUpdateConstraints: true,
          },
        },
      };
    }
    case DatabasesActionType.VALIDATE_CONSTRAINTS_ATTEMPT: {
      const dbName = action.payload.action.databaseId;
      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [dbName]: {
            ...prevState.databasesById[dbName],
            isPendingValidateConstraints: true,
          },
        },
      };
    }
    case DatabasesActionType.ADD_ICV_CONSTRAINTS_FAILURE:
    case DatabasesActionType.ADD_ICV_CONSTRAINTS_SUCCESS:
    case DatabasesActionType.REMOVE_ICV_CONSTRAINTS_FAILURE:
    case DatabasesActionType.REMOVE_ICV_CONSTRAINTS_SUCCESS: {
      const dbName = action.payload.action.databaseId;
      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [dbName]: {
            ...prevState.databasesById[dbName],
            isPendingUpdateConstraints: false,
          },
        },
      };
    }
    case DatabasesActionType.VALIDATE_CONSTRAINTS_FAILURE:
    case DatabasesActionType.VALIDATE_CONSTRAINTS_SUCCESS: {
      const dbName = action.payload.action.databaseId;
      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [dbName]: {
            ...prevState.databasesById[dbName],
            isPendingValidateConstraints: false,
          },
        },
      };
    }
    case DatabasesActionType.GET_SIZE_SUCCESS: {
      const dbName = action.payload.action.db;
      const triples = safelyGet(action, ['payload', 'response', 'body']);
      return {
        ...prevState,
        pending: false,
        databasesById: {
          ...prevState.databasesById,
          [dbName]: {
            ...prevState.databasesById[dbName],
            triples,
          },
        },
      };
    }
    case DatabasesActionType.UPDATE_DATABASES_MOSAIC_STATE: {
      const { mosaicStateChange } = action.payload;
      return {
        ...prevState,
        mosaicState: {
          ...prevState.mosaicState,
          ...mosaicStateChange,
        },
      };
    }
    case DatabasesActionType.REQUEST_NAMED_GRAPHS_SUCCESS: {
      if (!isBody(action.payload.response)) {
        return prevState;
      }
      const { db } = action.payload.action;
      const { bindings = [] } = action.payload?.response?.body?.results || {};
      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [db]: {
            ...prevState.databasesById[db],
            namedGraphBindings: bindings.filter((b) => Boolean(b.graph)),
          },
        },
      };
    }
    case DatabasesActionType.REQUEST_NAMED_GRAPH_ALIASES_SUCCESS: {
      if (!isBody(action.payload.response)) {
        return prevState;
      }
      const { db } = action.payload.action;
      const { bindings = [] } = action.payload?.response?.body?.results || {};
      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [db]: {
            ...prevState.databasesById[db],
            namedGraphAliasBindings: bindings.filter((b) => Boolean(b.graph)),
          },
        },
      };
    }
    case DatabasesActionType.CLEAR_NAMED_GRAPHS: {
      const { databaseId } = action.payload;
      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [databaseId]: {
            ...prevState.databasesById[databaseId],
            namedGraphBindings: emptyArray as any[],
            namedGraphAliasBindings: emptyArray as any[],
          },
        },
      };
    }
    case DatabasesActionType.REQUEST_RELATIONSHIPS_SUCCESS: {
      if (!isBody(action.payload.response)) {
        return prevState;
      }
      const { db } = action.payload.action;
      const { bindings = [] } = action.payload?.response?.body?.results || {};
      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [db]: {
            ...prevState.databasesById[db],
            relationshipBindings: bindings,
          },
        },
      };
    }
    case DatabasesActionType.REQUEST_TYPES_SUCCESS: {
      if (!isBody(action.payload.response)) {
        return prevState;
      }
      const { db } = action.payload.action;
      const { bindings = [] } = action.payload?.response?.body.results || {};
      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [db]: {
            ...prevState.databasesById[db],
            typeBindings: bindings,
          },
        },
      };
    }
    case DatabasesActionType.CLEAR_RELATIONSHIPS_AND_TYPES: {
      const { databaseId } = action.payload;
      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [databaseId]: {
            ...prevState.databasesById[databaseId],
            relationshipBindings: emptyArray as any[],
            typeBindings: emptyArray as any[],
          },
        },
      };
    }
    case DatabasesActionType.EXPORT_DATABASE_ATTEMPT: {
      const { databaseId } = action.payload;
      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [databaseId]: {
            ...prevState.databasesById[databaseId],
            isPendingExportDatabase: true,
          },
        },
      };
    }
    case DatabasesActionType.EXPORT_DATABASE_SUCCESS:
    case DatabasesActionType.EXPORT_DATABASE_FAILURE: {
      const { databaseId } = action.payload;
      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [databaseId]: {
            ...prevState.databasesById[databaseId],
            isPendingExportDatabase: false,
          },
        },
      };
    }
    case DatabasesActionType.OPTIMIZE_DATABASE_ATTEMPT: {
      const databaseId = action.payload.args[0];
      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [databaseId]: {
            ...prevState.databasesById[databaseId],
            isPendingOptimizeDatabase: true,
          },
        },
      };
    }
    case DatabasesActionType.OPTIMIZE_DATABASE_SUCCESS: {
      const databaseId = action.payload.args[0];
      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [databaseId]: {
            ...prevState.databasesById[databaseId],
            isPendingOptimizeDatabase: false,
          },
        },
      };
    }
    case DatabasesActionType.OPTIMIZE_DATABASE_FAILURE: {
      const databaseId = action.payload.args[0];
      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [databaseId]: {
            ...prevState.databasesById[databaseId],
            isPendingOptimizeDatabase: false,
          },
        },
      };
    }
    case DatabasesActionType.REQUEST_GRAPHQL_SCHEMAS_FOR_DATABASE_ATTEMPT: {
      const { databaseId } = action.payload.action;
      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [databaseId]: {
            ...prevState.databasesById[databaseId],
            isPendingRequestGraphQlSchemaIds: true,
          },
        },
      };
    }
    case DatabasesActionType.REQUEST_GRAPHQL_SCHEMAS_FOR_DATABASE_SUCCESS: {
      if (!isBody(action.payload.response)) {
        return prevState;
      }
      const { databaseId } = action.payload.action;
      const { schemas = emptyArray } = action.payload?.response?.body || {};
      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [databaseId]: {
            ...prevState.databasesById[databaseId],
            graphQlSchemaIds: schemas,
            graphQlSchemasById: schemas.reduce((acc, schemaId) => {
              acc[schemaId] = '';
              return acc;
            }, {}),
            isPendingRequestGraphQlSchemaIds: false,
            pendingGraphQlSchemaIds: emptyArray,
          },
        },
      };
    }
    case DatabasesActionType.REQUEST_GRAPHQL_SCHEMAS_FOR_DATABASE_FAILURE: {
      const { databaseId } = action.payload.action;
      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [databaseId]: {
            ...prevState.databasesById[databaseId],
            isPendingRequestGraphQlSchemaIds: false,
          },
        },
      };
    }
    case DatabasesActionType.REQUEST_GRAPHQL_SCHEMA_FOR_DATABASE_SUCCESS: {
      if (!isBody(action.payload.response)) {
        return prevState;
      }
      const { databaseId, graphQlSchemaId } = action.payload.action;
      const databaseDetails = prevState.databasesById[databaseId];
      const {
        pendingGraphQlSchemaIds = emptyArray,
        graphQlSchemasById = emptyObject,
      } = databaseDetails;
      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [databaseId]: {
            ...databaseDetails,
            graphQlSchemasById: {
              ...graphQlSchemasById,
              [graphQlSchemaId]: action.payload?.response?.body || '',
            },
            pendingGraphQlSchemaIds: pendingGraphQlSchemaIds.filter(
              (id) => id !== graphQlSchemaId
            ),
          },
        },
      };
    }
    case DatabasesActionType.REMOVE_GRAPHQL_SCHEMA_FOR_DATABASE_SUCCESS: {
      const { databaseId, graphQlSchemaId } = action.payload.action;
      const databaseDetails = prevState.databasesById[databaseId];
      const {
        pendingGraphQlSchemaIds = emptyArray,
        graphQlSchemaIds = emptyArray,
        graphQlSchemasById = emptyObject as any,
      } = databaseDetails;
      const nextPendingGraphQlSchemaIds = pendingGraphQlSchemaIds.filter(
        (id) => id !== graphQlSchemaId
      );
      const nextGraphQlSchemaIds = graphQlSchemaIds.filter(
        (id) => id !== graphQlSchemaId
      );
      const { [graphQlSchemaId]: _, ...nextGraphQlSchemasById } =
        graphQlSchemasById;

      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [databaseId]: {
            ...databaseDetails,
            graphQlSchemaIds: nextGraphQlSchemaIds,
            graphQlSchemasById: nextGraphQlSchemasById,
            pendingGraphQlSchemaIds: nextPendingGraphQlSchemaIds,
          },
        },
      };
    }
    case DatabasesActionType.REQUEST_GRAPHQL_SCHEMA_FOR_DATABASE_ATTEMPT: // intentional fallthrough
    case DatabasesActionType.REMOVE_GRAPHQL_SCHEMA_FOR_DATABASE_ATTEMPT: // intentional fallthrough
    case DatabasesActionType.UPDATE_GRAPHQL_SCHEMA_FOR_DATABASE_ATTEMPT: // intentional fallthrough
    case DatabasesActionType.ADD_GRAPHQL_SCHEMA_FOR_DATABASE_ATTEMPT: {
      const { databaseId, graphQlSchemaId } =
        action.type ===
        DatabasesActionType.UPDATE_GRAPHQL_SCHEMA_FOR_DATABASE_ATTEMPT
          ? action.payload
          : action.payload.action;
      const databaseDetails = prevState.databasesById[databaseId];
      const { pendingGraphQlSchemaIds = emptyArray } = databaseDetails;

      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [databaseId]: {
            ...databaseDetails,
            pendingGraphQlSchemaIds: [
              ...pendingGraphQlSchemaIds,
              graphQlSchemaId,
            ],
          },
        },
      };
    }
    case DatabasesActionType.UPDATE_GRAPHQL_SCHEMA_FOR_DATABASE_SUCCESS: // intentional fallthrough
    case DatabasesActionType.ADD_GRAPHQL_SCHEMA_FOR_DATABASE_SUCCESS: {
      const { databaseId, graphQlSchemaId, graphQlSchema } =
        action.type ===
        DatabasesActionType.UPDATE_GRAPHQL_SCHEMA_FOR_DATABASE_SUCCESS
          ? action.payload
          : action.payload.action;
      const databaseDetails = prevState.databasesById[databaseId];
      const {
        pendingGraphQlSchemaIds = emptyArray,
        graphQlSchemaIds = emptyArray,
        graphQlSchemasById = emptyObject,
      } = databaseDetails;
      const nextPendingGraphQlSchemaIds = pendingGraphQlSchemaIds.filter(
        (id) => id !== graphQlSchemaId
      );
      const nextGraphQlSchemaIds = Array.from(
        new Set(graphQlSchemaIds.concat(graphQlSchemaId))
      );
      const nextGraphQlSchemasById = {
        ...graphQlSchemasById,
        [graphQlSchemaId]: graphQlSchema,
      };

      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [databaseId]: {
            ...databaseDetails,
            pendingGraphQlSchemaIds: nextPendingGraphQlSchemaIds,
            graphQlSchemaIds: nextGraphQlSchemaIds,
            graphQlSchemasById: nextGraphQlSchemasById,
          },
        },
      };
    }
    case DatabasesActionType.REQUEST_GRAPHQL_SCHEMA_FOR_DATABASE_FAILURE: // intentional fallthrough
    case DatabasesActionType.REMOVE_GRAPHQL_SCHEMA_FOR_DATABASE_FAILURE: // intentional fallthrough
    case DatabasesActionType.UPDATE_GRAPHQL_SCHEMA_FOR_DATABASE_FAILURE: // intentional fallthrough
    case DatabasesActionType.ADD_GRAPHQL_SCHEMA_FOR_DATABASE_FAILURE: {
      const { databaseId, graphQlSchemaId } =
        action.type ===
        DatabasesActionType.UPDATE_GRAPHQL_SCHEMA_FOR_DATABASE_FAILURE
          ? action.payload
          : action.payload.action;
      const databaseDetails = prevState.databasesById[databaseId];
      const { pendingGraphQlSchemaIds = emptyArray } = databaseDetails;

      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [databaseId]: {
            ...databaseDetails,
            pendingGraphQlSchemaIds: pendingGraphQlSchemaIds.filter(
              (id) => id !== graphQlSchemaId
            ),
          },
        },
      };
    }
    case DatabasesActionType.REQUEST_BI_SCHEMA_MAPPING_ATTEMPT: // intentional fallthrough
    case DatabasesActionType.REQUEST_GENERATED_BI_SCHEMA_MAPPING_ATTEMPT: {
      const { databaseId } =
        action.type === DatabasesActionType.REQUEST_BI_SCHEMA_MAPPING_ATTEMPT
          ? action.payload
          : (action.payload as any).action;
      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [databaseId]: {
            ...prevState.databasesById[databaseId],
            isPendingRequestBiSchemaMapping: true,
          },
        },
      };
    }
    case DatabasesActionType.REQUEST_BI_SCHEMA_MAPPING_FAILURE: // intentional fallthrough
    case DatabasesActionType.REQUEST_GENERATED_BI_SCHEMA_MAPPING_FAILURE: {
      const { databaseId } =
        action.type === DatabasesActionType.REQUEST_BI_SCHEMA_MAPPING_FAILURE
          ? action.payload
          : (action.payload as any).action;
      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [databaseId]: {
            ...prevState.databasesById[databaseId],
            isPendingRequestBiSchemaMapping: false,
          },
        },
      };
    }
    case DatabasesActionType.REQUEST_BI_SCHEMA_MAPPING_SUCCESS: // intentional fallthrough
    case DatabasesActionType.REQUEST_GENERATED_BI_SCHEMA_MAPPING_SUCCESS: {
      const { biSchemaMapping, databaseId } =
        action.type === DatabasesActionType.REQUEST_BI_SCHEMA_MAPPING_SUCCESS
          ? action.payload
          : (action.payload as any).action;
      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [databaseId]: {
            ...prevState.databasesById[databaseId],
            isPendingRequestBiSchemaMapping: false,
            ...(biSchemaMapping ? { biSchemaMapping } : {}),
          },
        },
      };
    }
    case DatabasesActionType.UPDATE_BI_SCHEMA_MAPPING_ATTEMPT: {
      const { databaseId } = action.payload;

      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [databaseId]: {
            ...prevState.databasesById[databaseId],
            isPendingUpdateBiSchemaMapping: true,
          },
        },
      };
    }
    case DatabasesActionType.UPDATE_BI_SCHEMA_MAPPING_FAILURE: {
      const { databaseId } = action.payload;

      return {
        ...prevState,
        databasesById: {
          ...prevState.databasesById,
          [databaseId]: {
            ...prevState.databasesById[databaseId],
            isPendingUpdateBiSchemaMapping: false,
          },
        },
      };
    }
    case DatabasesActionType.UPDATE_BI_SCHEMA_MAPPING_SUCCESS: {
      const { databaseId, updatedSchemaMapping } = action.payload;

      return {
        ...prevState,
        dbManagerSettings: {
          ...prevState.dbManagerSettings,
          isBiSchemaMappingDirty: false,
        },
        databasesById: {
          ...prevState.databasesById,
          [databaseId]: {
            ...prevState.databasesById[databaseId],
            biSchemaMapping: updatedSchemaMapping,
            isPendingUpdateBiSchemaMapping: false,
          },
        },
      };
    }
    default:
      return prevState;
  }
};

export { databasesReducer };
