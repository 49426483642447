import {
  IColumnIndices,
  IRowIndices,
} from '@blueprintjs/table/lib/esm/common/grid';

import { FlatMosaicParent } from 'src/common/components/MosaicViewManager';
import { isStudioFeatureEnabled } from 'src/common/store/features/FeaturesState';
import { NoteState } from 'src/common/store/notebook/NoteState';
import { PaneState } from 'src/common/store/notebook/PaneState';
import { RawJsonLdData } from 'src/common/store/visualization/VisualizationState';
import { Binding } from 'src/common/utils/rpc/types';

export const defaultResultsHeight = 300;
export const defaultSanddanceResultsHeight = 700;
export const defaultVisualizationResultsHeight = 500;
export const minResultsHeight = 100;

export function isBindingsResult(body: any): body is BindingsResult {
  return body.results && body.results.bindings && body.head && body.head.vars;
}

export interface BindingsResult {
  results: {
    bindings: Array<{
      [column: string]: Binding;
    }>;
  };
  head: {
    vars: string[];
  };
}

export function hasJsonLdResults(jsonLdBody?: JsonLdBody) {
  return (
    jsonLdBody &&
    jsonLdBody.queryHistoryId &&
    Array.isArray(jsonLdBody.results) &&
    jsonLdBody.results.length
  );
}

export interface JsonLdBody {
  queryHistoryId: string;
  results?: RawJsonLdData[];
}

export type ScrollPosition = [
  IRowIndices['rowIndexStart'],
  IRowIndices['rowIndexEnd'],
  IColumnIndices['columnIndexStart'],
  IColumnIndices['columnIndexEnd']
];

export interface NoteStateMap {
  [id: string]: NoteState;
}

export interface PaneStateMap {
  [id: string]: PaneState;
}

export const enum NotebookTileIds {
  SIDEBAR = 'SIDEBAR',
  EXPLORER = 'EXPLORER',
  PANES = 'PANES',
  STORED_AND_HISTORY = 'STORED_AND_HISTORY',
}

export interface NotebookMosaicState {
  TOP: FlatMosaicParent<NotebookTileIds.SIDEBAR | NotebookTileIds.PANES>;
  SIDEBAR: FlatMosaicParent<
    NotebookTileIds.EXPLORER | NotebookTileIds.STORED_AND_HISTORY
  >;
}

export type NotebookMosaicStateChange =
  | {
      TOP: NotebookMosaicState['TOP'];
    }
  | {
      SIDEBAR: NotebookMosaicState['SIDEBAR'];
    };

export const defaultMosaicState: NotebookMosaicState = {
  TOP: {
    direction: 'row',
    first: NotebookTileIds.SIDEBAR,
    second: NotebookTileIds.PANES,
    splitPercentage: 20,
  },
  SIDEBAR: {
    direction: 'column',
    first: NotebookTileIds.EXPLORER,
    second: NotebookTileIds.STORED_AND_HISTORY,
    // while this are the "default" split percentage, it will
    // almost never be used since the mosaic state is always persisted
    // across sessions (see getPruned.ts);
    splitPercentage: !isStudioFeatureEnabled ? 0 : 25,
  },
};

export const enum NotebookStoredAndHistoryTab {
  STORED = 'STORED',
  HISTORY = 'HISTORY',
}

export class NotebookState {
  public paneIds = ['0'];

  public panes: PaneStateMap = {
    '0': new PaneState(),
  };

  public noteIds: Array<NoteState['id']> = [];

  public notes: NoteStateMap = {};

  public activeNoteId: NoteState['id'] = null;

  public mosaicState: NotebookMosaicState = defaultMosaicState;

  public storedAndHistoryTab: NotebookStoredAndHistoryTab =
    NotebookStoredAndHistoryTab.STORED;
}
