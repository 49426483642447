import { query } from 'stardog';

export type QueryType =
  | query.QueryType
  | 'graphql'
  | 'explain'
  | 'profile'
  | 'shacl_report';
export const enum QueryTypeIdentifier {
  SELECT = 'select',
  ASK = 'ask',
  CONSTRUCT = 'construct',
  DESCRIBE = 'describe',
  UPDATE = 'update',
  PATHS = 'paths',
  PROFILE = 'profile',
  GRAPHQL = 'graphql',
  EXPLAIN = 'explain',
  SHACL_REPORT = 'shacl_report',
  VALIDATE = 'validate',
}
