import { getQueryLabels } from 'src/common/utils/queryBuilders/shared/getQueryLabels';

export function getQueryLabelsValues({
  labelPredicates = [],
  namespaces = [],
  subject = '?subject',
  predicate = '?labelPredicate',
  object = '?label',
  indentation = 6,
}: {
  labelPredicates: string[];
  namespaces: string[];
  subject?: string;
  object?: string;
  predicate?: string;
  indentation?: number;
}) {
  const spaces = new Array<string>(indentation + 1).join(' ');
  return getQueryLabels({ labelPredicates, namespaces })
    .map(
      (label) => `{
${spaces}  BIND(${label} as ${predicate})
${spaces}  ${subject} ${label} ${object}
${spaces}}`
    )
    .join(' UNION ');
}
