import { editorDarkBgColor, editorLightBgColor } from 'src/common/constants/ui';

// default mode is 'dark mode' (nodes fills and links are light, node borders are dark)
export interface VisualizationColorsSchema {
  BACKGROUND: string;
  BORDER: string;
  OVERLAY: string;
  OVERLAY_OPACITY: string;
  PILL_TEXT: string;
  LINK_CONNECTOR: string;
  OVERLAY_FOCUS: string;
  OVERLAY_HOVER: string;
  CANVAS_FOCUS: string;
  CANVAS_HOVER: string;
}

const BORDER_COLOR = 'var(--text-main)';

export const DARK_MODE: VisualizationColorsSchema = {
  BACKGROUND: editorDarkBgColor,
  BORDER: BORDER_COLOR,
  OVERLAY: '#bcbcbc',
  OVERLAY_OPACITY: 'rgba(255,255,255,0.7)',
  PILL_TEXT: '#1e1e1e',
  LINK_CONNECTOR: '#ffffff',
  OVERLAY_FOCUS: '#1e1e1e',
  OVERLAY_HOVER: '#ffffff',
  CANVAS_FOCUS: '#1e1e1e',
  CANVAS_HOVER: '#ffffff',
};

export const LIGHT_MODE: VisualizationColorsSchema = {
  BACKGROUND: editorLightBgColor,
  BORDER: BORDER_COLOR,
  OVERLAY: '#bcbcbc',
  OVERLAY_OPACITY: 'rgba(0,0,0,0.25)',
  PILL_TEXT: '#1e1e1e',
  LINK_CONNECTOR: '#ffffff',
  OVERLAY_FOCUS: '#1e1e1e',
  OVERLAY_HOVER: '#ffffff',
  CANVAS_FOCUS: '#ffffff',
  CANVAS_HOVER: '#1e1e1e',
};

export const DARK_OBJECTS = [
  '#007fa4',
  '#b83d06',
  '#a20655',
  '#a1591a',
  '#c1af12',
  '#416f17',
  '#385598',
  '#90089c',

  // multi or none
  '#545454',
];

// lighter shades of the dark object colors
export const LIGHT_OBJECTS = [
  '#a4eaff',
  '#fbb89b',
  '#fcabd4',
  '#e8a870',
  '#f4ea8f',
  '#99e19b',
  '#a3b5dd',
  '#f4a8fb',

  // multi or none
  '#d4d4d4',
];

// ignore the "multi" or "none" color in the count
export const OBJECT_COLORS_COUNT = DARK_OBJECTS.length - 1;
