import { isDevelopment } from 'src/common/utils/isDevelopment';
import { isSnapshot } from 'src/common/utils/isSnapshot';
import { getAsTypedTuple } from 'src/common/utils/types/getAsTypedTuple';

export const PRUNED_STORE_KEYS = getAsTypedTuple(
  'connection',
  'preferences',
  'notebook',
  'fileSystem',
  'queryHistory',
  'version'
);

export const browserStoreKey =
  isDevelopment(process.env.NODE_ENV) || isSnapshot()
    ? '__STUDIO_DEV_STORE__'
    : '__STUDIO_STORE__';
