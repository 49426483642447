import { set } from 'idb-keyval';
import { AnyAction, Store } from 'redux';

import {
  StudioStateGetter,
  StudioThunkDispatch,
} from 'src/common/actions/StudioAction';
import { browserStoreKey } from 'src/common/constants/store';
import { appReducer, createInitialState } from 'src/common/reducers/reducers';
import { createHydratedStore, createStore } from 'src/common/store/createStore';
import { getPruned } from 'src/common/utils/store/getPruned';
import { ReduxState } from 'src/types';

let store: Store<ReduxState, AnyAction>;

type NotSetup = typeof notSetup;
type GetStore = () => {
  store: Store<ReduxState, AnyAction> & { dispatch: StudioThunkDispatch };
  dispatch: StudioThunkDispatch;
  getState: StudioStateGetter;
};
type SaveStore = () => Promise<void>;
type ResetStore = () => Promise<void>;

const notSetup = () => {
  throw new Error('Store cannot be accessed before calling `setupStore`!');
};
// These are overwritten below, after `setupStore` is called.
export let getStore: NotSetup | GetStore = notSetup;
export let saveStore: NotSetup | SaveStore = notSetup;
export let resetStore: NotSetup | ResetStore = notSetup;

export const setupStore = async () => {
  store = await createHydratedStore(createInitialState(), appReducer);

  getStore = () => ({
    store,
    dispatch: store.dispatch,
    getState: () => store.getState(),
  });

  saveStore = async () => {
    try {
      await set(browserStoreKey, getPruned(store));
    } catch (ex) {
      console.error(ex);
    }
  };

  resetStore = async () => {
    store = createStore(createInitialState(), appReducer);
    await saveStore();
  };
};
