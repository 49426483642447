import { isPortal } from 'vet-bones/bones/utils/portal';

import { Preferences } from 'src/common/store/preferences/PreferencesState';
import { getPreferencesWithDefaults } from 'src/common/utils/preferences/getPreferencesWithDefaults';
import { stringifyInOrder } from 'src/common/utils/preferences/stringifyInOrder';

export const getPreferencesQuery = (preferences: Partial<Preferences>) => {
  const prefsWithDefaults = getPreferencesWithDefaults(preferences);
  delete prefsWithDefaults.autoUpdate;
  delete prefsWithDefaults.zoomLevel;
  if (isPortal()) {
    // don't show telemetryConsent if we are in Portal
    // (the portal-sdk cookie is used instead)
    delete prefsWithDefaults.telemetryConsent;
  }

  return stringifyInOrder(prefsWithDefaults);
};
