export const GLOBAL_SYMBOL = '*';
export const GLOBAL_DISPLAY_NAME = `All Databases`;
export const globalSymbolToName = (s: string) =>
  s === GLOBAL_SYMBOL ? GLOBAL_DISPLAY_NAME : s;
export const globalDisplayNameToSymbol = (s: string) =>
  s === GLOBAL_DISPLAY_NAME ? GLOBAL_SYMBOL : s;

export const SCOPE_TOOLTIP_MSG = `A query stored to a specific database can only be programmatically executed against that database.`;

export const abbreviate = (s: string, n = 0) =>
  s.length > n ? s.substr(0, n).concat('...') : s;
