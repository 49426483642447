export type FeatureSupportVersion = {
  readonly min?: string;
  readonly max?: string;
};

export const featureSupportVersions: Record<string, FeatureSupportVersion> = {
  atomicGraphQlSchemaUpdate: {
    min: '7.1.3',
  },
  availableDatabaseOptionsMethod: {
    min: '6.2.3',
  },
  biServer: {
    min: '7.0.2',
  },
  clientSideQueryId: {
    min: '7.7.2',
  },
  dataSources: {
    min: '7.4.4',
  },
  dataSourceShare: {
    min: '7.4.5',
  },
  dbCreateWithPropertiesFile: {
    min: '7.1.0',
  },
  disableIcvConstraints: {
    min: '8.0.0',
  },
  editingDataSourcesWithVgs: {
    min: '7.4.5',
  },
  ephemeralLogin: {
    min: '7.4.5',
  },
  frequencyPredicates: {
    min: '6.1.3',
  },
  graphQLStoredQueries: {
    min: '6.2.3',
  },
  importNamespacesOverHttp: {
    min: '7.0.2',
  },
  jsonGraphQLExplanations: {
    min: '6.1.4',
  },
  multiSchema: {
    min: '7.0.0',
  },
  namedGraphAliases: {
    min: '7.4.5',
  },
  preserveExactMappings: {
    min: '6.0.1',
  },
  queryProfiler: {
    min: '7.7.1',
  },
  requestServerProperties: {
    min: '7.4.1',
  },
  sensitiveProperties: {
    min: '7.8.3',
  },
  sms2: {
    min: '6.0.0',
  },
  starchart: {
    min: '7.3.0',
  },
  storedQueryReasoningAndDescription: {
    min: '6.2.2',
  },
  updatedGetAll: {
    min: '6.2.2',
  },
  usersRolesListEndpoints: {
    min: '8.2.0',
  },
  vgAssociateWithDb: {
    min: '6.1.2',
  },
  virtualTransparency: {
    min: '7.0.0',
  },
};
