import { Classes, Dialog, H3, Hotkey } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';

import { ipcFacade as ipcRenderer } from 'src/browser/apis/browserIpcApi';
import { KeyboardShortcuts } from 'src/browser/constants/KeyboardShortcuts';
import { IpcEventType } from 'src/common/constants/events';

/**
 * This component doesn't register any hot keys, just shows what we have
 * registered via the ipcRenderer or ones that come for free. Typically <HotKey />
 * can be used to register them, but using it for styling purposes here.
 */

export const KeyboardShortcutsDialog = () => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    ipcRenderer.on(IpcEventType.SHOW_KEYBOARD_SHORTCUTS, () => setOpen(true));
  }, []);

  return (
    <Dialog
      className={Classes.HOTKEY_DIALOG}
      isOpen={open}
      onClose={() => setOpen(false)}
      title="Keyboard Shortcuts"
    >
      <div className={Classes.DIALOG_BODY}>
        <div className={Classes.HOTKEY_COLUMN}>
          <H3>Navigation</H3>
          {KeyboardShortcuts.navigation.map((config) => {
            return (
              <Hotkey
                key={config.combo}
                combo={config.combo}
                group="Navigation"
                label={config.label}
              />
            );
          })}
          <H3>Workspace</H3>
          {[...KeyboardShortcuts.workspace, ...KeyboardShortcuts.monaco].map(
            (config) => {
              return (
                <Hotkey
                  key={config.combo}
                  combo={config.combo}
                  group={config.group}
                  label={config.label}
                />
              );
            }
          )}
          <H3>Help</H3>
          {KeyboardShortcuts.help.map((config) => {
            return (
              <Hotkey
                key={config.combo}
                combo={config.combo}
                group={config.group}
                label={config.label}
              />
            );
          })}
        </div>
      </div>
    </Dialog>
  );
};
