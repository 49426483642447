import omit from 'lodash.omit';

import {
  Connection,
  SavedConnection,
  SavedConnections,
  defaultCurrentConnection,
} from 'src/common/store/connection/ConnectionState';
import { safelyGet } from 'src/common/utils/safelyGet';
import { InitialData } from 'src/types';

export default (data: InitialData) => {
  const prevSavedConnections = safelyGet<
    InitialData,
    SavedConnections,
    SavedConnections
  >(data, ['connection', 'saved'], {});

  const nextSavedConnections = Object.values(
    prevSavedConnections
  ).reduce<SavedConnections>((acc, connection) => {
    const newConnection = omit(connection, 'isConnected') as SavedConnection;
    acc[connection.name] = newConnection;
    return acc;
  }, {});

  const prevCurrentConnection = safelyGet<InitialData, Connection, Connection>(
    data,
    ['connection', 'current'],
    defaultCurrentConnection
  );

  const nextCurrentConnection = omit(prevCurrentConnection, 'isConnected');

  return {
    ...data,
    connection: {
      ...data.connection,
      current: nextCurrentConnection,
      saved: nextSavedConnections,
    },
    version: '2.4.4',
  };
};
