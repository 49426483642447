import { handleSessionTimeoutRedirectResponse } from 'vet-bones/bones/utils';

import {
  API,
  FailureResponse,
  Options,
  RequestAction,
  RequestSilent,
  Response,
  Scope,
  SuccessResponse,
  isBody,
} from 'src/common/actions/request/types';
import { getStardogConnection } from 'src/common/utils/connection/getStardogConnection';
import { isConnectionConnected } from 'src/common/utils/isConnectionConnected';
import { formatHeadersForPostMessage } from 'src/common/utils/rpc/formatHeadersForPostMessage';
import { safelyGet } from 'src/common/utils/safelyGet';

const connectionRefusedChecker = /CONNECTION_REFUSED$/;

const doHeadersNeedTransforming = (candidate) =>
  Object.prototype.toString.call(candidate) !== '[object Object]' ||
  typeof candidate.get === 'function';

const wrappedRequest = async (options, { dispatch, connection }) => {
  const { api, args, action, shouldNotDispatch } = options;
  const { silent } = options;
  const start = Date.now();
  const scope = !options.scope || options.scope === Scope.LOCAL ? 'local' : '';
  if (!shouldNotDispatch && Boolean(options.loading)) {
    dispatch({
      meta: {
        scope,
      },
      type: options.loading,
      payload: {
        args,
        action,
        silent: safelyGet<RequestSilent, boolean, boolean>(
          silent,
          ['loading'],
          false
        ),
      },
    });
  }

  let response;
  try {
    const conn = getStardogConnection(connection);
    response = await api(conn, ...args);

    // First check for expired response and redirect to custom login page.
    const isSessionTimeoutRedirectResponse =
      handleSessionTimeoutRedirectResponse(response);

    if (isBody(response) && !response.ok) {
      throw new Error(response.statusText || String(response.status));
    }

    if (doHeadersNeedTransforming(response.headers)) {
      response = formatHeadersForPostMessage(response);
    }

    const responseAction = {
      meta: {
        scope,
      },
      payload: {
        args,
        response,
        action: options.action,
        timeElapsed: Date.now() - start,
        silent: safelyGet<RequestSilent, boolean, boolean>(
          silent,
          ['success'],
          false
        ),
      },
      type: options.success,
    };

    if (!shouldNotDispatch && !isSessionTimeoutRedirectResponse) {
      dispatch(responseAction);
    }
    return responseAction;
  } catch (e) {
    console.error(e);
    const errorMessage = e.message || 'Operation failed.';
    const errorAction = {
      meta: {
        scope,
      },
      payload: {
        args,
        response: response || {
          id: args[0],
          errorMessage,
        },
        action: options.action,
        timeElapsed: Date.now() - start,
        silent: safelyGet<RequestSilent, boolean, boolean>(
          silent,
          ['failure'],
          false
        ),
      },
      type: options.failure,
    };
    if (!shouldNotDispatch) {
      dispatch(errorAction);
      if (connectionRefusedChecker.test(e.message)) {
        // If connection is outright refused, we've lost (or never had) the
        // connection and need to set `connectionStatus` to `FAILURE` and bail.
        dispatch({
          type: 'SET_CONNECTION_FAILURE', // literal to avoid circular dependency
          payload: {
            response: {
              errorMessage: isConnectionConnected(connection.current)
                ? `DISCONNECTED`
                : e.message,
            },
          },
        });
      } else if (response && response.status === 401) {
        // Handle connections that are unauthorized by forcing the user to
        // connect again. This should only happen when an access token has expired.
        dispatch({
          type: 'SET_CONNECTION_FAILURE', // literal here to avoid circular dependency
          payload: {
            response,
          },
        });
      }
    }

    return errorAction;
  }
};

export async function request<
  Action extends RequestAction,
  APIMethod extends API | string,
  PendingType extends string,
  SuccessType extends string,
  FailureType extends string,
  // setting an arbitrary string as the default seems necessary to allow control flow
  // to work properly in the notebook reducer
  OnResponseStartType extends string = 'arbitrary_default',
  OnReceivedType extends string = 'arbitrary_default'
>(
  options: Options<
    Action,
    APIMethod,
    PendingType,
    SuccessType,
    FailureType,
    OnResponseStartType,
    OnReceivedType
  >
): Promise<
  | SuccessResponse<Action, APIMethod, SuccessType>
  | FailureResponse<Action, FailureType>

  // The below return types ARE A LIE!
  // they should be temporary convenience types
  // while we refactor action creators (including this request file)
  // into keyed maps so that we can precisely type actions.
  | Response<Action, PendingType, any>
  | Response<Action, OnResponseStartType, any>
  | Response<Action, OnReceivedType, any>
> {
  // Node-style requires here avoid circular dependencies.
  const { getStore } = require('src/common/store');
  const { getState, dispatch } = getStore();

  const connection = options.action.config || getState().connection.current;
  return wrappedRequest(options, { dispatch, connection });
}
