/* eslint-disable jsx-a11y/no-static-element-interactions */
import 'typeface-roboto';
import 'normalize.css/normalize.css';
import 'react-mosaic-component/react-mosaic-component.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/table/lib/css/table.css';
import 'src/common/styles/index.scss';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';

import { HotkeysProvider, HotkeysTarget2 } from '@blueprintjs/core';
import * as React from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';

import { CloudRedirectBanner } from 'src/browser/components/CloudRedirectBanner';
import { KeyboardShortcutsDialog } from 'src/browser/components/KeyboardShortcutsDialog';
import { browserTest } from 'src/browser/constants/browserTest';
import {
  KeyboardShortcuts,
  overriddenShortcuts,
} from 'src/browser/constants/KeyboardShortcuts';
import { AppHooks } from 'src/browser/containers/AppHooks';
import { StudioAction } from 'src/common/actions/StudioAction';
import { LoadingScreen } from 'src/common/components/LoadingScreen';
import { Notifications } from 'src/common/components/Notifications';
import { ROUTES } from 'src/common/constants/Routes';
import { StatusBar } from 'src/common/containers/App/components/StatusBar';
import { NavBar } from 'src/common/containers/AppNavbar/NavBar';
import { ModelsStatusBar } from 'src/common/containers/Models/components/ModelsStatusBar';
import { NotebookStatusBar } from 'src/common/containers/Notebook/NotebookStatusBar';
import { VirtualGraphsStatusBar } from 'src/common/containers/VirtualGraphs/VirtualGraphsStatusBar';
import {
  ConnectionFailureReason,
  ConnectionStatus,
} from 'src/common/store/connection/ConnectionState';
import { hasAnsweredTelemetryConsent } from 'src/common/utils/analytics';
import { isConnectionConnected } from 'src/common/utils/isConnectionConnected';
import { isDevelopment } from 'src/common/utils/isDevelopment';
import { isRenderingHeadlessly } from 'src/common/utils/isRenderingHeadlessly';
import { requestTelemetryConsent } from 'src/common/utils/rpc/requestTelemetryConsent';
import { warnUnsupportedBrowser } from 'src/common/utils/web/warnUnsupportedBrowser';
import { warnWebProtocolInconsistencies } from 'src/common/utils/web/warnWebProtocolInconsistencies';
import { ReduxState } from 'src/types';

const Notebook = React.lazy(
  () => import('src/common/containers/Notebook/Notebook')
);
const Starchart = React.lazy(
  () => import('src/common/containers/Starchart/Starchart')
);
const Models = React.lazy(() => import('src/common/containers/Models/Models'));
const VirtualGraphs = React.lazy(
  () => import('src/common/containers/VirtualGraphs/VirtualGraphs')
);
const Data = React.lazy(() => import('src/common/containers/Data/Data'));
const Databases = React.lazy(
  () => import('src/common/containers/DatabasesManager/DatabasesManager')
);
const Security = React.lazy(
  () => import('src/common/containers/Security/Security')
);

const mapStateToProps = ({ features, connection, preferences }: ReduxState) => {
  const { current: currentConnection } = connection;
  const { telemetryConsent } = preferences;

  return {
    currentConnection,
    features,
    isConnected: isConnectionConnected(currentConnection),
    telemetryConsent,
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type OwnProps = {
  dispatch: Dispatch<StudioAction>;
} & RouteComponentProps<any>;
type AppProps = StateProps & OwnProps;

// @ts-ignore: react-dnd’s typings don’t allow custom properties on component instances
@DragDropContext(HTML5Backend)
class UnconnectedApp extends React.PureComponent<AppProps> {
  componentDidMount() {
    if (!isRenderingHeadlessly) {
      let openingDialogSeries = Promise.resolve();
      if (!browserTest.test(navigator.userAgent)) {
        openingDialogSeries = openingDialogSeries.then(warnUnsupportedBrowser);
      }
      const { telemetryConsent } = this.props;
      if (!hasAnsweredTelemetryConsent({ telemetryConsent })) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        openingDialogSeries = openingDialogSeries.then(requestTelemetryConsent);
      }
    }
    // if we're not at the root route and we're not connected,
    // navigate to the editor (root) pane
    const { isConnected, history } = this.props;
    if (!isConnected && history.location.pathname !== ROUTES.NOTEBOOK) {
      history.push(ROUTES.NOTEBOOK);
    }
  }

  componentDidUpdate(prevProps) {
    // If we're not at the root route and we've lost our connection, return to
    // the editor (root) pane.
    const { currentConnection, isConnected, history } = this.props;
    if (
      prevProps.isConnected &&
      !isConnected &&
      history.location.pathname !== ROUTES.NOTEBOOK
    ) {
      history.push(ROUTES.NOTEBOOK);
    }

    // if connection failed due to no response, alert that it's possibly due
    // to http/https protocol inconsistencies
    if (
      prevProps.currentConnection.status === ConnectionStatus.PENDING &&
      currentConnection.status === ConnectionStatus.FAILURE &&
      currentConnection.failureReason === ConnectionFailureReason.NO_RESPONSE
    ) {
      warnWebProtocolInconsistencies(currentConnection.endpoint);
    }
  }

  componentDidCatch(err) {
    if (isDevelopment(process.env.NODE_ENV)) {
      console.error(err);
    }
  }

  render() {
    const { features, history } = this.props;

    const notebookRoute = (
      <Route
        exact
        path={ROUTES.NOTEBOOK}
        render={(props) =>
          isRenderingHeadlessly ? (
            <LoadingScreen />
          ) : (
            <React.Suspense fallback={<LoadingScreen />}>
              <Notebook {...props} />
            </React.Suspense>
          )
        }
      />
    );
    const starchartRoute = (
      <Route
        exact
        path={ROUTES.STARCHART}
        render={(props) => (
          <React.Suspense fallback={<LoadingScreen />}>
            <Starchart {...props} />
          </React.Suspense>
        )}
      />
    );
    const modelsRoute = (
      <Route
        exact
        path={ROUTES.MODELS}
        render={(props) => (
          <React.Suspense fallback={<LoadingScreen />}>
            <Models {...props} />
          </React.Suspense>
        )}
      />
    );
    const virtualGraphsRoute = (
      <Route
        exact
        path={ROUTES.VIRTUAL_GRAPHS}
        render={(props) => (
          <React.Suspense fallback={<LoadingScreen />}>
            <VirtualGraphs {...props} />
          </React.Suspense>
        )}
      />
    );
    const dataRoute = features.stardog.isDataSourcesSupported ? (
      <Route
        exact
        path={ROUTES.DATA}
        render={(props) => (
          <React.Suspense fallback={<LoadingScreen />}>
            <Data {...props} />
          </React.Suspense>
        )}
      />
    ) : null;
    const databasesRoute = (
      <Route
        exact
        path={ROUTES.DATABASES}
        render={(props) => (
          <React.Suspense fallback={<LoadingScreen />}>
            <Databases {...props} />
          </React.Suspense>
        )}
      />
    );
    const securityRoute = (
      <Route
        path={ROUTES.SECURITY}
        render={(props) => (
          <React.Suspense fallback={<LoadingScreen />}>
            <Security {...props} />
          </React.Suspense>
        )}
      />
    );

    return (
      <HotkeysProvider>
        <HotkeysTarget2
          hotkeys={[...KeyboardShortcuts.help, ...overriddenShortcuts]}
          // this is set to a browser shortcut that can't be overriden
          // so that the default blueprint hotkey dialog will never show
          options={{ showDialogKeyCombo: 'mod + w' }}
        >
          {({
            handleKeyDown,
            handleKeyUp,
          }: {
            handleKeyDown: React.KeyboardEventHandler<HTMLDivElement>;
            handleKeyUp: React.KeyboardEventHandler<HTMLDivElement>;
          }) => (
            <>
              <AppHooks />
              <CloudRedirectBanner />
              <div
                className="sd-app-container"
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
              >
                <NavBar history={history} />
                <div className="sd-app-main-view-container not-draggable">
                  {notebookRoute}
                  {dataRoute}
                  {starchartRoute}
                  {modelsRoute}
                  {virtualGraphsRoute}
                  {databasesRoute}
                  {securityRoute}
                </div>
                {isRenderingHeadlessly ? null : <Notifications />}
              </div>
              <StatusBar>
                <Route component={ModelsStatusBar} exact path={ROUTES.MODELS} />
                <Route
                  component={NotebookStatusBar}
                  exact
                  path={ROUTES.NOTEBOOK}
                />
                <Route
                  component={VirtualGraphsStatusBar}
                  exact
                  path={ROUTES.VIRTUAL_GRAPHS}
                />
              </StatusBar>
              <KeyboardShortcutsDialog />
            </>
          )}
        </HotkeysTarget2>
      </HotkeysProvider>
    );
  }
}

export const App = withRouter(
  connect<StateProps, any, OwnProps>(mapStateToProps)(UnconnectedApp)
);
