import { dataSources as stardogDataSources } from 'stardog';

import { request } from 'src/common/actions/request';
import { RequestSilent, Response } from 'src/common/actions/request/types';
import {
  ActionCreatorAction,
  createTypedAction,
} from 'src/common/actions/types';
import {
  DataMosaicStateChange,
  DataSource,
} from 'src/common/store/data/DataState';
import { VirtualGraphOptionValueMap } from 'src/common/store/virtualGraphs/options';
import {
  VirtualGraphMappingType,
  VirtualGraphSource,
} from 'src/common/store/virtualGraphs/VirtualGraphMappingType';
import { filterVirtualGraphOptions } from 'src/common/utils/virtual_graphs/filterVirtualGraphOptions';

export const enum DataActionType {
  DELETE_DATA_SOURCE_ATTEMPT = 'DELETE_DATA_SOURCE_ATTEMPT',
  DELETE_DATA_SOURCE_SUCCESS = 'DELETE_DATA_SOURCE_SUCCESS',
  DELETE_DATA_SOURCE_FAILURE = 'DELETE_DATA_SOURCE_FAILURE',

  REQUEST_DATA_SOURCES_LIST_INFO_ATTEMPT = 'REQUEST_DATA_SOURCES_LIST_INFO_ATTEMPT',
  REQUEST_DATA_SOURCES_LIST_INFO_SUCCESS = 'REQUEST_DATA_SOURCES_LIST_INFO_SUCCESS',
  REQUEST_DATA_SOURCES_LIST_INFO_FAILURE = 'REQUEST_DATA_SOURCES_LIST_INFO_FAILURE',

  REQUEST_OPTIONS_FOR_DATA_SOURCES_ATTEMPT = 'REQUEST_OPTIONS_FOR_DATA_SOURCES_ATTEMPT',
  REQUEST_OPTIONS_FOR_DATA_SOURCES_SUCCESS = 'REQUEST_OPTIONS_FOR_DATA_SOURCES_SUCCESS',
  REQUEST_OPTIONS_FOR_DATA_SOURCES_FAILURE = 'REQUEST_OPTIONS_FOR_DATA_SOURCES_FAILURE',

  REQUEST_OPTIONS_FOR_DATA_SOURCE_ATTEMPT = 'REQUEST_OPTIONS_FOR_DATA_SOURCE_ATTEMPT',
  REQUEST_OPTIONS_FOR_DATA_SOURCE_SUCCESS = 'REQUEST_OPTIONS_FOR_DATA_SOURCE_SUCCESS',
  REQUEST_OPTIONS_FOR_DATA_SOURCE_FAILURE = 'REQUEST_OPTIONS_FOR_DATA_SOURCE_FAILURE',

  BRING_DATA_SOURCE_ONLINE_ATTEMPT = 'BRING_DATA_SOURCE_ONLINE_ATTEMPT',
  BRING_DATA_SOURCE_ONLINE_SUCCESS = 'BRING_DATA_SOURCE_ONLINE_SUCCESS',
  BRING_DATA_SOURCE_ONLINE_FAILURE = 'BRING_DATA_SOURCE_ONLINE_FAILURE',

  CREATE_DATA_SOURCE_ATTEMPT = 'CREATE_DATA_SOURCE_ATTEMPT',
  CREATE_DATA_SOURCE_SUCCESS = 'CREATE_DATA_SOURCE_SUCCESS',
  CREATE_DATA_SOURCE_FAILURE = 'CREATE_DATA_SOURCE_FAILURE',

  UPDATE_DATA_SOURCE_ATTEMPT = 'UPDATE_DATA_SOURCE_ATTEMPT',
  UPDATE_DATA_SOURCE_SUCCESS = 'UPDATE_DATA_SOURCE_SUCCESS',
  UPDATE_DATA_SOURCE_FAILURE = 'UPDATE_DATA_SOURCE_FAILURE',

  SHARE_DATA_SOURCE_ATTEMPT = 'SHARE_DATA_SOURCE_ATTEMPT',
  SHARE_DATA_SOURCE_SUCCESS = 'SHARE_DATA_SOURCE_SUCCESS',
  SHARE_DATA_SOURCE_FAILURE = 'SHARE_DATA_SOURCE_FAILURE',

  SET_SELECTED_DATA_SOURCE_ID = 'SET_SELECTED_DATA_SOURCE_ID',
  SHOW_DATA_EDIT_DISABLED_NOTIFICATION = 'SHOW_DATA_EDIT_DISABLED_NOTIFICATION',
  UPDATE_LOCAL_DATA_SOURCE = 'UPDATE_LOCAL_DATA_SOURCE',
  UPDATE_DATA_MOSAIC_STATE = 'UPDATE_MODELS_MOSAIC_STATE',
}

export const dataActionCreators = {
  requestOptionsForDataSourcesAttempt: () =>
    createTypedAction(DataActionType.REQUEST_OPTIONS_FOR_DATA_SOURCES_ATTEMPT),
  requestOptionsForDataSourcesSuccess: (
    responses: Array<
      Response<{}, DataActionType.REQUEST_OPTIONS_FOR_DATA_SOURCE_SUCCESS, any>
    >
  ) =>
    createTypedAction(DataActionType.REQUEST_OPTIONS_FOR_DATA_SOURCES_SUCCESS, {
      responses,
    }),
  requestOptionsForDataSourcesFailure: () =>
    createTypedAction(DataActionType.REQUEST_OPTIONS_FOR_DATA_SOURCES_FAILURE),

  // bringDataSourceOnline action payloads mimic the ones for Stardog requests
  bringDataSourceOnlineAttempt: (dataSourceId: string) =>
    createTypedAction(DataActionType.BRING_DATA_SOURCE_ONLINE_ATTEMPT, {
      action: { dataSourceId },
    }),
  bringDataSourceOnlineSuccess: (dataSourceId: string) =>
    createTypedAction(DataActionType.BRING_DATA_SOURCE_ONLINE_SUCCESS, {
      action: { dataSourceId },
    }),
  bringDataSourceOnlineFailure: (dataSourceId: string, response: any) =>
    createTypedAction(DataActionType.BRING_DATA_SOURCE_ONLINE_FAILURE, {
      action: { dataSourceId },
      response,
    }),

  setSelectedDataSourceId: (dataSourceId: string) =>
    createTypedAction(DataActionType.SET_SELECTED_DATA_SOURCE_ID, {
      dataSourceId,
    }),
  showEditDisabledNotification: (message: string) =>
    createTypedAction(DataActionType.SHOW_DATA_EDIT_DISABLED_NOTIFICATION, {
      message,
    }),
  updateLocalDataSource: (
    dataSourceId: string,
    source: DataSource['localSource'],
    type: DataSource['localType'],
    properties: DataSource['localProperties'],
    validationErrors: Partial<DataSource['validationErrors']>,
    missingFieldNames: string[]
  ) =>
    createTypedAction(DataActionType.UPDATE_LOCAL_DATA_SOURCE, {
      dataSourceId,
      source,
      type,
      properties,
      validationErrors,
      missingFieldNames,
    }),
  updateDataMosaicState: (mosaicStateChange: DataMosaicStateChange) =>
    createTypedAction(DataActionType.UPDATE_DATA_MOSAIC_STATE, {
      mosaicStateChange,
    }),
};

export const dataStardogRequestDispatchers = {
  deleteDataSource: async (
    dataSourceName: string,
    shouldNotDispatch?: boolean
  ) =>
    request({
      api: stardogDataSources.remove,
      args: [dataSourceName, { force: true }],
      loading: DataActionType.DELETE_DATA_SOURCE_ATTEMPT,
      success: DataActionType.DELETE_DATA_SOURCE_SUCCESS,
      failure: DataActionType.DELETE_DATA_SOURCE_FAILURE,
      action: { dataSourceName },
      shouldNotDispatch,
    }),

  requestDataSourcesListInfo: async () =>
    request({
      api: stardogDataSources.listInfo,
      args: [],
      loading: DataActionType.REQUEST_DATA_SOURCES_LIST_INFO_ATTEMPT,
      success: DataActionType.REQUEST_DATA_SOURCES_LIST_INFO_SUCCESS,
      failure: DataActionType.REQUEST_DATA_SOURCES_LIST_INFO_FAILURE,
      action: {},
    }),

  requestOptionsForDataSource: async ({
    dataSourceName,
    overrideLocalData,
    shouldNotDispatch,
  }: {
    dataSourceName: string;
    overrideLocalData?: boolean;
    shouldNotDispatch?: boolean;
  }) =>
    request({
      api: stardogDataSources.options,
      args: [dataSourceName],
      loading: DataActionType.REQUEST_OPTIONS_FOR_DATA_SOURCE_ATTEMPT,
      success: DataActionType.REQUEST_OPTIONS_FOR_DATA_SOURCE_SUCCESS,
      failure: DataActionType.REQUEST_OPTIONS_FOR_DATA_SOURCE_FAILURE,
      action: {
        dataSourceName,
        overrideLocalData,
      },
      shouldNotDispatch,
    }),

  bringDataSourceOnline: async (dataSourceId: string) =>
    request({
      api: stardogDataSources.online,
      args: [dataSourceId],
      loading: DataActionType.BRING_DATA_SOURCE_ONLINE_ATTEMPT,
      success: DataActionType.BRING_DATA_SOURCE_ONLINE_SUCCESS,
      failure: DataActionType.BRING_DATA_SOURCE_ONLINE_FAILURE,
      action: {
        dataSourceId,
      },
    }),

  createDataSource: async ({
    dataSourceName,
    source,
    sourceType,
    options,
    silent,
  }: {
    dataSourceName: string;
    source: VirtualGraphSource;
    sourceType: VirtualGraphMappingType;
    options: VirtualGraphOptionValueMap;
    silent?: RequestSilent;
  }) => {
    const filteredOptions = filterVirtualGraphOptions(options);
    return request({
      api: stardogDataSources.add,
      args: [dataSourceName, filteredOptions],
      loading: DataActionType.CREATE_DATA_SOURCE_ATTEMPT,
      success: DataActionType.CREATE_DATA_SOURCE_SUCCESS,
      failure: DataActionType.CREATE_DATA_SOURCE_FAILURE,
      action: {
        dataSourceName,
        source,
        sourceType,
        options: filteredOptions,
      },
      silent,
    });
  },

  shareDataSource: (dataSourceName: string) => {
    return request({
      api: stardogDataSources.share,
      args: [dataSourceName],
      loading: DataActionType.SHARE_DATA_SOURCE_ATTEMPT,
      success: DataActionType.SHARE_DATA_SOURCE_SUCCESS,
      failure: DataActionType.SHARE_DATA_SOURCE_FAILURE,
      action: {
        dataSourceName,
      },
    });
  },

  updateDataSource: async ({
    dataSourceName,
    source,
    sourceType,
    options,
    requestOptions,
  }: {
    dataSourceName: string;
    source: VirtualGraphSource;
    sourceType: VirtualGraphMappingType;
    options: VirtualGraphOptionValueMap;
    requestOptions?: { force?: boolean };
  }) => {
    const filteredOptions = filterVirtualGraphOptions(options);
    return request({
      api: stardogDataSources.update,
      args: [dataSourceName, filteredOptions, requestOptions],
      loading: DataActionType.UPDATE_DATA_SOURCE_ATTEMPT,
      success: DataActionType.UPDATE_DATA_SOURCE_SUCCESS,
      failure: DataActionType.UPDATE_DATA_SOURCE_FAILURE,
      action: {
        dataSourceName,
        source,
        sourceType,
        options: filteredOptions,
      },
    });
  },
};

const dataActionTypeMap = {
  ...dataActionCreators,
  ...dataStardogRequestDispatchers,
};

export type DataAction = ActionCreatorAction<typeof dataActionTypeMap>;
