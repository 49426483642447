import * as Monaco from 'monaco-editor/esm/vs/editor/editor.api';
import { HTTP, db as stardogDb, query as stardogQuery } from 'stardog';

import { request } from 'src/common/actions/request';
import {
  ActionCreatorAction,
  createTypedAction,
} from 'src/common/actions/types';
import { EDITOR_QUERY_LIMIT } from 'src/common/constants/editorLimits';
import {
  ModelsItemLocalData,
  ModelsMosaicStateChange,
  ModelsTabIds,
} from 'src/common/store/models/ModelsState';
import { rdfAcceptTypes } from 'src/common/utils/acceptTypes';
import { rdfExtensions } from 'src/common/utils/fileExtensions';
import { Label } from 'src/common/utils/labels/types';
import {
  RawModelsItemBinding,
  buildModelConstraintsGraphQuery,
  modelConstraintsRequestQuery,
} from 'src/common/utils/queryBuilders/models';
import { buildRdfsSchemaQuery } from 'src/common/utils/queryBuilders/schemas';

const turtleMimeType = rdfExtensions.fileExtensionToMimeType
  .ttl as HTTP.RdfMimeType;

export const enum ModelsActionType {
  CLEAR_MODELS_DATA = 'CLEAR_MODELS_DATA',

  CREATE_MODELS_ATTRIBUTE = 'CREATE_MODELS_ATTRIBUTE',
  CREATE_MODELS_CLASS = 'CREATE_MODELS_CLASS',
  CREATE_MODELS_RELATIONSHIP = 'CREATE_MODELS_RELATIONSHIP',

  DELETE_MODELS_ATTRIBUTE = 'DELETE_MODELS_ATTRIBUTE',
  DELETE_MODELS_CLASS = 'DELETE_MODELS_CLASS',
  DELETE_MODELS_RELATIONSHIP = 'DELETE_MODELS_RELATIONSHIP',

  REQUEST_MODEL_ITEMS_CHUNK_ATTEMPT = 'REQUEST_MODEL_ITEMS_CHUNK_ATTEMPT',
  REQUEST_MODEL_ITEMS_CHUNK_FAILURE = 'REQUEST_MODEL_ITEMS_CHUNK_FAILURE',
  REQUEST_MODEL_ITEMS_CHUNK_SUCCESS = 'REQUEST_MODEL_ITEMS_CHUNK_SUCCESS',

  REQUEST_MODEL_ATTRIBUTES_ATTEMPT = 'REQUEST_MODEL_ATTRIBUTES_ATTEMPT',
  REQUEST_MODEL_ATTRIBUTES_FAILURE = 'REQUEST_MODEL_ATTRIBUTES_FAILURE',
  REQUEST_MODEL_ATTRIBUTES_SUCCESS = 'REQUEST_MODEL_ATTRIBUTES_SUCCESS',

  REQUEST_MODEL_CLASSES_ATTEMPT = 'REQUEST_MODEL_CLASSES_ATTEMPT',
  REQUEST_MODEL_CLASSES_FAILURE = 'REQUEST_MODEL_CLASSES_FAILURE',
  REQUEST_MODEL_CLASSES_SUCCESS = 'REQUEST_MODEL_CLASSES_SUCCESS',

  REQUEST_MODEL_RELATIONSHIPS_ATTEMPT = 'REQUEST_MODEL_RELATIONSHIPS_ATTEMPT',
  REQUEST_MODEL_RELATIONSHIPS_FAILURE = 'REQUEST_MODEL_RELATIONSHIPS_FAILURE',
  REQUEST_MODEL_RELATIONSHIPS_SUCCESS = 'REQUEST_MODEL_RELATIONSHIPS_SUCCESS',

  REQUEST_MODEL_VISUALIZATION_DATA_ATTEMPT = 'REQUEST_MODEL_VISUALIZATION_DATA_ATTEMPT',
  REQUEST_MODEL_VISUALIZATION_DATA_SUCCESS = 'REQUEST_MODEL_VISUALIZATION_DATA_SUCCESS',
  REQUEST_MODEL_VISUALIZATION_DATA_FAILURE = 'REQUEST_MODEL_VISUALIZATION_DATA_FAILURE',

  SAVE_SELECTED_MODEL_ATTEMPT = 'SAVE_SELECTED_MODEL_ATTEMPT',
  SAVE_SELECTED_MODEL_FAILURE = 'SAVE_SELECTED_MODEL_FAILURE',
  SAVE_SELECTED_MODEL_SUCCESS = 'SAVE_SELECTED_MODEL_SUCCESS',

  SHOW_MODEL_EDIT_DISABLED_NOTIFICATION = 'SHOW_MODEL_EDIT_DISABLED_NOTIFICATION',

  SET_MODEL_ATTRIBUTE_IRI = 'SET_MODEL_ATTRIBUTE_IRI',
  SET_MODEL_CLASS_IRI = 'SET_MODEL_CLASS_IRI',
  SET_MODEL_RELATIONSHIP_IRI = 'SET_MODEL_RELATIONSHIP_IRI',

  SET_MODELS_DATABASE_ID = 'SET_MODELS_DATABASE_ID',
  SET_MODELS_SCHEMA_NAME = 'SET_MODELS_SCHEMA_NAME',
  SET_MODELS_SECTION = 'SET_MODELS_SECTION',
  SET_MODELS_NEWLY_CREATED = 'SET_MODELS_NEWLY_CREATED',

  SET_MODEL_ATTRIBUTE_NODE_OPEN = 'SET_MODEL_ATTRIBUTE_NODE_OPEN',
  SET_MODEL_CLASS_NODE_OPEN = 'SET_MODEL_CLASS_NODE_OPEN',
  SET_MODEL_RELATIONSHIP_NODE_OPEN = 'SET_MODEL_CLASSRELATIONSHIP_OPEN',
  SET_MODEL_ATTRIBUTE_NODE_CLOSED = 'SET_MODEL_ATTRIBUTE_NODE_CLOSED',
  SET_MODEL_CLASS_NODE_CLOSED = 'SET_MODEL_CLASS_NODE_CLOSED',
  SET_MODEL_RELATIONSHIP_NODE_CLOSED = 'SET_MODEL_CLASSRELATIONSHIP_CLOSED',

  UPDATE_MODEL_CLASS_LOCAL_DATA = 'UPDATE_MODEL_CLASS_LOCAL_DATA',
  UPDATE_MODEL_ATTRIBUTE_LOCAL_DATA = 'UPDATE_MODEL_ATTRIBUTE_LOCAL_DATA',
  UPDATE_MODEL_RELATIONSHIP_LOCAL_DATA = 'UPDATE_MODEL_RELATIONSHIP_LOCAL_DATA',

  UPDATE_MODELS_MOSAIC_STATE = 'UPDATE_MODELS_MOSAIC_STATE',

  // constraints

  CLOSE_MODEL_CONSTRAINTS_EDIT_WARNING = 'CLOSE_MODEL_CONSTRAINTS_EDIT_WARNING',
  RESIZE_MODEL_CONSTRAINTS_REPORT = 'RESIZE_MODEL_CONSTRAINTS_REPORT',
  REQUEST_MODEL_CONSTRAINTS_ATTEMPT = 'REQUEST_MODEL_CONSTRAINTS_ATTEMPT',
  REQUEST_MODEL_CONSTRAINTS_FAILURE = 'REQUEST_MODEL_CONSTRAINTS_FAILURE',
  REQUEST_MODEL_CONSTRAINTS_SUCCESS = 'REQUEST_MODEL_CONSTRAINTS_SUCCESS',
  REQUEST_MODEL_CONSTRAINTS_TEXT_ATTEMPT = 'REQUEST_MODEL_CONSTRAINTS_TEXT_ATTEMPT',
  REQUEST_MODEL_CONSTRAINTS_TEXT_FAILURE = 'REQUEST_MODEL_CONSTRAINTS_TEXT_FAILURE',
  REQUEST_MODEL_CONSTRAINTS_TEXT_SUCCESS = 'REQUEST_MODEL_CONSTRAINTS_TEXT_SUCCESS',
  REQUEST_MODEL_CONSTRAINTS_GRAPHS_ATTEMPT = 'REQUEST_MODEL_CONSTRAINTS_GRAPHS_ATTEMPT',
  REQUEST_MODEL_CONSTRAINTS_GRAPHS_FAILURE = 'REQUEST_MODEL_CONSTRAINTS_GRAPHS_FAILURE',
  REQUEST_MODEL_CONSTRAINTS_GRAPHS_SUCCESS = 'REQUEST_MODEL_CONSTRAINTS_GRAPHS_SUCCESS',
  REQUEST_MODEL_CONSTRAINTS_REPORT_ATTEMPT = 'REQUEST_MODEL_CONSTRAINTS_REPORT_ATTEMPT',
  REQUEST_MODEL_CONSTRAINTS_REPORT_FAILURE = 'REQUEST_MODEL_CONSTRAINTS_REPORT_FAILURE',
  REQUEST_MODEL_CONSTRAINTS_REPORT_SUCCESS = 'REQUEST_MODEL_CONSTRAINTS_REPORT_SUCCESS',
  SAVE_MODEL_CONSTRAINTS_EDITOR_VIEW_STATE = 'SAVE_MODEL_CONSTRAINTS_EDITOR_VIEW_STATE',
  SAVE_MODEL_CONSTRAINTS_REPORT_VIEW_STATE = 'SAVE_MODEL_CONSTRAINTS_REPORT_VIEW_STATE',
  SAVE_MODEL_CONSTRAINTS_REPORT_ATTEMPT = 'SAVE_MODEL_CONSTRAINTS_REPORT_ATTEMPT',
  SAVE_MODEL_CONSTRAINTS_REPORT_SUCCESS = 'SAVE_MODEL_CONSTRAINTS_REPORT_SUCCESS',
  SAVE_MODEL_CONSTRAINTS_REPORT_FAILURE = 'SAVE_MODEL_CONSTRAINTS_REPORT_FAILURE',
  SHOW_MODEL_CONSTRAINTS_DISABLED_SYNTAX_NOTIFICATION = 'SHOW_MODEL_CONSTRAINTS_DISABLED_SYNTAX_NOTIFICATION',
  SHOW_MODEL_CONSTRAINTS_EDIT_WARNING = 'SHOW_MODEL_CONSTRAINTS_EDIT_WARNING',
  SET_MODEL_CONSTRAINTS_REPORT_NAMED_GRAPHS = 'SET_MODEL_CONSTRAINTS_REPORT_NAMED_GRAPHS',
  SET_MODEL_CONSTRAINTS_LOCAL_DOC = 'SET_LOCAL_MODEL_CONSTRAINTS_DOC',
  TOGGLE_MODEL_CONSTRAINTS_REPORT_COLLAPSE = 'TOGGLE_MODEL_CONSTRAINTS_REPORT_COLLAPSE',
  TOGGLE_MODEL_CONSTRAINTS_SYNTAX_FEATURES = 'TOGGLE_MODEL_CONSTRAINTS_SYNTAX_FEATURES',

  // text editor
  REQUEST_MODEL_TEXT_ATTEMPT = 'REQUEST_MODEL_TEXT_ATTEMPT',
  REQUEST_MODEL_TEXT_FAILURE = 'REQUEST_MODEL_TEXT_FAILURE',
  REQUEST_MODEL_TEXT_SUCCESS = 'REQUEST_MODEL_TEXT_SUCCESS',
  SAVE_MODEL_TEXT_ATTEMPT = 'SAVE_MODEL_TEXT_ATTEMPT',
  SAVE_MODEL_TEXT_FAILURE = 'SAVE_MODEL_TEXT_FAILURE',
  SAVE_MODEL_TEXT_SUCCESS = 'SAVE_MODEL_TEXT_SUCCESS',
  SHOW_MODEL_TEXT_EDITOR_DISABLED_SYNTAX_NOTIFICATION = 'SHOW_MODEL_TEXT_EDITOR_DISABLED_SYNTAX_NOTIFICATION',
  SET_MODEL_TEXT_EDITOR_LOCAL_DOC = 'SET_MODEL_TEXT_EDITOR_LOCAL_DOC',
  TOGGLE_MODEL_TEXT_EDITOR_SYNTAX_FEATURES = 'TOGGLE_MODELS_TEXT_EDITOR_SYNTAX_FEATURES',
}

const constraintsActionCreators = {
  requestModelConstraintsAttempt: (databaseId: string, schemaName: string) =>
    createTypedAction(ModelsActionType.REQUEST_MODEL_CONSTRAINTS_ATTEMPT, {
      databaseId,
      schemaName,
    }),
  requestModelConstraintsFailure: (databaseId: string, schemaName: string) =>
    createTypedAction(ModelsActionType.REQUEST_MODEL_CONSTRAINTS_FAILURE, {
      databaseId,
      schemaName,
    }),
  requestModelConstraintsSuccess: (
    databaseId: string,
    schemaName: string,
    body: string,
    requestedNamedGraphs: string[]
  ) =>
    createTypedAction(ModelsActionType.REQUEST_MODEL_CONSTRAINTS_SUCCESS, {
      body,
      databaseId,
      requestedNamedGraphs,
      schemaName,
    }),
  resizeReport: (
    databaseId: string,
    schemaName: string,
    height: number,
    autoCollapse: boolean
  ) =>
    createTypedAction(ModelsActionType.RESIZE_MODEL_CONSTRAINTS_REPORT, {
      autoCollapse,
      databaseId,
      height,
      schemaName,
    }),
  saveConstraintsEditorViewState: (
    databaseId: string,
    schemaName: string,
    viewState: Monaco.editor.ICodeEditorViewState
  ) =>
    createTypedAction(
      ModelsActionType.SAVE_MODEL_CONSTRAINTS_EDITOR_VIEW_STATE,
      { databaseId, schemaName, viewState }
    ),
  saveConstraintsReportViewState: (
    databaseId: string,
    schemaName: string,
    viewState: Monaco.editor.ICodeEditorViewState
  ) =>
    createTypedAction(
      ModelsActionType.SAVE_MODEL_CONSTRAINTS_REPORT_VIEW_STATE,
      { databaseId, schemaName, viewState }
    ),
  saveConstraintsReportToFileAttempt: (
    databaseId: string,
    schemaName: string,
    filename: string
  ) =>
    createTypedAction(ModelsActionType.SAVE_MODEL_CONSTRAINTS_REPORT_ATTEMPT, {
      databaseId,
      schemaName,
      filename,
    }),
  saveConstraintsReportToFileFailure: (
    databaseId: string,
    schemaName: string,
    filename: string
  ) =>
    createTypedAction(ModelsActionType.SAVE_MODEL_CONSTRAINTS_REPORT_FAILURE, {
      databaseId,
      schemaName,
      filename,
    }),
  saveConstraintsReportToFileSuccess: (
    databaseId: string,
    schemaName: string,
    filePath: string
  ) =>
    createTypedAction(ModelsActionType.SAVE_MODEL_CONSTRAINTS_REPORT_SUCCESS, {
      databaseId,
      schemaName,
      filePath,
    }),
  setConstraintsLocalDoc: (
    databaseId: string,
    schemaName: string,
    localDoc: string
  ) =>
    createTypedAction(ModelsActionType.SET_MODEL_CONSTRAINTS_LOCAL_DOC, {
      databaseId,
      schemaName,
      localDoc,
    }),
  setNamedGraphsForReport: (
    databaseId: string,
    schemaName: string,
    namedGraphsForReport: string[]
  ) =>
    createTypedAction(
      ModelsActionType.SET_MODEL_CONSTRAINTS_REPORT_NAMED_GRAPHS,
      {
        databaseId,
        schemaName,
        namedGraphsForReport,
      }
    ),
  showConstraintsDisabledSyntaxNotification: (
    databaseId: string,
    schemaName: string,
    buttonAction: () => void,
    closeNotification: (id: string) => void
  ) =>
    createTypedAction(
      ModelsActionType.SHOW_MODEL_CONSTRAINTS_DISABLED_SYNTAX_NOTIFICATION,
      {
        buttonAction,
        closeNotification,
        databaseId,
        schemaName,
      }
    ),
  closeConstraintsEditWarning: (databaseId: string, schemaName: string) =>
    createTypedAction(ModelsActionType.CLOSE_MODEL_CONSTRAINTS_EDIT_WARNING, {
      databaseId,
      schemaName,
    }),
  attemptShowConstraintsEditWarning: (databaseId: string, schemaName: string) =>
    createTypedAction(ModelsActionType.SHOW_MODEL_CONSTRAINTS_EDIT_WARNING, {
      databaseId,
      schemaName,
    }),
  toggleConstraintsSyntaxFeatures: (
    databaseId: string,
    schemaName: string,
    areSyntaxFeaturesDisabled: boolean
  ) =>
    createTypedAction(
      ModelsActionType.TOGGLE_MODEL_CONSTRAINTS_SYNTAX_FEATURES,
      {
        databaseId,
        schemaName,
        areSyntaxFeaturesDisabled,
      }
    ),
  toggleConstraintsReportCollapse: (databaseId: string, schemaName: string) =>
    createTypedAction(
      ModelsActionType.TOGGLE_MODEL_CONSTRAINTS_REPORT_COLLAPSE,
      { databaseId, schemaName }
    ),
};

const textEditorActionCreators = {
  saveModelTextAttempt: (databaseId: string, schemaName: string) =>
    createTypedAction(ModelsActionType.SAVE_MODEL_TEXT_ATTEMPT, {
      databaseId,
      schemaName,
    }),
  saveModelTextFailure: (
    databaseId: string,
    schemaName: string,
    exception: Error
  ) =>
    createTypedAction(ModelsActionType.SAVE_MODEL_TEXT_FAILURE, {
      databaseId,
      exception,
      schemaName,
    }),
  saveModelTextSuccess: (
    databaseId: string,
    schemaName: string,
    updatedDoc: string
  ) =>
    createTypedAction(ModelsActionType.SAVE_MODEL_TEXT_SUCCESS, {
      databaseId,
      schemaName,
      updatedDoc,
    }),
  setTextEditorLocalDoc: (
    databaseId: string,
    schemaName: string,
    localDoc: string
  ) =>
    createTypedAction(ModelsActionType.SET_MODEL_TEXT_EDITOR_LOCAL_DOC, {
      databaseId,
      localDoc,
      schemaName,
    }),
  showTextEditorDisabledSyntaxNotification: (
    databaseId: string,
    schemaName: string,
    buttonAction: () => void,
    closeNotification: (id: string) => void
  ) =>
    createTypedAction(
      ModelsActionType.SHOW_MODEL_TEXT_EDITOR_DISABLED_SYNTAX_NOTIFICATION,
      {
        buttonAction,
        closeNotification,
        databaseId,
        schemaName,
      }
    ),
  toggleTextEditorSyntaxFeatures: (
    databaseId: string,
    schemaName: string,
    areSyntaxFeaturesDisabled: boolean
  ) =>
    createTypedAction(
      ModelsActionType.TOGGLE_MODEL_TEXT_EDITOR_SYNTAX_FEATURES,
      {
        areSyntaxFeaturesDisabled,
        databaseId,
        schemaName,
      }
    ),
};

export const modelsActionCreators = {
  ...constraintsActionCreators,
  ...textEditorActionCreators,
  clearModelsData: () => createTypedAction(ModelsActionType.CLEAR_MODELS_DATA),
  createModelsAttribute: (
    databaseId: string,
    schemaName: string,
    attributeIri: string,
    parentIri: string,
    label: Label,
    predicate: string
  ) =>
    createTypedAction(ModelsActionType.CREATE_MODELS_ATTRIBUTE, {
      databaseId,
      attributeIri,
      label,
      parentIri,
      predicate,
      schemaName,
    }),
  createModelsClass: (
    databaseId: string,
    schemaName: string,
    classIri: string,
    parentIri: string,
    label: Label,
    predicate: string
  ) =>
    createTypedAction(ModelsActionType.CREATE_MODELS_CLASS, {
      databaseId,
      classIri,
      label,
      parentIri,
      predicate,
      schemaName,
    }),
  createModelsRelationship: (
    databaseId: string,
    schemaName: string,
    relationshipIri: string,
    parentIri: string,
    label: Label,
    predicate: string
  ) =>
    createTypedAction(ModelsActionType.CREATE_MODELS_RELATIONSHIP, {
      databaseId,
      relationshipIri,
      label,
      parentIri,
      predicate,
      schemaName,
    }),
  deleteModelsAttribute: (
    databaseId: string,
    schemaName: string,
    attributeIri: string
  ) =>
    createTypedAction(ModelsActionType.DELETE_MODELS_ATTRIBUTE, {
      databaseId,
      schemaName,
      attributeIri,
    }),
  deleteModelsClass: (
    databaseId: string,
    schemaName: string,
    classIri: string
  ) =>
    createTypedAction(ModelsActionType.DELETE_MODELS_CLASS, {
      databaseId,
      schemaName,
      classIri,
    }),
  deleteModelsRelationship: (
    databaseId: string,
    schemaName: string,
    relationshipIri: string,
    isEdgePropertiesEnabled?: boolean
  ) =>
    createTypedAction(ModelsActionType.DELETE_MODELS_RELATIONSHIP, {
      databaseId,
      schemaName,
      relationshipIri,
      isEdgePropertiesEnabled,
    }),
  requestModelAttributesAttempt: (databaseId: string, schemaName: string) =>
    createTypedAction(ModelsActionType.REQUEST_MODEL_ATTRIBUTES_ATTEMPT, {
      databaseId,
      schemaName,
    }),
  requestModelAttributesFailure: (
    databaseId: string,
    schemaName: string,
    exception: Error
  ) =>
    createTypedAction(ModelsActionType.REQUEST_MODEL_ATTRIBUTES_FAILURE, {
      databaseId,
      schemaName,
      exception,
    }),
  requestModelAttributesSuccess: (
    databaseId: string,
    schemaName: string,
    bindings: RawModelsItemBinding[]
  ) =>
    createTypedAction(ModelsActionType.REQUEST_MODEL_ATTRIBUTES_SUCCESS, {
      databaseId,
      schemaName,
      bindings,
    }),
  requestModelClassesAttempt: (databaseId: string, schemaName: string) =>
    createTypedAction(ModelsActionType.REQUEST_MODEL_CLASSES_ATTEMPT, {
      databaseId,
      schemaName,
    }),
  requestModelClassesFailure: (
    databaseId: string,
    schemaName: string,
    exception: Error
  ) =>
    createTypedAction(ModelsActionType.REQUEST_MODEL_CLASSES_FAILURE, {
      databaseId,
      schemaName,
      exception,
    }),
  requestModelClassesSuccess: (
    databaseId: string,
    schemaName: string,
    bindings: RawModelsItemBinding[]
  ) =>
    createTypedAction(ModelsActionType.REQUEST_MODEL_CLASSES_SUCCESS, {
      databaseId,
      schemaName,
      bindings,
    }),
  requestModelRelationshipsAttempt: (databaseId: string, schemaName: string) =>
    createTypedAction(ModelsActionType.REQUEST_MODEL_RELATIONSHIPS_ATTEMPT, {
      databaseId,
      schemaName,
    }),
  requestModelRelationshipsFailure: (
    databaseId: string,
    schemaName: string,
    exception: Error
  ) =>
    createTypedAction(ModelsActionType.REQUEST_MODEL_RELATIONSHIPS_FAILURE, {
      databaseId,
      schemaName,
      exception,
    }),
  requestModelRelationshipsSuccess: (
    databaseId: string,
    schemaName: string,
    bindings: RawModelsItemBinding[]
  ) =>
    createTypedAction(ModelsActionType.REQUEST_MODEL_RELATIONSHIPS_SUCCESS, {
      databaseId,
      schemaName,
      bindings,
    }),

  setModelAttributeIri: (
    databaseId: string,
    schemaName: string,
    attributeIri: string,
    path?: string[]
  ) =>
    createTypedAction(ModelsActionType.SET_MODEL_ATTRIBUTE_IRI, {
      attributeIri,
      databaseId,
      path,
      schemaName,
    }),
  setModelClassIri: (
    databaseId: string,
    schemaName: string,
    classIri: string,
    path?: string[]
  ) =>
    createTypedAction(ModelsActionType.SET_MODEL_CLASS_IRI, {
      classIri,
      databaseId,
      path,
      schemaName,
    }),
  setModelRelationshipIri: (
    databaseId: string,
    schemaName: string,
    relationshipIri: string,
    path?: string[]
  ) =>
    createTypedAction(ModelsActionType.SET_MODEL_RELATIONSHIP_IRI, {
      relationshipIri,
      databaseId,
      path,
      schemaName,
    }),
  setNewlyCreated: (isNewlyCreated: boolean) =>
    createTypedAction(ModelsActionType.SET_MODELS_NEWLY_CREATED, {
      isNewlyCreated,
    }),
  saveSelectedModelAttempt: (databaseId: string, schemaName: string) =>
    createTypedAction(ModelsActionType.SAVE_SELECTED_MODEL_ATTEMPT, {
      databaseId,
      schemaName,
    }),
  saveSelectedModelFailure: (
    databaseId: string,
    schemaName: string,
    exception: Error
  ) =>
    createTypedAction(ModelsActionType.SAVE_SELECTED_MODEL_FAILURE, {
      databaseId,
      schemaName,
      exception,
    }),
  saveSelectedModelSuccess: (databaseId: string, schemaName: string) =>
    createTypedAction(ModelsActionType.SAVE_SELECTED_MODEL_SUCCESS, {
      databaseId,
      schemaName,
    }),
  setModelsDatabaseId: (prevDatabaseId: string, databaseId: string) =>
    createTypedAction(ModelsActionType.SET_MODELS_DATABASE_ID, {
      databaseId,
      prevDatabaseId,
    }),
  setModelsSchemaName: (schemaName: string) =>
    createTypedAction(ModelsActionType.SET_MODELS_SCHEMA_NAME, {
      schemaName,
    }),
  setModelsSection: (tabId: ModelsTabIds) =>
    createTypedAction(ModelsActionType.SET_MODELS_SECTION, {
      tabId,
    }),
  setModelAttributeNodeOpen: (
    databaseId: string,
    schemaName: string,
    path: string[]
  ) =>
    createTypedAction(ModelsActionType.SET_MODEL_ATTRIBUTE_NODE_OPEN, {
      databaseId,
      path,
      schemaName,
    }),
  setModelClassNodeOpen: (
    databaseId: string,
    schemaName: string,
    path: string[]
  ) =>
    createTypedAction(ModelsActionType.SET_MODEL_CLASS_NODE_OPEN, {
      databaseId,
      path,
      schemaName,
    }),
  setModelRelationshipNodeOpen: (
    databaseId: string,
    schemaName: string,
    path: string[]
  ) =>
    createTypedAction(ModelsActionType.SET_MODEL_RELATIONSHIP_NODE_OPEN, {
      databaseId,
      path,
      schemaName,
    }),
  setModelAttributeNodeClosed: (
    databaseId: string,
    schemaName: string,
    path: string[]
  ) =>
    createTypedAction(ModelsActionType.SET_MODEL_ATTRIBUTE_NODE_CLOSED, {
      databaseId,
      path,
      schemaName,
    }),
  setModelClassNodeClosed: (
    databaseId: string,
    schemaName: string,
    path: string[]
  ) =>
    createTypedAction(ModelsActionType.SET_MODEL_CLASS_NODE_CLOSED, {
      databaseId,
      path,
      schemaName,
    }),
  setModelRelationshipNodeClosed: (
    databaseId: string,
    schemaName: string,
    path: string[]
  ) =>
    createTypedAction(ModelsActionType.SET_MODEL_RELATIONSHIP_NODE_CLOSED, {
      databaseId,
      path,
      schemaName,
    }),
  showEditDisabledNotification: (message: string) =>
    createTypedAction(ModelsActionType.SHOW_MODEL_EDIT_DISABLED_NOTIFICATION, {
      message,
    }),
  updateModelAttributeLocalData: (
    databaseId: string,
    schemaName: string,
    attributeIri: string,
    localData: Partial<ModelsItemLocalData>
  ) =>
    createTypedAction(ModelsActionType.UPDATE_MODEL_ATTRIBUTE_LOCAL_DATA, {
      databaseId,
      schemaName,
      attributeIri,
      localData,
    }),
  updateModelClassLocalData: (
    databaseId: string,
    schemaName: string,
    classIri: string,
    localData: Partial<ModelsItemLocalData>
  ) =>
    createTypedAction(ModelsActionType.UPDATE_MODEL_CLASS_LOCAL_DATA, {
      databaseId,
      schemaName,
      classIri,
      localData,
    }),
  updateModelRelationshipLocalData: (
    databaseId: string,
    schemaName: string,
    relationshipIri: string,
    localData: Partial<ModelsItemLocalData>
  ) =>
    createTypedAction(ModelsActionType.UPDATE_MODEL_RELATIONSHIP_LOCAL_DATA, {
      databaseId,
      schemaName,
      relationshipIri,
      localData,
    }),
  updateModelsMosaicState: (mosaicStateChange: ModelsMosaicStateChange) =>
    createTypedAction(ModelsActionType.UPDATE_MODELS_MOSAIC_STATE, {
      mosaicStateChange,
    }),
};

export const modelsStardogRequestDispatchers = {
  requestModelConstraints: (
    databaseId: string,
    schemaName: string,
    namedGraphs: string[],
    shouldNotDispatch = false
  ) => {
    return request({
      api: stardogQuery.execute,
      args: [
        databaseId,
        modelConstraintsRequestQuery,
        'text/turtle',
        {
          prettify: true,
          useNamespaces: true,
          'graph-uri': namedGraphs,
        },
      ],
      loading: ModelsActionType.REQUEST_MODEL_CONSTRAINTS_TEXT_ATTEMPT,
      success: ModelsActionType.REQUEST_MODEL_CONSTRAINTS_TEXT_SUCCESS,
      failure: ModelsActionType.REQUEST_MODEL_CONSTRAINTS_TEXT_FAILURE,
      action: { databaseId, namedGraphs, schemaName },
      shouldNotDispatch,
    });
  },
  requestModelConstraintsGraphs: ({
    databaseId,
    stardogSupportsVirtualTransparency,
    shouldNotDispatch = false,
  }: {
    databaseId: string;
    stardogSupportsVirtualTransparency: boolean;
    shouldNotDispatch?: boolean;
  }) => {
    return request({
      api: stardogQuery.execute,
      args: [
        databaseId,
        buildModelConstraintsGraphQuery(stardogSupportsVirtualTransparency),
      ],
      loading: ModelsActionType.REQUEST_MODEL_CONSTRAINTS_GRAPHS_ATTEMPT,
      success: ModelsActionType.REQUEST_MODEL_CONSTRAINTS_GRAPHS_SUCCESS,
      failure: ModelsActionType.REQUEST_MODEL_CONSTRAINTS_GRAPHS_FAILURE,
      action: { databaseId },
      shouldNotDispatch,
    });
  },
  requestModelConstraintsReport: ({
    databaseId,
    constraints,
    schemaName,
    options = { accept: turtleMimeType },
    params,
    shouldNotDispatch = false,
  }: {
    databaseId: string;
    constraints: string;
    schemaName: string;
    options?: { accept: HTTP.RdfMimeType };
    params?: { namedGraphs?: string[] };
    shouldNotDispatch?: boolean;
  }) =>
    request({
      api: stardogDb.icv.report,
      args: [
        databaseId,
        constraints,
        options,
        { graphUri: params.namedGraphs },
      ],
      success: ModelsActionType.REQUEST_MODEL_CONSTRAINTS_REPORT_SUCCESS,
      loading: ModelsActionType.REQUEST_MODEL_CONSTRAINTS_REPORT_ATTEMPT,
      failure: ModelsActionType.REQUEST_MODEL_CONSTRAINTS_REPORT_FAILURE,
      action: {
        databaseId,
        schemaName,
        namedGraphsForReport: params ? params.namedGraphs : [],
      },
      shouldNotDispatch,
    }),
  requestModelTextData: ({
    databaseId,
    namedGraphs,
    schemaName,
    shouldNotDispatch = false,
  }: {
    databaseId: string;
    namedGraphs: string[];
    schemaName: string;
    shouldNotDispatch?: boolean;
  }) => {
    const query = `construct { ?s ?p ?o } { ?s ?p ?o } limit ${EDITOR_QUERY_LIMIT}`;
    return request({
      api: stardogQuery.execute,
      args: [
        databaseId,
        query,
        'text/turtle',
        {
          pretty: true,
          prettify: true,
          useNamespaces: true,
          'graph-uri': namedGraphs,
        },
      ],
      loading: ModelsActionType.REQUEST_MODEL_TEXT_ATTEMPT,
      success: ModelsActionType.REQUEST_MODEL_TEXT_SUCCESS,
      failure: ModelsActionType.REQUEST_MODEL_TEXT_FAILURE,
      action: { databaseId, schemaName },
      shouldNotDispatch,
    });
  },
  requestModelVisualizationData: (
    databaseId: string,
    schemaName: string,
    namedGraphs: string[],
    labelPredicates?: string[],
    namespaces?: string[]
  ) =>
    request({
      api: stardogQuery.execute,
      args: [
        databaseId,
        buildRdfsSchemaQuery(labelPredicates, namespaces),
        rdfAcceptTypes['json-ld'].acceptType,
        { 'graph-uri': namedGraphs },
      ],
      loading: ModelsActionType.REQUEST_MODEL_VISUALIZATION_DATA_ATTEMPT,
      success: ModelsActionType.REQUEST_MODEL_VISUALIZATION_DATA_SUCCESS,
      failure: ModelsActionType.REQUEST_MODEL_VISUALIZATION_DATA_FAILURE,
      action: {
        databaseId,
        schemaName,
      },
    }),
};

const modelsActionTypeMap = {
  ...modelsActionCreators,
  ...modelsStardogRequestDispatchers,
};

export type ModelsAction = ActionCreatorAction<typeof modelsActionTypeMap>;
