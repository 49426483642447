import * as VisualizationColors from 'src/common/constants/visualization/visualizationColors';
import {
  BASE_LINK_CAPTIONS,
  BASE_LINK_GROUPS,
  BASE_NODE_CAPTIONS,
  BASE_NODE_GROUPS,
  DEFAULT_LINK_CAPTION_KEY,
  DEFAULT_NODE_CAPTION_KEY,
  MULTI_GROUP_TYPE,
  NO_GROUP_TYPE,
} from 'src/common/constants/visualization/visualizationConstants';
import {
  ForceGraphGroupTypeMap,
  ForceGraphObjectType,
  VisualizationOption,
} from 'src/common/constants/visualization/visualizationTypes';
import { IMAGE_EXTENSION_TYPE } from 'src/common/utils/fileExtensions';
import { getVisualizationOptions } from 'src/common/utils/visualization/getVisualizationOptions';

export const SCHEMAS_VISUALIZATION_ID = '__SCHEMAS_VISUALIZATION_ID__';
export const MODELS_VISUALIZATION_ID = '__MODELS_VISUALIZATION_ID__';

export interface RawJsonLdData {
  '@id'?: string | RawJsonLdData;
  '@type'?: string | string[];
  '@value'?: string | boolean | number;
  [predicate: string]:
    | string
    | boolean
    | number
    | string[]
    | RawJsonLdData
    | RawJsonLdData[];
}

// stardog v7.3.4+
export interface RawJsonLdResult {
  '@context'?: { [predicate: string]: string };
  '@graph': RawJsonLdData[];
}

export const enum VisualizationSourceType {
  NOTE = 'NOTE',
  MODELS = 'MODELS',
}

export interface VisualizationSourceData {
  databaseId: string;
  noteId?: string;
  schemaName?: string;
  schemaType?: string;
  type: VisualizationSourceType;
}

export interface VisualizationGroupSettings {
  count: number;
  groupBy: string;
  options: VisualizationOption[];
  groups: ForceGraphGroupTypeMap;
  isForceGraphObject?: false;
  isGroupType?: false;
}

export const VISUALIZATION_SETTINGS_MENUS = [
  'isInfoOpen',
  'isRedrawOpen',
  'isSaveOpen',
  'isSettingsOpen',
];

export interface VisualizationHiddenSettings {
  showLiteralsAsNodes: boolean;
  showAttributes: boolean;
  hideEdgeProperties: boolean;
}

export interface VisualizationSettings extends VisualizationHiddenSettings {
  isTransforming?: boolean;
  isExporting?: boolean;
  isPerformanceAlertOpen: boolean;
  isInfoOpen: boolean;
  isRedrawOpen: boolean;
  isSaveOpen: boolean;
  isSettingsOpen: boolean;
  isDarkMode: boolean;
  isPerformanceMode: boolean;
  isForceDisabled: boolean;
  hideNodeCaptions: boolean;
  hideLinkCaptions: boolean;
  showNodeHoverLabel: boolean;
  showLinkHoverLabel: boolean;
  showLinkDirectionArrow: boolean;
  solidEdgeProperties: boolean;
  groupTypes: {
    nodes: VisualizationGroupSettings;
    links: VisualizationGroupSettings;
  };
}

export type ExportVisualizationOptions = {
  file: {
    name: string;
    ext: IMAGE_EXTENSION_TYPE;
  };
  showLinkDirectionArrow: boolean;
  isCancelled?: boolean;
};

export function baseGroupTypes(
  type: ForceGraphObjectType
): ForceGraphGroupTypeMap {
  const isNode = ForceGraphObjectType.NODE;
  const defaultCaptionKey = isNode
    ? DEFAULT_NODE_CAPTION_KEY
    : DEFAULT_LINK_CAPTION_KEY;
  const defaultCaptionMap = isNode ? BASE_NODE_CAPTIONS : BASE_LINK_CAPTIONS;
  const defaultCaptionOptions = getVisualizationOptions(defaultCaptionMap);

  return {
    [NO_GROUP_TYPE]: {
      id: NO_GROUP_TYPE,
      label: 'No Group',
      count: 0,
      colorIdx: VisualizationColors.OBJECT_COLORS_COUNT,
      captionKey: defaultCaptionKey,
      captionOptions: defaultCaptionOptions,
      type,
      isGroupType: true,
    },
    [MULTI_GROUP_TYPE]: {
      id: MULTI_GROUP_TYPE,
      label: 'Multiple Groups',
      count: 0,
      colorIdx: VisualizationColors.OBJECT_COLORS_COUNT,
      captionKey: defaultCaptionKey,
      captionOptions: defaultCaptionOptions,
      type,
      isGroupType: true,
    },
  };
}

// use a functional method for generating visualization settings
// as the settings included nested objects, and using spread
// operator will not create new nested objects
export function defaultVisualizationSettings(): VisualizationSettings {
  const defaultNodeGroupOptions = getVisualizationOptions(BASE_NODE_GROUPS);
  const defaultLinkGroupOptions = getVisualizationOptions(BASE_LINK_GROUPS);

  return {
    isPerformanceAlertOpen: false,
    isInfoOpen: false,
    isRedrawOpen: false,
    isSaveOpen: false,
    isSettingsOpen: false,
    isDarkMode: true,
    isPerformanceMode: false,
    isForceDisabled: false,
    showLiteralsAsNodes: false,
    showAttributes: false,
    hideEdgeProperties: false,
    hideNodeCaptions: false,
    hideLinkCaptions: false,
    showNodeHoverLabel: true,
    showLinkHoverLabel: true,
    showLinkDirectionArrow: true,
    solidEdgeProperties: false,
    groupTypes: {
      nodes: {
        count: 0,
        groupBy: defaultNodeGroupOptions[0].value,
        options: defaultNodeGroupOptions,
        groups: baseGroupTypes(ForceGraphObjectType.NODE),
      },
      links: {
        count: 0,
        groupBy: defaultLinkGroupOptions[0].value,
        options: defaultLinkGroupOptions,
        groups: baseGroupTypes(ForceGraphObjectType.LINK),
      },
    },
  };
}

export interface VisualizationSettingsMap {
  [visualizationSettingsId: string]: VisualizationSettings;
}

export class VisualizationsState {
  constructor(public settings: VisualizationSettingsMap = {}) {}
}
