import * as Monaco from 'monaco-editor/esm/vs/editor/editor.api';
import { virtualGraphs as stardogVirtualGraphs } from 'stardog';

import { request } from 'src/common/actions/request';
import { Response } from 'src/common/actions/request/types';
import {
  ActionCreatorAction,
  createTypedAction,
} from 'src/common/actions/types';
import {
  VgOnlyConfigUpdate,
  VgWithDsConfigUpdate,
} from 'src/common/containers/VirtualGraphs/sharedTypes';
import { VirtualGraphOptionValueMap } from 'src/common/store/virtualGraphs/options';
import {
  VirtualGraphMappingType,
  VirtualGraphSource,
} from 'src/common/store/virtualGraphs/VirtualGraphMappingType';
import {
  MutableVirtualGraphSettings,
  VirtualGraph,
  VirtualGraphsMosaicStateChange,
} from 'src/common/store/virtualGraphs/VirtualGraphsState';
import { filterVirtualGraphOptions } from 'src/common/utils/virtual_graphs/filterVirtualGraphOptions';

export const enum VirtualGraphsActionType {
  REQUEST_VIRTUAL_GRAPHS_LIST_ATTEMPT = 'REQUEST_VIRTUAL_GRAPHS_LIST_ATTEMPT',
  REQUEST_VIRTUAL_GRAPHS_LIST_SUCCESS = 'REQUEST_VIRTUAL_GRAPHS_LIST_SUCCESS',
  REQUEST_VIRTUAL_GRAPHS_LIST_FAILURE = 'REQUEST_VIRTUAL_GRAPHS_LIST_FAILURE',

  REQUEST_VIRTUAL_GRAPHS_LIST_INFO_ATTEMPT = 'REQUEST_VIRTUAL_GRAPHS_LIST_INFO_ATTEMPT',
  REQUEST_VIRTUAL_GRAPHS_LIST_INFO_SUCCESS = 'REQUEST_VIRTUAL_GRAPHS_LIST_INFO_SUCCESS',
  REQUEST_VIRTUAL_GRAPHS_LIST_INFO_FAILURE = 'REQUEST_VIRTUAL_GRAPHS_LIST_INFO_FAILURE',

  REQUEST_OPTIONS_FOR_VIRTUAL_GRAPHS_ATTEMPT = 'REQUEST_OPTIONS_FOR_VIRTUAL_GRAPHS_ATTEMPT',
  REQUEST_OPTIONS_FOR_VIRTUAL_GRAPHS_SUCCESS = 'REQUEST_OPTIONS_FOR_VIRTUAL_GRAPHS_SUCCESS',
  REQUEST_OPTIONS_FOR_VIRTUAL_GRAPHS_FAILURE = 'REQUEST_OPTIONS_FOR_VIRTUAL_GRAPHS_FAILURE',

  REQUEST_OPTIONS_FOR_VIRTUAL_GRAPH_ATTEMPT = 'REQUEST_OPTIONS_FOR_VIRTUAL_GRAPH_ATTEMPT',
  REQUEST_OPTIONS_FOR_VIRTUAL_GRAPH_SUCCESS = 'REQUEST_OPTIONS_FOR_VIRTUAL_GRAPH_SUCCESS',
  REQUEST_OPTIONS_FOR_VIRTUAL_GRAPH_FAILURE = 'REQUEST_OPTIONS_FOR_VIRTUAL_GRAPH_FAILURE',

  REQUEST_MAPPINGS_FOR_VIRTUAL_GRAPH_ATTEMPT = 'REQUEST_MAPPINGS_FOR_VIRTUAL_GRAPH_ATTEMPT',
  REQUEST_MAPPINGS_FOR_VIRTUAL_GRAPH_SUCCESS = 'REQUEST_MAPPINGS_FOR_VIRTUAL_GRAPH_SUCCESS',
  REQUEST_MAPPINGS_FOR_VIRTUAL_GRAPH_FAILURE = 'REQUEST_MAPPINGS_FOR_VIRTUAL_GRAPH_FAILURE',

  CREATE_VIRTUAL_GRAPH_ATTEMPT = 'CREATE_VIRTUAL_GRAPH_ATTEMPT',
  CREATE_VIRTUAL_GRAPH_SUCCESS = 'CREATE_VIRTUAL_GRAPH_SUCCESS',
  CREATE_VIRTUAL_GRAPH_FAILURE = 'CREATE_VIRTUAL_GRAPH_FAILURE',

  UPDATE_VIRTUAL_GRAPH_ATTEMPT = 'UPDATE_VIRTUAL_GRAPH_ATTEMPT',
  UPDATE_VIRTUAL_GRAPH_SUCCESS = 'UPDATE_VIRTUAL_GRAPH_SUCCESS',
  UPDATE_VIRTUAL_GRAPH_FAILURE = 'UPDATE_VIRTUAL_GRAPH_FAILURE',

  DELETE_VIRTUAL_GRAPH_ATTEMPT = 'DELETE_VIRTUAL_GRAPH_ATTEMPT',
  DELETE_VIRTUAL_GRAPH_SUCCESS = 'DELETE_VIRTUAL_GRAPH_SUCCESS',
  DELETE_VIRTUAL_GRAPH_FAILURE = 'DELETE_VIRTUAL_GRAPH_FAILURE',

  BRING_VIRTUAL_GRAPH_ONLINE_ATTEMPT = 'BRING_VIRTUAL_GRAPH_ONLINE_ATTEMPT',
  BRING_VIRTUAL_GRAPH_ONLINE_SUCCESS = 'BRING_VIRTUAL_GRAPH_ONLINE_SUCCESS',
  BRING_VIRTUAL_GRAPH_ONLINE_FAILURE = 'BRING_VIRTUAL_GRAPH_ONLINE_FAILURE',

  SET_SELECTED_VIRTUAL_GRAPH_ID = 'SET_SELECTED_VIRTUAL_GRAPH_ID',

  TOGGLE_VIRTUAL_GRAPH_SYNTAX_FEATURES = 'TOGGLE_VIRTUAL_GRAPH_SYNTAX_FEATURES',
  SHOW_VIRTUAL_GRAPH_DISABLED_SYNTAX_NOTIFICATION = 'SHOW_VIRTUAL_GRAPH_DISABLED_SYNTAX_NOTIFICATION',

  UPDATE_VIRTUAL_GRAPHS_MOSAIC_STATE = 'UPDATE_VIRTUAL_GRAPHS_MOSAIC_STATE',
  SET_MAPPINGS_DOCUMENT = 'SET_MAPPINGS_DOCUMENT',
  SAVE_VIEW_STATE_FOR_VG = 'SAVE_VIEW_STATE_FOR_VG',
  CREATE_UNSAVED_VIRTUAL_GRAPH = 'CREATE_UNSAVED_VIRTUAL_GRAPH',
  UPDATE_UNSAVED_VIRTUAL_GRAPH_SETTINGS = 'UPDATE_UNSAVED_VIRTUAL_GRAPH_SETTINGS',
  RESET_VIRTUAL_GRAPHS = 'RESET_VIRTUAL_GRAPHS',
  SET_LANGUAGE_ID_FOR_VG = 'SET_LANGUAGE_ID_FOR_VG',
}

export const virtualGraphsActionCreators = {
  setLanguageIdForVg: ({ vgId, languageId }) =>
    createTypedAction(VirtualGraphsActionType.SET_LANGUAGE_ID_FOR_VG, {
      vgId,
      languageId,
    }),
  updateVirtualGraphsMosaicState: (
    mosaicStateChange: VirtualGraphsMosaicStateChange
  ) =>
    createTypedAction(
      VirtualGraphsActionType.UPDATE_VIRTUAL_GRAPHS_MOSAIC_STATE,
      {
        mosaicStateChange,
      }
    ),
  setMappingsDocument: (virtualGraphName: string, document) =>
    createTypedAction(VirtualGraphsActionType.SET_MAPPINGS_DOCUMENT, {
      virtualGraphName,
      document,
    }),
  saveViewState: (
    virtualGraphUri: string,
    viewState: Monaco.editor.ICodeEditorViewState
  ) =>
    createTypedAction(VirtualGraphsActionType.SAVE_VIEW_STATE_FOR_VG, {
      virtualGraphUri,
      viewState,
    }),
  createUnsavedVirtualGraph: (
    initialSettings: (VgOnlyConfigUpdate | VgWithDsConfigUpdate) & {
      mappingDoc?: string;
    }
  ) =>
    createTypedAction(
      VirtualGraphsActionType.CREATE_UNSAVED_VIRTUAL_GRAPH,
      initialSettings
    ),
  updateUnsavedVirtualGraphSettings: (
    settings: VgOnlyConfigUpdate | VgWithDsConfigUpdate
  ) =>
    createTypedAction(
      VirtualGraphsActionType.UPDATE_UNSAVED_VIRTUAL_GRAPH_SETTINGS,
      settings
    ),
  setSelectedVirtualGraphId: (virtualGraphId: string) =>
    createTypedAction(VirtualGraphsActionType.SET_SELECTED_VIRTUAL_GRAPH_ID, {
      virtualGraphId,
    }),
  requestOptionsForVirtualGraphsSuccess: (
    responses: Array<
      Response<
        {},
        VirtualGraphsActionType.REQUEST_OPTIONS_FOR_VIRTUAL_GRAPH_SUCCESS,
        any
      >
    >
  ) =>
    createTypedAction(
      VirtualGraphsActionType.REQUEST_OPTIONS_FOR_VIRTUAL_GRAPHS_SUCCESS,
      {
        responses,
      }
    ),
  requestOptionsForVirtualGraphsFailure: () =>
    createTypedAction(
      VirtualGraphsActionType.REQUEST_OPTIONS_FOR_VIRTUAL_GRAPHS_FAILURE
    ),
  deleteVirtualGraphAttempt: (vgId: string) =>
    createTypedAction(VirtualGraphsActionType.DELETE_VIRTUAL_GRAPH_ATTEMPT, {
      vgId,
    }),
  deleteVirtualGraphFailure: (
    deletedGraph: Partial<VirtualGraph>,
    response: any
  ) =>
    createTypedAction(VirtualGraphsActionType.DELETE_VIRTUAL_GRAPH_FAILURE, {
      deletedGraph,
      response,
    }),
  deleteVirtualGraphSuccess: (vgId: string) =>
    createTypedAction(VirtualGraphsActionType.DELETE_VIRTUAL_GRAPH_SUCCESS, {
      vgId,
    }),
  resetVirtualGraphs: () =>
    createTypedAction(VirtualGraphsActionType.RESET_VIRTUAL_GRAPHS),
  showDisabledSyntaxNotification: (
    vgId: string,
    buttonAction: () => void,
    closeNotification: (id: string) => void
  ) =>
    createTypedAction(
      VirtualGraphsActionType.SHOW_VIRTUAL_GRAPH_DISABLED_SYNTAX_NOTIFICATION,
      {
        buttonAction,
        closeNotification,
        vgId,
      }
    ),
  toggleSyntaxFeatures: (vgId: string, areSyntaxFeaturesDisabled: boolean) =>
    createTypedAction(
      VirtualGraphsActionType.TOGGLE_VIRTUAL_GRAPH_SYNTAX_FEATURES,
      {
        areSyntaxFeaturesDisabled,
        vgId,
      }
    ),
};

export const virtualGraphsStardogRequestDispatchers = {
  requestVirtualGraphsList: async () =>
    request({
      api: stardogVirtualGraphs.list,
      args: [],
      loading: VirtualGraphsActionType.REQUEST_VIRTUAL_GRAPHS_LIST_ATTEMPT,
      success: VirtualGraphsActionType.REQUEST_VIRTUAL_GRAPHS_LIST_SUCCESS,
      failure: VirtualGraphsActionType.REQUEST_VIRTUAL_GRAPHS_LIST_FAILURE,
      action: {},
    }),

  requestVirtualGraphsListInfo: async (shouldNotDispatch?: boolean) =>
    request({
      api: stardogVirtualGraphs.listInfo,
      args: [],
      loading: VirtualGraphsActionType.REQUEST_VIRTUAL_GRAPHS_LIST_INFO_ATTEMPT,
      success: VirtualGraphsActionType.REQUEST_VIRTUAL_GRAPHS_LIST_INFO_SUCCESS,
      failure: VirtualGraphsActionType.REQUEST_VIRTUAL_GRAPHS_LIST_INFO_FAILURE,
      action: {},
      shouldNotDispatch,
    }),

  requestOptionsForVirtualGraph: async (
    vgName: string,
    shouldNotDispatch?: boolean
  ) =>
    request({
      api: stardogVirtualGraphs.options,
      args: [vgName],
      loading:
        VirtualGraphsActionType.REQUEST_OPTIONS_FOR_VIRTUAL_GRAPH_ATTEMPT,
      success:
        VirtualGraphsActionType.REQUEST_OPTIONS_FOR_VIRTUAL_GRAPH_SUCCESS,
      failure:
        VirtualGraphsActionType.REQUEST_OPTIONS_FOR_VIRTUAL_GRAPH_FAILURE,
      action: {
        vgName,
      },
      shouldNotDispatch,
    }),

  createVirtualGraph: async ({
    name,
    mappings,
    options,
    source,
    sourceType,
    databaseId = '*',
    dataSourceId,
    params,
  }: {
    name: string;
    mappings: string;
    options: MutableVirtualGraphSettings['properties'] & {
      generateDirectMapping?: boolean;
      'mappings.syntax'?: string;
    };
    source: VirtualGraphSource;
    sourceType: VirtualGraphMappingType;
    databaseId?: string;
    dataSourceId?: string;
    params?;
  }) => {
    const filteredOptions = filterVirtualGraphOptions(options);
    const meta: any = { db: databaseId, dataSource: dataSourceId };

    return request({
      api: stardogVirtualGraphs.add,
      args: [name, mappings, filteredOptions, meta, params],
      loading: VirtualGraphsActionType.CREATE_VIRTUAL_GRAPH_ATTEMPT,
      success: VirtualGraphsActionType.CREATE_VIRTUAL_GRAPH_SUCCESS,
      failure: VirtualGraphsActionType.CREATE_VIRTUAL_GRAPH_FAILURE,
      action: {
        name,
        mappings,
        options: filteredOptions,
        source,
        sourceType,
        databaseId,
        dataSourceId,
      },
    });
  },

  updateVirtualGraph: async ({
    name,
    mappings,
    options,
    params,
    databaseId,
    dataSourceId,
    isDataSourceSharable,
    resetLocalMapping = false,
  }: {
    name: string;
    mappings: string;
    options: VirtualGraphOptionValueMap;
    databaseId?: string;
    dataSourceId?: string;
    isDataSourceSharable?: boolean;
    params?;
    resetLocalMapping?: boolean;
  }) => {
    const filteredOptions = filterVirtualGraphOptions(options);
    const meta: any = { db: databaseId };
    // only send sharable data sources to stardog
    if (isDataSourceSharable) {
      meta.dataSource = dataSourceId;
    }

    return request({
      api: stardogVirtualGraphs.update,
      args: [name, mappings, filteredOptions, meta, params],
      loading: VirtualGraphsActionType.UPDATE_VIRTUAL_GRAPH_ATTEMPT,
      success: VirtualGraphsActionType.UPDATE_VIRTUAL_GRAPH_SUCCESS,
      failure: VirtualGraphsActionType.UPDATE_VIRTUAL_GRAPH_FAILURE,
      action: {
        name,
        mappings,
        options: filteredOptions,
        resetLocalMapping,
        databaseId,
        dataSourceId,
      },
    });
  },

  requestMappingsForVirtualGraph: async ({
    name,
    requestOptions,
    shouldNotDispatch,
  }: {
    name: string;
    requestOptions?;
    shouldNotDispatch?: boolean;
  }) =>
    request({
      api: stardogVirtualGraphs.mappings,
      args: [name, requestOptions],
      loading:
        VirtualGraphsActionType.REQUEST_MAPPINGS_FOR_VIRTUAL_GRAPH_ATTEMPT,
      success:
        VirtualGraphsActionType.REQUEST_MAPPINGS_FOR_VIRTUAL_GRAPH_SUCCESS,
      failure:
        VirtualGraphsActionType.REQUEST_MAPPINGS_FOR_VIRTUAL_GRAPH_FAILURE,
      action: {},
      shouldNotDispatch,
    }),

  bringVirtualGraphOnline: async (
    virtualGraphName: string,
    shouldNotDispatch?: boolean
  ) =>
    request({
      api: stardogVirtualGraphs.online,
      args: [virtualGraphName],
      loading: VirtualGraphsActionType.BRING_VIRTUAL_GRAPH_ONLINE_ATTEMPT,
      success: VirtualGraphsActionType.BRING_VIRTUAL_GRAPH_ONLINE_SUCCESS,
      failure: VirtualGraphsActionType.BRING_VIRTUAL_GRAPH_ONLINE_FAILURE,
      action: {
        virtualGraphName,
      },
      shouldNotDispatch,
    }),
};

const virtualGraphsActionTypeMap = {
  ...virtualGraphsActionCreators,
  ...virtualGraphsStardogRequestDispatchers,
};

export type VirtualGraphsAction = ActionCreatorAction<
  typeof virtualGraphsActionTypeMap
>;
