import { Dialog, Intent, Tab, Tabs, ToastProps } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { autoBindMethodsForReact } from 'class-autobind-decorator';
import omit from 'lodash.omit';
import * as React from 'react';
import { connect } from 'react-redux';

import { editSavedConnection } from 'src/common/actions/connection/action-creators/editSavedConnection';
import { setConnection } from 'src/common/actions/connection/action-creators/stardog-api/setConnection';
import {
  ConnectionActionType,
  connectionActionCreators,
} from 'src/common/actions/connection/connectionActionCreators';
import { notificationsActionCreators } from 'src/common/actions/notifications/notificationsActionCreators';
import { StudioThunkDispatch } from 'src/common/actions/StudioAction';
import { TEST_IDS } from 'src/common/constants/testIds';
import { EditConnectionForm } from 'src/common/containers/Login/EditConnectionForm';
import { NewConnectionForm } from 'src/common/containers/Login/NewConnectionForm';
import { SavedConnectionsManager } from 'src/common/containers/Login/SavedConnectionsManager';
import {
  Connection,
  DrawerStatus,
  SavedConnection,
} from 'src/common/store/connection/ConnectionState';
import { ReduxState } from 'src/types';

const enum LoginTabIds {
  SAVED = 'connection-list-saved',
  EDITING = 'connection-form-editing',
  NEW = 'connection-form-new',
}

const getDefaultTabId = (savedConnectionNames: string[]) =>
  savedConnectionNames.length ? LoginTabIds.SAVED : LoginTabIds.NEW;

type StandAloneLoginDialogState = {
  tab: LoginTabIds;
  editingName: string;
};

function mapStateToProps({ connection }: ReduxState) {
  const { current, drawer, saved, savedNames } = connection;
  return {
    connection: current,
    isOpen: drawer.status === DrawerStatus.OPEN,
    savedConnections: saved,
    savedConnectionNames: savedNames,
    selectedConnectionName: drawer.selectedConnectionName,
  };
}

function mapDispatchToProps(dispatch: StudioThunkDispatch) {
  return {
    setConnection: (connection: Connection, shouldSaveConnection?: boolean) =>
      dispatch(setConnection(connection, shouldSaveConnection)),
    editSavedConnection: (name: string, connection: SavedConnection) =>
      dispatch(editSavedConnection(name, connection)),
    deleteSavedConnection: (name: string) =>
      dispatch(connectionActionCreators.deleteSavedConnection(name)),
    queueNotification: (notification: ToastProps) =>
      dispatch(notificationsActionCreators.queueNotification(notification)),
    setSelectedConnectionName: (connectionName: string) =>
      dispatch(
        connectionActionCreators.setSelectedConnectionName(connectionName)
      ),
    toggleAppDrawer: () => dispatch(connectionActionCreators.toggleAppDrawer()),
  };
}

type StandAloneLoginDialogStateProps = ReturnType<typeof mapStateToProps>;
type StandAloneLoginDialogDispatchProps = ReturnType<typeof mapDispatchToProps>;
type StandAloneLoginDialogCombinedProps = StandAloneLoginDialogStateProps &
  StandAloneLoginDialogDispatchProps;

@autoBindMethodsForReact
class UnconnectedStandAloneLoginDialog extends React.Component<
  StandAloneLoginDialogCombinedProps,
  StandAloneLoginDialogState
> {
  constructor(props) {
    super(props);
    this.state = {
      editingName: '',
      tab: getDefaultTabId(props.savedConnectionNames),
    };
  }

  handleClose() {
    const { savedConnectionNames, toggleAppDrawer } = this.props;
    toggleAppDrawer();
    this.handleTabChange(getDefaultTabId(savedConnectionNames));
  }

  handleTabChange(tab: LoginTabIds) {
    // The editing tab isn't a normal tab in that it only appears when the
    // user has already selected a connection to edit, so clicking on it
    // should not change the currrent tab or reset the editingName since it
    // will only be available when the current tab is already the editing tab
    if (tab === LoginTabIds.EDITING) {
      return;
    }
    this.setState({ tab, editingName: '' });
  }

  handleSetEditingName(editingName: string) {
    this.setState({ tab: LoginTabIds.EDITING, editingName });
  }

  handleSetNewConnection(
    connection: Connection,
    shouldSaveConnection: boolean
  ) {
    const { queueNotification, savedConnections, setConnection } = this.props;
    if (shouldSaveConnection && savedConnections[connection.name]) {
      queueNotification({
        message: `Cannot add "${connection.name}" to My Connections: a connection with that name already exists.`,
        intent: Intent.DANGER,
      });
    } else {
      setConnection(omit(connection, 'token'), shouldSaveConnection);
    }
  }

  handleSignIn(connection: Connection) {
    const { setConnection } = this.props;
    setConnection(omit(connection, 'token'));
  }

  async handleEditSavedConnection(connection: SavedConnection) {
    const { editSavedConnection, savedConnectionNames, setConnection } =
      this.props;
    const { editingName } = this.state;
    const didEditSucceed = editSavedConnection(editingName, connection);
    if (didEditSucceed) {
      const credentialsValidationResponse = await setConnection(
        omit(connection, 'token')
      );
      if (
        credentialsValidationResponse.type ===
        ConnectionActionType.VALIDATE_CREDENTIALS_SUCCESS
      ) {
        this.handleTabChange(getDefaultTabId(savedConnectionNames));
      }
    }
  }

  render() {
    const {
      connection,
      deleteSavedConnection,
      isOpen,
      savedConnections,
      savedConnectionNames,
      selectedConnectionName,
      setSelectedConnectionName,
    } = this.props;
    const { editingName, tab } = this.state;

    // TODO: Editing could be moved into the SavedConnectionsManager, unless we
    // really do want to keep it as an ephemeral tab.
    const editingTab = editingName ? (
      <Tab
        id={LoginTabIds.EDITING}
        panel={
          <EditConnectionForm
            connection={savedConnections[editingName]}
            handleCancel={() => this.handleTabChange(LoginTabIds.SAVED)}
            handleEditSavedConnection={this.handleEditSavedConnection}
          />
        }
        title="Edit Connection"
      />
    ) : null;

    return (
      <Dialog
        className="sd-dialog-connect"
        icon={IconNames.OFFLINE}
        isOpen={isOpen}
        onClose={this.handleClose}
        title="Connect to Stardog"
      >
        <div
          className="sd-dialog-login"
          data-testid={TEST_IDS.standalone.loginDialog}
        >
          <Tabs
            animate={false}
            onChange={this.handleTabChange}
            selectedTabId={tab}
          >
            <Tab
              id={LoginTabIds.SAVED}
              panel={
                <SavedConnectionsManager
                  connectionNames={savedConnectionNames}
                  connections={savedConnections}
                  currentConnection={connection}
                  handleDeleteConnection={deleteSavedConnection}
                  handleSetConnection={this.handleSignIn}
                  handleSetEditingConnection={this.handleSetEditingName}
                  handleSetSelectedConnectionName={setSelectedConnectionName}
                  selectedConnectionName={selectedConnectionName}
                />
              }
              title="My Connections"
            />
            <Tab
              id={LoginTabIds.NEW}
              panel={
                <NewConnectionForm
                  // 'New Connections' should never populate with the saved name of the current
                  // connection in order to avoid overriding the details of saved connections
                  connection={{
                    ...connection,
                    name: '',
                  }}
                  handleSetNewConnection={this.handleSetNewConnection}
                />
              }
              title="New Connection"
            />
            {editingTab}
          </Tabs>
        </div>
      </Dialog>
    );
  }
}

export const StandAloneLoginDialog = connect<
  StandAloneLoginDialogStateProps,
  StandAloneLoginDialogDispatchProps
>(
  mapStateToProps,
  mapDispatchToProps
)(UnconnectedStandAloneLoginDialog);
