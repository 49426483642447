import {
  ConnectionAction,
  ConnectionActionType,
} from 'src/common/actions/connection/connectionActionCreators';
import {
  DatabasesAction,
  DatabasesActionType,
} from 'src/common/actions/databases/databasesActionCreators';
import { isBody } from 'src/common/actions/request/types';
import {
  StarchartAction,
  StarchartActionType,
} from 'src/common/actions/starchart/starchartActionCreators';
import { ServerState } from 'src/common/store/security/ServerState';
import {
  StarchartState,
  defaultStarchartSourcesData,
  defaultStartChartSorting,
} from 'src/common/store/starchart/StarchartState';
import { safelyGet } from 'src/common/utils/safelyGet';

const initialState = new StarchartState();

const starchartReducer = (
  prevState = initialState,
  action: StarchartAction | ConnectionAction | DatabasesAction
): StarchartState => {
  switch (action.type) {
    case ConnectionActionType.SET_CONNECTION_SUCCESS: {
      return initialState;
    }
    case StarchartActionType.SET_STARCHART_VIEW: {
      return {
        ...prevState,
        currentView: action.payload.view,
      };
    }
    case StarchartActionType.SET_STARCHART_DATABASE_ID: {
      const selectedDatabaseId = action.payload.databaseId;
      const { sortingByDatabase } = prevState;
      if (sortingByDatabase.hasOwnProperty(selectedDatabaseId)) {
        return {
          ...prevState,
          selectedDatabaseId,
          selectedClassIris: new Set(),
          selectedSourceIris: new Set(),
        };
      }

      return {
        ...prevState,
        selectedDatabaseId,
        sortingByDatabase: {
          ...sortingByDatabase,
          [selectedDatabaseId]: defaultStartChartSorting(),
        },
      };
    }
    case StarchartActionType.SET_STARCHART_DATABASE_PAGE: {
      const { page } = action.payload;
      const { sortingByDatabase, selectedDatabaseId } = prevState;

      return {
        ...prevState,
        sortingByDatabase: {
          ...sortingByDatabase,
          [selectedDatabaseId]: {
            ...sortingByDatabase[selectedDatabaseId],
            page,
          },
        },
      };
    }
    case StarchartActionType.SET_STARCHART_DATABASE_SORT_BY: {
      const { sortBy } = action.payload;
      const { sortingByDatabase, selectedDatabaseId } = prevState;

      return {
        ...prevState,
        sortingByDatabase: {
          ...sortingByDatabase,
          [selectedDatabaseId]: {
            ...sortingByDatabase[selectedDatabaseId],
            sortBy,
            page: 0,
          },
        },
      };
    }
    case StarchartActionType.SET_STARCHART_SELECTED_CLASSES: {
      const { selectedClassIris } = action.payload;

      return {
        ...prevState,
        selectedClassIris,
      };
    }
    case StarchartActionType.SET_STARCHART_SELECTED_SOURCES: {
      const { selectedSourceIris } = action.payload;

      return {
        ...prevState,
        selectedSourceIris,
      };
    }
    case StarchartActionType.REQUEST_STARCHART_SOURCES_DATA_ATTEMPT: {
      if (action.payload.databaseId !== prevState.selectedDatabaseId) {
        return prevState;
      }
      return {
        ...prevState,
        isPendingSourcesData: true,
      };
    }
    case StarchartActionType.REQUEST_STARCHART_SOURCES_DATA_SUCCESS: {
      if (action.payload.databaseId !== prevState.selectedDatabaseId) {
        return prevState;
      }
      return {
        ...prevState,
        sourcesData: action.payload.data,
        isPendingSourcesData: false,
        selectedClassIris: new Set(),
        selectedSourceIris: new Set(),
      };
    }
    case StarchartActionType.REQUEST_STARCHART_SOURCES_DATA_FAILURE: {
      if (action.payload.databaseId !== prevState.selectedDatabaseId) {
        return prevState;
      }
      return {
        ...prevState,
        sourcesData: defaultStarchartSourcesData(),
        isPendingSourcesData: false,
      };
    }
    case DatabasesActionType.DROP_DATABASE_SUCCESS: {
      const id = action.payload.args[0];
      if (id !== prevState.selectedDatabaseId) {
        return prevState;
      }

      return {
        ...prevState,
        selectedDatabaseId: '',
        selectedClassIris: new Set(),
        selectedSourceIris: new Set(),
      };
    }
    case DatabasesActionType.GET_STATUS_ATTEMPT: {
      return {
        ...prevState,
        isPendingStats: true,
      };
    }
    case DatabasesActionType.GET_STATUS_FAILURE: {
      return {
        ...prevState,
        stats: null,
        isPendingStats: false,
      };
    }
    case DatabasesActionType.GET_STATUS_SUCCESS: {
      if (!isBody(action.payload?.response)) {
        // type narrowing
        return prevState;
      }

      const serverState: ServerState = action.payload?.response?.body;
      const databaseId = prevState.selectedDatabaseId;
      return {
        ...prevState,
        stats: {
          avgQueryLatency: Number(
            safelyGet(
              serverState,
              [`databases.${databaseId}.queries.latency`, 'mean'],
              0
            )
          ),
          avgTransactionLatency: Number(
            safelyGet(
              serverState,
              [`databases.${databaseId}.txns.latency`, 'mean'],
              0
            )
          ),
          queryLatencyUnits: safelyGet(
            serverState,
            [`databases.${databaseId}.queries.latency`, 'duration_units'],
            ''
          ),
          transactionLatencyUnits: safelyGet(
            serverState,
            [`databases.${databaseId}.txns.latency`, 'duration_units'],
            ''
          ),
          size: Number(
            safelyGet(serverState, [`databases.${databaseId}.size`, 'value'], 0)
          ),
        },
        isPendingStats: false,
      };
    }
    default:
      return prevState;
  }
};

export { starchartReducer };
