import pick from 'lodash.pick';
import { Store } from 'redux';

import { PRUNED_STORE_KEYS } from 'src/common/constants/store';
import { isDevelopment } from 'src/common/utils/isDevelopment';
import { pruneSavedConnectionPasswords } from 'src/common/utils/store/pruneSavedConnectionPasswords';
import { resetCurrentConnectionState } from 'src/common/utils/store/resetCurrentConnectionState';
import { resetNoteResults } from 'src/common/utils/store/resetNoteResults';
import { validateActiveNoteId } from 'src/common/utils/store/validateActiveNoteId';
import { ReduxState } from 'src/types';

const pickStoredStoreParts = (state: ReduxState) =>
  pick(state, ...PRUNED_STORE_KEYS);

export const getPruned = (store: Store<ReduxState>) => {
  const pruningOperations = [resetNoteResults, resetCurrentConnectionState];
  pruningOperations.push(pruneSavedConnectionPasswords);
  if (isDevelopment) {
    pruningOperations.push(validateActiveNoteId);
  }
  pruningOperations.push(pickStoredStoreParts as any);

  const pruned = pruningOperations.reduce(
    (state, operation) => operation(state),
    store.getState()
  );
  return pruned;
};
