import {
  Classes,
  H5,
  Popover,
  PopoverInteractionKind,
} from '@blueprintjs/core';
import { autoBindMethodsForReact } from 'class-autobind-decorator';
import * as React from 'react';

import { ConfirmationDialogContent } from 'src/browser/components/ConfirmationDialogContent';
import { WithConfirmationProps } from 'src/common/components/ContextualStudioApi';

// Popover wrapper for declaratively guarding elements with confirmation.
@autoBindMethodsForReact
export class BrowserWithConfirmation extends React.Component<
  WithConfirmationProps,
  { isOpen: boolean }
> {
  static defaultProps = {
    noPrompt: 'No',
    yesPrompt: 'Yes',
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  handleConfirmationFailure() {
    this.setState({ isOpen: false });
    const { onConfirmationFailure } = this.props;
    onConfirmationFailure?.();
  }

  handleConfirmationSuccess() {
    this.setState({ isOpen: false });
    const { onConfirmationSuccess } = this.props;
    onConfirmationSuccess();
  }

  getTitle() {
    const { title } = this.props;
    return title ? <H5>{title}</H5> : null;
  }

  render() {
    const { isOpen } = this.state;
    const { message, noPrompt, yesPrompt, target } = this.props;
    return (
      <Popover
        aria-describedby="confirmation-description"
        aria-labelledby="confirmation-title"
        content={
          <ConfirmationDialogContent
            message={message}
            noPrompt={noPrompt}
            onConfirmationFailure={this.handleConfirmationFailure}
            onConfirmationSuccess={this.handleConfirmationSuccess}
            title={this.getTitle()}
            yesPrompt={yesPrompt}
          />
        }
        interactionKind={PopoverInteractionKind.CLICK}
        isOpen={isOpen}
        onClose={this.handleConfirmationFailure}
        onInteraction={(isOpen) => this.setState({ isOpen })}
        popoverClassName={Classes.POPOVER_CONTENT_SIZING}
        position="auto"
        target={target || <div />}
      />
    );
  }
}
