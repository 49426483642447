import * as React from 'react';

import {
  ConnectionForm,
  ConnectionType,
} from 'src/common/containers/Login/ConnectionForm';
import { Connection } from 'src/common/store/connection/ConnectionState';

type EditConnectionFormProps = {
  connection: Connection;
  handleEditSavedConnection: (connection: Connection) => any;
  handleCancel: () => any;
};

export const EditConnectionForm = ({
  connection,
  handleCancel,
  handleEditSavedConnection,
}: EditConnectionFormProps) => (
  <ConnectionForm
    connection={connection}
    connectionType={ConnectionType.EXISTING}
    onCancel={handleCancel}
    onSubmit={(connectionFormState) =>
      handleEditSavedConnection(connectionFormState.connection)
    }
  />
);
