import * as path from 'path';
import uuid from 'uuid/v4';

import { safelyGet } from 'src/common/utils/safelyGet';
import { InitialData } from 'src/types';

const defaultEditorSettings = {
  activeDatabase: '',
  languageId: 'sparql',
  reasoning: false,
  query: '',
  latestSavedVersionId: 1, // monaco's version ids are 1-indexed
  isSaved: true,
  isUntitled: false,
};

const defaultOldNotebookState = {
  notes: {
    default: {
      editorSettings: {},
      resultSettings: {
        sortColumn: '',
        sortOrder: 'desc',
        viewType: 'textual',
        page: 0,
        rowsPerPage: 5,
        isCollapsed: true,
        pending: false,
      },
      pane: 0,
    },
  },
};

export default (data: InitialData) => {
  const preferences = safelyGet(data, ['preferences'], {});
  const oldNotebook = safelyGet(data, ['notebook'], defaultOldNotebookState);

  const notes = Object.keys(oldNotebook.notes).reduce((acc, id, idx) => {
    const tabId = path.join(uuid(), `Untitled-${idx + 1}`);
    return {
      ...acc,
      [tabId]: {
        ...oldNotebook.notes[id],
        id: tabId,
        editorSettings: {
          ...defaultEditorSettings,
          ...oldNotebook.notes[id].editorSettings,
          isUntitled: true,
        },
      },
    };
  }, {});
  const noteIds = Object.keys(notes);
  const activeNoteId = noteIds.length ? noteIds[0] : undefined;
  const panes = {
    '0': {
      noteIds,
    },
  };
  const paneIds = ['0'];
  const mosaicState = {
    TOP: {
      direction: 'row',
      first: 'SIDEBAR',
      second: 'PANES',
      splitPercentage: 20,
    },
    SIDEBAR: {
      direction: 'column',
      first: 'EXPLORER',
      second: 'STORED_AND_HISTORY',
      splitPercentage: 0,
    },
  };

  const newNotebook = {
    ...oldNotebook,
    noteIds,
    notes,
    activeNoteId,
    paneIds,
    panes,
    mosaicState,
  };

  return {
    ...data,
    version: '1.0.0',
    preferences,
    notebook: newNotebook,
  };
};
