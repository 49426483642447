import { ConnectionState } from 'src/common/store/connection/ConnectionState';
import { dedupeArray } from 'src/common/utils/dedupeArray';
import { safelyGet } from 'src/common/utils/safelyGet';
import { InitialData } from 'src/types';

export default (data: InitialData) => {
  const oldConnection: Partial<ConnectionState> = safelyGet(
    data,
    ['connection'],
    {}
  );

  const dedupedSavedNames = dedupeArray(oldConnection.savedNames);

  return {
    ...data,
    version: '1.25.0',
    connection: {
      ...oldConnection,
      savedNames: dedupedSavedNames,
    },
  };
};
