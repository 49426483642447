export function getMinimum<T>(
  items: T[],
  picker: (item: T) => string | number = (item) => <any>item
) {
  if (!items.length) {
    return;
  }

  let min = items[0];

  items.forEach((item) => {
    if (picker(item) < picker(min)) {
      min = item;
    }
  });

  return min;
}
