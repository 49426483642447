import {
  NotebookState,
  NotebookStoredAndHistoryTab,
} from 'src/common/store/notebook/NotebookState';
import { safelyGet } from 'src/common/utils/safelyGet';
import { InitialData } from 'src/types';

export default (data: InitialData) => {
  const oldNotebook: Partial<NotebookState> = safelyGet(data, ['notebook'], {});
  const storedAndHistoryTab =
    oldNotebook.storedAndHistoryTab || NotebookStoredAndHistoryTab.STORED;

  return {
    ...data,
    version: '1.16.0',
    notebook: {
      ...oldNotebook,
      storedAndHistoryTab,
    },
  };
};
