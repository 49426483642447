import {
  Classes,
  Divider,
  IconProps,
  Position,
  Tab,
  Tabs,
  TooltipProps,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { autoBindMethodsForReact } from 'class-autobind-decorator';
import classNames from 'classnames';
import { History, Location, UnregisterCallback } from 'history';
import * as React from 'react';
import { connect } from 'react-redux';

import { withLeaveDataConfirmation } from 'src/common/actions/data/withLeaveDataConfirmation';
import { withLeaveDatabasesConfirmation } from 'src/common/actions/databases/withLeaveDatabasesConfirmation';
import { withLeaveModelsConfirmation } from 'src/common/actions/models/withLeaveModelsConfirmation';
import { ROUTES } from 'src/common/constants/Routes';
import { IconWithTooltip } from 'src/common/containers/App/components/IconWithTooltip';
import { ConnectionButton } from 'src/common/containers/AppNavbar/ConnectionButton';
import { LoginDialog } from 'src/common/containers/Login/LoginDialog';
import { isConnectionConnected } from 'src/common/utils/isConnectionConnected';
import { ReduxState } from 'src/types';

export const navBarIconProps: Partial<IconProps> = {
  iconSize: 20,
};

export const navBarTooltipProps: Partial<TooltipProps> = {
  position: Position.RIGHT,
};

export enum TabId {
  editor = 'editor',
  data = 'data',
  starchart = 'starchart',
  models = 'models',
  virtualGraphs = 'virtual-graphs',
  databases = 'databases',
  security = 'security',
}

const navigationMap: {
  idToRoute: { [key in TabId]: ROUTES };
  routeToId: Array<{ regex: RegExp; key: TabId }>;
} = {
  idToRoute: {
    [TabId.data]: ROUTES.DATA,
    [TabId.editor]: ROUTES.NOTEBOOK,
    [TabId.starchart]: ROUTES.STARCHART,
    [TabId.models]: ROUTES.MODELS,
    [TabId.virtualGraphs]: ROUTES.VIRTUAL_GRAPHS,
    [TabId.databases]: ROUTES.DATABASES,
    [TabId.security]: ROUTES.SECURITY,
  },
  routeToId: [
    { regex: /^\/$/, key: TabId.editor },
    { regex: /^\/data\/?$/, key: TabId.data },
    { regex: /^\/provenance/, key: TabId.starchart },
    { regex: /^\/models\/?$/, key: TabId.models },
    { regex: /^\/virtual-graphs/, key: TabId.virtualGraphs },
    { regex: /^\/databases/, key: TabId.databases },
    { regex: /^\/security/, key: TabId.security },
  ],
};

const getTabIdFromLocation = (location: Location) => {
  return (
    navigationMap.routeToId.find(({ regex }) => regex.test(location.pathname))
      .key || TabId.editor
  );
};

const mapStateToProps = ({ connection, features }: ReduxState) => {
  const { current: currentConnection } = connection;
  const { isSms2Supported, isStarchartSupported } = features.stardog;

  return {
    features,
    isConnected: isConnectionConnected(currentConnection),
    stardogSupportsSms: isSms2Supported,
    stardogSupportsStarchart: isStarchartSupported,
  };
};

const mapDispatchToProps = (dispatch) => ({
  confirmationHandlers: {
    [TabId.data]: (onConfirmationSuccess: () => any) =>
      dispatch(withLeaveDataConfirmation({ onConfirmationSuccess })),
    [TabId.databases]: (onConfirmationSuccess: () => any) =>
      dispatch(withLeaveDatabasesConfirmation({ onConfirmationSuccess })),
    [TabId.models]: (onConfirmationSuccess: () => any) =>
      dispatch(withLeaveModelsConfirmation({ onConfirmationSuccess })),
  },
});

type NavBarStateProps = ReturnType<typeof mapStateToProps>;
type NavBarDispatchProps = ReturnType<typeof mapDispatchToProps>;

interface NavBarOwnProps {
  className?: string;
  history: History;
  onHistoryChange?(location: Location): any;
}

@autoBindMethodsForReact
export class UnconnectedNavBar extends React.Component<
  NavBarOwnProps & NavBarStateProps & NavBarDispatchProps,
  { selectedTabId: keyof typeof navigationMap.idToRoute }
> {
  private unlistenToHistory: UnregisterCallback;

  constructor(props) {
    super(props);
    this.state = {
      selectedTabId: getTabIdFromLocation(props.history.location),
    };
  }

  componentDidMount() {
    const { history, onHistoryChange } = this.props;
    this.unlistenToHistory = history.listen((location: Location) => {
      this.setState({
        selectedTabId: getTabIdFromLocation(location),
      });
      if (onHistoryChange) {
        onHistoryChange(location);
      }
    });
  }

  componentWillUnmount() {
    this.unlistenToHistory();
  }

  navigate(newTabId: TabId, prevTab: TabId) {
    const { confirmationHandlers, history } = this.props;
    const routerPath =
      (navigationMap.idToRoute[newTabId] as string) || `/${newTabId}`;
    const leaveConfirmationHandler = confirmationHandlers[prevTab];

    if (routerPath === history.location.pathname) {
      history.replace(routerPath);
    } else if (leaveConfirmationHandler) {
      leaveConfirmationHandler(() => history.push(routerPath));
    } else {
      history.push(routerPath);
    }
  }

  render() {
    const {
      className,
      features,
      isConnected,
      stardogSupportsSms,
      stardogSupportsStarchart,
    } = this.props;
    const { selectedTabId } = this.state;

    const workspaceTab = (
      <Tab id={TabId.editor}>
        <IconWithTooltip
          icon={IconNames.DOCUMENT}
          iconProps={navBarIconProps}
          tooltipContent="Workspace"
          tooltipProps={navBarTooltipProps}
        />
      </Tab>
    );

    const dataTab = features.stardog.isDataSourcesSupported ? (
      <Tab disabled={!isConnected} id={TabId.data}>
        <IconWithTooltip
          icon={IconNames.DATA_LINEAGE}
          iconProps={navBarIconProps}
          tooltipContent="Data"
          tooltipProps={navBarTooltipProps}
        />
      </Tab>
    ) : null;

    const starchartTab = (
      <Tab
        disabled={!isConnected || !stardogSupportsStarchart}
        id={TabId.starchart}
      >
        <IconWithTooltip
          icon={IconNames.DASHBOARD}
          iconProps={navBarIconProps}
          tooltipContent={`Provenance${
            !isConnected || stardogSupportsStarchart
              ? ''
              : ' - Requires Stardog 7.3+'
          }`}
          tooltipProps={navBarTooltipProps}
        />
      </Tab>
    );

    const modelsTab = (
      <Tab disabled={!isConnected} id={TabId.models}>
        <IconWithTooltip
          icon={IconNames.DIAGRAM_TREE}
          iconProps={navBarIconProps}
          tooltipContent="Models"
          tooltipProps={navBarTooltipProps}
        />
      </Tab>
    );

    const virtualGraphsTab = (
      <Tab
        disabled={!isConnected || !stardogSupportsSms}
        id={TabId.virtualGraphs}
      >
        <IconWithTooltip
          icon={IconNames.JOIN_TABLE}
          iconProps={navBarIconProps}
          tooltipContent={`Virtual Graphs${
            !isConnected || stardogSupportsSms ? '' : ' - Requires Stardog 6+'
          }`}
          tooltipProps={navBarTooltipProps}
        />
      </Tab>
    );

    const databasesTab = (
      <Tab disabled={!isConnected} id={TabId.databases}>
        <IconWithTooltip
          icon={IconNames.DATABASE}
          iconProps={navBarIconProps}
          tooltipContent="Databases"
          tooltipProps={navBarTooltipProps}
        />
      </Tab>
    );

    const securityTab = (
      <Tab disabled={!isConnected} id={TabId.security}>
        <IconWithTooltip
          icon={IconNames.LOCK}
          iconProps={navBarIconProps}
          tooltipContent="Security"
          tooltipProps={navBarTooltipProps}
        />
      </Tab>
    );

    return (
      <nav className={classNames(Classes.NAVBAR, 'sd-main-nav', className)}>
        <Tabs
          id="navbar"
          onChange={this.navigate}
          selectedTabId={selectedTabId}
          vertical
        >
          <ConnectionButton />
          <Divider className="sd-main-nav-divider" />
          {starchartTab}
          {workspaceTab}
          <Divider className="sd-main-nav-divider" />
          {modelsTab}
          {virtualGraphsTab}
          <Divider className="sd-main-nav-divider" />
          {dataTab}
          {databasesTab}
          {securityTab}
        </Tabs>
        <LoginDialog />
      </nav>
    );
  }
}

export const NavBar = connect<
  NavBarStateProps,
  NavBarDispatchProps,
  NavBarOwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(UnconnectedNavBar);
