import { AnchorButton, Intent } from '@blueprintjs/core';
import classNames from 'classnames';
import * as React from 'react';

import { TEST_IDS, getNotificationTestId } from 'src/common/constants/testIds';
import * as copy from 'src/common/templates/copy';
import { isClipboardEnabled } from 'src/common/utils/isClipboardEnabled';

type ErrorNotificationMessageProps = {
  notificationId: string;
  message: string;
};

export const ErrorNotificationMessage: React.VFC<ErrorNotificationMessageProps> =
  ({ notificationId, message }) => {
    const [isClicked, setIsClicked] = React.useState(false);
    const testId = getNotificationTestId(notificationId);
    return (
      <>
        <div className="sd-toast-actionable-message" data-testid={testId}>
          <span className={classNames('sd-toast-error-message', 'selectable')}>
            {message}
          </span>
        </div>
        {isClipboardEnabled() ? (
          <div className="sd-toast-actionable-actions">
            <AnchorButton
              data-testid={TEST_IDS.notification.errorCopyButton}
              intent={Intent.DANGER}
              minimal
              onClick={() => {
                setIsClicked(true);
                navigator.clipboard.writeText(message);
              }}
              text={
                !isClicked
                  ? copy.components.notifications.error.copy
                  : copy.components.notifications.error.copied
              }
            />
          </div>
        ) : null}
      </>
    );
  };
