import { Classes } from '@blueprintjs/core';

import { colors } from 'src/common/constants/colors';
import { luminance, opacity } from 'src/common/utils/colors';

const { blueprintjs } = colors;

export const enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
}

type SharedThemeSettings = {
  // constant backgrounds (visualizastion, visual query plan)
  '--background-dark': string;
  '--background-light': string;

  // constant overlays (create database dialog)
  '--background-overlay-dark': string;

  // intents (status bar, tutorial glow, stop button)
  '--intent-primary': string;
  '--intent-primary-active': string;
  '--intent-success': string;
  '--intent-danger': string;

  '--text-dark': string;
  '--text-light': string;
  '--text-inactive': string;
  '--text-semi-active': string;
  '--text-active': string;

  '--highlight-primary-1': string;
  '--highlight-primary-2': string;
  '--highlight-primary-3': string;
  '--highlight-primary-4': string;
};

type ThemeSettings = SharedThemeSettings & {
  // main app backgrounds (empty containers, editor)
  '--background-main': string;

  // overlays (connection bar, stats drawer)
  '--background-overlay': string;
  '--background-overlay-drawer': string;

  // scrollbar
  '--background-scrollbar-thumb': string;
  '--background-scrollbar-track': string;
  '--background-scrollbar-corner': string;

  // mosaic
  '--background-mosaic-row': string;
  '--background-mosaic-column': string;

  // manager headers (databases, security, models)
  '--background-header': string;
  // results bar and sub-header bar
  '--background-header-sub': string;

  // main left navbar and sidebar header
  '--background-navbar': string;

  // active tabs (sidebar, tab control bar)
  '--background-tab-active': string;
  // inactive tabs (secondary control bar)
  '--background-tab-inactive': string;

  // sections (cards, modals)
  '--background-section': string;
  '--background-section-body': string;
  // sub-sections and inputs (security user items, create database properties, virtual graph inputs, table stripe)
  '--background-section-sub': string;
  // super-sections and inputs (models selected labels)
  '--background-section-super': string;

  '--background-end-service-banner': string;

  // contrasts with the section body and active tab color (filter selectors, advanced options)
  '--button-section': string;

  // button that contrasts with header
  '--button-header': string;
  '--button-header-hover': string;

  // button that contrasts with the inactive tab color (new tab, left and right)
  '--button-tab': string;
  '--button-tab-hover': string;

  '--icon': string;

  '--text-main': string;
  '--text-overlay': string;
  '--text-tooltip': string;

  '--highlight-primary-5': string;
  '--highlight-primary-6': string;
};

const sharedThemeSettings: SharedThemeSettings = {
  '--background-dark': colors.black,
  '--background-light': colors.white,
  '--background-overlay-dark': opacity(blueprintjs.darkGray2, 0.75),
  '--intent-primary': blueprintjs.blue2,
  '--intent-primary-active': blueprintjs.blue1,
  '--intent-success': blueprintjs.green5,
  '--intent-danger': blueprintjs.red5,
  '--text-dark': colors.black,
  '--text-light': blueprintjs.lightGray5,
  '--text-inactive': blueprintjs.gray3,
  '--text-semi-active': blueprintjs.blue1,
  '--text-active': blueprintjs.blue5,
  '--highlight-primary-1': blueprintjs.darkGray1,
  '--highlight-primary-2': blueprintjs.darkGray2,
  '--highlight-primary-3': colors.black,
  '--highlight-primary-4': opacity(blueprintjs.black, 0.15),
};

const DarkThemeSettings: ThemeSettings = {
  ...sharedThemeSettings,
  '--background-main': colors.black,
  '--background-overlay': opacity(blueprintjs.darkGray2, 0.75),
  '--background-overlay-drawer': opacity(blueprintjs.black, 0.9),
  '--background-scrollbar-thumb': blueprintjs.darkGray5,
  '--background-scrollbar-track': opacity(blueprintjs.black, 0.7),
  '--background-scrollbar-corner': blueprintjs.darkGray3,
  '--background-mosaic-row': blueprintjs.darkGray3,
  '--background-mosaic-column': blueprintjs.gray3,
  '--background-header': blueprintjs.darkGray5,
  '--background-header-sub': blueprintjs.darkGray3,
  '--background-navbar': blueprintjs.darkGray4,
  '--background-tab-active': blueprintjs.darkGray1,
  '--background-tab-inactive': blueprintjs.darkGray3,
  '--background-section': blueprintjs.darkGray4,
  '--background-section-body': blueprintjs.darkGray3,
  '--background-section-sub': blueprintjs.darkGray2,
  '--background-section-super': blueprintjs.darkGray5,
  '--background-end-service-banner': blueprintjs.blue2,
  '--button-section': blueprintjs.darkGray2,
  '--button-header': blueprintjs.darkGray3,
  '--button-header-hover': blueprintjs.darkGray2,
  '--button-tab': blueprintjs.darkGray4,
  '--button-tab-hover': blueprintjs.darkGray5,
  '--icon': blueprintjs.gray5,
  '--text-main': blueprintjs.lightGray5,
  '--text-overlay': blueprintjs.gray3,
  '--text-tooltip': blueprintjs.darkGray5,
  '--highlight-primary-5': blueprintjs.black,
  '--highlight-primary-6': blueprintjs.black,
};

const LightThemeSettings: ThemeSettings = {
  ...sharedThemeSettings,
  '--background-main': colors.white,
  '--background-overlay': opacity(blueprintjs.lightGray2, 0.75),
  '--background-overlay-drawer': opacity(colors.white, 0.9),
  '--background-scrollbar-thumb': blueprintjs.gray5,
  '--background-scrollbar-track': blueprintjs.lightGray4,
  '--background-scrollbar-corner': blueprintjs.lightGray4,
  '--background-mosaic-row': blueprintjs.lightGray4,
  '--background-mosaic-column': blueprintjs.gray3,
  '--background-header': blueprintjs.lightGray4,
  '--background-header-sub': luminance(blueprintjs.lightGray4, -0.015),
  '--background-navbar': blueprintjs.lightGray4,
  '--background-tab-active': colors.white,
  '--background-tab-inactive': luminance(blueprintjs.lightGray4, -0.015),
  '--background-section': blueprintjs.lightGray4,
  '--background-section-body': colors.white,
  '--background-section-sub': blueprintjs.lightGray3,
  '--background-section-super': blueprintjs.lightGray5,
  '--background-end-service-banner': blueprintjs.lightGray4,
  '--button-section': colors.white,
  '--button-header': colors.white,
  '--button-header-hover': colors.white,
  '--button-tab': colors.white,
  '--button-tab-hover': blueprintjs.gray5,
  '--icon': blueprintjs.darkGray5,
  '--text-main': blueprintjs.darkGray3,
  '--text-overlay': blueprintjs.darkGray2,
  '--text-tooltip': colors.white,
  '--highlight-primary-5': blueprintjs.lightGray2,
  '--highlight-primary-6': blueprintjs.gray5,
};

export const isDarkTheme = (theme: Theme) => !theme || theme === Theme.DARK;

export const setTheme = (theme: Theme) => {
  let themeSettings: ThemeSettings;

  switch (theme) {
    case Theme.LIGHT: {
      themeSettings = LightThemeSettings;
      document.body.classList.remove(Classes.DARK);
      break;
    }
    // Theme.DARK
    default: {
      themeSettings = DarkThemeSettings;
      document.body.classList.add(Classes.DARK);
    }
  }

  Object.entries<string>(themeSettings).forEach(([cssProp, color]) =>
    setCssProperty(cssProp, color)
  );
};

const setCssProperty = (key: string, value: string) => {
  document.documentElement.style.setProperty(key, value);
};
