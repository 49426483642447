export const RDF_URI = 'http://www.w3.org/1999/02/22-rdf-syntax-ns#';
export const RDF_LANG_STRING_URI = `${RDF_URI}langString`;
export const RDF_TYPE_URI = `${RDF_URI}type`;

export const RDFS_URI = 'http://www.w3.org/2000/01/rdf-schema#';
export const RDFS_CLASS_URI = `${RDFS_URI}Class`;
export const RDFS_COMMENT_URI = `${RDFS_URI}comment`;
export const RDFS_DOMAIN_URI = `${RDFS_URI}domain`;
export const RDFS_LABEL_URI = `${RDFS_URI}label`;
export const RDFS_RANGE_URI = `${RDFS_URI}range`;
export const RDFS_SUB_CLASS_OF_URI = `${RDFS_URI}subClassOf`;
export const RDFS_SUB_PROPERTY_OF_URI = `${RDFS_URI}subPropertyOf`;

export const OWL_URI = 'http://www.w3.org/2002/07/owl#';
export const OWL_CLASS_URI = `${OWL_URI}Class`;
export const OWL_DATATYPE_PROPERTY_URI = `${OWL_URI}DatatypeProperty`;
export const OWL_OBJECT_PROPERTY_URI = `${OWL_URI}ObjectProperty`;
export const OWL_THING_URI = `${OWL_URI}Thing`;

export const DCAT_URI = 'http://www.w3.org/ns/dcat#';
export const DCAT_DATA_SERVICE_URI = `${DCAT_URI}DataService`;

export const DCMI_ELEMENTS_URI = 'http://purl.org/dc/elements/1.1/';
export const DCMI_ELEMENTS_TITLE_URI = `${DCMI_ELEMENTS_URI}title`;
export const DCMI_ELEMENTS_TYPE_URI = `${DCMI_ELEMENTS_URI}type`;

export const SCHEMA_URI = 'https://schema.org/';
export const SCHEMA_DOMAIN_INCLUDES_URI = `${SCHEMA_URI}domainIncludes`;
export const SCHEMA_RANGE_INCLUDES_URI = `${SCHEMA_URI}rangeIncludes`;

export const SCHEMA_URI_HTTP = 'http://schema.org/';
export const SCHEMA_DOMAIN_INCLUDES_URI_HTTP = `${SCHEMA_URI_HTTP}domainIncludes`;
export const SCHEMA_RANGE_INCLUDES_URI_HTTP = `${SCHEMA_URI_HTTP}rangeIncludes`;

export const SHACL_URI = 'http://www.w3.org/ns/shacl#';

export const SKOS_URI = 'http://www.w3.org/2004/02/skos/core#';
export const SKOS_CONCEPT_URI = `${SKOS_URI}Concept`;
export const SKOS_DEFINITION_URI = `${SKOS_URI}definition`;
export const SKOS_PREF_LABEL_URI = `${SKOS_URI}prefLabel`;

export const STARDOG_API_TAG = 'tag:stardog:api:';
export const STARDOG_API_GRAPH_TAG = `${STARDOG_API_TAG}graph`;

export const STARDOG_API_MODEL_TAG = `${STARDOG_API_TAG}model:`;
export const STARDOG_API_MODEL_DATA_SERVICE_TAG = `${STARDOG_API_MODEL_TAG}dataService:`;
export const STARDOG_API_MODEL_NAME_TAG = `${STARDOG_API_MODEL_TAG}name`;
export const STARDOG_API_MODEL_SOURCE_TAG = `${STARDOG_API_MODEL_TAG}source`;
export const STARDOG_API_MODEL_VIRTUAL_GRAPH_TAG = `${STARDOG_API_MODEL_TAG}VirtualGraph`;

export const STARDOG_STUDIO_TAG = 'tag:stardog:studio:';
export const STARDOG_STUDIO_LABEL_TAG = `${STARDOG_STUDIO_TAG}label`;
export const STARDOG_STUDIO_IS_ATTRIBUTE_TAG = `${STARDOG_STUDIO_TAG}isAttribute`;

export const XSD_URI = 'http://www.w3.org/2001/XMLSchema#';
export const XSD_ANY_URI_URI = `${XSD_URI}anyURI`;
export const XSD_BASE_64_BINARY_URI = `${XSD_URI}base64Binary`;
export const XSD_BOOLEAN_URI = `${XSD_URI}boolean`;
export const XSD_DATE_TIME_URI = `${XSD_URI}dateTime`;
export const XSD_DATE_URI = `${XSD_URI}date`;
export const XSD_DECIMAL_URI = `${XSD_URI}decimal`;
export const XSD_DOUBLE_URI = `${XSD_URI}double`;
export const XSD_DURATION_URI = `${XSD_URI}duration`;
export const XSD_FLOAT_URI = `${XSD_URI}float`;
export const XSD_G_DAY_URI = `${XSD_URI}gDay`;
export const XSD_G_MONTH_DAY_URI = `${XSD_URI}gMonthDay`;
export const XSD_G_MONTH_URI = `${XSD_URI}gMonth`;
export const XSD_G_YEAR_MONTH_URI = `${XSD_URI}gYearMonth`;
export const XSD_G_YEAR_URI = `${XSD_URI}gYear`;
export const XSD_HEX_BINARY_URI = `${XSD_URI}hexBinary`;
export const XSD_INTEGER_URI = `${XSD_URI}integer`;
export const XSD_NOTATION_URI = `${XSD_URI}NOTATION`;
export const XSD_Q_NAME_URI = `${XSD_URI}QName`;
export const XSD_STRING_URI = `${XSD_URI}string`;
export const XSD_TIME_URI = `${XSD_URI}time`;

export const DEFAULT_NAMED_GRAPH = 'default';

export const BNODE_PREFIX = '_:bnode_';
export const DATA_SOURCE_PREFIX = 'data-source://';
export const VIRTUAL_GRAPH_PREFIX = 'virtual://';
export const VIRTUAL_GRAPH_PROVENANCE_PREFIX = '_:vgprov_';

export const GRAPHQL_VALUE_TYPE_BINDINGS = [
  {
    type: 'Int',
    iri: XSD_INTEGER_URI,
  },
  {
    type: 'Float',
    iri: XSD_FLOAT_URI,
  },
  {
    type: 'String',
    iri: XSD_STRING_URI,
  },
  {
    type: 'Boolean',
    iri: XSD_BOOLEAN_URI,
  },
];

export const PRIMITIVE_DATATYPES = [
  XSD_STRING_URI,
  XSD_BOOLEAN_URI,
  XSD_DECIMAL_URI,
  XSD_FLOAT_URI,
  XSD_DOUBLE_URI,
  XSD_DURATION_URI,
  XSD_DATE_TIME_URI,
  XSD_TIME_URI,
  XSD_DATE_URI,
  XSD_G_YEAR_MONTH_URI,
  XSD_G_YEAR_URI,
  XSD_G_MONTH_DAY_URI,
  XSD_G_DAY_URI,
  XSD_G_MONTH_URI,
  XSD_HEX_BINARY_URI,
  XSD_BASE_64_BINARY_URI,
  XSD_ANY_URI_URI,
  XSD_Q_NAME_URI,
  XSD_NOTATION_URI,
];
