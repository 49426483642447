import { exportToFile } from 'src/browser/utils/exportToFile';
import { fileSystemActionCreators } from 'src/common/actions/fileSystem/fileSystemActionCreators';
import {
  StudioStateGetter,
  StudioThunkDispatch,
} from 'src/common/actions/StudioAction';
import { savedConnectionsToCsv } from 'src/common/utils/connection/savedConnectionsToCsv';

const filename = 'Stardog_Studio_Saved_Connections.csv';

export const exportSavedConnections =
  () => async (dispatch: StudioThunkDispatch, getState: StudioStateGetter) => {
    const {
      connection: { saved: savedConnections },
    } = getState();

    let errorMessage: string | undefined;
    try {
      const contents = savedConnectionsToCsv(savedConnections);

      exportToFile({
        contents,
        filename,
        mimeType: 'text/csv',
      });
    } catch (e) {
      errorMessage = e.message;
    }

    dispatch(
      fileSystemActionCreators.exportedSavedConnections(filename, errorMessage)
    );
  };
