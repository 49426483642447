import {
  ActionCreatorAction,
  createTypedAction,
} from 'src/common/actions/types';
import {
  StarchartSortBy,
  StarchartSourcesData,
  StarchartView,
} from 'src/common/store/starchart/StarchartState';

export const enum StarchartActionType {
  SET_STARCHART_VIEW = 'SET_STARCHART_VIEW',
  SET_STARCHART_DATABASE_ID = 'SET_STARCHART_DATABASE_ID',
  SET_STARCHART_DATABASE_PAGE = 'SET_STARCHART_DATABASE_PAGE',
  SET_STARCHART_DATABASE_SORT_BY = 'SET_STARCHART_DATABASE_SORT_BY',
  SET_STARCHART_SELECTED_CLASSES = 'SET_STARCHART_SELECTED_CLASSES',
  SET_STARCHART_SELECTED_SOURCES = 'SET_STARCHART_SELECTED_SOURCES',

  REQUEST_STARCHART_SOURCES_DATA_ATTEMPT = 'REQUEST_STARCHART_SOURCES_DATA_ATTEMPT',
  REQUEST_STARCHART_SOURCES_DATA_SUCCESS = 'REQUEST_STARCHART_SOURCES_DATA_SUCCESS',
  REQUEST_STARCHART_SOURCES_DATA_FAILURE = 'REQUEST_STARCHART_SOURCES_DATA_FAILURE',

  REQUEST_STARCHART_MODEL_ATTEMPT = 'REQUEST_STARCHART_MODEL_ATTEMPT',
  REQUEST_STARCHART_MODEL_SUCCESS = 'REQUEST_STARCHART_MODEL_SUCCESS',
  REQUEST_STARCHART_MODEL_FAILURE = 'REQUEST_STARCHART_MODEL_FAILURE',

  REQUEST_STARCHART_CLASSES_ATTEMPT = 'REQUEST_STARCHART_CLASSES_ATTEMPT',
  REQUEST_STARCHART_CLASSES_SUCCESS = 'REQUEST_STARCHART_CLASSES_SUCCESS',
  REQUEST_STARCHART_CLASSES_FAILURE = 'REQUEST_STARCHART_CLASSES_FAILURE',
}

export const starchartActionCreators = {
  setStarchartView: (view: StarchartView) =>
    createTypedAction(StarchartActionType.SET_STARCHART_VIEW, {
      view,
    }),
  setStarchartDatabaseId: (databaseId: string) =>
    createTypedAction(StarchartActionType.SET_STARCHART_DATABASE_ID, {
      databaseId,
    }),
  setStarchartDatabasePage: (page: number) =>
    createTypedAction(StarchartActionType.SET_STARCHART_DATABASE_PAGE, {
      page,
    }),
  setStarchartDatabaseSortBy: (sortBy: StarchartSortBy) =>
    createTypedAction(StarchartActionType.SET_STARCHART_DATABASE_SORT_BY, {
      sortBy,
    }),
  setStarchartSelectedClasses: (selectedClassIris: Set<string>) =>
    createTypedAction(StarchartActionType.SET_STARCHART_SELECTED_CLASSES, {
      selectedClassIris,
    }),
  setStarchartSelectedSources: (selectedSourceIris: Set<string>) =>
    createTypedAction(StarchartActionType.SET_STARCHART_SELECTED_SOURCES, {
      selectedSourceIris,
    }),

  requestStarchartSourcesDataAttempt: (databaseId: string) =>
    createTypedAction(
      StarchartActionType.REQUEST_STARCHART_SOURCES_DATA_ATTEMPT,
      {
        databaseId,
      }
    ),
  requestStarchartSourcesDataSuccess: (
    databaseId: string,
    data: StarchartSourcesData
  ) =>
    createTypedAction(
      StarchartActionType.REQUEST_STARCHART_SOURCES_DATA_SUCCESS,
      {
        databaseId,
        data,
      }
    ),
  requestStarchartSourcesDataFailure: (databaseId: string, response: any) =>
    createTypedAction(
      StarchartActionType.REQUEST_STARCHART_SOURCES_DATA_FAILURE,
      {
        databaseId,
        response,
      }
    ),
};

export type StarchartAction = ActionCreatorAction<
  typeof starchartActionCreators
>;
