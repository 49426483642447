import { dedupeArray } from 'src/common/utils/dedupeArray';
import { getMinimum } from 'src/common/utils/getMinimum';

type TreeViewItem<T> = T & {
  id: string;
  parents: string[];
  children?: string[];
};

export type TreeViewItemMap<T> = {
  [id: string]: TreeViewItem<T>;
};

type KosarajuItem<T> = TreeViewItem<T> & {
  visited?: boolean;
  component?: string;
};

type KosarajuItemMap<T> = {
  [id: string]: KosarajuItem<T>;
};

type TreeComponent = {
  id: string;
  indegree: number;
  vertices: string[];
};

type TreeComponentMap = {
  [id: string]: TreeComponent;
};

function assignChildItems<T>(rawItems: Array<TreeViewItem<T>>) {
  const childMap: { [id: string]: string[] } = {};

  rawItems.forEach((vertex) => {
    vertex.parents.forEach((parentId) => {
      if (!childMap[parentId]) {
        childMap[parentId] = [];
      }
      childMap[parentId].push(vertex.id);
    });
  });

  return rawItems.reduce((acc, rawItem) => {
    acc[rawItem.id] = {
      ...rawItem,
      parents: [...(rawItem.parents || [])].sort(),
      children: [...(childMap[rawItem.id] || [])].sort(),
    };
    return acc;
  }, {} as TreeViewItemMap<T>);
}

function assignStronglyConnectedComponents<T>(vertices: TreeViewItemMap<T>) {
  // Using Kosaraju's Algorithm for Strongly Connected Components
  // https://en.wikipedia.org/wiki/Kosaraju%27s_algorithm

  const vertexList = Object.values(vertices);

  // For each vertex u of the graph, mark u as unvisited.
  const vertexMap: KosarajuItemMap<T> = vertexList.reduce((acc, vertex) => {
    acc[vertex.id] = {
      ...vertex,
      visited: false,
    };
    return acc;
  }, {});

  // Let L be empty.
  const L: string[] = [];

  // visit(u) is the recursive subroutine:
  function visit(id: string) {
    const vertex = vertexMap[id];
    // If u is unvisited then:
    if (!vertex.visited) {
      // Mark u as visited.
      vertex.visited = true;
      // For each out-neighbour v of u, do visit(v).
      vertex.children.forEach((childId) => visit(childId));
      // Prepend u to L.
      L.unshift(vertex.id);
    }
    // Otherwise do nothing.
  }

  // For each vertex u of the graph do visit(u)
  vertexList.forEach((vertex) => visit(vertex.id));

  // assign(u,root) is the recursive subroutine:
  // function assign(id: string, root: string) {
  function assign(id: string, root: string) {
    const vertex = vertexMap[id];
    // If u has not been assigned to a component then:
    if (vertex && !vertex.component) {
      // assign u as belonging to the component whose root is root.
      vertex.component = root;
      // For each in-neighbour v of u, do assign(v,root).
      vertex.parents.forEach((parentId) => assign(parentId, root));
    }
    // Otherwise do nothing.
  }

  // For each element u of L in order, do assign(u,u) where assign(u,root) is the recursive subroutine:
  L.forEach((vertex) => assign(vertex, vertex));

  return vertexMap;
}

function getComponentsWithIndegree<T>(vertexMap: KosarajuItemMap<T>) {
  const components: TreeComponentMap = {};

  // For each vertex u that's assigned to component c
  Object.values(vertexMap).forEach((vertex) => {
    // If it doesn't already exist then:
    if (!components[vertex.component]) {
      // Create a component object c
      components[vertex.component] = {
        id: vertex.component,
        indegree: 0,
        vertices: [],
      };
    }

    // Add the u to the list of vertices on c
    components[vertex.component].vertices.push(vertex.id);

    // For each in-neighbour v of u
    vertex.parents.forEach((id) => {
      // If v is not also in c
      if (vertexMap[id] && vertexMap[id].component !== vertex.component) {
        // Increment c's indegree
        components[vertex.component].indegree += 1;
      }
    });
  });

  return components;
}

function getRoots<T>(
  components: TreeComponentMap,
  items: TreeViewItemMap<T>,
  rootId?: string
) {
  // A root is a component with indegree 0 or a vertex with a given parent id
  // If the component has many vertices, use the minimum value for consistency

  const rootComponents = Object.values(components)
    .filter((component) => component.indegree === 0)
    .map((component) => {
      if (component.vertices.length === 1) {
        return component.id;
      }
      return getMinimum(component.vertices);
    });

  if (!rootId) {
    return rootComponents;
  }

  const rootVertices = Object.keys(items).filter((id) =>
    items[id].parents.includes(rootId)
  );
  return dedupeArray([...rootComponents, ...rootVertices]).filter(
    (id) => id !== rootId
  );
}

export function getTreeViewData<T>(
  rawItems: Array<TreeViewItem<T>>,
  rootId?: string
) {
  // We are finding the smallest possible set of vertices such that
  // any other vertex can be reached from that set.

  // Picking only the indegree zero vertices is insufficient as
  // a general digraph can have a cycle that is not reachable
  // from an indegree zero vertex.

  // The graph of strongly connected components (condensation)
  // is a DAG so by finding the indegree zero components,
  // we know which vertices to display at the top-level.

  // 1. Sort parents and assign sorted children
  const items = assignChildItems(rawItems);
  // 2. Assign strongly connected components to vertices
  const verticesWithComponents = assignStronglyConnectedComponents(items);
  // 3. Get component indegree and vertices
  const components = getComponentsWithIndegree(verticesWithComponents);
  // 4. Get roots from components with indegree and vertices
  const roots = getRoots(components, items, rootId);

  return { treeView: items, roots };

  // To take this tree view data and build the tree from it:
  // 1. Pick a root
  // 2. Set the current node = root
  // 3. Loop over the children of the current node and display children under the current node
  // 4. Repeat (1-3) for each other root
}
