export const ALL_GRAPHS = 'tag:stardog:api:context:all';
export const DEFAULT_GRAPH = 'tag:stardog:api:context:default';
export const ALL_NONDEFAULT_NAMED_GRAPHS = 'tag:stardog:api:context:named';
export const ALL_LOCAL_NAMED_GRAPHS = 'tag:stardog:api:context:local';
export const ALL_VIRTUAL_GRAPHS = 'tag:stardog:api:context:virtual';
export const ALIASES_GRAPH = 'tag:stardog:api:graph:aliases';
export const ALIAS_PREDICATE = 'tag:stardog:api:graph:alias';
export const SQL_SCHEMA_GRAPH = 'tag:stardog:api:sql:schema';

// Special named graphs, as described in https://docs.stardog.com/query-stardog/#special-named-graphs
const SPECIAL_NAMED_GRAPHS = [
  ALL_GRAPHS,
  DEFAULT_GRAPH,
  ALL_NONDEFAULT_NAMED_GRAPHS,
];

// ALL_LOCAL_NAMED_GRAPHS and ALL_VIRTUAL_GRAPHS are only supported with the introduction of virutal transparency
const SPECIAL_VT_NAMED_GRAPHS = [
  ...SPECIAL_NAMED_GRAPHS,
  ALL_LOCAL_NAMED_GRAPHS,
  ALL_VIRTUAL_GRAPHS,
];

// These graphs include metadata and should not be easily accessible
export const BLOCKED_NAMED_GRAPHS = [ALIASES_GRAPH, SQL_SCHEMA_GRAPH];

export function getSpecialNamedGraphs(
  stardogSupportsVirtualTransparency: boolean
) {
  return stardogSupportsVirtualTransparency
    ? SPECIAL_VT_NAMED_GRAPHS
    : SPECIAL_NAMED_GRAPHS;
}

export type NamedGraphBinding = {
  graph: {
    type: 'uri';
    value: string;
  };
};

export function createNamedGraphBinding(namedGraph: string): NamedGraphBinding {
  return {
    graph: {
      type: 'uri' as const,
      value: namedGraph,
    },
  };
}

const SPECIAL_NAMED_GRAPH_BINDINGS = SPECIAL_NAMED_GRAPHS.map(
  createNamedGraphBinding
);
const SPECIAL_VT_NAMED_GRAPH_BINDINGS = SPECIAL_VT_NAMED_GRAPHS.map(
  createNamedGraphBinding
);

export function getSpecialNamedGraphBindings(
  stardogSupportsVirtualTransparency: boolean
) {
  return stardogSupportsVirtualTransparency
    ? SPECIAL_VT_NAMED_GRAPH_BINDINGS
    : SPECIAL_NAMED_GRAPH_BINDINGS;
}

export function getAllLocalNamedGraph(
  stardogSupportsVirtualTransparency: boolean
) {
  return stardogSupportsVirtualTransparency
    ? ALL_LOCAL_NAMED_GRAPHS
    : ALL_GRAPHS;
}
