import { Intent, Position, Toast, Toaster } from '@blueprintjs/core';
import * as React from 'react';
import { connect } from 'react-redux';

import { notificationsActionCreators } from 'src/common/actions/notifications/notificationsActionCreators';
import { StudioThunkDispatch } from 'src/common/actions/StudioAction';
import { ActionableNotificationMessage } from 'src/common/components/ActionableNotificationMessage';
import { ErrorNotificationMessage } from 'src/common/components/ErrorNotificationMessage';
import { getNotificationTestId } from 'src/common/constants/testIds';
import { Notification } from 'src/common/store/notifications/NotificationsState';
import { ReduxState } from 'src/types';

const mapStateToProps = ({ notifications, preferences }: ReduxState) => {
  return {
    errorToastTimeout: preferences.errorToastTimeout,
    notifications: notifications.notifications,
  };
};

const mapDispatchToProps = (dispatch: StudioThunkDispatch) => {
  return {
    unqueueNotification: (id: string) =>
      dispatch(notificationsActionCreators.unqueueNotification(id)),
  };
};

type NotificationToastProps = {
  defaultFailureTimeout: number | undefined;
  notification: Notification;
  onDismiss: () => void;
};

const NotificationToast: React.VFC<NotificationToastProps> = ({
  defaultFailureTimeout,
  notification,
  onDismiss,
}) => {
  const { id, type, data, message, ...extraProps } = notification;
  const isFailure = extraProps.intent === Intent.DANGER;
  const isWarning = extraProps.intent === Intent.WARNING;

  const testId = getNotificationTestId(type);
  let messageElement: JSX.Element = <span data-testid={testId}>{message}</span>;
  if (isFailure) {
    messageElement = (
      <ErrorNotificationMessage
        message={message as string}
        notificationId={id}
      />
    );
  } else if (data.actionableProps) {
    messageElement = (
      <ActionableNotificationMessage
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...data.actionableProps}
        testId={testId}
      />
    );
  }

  let defaultTimeout = 3500;
  if (isFailure) {
    // use user preference, or 0 to never timeout
    defaultTimeout = defaultFailureTimeout || 0;
  } else if (isWarning) {
    defaultTimeout = 5000;
  }

  return (
    <Toast
      message={messageElement}
      onDismiss={onDismiss}
      timeout={defaultTimeout}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...extraProps}
    />
  );
};

type UnconnectedNotificationsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

// Component to handle showing and closing "snackbar" notifications. The
// component renders only one notification (the first one in the notifications
// queue) at a time.
const UnconnectedNotifications = ({
  errorToastTimeout,
  notifications,
  unqueueNotification,
}: UnconnectedNotificationsProps) => (
  <Toaster position={Position.BOTTOM_RIGHT}>
    {notifications.map((notification, index) => (
      <NotificationToast
        key={`notification_id_${notification.id || index}`}
        defaultFailureTimeout={errorToastTimeout * 1000}
        notification={notification}
        onDismiss={() => unqueueNotification(notification.id)}
      />
    ))}
  </Toaster>
);

export const Notifications = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnconnectedNotifications);
