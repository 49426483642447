import { query as stardogQuery } from 'stardog';

import { CachePayload } from 'src/browser/apis/browserQueryCache';
import { FileSystemFileHandle } from 'src/browser/constants/FileSystemAccess';
import { getPathsStartAndEnd } from 'src/common/actions/notebook/action-creators/stardog-api/executeQueryForNote/paths';
import { request } from 'src/common/actions/request';
import { API } from 'src/common/actions/request/types';
import {
  ActionCreatorAction,
  createTypedAction,
} from 'src/common/actions/types';
import { LanguageId, QueryLanguageId } from 'src/common/constants/LanguageId';
import { QueryTypeIdentifier } from 'src/common/constants/QueryType';
import { DEFAULT_REASONING_SCHEMA } from 'src/common/store/databases/DatabasesState';
import {
  NotebookMosaicStateChange,
  NotebookStoredAndHistoryTab,
  ScrollPosition,
} from 'src/common/store/notebook/NotebookState';
import {
  NoteStorageType,
  ResultSettings,
} from 'src/common/store/notebook/NoteState';
import { Preferences } from 'src/common/store/preferences/PreferencesState';
import { rdfExtensions } from 'src/common/utils/fileExtensions';
import { StardogQueryMethodData } from 'src/common/utils/rpc/types';
import { VoiceboxMessage } from 'src/common/utils/voicebox/types';

export const enum NotebookActionType {
  ASK_VOICEBOX_ATTEMPT = 'ASK_VOICEBOX_ATTEMPT',
  ASK_VOICEBOX_FAILURE = 'ASK_VOICEBOX_FAILURE',
  ASK_VOICEBOX_SUCCESS = 'ASK_VOICEBOX_SUCCESS',
  UPDATED_VOICEBOX_CONVERSATION = 'UPDATED_VOICEBOX_CONVERSATION',
  DOWNLOADED_VOICEBOX_CONVERSATION = 'DOWNLOADED_VOICEBOX_CONVERSATION',
  SET_DB_FOR_NOTE = 'SET_DB_FOR_NOTE',
  SET_NAMED_GRAPHS_FOR_NOTE = 'SET_NAMED_GRAPHS_FOR_NOTE',
  TOGGLE_REASONING_FOR_NOTE = 'TOGGLE_REASONING_FOR_NOTE',
  SET_QUERY_TIMEOUT_FOR_NOTE = 'SET_QUERY_TIMEOUT_FOR_NOTE',
  SET_REASONING_SCHEMA_FOR_NOTE = 'SET_REASONING_SCHEMA_FOR_NOTE',
  SET_QUERY_FOR_NOTE = 'SET_QUERY_FOR_NOTE',
  EXECUTE_QUERY_FOR_NOTE_ATTEMPT = 'EXECUTE_QUERY_FOR_NOTE_ATTEMPT',
  EXECUTE_QUERY_FOR_NOTE_SUCCESS = 'EXECUTE_QUERY_FOR_NOTE_SUCCESS',
  EXECUTE_QUERY_FOR_NOTE_FAILURE = 'EXECUTE_QUERY_FOR_NOTE_FAILURE',
  EXECUTE_QUERY_FOR_NOTE_RECEIVED = 'EXECUTE_QUERY_FOR_NOTE_RECEIVED',
  EXECUTE_JSON_LD_QUERY_FOR_NOTE_ATTEMPT = 'EXECUTE_JSON_LD_QUERY_FOR_NOTE_ATTEMPT',
  EXECUTE_JSON_LD_QUERY_FOR_NOTE_SUCCESS = 'EXECUTE_JSON_LD_QUERY_FOR_NOTE_SUCCESS',
  EXECUTE_JSON_LD_QUERY_FOR_NOTE_FAILURE = 'EXECUTE_JSON_LD_QUERY_FOR_NOTE_FAILURE',
  EXECUTE_DESCRIBE_QUERY_FOR_IRI_ATTEMPT = 'EXECUTE_DESCRIBE_QUERY_FOR_IRI_ATTEMPT',
  EXECUTE_DESCRIBE_QUERY_FOR_IRI_SUCCESS = 'EXECUTE_DESCRIBE_QUERY_FOR_IRI_SUCCESS',
  EXECUTE_DESCRIBE_QUERY_FOR_IRI_FAILURE = 'EXECUTE_DESCRIBE_QUERY_FOR_IRI_FAILURE',
  EXPORT_RESULTS_FOR_NOTE_ATTEMPT = 'EXPORT_RESULTS_FOR_NOTE_ATTEMPT',
  EXPORT_RESULTS_FOR_NOTE_SUCCESS = 'EXPORT_RESULTS_FOR_NOTE_SUCCESS',
  EXPORT_RESULTS_FOR_NOTE_FAILURE = 'EXPORT_RESULTS_FOR_NOTE_FAILURE',
  ADD_NOTE = 'ADD_NOTE',
  DELETE_FILE_HANDLE_FOR_NOTE = 'DELETE_FILE_HANDLE_FOR_NOTE',
  DELETE_NOTE = 'DELETE_NOTE',
  RENAME_NOTE = 'RENAME_NOTE',
  OPEN_SEARCH_NOTE = 'OPEN_SEARCH_NOTE',
  TOGGLE_RESULT_COLLAPSE_FOR_NOTE = 'TOGGLE_RESULT_COLLAPSE_FOR_NOTE',
  EXPORT_QUERY_FOR_NOTE_ATTEMPT = 'EXPORT_QUERY_FOR_NOTE_ATTEMPT',
  EXPORT_QUERY_FOR_NOTE_SUCCESS = 'EXPORT_QUERY_FOR_NOTE_SUCCESS',
  EXPORT_QUERY_FOR_NOTE_FAILURE = 'EXPORT_QUERY_FOR_NOTE_FAILURE',
  EXPLAIN_QUERY_FOR_NOTE_ATTEMPT = 'EXPLAIN_QUERY_FOR_NOTE_ATTEMPT',
  EXPLAIN_QUERY_FOR_NOTE_SUCCESS = 'EXPLAIN_QUERY_FOR_NOTE_SUCCESS',
  EXPLAIN_QUERY_FOR_NOTE_FAILURE = 'EXPLAIN_QUERY_FOR_NOTE_FAILURE',
  SET_QUERY_ID_FOR_NOTE = 'SET_QUERY_ID_FOR_NOTE',
  REARRANGE_NOTES = 'REARRANGE_NOTES',
  RESIZE_RESULTS_FOR_NOTE = 'RESIZE_RESULTS_FOR_NOTE',
  OPEN_CONTENTS_IN_NEW_NOTE = 'OPEN_CONTENTS_IN_NEW_NOTE',
  SAVE_VIEW_STATE_FOR_NOTE = 'SAVE_VIEW_STATE_FOR_NOTE',
  SET_SAVED_FLAG_FOR_NOTE = 'SET_SAVED_FLAG_FOR_NOTE',
  UPDATE_NOTEBOOK_MOSAIC_STATE = 'UPDATE_NOTEBOOK_MOSAIC_STATE',
  SET_STORED_AND_HISTORY_TAB = 'SET_STORED_AND_HISTORY_TAB',
  SET_RESULTS_COLUMN_WIDTHS = 'SET_RESULTS_COLUMN_WIDTHS',
  SET_RESULTS_SCROLL_POSITION = 'SET_RESULTS_SCROLL_POSITION',
  SET_RESULTS_SELECTION = 'SET_RESULTS_SELECTION',
  SET_RESULTS_VIEW_TYPE = 'SET_RESULTS_VIEW_TYPE',
  TOGGLE_STORE_QUERY_POPOVER = 'TOGGLE_STORE_QUERY_POPOVER',
  SHOW_STORE_QUERY_DIALOG = 'SHOW_STORE_QUERY_DIALOG',
  SET_QUERY_CONFIG_POPOVER_OPEN = 'SET_QUERY_CONFIG_POPOVER_OPEN',
  SET_SCHEMA_DIALOG_OPEN = 'SET_SCHEMA_DIALOG_OPEN',
  IMPORT_QUERY_FOR_NOTE_ATTEMPT = 'IMPORT_QUERY_FOR_NOTE_ATTEMPT',
  IMPORT_QUERY_FOR_NOTE_SUCCESS = 'IMPORT_QUERY_FOR_NOTE_SUCCESS',
  IMPORT_QUERY_FOR_NOTE_FAILURE = 'IMPORT_QUERY_FOR_NOTE_FAILURE',
  RESET_SELECTED_DATABASES = 'RESET_SELECTED_DATABASES',
  FETCH_FROM_CACHE = 'FETCH_FROM_CACHE',
  SET_LANGUAGE_ID_FOR_NOTE = 'SET_LANGUAGE_ID_FOR_NOTE',
  OPEN_RELEASE_NOTES = 'OPEN_RELEASE_NOTES',
  OPEN_USER_PREFERENCES = 'OPEN_USER_PREFERENCES',
  OPEN_IN_TAB = 'OPEN_IN_TAB',
  LOAD_SANDDANCE_ATTEMPT = 'LOAD_SANDDANCE_ATTEMPT',
  LOAD_SANDDANCE_FAILURE = 'LOAD_SANDDANCE_FAILURE',
  LOAD_SANDDANCE_SUCCESS = 'LOAD_SANDDANCE_SUCCESS',
}

export const notebookActionCreators = {
  askVoiceboxAttempt: ({ noteId }: { noteId: string }) =>
    createTypedAction(NotebookActionType.ASK_VOICEBOX_ATTEMPT, {
      noteId,
    }),
  askVoiceboxFailure: ({
    errorMessage,
    noteId,
  }: {
    errorMessage: string;
    noteId: string;
  }) =>
    createTypedAction(NotebookActionType.ASK_VOICEBOX_FAILURE, {
      errorMessage,
      noteId,
    }),
  askVoiceboxSuccess: ({ noteId }: { noteId: string }) =>
    createTypedAction(NotebookActionType.ASK_VOICEBOX_SUCCESS, {
      noteId,
    }),
  updatedVoiceboxConversation: ({
    conversationId,
    messages,
    noteId,
  }: {
    conversationId: string;
    messages: VoiceboxMessage[];
    noteId: string;
  }) =>
    createTypedAction(NotebookActionType.UPDATED_VOICEBOX_CONVERSATION, {
      conversationId,
      messages,
      noteId,
    }),

  downloadedVoiceboxConversation: (filename: string) =>
    createTypedAction(NotebookActionType.DOWNLOADED_VOICEBOX_CONVERSATION, {
      filename,
    }),

  addNote: (contents?: string, title?: string) =>
    createTypedAction(NotebookActionType.ADD_NOTE, {
      contents,
      title,
    }),
  deleteFileHandleForNote: (noteId: string) =>
    createTypedAction(NotebookActionType.DELETE_FILE_HANDLE_FOR_NOTE, {
      noteId,
    }),
  deleteNote: (noteId: string) =>
    createTypedAction(NotebookActionType.DELETE_NOTE, {
      noteId,
    }),
  fetchRowFromCache: (payload: CachePayload) =>
    createTypedAction(NotebookActionType.FETCH_FROM_CACHE, payload),
  exportResultsForNoteAttempt: (noteId: string) =>
    createTypedAction(NotebookActionType.EXPORT_RESULTS_FOR_NOTE_ATTEMPT, {
      noteId,
    }),
  exportResultsForNoteFailure: (
    noteId: string,
    filePath: string,
    typeOfQuery: QueryTypeIdentifier
  ) =>
    createTypedAction(NotebookActionType.EXPORT_RESULTS_FOR_NOTE_FAILURE, {
      noteId,
      filePath,
      typeOfQuery,
    }),
  exportResultsForNoteSuccess: (
    noteId: string,
    filePath: string,
    typeOfQuery: QueryTypeIdentifier
  ) =>
    createTypedAction(NotebookActionType.EXPORT_RESULTS_FOR_NOTE_SUCCESS, {
      noteId,
      filePath,
      typeOfQuery,
    }),
  openContentsInNewNote: (contents: string) =>
    createTypedAction(NotebookActionType.OPEN_CONTENTS_IN_NEW_NOTE, {
      contents,
    }),
  openInTab: ({
    target,
    contents,
    storageType,
    handle,
    database,
    reasoning,
    languageId,
  }: {
    target: string;
    contents: string;
    storageType: NoteStorageType;
    handle?: FileSystemFileHandle;
    database?: string;
    reasoning?: boolean;
    languageId?: LanguageId;
  }) =>
    createTypedAction(NotebookActionType.OPEN_IN_TAB, {
      target,
      contents,
      storageType: storageType || NoteStorageType.STARDOG,
      handle,
      database,
      reasoning,
      languageId,
    }),
  rearrangeNotes: (payload: { sourceNoteId: string; targetNoteId: string }) =>
    createTypedAction(NotebookActionType.REARRANGE_NOTES, payload),
  renameNote: (oldNoteId: string, newNoteId: string) =>
    createTypedAction(NotebookActionType.RENAME_NOTE, {
      oldNoteId,
      newNoteId,
    }),
  resizeResultsForNote: (noteId: string, resultsHeight: number) =>
    createTypedAction(NotebookActionType.RESIZE_RESULTS_FOR_NOTE, {
      noteId,
      resultsHeight,
    }),
  saveViewStateForNote: (noteId, viewState) =>
    createTypedAction(NotebookActionType.SAVE_VIEW_STATE_FOR_NOTE, {
      noteId,
      viewState,
    }),
  setDbForNote: (noteId: string, db: string) =>
    createTypedAction(NotebookActionType.SET_DB_FOR_NOTE, {
      db,
      noteId,
    }),
  setNamedGraphsForNote: (noteId: string, namedGraphs: string[]) =>
    createTypedAction(NotebookActionType.SET_NAMED_GRAPHS_FOR_NOTE, {
      namedGraphs,
      noteId,
    }),
  setQueryForNote: (query, noteId) =>
    createTypedAction(NotebookActionType.SET_QUERY_FOR_NOTE, {
      noteId,
      query,
    }),
  setResultsColumnWidths: (columnWidths: ResultSettings['columnWidths']) =>
    createTypedAction(NotebookActionType.SET_RESULTS_COLUMN_WIDTHS, {
      columnWidths,
    }),
  setResultsScrollPosition: (scrollPosition: ScrollPosition, noteId: string) =>
    createTypedAction(NotebookActionType.SET_RESULTS_SCROLL_POSITION, {
      noteId,
      scrollPosition,
    }),
  setResultsSelection: (selection: ResultSettings['selection']) =>
    createTypedAction(NotebookActionType.SET_RESULTS_SELECTION, {
      selection,
    }),
  setResultsViewType: (viewType: ResultSettings['viewType']) =>
    createTypedAction(NotebookActionType.SET_RESULTS_VIEW_TYPE, {
      viewType,
    }),
  setSavedFlagForNote: (isSaved: boolean) =>
    createTypedAction(NotebookActionType.SET_SAVED_FLAG_FOR_NOTE, {
      isSaved,
    }),
  toggleStoreQueryPopover: (noteId: string) =>
    createTypedAction(NotebookActionType.TOGGLE_STORE_QUERY_POPOVER, {
      noteId,
    }),
  showStoreQueryDialog: (noteId: string, open: boolean) =>
    createTypedAction(NotebookActionType.SHOW_STORE_QUERY_DIALOG, {
      noteId,
      open,
    }),
  setQueryConfigPopoverOpen: (noteId: string, isOpen: boolean) =>
    createTypedAction(NotebookActionType.SET_QUERY_CONFIG_POPOVER_OPEN, {
      noteId,
      isOpen,
    }),
  setSchemaDialogOpen: (noteId: string, isOpen: boolean) =>
    createTypedAction(NotebookActionType.SET_SCHEMA_DIALOG_OPEN, {
      noteId,
      isOpen,
    }),
  toggleReasoningForNote: (noteId: string) =>
    createTypedAction(NotebookActionType.TOGGLE_REASONING_FOR_NOTE, {
      noteId,
    }),
  setQueryTimeoutForNote: (noteId: string, timeout: string) =>
    createTypedAction(NotebookActionType.SET_QUERY_TIMEOUT_FOR_NOTE, {
      noteId,
      timeout,
    }),
  setReasoningSchemaForNote: (noteId: string, schema: string) =>
    createTypedAction(NotebookActionType.SET_REASONING_SCHEMA_FOR_NOTE, {
      noteId,
      schema,
    }),
  toggleResultCollapseForNote: (noteId: string) =>
    createTypedAction(NotebookActionType.TOGGLE_RESULT_COLLAPSE_FOR_NOTE, {
      noteId,
    }),
  updateNotebookMosaicState: (mosaicStateChange: NotebookMosaicStateChange) =>
    createTypedAction(NotebookActionType.UPDATE_NOTEBOOK_MOSAIC_STATE, {
      mosaicStateChange,
    }),
  setStoredAndHistoryTab: (storedAndHistoryTab: NotebookStoredAndHistoryTab) =>
    createTypedAction(NotebookActionType.SET_STORED_AND_HISTORY_TAB, {
      storedAndHistoryTab,
    }),
  resetSelectedDatabases: (databases: string[]) =>
    createTypedAction(NotebookActionType.RESET_SELECTED_DATABASES, {
      databases,
    }),
  executeSelectAttempt: (query: string, noteId: string, queryId: string) =>
    createTypedAction(NotebookActionType.EXECUTE_QUERY_FOR_NOTE_ATTEMPT, {
      query,
      noteId,
      queryId,
    }),
  executeSelectSuccess: ({
    query,
    noteId,
    response,
    timeElapsed,
  }: {
    query: string;
    noteId: string;
    response: any;
    timeElapsed: number;
  }) =>
    createTypedAction(NotebookActionType.EXECUTE_QUERY_FOR_NOTE_SUCCESS, {
      query,
      response,
      timeElapsed,
      noteId,
      action: {
        noteId,
        telemetry: {
          query_length: query.length,
        },
      },
    }),
  executeSelectFailure: ({ noteId, error }) =>
    createTypedAction(NotebookActionType.EXECUTE_QUERY_FOR_NOTE_FAILURE, {
      noteId,
      // Put the error on the property where the Notebook reducer expects it
      response: {
        errorMessage: error,
      },
    }),
  setQueryIdForNote: (queryId: string, noteId: string) =>
    createTypedAction(NotebookActionType.SET_QUERY_ID_FOR_NOTE, {
      queryId,
      noteId,
    }),
  setLanguageIdForNote: ({
    languageId,
    noteId,
  }: {
    languageId: LanguageId;
    noteId: string;
  }) =>
    createTypedAction(NotebookActionType.SET_LANGUAGE_ID_FOR_NOTE, {
      languageId,
      noteId,
    }),
  openReleaseNotes: () =>
    createTypedAction(NotebookActionType.OPEN_RELEASE_NOTES),
  openUserPreferences: (preferences: Preferences) =>
    createTypedAction(NotebookActionType.OPEN_USER_PREFERENCES, {
      preferences,
    }),
  loadSandDanceAttempt: (noteId: string) =>
    createTypedAction(NotebookActionType.LOAD_SANDDANCE_ATTEMPT, { noteId }),
  loadSandDanceFailure: (noteId: string) =>
    createTypedAction(NotebookActionType.LOAD_SANDDANCE_FAILURE, { noteId }),
  loadSandDanceSuccess: (noteId: string) =>
    createTypedAction(NotebookActionType.LOAD_SANDDANCE_SUCCESS, { noteId }),
};

export const notebookStardogRequestDispatchers = {
  executePathsQueryForNoteDispatcher: ({
    query,
    queryId,
    db,
    namedGraphs,
    onResponseStart,
    reasoning,
    limit,
    schema,
    timeout,
    noteId,
  }: Omit<StardogQueryMethodData, 'connection'>) => {
    const { start, end } = getPathsStartAndEnd(query);

    return notebookStardogRequestDispatchers.executeQueryDispatcher({
      db,
      query,
      queryId,
      namedGraphs,
      onResponseStart,
      reasoning,
      limit,
      schema,
      timeout,
      noteId,
      start,
      end,
    });
  },
  executeQueryDispatcher: ({
    db,
    query,
    queryId,
    acceptType,
    namedGraphs,
    reasoning = false,
    limit,
    schema,
    timeout,
    noteId,
    start,
    end,
    languageId,
    onResponseStart,
  }: Omit<StardogQueryMethodData, 'connection'> & {
    languageId?: QueryLanguageId;
  }) => {
    let api: API;
    let args: any[];
    const options: any = {};

    if (queryId) {
      options.id = queryId;
    } else {
      options.flushResponseHeaders = 'true';
    }

    if (languageId === LanguageId.GRAPHQL) {
      api = stardogQuery.graphql.execute;
      const variables: any = { '@reasoning': reasoning };
      if (timeout) {
        options.timeout = timeout;
      }
      if (schema && schema !== DEFAULT_REASONING_SCHEMA) {
        options.schema = schema;
      }
      args = [db, query, variables, options];
    } else {
      api = stardogQuery.execute;
      options.reasoning = reasoning;
      if (limit) {
        options.limit = limit;
      }
      if (namedGraphs && namedGraphs.length) {
        options['graph-uri'] = namedGraphs;
        // remove-graph-uri is needed for DELETE queries
        options['remove-graph-uri'] = namedGraphs;
        // insert-graph-uri is needed for INSERT queries
        options['insert-graph-uri'] = namedGraphs;
        // ... stardog ignores these extra parameters when they're irrelevant to
        // the query; see discussion in VET-1093
      }
      if (timeout) {
        options.timeout = timeout;
      }
      if (schema && schema !== DEFAULT_REASONING_SCHEMA) {
        options.schema = schema;
      }
      args = [
        db,
        query,
        acceptType,
        options,
        onResponseStart ? { onResponseStart } : undefined,
      ];
    }

    return request({
      api,
      args,
      loading: NotebookActionType.EXECUTE_QUERY_FOR_NOTE_ATTEMPT,
      success: NotebookActionType.EXECUTE_QUERY_FOR_NOTE_SUCCESS,
      failure: NotebookActionType.EXECUTE_QUERY_FOR_NOTE_FAILURE,
      onReceived: NotebookActionType.EXECUTE_QUERY_FOR_NOTE_RECEIVED,
      action: {
        noteId,
        languageId,
        queryId,
        telemetry: {
          query_length: query.length,
        },
        start,
        end,
      },
    });
  },
  executeJsonLdQueryDispatcher: ({
    db,
    query,
    queryId,
    limit,
    namedGraphs,
    reasoning,
    schema,
    timeout,
    noteId,
  }: Omit<StardogQueryMethodData, 'connection'>) => {
    const acceptType = rdfExtensions.fileExtensionToMimeType.json;
    const api = stardogQuery.execute;
    const options: any = { reasoning };
    if (queryId) {
      options.id = queryId;
    }
    if (limit) {
      options.limit = limit;
    }
    if (namedGraphs && namedGraphs.length) {
      options['graph-uri'] = namedGraphs;
    }
    if (timeout) {
      options.timeout = timeout;
    }
    if (schema && schema !== DEFAULT_REASONING_SCHEMA) {
      options.schema = schema;
    }
    const args = [db, query, acceptType, options];

    return request({
      api,
      args,
      loading: NotebookActionType.EXECUTE_JSON_LD_QUERY_FOR_NOTE_ATTEMPT,
      success: NotebookActionType.EXECUTE_JSON_LD_QUERY_FOR_NOTE_SUCCESS,
      failure: NotebookActionType.EXECUTE_JSON_LD_QUERY_FOR_NOTE_FAILURE,
      action: {
        noteId,
        queryHistoryId: queryId,
        telemetry: {
          query_length: query.length,
        },
      },
    });
  },
  executeDescribeQueryForIriDispatcher: ({
    db,
    query,
    namedGraphs,
    reasoning,
    schema,
    timeout,
    iri,
  }: {
    db: string;
    query: string;
    namedGraphs?: string[];
    reasoning: boolean;
    schema?: string;
    timeout?: string;
    iri: string;
  }) => {
    const acceptType = rdfExtensions.fileExtensionToMimeType.json;
    const api = stardogQuery.execute;
    const options: any = { reasoning };
    if (namedGraphs && namedGraphs.length) {
      options['graph-uri'] = namedGraphs;
    }
    if (timeout) {
      options.timeout = timeout;
    }
    if (schema && schema !== DEFAULT_REASONING_SCHEMA) {
      options.schema = schema;
    }
    const args = [db, query, acceptType, options];

    return request({
      api,
      args,
      loading: NotebookActionType.EXECUTE_DESCRIBE_QUERY_FOR_IRI_ATTEMPT,
      success: NotebookActionType.EXECUTE_DESCRIBE_QUERY_FOR_IRI_SUCCESS,
      failure: NotebookActionType.EXECUTE_DESCRIBE_QUERY_FOR_IRI_FAILURE,
      action: {
        iri,
        telemetry: {
          query_length: query.length,
        },
      },
    });
  },
  explainQueryForNote: ({
    db,
    query,
    queryId,
    limit,
    namedGraphs,
    reasoning = false,
    schema,
    timeout,
    noteId,
    profile,
    queryLanguageId,
    acceptType,
  }: {
    db: string;
    query: string;
    queryId?: string;
    limit: number;
    namedGraphs?: string[];
    reasoning: boolean;
    schema?: string;
    timeout?: string;
    noteId: string;
    profile: boolean;
    queryLanguageId: LanguageId;
    acceptType: string;
  }) => {
    let api;
    let args: any[];

    if (queryLanguageId === LanguageId.GRAPHQL) {
      api = stardogQuery.graphql.execute;
      const variables: any = {
        '@reasoning': reasoning,
        '@explain': true,
        '@explainAsJson': false,
      };
      const options: any = {};
      if (timeout) {
        options.timeout = timeout;
      }
      if (schema && schema !== DEFAULT_REASONING_SCHEMA) {
        options.schema = schema;
      }
      args = [db, query, variables, options];
    } else {
      api = stardogQuery.explain;
      const options: any = { reasoning };
      if (profile) {
        options.profile = true;
      }
      if (queryId) {
        options.id = queryId;
      }
      if (limit) {
        options.limit = limit;
      }
      if (namedGraphs && namedGraphs.length) {
        options['graph-uri'] = namedGraphs;
      }
      if (timeout) {
        options.timeout = timeout;
      }
      if (schema && schema !== DEFAULT_REASONING_SCHEMA) {
        options.schema = schema;
      }
      args = [db, query, acceptType, options];
    }

    return request({
      api,
      args,
      action: {
        noteId,
        queryId,
        profile,
        queryLanguageId,
        explanationLanguageId: LanguageId.TXT,
      },
      loading: NotebookActionType.EXPLAIN_QUERY_FOR_NOTE_ATTEMPT,
      success: NotebookActionType.EXPLAIN_QUERY_FOR_NOTE_SUCCESS,
      failure: NotebookActionType.EXPLAIN_QUERY_FOR_NOTE_FAILURE,
    });
  },
};

const notebookActionTypeMap = {
  ...notebookActionCreators,
  ...notebookStardogRequestDispatchers,
};

export type NotebookAction = ActionCreatorAction<typeof notebookActionTypeMap>;
