import { IconName, Intent, ToastProps } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import omit from 'lodash.omit';
import { Reducer } from 'redux';
import uuid from 'uuid/v4';
import { isEqual } from 'vet-bones/bones/utils';

import { ConnectionActionType } from 'src/common/actions/connection/connectionActionCreators';
import { DataActionType } from 'src/common/actions/data/dataActionCreators';
import { DatabasesActionType } from 'src/common/actions/databases/databasesActionCreators';
import { FileSystemActionType } from 'src/common/actions/fileSystem/fileSystemActionCreators';
import { ModelsActionType } from 'src/common/actions/models/modelsActionCreators';
import { NotebookActionType } from 'src/common/actions/notebook/notebookActionCreators';
import { NotificationsActionType } from 'src/common/actions/notifications/notificationsActionCreators';
import { QueriesActionType } from 'src/common/actions/queries/queriesActionCreators';
import { SchemasActionType } from 'src/common/actions/schemas/schemasActionCreators';
import { SecurityActionType } from 'src/common/actions/security/securityActionCreators';
import { StarchartActionType } from 'src/common/actions/starchart/starchartActionCreators';
import { StoredQueriesActionType } from 'src/common/actions/storedQueries/storedQueriesActionCreators';
import {
  StudioAction,
  StudioActionType,
} from 'src/common/actions/StudioAction';
import { VirtualGraphsActionType } from 'src/common/actions/virtualGraphs/virtualGraphsActionCreators';
import { VisualizationsActionType } from 'src/common/actions/visualizations/visualizationsActionCreators';
import { ActionableNotificationMessageProps } from 'src/common/components/ActionableNotificationMessage';
import { USER_PREFERENCES_NOTE_ID } from 'src/common/constants/notebook/noteConstants';
import { QueryTypeIdentifier } from 'src/common/constants/QueryType';
import { CreateModelDialogType } from 'src/common/store/models/ModelsState';
import { NoteState } from 'src/common/store/notebook/NoteState';
import { NotificationsState } from 'src/common/store/notifications/NotificationsState';
import { Query } from 'src/common/store/queries/QueriesState';
import { commaSeparatedString } from 'src/common/utils/commaSeparatedString';

const initialState = new NotificationsState();

const dataIcon = IconNames.DATA_LINEAGE;
const databaseIcon = IconNames.DATABASE;
const modelsIcon = IconNames.DIAGRAM_TREE;
const virtualGraphIcon = IconNames.JOIN_TABLE;
const roleIcon = IconNames.SHIELD;
const userIcon = IconNames.USER;

export type NotificationsReducer = Reducer<NotificationsState>;

// Given previous state and a note id, returns a new state with the "killed
// query" metadata for that note id removed.
const getPartialNextStatePostQueryKill = (
  prevState: NotificationsState,
  noteId: NoteState['id']
) => {
  // Remove the query id being killed for this note id; leave all others.
  const { [noteId]: _removed, ...nextQueriesBeingKilledByNoteId } =
    prevState.metadata.queriesBeingKilledByNoteId;

  const { [noteId]: _bulk_removed, ...nextBulkQueriesBeingKilledByNoteId } =
    prevState.metadata.bulkQueriesBeingKilledByNoteId;

  return {
    ...prevState,
    metadata: {
      ...prevState.metadata,
      queriesBeingKilledByNoteId: nextQueriesBeingKilledByNoteId,
      bulkQueriesBeingKilledByNoteId: nextBulkQueriesBeingKilledByNoteId,
    },
  };
};

const getStateWithQueuedNotification = (
  prevState: NotificationsState,
  type: StudioActionType,
  notification: ToastProps,
  id: string = uuid(),
  data: { [key: string]: any } = {}
): NotificationsState => {
  const isFailure = notification.intent === Intent.DANGER;

  if (isFailure) {
    console.error(notification.message);
  }

  const notifications = [...prevState.notifications];

  const prevNotification = notifications[notifications.length - 1];
  const nextNotification = { ...notification, id, type, data };

  if (isEqual(omit(prevNotification, 'id'), omit(nextNotification, 'id'))) {
    notifications.splice(notifications.length - 1, 1, nextNotification);
  } else {
    notifications.push(nextNotification);
  }

  return {
    ...prevState,
    notifications,
  };
};

const buildStardogErrorMessage = (action) => {
  const errorMessage = action?.payload?.response?.errorMessage;
  if (errorMessage) {
    return errorMessage;
  }

  const body = action?.payload?.response?.body;
  const failureCode = body?.code;
  const failureMessage = typeof body === 'string' ? body : body?.message;
  const statusText = action?.payload?.response?.statusText ?? '';

  let returnMessage: string;
  if (failureCode) {
    if (failureMessage) {
      returnMessage = statusText
        ? `${failureCode}: ${failureMessage} (${statusText})`
        : `${failureCode}: ${failureMessage}`;
    } else {
      returnMessage = statusText
        ? `${statusText} (Code: ${failureCode})`
        : `(Code: ${failureCode})`;
    }
  } else if (failureMessage) {
    returnMessage = statusText
      ? `${failureMessage} (${statusText})`
      : failureMessage;
  } else {
    returnMessage = statusText;
  }

  return (
    returnMessage ||
    `Something went wrong and we're not sure what it is. Contact your system admin to review server logs for more details if the issue persists.`
  );
};

class NotificationPayload {
  message: string;

  icon: IconName;
}

const buildStardogErrorPayload = (action, actionSubject: string) => {
  let errorMessage: string;
  let errorIcon: IconName = IconNames.ERROR;

  const responseStatus = action?.payload?.response?.status;
  if (responseStatus && responseStatus === 403) {
    errorMessage = `You are not authorized to ${actionSubject}. Contact your system admin to ensure you have the correct user privileges.`;
    errorIcon = IconNames.LOCK;
  } else {
    errorMessage = buildStardogErrorMessage(action);
  }

  return {
    message: errorMessage,
    icon: errorIcon,
  } as NotificationPayload;
};

// Note: Keep an eye out for user-feedback on "WARNING" intent colors.

export const notificationsReducer: NotificationsReducer = (
  prevState = initialState,
  action: StudioAction
) => {
  const actionPayload = action.payload as any;
  // Any action set to `silent` in this way will not queue a notification.
  if (
    actionPayload &&
    (actionPayload.silent ||
      (actionPayload.action && actionPayload.action.silent))
  ) {
    return prevState;
  }

  switch (action.type) {
    case NotificationsActionType.UNQUEUE_NOTIFICATION:
      return {
        ...prevState,
        notifications: action.payload.id
          ? prevState.notifications.filter(
              (notification) => notification.id !== action.payload.id
            )
          : prevState.notifications.slice(1),
      };
    case NotificationsActionType.QUEUE_NOTIFICATION:
      return getStateWithQueuedNotification(
        prevState,
        action.type,
        action.payload
      );
    case DatabasesActionType.GET_STATUS_FAILURE: {
      const errorPayload = buildStardogErrorPayload(
        action,
        'get server status'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to get server status: ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case DatabasesActionType.CREATE_DATABASE_FAILURE: {
      const errorPayload = buildStardogErrorPayload(
        action,
        'create a database'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to create "${action.payload.args[0]}": ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case DatabasesActionType.OPTIMIZE_DATABASE_SUCCESS:
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Optimized database "${action.payload.args[0]}".`,
        icon: databaseIcon,
        intent: Intent.SUCCESS,
      });
    case DatabasesActionType.OPTIMIZE_DATABASE_FAILURE: {
      const errorPayload = buildStardogErrorPayload(
        action,
        'optimize a database'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed optimize database "${action.payload.args[0]}": ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case DatabasesActionType.ADD_ICV_CONSTRAINTS_SUCCESS:
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Added constraints to "${action.payload.args[0]}"`,
        icon: IconNames.ADD,
        intent: Intent.SUCCESS,
      });
    case DatabasesActionType.ADD_ICV_CONSTRAINTS_FAILURE: {
      const errorPayload = buildStardogErrorPayload(
        action,
        'add constraints to a database'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to add constraints to "${action.payload.args[0]}": ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case DatabasesActionType.REMOVE_ICV_CONSTRAINTS_SUCCESS:
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Removed constraints from "${action.payload.args[0]}"`,
        icon: IconNames.REMOVE,
        intent: Intent.WARNING,
      });
    case DatabasesActionType.REMOVE_ICV_CONSTRAINTS_FAILURE: {
      const errorPayload = buildStardogErrorPayload(
        action,
        'remove constraints from a database'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to remove constraint from "${action.payload.args[0]}": ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case DatabasesActionType.TOGGLE_DATABASE_ONLINE_SUCCESS:
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Database "${action.payload.args[0]}" is now ${
          action.payload.action.isComingOnline ? 'online' : 'offline'
        }.`,
        icon: databaseIcon,
        intent: action.payload.action.isComingOnline
          ? Intent.SUCCESS
          : Intent.WARNING,
      });
    case DatabasesActionType.TOGGLE_DATABASE_ONLINE_FAILURE: {
      const errorPayload = buildStardogErrorPayload(
        action,
        'toggle the status of a database'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: errorPayload.message,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case VirtualGraphsActionType.REQUEST_OPTIONS_FOR_VIRTUAL_GRAPHS_FAILURE: {
      const errorPayload = buildStardogErrorPayload(
        action,
        'request properties for Virtual Graphs'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to request properties for Virtual Graphs: ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case VirtualGraphsActionType.REQUEST_OPTIONS_FOR_VIRTUAL_GRAPH_SUCCESS: {
      const responseType = action.payload?.response?.body?.options?.type;
      const id = action.payload.args[0] as string;
      if (!responseType) {
        return getStateWithQueuedNotification(prevState, action.type, {
          message: `There was a problem getting the database type for the Virtual Graph "${id}".`, // Any more details of the problem? A possible next step to resolve the issue.
          icon: IconNames.ERROR,
          intent: Intent.DANGER,
        });
      }
    }
    case VirtualGraphsActionType.CREATE_VIRTUAL_GRAPH_SUCCESS:
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Virtual Graph "${action.payload.args[0]}" created.`,
        icon: virtualGraphIcon,
        intent: Intent.SUCCESS,
      });
    case VirtualGraphsActionType.CREATE_VIRTUAL_GRAPH_FAILURE: {
      const errorPayload = buildStardogErrorPayload(
        action,
        'create a Virtual Graph'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to create Virtual Graph "${action.payload.args[0]}": ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case VirtualGraphsActionType.DELETE_VIRTUAL_GRAPH_SUCCESS:
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Virtual Graph "${action.payload.vgId}" deleted.`,
        icon: IconNames.DELETE,
        intent: Intent.WARNING,
      });
    case VirtualGraphsActionType.DELETE_VIRTUAL_GRAPH_FAILURE: {
      const errorPayload = buildStardogErrorPayload(
        action,
        'delete a Virtual Graph'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to delete Virtual Graph "${action.payload.deletedGraph.id}": ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case VirtualGraphsActionType.REQUEST_MAPPINGS_FOR_VIRTUAL_GRAPH_FAILURE: {
      const errorPayload = buildStardogErrorPayload(
        action,
        'request mappings for a Virtual Graph'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to request mappings for Virtual Graph "${action.payload.args[0]}": ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case VirtualGraphsActionType.REQUEST_VIRTUAL_GRAPHS_LIST_FAILURE:
    case VirtualGraphsActionType.REQUEST_VIRTUAL_GRAPHS_LIST_INFO_FAILURE: {
      const errorPayload = buildStardogErrorPayload(
        action,
        'request Virtual Graphs'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to request Virtual Graphs: ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case VirtualGraphsActionType.UPDATE_VIRTUAL_GRAPH_SUCCESS:
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Virtual Graph "${action.payload.args[0]}" updated.`,
        icon: virtualGraphIcon,
        intent: Intent.SUCCESS,
      });
    case VirtualGraphsActionType.UPDATE_VIRTUAL_GRAPH_FAILURE: {
      const errorPayload = buildStardogErrorPayload(
        action,
        'update a Virtual Graph'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to update Virtual Graph "${action.payload.args[0]}": ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case DatabasesActionType.DROP_DATABASE_SUCCESS:
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Database "${action.payload.args[0]}" has been dropped.`,
        icon: databaseIcon,
        intent: Intent.WARNING,
      });
    case DatabasesActionType.DROP_DATABASE_FAILURE: {
      const errorPayload = buildStardogErrorPayload(action, 'drop a database');
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Database "${action.payload.args[0]}" could not be dropped: ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case DatabasesActionType.CREATE_DATABASE_SUCCESS:
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Database "${action.payload.args[0]}" created.`,
        icon: databaseIcon,
        intent: Intent.SUCCESS,
      });
    case NotebookActionType.EXPLAIN_QUERY_FOR_NOTE_FAILURE: {
      const errorPayload = buildStardogErrorPayload(
        action,
        'show a Query Plan'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `The Show Query Plan request failed: ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case DatabasesActionType.UPDATE_DATABASE_DETAILS_SUCCESS:
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Database "${action.payload.args[0]}" properties updated`,
        intent: Intent.SUCCESS,
        icon: databaseIcon,
      });
    case DatabasesActionType.UPDATE_DATABASE_DETAILS_FAILURE: {
      const errorPayload = buildStardogErrorPayload(
        action,
        'make changes to a database'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to update database "${action.payload.args[0]}": ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case DatabasesActionType.ADD_GRAPHQL_SCHEMA_FOR_DATABASE_FAILURE: {
      const { graphQlSchemaId, databaseId } = action.payload.action;
      const errorPayload = buildStardogErrorPayload(
        action,
        'add GraphQL schemas to a database'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to add GraphQL schema "${graphQlSchemaId}" to database "${databaseId}": ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case DatabasesActionType.UPDATE_GRAPHQL_SCHEMA_FOR_DATABASE_FAILURE: {
      const { graphQlSchemaId, databaseId, failureResponse } = action.payload;
      const errorPayload = buildStardogErrorPayload(
        failureResponse,
        'update GraphQL schemas for a database'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to update GraphQL schema "${graphQlSchemaId}" for database "${databaseId}": ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case DatabasesActionType.UPDATE_GRAPHQL_SCHEMA_FOR_DATABASE_SUCCESS: // intentional fallthrough
    case DatabasesActionType.ADD_GRAPHQL_SCHEMA_FOR_DATABASE_SUCCESS: {
      const { graphQlSchemaId, databaseId } =
        action.type ===
        DatabasesActionType.UPDATE_GRAPHQL_SCHEMA_FOR_DATABASE_SUCCESS
          ? action.payload
          : action.payload.action;
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Saved GraphQL schema "${graphQlSchemaId}" to database "${databaseId}".`,
        icon: databaseIcon,
        intent: Intent.SUCCESS,
      });
    }
    case DatabasesActionType.UPDATE_BI_SCHEMA_MAPPING_FAILURE: {
      const { databaseId, exception } = action.payload;
      const message = `Failed to update BI schema mapping for database "${databaseId}": ${exception.message}`;
      console.error(message);
      return getStateWithQueuedNotification(prevState, action.type, {
        message,
        icon: databaseIcon,
        intent: Intent.DANGER,
      });
    }
    case DatabasesActionType.UPDATE_BI_SCHEMA_MAPPING_SUCCESS: {
      const { databaseId } = action.payload;
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Updated BI schema mapping for database "${databaseId}".`,
        icon: databaseIcon,
        intent: Intent.SUCCESS,
      });
    }
    case DatabasesActionType.REQUEST_BI_SCHEMA_MAPPING_FAILURE: {
      const { databaseId, failureResponse } = action.payload as any;
      const errorPayload = buildStardogErrorPayload(
        failureResponse,
        'request BI schema mappings'
      );
      const message = `Failed to request BI schema mapping for database "${databaseId}": ${errorPayload.message}`;
      console.error(message);
      return getStateWithQueuedNotification(prevState, action.type, {
        message,
      });
    }
    case ModelsActionType.SAVE_MODEL_TEXT_SUCCESS:
    case ModelsActionType.SAVE_SELECTED_MODEL_SUCCESS: {
      const { schemaName } = action.payload;
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Saved model "${schemaName}".`,
        icon: modelsIcon,
        intent: Intent.SUCCESS,
      });
    }
    case ModelsActionType.SAVE_MODEL_TEXT_FAILURE:
    case ModelsActionType.SAVE_SELECTED_MODEL_FAILURE: {
      const { schemaName, exception } = action.payload;
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to save model "${schemaName}": ${exception.message}.`,
        icon: modelsIcon,
        intent: Intent.DANGER,
      });
    }
    case ModelsActionType.SHOW_MODEL_EDIT_DISABLED_NOTIFICATION: {
      const { message } = action.payload;
      return getStateWithQueuedNotification(prevState, action.type, {
        message,
        icon: modelsIcon,
        intent: Intent.WARNING,
        timeout: 0,
      });
    }
    case ModelsActionType.SHOW_MODEL_TEXT_EDITOR_DISABLED_SYNTAX_NOTIFICATION: {
      const { buttonAction, closeNotification, schemaName } = action.payload;
      const message = `Syntax features such as highlighting and folding have been disabled for model '${schemaName}' for better performance.`;
      const notificationId = uuid();
      const actionableProps: ActionableNotificationMessageProps = {
        actions: [
          {
            onClick: buttonAction,
            text: 'Forcefully enable Features',
          },
        ],
        closeNotification,
        message,
        notificationId,
      };
      return getStateWithQueuedNotification(
        prevState,
        action.type,
        {
          className: 'sd-toast-actionable',
          message,
          icon: modelsIcon,
          intent: Intent.NONE,
          timeout: 0,
        },
        notificationId,
        { schemaName, actionableProps }
      );
    }
    case SchemasActionType.CREATE_SCHEMA_FOR_DATABASE_SUCCESS: {
      const { databaseId, schemaName, dialogType } = action.payload;
      const verb =
        dialogType === CreateModelDialogType.CONFIRM
          ? 'Set namespace for model'
          : 'Created model';
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `${verb} "${schemaName}" for database "${databaseId}".`,
        icon: modelsIcon,
        intent: Intent.SUCCESS,
      });
    }
    case SchemasActionType.CREATE_SCHEMA_FOR_DATABASE_FAILURE: {
      const { databaseId, schemaName, dialogType, failureResponse } =
        action.payload;
      const verb =
        dialogType === CreateModelDialogType.CONFIRM
          ? 'set namespace for model'
          : 'create model';
      const errorPayload = buildStardogErrorPayload(
        failureResponse,
        `${verb}s for a database`
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to ${verb} "${schemaName}" for database "${databaseId}": ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case SchemasActionType.REMOVE_SCHEMA_FROM_DATABASE_SUCCESS: {
      const { databaseId, schemaName } = action.payload;
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Removed model "${schemaName}" from database "${databaseId}".`,
        icon: modelsIcon,
        intent: Intent.WARNING,
      });
    }
    case SchemasActionType.REMOVE_SCHEMA_FROM_DATABASE_FAILURE: {
      const { databaseId, schemaName, failureResponse } = action.payload;
      const errorPayload = buildStardogErrorPayload(
        failureResponse,
        'remove models from a database'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to remove model "${schemaName}" from database "${databaseId}": ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case SecurityActionType.ADD_PERMISSION_FOR_ROLE_FAILURE: {
      const errorPayload = buildStardogErrorPayload(
        action,
        'add permissions to a role'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to add permission to role "${action.payload.args[0]}": ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case SecurityActionType.ADD_PERMISSION_FOR_ROLE_SUCCESS:
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Added ${action.payload.args[1].action} permission to role "${action.payload.args[0]}".`,
        icon: roleIcon,
        intent: Intent.SUCCESS,
      });
    case SecurityActionType.ADD_ROLE_FAILURE: {
      const errorPayload = buildStardogErrorPayload(action, 'create a role');
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to add role "${action.payload.args[0].name}": ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case SecurityActionType.DELETE_ROLE_FAILURE: {
      const errorPayload = buildStardogErrorPayload(action, 'delete a role');
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to delete role "${action.payload.args[0]}": ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case SecurityActionType.DELETE_ROLE_SUCCESS:
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Role "${action.payload.args[0]}" deleted.`,
        icon: roleIcon,
        intent: Intent.WARNING,
      });
    case SecurityActionType.ADD_ROLE_SUCCESS:
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Role "${action.payload.args[0].name}" created.`,
        icon: roleIcon,
        intent: Intent.SUCCESS,
      });
    case SecurityActionType.ADD_USER_FAILURE: {
      const errorPayload = buildStardogErrorPayload(action, 'create a user');
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to create user "${action.payload.args[0].username}": ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case SecurityActionType.ADD_USER_SUCCESS:
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `User "${action.payload.args[0].username}" created.`,
        icon: userIcon,
        intent: Intent.SUCCESS,
      });
    case SecurityActionType.CHANGE_PASSWORD_FOR_USER_FAILURE: {
      const errorPayload = buildStardogErrorPayload(
        action,
        'change the password for a user'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `The password for "${action.payload.args[0]}" could not be updated: ${errorPayload.message}.`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case SecurityActionType.CHANGE_PASSWORD_FOR_USER_SUCCESS:
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `The password for "${action.payload.args[0]}" was updated.`,
        icon: userIcon,
        intent: Intent.SUCCESS,
      });
    case SecurityActionType.ADD_PERMISSION_FOR_USER_FAILURE: {
      const errorPayload = buildStardogErrorPayload(
        action,
        'add permissions to a user'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to add permission to user "${action.payload.args[0]}": ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case SecurityActionType.REQUEST_DETAILS_FOR_USER_FAILURE: // intentional fallthrough
    case SecurityActionType.REQUEST_DETAILS_FOR_ROLE_FAILURE: {
      const entityType =
        action.type === SecurityActionType.REQUEST_DETAILS_FOR_ROLE_FAILURE
          ? 'role'
          : 'user';
      const entityName = action.payload.args[0];
      const actionSubject = `retrieve details for the ${entityType} ${entityName}`;
      const errorPayload = buildStardogErrorPayload(action, actionSubject);
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to ${actionSubject}: ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case SecurityActionType.REQUEST_ROLES_FAILURE: // intentional fallthrough
    case SecurityActionType.REQUEST_USERS_FAILURE: {
      const entity =
        action.type === SecurityActionType.REQUEST_ROLES_FAILURE
          ? 'roles'
          : 'users';
      const actionSubject = `retrieve ${entity}`;
      const errorPayload = buildStardogErrorPayload(action, actionSubject);
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to ${actionSubject}: ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case ConnectionActionType.SET_CONNECTION_SUCCESS:
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `You've connected to the Stardog endpoint!`,
        intent: Intent.SUCCESS,
        icon: IconNames.OFFLINE,
      });
    case ConnectionActionType.SET_CONNECTION_FAILURE: {
      // Special sauce for connection failures.
      let connectionErrorMessage: string;
      let connectionErrorIcon: IconName = IconNames.ERROR;
      switch (buildStardogErrorMessage(action)) {
        case 'net::ERR_CONNECTION_REFUSED':
          connectionErrorMessage = `The connection was refused. Make sure your Stardog endpoint is online and your URL is correct.`;
          connectionErrorIcon = IconNames.BAN_CIRCLE;
          break;
        case 'DISCONNECTED':
          connectionErrorMessage = `Oops! You were disconnected from your Stardog endpoint. Try connecting again.`;
          connectionErrorIcon = IconNames.OFFLINE;
          break;
        case 'Invalid Credentials':
        case 'Unauthorized':
        case '401':
          connectionErrorMessage = `Your username and/or password are incorrect.`;
          connectionErrorIcon = IconNames.LOCK;
          break;
        case 'Request Aborted':
          connectionErrorMessage = `The request was aborted. Please check the status of your Stardog endpoint.`;
          connectionErrorIcon = IconNames.BAN_CIRCLE;
          break;
        default:
          connectionErrorMessage = `The Stardog Knowledge Graph endpoint is not responding. Please check the status of your Stardog endpoint.`;
      }
      return getStateWithQueuedNotification(prevState, action.type, {
        message: connectionErrorMessage,
        icon: connectionErrorIcon,
        intent: Intent.DANGER,
      });
    }
    case DataActionType.BRING_DATA_SOURCE_ONLINE_SUCCESS: {
      const id = action.payload.action.dataSourceId;
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Data Source "${id}" is now connected.`,
        icon: dataIcon,
        intent: Intent.SUCCESS,
      });
    }
    case DataActionType.BRING_DATA_SOURCE_ONLINE_FAILURE: {
      const id = action.payload.action.dataSourceId;
      const errorPayload = buildStardogErrorPayload(
        action,
        'connect to data source'
      );
      // use warning intent when the Data Source is already online
      const intent = (action.payload?.response?.body?.message ?? '').endsWith(
        'is already online'
      )
        ? Intent.WARNING
        : Intent.DANGER;
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to connect to "${id}": ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent,
      });
    }
    case DataActionType.CREATE_DATA_SOURCE_SUCCESS:
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Data Source "${action.payload.args[0]}" created.`,
        icon: dataIcon,
        intent: Intent.SUCCESS,
      });
    case DataActionType.CREATE_DATA_SOURCE_FAILURE: {
      const errorPayload = buildStardogErrorPayload(
        action,
        'create a data source'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to create Data Source "${action.payload.args[0]}": ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case DataActionType.DELETE_DATA_SOURCE_SUCCESS:
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Data Source "${action.payload.args[0]}" deleted.`,
        icon: dataIcon,
        intent: Intent.SUCCESS,
      });
    case DataActionType.DELETE_DATA_SOURCE_FAILURE: {
      const errorPayload = buildStardogErrorPayload(
        action,
        'delete a data source'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to delete Data Source "${action.payload.args[0]}": ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case DataActionType.SHARE_DATA_SOURCE_SUCCESS: {
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Data Source "${action.payload.action.dataSourceName}" shared successfully.`,
        icon: dataIcon,
        intent: Intent.SUCCESS,
      });
    }
    case DataActionType.SHARE_DATA_SOURCE_FAILURE: {
      const errorPayload = buildStardogErrorPayload(
        action,
        'share a data source'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to share Data Source "${action.payload.action.dataSourceName}": ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case DataActionType.UPDATE_DATA_SOURCE_SUCCESS:
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Data Source "${action.payload.args[0]}" updated.`,
        icon: dataIcon,
        intent: Intent.SUCCESS,
      });
    case DataActionType.UPDATE_DATA_SOURCE_FAILURE: {
      const errorPayload = buildStardogErrorPayload(
        action,
        'update a data source'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to update Data Source "${action.payload.args[0]}": ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case DataActionType.SHOW_DATA_EDIT_DISABLED_NOTIFICATION: {
      const { message } = action.payload;
      return getStateWithQueuedNotification(prevState, action.type, {
        message,
        icon: dataIcon,
        intent: Intent.WARNING,
        timeout: 0,
      });
    }
    case DatabasesActionType.ADD_DATA_SUCCESS:
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Data loaded to "${action.payload.databaseId}"`,
        icon: databaseIcon,
        intent: Intent.SUCCESS,
      });
    case DatabasesActionType.ADD_DATA_FAILURE: {
      const errorPayload = buildStardogErrorPayload(
        action,
        'load data to a database'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to load data to "${action.payload.databaseId}": ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case DatabasesActionType.REMOVE_DATA_SUCCESS:
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Data removed from ${action.payload.databaseId}`,
        icon: databaseIcon,
        intent: Intent.WARNING,
      });
    case DatabasesActionType.REMOVE_DATA_FAILURE: {
      const errorPayload = buildStardogErrorPayload(
        action,
        'remove data from a database'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to remove data from "${action.payload.databaseId}": ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case StoredQueriesActionType.GET_STORED_QUERIES_FAILURE: {
      const errorPayload = buildStardogErrorPayload(
        action,
        'retrieve stored queries'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to retrieve stored queries: ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case StoredQueriesActionType.STORE_QUERY_SUCCESS:
      // real-world recreation pending
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `The query "${action.payload.args[0].name}" was stored on the server.`,
        icon: IconNames.BOOKMARK,
        intent: Intent.SUCCESS,
      });
    case StoredQueriesActionType.STORE_QUERY_FAILURE: {
      // The syntax errors that return here don't go into a results pane so they don't appear for long. Will need a longer term fix for this.
      const errorPayload = buildStardogErrorPayload(action, 'store a query');
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to store query: ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case StoredQueriesActionType.UPDATE_QUERY_SUCCESS:
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `The stored query '${action.payload.args[0].name}' was updated.`, // stored query or server stored query?
        icon: IconNames.BOOKMARK,
        intent: Intent.SUCCESS,
      });
    case StoredQueriesActionType.UPDATE_QUERY_FAILURE: {
      const errorPayload = buildStardogErrorPayload(
        action,
        'update the stored query'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to update stored query '${action.payload.args[0].name}': ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case StoredQueriesActionType.DELETE_STORED_QUERY_FAILURE: {
      const errorPayload = buildStardogErrorPayload(
        action,
        'delete the stored query'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to delete stored query '${action.payload.action.queryName}': ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case StoredQueriesActionType.DELETE_STORED_QUERY_SUCCESS:
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `The stored query "${action.payload.action.queryName}" was deleted`,
        icon: IconNames.BOOKMARK,
        intent: Intent.WARNING,
      });
    case DatabasesActionType.VALIDATE_CONSTRAINTS_FAILURE: {
      // why "Does your buffer contain invalid constraints?"
      const errorPayload = buildStardogErrorPayload(
        action,
        'validate constraints'
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Validation request failed. Have you entered invalid constraints? (Error: ${errorPayload.message})`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case NotebookActionType.ASK_VOICEBOX_FAILURE: {
      const { errorMessage } = action.payload;
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Voicebox error: ${errorMessage}`,
        intent: Intent.DANGER,
      });
    }
    case NotebookActionType.DOWNLOADED_VOICEBOX_CONVERSATION: {
      const { filename } = action.payload;
      return getStateWithQueuedNotification(prevState, action.type, {
        className: 'sd-toast-actionable',
        message: `Saved conversation to ${filename}`,
        icon: IconNames.DOWNLOAD,
        intent: Intent.SUCCESS,
        timeout: 6000,
      });
    }
    case NotebookActionType.EXECUTE_QUERY_FOR_NOTE_FAILURE: {
      const { payload } = action as any; // there are two possible payloads for this action
      const noteId = payload.noteId || payload.action.noteId;

      // notifications for bulk kill queries are handled in `KILL_QUERIES_SUCCESS`
      // so we can silently remove it from the list
      if (prevState.metadata.bulkQueriesBeingKilledByNoteId[noteId]) {
        return getPartialNextStatePostQueryKill(prevState, noteId);
      }

      const queryBeingKilledForNote =
        prevState.metadata.queriesBeingKilledByNoteId[noteId];

      if (queryBeingKilledForNote) {
        // Failure is what we want here -- we killed this query!
        return getStateWithQueuedNotification(
          getPartialNextStatePostQueryKill(prevState, noteId),
          action.type,
          {
            message: `Killed query ${queryBeingKilledForNote}.`,
            intent: Intent.WARNING,
          }
        );
      }
      const errorPayload = buildStardogErrorPayload(action, 'run query');
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to run query: ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case NotebookActionType.EXECUTE_QUERY_FOR_NOTE_SUCCESS: {
      const { noteId } = action.payload.action;
      const notificationData: ToastProps = {
        message: `Executed query in ${action.payload.timeElapsed} ms`,
        icon: IconNames.PLAY,
        intent: Intent.SUCCESS,
      };
      const queryBeingKilledForNote =
        prevState.metadata.queriesBeingKilledByNoteId[noteId];

      return getStateWithQueuedNotification(
        // Be safe: check whether the query was being killed but happened to
        // succeed before the killing completed, and if so, clean up state.
        queryBeingKilledForNote
          ? getPartialNextStatePostQueryKill(prevState, noteId)
          : prevState,
        action.type,
        notificationData
      );
    }
    case NotebookActionType.EXECUTE_DESCRIBE_QUERY_FOR_IRI_FAILURE: {
      // TODO: throw specific erros for this action
      const { iri } = action.payload.action;
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to expand from <${iri}>`,
        intent: Intent.DANGER,
      });
    }
    case NotebookActionType.EXECUTE_DESCRIBE_QUERY_FOR_IRI_SUCCESS: {
      const { iri } = action.payload.action;
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Expanded from <${iri}>`,
        icon: IconNames.GRAPH,
        intent: Intent.SUCCESS,
      });
    }
    case NotebookActionType.LOAD_SANDDANCE_FAILURE: {
      return getStateWithQueuedNotification(prevState, action.type, {
        message: 'Failed to load data for charts. Please try again.',
        icon: IconNames.GRAPH,
        intent: Intent.WARNING,
      });
    }
    case QueriesActionType.KILL_QUERY_ATTEMPT: {
      // Keep some metadata about the query being killed, so that we can show
      // the right notifications.
      const queryId: Query['id'] = action.payload.args[0];
      const { associatedNoteId } = action.payload.action;

      if (prevState.metadata.queriesBeingKilledByNoteId[associatedNoteId]) {
        // no dupes here -- query is already marked as being killed
        return prevState;
      }

      return {
        ...prevState,
        metadata: {
          ...prevState.metadata,
          queriesBeingKilledByNoteId: {
            ...prevState.metadata.queriesBeingKilledByNoteId,
            [associatedNoteId]: queryId,
          },
        },
      };
    }
    case QueriesActionType.KILL_QUERY_FAILURE: {
      const queryId = action.payload.args[0];
      const { associatedNoteId } = action.payload.action;
      const notificationData: ToastProps = {
        message: `Failed to kill query${queryId ? ` ${queryId}` : ''}!`, // Suggest follow-up action?
        intent: Intent.DANGER,
      };
      const queryBeingKilledForNote =
        prevState.metadata.queriesBeingKilledByNoteId[associatedNoteId];

      return getStateWithQueuedNotification(
        queryBeingKilledForNote
          ? getPartialNextStatePostQueryKill(prevState, associatedNoteId)
          : prevState,
        action.type, // Somehow the query just killed wasn't in our metadata, so no cleanup necessary.
        notificationData
      );
    }
    // NOTE: We do NOT remove the query/noteId for queries being killed when
    // `KILL_QUERY_SUCCESS` occurs for a query attached to a note, because
    // the notifications reducer needs to know that the query was just killed
    // WHEN THE QUERY FAILS (so that it can show the appropriate notification).
    // The removal happens, instead, when the query itself fails or succeeds.
    case QueriesActionType.KILL_QUERY_SUCCESS: {
      const queryId = action.payload.args[0];
      const { associatedNoteId } = action.payload.action;

      if (associatedNoteId) {
        return prevState;
      }

      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Killed query ${queryId}.`,
        icon: IconNames.STOP,
        intent: Intent.WARNING,
      });
    }
    case QueriesActionType.KILL_QUERIES_ATTEMPT: {
      // Keep some metadata about the query being killed, so that we can show
      // the right notifications.
      const { associatedNoteIds } = action.payload;

      return Object.entries(associatedNoteIds).reduce(
        (accState, [associatedNoteId, queryId]) => {
          // no dupes here -- query is already marked as being killed
          if (
            accState.metadata.bulkQueriesBeingKilledByNoteId[associatedNoteId]
          ) {
            return accState;
          }

          return {
            ...accState,
            metadata: {
              ...accState.metadata,
              bulkQueriesBeingKilledByNoteId: {
                ...accState.metadata.bulkQueriesBeingKilledByNoteId,
                [associatedNoteId]: queryId,
              },
            },
          };
        },
        prevState
      );
    }
    case QueriesActionType.KILL_QUERIES_FAILURE: {
      const { failureQueryIds, failureAssociatedNoteIds } = action.payload;

      const notificationData: ToastProps = {
        message: `Failed to kill queries ${failureQueryIds.join(', ')}!`,
        icon: IconNames.ERROR,
        intent: Intent.DANGER,
      };

      const partialState = Object.keys(failureAssociatedNoteIds).reduce(
        (accState, associatedNoteId) => {
          if (
            accState.metadata.bulkQueriesBeingKilledByNoteId[associatedNoteId]
          ) {
            return getPartialNextStatePostQueryKill(accState, associatedNoteId);
          }
          // Somehow the query just killed wasn't in our metadata, so no cleanup necessary.
          return accState;
        },
        prevState
      );

      return getStateWithQueuedNotification(
        partialState,
        action.type,
        notificationData
      );
    }
    // NOTE: We do NOT remove the query/noteId for queries being killed when
    // `KILL_QUERY_SUCCESS` occurs for a query attached to a note, because
    // the notifications reducer needs to know that the query was just killed
    // WHEN THE QUERY FAILS (so that it can show the appropriate notification).
    // The removal happens, instead, when the query itself fails or succeeds.
    case QueriesActionType.KILL_QUERIES_SUCCESS: {
      const { successQueryIds } = action.payload;

      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Killed queries ${successQueryIds.join(', ')}.`,
        icon: IconNames.STOP,
        intent: Intent.SUCCESS,
      });
    }
    case NotebookActionType.EXPORT_RESULTS_FOR_NOTE_SUCCESS: {
      const { typeOfQuery, filePath } = action.payload;
      const message = `${
        typeOfQuery === QueryTypeIdentifier.EXPLAIN ||
        typeOfQuery === QueryTypeIdentifier.PROFILE
          ? 'Explanation'
          : 'Query results'
      } exported to ${filePath}`;
      return getStateWithQueuedNotification(prevState, action.type, {
        className: 'sd-toast-actionable',
        message,
        icon: IconNames.DOWNLOAD,
        intent: Intent.SUCCESS,
        timeout: 6000,
      });
    }
    case NotebookActionType.EXPORT_RESULTS_FOR_NOTE_FAILURE: {
      // real-world recreation pending
      const errorPayload = buildStardogErrorPayload(
        action,
        'export explanation results'
      );
      const { typeOfQuery, filePath } = action.payload;
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to export ${
          typeOfQuery === QueryTypeIdentifier.EXPLAIN ||
          typeOfQuery === QueryTypeIdentifier.PROFILE
            ? 'explanation'
            : 'results'
        } ${filePath ? `to ${filePath}` : ''}: ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case DatabasesActionType.EXPORT_NAMESPACES_SUCCESS: {
      const { filePath } = action.payload;
      const message = `Exported namespaces to ${filePath}`;
      return getStateWithQueuedNotification(prevState, action.type, {
        className: 'sd-toast-actionable',
        message,
        icon: IconNames.DOWNLOAD,
        intent: Intent.SUCCESS,
        timeout: 6000,
      });
    }
    case DatabasesActionType.EXPORT_NAMESPACES_FAILURE: {
      // real-world recreation pending
      const errorPayload = buildStardogErrorPayload(
        action,
        'export namespaces'
      );
      const { databaseId } = action.payload;
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to save namespaces for ${databaseId}: ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case DatabasesActionType.EXPORT_DATABASE_SUCCESS: {
      const { databaseId, filePath, responseMessage } = action.payload;
      const message =
        responseMessage ||
        `Successfully exported database "${databaseId}"${
          filePath ? ` to "${filePath}"` : ''
        }`;
      return getStateWithQueuedNotification(prevState, action.type, {
        className: 'sd-toast-actionable',
        message,
        icon: IconNames.DOWNLOAD,
        intent: Intent.SUCCESS,
        timeout: 6000,
      });
    }
    case DatabasesActionType.EXPORT_DATABASE_FAILURE: {
      const { databaseId } = action.payload;
      const errorPayload = buildStardogErrorPayload(
        action,
        `export database ${databaseId}`
      );
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to export database "${databaseId}": ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case VisualizationsActionType.EXPORT_VISUALIZATION_TO_IMAGE_SUCCESS: {
      const { filePath } = action.payload;
      const message = `Exported visualization to ${filePath}`;
      return getStateWithQueuedNotification(prevState, action.type, {
        className: 'sd-toast-actionable',
        message,
        icon: IconNames.DOWNLOAD,
        intent: Intent.SUCCESS,
        timeout: 6000,
      });
    }
    case VisualizationsActionType.EXPORT_VISUALIZATION_TO_IMAGE_FAILURE: {
      // TODO: throw specific erros for this action
      const { exportFor, wasCancelled } = action.payload;
      if (wasCancelled) {
        return getStateWithQueuedNotification(prevState, action.type, {
          message: `Cancelled export visualization for ${exportFor}`,
          icon: IconNames.ERROR,
          intent: Intent.WARNING,
        });
      }
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to export visualization for ${exportFor}`,
        icon: IconNames.ERROR,
        intent: Intent.DANGER,
      });
    }
    case DatabasesActionType.IMPORT_NAMESPACES_SUCCESS: {
      const { response, args } = action.payload;
      const [databaseId] = args;
      const { numImportedNamespaces } = response?.body || {};
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Successfully imported ${numImportedNamespaces} namespaces into ${databaseId}`,
        intent: Intent.SUCCESS,
      });
    }
    case DatabasesActionType.IMPORT_NAMESPACES_FAILURE: {
      const errorPayload = buildStardogErrorPayload(
        action,
        'import namespaces'
      );
      const [databaseId] = action.payload.args;
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to import namespaces into ${databaseId}. (Error: ${errorPayload.message})`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case VisualizationsActionType.SAVE_VISUALIZATION_LAYOUT_SUCCESS: {
      const { filePath } = action.payload;
      const message = `Saved layout to ${filePath}`;
      return getStateWithQueuedNotification(prevState, action.type, {
        className: 'sd-toast-actionable',
        message,
        icon: IconNames.DOWNLOAD,
        intent: Intent.SUCCESS,
        timeout: 6000,
      });
    }
    case VisualizationsActionType.SAVE_VISUALIZATION_LAYOUT_FAILURE: {
      // TODO: throw specific erros for this action
      const { layoutFor } = action.payload;
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to save layout for ${layoutFor}`,
        icon: IconNames.ERROR,
        intent: Intent.DANGER,
      });
    }
    case VisualizationsActionType.LOAD_VISUALIZATION_LAYOUT_SUCCESS: {
      const { filePath } = action.payload;
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Loaded layout from ${filePath}`,
        icon: IconNames.UPLOAD,
        intent: Intent.SUCCESS,
      });
    }
    case VisualizationsActionType.LOAD_VISUALIZATION_LAYOUT_FAILURE: {
      // TODO: throw specific erros for this action
      const { layoutFor } = action.payload;
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to load layout for ${layoutFor}`,
        icon: IconNames.ERROR,
        intent: Intent.DANGER,
      });
    }
    case StarchartActionType.REQUEST_STARCHART_SOURCES_DATA_FAILURE: {
      const errorPayload = buildStardogErrorPayload(action, 'run query');
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to load Provenance for "${action.payload.databaseId}": ${errorPayload.message}`,
        icon: errorPayload.icon,
        intent: Intent.DANGER,
      });
    }
    case ModelsActionType.REQUEST_MODEL_CONSTRAINTS_REPORT_FAILURE: {
      const { namedGraphsForReport } = action.payload.action;
      const errorMessage = buildStardogErrorMessage(action);
      const message = `Failed to get the report for "${commaSeparatedString(
        namedGraphsForReport
      )}": ${errorMessage}`;
      console.error(message);
      return getStateWithQueuedNotification(prevState, action.type, {
        message,
        icon: modelsIcon,
        intent: Intent.DANGER,
      });
    }
    case ModelsActionType.SAVE_MODEL_CONSTRAINTS_REPORT_FAILURE: {
      const { schemaName } = action.payload;
      return getStateWithQueuedNotification(prevState, action.type, {
        message: `Failed to save report in ${schemaName}`,
        icon: IconNames.ERROR,
        intent: Intent.DANGER,
      });
    }
    case ModelsActionType.SAVE_MODEL_CONSTRAINTS_REPORT_SUCCESS: {
      const { filePath } = action.payload;
      const message = `Saved report to ${filePath}`;
      return getStateWithQueuedNotification(prevState, action.type, {
        className: 'sd-toast-actionable',
        message,
        icon: IconNames.DOWNLOAD,
        intent: Intent.SUCCESS,
        timeout: 6000,
      });
    }
    case DatabasesActionType.SHOW_DISABLED_SYNTAX_NOTIFICATION_FOR_BI_SCHEMA: {
      const { buttonAction, closeNotification, databaseId } = action.payload;
      const message = `Syntax features such as highlighting and folding have been disabled for the BI Schema in database ${databaseId}.`;
      const notificationId = uuid();
      const actionableProps: ActionableNotificationMessageProps = {
        actions: [
          {
            onClick: buttonAction,
            text: 'Forcefully enable Features',
          },
        ],
        closeNotification,
        message,
        notificationId,
      };
      return getStateWithQueuedNotification(
        prevState,
        action.type,
        {
          className: 'sd-toast-actionable',
          message,
          icon: databaseIcon,
          intent: Intent.NONE,
          timeout: 0,
        },
        notificationId,
        { databaseId, actionableProps }
      );
    }
    case DatabasesActionType.SHOW_DISABLED_SYNTAX_NOTIFICATION_FOR_GRAPHQL_SCHEMA: {
      const { buttonAction, closeNotification, schemaName } = action.payload;
      const message = `Syntax features such as highlighting and folding have been disabled for schema ${schemaName}.`;
      const notificationId = uuid();
      const actionableProps: ActionableNotificationMessageProps = {
        actions: [
          {
            onClick: buttonAction,
            text: 'Forcefully enable Features',
          },
        ],
        closeNotification,
        message,
        notificationId,
      };
      return getStateWithQueuedNotification(
        prevState,
        action.type,
        {
          className: 'sd-toast-actionable',
          message,
          icon: databaseIcon,
          intent: Intent.NONE,
          timeout: 0,
        },
        notificationId,
        { schemaName, actionableProps }
      );
    }
    case ModelsActionType.SHOW_MODEL_CONSTRAINTS_DISABLED_SYNTAX_NOTIFICATION: {
      const { buttonAction, closeNotification, schemaName } = action.payload;
      const message = `Syntax features such as highlighting and folding have been disabled for model '${schemaName}' for better performance.`;
      const notificationId = uuid();
      const actionableProps: ActionableNotificationMessageProps = {
        actions: [
          {
            onClick: buttonAction,
            text: 'Forcefully enable Features',
          },
        ],
        closeNotification,
        message,
        notificationId,
      };
      return getStateWithQueuedNotification(
        prevState,
        action.type,
        {
          className: 'sd-toast-actionable',
          message,
          icon: modelsIcon,
          intent: Intent.NONE,
          timeout: 0,
        },
        notificationId,
        { schemaName, actionableProps }
      );
    }
    case VirtualGraphsActionType.SHOW_VIRTUAL_GRAPH_DISABLED_SYNTAX_NOTIFICATION: {
      const { buttonAction, closeNotification, vgId } = action.payload;
      const message = `Syntax features such as highlighting and folding have been disabled for virtual graph '${vgId}'.`;
      const notificationId = uuid();
      const actionableProps: ActionableNotificationMessageProps = {
        actions: [
          {
            onClick: buttonAction,
            text: 'Forcefully enable Features',
          },
        ],
        closeNotification,
        message,
        notificationId,
      };
      return getStateWithQueuedNotification(
        prevState,
        action.type,
        {
          className: 'sd-toast-actionable',
          message,
          icon: virtualGraphIcon,
          intent: Intent.NONE,
          timeout: 0,
        },
        notificationId,
        { vgId, actionableProps }
      );
    }
    case FileSystemActionType.EXPORTED_SAVED_CONNECTIONS: {
      const { filename, errorMessage } = action.payload;
      if (errorMessage) {
        return getStateWithQueuedNotification(prevState, action.type, {
          message: `Failed to save connections to ${filename}: ${errorMessage}`,
          icon: IconNames.ERROR,
          intent: Intent.DANGER,
        });
      }

      return getStateWithQueuedNotification(prevState, action.type, {
        className: 'sd-toast-actionable',
        message: `Saved connections to ${filename}`,
        icon: IconNames.DOWNLOAD,
        intent: Intent.SUCCESS,
        timeout: 6000,
      });
    }
    case FileSystemActionType.DID_SAVE: {
      const { noteId } = action.payload;
      const message = `Saved ${
        noteId !== USER_PREFERENCES_NOTE_ID ? noteId : 'Preferences'
      }`;
      return getStateWithQueuedNotification(prevState, action.type, {
        className: 'sd-toast-actionable',
        message,
        icon: IconNames.DOWNLOAD,
        intent: Intent.SUCCESS,
        timeout: 6000,
      });
    }
    case FileSystemActionType.DID_SAVE_AS: {
      const { newUri } = action.payload;
      const message = `Saved file as ${newUri}`;
      return getStateWithQueuedNotification(prevState, action.type, {
        className: 'sd-toast-actionable',
        message,
        icon: IconNames.DOWNLOAD,
        intent: Intent.SUCCESS,
        timeout: 6000,
      });
    }
    default:
      return prevState;
  }
};
