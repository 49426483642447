import { FlatMosaicParent } from 'src/common/components/MosaicViewManager';
import {
  ConstraintsEditorSettings,
  ConstraintsReportSettings,
  defaultConstraintsEditorSettings,
  defaultConstraintsReportSettings,
} from 'src/common/store/models/ModelsConstraintsState';
import {
  ModelsTextEditorSettings,
  defaultModelsTextEditorSettings,
} from 'src/common/store/models/ModelsTextEditorState';
import { RawJsonLdData } from 'src/common/store/visualization/VisualizationState';
import {
  ItemWithLabels,
  Label,
  LabelsByPredicate,
} from 'src/common/utils/labels/types';

export const enum CreateModelDialogType {
  CREATE = 'Create',
  CONFIRM = 'Confirm',
}

export enum ModelsTabIds {
  OVERVIEW = 'Overview',
  CLASSES = 'Classes',
  RELATIONSHIPS = 'Relationships',
  ATTRIBUTES = 'Attributes',
  CONSTRAINTS = 'Constraints',
  RULES = 'Rules',
  TEXT_EDITOR = 'Text Editor',
}

export const enum ModelsTileIds {
  SIDEBAR = 'SIDEBAR',
  VIEW = 'VIEW',
}

export const enum ModelsAttributesTileIds {
  TREE = 'TREE',
  WORKSPACE = 'WORKSPACE',
}

export const enum ModelsClassesTileIds {
  TREE = 'TREE',
  WORKSPACE = 'WORKSPACE',
}

export const enum ModelsRelationshipsTileIds {
  TREE = 'TREE',
  WORKSPACE = 'WORKSPACE',
}

export interface ModelsMosaicState {
  ATTRIBUTES: FlatMosaicParent<
    ModelsAttributesTileIds.TREE | ModelsAttributesTileIds.WORKSPACE
  >;
  CLASSES: FlatMosaicParent<
    ModelsClassesTileIds.TREE | ModelsClassesTileIds.WORKSPACE
  >;
  RELATIONSHIPS: FlatMosaicParent<
    ModelsRelationshipsTileIds.TREE | ModelsRelationshipsTileIds.WORKSPACE
  >;
  TOP: FlatMosaicParent<ModelsTileIds.SIDEBAR | ModelsTileIds.VIEW>;
}

export type ModelsMosaicStateChange = {
  ATTRIBUTES?: ModelsMosaicState['ATTRIBUTES'];
  CLASSES?: ModelsMosaicState['CLASSES'];
  RELATIONSHIPS?: ModelsMosaicState['RELATIONSHIPS'];
  TOP?: ModelsMosaicState['TOP'];
};

export const defaultMosaicState: ModelsMosaicState = {
  ATTRIBUTES: {
    direction: 'row',
    first: ModelsAttributesTileIds.TREE,
    second: ModelsAttributesTileIds.WORKSPACE,
    splitPercentage: 20,
  },
  CLASSES: {
    direction: 'row',
    first: ModelsClassesTileIds.TREE,
    second: ModelsClassesTileIds.WORKSPACE,
    splitPercentage: 20,
  },
  RELATIONSHIPS: {
    direction: 'row',
    first: ModelsRelationshipsTileIds.TREE,
    second: ModelsRelationshipsTileIds.WORKSPACE,
    splitPercentage: 20,
  },
  TOP: {
    direction: 'row',
    first: ModelsTileIds.SIDEBAR,
    second: ModelsTileIds.VIEW,
    splitPercentage: 20,
  },
};

export enum ModelsItemDataFields {
  comment = 'comment',
  labelsByPredicate = 'labelsByPredicate',
  preferredLabel = 'preferredLabel',
  parents = 'parents',
  domain = 'domain',
  range = 'range',
}

export interface ModelsItemData {
  [ModelsItemDataFields.comment]: string;
  [ModelsItemDataFields.domain]: string[];
  [ModelsItemDataFields.range]: string[];
}

export interface ModelsItemLocalData extends ModelsItemData {
  labelsByPredicateId: string;
  [ModelsItemDataFields.preferredLabel]: Label;
  [ModelsItemDataFields.labelsByPredicate]: LabelsByPredicate;
  [ModelsItemDataFields.parents]: string[];
}

export type ModelsItemLocalDataValues =
  ModelsItemLocalData[keyof ModelsItemLocalData];

export type NormalizedModelsItem = ItemWithLabels<{
  children: string[];
  data?: ModelsItemData;
  id: string;
  isDirty: boolean;
  isNew: boolean;
  isPendingData: boolean;
  localData?: ModelsItemLocalData;
  parents: string[];
}>;

export interface NormalizedModelsItems {
  [iri: string]: NormalizedModelsItem;
}

export interface DenormalizedModelsItem {
  children: DenormalizedModelsItems;
  id: string;
  isCircular: boolean;
  path: string[];
}

export interface DenormalizedModelsItems {
  [value: string]: DenormalizedModelsItem;
}

export type ModelsSchemaTabData = {
  areItemsDirty: boolean;
  deletedItems: Set<string>;
  isPendingData: boolean;
  itemHierarchy: DenormalizedModelsItems;
  itemsByIri: NormalizedModelsItems;
  openItems: Set<string>;
  selectedIri: string;
  selectedPath: string[];
};

export function getDefaultModelSchemaTabData(): ModelsSchemaTabData {
  return {
    areItemsDirty: false,
    deletedItems: new Set(),
    isPendingData: false,
    itemHierarchy: {},
    itemsByIri: {},
    openItems: new Set(),
    selectedIri: '',
    selectedPath: [],
  };
}

export type ModelsConstraintsData = {
  editorSettings: ConstraintsEditorSettings;
  reportSettings: ConstraintsReportSettings;
};

export function getDefaultModelConstraintsData(): ModelsConstraintsData {
  return {
    editorSettings: defaultConstraintsEditorSettings,
    reportSettings: defaultConstraintsReportSettings,
  };
}

export function getDefaultModelsTextEditorData(): ModelsTextEditorSettings {
  return defaultModelsTextEditorSettings;
}

export interface ModelsVisualizationSettings {
  visualizationId?: string;
  visualizationData?: RawJsonLdData[];
  visualizationDate?: string;
}

export type ModelsNamedGraphsBySchema = {
  [schemaName: string]: string[];
};

export class ModelsState {
  constructor(
    public attributes: ModelsSchemaTabData = getDefaultModelSchemaTabData(),
    public classes: ModelsSchemaTabData = getDefaultModelSchemaTabData(),
    public relationships: ModelsSchemaTabData = getDefaultModelSchemaTabData(),
    public constraints: ModelsConstraintsData = getDefaultModelConstraintsData(),
    public isPendingCreateSchema: boolean = false,
    public isPendingSave: boolean = false,
    public isNewlyCreated: boolean = false,
    public mosaicState: ModelsMosaicState = defaultMosaicState,
    public namedGraphsBySchema: ModelsNamedGraphsBySchema = {},
    public pendingVisualization: boolean = false,
    public selectedDatabaseId: string = '',
    public selectedSchemaName: string = '',
    public tabId: ModelsTabIds = ModelsTabIds.OVERVIEW,
    public textEditor: ModelsTextEditorSettings = defaultModelsTextEditorSettings,
    public visualizationSettings?: ModelsVisualizationSettings
  ) {}
}
