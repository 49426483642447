import orderBy from 'lodash.orderby';
import { alphaSort } from 'vet-bones/bones/utils';

import {
  ConnectionAction,
  ConnectionActionType,
} from 'src/common/actions/connection/connectionActionCreators';
import {
  DatabasesAction,
  DatabasesActionType,
} from 'src/common/actions/databases/databasesActionCreators';
import { isBody } from 'src/common/actions/request/types';
import {
  SecurityAction,
  SecurityActionType,
} from 'src/common/actions/security/securityActionCreators';
import {
  Permission,
  PermissionAssignee,
  ResourceType,
  Role,
  SecurityState,
  User,
} from 'src/common/store/security/SecurityState';
import { ServerState } from 'src/common/store/security/ServerState';

const initialState = new SecurityState();

// TODO: Lots of room for making things DRY here.
const securityReducer = (
  prevState = initialState,
  action: SecurityAction | ConnectionAction | DatabasesAction
): SecurityState => {
  switch (action.type) {
    // Reset security state if a new connection is set
    case ConnectionActionType.SET_CONNECTION_SUCCESS: {
      return initialState;
    }
    case SecurityActionType.REQUEST_USERS_ATTEMPT:
    case SecurityActionType.REQUEST_ROLES_ATTEMPT: // intentional fallthrough
      return {
        ...prevState,
        pending: true,
      };
    case SecurityActionType.REQUEST_USERS_FAILURE:
    case SecurityActionType.REQUEST_ROLES_FAILURE: // intentional fallthrough
      return {
        ...prevState,
        pending: false,
      };
    case SecurityActionType.REQUEST_USERS_SUCCESS: {
      action.payload.response.body.users?.sort(alphaSort());
      const user =
        action.payload.response.body.users?.indexOf(prevState.user) > -1
          ? prevState.user
          : action.payload.response.body.users?.[0];
      return {
        ...prevState,
        pending: false,
        userIds: action.payload.response.body?.users || [],
        user,
      };
    }
    case SecurityActionType.REQUEST_DETAILS_FOR_USER_SUCCESS: {
      if (!isBody(action.payload?.response)) {
        return prevState;
      }
      const { permissions = [], ...restBody } =
        action.payload.response?.body || {};
      const explicitPermissions = permissions
        .filter((permission) => permission.explicit)
        .map((permission) => ({
          ...permission,
          resource: [permission.resource.join('\\')],
        }));
      const sortedPermissions = orderBy(
        explicitPermissions,
        [prevState.userPermissionColumn],
        [prevState.userPermissionSort]
      );
      const id = action.payload.args[0];
      return {
        ...prevState,
        pending: false,
        userIds:
          prevState.userIds.indexOf(id) !== -1
            ? prevState.userIds
            : prevState.userIds.concat(id),
        users: {
          ...prevState.users,
          [id]: {
            ...(prevState.users[id] || {}),
            username: id,
            ...restBody,
            permissions: sortedPermissions,
          },
        },
      };
    }
    case SecurityActionType.REQUEST_USERS_LIST_INFO_SUCCESS: {
      if (!isBody(action.payload?.response)) {
        return prevState;
      }

      const { users } = action.payload.response.body || {};

      if (!Array.isArray(users) || !users.length || !users[0].username) {
        return prevState;
      }

      const sortedUsers = (users as User[]).sort(
        alphaSort((user) => user?.username || '')
      );
      const userIds = sortedUsers.map((user) => user?.username || '');
      const usersById = sortedUsers.reduce((acc, user) => {
        if (user.username) {
          acc[user.username] = user;
        }
        return acc;
      }, {});

      return {
        ...prevState,
        pending: false,
        userIds,
        users: usersById,
      };
    }
    case SecurityActionType.REQUEST_ROLES_SUCCESS: {
      action.payload.response.body.roles?.sort(alphaSort());
      const role =
        action.payload.response.body.roles?.indexOf(prevState.role) > -1
          ? prevState.role
          : action.payload.response.body.roles?.[0];
      return {
        ...prevState,
        pending: false,
        roleIds: action.payload.response.body?.roles || [],
        role,
      };
    }
    case SecurityActionType.REQUEST_DETAILS_FOR_ROLE_SUCCESS: {
      if (!isBody(action.payload.response)) {
        return prevState;
      }
      const id = action.payload.args[0];
      return {
        ...prevState,
        pending: false,
        roleIds:
          prevState.roleIds.indexOf(id) !== -1
            ? prevState.roleIds
            : prevState.roleIds.concat(id),
        roles: {
          ...prevState.roles,
          [id]: {
            ...(prevState.roles[id] || {}),
            rolename: id,
            permissions: (action.payload.response.body.permissions || []).map(
              (permission) => ({
                ...permission,
                resource: [permission.resource.join('\\')],
              })
            ),
          },
        },
      };
    }
    case SecurityActionType.REQUEST_ROLES_LIST_INFO_SUCCESS: {
      if (!isBody(action.payload?.response)) {
        return prevState;
      }

      const { roles } = action.payload.response.body || {};

      if (!Array.isArray(roles) || !roles.length || !roles[0].rolename) {
        return prevState;
      }

      // role has `rolename` instead of `name` which would match Role type
      const sortedRoles = roles.sort(alphaSort((user) => user?.rolename || ''));
      const roleIds = sortedRoles.map((role) => role?.rolename || '');
      const rolesById = sortedRoles.reduce<Record<string, Role>>(
        (acc, role) => {
          if (role.rolename) {
            acc[role.rolename] = {
              rolename: role.rolename,
              permissions: role.permissions,
            };
          }
          return acc;
        },
        {}
      );

      return {
        ...prevState,
        pending: false,
        roleIds,
        roles: rolesById,
      };
    }
    case SecurityActionType.TOGGLE_USER_ENABLED_SUCCESS: {
      const id = action.payload.args[0];
      return {
        ...prevState,
        pending: false,
        users: {
          ...prevState.users,
          [id]: {
            ...prevState.users[id],
            enabled: action.payload.args[1],
          },
        },
      };
    }
    case SecurityActionType.DELETE_USER_SUCCESS: {
      const userName = action.payload.args[0];
      const userIds = prevState.userIds.filter((id) => id !== userName);
      return {
        ...prevState,
        pending: false,
        userIds,
        user: userIds[0],
        users: Object.keys(prevState.users)
          .filter((id) => id !== userName)
          .reduce((newState, id) => {
            newState[id] = prevState.users[id];
            return newState;
          }, {}),
      };
    }
    case SecurityActionType.ADD_USER_SUCCESS: {
      const user = action.payload.args[0];
      return {
        ...prevState,
        pending: false,
        userIds: prevState.userIds.concat(user.username),
        users: {
          ...prevState.users,
          [user.username]: {
            username: user.username,
            enabled: true,
            superuser: user.superuser,
            // Defaults:
            roles: [],
            permissions: [
              {
                action: 'READ',
                resource_type: ResourceType.USER,
                resource: [user.username],
              },
            ],
          },
        },
      };
    }
    case SecurityActionType.DELETE_ROLE_SUCCESS: {
      const roleName = action.payload.args[0];
      const roleIds = prevState.roleIds.filter((id) => id !== roleName);
      return {
        ...prevState,
        pending: false,
        roleIds,
        role: roleIds[0],
        roles: Object.keys(prevState.roles)
          .filter((id) => id !== roleName)
          .reduce((newState, id) => {
            newState[id] = prevState.roles[id];
            return newState;
          }, {}),
      };
    }
    case SecurityActionType.ADD_ROLE_SUCCESS:
      return {
        ...prevState,
        pending: false,
        roleIds: prevState.roleIds.concat(action.payload.args[0].name),
        roles: {
          ...prevState.roles,
          [action.payload.args[0].name]: {
            rolename: action.payload.args[0].name,
            permissions: [],
          },
        },
      };
    case SecurityActionType.SET_ROLES_FOR_USER_SUCCESS:
      return {
        ...prevState,
        users: {
          ...prevState.users,
          [action.payload.args[0]]: {
            ...prevState.users[action.payload.args[0]],
            roles: action.payload.args[1],
          },
        },
      };
    case SecurityActionType.ADD_PERMISSION_FOR_ROLE_SUCCESS: // intentional fallthrough
    case SecurityActionType.DELETE_PERMISSION_FOR_ROLE_SUCCESS: {
      const roleId = action.payload.args[0];
      const permission = action.payload.args[1];

      const prevPermissions =
        prevState.roles[action.payload.args[0]].permissions || [];
      let newPermissions;

      if (action.type === SecurityActionType.ADD_PERMISSION_FOR_ROLE_SUCCESS) {
        newPermissions = prevPermissions.concat({
          action: permission.action,
          resource: permission.resources,
          resource_type: permission.resourceType,
          explicit: false,
        });
      } else {
        newPermissions = prevPermissions.filter((p: Permission) => {
          return (
            p.action !== permission.action ||
            p.resource_type !== permission.resourceType ||
            p.resource.length !== permission.resources.length ||
            !p.resource.every(
              (resource) => permission.resources.indexOf(resource) > -1
            )
          );
        });
      }

      return {
        ...prevState,
        roles: {
          ...prevState.roles,
          [roleId]: {
            ...prevState.roles[roleId],
            permissions: newPermissions,
          },
        },
      };
    }
    case SecurityActionType.ADD_PERMISSION_FOR_USER_SUCCESS: {
      const userId = action.payload.args[0];
      return {
        ...prevState,
        users: {
          ...prevState.users,
          [userId]: {
            ...prevState.users[userId],
            permissions: prevState.users[userId].permissions.concat({
              action: action.payload.args[1].action,
              resource: action.payload.args[1].resources,
              resource_type: action.payload.args[1].resourceType,
              explicit: action.payload.action.explicit,
            }),
          },
        },
      };
    }
    case SecurityActionType.DELETE_PERMISSION_FOR_USER_SUCCESS: {
      const userId = action.payload.args[0];
      const permission = action.payload.args[1];
      const index = prevState.users[userId].permissions.findIndex(
        (p: Permission) => {
          return (
            p.action === permission.action &&
            p.resource_type === permission.resourceType &&
            p.resource.length === permission.resources.length &&
            p.resource.every(
              (resource) => permission.resources.indexOf(resource) > -1
            )
          );
        }
      );
      const permissions = [...prevState.users[userId].permissions];
      permissions.splice(index, 1);
      return {
        ...prevState,
        users: {
          ...prevState.users,
          [userId]: {
            ...prevState.users[userId],
            permissions,
          },
        },
      };
    }
    case SecurityActionType.SELECT_ROLE: {
      return {
        ...prevState,
        role: action.payload.role,
        which: PermissionAssignee.ROLE,
      };
    }
    case SecurityActionType.SELECT_USER: {
      return {
        ...prevState,
        user: action.payload.user,
        which: PermissionAssignee.USER,
      };
    }
    case DatabasesActionType.GET_STATUS_SUCCESS: {
      if (!isBody(action.payload?.response)) {
        // type narrowing
        return prevState;
      }

      const serverState: ServerState = action.payload?.response?.body;
      const shouldLimitPermissions =
        serverState?.['dbms.security.realms']?.value === 'ldap';
      return {
        ...prevState,
        password: {
          min: Number(serverState['dbms.password.length.min'].value),
          max: Number(serverState['dbms.password.length.max'].value),
          // @ts-ignore
          regex: RegExp(String(serverState['dbms.password.regex'].value)),
        },
        shouldLimitPermissions,
      };
    }
    case SecurityActionType.SET_SORT: {
      // this reducer sorts the permissions list and also sets properties
      // about how the table is currently sorted to persist

      const { which, direction, username, column } = action.payload;

      enum SortType {
        ROLE = 'rolePermissionSort',
        USER = 'userPermissionSort',
      }

      enum ColumnType {
        ROLE = 'rolePermissionColumn',
        USER = 'userPermissionColumn',
      }

      enum PermissionsType {
        ROLE = 'roles',
        USER = 'users',
      }

      let sort = SortType.ROLE;
      let columnName = ColumnType.ROLE;
      let cat = PermissionsType.ROLE;

      if (which === PermissionAssignee.USER) {
        sort = SortType.USER;
        columnName = ColumnType.USER;
        cat = PermissionsType.USER;
      }

      const user = prevState[cat][username];

      if (!user) {
        return prevState;
      }

      const { permissions } = user;
      // sort permissions, if passing in column sort by column or keep the previous column
      // then sort by direction if direction was passed in else by previous direction
      const sortedPermissions = orderBy(
        permissions,
        [column || prevState[columnName]],
        [direction || prevState[sort]]
      );

      return {
        ...prevState,
        [cat]: {
          ...prevState[cat],
          [username]: {
            ...prevState[cat][username],
            permissions: sortedPermissions,
          },
        },
        [sort]: direction,
        [columnName]: column,
      };
    }
    case SecurityActionType.UPDATE_SECURITY_MOSAIC_STATE: {
      const { mosaicStateChange } = action.payload;
      return {
        ...prevState,
        mosaicState: {
          ...prevState.mosaicState,
          ...mosaicStateChange,
        },
      };
    }
    default:
      return prevState;
  }
};

export { securityReducer };
