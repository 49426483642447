import * as React from 'react';
import { isPortal } from 'vet-bones/bones/utils/portal';

import { PortalConnectionButton } from 'src/common/containers/AppNavbar/PortalConnectionButton';
import { StandaloneConnectionButton } from 'src/common/containers/AppNavbar/StandaloneConnectionButton';

export const ConnectionButton: React.VFC = () => {
  if (isPortal()) {
    return <PortalConnectionButton />;
  }

  return <StandaloneConnectionButton />;
};
