import * as Monaco from 'monaco-editor/esm/vs/editor/editor.api';

import { FlatMosaicParent } from 'src/common/components/MosaicViewManager';
import { LanguageId } from 'src/common/constants/LanguageId';
import {
  CassandraOptionValueMap,
  MongoOptionValueMap,
  SqlOptionValueMap,
} from 'src/common/store/virtualGraphs/options';
import {
  VirtualGraphMappingType,
  VirtualGraphSource,
} from 'src/common/store/virtualGraphs/VirtualGraphMappingType';

type RequiredVirtualGraphProperties = 'id' | 'type' | 'languageId';

export class VirtualGraph {
  public id: string;

  public source: VirtualGraphSource;

  public type: VirtualGraphMappingType;

  public databaseId: string | null;

  public dataSource: string | null;

  public languageId: LanguageId;

  // TODO CsvOptionValueMap
  public properties:
    | Partial<SqlOptionValueMap>
    | Partial<CassandraOptionValueMap>
    | Partial<MongoOptionValueMap> = {};

  public connectionPoolProperties: { [key: string]: string };

  public externalProperties: { [key: string]: string };

  public mappingDoc = '';

  public pending = false;

  public pendingUpdate = false;

  public viewState: Monaco.editor.ICodeEditorViewState = null;

  public isLocal = false;

  public isDataSourceDirty = true;

  public isPropertiesDirty = true;

  public isMappingDirty = true;

  public lastRetrievedDataSource: string | null;

  public lastRetrievedProperties:
    | Partial<SqlOptionValueMap>
    | Partial<MongoOptionValueMap>
    | Partial<CassandraOptionValueMap>;

  public lastRetrievedMappingDoc: string | undefined;

  public areSyntaxFeaturesDisabled = false;

  public wasSyntaxNotificationShown = false;

  constructor(
    initialConfig: Pick<VirtualGraph, RequiredVirtualGraphProperties> &
      Partial<
        Pick<
          VirtualGraph,
          Exclude<keyof VirtualGraph, RequiredVirtualGraphProperties>
        >
      >
  ) {
    Object.keys(initialConfig).forEach((key) => {
      this[key] = initialConfig[key];
    });
  }
}

export type MutableVirtualGraphSettings = Pick<
  VirtualGraph,
  'source' | 'type' | 'properties' | 'languageId' | 'mappingDoc'
>;

export type VirtualGraphSaveContext = 'config' | 'mapping';

export const enum VirtualGraphsTileIds {
  SIDEBAR = 'SIDEBAR',
  VIEW = 'VIEW',
}

export interface VirtualGraphsMosaicState {
  TOP: FlatMosaicParent<
    VirtualGraphsTileIds.SIDEBAR | VirtualGraphsTileIds.VIEW
  >;
}

export type VirtualGraphsMosaicStateChange = {
  TOP: VirtualGraphsMosaicState['TOP'];
};

export const defaultMosaicState: VirtualGraphsMosaicState = {
  TOP: {
    direction: 'row',
    first: VirtualGraphsTileIds.SIDEBAR,
    second: VirtualGraphsTileIds.VIEW,
    splitPercentage: 20,
  },
};

export type VirtualGraphListInfoObject = {
  name: string;
  database: string;
  available: boolean;
};

export class VirtualGraphsState {
  constructor(
    public mosaicState = defaultMosaicState,
    public selectedVgId = '',
    public pending = false,
    public pendingUpdate = false,
    public vgIds: readonly string[] = [],
    public vgsById: Record<string, Partial<VirtualGraph>> = {},
    public lastKnownServerSideVgIds: readonly string[] = []
  ) {}
}
