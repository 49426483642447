import { query } from 'stardog';

import { request } from 'src/common/actions/request';
import { ActionCreatorAction } from 'src/common/actions/types';
import { StoredQuery } from 'src/common/store/storedQueries/StoredQueriesState';

export const enum StoredQueriesActionType {
  GET_STORED_QUERIES_ATTEMPT = 'GET_STORED_QUERIES_ATTEMPT',
  GET_STORED_QUERIES_SUCCESS = 'GET_STORED_QUERIES_SUCCESS',
  GET_STORED_QUERIES_FAILURE = 'GET_STORED_QUERIES_FAILURE',
  DELETE_STORED_QUERY_ATTEMPT = 'DELETE_STORED_QUERY_ATTEMPT',
  DELETE_STORED_QUERY_SUCCESS = 'DELETE_STORED_QUERY_SUCCESS',
  DELETE_STORED_QUERY_FAILURE = 'DELETE_STORED_QUERY_FAILURE',
  STORE_QUERY_ATTEMPT = 'STORE_QUERY_ATTEMPT',
  STORE_QUERY_SUCCESS = 'STORE_QUERY_SUCCESS',
  STORE_QUERY_FAILURE = 'STORE_QUERY_FAILURE',
  UPDATE_QUERY_ATTEMPT = 'UPDATE_QUERY_ATTEMPT',
  UPDATE_QUERY_SUCCESS = 'UPDATE_QUERY_SUCCESS',
  UPDATE_QUERY_FAILURE = 'UPDATE_QUERY_FAILURE',
}

export const storedQueriesActionCreators = {};

export const storedQueriesStardogRequestDispatchers = {
  deleteStoredQuery: (queryName, database) =>
    request({
      api: query.stored.remove,
      args: [queryName],
      action: {
        queryName,
        database,
      },
      loading: StoredQueriesActionType.DELETE_STORED_QUERY_ATTEMPT,
      success: StoredQueriesActionType.DELETE_STORED_QUERY_SUCCESS,
      failure: StoredQueriesActionType.DELETE_STORED_QUERY_FAILURE,
    }),
  getStoredQueries: (isGraphQLStoredQueriesSupported: boolean) =>
    request({
      api: query.stored.list,
      args: [],
      action: { isGraphQLStoredQueriesSupported },
      loading: StoredQueriesActionType.GET_STORED_QUERIES_ATTEMPT,
      success: StoredQueriesActionType.GET_STORED_QUERIES_SUCCESS,
      failure: StoredQueriesActionType.GET_STORED_QUERIES_FAILURE,
    }),
  storeQueryForNote: (
    storeQueryOptions: Pick<
      StoredQuery,
      // `reasoning` is added in 6.2.2, but it does no harm to provide it
      // earlier, at least tested with 6.2.0
      'name' | 'database' | 'query' | 'shared' | 'reasoning'
    >
  ) =>
    request({
      api: query.stored.create,
      args: [storeQueryOptions],
      action: {},
      loading: StoredQueriesActionType.STORE_QUERY_ATTEMPT,
      success: StoredQueriesActionType.STORE_QUERY_SUCCESS,
      failure: StoredQueriesActionType.STORE_QUERY_FAILURE,
    }),
  updateQueryForNote: (
    storedQueryOptions: Pick<
      StoredQuery,
      // `reasoning` is added in 6.2.2, but it does no harm to provide it
      // earlier, at least tested with 6.2.0
      'name' | 'database' | 'query' | 'shared' | 'reasoning'
    >,
    latestSavedVersionId: number
  ) =>
    request({
      api: query.stored.update,
      args: [storedQueryOptions],
      action: { latestSavedVersionId },
      loading: StoredQueriesActionType.UPDATE_QUERY_ATTEMPT,
      success: StoredQueriesActionType.UPDATE_QUERY_SUCCESS,
      failure: StoredQueriesActionType.UPDATE_QUERY_FAILURE,
    }),
};

const storedQueriesActionTypeMap = {
  ...storedQueriesActionCreators,
  ...storedQueriesStardogRequestDispatchers,
};

export type StoredQueriesAction = ActionCreatorAction<
  typeof storedQueriesActionTypeMap
>;
