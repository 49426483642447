import { autoBindMethodsForReact } from 'class-autobind-decorator';
import debounce from 'lodash.debounce';
import * as React from 'react';
import { noop } from 'vet-bones/bones/utils';

import { makeEnterHandler } from 'src/common/utils/makeEnterHandler';

type DebouncedInputProps = {
  className?: string;
  disabled?: boolean;
  onChange: (value: string) => any;
  onEnter?: (value: string) => any;
  onFocus?: () => any;
  onRef?: (ref: HTMLInputElement) => any;
  placeholder?: string;
  testId?: string;
  type?: string;
  value: string;
  wait?: number;
};

type DebouncedInputState = {
  value: string;
};

@autoBindMethodsForReact
export class DebouncedInput extends React.Component<
  DebouncedInputProps,
  DebouncedInputState
> {
  static defaultProps = {
    ref: noop,
    onEnter: noop,
    onFocus: noop,
    type: 'text',
    wait: 200,
  };

  debouncedOnChange = debounce(this.onChange, this.props.wait);

  constructor(props: DebouncedInputProps) {
    super(props);

    this.state = {
      value: '',
    };
  }

  componentDidUpdate(prevProps: DebouncedInputProps) {
    const { value } = this.props;
    if (value !== prevProps.value && value !== this.state.value) {
      this.setState({ value });
    }
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ value: event.target.value });
    this.debouncedOnChange();
  }

  handleKeyDown = makeEnterHandler(this.onEnter);

  onChange() {
    this.props.onChange(this.state.value);
  }

  onEnter() {
    this.props.onEnter(this.state.value);
  }

  render() {
    const { className, disabled, onFocus, onRef, placeholder, testId, type } =
      this.props;
    const { value } = this.state;
    return (
      <input
        ref={onRef}
        className={className}
        data-testid={testId}
        disabled={disabled}
        onChange={this.handleChange}
        onFocus={onFocus}
        onKeyDown={this.handleKeyDown}
        placeholder={placeholder}
        type={type}
        value={value}
      />
    );
  }
}
